import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
  Box,
  MobileStepper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIsLargeScreen from 'src/hooks/useIsLargeScreen';

const QontoConnector = withStyles(theme => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main
    }
  },
  completed: {
    '& $line': {
      borderColor: '#784af4'
    }
  },
  line: {
    borderTopWidth: '1px',
    borderBottomWidth: '0px',
    height: '1px',
    borderStyle: 'dashed'
  }
}))(StepConnector);

const FormStepper = ({ index, labels, ...props }) => {
  const { t } = useTranslation();

  const isLargeScreen = useIsLargeScreen();

  return (
    <Box sx={{ width: '100%' }} {...props}>
      {isLargeScreen && (
        <Stepper
          alternativeLabel
          activeStep={index}
          connector={<QontoConnector />}
        >
          {labels.map(label => (
            <Step key={label}>
              <StepLabel className={index.toString()}>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {!isLargeScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingY: '20px',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <Typography variant="h1" color="primary">
            {t(labels[index])}
          </Typography>
          <MobileStepper
            steps={labels.length}
            position="static"
            activeStep={index}
            sx={{ background: 'transparent' }}
          />
        </Box>
      )}
    </Box>
  );
};

FormStepper.propTypes = {
  index: PropTypes.number.isRequired,
  labels: PropTypes.array.isRequired
};

export default FormStepper;
