import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string()
    .test(
      'rfc-validation',
      'SCHEMA_ERRORS.RFC_LEGAL_PERSON_NOR_AVAILABLE',
      value => {
        const regexLegalPerson = /^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        if (regexLegalPerson.test(value)) {
          return false;
        }
        return true;
      }
    )
    .matches(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED')
});
