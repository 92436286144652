export default values => ({
  insurerId: values.insurance?.insurer ?? null,
  agencyId: values.insurance?.agency ?? null,
  coverageStart: values.insurance?.coverageStart ?? null,
  coverageEnd: values.insurance?.coverageEnd ?? null,
  price: values.insurance?.price ?? '',
  iva: values.insurance?.iva || values.iva,
  policyId: values.insurance?.policyId ?? '',
  policyPdfUrl: values.insurance?.policyPdfUrl
    ? {
        name: 'EQUIPMENT_VIEW.EQUIPMENT_DETAIL.VIEW_POLICY_PDF',
        link: values.insurance.policyPdfUrl,
        inDb: true
      }
    : null
});
