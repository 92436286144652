import { MetricsViewOptions } from 'src/utils/constants/business';
import {
  CLEAR_BUSINESS_METRICS,
  LOAD_BUSINESS_METRICS,
  LOAD_BUSINESS_METRICS_RESUME,
  SAVE_BUSINESS_OPTIONS
} from '../actions/businessActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    metricsViewOption: MetricsViewOptions.DISTRIBUTOR
  },
  metrics: {
    items: [],
    count: 0
  },
  resume: {
    items: [],
    count: 0
  }
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUSINESS_METRICS.success: {
      return {
        ...state,
        metrics: {
          items: action.items,
          count: action.count
        }
      };
    }

    case LOAD_BUSINESS_METRICS_RESUME.success: {
      return {
        ...state,
        resume: {
          items: action.items,
          count: action.count
        }
      };
    }
    case SAVE_BUSINESS_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options
        }
      };
    }

    case CLEAR_BUSINESS_METRICS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default businessReducer;
