import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import { useTranslation } from 'react-i18next';
import { formatPositiveAmount } from 'src/utils/formatAmout';

const DistributorsRow = ({
  id,
  name,
  commercialName,
  equipmentType,
  quotesInReview,
  quotesAccepted,
  nufiScore
}) => {
  const { t } = useTranslation();
  const getValidationIcon = score => {
    if (score === 100) {
      return StatusIcon({
        description: `ENUMS.CLIENT_VALIDATION_STATUS.VALIDATED`,
        type: IconStatusAndColor.OK.status
      });
    }
    return StatusIcon({
      description: `ENUMS.CLIENT_VALIDATION_STATUS.NOT_VALIDATED`,
      type: IconStatusAndColor.ERROR.status
    });
  };
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>
        <Link to={`/distributors/business/${id}`}>{name}</Link>
      </TableCell>
      <TableCell align="center">{commercialName}</TableCell>
      <TableCell align="center">{equipmentType}</TableCell>
      <TableCell align="center">
        {quotesInReview && (
          <TooltipTypography
            title={`${quotesInReview.count} ${t('COMMON.REQUESTS')}`}
            text={formatPositiveAmount(quotesInReview.amount)}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {quotesAccepted && (
          <TooltipTypography
            title={`${quotesAccepted.count} ${t('COMMON.CONTRACTS')}`}
            text={formatPositiveAmount(quotesAccepted.amount)}
          />
        )}
      </TableCell>
      <TableCell align="center">{getValidationIcon(nufiScore)}</TableCell>
    </TableRow>
  );
};

DistributorsRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  commercialName: PropTypes.string.isRequired,
  equipmentType: PropTypes.string.isRequired,
  quotesInReview: PropTypes.object.isRequired,
  quotesAccepted: PropTypes.object.isRequired,
  nufiScore: PropTypes.number.isRequired
};

export default DistributorsRow;
