import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE,
  UPDATE_AVALS_LIST,
  createAval,
  getClientAvals
} from 'src/store/actions/clientActions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import AddIcon from '@mui/icons-material/Add';

import {
  selectClientAvalCount,
  selectClientAvalsList,
  selectClientDefaultLegalRepresentative,
  selectClientPersonType,
  selectClientsQueryOptions
} from 'src/store/selectors/clientSelectors';
import Preloader from 'src/components/Preloader';
import AlertMessage from 'src/components/Alert/Alert';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import { PersonType } from 'src/utils/constants/clients';
import AvalDetailTable from './AvalDetailList/AvalDetailTable';
import useStyles from '../styles';
import CreateAvalForm from './CreateAvalForm';

const AvalView = ({ isDisabledEdit = false, isAdminView = true }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const avals = useSelector(selectClientAvalsList);
  const defaultLegalRepresentative = useSelector(
    selectClientDefaultLegalRepresentative
  );
  const loading = useSelector(isLoadingSelector([UPDATE_AVALS_LIST.action]));
  const success = useSelector(successSelector([UPDATE_AVALS_LIST.action]));
  const successReassign = useSelector(
    successSelector([ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE.action])
  );
  const { page } = useSelector(selectClientsQueryOptions);
  const count = useSelector(selectClientAvalCount);
  const clientPersonType = useSelector(selectClientPersonType);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const { t } = useTranslation();
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    (values, setdata) => {
      dispatch(createAval(Number(id), values, handleClose, () => setdata()));
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(getClientAvals(id, { page: value }));
    },
    [dispatch]
  );

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getClientAvals(id));
  }, []);

  useEffect(() => {
    if (!defaultLegalRepresentative && clientPersonType === PersonType.LEGAL) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [defaultLegalRepresentative, clientPersonType, successReassign]);

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.AVALS')}
            </Typography>
          </Box>
        </Grid>
        {loading && !success ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        ) : (
          <>
            <Grid item sx={12} lg={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <Box>
                  <Button
                    className={classes.buttons}
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleClickOpen}
                    disabled={isDisabledEdit}
                  >
                    {t('CLIENTS_VIEW.AVAL_DETAIL.CREATE_AVAL')}
                  </Button>
                </Box>

                <CreateAvalForm
                  open={open}
                  handleClose={handleClose}
                  handleCreateAval={handleSubmit}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <AlertMessage
                open={openAlert}
                severity={AlertSeverity.WARNING}
                title={t('COMMON.WARNING')}
                text={t(
                  'TOOLTIP_MESSAGES.CLIENT_DOES_NOT_HAVE_LEGAL_REPRESENTATIVE'
                )}
                handleClose={handleAlertClose}
              />
            </Grid>
            <Grid item xs={12}>
              <AvalDetailTable
                avals={avals}
                loading={loading}
                onChangePage={handleChangePage}
                page={page}
                count={count}
                isDisabledEdit={isDisabledEdit}
                isAdminView={isAdminView}
                clientPersonType={clientPersonType}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

AvalView.propTypes = {
  isDisabledEdit: PropTypes.bool,
  isAdminView: PropTypes.bool
};

export default AvalView;
