import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPointsPhone } from 'src/utils/common';
import { CommonActions } from 'src/utils/constants/clients';
import { AgentStatus } from 'src/utils/constants/distributors';
import ChangeStatus from '@mui/icons-material/ImportExport';
import ReSendIcon from '@mui/icons-material/Autorenew';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateKam } from 'src/store/actions/userKamActions';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { resendValidationEmail } from 'src/store/actions/authActions';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import KamDialog from './KamsDialog';

const AgentMenu = (status, emailValidated) => {
  const agentStatus =
    status === AgentStatus.ACTIVE
      ? 'DISTRIBUTORS_VIEW.AGENTS.DISABLE_KAM'
      : 'DISTRIBUTORS_VIEW.AGENTS.ENABLE_KAM';

  return [
    {
      name: CommonActions.EDIT,
      label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.EDIT}`
    },
    {
      name: CommonActions.CHANGE_STATUS,
      label: agentStatus,
      icon: <ChangeStatus />
    },
    {
      name: CommonActions.RESEND_VALIDATION,
      label: 'ENUMS.ADMINISTRATIVE_ACTIONS.RESEND_VALIDATION',
      icon: <ReSendIcon />,
      disabled: emailValidated
    }
  ];
};

const KamRow = ({
  id,
  name,
  emailValidated,
  email,
  phone,
  officePhone,
  status,
  quotesInDraft,
  quotesInReview,
  quotesAccepted
}) => {
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditStatusDialog, setOpenEditStatusDialog] = useState(false);
  const dispatch = useDispatch();
  const options = AgentMenu(status, emailValidated);

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatusDialog(false);
  };

  const handleUpdateKam = useCallback(
    values => {
      dispatch(updateKam(id, values));
      handleCloseEdit();
    },
    [dispatch, id]
  );

  const handleChangeStatus = useCallback(() => {
    if (status === AgentStatus.ACTIVE) {
      dispatch(updateKam(id, { status: AgentStatus.INACTIVE }));
      return handleCloseEditStatus();
    }
    dispatch(updateKam(id, { status: AgentStatus.ACTIVE }));
    return handleCloseEditStatus();
  });

  const handleResendValidation = useCallback(() => {
    dispatch(resendValidationEmail(id));
  }, [id]);

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.CHANGE_STATUS) {
      setOpenEditStatusDialog(true);
    }
    if (option === CommonActions.RESEND_VALIDATION) {
      handleResendValidation();
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/user/kam/${id}`}>{name}</Link>
      </TableCell>
      <TableCell>{addPointsPhone(phone || '')}</TableCell>
      <TableCell>{addPointsPhone(officePhone || '')}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            emailValidated
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            emailValidated
              ? 'ENUMS.VERIFICATION_STATUS.VERIFIED'
              : 'ENUMS.VERIFICATION_STATUS.UNVERIFIED'
          }
        />
      </TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            status === AgentStatus.ACTIVE
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            status === AgentStatus.ACTIVE
              ? 'ENUMS.STATUS.ACTIVE'
              : 'ENUMS.STATUS.INACTIVE'
          }
        />
      </TableCell>
      <TableCell align="center">
        <Tooltip title={`${quotesInDraft.count} ${t('COMMON.QUOTES')}`}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            {quotesInDraft && formatPositiveAmount(quotesInDraft.amount)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={`${quotesInReview.count} ${t('COMMON.REQUESTS')}`}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            {quotesInReview && formatPositiveAmount(quotesInReview.amount)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={`${quotesAccepted.count} ${t('COMMON.CONTRACTS')}`}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            {quotesInReview && formatPositiveAmount(quotesAccepted.amount)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <MoreMenu
          options={options}
          handleClickOption={handleClickMore}
          deleteDisabled
        />
        <KamDialog
          open={openEditDialog}
          handleClose={handleCloseEdit}
          onSubmit={handleUpdateKam}
          isEmailValidated={emailValidated}
          kam={{
            name,
            email,
            phone,
            officePhone,
            emailValidated,
            status,
            id
          }}
        />
        <ConfirmationDialog
          open={openEditStatusDialog}
          handleClose={handleCloseEditStatus}
          onSubmit={handleChangeStatus}
          title={
            status === 'ACTIVE'
              ? t('DISTRIBUTORS_VIEW.AGENTS.DISABLE_KAM')
              : t('DISTRIBUTORS_VIEW.AGENTS.ENABLE_KAM')
          }
          description={t(
            'DISTRIBUTORS_VIEW.AGENTS.CHANGE_STATUS_KAM_DESCRIPTION'
          )}
        />
      </TableCell>
    </TableRow>
  );
};

KamRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  emailValidated: PropTypes.bool,
  officePhone: PropTypes.string,
  status: PropTypes.bool,
  quotesInReview: PropTypes.object,
  quotesInDraft: PropTypes.object,
  quotesAccepted: PropTypes.object
};

export default KamRow;
