import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Autocomplete = ({
  isLoading = false,
  label,
  error,
  helperText,
  onBlur,
  name,
  tooltipText,
  inputType = 'string',
  ...props
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <Tooltip title={tooltipText}>
        <MuiAutocomplete
          id="mui-autocomplete"
          clearIcon={null}
          key={name}
          noOptionsText={t('COMMON.NO_OPTIONS')}
          renderOption={renderProps => {
            return (
              <li {...renderProps} key={`${crypto.randomUUID()}`}>
                {renderProps.key}
              </li>
            );
          }}
          {...props}
          renderInput={params => (
            <TextField
              InputProps={{
                autoComplete: 'off'
              }}
              key={name}
              label={isLoading ? `${t(`COMMON.LOADING`)} ${label}` : label}
              error={error}
              helperText={helperText}
              onBlur={onBlur}
              type={inputType}
              name={name}
              {...params}
            />
          )}
        />
      </Tooltip>
    </>
  );
};

export default Autocomplete;

Autocomplete.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  optionsLabel: PropTypes.arrayOf(PropTypes.string),
  inputType: PropTypes.string,
  tooltipText: PropTypes.string
};
