import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import IndustriesRow from './IndustriesRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ industries }) =>
  industries.map(industry => <IndustriesRow key={industry.id} {...industry} />);

const IndustryTable = ({ industries, loading, success, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell sx={{ width: '90%' }} variant="head" key="name">
        {t('COMMON.NAME')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" key="options">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody industries={industries} />, [
    industries
  ]);

  if (success && !loading && !industries.length) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.INDUSTRY_TITLE" />
      </Card>
    );
  }

  return <Table {...rest} tbody={tbody} theads={theads} loading={loading} />;
};

IndustryTable.propTypes = {
  industries: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default IndustryTable;
