export const selectCounselor = state => state.counselors;
export const selectCounselorsList = state => selectCounselor(state).items;
export const selectCounselorsListCount = state => selectCounselor(state).count;
export const selectCounselorsQueryOptions = state => {
  const { options } = selectCounselor(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
