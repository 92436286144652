import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Box, Card, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectContractDetail } from 'src/store/selectors/contractSelectors';
import {
  CLEAR_CONTRACT_OPTIONS,
  loadContractDetail,
  LOAD_CONTRACT_DETAIL,
  CLEAR_CONTRACT_DETAIL
} from 'src/store/actions/contractActions';
import ContracTabs from './ContractsTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const ContractDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const contract = useSelector(selectContractDetail);
  const loading = useSelector(isLoadingSelector([LOAD_CONTRACT_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_CONTRACT_DETAIL.action]));

  useEffect(() => {
    dispatch(loadContractDetail({ id }));
    dispatch({ type: CLEAR_CONTRACT_OPTIONS });
    return dispatch({ type: CLEAR_CONTRACT_DETAIL });
  }, [dispatch, id]);

  return (
    <Page className={classes.root} title={`Contrato ${contract ? id : ''}`}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loading && contract ? (
              <ContracTabs contract={contract} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default ContractDetailView;
