import {
  downloadExportedIncomeResume,
  getIncomeResume
} from 'src/services/incomeResumeService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectIncomeResumeQueryOptions } from '../selectors/incomeResumeSelector';
import { showMessage } from './snackBarActions';

export const LOAD_INCOME_RESUME = asyncActionCreator('LOAD_INCOME_RESUME');
export const DOWNLOAD_INCOME_RESUME = asyncActionCreator(
  'DOWNLOAD_INCOME_RESUME'
);
export const CLEAR_INCOME_RESUME = 'CLEAR_INCOME_RESUME';
export const SAVE_INCOME_RESUME_OPTIONS = 'SAVE_INCOME_RESUME_OPTIONS';
export const CLEAR_INCOME_RESUME_OPTIONS = 'CLEAR_INCOME_RESUME_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectIncomeResumeQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_INCOME_RESUME_OPTIONS });
};

export const loadIncomeResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INCOME_RESUME.start });

    dispatch({
      type: SAVE_INCOME_RESUME_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const incomeResume = await getIncomeResume(reqOptions);

    return dispatch({
      type: LOAD_INCOME_RESUME.success,
      ...incomeResume
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INCOME_RESUME.failure });
  }
};

export const downloadExcelIncomeResume = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOWNLOAD_INCOME_RESUME.start
    });

    dispatch({
      type: SAVE_INCOME_RESUME_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExportedIncomeResume(reqOptions);

    return dispatch({
      type: DOWNLOAD_INCOME_RESUME.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_INCOME_RESUME.failure });
  }
};
