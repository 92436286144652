import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Grid } from '@mui/material';
import Send from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import DetailCard from 'src/components/DetailBox/DetailCard';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';
import selectDocumentType from 'src/utils/selectDocumentType';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import { DocumentStatus } from 'src/utils/constants/common';
import { DocumentType } from 'src/utils/constants/clients';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { LoadingButton } from '@mui/lab';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  GENERATE_KYC_DOCUMENT,
  SEND_KYC_TO_SIGN,
  generateKYCDocument,
  sendKYCToSignAction
} from 'src/store/actions/investorActions';
import { useDispatch, useSelector } from 'react-redux';
import DetailRow from 'src/components/DetailRow';
import ResendSigningEmailButton from 'src/components/ResendSigningEmailButton/ResendSigningEmailButton';
import useStyles from '../../styles';

const DocumentKYC = ({ investor }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const kycLoading = useSelector(
    isLoadingSelector([GENERATE_KYC_DOCUMENT.action, SEND_KYC_TO_SIGN.action])
  );

  const { id } = useParams();

  const handleSendKYCToSign = useCallback(() => {
    dispatch(sendKYCToSignAction(id));
  }, [id]);

  const handleGenerateDocument = useCallback(() => {
    dispatch(generateKYCDocument(id));
  }, [id]);

  const selectActionFromkycSignStatus = kycSignStatus => {
    if (!kycSignStatus) {
      return t('INVESTORS_VIEW.INVESTOR_DETAIL.GENERATE_DOCUMENT');
    }
    return t('INVESTORS_VIEW.INVESTOR_DETAIL.SEND_TO_SIGN');
  };

  const selectDocumentAction = kycStatus => {
    if (!kycStatus) {
      return handleGenerateDocument;
    }
    if (kycStatus === DocumentStatus.DRAFT) {
      return handleSendKYCToSign;
    }
    return null;
  };

  return (
    <Card variant="elevation">
      <DetailCard title={t('COMMON.DOCUMENT')}>
        {investor.kycSignStatus ? (
          <>
            <DetailRow
              name={`${t('COMMON.DOCUMENT')} KYC`}
              value={
                <DocumentLink
                  link={selectDocumentType(
                    DocumentType.KYC,
                    investor.documents
                  )}
                />
              }
            >
              <DocumentsMenu
                investorId={id}
                documentType={DocumentType.KYC}
                isDisabledAdd
                documents={Boolean(
                  selectDocumentType(DocumentType.KYC, investor.documents)
                )}
              />
            </DetailRow>
          </>
        ) : null}

        {investor.kycSignStatus === DocumentStatus.PENDING && (
          <Grid container direction="column" alignItems="stretch" mt={1}>
            <Grid
              item
              className={classes.buttons}
              sx={{
                marginBottom: '0 !important'
              }}
            >
              <ResendSigningEmailButton
                sx={{ width: '100%' }}
                weetrustId={investor.weetrustDocId}
              />
            </Grid>
          </Grid>
        )}

        {!investor.kycSignStatus ||
        investor.kycSignStatus === DocumentStatus.DRAFT ? (
          <Grid container direction="column" alignItems="stretch">
            <Grid item className={classes.buttons}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={kycLoading}
                loadingPosition="start"
                onClick={selectDocumentAction(investor.kycSignStatus)}
                className={clsx(classes.label, classes.buttons)}
                startIcon={<Send />}
              >
                {selectActionFromkycSignStatus(investor.kycSignStatus)}
              </LoadingButton>
            </Grid>
          </Grid>
        ) : null}
      </DetailCard>
    </Card>
  );
};

DocumentKYC.propTypes = {
  investor: PropTypes.object.isRequired
};

export default DocumentKYC;
