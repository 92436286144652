export const getNufiValidationList = (client, id) => {
  const validationFilter = [];
  validationFilter.push({
    clientId: id,
    name: `Client - ${client.fullName}`,
    clientValidation: client.nufiValidation || null,
    nufiScore: client.nufiScore ? client.nufiScore.clientGeneralScore : 0
  });
  if (client.avals.length) {
    client.avals.forEach(aval => {
      validationFilter.push({
        avalId: aval.id,
        name: `Aval - ${aval.fullName}`,
        clientValidation: aval.nufiValidation || null,
        nufiScore: client.nufiScore
          ? client.nufiScore.avalsGeneralScore.find(
              avalScore => avalScore.id === aval.id
            ).score
          : 0
      });
    });
  }
  return validationFilter;
};

const getMoffinCauses = causes => {
  const causesList = [];
  causes.forEach(cause => {
    if (cause) {
      causesList.push({ description: cause });
    }
  });
  return causesList;
};

export const getMoffinCreditProfileScore = (client, id) => {
  return {
    clientId: id,
    name: `Client - ${client.fullName}`,
    creditProfileScore: client.creditProfileValidation
      ? client.creditProfileValidation.moffinScore
      : 0,
    causes:
      client.creditProfileValidation &&
      getMoffinCauses([
        client.creditProfileValidation.moffinScoreCauseOne,
        client.creditProfileValidation.moffinScoreCauseTwo,
        client.creditProfileValidation.moffinScoreCauseThree,
        client.creditProfileValidation.moffinScoreCauseFour
      ]),
    rfc: client.rfc,
    lastUpdate: client.creditProfileValidation
      ? client.creditProfileValidation.lastUpdate
      : '',
    moffinAuthorizationMessageSent:
      client.moffinAuthorizationMessageSent || false,
    moffinScoreStatus: client.creditProfileValidation
      ? client.creditProfileValidation.moffinScoreStatus
      : '',
    documentReport: client.creditProfileValidation?.document || null
  };
};
