export default (values, person) => {
  const personExists = Object.keys(person).length;

  return {
    ...values,
    name: personExists ? person.name : values.name || '',
    maternalName: personExists
      ? person.maternalName
      : values.maternalName || '',
    paternalName: personExists
      ? person.paternalName
      : values.paternalName || '',
    phone: personExists ? person.phone : values.phone || '',
    email: personExists ? person.email : values.email || '',
    industry: values.industry || '',
    denomination: values.denomination || '',
    sex: personExists ? person.sex : values.sex || '',
    existingRfc: !!Object.keys(person).length,
    curp: personExists ? person.curp : values.curp || '',
    identificationNumber: personExists
      ? person.identificationNumber
      : values.identificationNumber || '',
    nationality: personExists ? person.nationality : values.nationality || ''
  };
};
