import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { updateEquipment } from 'src/store/actions/equipmentsActions';
import { useParams } from 'react-router';
import useStyles from '../styles';
import GeneralEquipmentInfo from './GeneralEquipmentInfo';
import GeneralDetailDialog from './GeneralDetailDialog';

const GeneralClient = ({ equipment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateEquipment(id, values, () => handleClose()));
    },
    [equipment, dispatch]
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.GENERAL_EQUIPMENT_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <GeneralEquipmentInfo equipment={equipment} />
          </TableBody>
        </Table>

        <GeneralDetailDialog
          open={open}
          handleClose={handleClose}
          equipment={equipment}
          submit={handleSubmit}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

GeneralClient.propTypes = {
  equipment: PropTypes.object.isRequired
};

export default GeneralClient;
