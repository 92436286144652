import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { adminRoutes, superAdminUserRoutes } from 'src/routes/adminNavigation';
import Logo from 'src/components/Logo';
import IconLogo from 'src/components/IconLogo';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthRole,
  selectAuthStatus,
  selectAuthSystem
} from 'src/store/selectors/authSelectors';
import UserType, { UserRoles } from 'src/utils/constants/auth';
import getUserNavigationRoutes from 'src/routes/userNavigation';
import { selectBillingFailuresCount } from 'src/store/selectors/billingSelector';
import { loadBillingFailures } from 'src/store/actions/billingActions';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {
  getLocalStorage,
  localStorageKey,
  setLocalStorage
} from 'src/utils/localStorage';
import navBarOptions from 'src/utils/constants/navBar';
import Menu from './Menu';
import EditNavBarOptionsDialog from './EditNavBarOptionsDialog';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: `${theme.spacing(36)}`,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: `${theme.spacing(36)}`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(13)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.default
  },
  container: {
    flexGrow: 1,
    backgroundColor: 'inherit',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginTop: theme.spacing(5)
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: 30,
    marginRight: 60
  }
}));

const NavBar = ({ open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const systemRole = useSelector(selectAuthSystem);
  const role = useSelector(selectAuthRole);
  const status = useSelector(selectAuthStatus);
  const billingFailuresCount = useSelector(selectBillingFailuresCount);
  const [openOptionSelected, setOpenOptionSelected] = useState(false);
  const adminDefaultOptions = Object.values(navBarOptions.ADMIN);
  const navbarOptions =
    getLocalStorage(localStorageKey.NAVBAR_OPTIONS) || adminDefaultOptions;

  const getDistributorUserRoutes = useCallback(
    () => getUserNavigationRoutes(role, status),
    [role, status]
  );

  const handleOpenOptionSelected = () => {
    setOpenOptionSelected(!openOptionSelected);
  };

  const handleSubmitNavBarOptions = values => {
    setLocalStorage(localStorageKey.NAVBAR_OPTIONS, values.options);
    setOpenOptionSelected();
  };

  useEffect(() => {
    if (systemRole === UserType.ADMIN) {
      dispatch(loadBillingFailures());
    }
  }, []);

  const routesWithBadgeToAdmin = {
    '/billing/failures': billingFailuresCount
  };

  const content = (
    <Box className={classes.root}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2
        }}
      >
        <RouterLink className={classes.logo} to="/">
          {open ? <Logo /> : <IconLogo />}
        </RouterLink>
        {(role === UserRoles.SUPER_ADMIN || role === UserRoles.ADMIN) && (
          <IconButton onClick={handleOpenOptionSelected}>
            <AppRegistrationIcon
              sx={{
                color: 'white'
              }}
            />
          </IconButton>
        )}
        <EditNavBarOptionsDialog
          open={openOptionSelected}
          handleClose={handleOpenOptionSelected}
          submit={handleSubmitNavBarOptions}
        />
      </Box>

      <Box className={classes.container}>
        {systemRole === UserType.ADMIN &&
          (role === UserRoles.SUPER_ADMIN
            ? superAdminUserRoutes
                .filter(item =>
                  navbarOptions.includes(item.title.split('.')[1])
                )
                .map(item => {
                  return (
                    <Menu
                      item={item}
                      key={item.title}
                      openDrawer={open}
                      routesWithBadge={routesWithBadgeToAdmin}
                    />
                  );
                })
            : adminRoutes
                .filter(item =>
                  navbarOptions.includes(item.title.split('.')[1])
                )
                .map(item => {
                  return (
                    <Menu
                      item={item}
                      key={item.title}
                      openDrawer={open}
                      routesWithBadge={routesWithBadgeToAdmin}
                    />
                  );
                }))}

        {systemRole === UserType.DISTRIBUTOR &&
          getDistributorUserRoutes().map(item => (
            <Menu
              item={item}
              key={item.title}
              openDrawer={open}
              routesWithBadge={{}}
            />
          ))}
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool
};

export default NavBar;
