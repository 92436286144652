import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import {
  SEARCH_INVESTOR,
  searchInvestor
} from 'src/store/actions/investorActions';
import PhoneInput from 'src/components/PhoneInput';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';

const StepTwo = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loading = useSelector(isLoadingSelector([SEARCH_INVESTOR.action]));

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  useEffect(() => {
    if (data.rfc && !data.name && !data.paternalName && !data.maternalName) {
      dispatch(searchInvestor(data.rfc, handleBack));
    }
  }, [data, dispatch]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={onNext}
          enableReinitialize
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.paternalName && errors.paternalName)}
                  fullWidth
                  helperText={touched.paternalName && t(errors.paternalName)}
                  label={t('COMMON.PATERNAL_NAME')}
                  margin="normal"
                  name="paternalName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.paternalName}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.maternalName && errors.maternalName)}
                  fullWidth
                  helperText={touched.maternalName && t(errors.maternalName)}
                  label={t('COMMON.MATERNAL_NAME')}
                  margin="normal"
                  name="maternalName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.maternalName}
                  variant="outlined"
                />

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    label={t('COMMON.PHONE')}
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  label={t('COMMON.EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <Box
                  className={classes.box}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    className={classes.buttons}
                    onClick={() => handleBack()}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.NEXT')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};
export default StepTwo;
