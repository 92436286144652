import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import UserCLientToolbar from './UserClientToolbar';
import UserClientsList from './UserClientList';

const UserClientListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.indexRoot} title={t('USER_NAVIGATION.MY_CLIENTS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <UserCLientToolbar />
          <Box mt={3}>
            <UserClientsList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default UserClientListView;
