/* eslint-disable import/prefer-default-export */
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getMetrics = serviceBase({
  request: options =>
    axiosClient.get('/distributor/metrics', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const downloadExportedMetrics = serviceBase({
  request: options =>
    axiosClient.get('/distributor/export/metrics', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Metricas.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const getBusinessResume = serviceBase({
  request: options =>
    axiosClient.get('/metrics/quotes', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const downloadBusinessResume = serviceBase({
  request: options =>
    axiosClient.get('/metrics/quotes/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Resumen de Metricas.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});
