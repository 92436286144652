import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_KINSHIPS, loadKinships } from 'src/store/actions/catalogActions';
import { selectKinshipsList } from 'src/store/selectors/catalogSelectors';
import KinshipTable from './KinshipTable';

const KinshipList = () => {
  const dispatch = useDispatch();
  const kinships = useSelector(selectKinshipsList);
  const loading = useSelector(isLoadingSelector([LOAD_KINSHIPS.action]));
  const success = useSelector(successSelector([LOAD_KINSHIPS.action]));

  useEffect(() => {
    dispatch(loadKinships());
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <KinshipTable
            kinships={kinships}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KinshipList;
