export const EquipmentStatus = {
  ACTIVE: 'ACTIVE',
  SOLD: 'SOLD'
};

export const AssigantionStatus = {
  ASSIGNED: 'ASSIGNED',
  PENDING: 'PENDING',
  AVAILABLE: 'AVAILABLE'
};

export const EquipmentMode = {
  FINANCIAL_LEASE: 'FINANCIAL_LEASE',
  PURE_LEASE: 'PURE_LEASE',
  FACTORING: 'FACTORING'
};
