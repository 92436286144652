import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableSortLabel, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import MetricsRow from './MetricsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ metrics }) =>
  metrics.map(metric => <MetricsRow key={metric.id} {...metric} />);

const MetricsTable = ({
  className,
  metrics,
  count,
  page,
  handleChangePage,
  loading,
  onSortChange,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('amountQuotesInReview');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isDesc = property === orderBy && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
      onSortChange(property, isDesc ? 'asc' : 'desc');
    },
    [order, orderBy, setOrder, setOrderBy, onSortChange]
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort]
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="id">
          ID
        </TableCell>
        <TableCell variant="head" key="name">
          {t('COMMON.NAME')}
        </TableCell>

        <TableCell
          variant="head"
          key="quotes_in_review"
          align="center"
          sortDirection={orderBy === 'amountQuotesInReview' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'amountQuotesInReview'}
            direction={orderBy === 'amountQuotesInReview' ? order : 'desc'}
            onClick={createSortHandler('amountQuotesInReview')}
            hideSortIcon={orderBy !== 'amountQuotesInReview'}
          >
            <Typography variant="head" color="textSecondary" noWrap>
              {t('BUSINESS_VIEW.METRICS.IN_REVIEW')}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell
          variant="head"
          key="quotes_in_draft"
          align="center"
          sortDirection={orderBy === 'amountQuotesInDraft' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'amountQuotesInDraft'}
            direction={orderBy === 'amountQuotesInDraft' ? order : 'desc'}
            onClick={createSortHandler('amountQuotesInDraft')}
            hideSortIcon={orderBy !== 'amountQuotesInDraft'}
          >
            <Typography variant="head" color="textSecondary" noWrap>
              {t('BUSINESS_VIEW.METRICS.IN_DRAFT')}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell variant="head" key="quotes-accepted" align="center">
          {t('BUSINESS_VIEW.METRICS.ACCEPTED')}
        </TableCell>
        <TableCell variant="head" key="quotes_rejected" align="center">
          {t('BUSINESS_VIEW.METRICS.REJECTED')}
        </TableCell>
        <TableCell variant="head" key="quotes_archived" align="center">
          {t('BUSINESS_VIEW.METRICS.ARCHIVED')}
        </TableCell>
      </TableRow>
    ),
    [classes, t, order, orderBy, createSortHandler]
  );

  const tbody = useMemo(() => () => <Tbody metrics={metrics} />, [metrics]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, metrics]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

MetricsTable.propTypes = {
  className: PropTypes.string,
  metrics: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  onSortChange: PropTypes.func
};

MetricsTable.defaultProps = {
  count: 0
};

export default MetricsTable;
