import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { getClientFullName } from 'src/utils/common';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableCell
          align="center"
          colSpan={2}
          className={classes.reviewDataSubtitle}
        >
          {t('QUOTES_VIEW.QUOTE_DATA')}
        </TableCell>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.CLIENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {getClientFullName(values.client)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EQUIPMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${values.distributorEquipment.brand.name} - ${values.distributorEquipment.model}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.PRICE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`$${formatPositiveAmount(values.price)}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INITIAL_PAYMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`$${formatPositiveAmount(values.initialPayment)}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.DAYS_FOR_FIRST_RENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${translateGracePeriodValueToDaysForFirstRent(
                values.gracePeriod,
                t
              )}` || t('COMMON.NO_DATA')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentInsuranceIncluded
                ? t('COMMON.YES')
                : t('COMMON.NO')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
