import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_BUSINESS_METRICS,
  loadBusinessMetrics
} from 'src/store/actions/businessActions';
import {
  selectBusinessMetricsCount,
  selectBusinessMetricsList,
  selectBusinessQueryOptions
} from 'src/store/selectors/businessSelectors';
import MetricsTable from './MetricsTable';

const MetricsList = () => {
  const dispatch = useDispatch();
  const metrics = useSelector(selectBusinessMetricsList);
  const count = useSelector(selectBusinessMetricsCount);
  const { page } = useSelector(selectBusinessQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_BUSINESS_METRICS.action])
  );
  const success = useSelector(successSelector([LOAD_BUSINESS_METRICS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadBusinessMetrics({ page: value }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadBusinessMetrics({ sort: { property, order } }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.METRICS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.METRICS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <MetricsTable
      metrics={metrics}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
      onSortChange={handleSortChange}
    />
  );
};

export default MetricsList;
