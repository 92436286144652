import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteKinshipAction,
  editKinshipAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import KinshipForm from './KinshipForm';

const KinshipRow = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openKinshipForm, setOpenKinshipForm] = useState(false);
  const [openKinshipDelete, setOpenKinshipDelete] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenKinshipDelete(false);
  };

  const handleCloseModelForm = () => {
    setOpenKinshipForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenKinshipForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenKinshipDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteKinshipAction(id, handleCloseDeleteModal));
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editKinshipAction(values, handleCloseModelForm));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <KinshipForm
        data={{ name, id }}
        open={openKinshipForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openKinshipDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.KINSHIPS.DELETE_KINSHIP_TITLE')}
        description={t('CATALOGS_VIEW.KINSHIPS.DELETE_KINSHIP_DESCRIPTION')}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

KinshipRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default KinshipRow;
