import React from 'react';
import { Box, Grid } from '@mui/material';
import ContactsList from './ContactsList/ContactsList';

const ContactsView = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContactsList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactsView;
