import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_CATALOG,
  loadBillingConceptsList,
  LOAD_BILLING_CONCEPT
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotFoundRecords from 'src/components/NotFoundRecords';
import BillingConceptTable from './BillingConceptTable';

const BillingConceptList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const billingConcepts = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_BILLING_CONCEPT.action]));
  const success = useSelector(successSelector([LOAD_BILLING_CONCEPT.action]));

  useEffect(() => {
    dispatch(loadBillingConceptsList());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []);

  if (success && !loading && billingConcepts.length === 0)
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.BILLING_CONCEPTS_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.BILLING_CONCEPTS_DESCRIPTION'
          )}
        />
      </Card>
    );

  return (
    <BillingConceptTable billingConcepts={billingConcepts} loading={loading} />
  );
};

export default BillingConceptList;
