import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_AVAL } from 'src/store/actions/clientActions';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import FinalStep from './FinalStep';

const CurrentStep = ({
  activeStep,
  setStep,
  data,
  onNext,
  onBack,
  onSubmit
}) => {
  const loading = useSelector(isLoadingSelector([CREATE_AVAL.action]));

  if (activeStep === 0) {
    return <StepOne data={data} handleNext={onNext} handleBack={onBack} />;
  }

  if (activeStep === 1) {
    return (
      <StepTwo
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        activeStep={activeStep}
        setStep={setStep}
      />
    );
  }

  return (
    <FinalStep
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CurrentStep;
