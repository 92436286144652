import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'src/components/Accordion/Accordion';
import { Box, Paper, Typography } from '@mui/material';
import filterNufiParameters from 'src/utils/filterNufiParams';
import { NufiScoreSection } from 'src/utils/constants/parameters';
import { useTranslation } from 'react-i18next';
import NufiParameterTable from './NufiParameterTable';

const NufiParameterList = ({ parameters, loading }) => {
  const { t } = useTranslation();
  const openFirstAcorddion = true;
  const validateTheJudicialRecords = true;

  const {
    sectionValue: valueOfValidateIdentityForPersonPhysical,
    filterList: listOfValidateIdentityForPersonPhysical
  } = filterNufiParameters(parameters, NufiScoreSection.VALIDATE_IDENTITY);

  const {
    sectionValue: valueOfCriminalRecordForPersonPhysical,
    filterList: listOfCriminalRecordForPersonPhysical
  } = filterNufiParameters(parameters, NufiScoreSection.CRIMINAL_RECORDS);

  const {
    sectionValue: valueOfValidateContactInformationForPersonPhysical,
    filterList: listOfValidateContactInformationForPersonPhysical
  } = filterNufiParameters(
    parameters,
    NufiScoreSection.VALIDATE_CONTACT_INFORMATION
  );

  // Disabled momentarily
  // const {
  //   sectionValue: valueOfValidateIdentificationForPersonPhysical,
  //   filterList: listOfValidateIdentificationForPersonPhysical
  // } = filterNufiParameters(
  //   parameters,
  //   NufiScoreSection.VALIDATE_IDENTIFICATION
  // );

  const {
    sectionValue: valueOfValidateIdentityForPersonLegal,
    filterList: listOfValidateIdentityForPersonLegal
  } = filterNufiParameters(
    parameters,
    NufiScoreSection.VALIDATE_IDENTITY_FOR_PERSON_LEGAL
  );

  const { filterList: listOfLegalPersonJudicialRecod } = filterNufiParameters(
    parameters,
    NufiScoreSection.VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE
  );

  const parameterList = {
    PHYSICAL_PERSON_PARAMETERS: [
      {
        title: 'PARAMETERS_VIEW.NUFI_PARAMETER.VALIDATE_IDENTITY',
        subtitle: `${t(
          'PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE'
        )} ${valueOfValidateIdentityForPersonPhysical}`,
        content: (
          <NufiParameterTable
            parameters={listOfValidateIdentityForPersonPhysical}
            loading={loading}
          />
        )
      },
      {
        title: 'PARAMETERS_VIEW.NUFI_PARAMETER.CRIMINAL_RECORDS',
        subtitle: `${t(
          'PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE'
        )} ${valueOfCriminalRecordForPersonPhysical}`,
        content: (
          <NufiParameterTable
            parameters={listOfCriminalRecordForPersonPhysical}
            loading={loading}
          />
        )
      },
      {
        title: 'PARAMETERS_VIEW.NUFI_PARAMETER.VALIDATE_CONTACT_INFORMATION',
        subtitle: `${t(
          'PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE'
        )} ${valueOfValidateContactInformationForPersonPhysical}`,
        content: (
          <NufiParameterTable
            parameters={listOfValidateContactInformationForPersonPhysical}
            loading={loading}
          />
        )
      }
      // Disabled momentarily
      // {
      //   title: 'PARAMETERS_VIEW.NUFI_PARAMETER.VALIDATE_IDENTIFICATION',
      //   subtitle: `${t(
      //     'PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE'
      //   )}${valueOfValidateIdentificationForPersonPhysical}`,
      //   content: (
      //     <NufiParameterTable
      //       parameters={listOfValidateIdentificationForPersonPhysical}
      //       loading={loading}
      //     />
      //   )
      // }
    ],
    LEGAL_PERSON_PARAMETERS: [
      {
        title: 'PARAMETERS_VIEW.NUFI_PARAMETER.VALIDATE_IDENTITY',
        subtitle: `${t(
          'PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE'
        )} ${valueOfValidateIdentityForPersonLegal}`,
        content: (
          <NufiParameterTable
            parameters={listOfValidateIdentityForPersonLegal}
            loading={loading}
          />
        )
      },
      {
        title: 'PARAMETERS_VIEW.NUFI_PARAMETER.CRIMINAL_RECORDS',
        subtitle: `${t('PARAMETERS_VIEW.NUFI_PARAMETER.BLOCK_VALUE')} ${100}`,
        content: (
          <NufiParameterTable
            parameters={listOfLegalPersonJudicialRecod}
            loading={loading}
            validateTheJudicialRecords={validateTheJudicialRecords}
          />
        )
      }
    ]
  };

  return (
    <Box>
      <Paper
        component="p"
        sx={{
          overflow: 'scroll',
          wordWrap: 'break-word',
          boxShadow: 2,
          margin: '1rem'
        }}
      >
        <Typography
          sx={{
            width: '80%',
            flexShrink: 0,
            color: 'text.primary',
            fontSize: '1.0rem',
            margin: '1rem'
          }}
          component="pre"
        >
          {t('PARAMETERS_VIEW.NUFI_PARAMETER.NUFI_PARAMETER_DESCRIPTION')}
        </Typography>
      </Paper>
      <Typography sx={{ margin: '1rem' }} variant="h5">
        {t('PARAMETERS_VIEW.NUFI_PARAMETER.PHYSICAL_PERSON_PARAMETERS')}
      </Typography>
      <Accordion
        list={parameterList.PHYSICAL_PERSON_PARAMETERS}
        openFirst={openFirstAcorddion}
      />
      <Typography sx={{ margin: '1rem' }} variant="h5">
        {t('PARAMETERS_VIEW.NUFI_PARAMETER.LEGAL_PERSON_PARAMETERS')}
      </Typography>
      <Accordion
        list={parameterList.LEGAL_PERSON_PARAMETERS}
        openFirst={openFirstAcorddion}
      />
    </Box>
  );
};

NufiParameterList.propTypes = {
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default NufiParameterList;
