import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPointsPhone } from 'src/utils/common';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CommonActions } from 'src/utils/constants/clients';
import { AgentStatus } from 'src/utils/constants/distributors';
import ChangeStatus from '@mui/icons-material/ImportExport';
import ReSendIcon from '@mui/icons-material/Autorenew';
import { updateDistributorAgent } from 'src/store/actions/distributorActions';
import { useTranslation } from 'react-i18next';
import { resendValidationEmail } from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import AgentDialog from './AgentDialog';
import ChangeAgentStatusDialog from './ChangeAgentStatusDialog/ChangeAgentStatusDialog';

const AgentMenu = (status, emailValidated) => {
  const agentStatus =
    status === AgentStatus.ACTIVE
      ? 'DISTRIBUTORS_VIEW.AGENTS.DISABLE_KAM'
      : 'DISTRIBUTORS_VIEW.AGENTS.ENABLE_KAM';

  return [
    {
      name: CommonActions.EDIT,
      label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.EDIT}`
    },
    {
      name: CommonActions.CHANGE_STATUS,
      label: agentStatus,
      icon: <ChangeStatus />
    },
    {
      name: CommonActions.RESEND_VALIDATION,
      label: 'ENUMS.ADMINISTRATIVE_ACTIONS.RESEND_VALIDATION',
      icon: <ReSendIcon />,
      disabled: emailValidated
    }
  ];
};

const AgentRow = ({
  name,
  emailValidated,
  email,
  phone,
  officePhone,
  status,
  id,
  role,
  quotesInReview,
  quotesAccepted
}) => {
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditStatusDialog, setOpenEditStatusDialog] = useState(false);
  const { id: distributorId } = useParams();
  const dispatch = useDispatch();
  const options = AgentMenu(status, emailValidated);
  const enableEditRole = true;

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatusDialog(false);
  };

  const handleUpdateContact = useCallback(
    values => {
      dispatch(updateDistributorAgent(distributorId, id, values));
      handleCloseEdit();
    },
    [dispatch, id]
  );

  const handleChangeStatus = useCallback(values => {
    dispatch(updateDistributorAgent(distributorId, id, values));
    handleCloseEditStatus();
  });

  const handleResendValidation = useCallback(() => {
    dispatch(resendValidationEmail(id));
  }, [id]);

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.CHANGE_STATUS) {
      setOpenEditStatusDialog(true);
    }
    if (option === CommonActions.RESEND_VALIDATION) {
      handleResendValidation();
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/distributor-profile/${id}`}>{name}</Link>
      </TableCell>
      <TableCell>{addPointsPhone(phone || '')}</TableCell>
      <TableCell>{addPointsPhone(officePhone || '')}</TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">{t(`COMMON.${role}`)}</TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            emailValidated
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            emailValidated
              ? 'ENUMS.VERIFICATION_STATUS.VERIFIED'
              : 'ENUMS.VERIFICATION_STATUS.UNVERIFIED'
          }
        />
      </TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            status === AgentStatus.ACTIVE
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            status === AgentStatus.ACTIVE
              ? 'ENUMS.STATUS.ACTIVE'
              : 'ENUMS.STATUS.INACTIVE'
          }
        />
      </TableCell>
      <TableCell align="center">
        {quotesInReview && (
          <TooltipTypography
            title={`${quotesInReview.count} ${t('COMMON.REQUESTS')}`}
            text={formatPositiveAmount(quotesInReview.amount)}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {quotesAccepted && (
          <TooltipTypography
            title={`${quotesAccepted.count} ${t('COMMON.CONTRACTS')}`}
            text={formatPositiveAmount(quotesAccepted.amount)}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <MoreMenu
          options={options}
          handleClickOption={handleClickMore}
          deleteDisabled
        />
        <AgentDialog
          open={openEditDialog}
          handleClose={handleCloseEdit}
          onSubmit={handleUpdateContact}
          enableEditRole={enableEditRole}
          isEmailValidated={emailValidated}
          agent={{
            name,
            email,
            phone,
            officePhone,
            emailValidated,
            status,
            role,
            id
          }}
        />
        <ChangeAgentStatusDialog
          open={openEditStatusDialog}
          handleClose={handleCloseEditStatus}
          onSubmit={handleChangeStatus}
          status={status}
        />
      </TableCell>
    </TableRow>
  );
};

AgentRow.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  emailValidated: PropTypes.bool,
  officePhone: PropTypes.string,
  status: PropTypes.bool,
  id: PropTypes.string,
  role: PropTypes.string,
  quotesInReview: PropTypes.object,
  quotesAccepted: PropTypes.object
};

export default AgentRow;
