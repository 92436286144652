import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import Page from 'src/components/Page';
import FadeIn from 'react-fade-in';
import clsx from 'clsx';
import QuotesAdminToolbar from './QuotesAdminToolbar';
import useStyles from './styles';
import QuotesListTabs from './QuotesListTabs';

const QuotesAdminView = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Page className={clsx(classes.root)} title={t('COMMON.QUOTES')}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <QuotesAdminToolbar />
              <QuotesListTabs />
            </Box>
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default QuotesAdminView;
