import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import { IconStatusAndColor } from 'src/utils/constants/common';
import { addPointsPhone } from 'src/utils/common';
import OverflowTipography from 'src/components/OverflowTipography';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const getStatusIcon = isLegalRepresentative => {
  if (isLegalRepresentative) {
    return (
      <StatusIcon
        description="COMMON.LEGAL_REPRESENTATIVE"
        type={IconStatusAndColor.OK.status}
      />
    );
  }

  return (
    <StatusIcon
      description="COMMON.SOLIDARY_OBLIGEE"
      type={IconStatusAndColor.ERROR.status}
    />
  );
};

const AvalRow = ({ id, name, email, phone, isLegalRepresentative }) => {
  const isLegalRepresentativeIcon = useMemo(
    () => getStatusIcon(isLegalRepresentative),
    [isLegalRepresentative]
  );

  const { pathname } = useLocation();

  return (
    <TableRow hover>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <OverflowTipography title={name} maxWidth="250px">
          <Link style={{ textDecoration: 'none' }} to={`${pathname}/${id}`}>
            {name}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center" width="25%">
        {email}
      </TableCell>
      <TableCell align="center" width="25%">
        {addPointsPhone(phone)}
      </TableCell>
      <TableCell align="center" width="25%">
        {isLegalRepresentativeIcon}
      </TableCell>
    </TableRow>
  );
};

AvalRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  isLegalRepresentative: PropTypes.bool
};

export default AvalRow;
