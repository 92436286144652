import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import CFDIExpenseRow from './CFDIExpenseRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ cfdis }) =>
  cfdis.map(cfdi => <CFDIExpenseRow key={cfdi.id} {...cfdi} />);

const CFDIExpenseTable = ({
  className,
  cfdis,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell align="start">{t('COMMON.DATE')}</TableCell>
      <TableCell align="center">{t('COMMON.CREDIT_NOTE')}</TableCell>
      <TableCell align="center">{t('CFDI_VIEWS.EXPENSE_TOTAL')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody cfdis={cfdis} />, [cfdis]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, cfdis]
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CFDIExpenseTable.propTypes = {
  className: PropTypes.string,
  cfdis: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

CFDIExpenseTable.defaultProps = {
  count: 0
};

export default CFDIExpenseTable;
