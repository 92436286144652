import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectRatingValidation } from 'src/store/selectors/clientSelectors';
import {
  LOAD_CLIENT_RATING_VALIDATION,
  loadClientRatingValidation
} from 'src/store/actions/clientActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import Preloader from 'src/components/Preloader';
import useStyles from '../styles';
import ContractDateDetail from './ClientRateView';

const RateDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [lineOfBusinessUpdated, setLineOfBusinessUpdated] = useState(false);

  useEffect(() => {
    dispatch(loadClientRatingValidation(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (lineOfBusinessUpdated) {
      dispatch(loadClientRatingValidation(id));
      setLineOfBusinessUpdated(false);
    }
  }, [lineOfBusinessUpdated, dispatch, id]);

  const clientRatingValidation = useSelector(selectRatingValidation);
  const loading = useSelector(
    isLoadingSelector([LOAD_CLIENT_RATING_VALIDATION.action])
  );
  const success = useSelector(
    successSelector([LOAD_CLIENT_RATING_VALIDATION.action])
  );

  useEffect(() => {
    dispatch(loadClientRatingValidation(id));
  }, [id, dispatch]);

  return (
    <Box mt={5}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.RATING')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card variant="elevation" sx={{ maxWidth: '100%' }}>
            <CardHeader
              className={classes.header}
              title={
                <Typography
                  className={classes.tableCell}
                  component="h4"
                  variant="h4"
                >
                  {t('COMMON.RATING')}
                </Typography>
              }
            />
            <Divider className={classes.tableCell} />
            {loading && !success ? (
              <CardContent style={{ padding: '16px', margin: '16px' }}>
                <Table>
                  <TableBody>
                    <Preloader style={{ height: '38vh' }} />
                  </TableBody>
                </Table>
              </CardContent>
            ) : (
              success && (
                <CardContent style={{ padding: 0, marginBottom: '16px' }}>
                  <Table>
                    <TableBody>
                      <ContractDateDetail
                        clientRatingValidation={clientRatingValidation}
                      />
                    </TableBody>
                  </Table>
                </CardContent>
              )
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RateDetail;
