import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem'
        }
      }
    }
  },

  palette: {
    background: {
      default: '#153750',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#5F6162', // Neutral dark 2
      secondary: '#2B2D42',
      hint: '#000000',
      disabled: 'rgba(95,97,98,0.6)'
    },
    primary: {
      dark: '#2B2D42', // Dark
      main: '#153750', // Blue dark
      contrastText: '#FFFFFF' // Neutral white
    },
    secondary: {
      main: '#EE3552', // Pantone red
      edit: '#5C95FF'
    },
    succes: {
      main: '#7cb342'
    },
    notification: {
      unread: 'rgb(237, 247, 237)',
      read: 'rgba(70,70,92,0.1)'
    }
  },
  shadows,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

export default theme;
