import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import { Card, Container, Grid } from '@mui/material';
import {
  LOAD_USER_PROFILE,
  loadUserProfile
} from 'src/store/actions/userProfileActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectUserDetail } from 'src/store/selectors/userProfileSelectors';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import useStyles from './styles';
import GeneralUserInfo from './GeneralUserInfo';
import DistributorUserInfo from './DistributorUserInfo';

const HomeView = () => {
  const classes = useStyles();
  const userProfile = useSelector(selectUserDetail);
  const loading = useSelector(isLoadingSelector([LOAD_USER_PROFILE.action]));
  const success = useSelector(successSelector([LOAD_USER_PROFILE.action]));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile());
  }, []);

  return (
    <Page className={classes.root} title="Inicio">
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          {success && !loading && userProfile ? (
            <Grid container spacing={3} sx={{ height: '80vh' }}>
              <Grid item lg={6} md={6} xs={12} sx={{ height: '100%' }}>
                <GeneralUserInfo user={userProfile} />
              </Grid>
              {userProfile.distributor && (
                <Grid item lg={6} md={6} xs={12} sx={{ height: '100%' }}>
                  <DistributorUserInfo distributor={userProfile.distributor} />
                </Grid>
              )}
            </Grid>
          ) : (
            <Card className={classes.preloaderCard}>
              <Preloader />
            </Card>
          )}
        </Container>
      </FadeIn>
    </Page>
  );
};

export default HomeView;
