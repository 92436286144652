/* eslint-disable import/no-cycle */
import getUserDistributor, {
  getUserDistributorCatalogs,
  getUserDistributorContacts
} from 'src/services/userDistributorService';
import { SnackBarVariants } from 'src/utils/constants/common';
import {
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_CONTACTS,
  SAVE_DISTRIBUTORS_OPTIONS,
  LOAD_KAM_DISTRIBUTOR_PROFILE
} from './distributorActions';
import { showMessage } from './snackBarActions';

const loadUserDistributor = () => async dispatch => {
  try {
    dispatch({
      type: LOAD_KAM_DISTRIBUTOR_PROFILE.start
    });

    const data = await getUserDistributor();

    return dispatch({
      type: LOAD_KAM_DISTRIBUTOR_PROFILE.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_KAM_DISTRIBUTOR_PROFILE.failure });
  }
};

export const loadUserDistributorContactsList = id => async dispatch => {
  try {
    dispatch({ type: LOAD_DISTRIBUTOR_CONTACTS.start });

    const data = await getUserDistributorContacts(id);

    return dispatch({
      type: LOAD_DISTRIBUTOR_CONTACTS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_DISTRIBUTOR_CONTACTS.failure });
  }
};

export const loadAllDistributorEquipmentsCatalog = options => async dispatch => {
  try {
    dispatch({ type: LOAD_DISTRIBUTOR_CATALOGS.start });

    dispatch({
      type: SAVE_DISTRIBUTORS_OPTIONS,
      options
    });

    let catalogs = null;

    catalogs = await getUserDistributorCatalogs({});

    return dispatch({
      type: LOAD_DISTRIBUTOR_CATALOGS.success,
      ...catalogs
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_DISTRIBUTOR_CATALOGS.failure });
  }
};

export default loadUserDistributor;
