import {
  NotificationCategory,
  NotificationType
} from 'src/utils/constants/notifications';
import {
  PersonRole,
  getAvalFullName,
  getClientFullName,
  getInvestorFullName
} from './common';

const buildQuotesNotificationMessage = (notification, t) => {
  const { quote } = notification.content;
  const { fullname } = quote.client;
  const { brand, model } = quote.equipment;

  switch (notification.type) {
    case NotificationType.NEW_CONTRACT_REQUEST: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.NEW_CONTRACT_REQUEST.FIRST_TEXT'
      )} ${fullname} ${t(
        'NOTIFICATIONS_MESSAGE.NEW_CONTRACT_REQUEST.SECOND_TEXT'
      )} ${brand} ${t(
        'NOTIFICATIONS_MESSAGE.NEW_CONTRACT_REQUEST.THIRD_TEXT'
      )} ${model}.`;
    }
    case NotificationType.CONTRACT_REQUEST_APPROVED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_APPROVED.FIRST_TEXT'
      )} ${fullname} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_APPROVED.SECOND_TEXT'
      )} ${brand} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_APPROVED.THIRD_TEXT'
      )} ${brand} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_APPROVED.FOURTH_TEXT'
      )}`;
    }
    case NotificationType.CONTRACT_REQUEST_REJECTED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_REJECTED.FIRST_TEXT'
      )} ${fullname} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_REJECTED.SECOND_TEXT'
      )} ${brand} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_REJECTED.THIRD_TEXT'
      )} ${model} ${t(
        'NOTIFICATIONS_MESSAGE.CONTRACT_REQUEST_REJECTED.FOURTH_TEXT'
      )}`;
    }

    case NotificationType.QUOTE_ASSIGNED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.QUOTE_ASSIGNED.FIRST_TEXT'
      )} ${fullname} ${t(
        'NOTIFICATIONS_MESSAGE.QUOTE_ASSIGNED.SECOND_TEXT'
      )} ${brand} ${t(
        'NOTIFICATIONS_MESSAGE.QUOTE_ASSIGNED.THIRD_TEXT'
      )} ${model}`;
    }

    default:
      return t('ENUMS.NOTIFICATIONS.DEFAULT_MESSAGE');
  }
};

const buildQuotesToastMessage = (notification, t) => {
  const { quote } = notification.content;
  const { fullname } = quote.client;

  switch (notification.type) {
    case NotificationType.NEW_CONTRACT_REQUEST: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.TOAST_MESSAGE.NEW_CONTRACT_REQUEST'
      )} ${fullname}`;
    }
    case NotificationType.CONTRACT_REQUEST_APPROVED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.TOAST_MESSAGE.CONTRACT_REQUEST_APPROVED'
      )} ${fullname}`;
    }
    case NotificationType.CONTRACT_REQUEST_REJECTED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.TOAST_MESSAGE.CONTRACT_REQUEST_REJECTED'
      )} ${fullname}`;
    }
    case NotificationType.QUOTE_ASSIGNED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.TOAST_MESSAGE.QUOTE_ASSIGNED'
      )} ${fullname}`;
    }
    default:
      return t('ENUMS.NOTIFICATIONS.DEFAULT_MESSAGE');
  }
};

const buildCurpFaildMessage = (notification, t) => {
  const { personType, person } = notification.content;

  const getRoleAndName = () => {
    return `${t(`COMMON.${personType}`)} ${
      personType === 'CLIENT'
        ? getClientFullName(person)
        : getAvalFullName(person)
    }`;
  };

  return `${t('NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_CURP_FAILED.FIRST_TEXT')} 
      ${getRoleAndName()}
      ${t('NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_CURP_FAILED.SECOND_TEXT')} ${
    person.id
  }
      ${t('NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_CURP_FAILED.THIRD_TEXT')} 
        `;
};

const buildNufiNotificationMessage = (notification, t) => {
  const { validation } = notification.content;

  if (notification.type === NotificationType.NUFI_VALIDATION_CURP_FAILED) {
    return buildCurpFaildMessage(notification, t);
  }

  const getRoleAndName = () => {
    if (validation.nufiValidation.clientId) {
      return `${t('COMMON.CLIENT')} ${getClientFullName(validation.client)}`;
    }
    if (validation.nufiValidation.avalId) {
      return `${t('COMMON.AVAL')} ${getAvalFullName(validation.aval)}`;
    }
    if (validation.nufiValidation.investorId) {
      return `${t('COMMON.INVESTOR')} ${getInvestorFullName(
        validation.investor
      )}`;
    }
    return t('COMMON.DISTRIBUTOR');
  };

  switch (notification.type) {
    case NotificationType.NUFI_VALIDATION_COMPLETED: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED.FIRST_TEXT'
      )} 
      ${getRoleAndName()}
      ${t(
        'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED.SECOND_TEXT'
      )} ${validation.nufiValidation.clientId ||
        validation.nufiValidation.avalId ||
        validation.nufiValidation.distributorId ||
        validation.nufiValidation.investorId}. 
        ${
          validation.currentError &&
          Object.keys(validation.currentError).length > 0
            ? t('NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED.THIRD_TEXT')
            : ''
        }
        `;
    }
    case NotificationType.NUFI_VALIDATION_COMPLETED_SINGLE: {
      return `${t(
        'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED_SINGLE.FIRST_TEXT'
      )} 
      ${validation.field.toUpperCase()}
      ${t(
        'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED_SINGLE.SECOND_TEXT'
      )}  
      ${getRoleAndName()}
      ${t(
        'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED_SINGLE.THIRD_TEXT'
      )} ${validation.nufiValidation.clientId ||
        validation.nufiValidation.avalId ||
        validation.nufiValidation.distributorId ||
        validation.nufiValidation.investorId}. 
        ${
          validation.currentError
            ? t(
                'NOTIFICATIONS_MESSAGE.NUFI_VALIDATION_COMPLETED_SINGLE.FOURTH_TEXT'
              )
            : ''
        }
        `;
    }
    default:
      return t('ENUMS.NOTIFICATIONS.DEFAULT_MESSAGE');
  }
};

const buildDistrituborProfileRegistrationRequestMessage = (notification, t) => {
  const { distributorProfile } = notification.content;

  return `${t(
    'NOTIFICATIONS_MESSAGE.DISTRIBUTOR_PROFILE_REGISTRATION_REQUEST.FIRST_TEXT'
  )} ${distributorProfile.name} ${t(
    'NOTIFICATIONS_MESSAGE.DISTRIBUTOR_PROFILE_REGISTRATION_REQUEST.SECOND_TEXT'
  )}`;
};

const buildBuroValidationFormCompletedMessage = (notification, t) => {
  const { person } = notification.content;

  const getRoleAndName = () => {
    if (person.personRole === PersonRole.CLIENT) {
      return `${t('COMMON.CLIENT').toLowerCase()} ${person.name}`;
    }
    return `${t('COMMON.AVAL').toLowerCase()} ${person.name}`;
  };

  return `${t('NOTIFICATIONS_MESSAGE.BURO_VALIDATION_FORM_COMPLETED.FIRST_TEXT')} ${getRoleAndName()}`;
};

const buildNotificationMessage = (notification, t) => {
  switch (notification.category) {
    case NotificationCategory.QUOTES:
      return buildQuotesNotificationMessage(notification, t);
    case NotificationCategory.NUFI:
      return buildNufiNotificationMessage(notification, t);
    case NotificationCategory.DISTRIBUTOR_PROFILE_REGISTRATION:
      return buildDistrituborProfileRegistrationRequestMessage(notification, t);
    case NotificationCategory.BURO_VALIDATION:
      return buildBuroValidationFormCompletedMessage(notification, t);
    default:
      return t('ENUMS.NOTIFICATIONS.DEFAULT_MESSAGE');
  }
};

export const buildToastNotificationMessage = (notification, t) => {
  switch (notification.category) {
    case NotificationCategory.QUOTES:
      return buildQuotesToastMessage(notification, t);
    default:
      return t('ENUMS.NOTIFICATIONS.DEFAULT_MESSAGE');
  }
};

export default buildNotificationMessage;
