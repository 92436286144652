import * as Yup from 'yup';

const getValidationSchema = phone => {
  if (phone) {
    return Yup.object().shape({
      term: Yup.string().required('SCHEMA_ERRORS.TERM_REQUIRED'),
      moreInfo: Yup.string(),
      initialPaymentDate: Yup.date()
        .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
        .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      gracePeriod: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      firstRentDate: Yup.date()
        .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
        .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    });
  }
  return Yup.object().shape({
    term: Yup.string().required('SCHEMA_ERRORS.TERM_REQUIRED'),
    moreInfo: Yup.string(),
    initialPaymentDate: Yup.date()
      .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
      .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
    gracePeriod: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
    firstRentDate: Yup.date()
      .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
      .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
    phone: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  });
};

export default getValidationSchema;
