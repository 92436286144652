import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';

const BillingConceptRow = ({ name, code, description }) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell>{code}</TableCell>
      <TableCell>{t(`ENUMS.BILLING_CONCEPT.${name}`)}</TableCell>
      <TableCell>{description}</TableCell>
    </TableRow>
  );
};

BillingConceptRow.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
};

export default BillingConceptRow;
