import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';

import NotFoundRecords from 'src/components/NotFoundRecords';
import NotificationsParameterTable from './NotificationsParameterTable';

const NotificationsParameterList = ({ parameters, loading }) => {
  if (!parameters.length)
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_DESCRIPTION"
        />
      </Card>
    );

  return (
    <Box sx={{ mt: 2 }}>
      <NotificationsParameterTable parameters={parameters} loading={loading} />
    </Box>
  );
};

NotificationsParameterList.propTypes = {
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default NotificationsParameterList;
