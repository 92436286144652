import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Preloader from 'src/components/Preloader';
import ContributionsTable from './ContributionsTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const ContribtutionsList = ({ contributions, loading, success }) => {
  const classes = useStyles();

  return (
    <Box mt={3}>
      {success && !loading && contributions ? (
        <ContributionsTable contributions={contributions} />
      ) : (
        <Card className={classes.preloaderCard}>
          <Preloader />
        </Card>
      )}
    </Box>
  );
};

ContribtutionsList.propTypes = {
  contributions: PropTypes.object,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default ContribtutionsList;
