import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import ContractDetail from './ContractDetail';
import EquipmentAddressDetail from './EquipmentAddressDetail';

const ContractView = ({ equipment }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ContractDetail equipment={equipment} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <EquipmentAddressDetail contract={equipment.contract} />
        </Grid>
      </Grid>
    </Box>
  );
};

ContractView.propTypes = {
  equipment: PropTypes.object
};

export default ContractView;
