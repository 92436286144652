import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { showMessage } from 'src/store/actions/snackBarActions';
import { useTranslation } from 'react-i18next';
import {
  createOrUpdateDocument,
  CREATE_OR_UPDATE_DOCUMENT
} from 'src/store/actions/clientActions';
import theme from 'src/theme';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Dropzone from '../Dropzone';
import Loading from '../Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialValues = () => ({
  fileName: '',
  file: ''
});

const AddDocumentModal = ({
  avalId,
  clientId,
  contractId,
  investorId,
  investmentId,
  promissoryNoteId,
  open,
  handleClose,
  documentType,
  documents
}) => {
  const dispatch = useDispatch();
  const initialValues = makeInitialValues();
  const { t } = useTranslation();
  const loading = useSelector(
    isLoadingSelector([CREATE_OR_UPDATE_DOCUMENT.action])
  );

  const handleOnChangeFileInput = useCallback(
    setFieldValue => files => {
      setFieldValue('file', files[0]);
      setFieldValue('fileName', files[0].name);
    },
    []
  );

  const handleFileReject = useCallback(() => {
    dispatch(
      showMessage({
        message: 'INVALID_FILE_FORMAT',
        variant: 'error'
      })
    );
  }, [dispatch]);

  const handleDropFile = useCallback(
    setFieldValue => () => {
      setFieldValue('file', '');
      setFieldValue('fileName', '');
    },
    []
  );

  const onSubmitDocument = useCallback(
    async values => {
      return dispatch(
        createOrUpdateDocument({
          clientId,
          avalId,
          contractId,
          investorId,
          investmentId,
          promissoryNoteId,
          documentType,
          file: values.file
        })
      );
    },
    [avalId, clientId, contractId, promissoryNoteId, documentType, dispatch]
  );

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '30fr',
          maxHeight: '70vh'
        }
      }}
      open={open === documentType}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>
        <DialogTitle
          sx={{
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            '& h2': {
              fontWeight: 400
            }
          }}
          id="form-documents-dialog-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            sx={{
              float: 'right',
              width: '20px',
              height: '20px'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="primary" variant="h2">
            {documents ? t('COMMON.UPDATE_DOCUMENT') : t('COMMON.ADD_DOCUMENT')}
          </Typography>
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loading />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitDocument}
            enableReinitialize
          >
            {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  {!values.fileName && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '13px',
                          marginBottom: '5px',
                          minWidth: theme.spacing(55)
                        }}
                      >
                        {t('CLIENTS_VIEW.CLIENT_DETAIL.FILE_FORMAT')}
                      </Typography>
                      <Dropzone
                        acceptedFileFormat={{
                          'image/*': ['.jpg', '.jpeg', '.png'],
                          'application/pdf': ['.pdf']
                        }}
                        handleFileChange={handleOnChangeFileInput(
                          setFieldValue
                        )}
                        handleFileReject={handleFileReject}
                      />
                    </>
                  )}
                  {values.fileName && (
                    <Container
                      sx={{
                        border: `1px dashed ${theme.palette.text.secondary}`,
                        padding: '10px 10px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h4">{values.fileName}</Typography>
                      <Button
                        onClick={handleDropFile(setFieldValue)}
                        size="small"
                      >
                        <CloseIcon fontSize="10px" />
                      </Button>
                    </Container>
                  )}
                  <Button
                    disabled={isSubmitting || !values.fileName}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: theme.spacing(2)
                    }}
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

AddDocumentModal.propTypes = {
  avalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  promissoryNoteId: PropTypes.number,
  open: PropTypes.string,
  handleClose: PropTypes.func,
  documentType: PropTypes.string,
  documents: PropTypes.bool,
  investorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  investmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default AddDocumentModal;
