import * as Yup from 'yup';

export default Yup.object().shape({
  zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'SCHEMA_ERRORS.ZIP_CODE_INVALID')
    .required('SCHEMA_ERRORS.ZIP_CODE_REQUIRED'),
  street: Yup.string().required('SCHEMA_ERRORS.STREET_REQUIRED'),
  insideHomeNumber: Yup.string().nullable(),
  externalHomeNumber: Yup.string().required(
    'SCHEMA_ERRORS.EXTERNAL_HOME_NUMBER_REQUIRED'
  ),
  colonia: Yup.string(),
  city: Yup.string().required('SCHEMA_ERRORS.CITY_REQUIRED'),
  state: Yup.string().required('SCHEMA_ERRORS.STATE_REQUIRED')
});
