export default values => ({
  ...values,
  equipmentStreet: values ? values.equipmentStreet ?? '' : '',
  equipmentInsideHomeNumber: values
    ? values.equipmentInsideHomeNumber ?? ''
    : '',
  equipmentExternalHomeNumber: values
    ? values.equipmentExternalHomeNumber ?? ''
    : '',
  equipmentColonia: values ? values.equipmentColonia : '',
  equipmentCity: values ? values.equipmentCity : '',
  equipmentState: values ? values.equipmentState : '',
  equipmentZipCode: values ? values.equipmentZipCode ?? '' : '',
  useClientAddress: values ? values.useClientAddress : false
});
