import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import OverflowTipography from 'src/components/OverflowTipography';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { CFDIListActions } from 'src/utils/constants/cfdi';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { cancelCfdiAction } from 'src/store/actions/cfdiActions';
import { capitalizeName } from 'src/utils/common';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import CancelCFDIDialog from './CancelCFDIDialog/CancelCFDIDialog';
import SendEmailDialog from './SendEmailDialog';
import SendWhatsappDialog from './SendWhatsappDialog';

export const getCFDIListOptions = (document, client) => {
  return [
    {
      name: CFDIListActions.VIEW_PDF,
      label: `ENUMS.CFDI_LIST_ACTIONS.${CFDIListActions.VIEW_PDF}`,
      icon: <InsertDriveFileRoundedIcon />,
      disabled: !document,
      message: 'COMMON.NO_DOCUMENTS_AVAILABLE'
    },
    {
      name: CFDIListActions.SEND_EMAIL,
      label: `ENUMS.CFDI_LIST_ACTIONS.${CFDIListActions.SEND_EMAIL}`,
      disabled: !document || !client,
      message: !document
        ? 'COMMON.NO_DOCUMENTS_AVAILABLE'
        : 'COMMON.NO_CLIENT_ASSOCIATED',
      icon: <SendRoundedIcon />
    },
    {
      name: CFDIListActions.SEND_WHATSAPP,
      label: `ENUMS.CFDI_LIST_ACTIONS.${CFDIListActions.SEND_WHATSAPP}`,
      disabled: !document || !client,
      message: !document
        ? 'COMMON.NO_DOCUMENTS_AVAILABLE'
        : 'COMMON.NO_CLIENT_ASSOCIATED',
      icon: <WhatsappIcon />
    },
    {
      name: CFDIListActions.CANCEL,
      label: `ENUMS.CFDI_LIST_ACTIONS.${CFDIListActions.CANCEL}`,
      message: 'Cancelar factura',
      icon: <CancelIcon />
    }
  ];
};

const CFDIIssuedRow = ({
  id,
  createdAt,
  folio,
  series,
  type,
  amount,
  iva,
  amountIvaIncluded,
  client,
  cancelled,
  document,
  customerInfo,
  uuid
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openOption, setOpenOption] = useState('');

  const handleCancelCdfi = values => {
    dispatch(cancelCfdiAction({ id, ...values }));
  };

  const handleCloseDialog = () => {
    setOpenOption('');
  };

  const handleOpenDialog = option => {
    switch (option) {
      case CFDIListActions.VIEW_PDF:
        setOpenOption(CFDIListActions.VIEW_PDF);
        break;
      case CFDIListActions.SEND_EMAIL:
        setOpenOption(CFDIListActions.SEND_EMAIL);
        break;
      case CFDIListActions.SEND_WHATSAPP:
        setOpenOption(CFDIListActions.SEND_WHATSAPP);
        break;
      case CFDIListActions.CANCEL:
        setOpenOption(CFDIListActions.CANCEL);
        break;
      default:
        break;
    }
  };

  return (
    <TableRow hover align="center">
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="center"
      >
        {selectFormatDate(createdAt, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center">{folio}</TableCell>
      {client && (
        <>
          <TableCell align="left">
            <OverflowTipography title={client.name} maxWidth="100%">
              <Link to={`/clients/detail/${client?.id}`}>{client?.name}</Link>
            </OverflowTipography>
          </TableCell>
          <TableCell align="center">{client?.rfc}</TableCell>
        </>
      )}
      {customerInfo && (
        <>
          <TableCell align="left">
            {capitalizeName(customerInfo.legal_name)}
          </TableCell>
          <TableCell align="center">{customerInfo.tax_id}</TableCell>
        </>
      )}
      <TableCell align="center">{t(`ENUMS.INVOICE_TYPES.${type}`)}</TableCell>
      <TableCell align="center">{series}</TableCell>
      <TableCell align="center">
        {formatAmountWithDecimals(amount, 2)}
      </TableCell>
      <TableCell align="center">{formatAmountWithDecimals(iva, 2)}</TableCell>
      <TableCell align="center">
        {formatAmountWithDecimals(amountIvaIncluded, 2)}
      </TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            cancelled
              ? IconStatusAndColor.ERROR.status
              : IconStatusAndColor.OK.status
          }
          description={cancelled ? 'COMMON.CANCELED' : 'COMMON.ACTIVE'}
        />
      </TableCell>
      <TableCell align="right">
        <MoreMenu
          deleteDisabled
          editDisabled
          key={folio}
          options={getCFDIListOptions(document, client)}
          handleClickOption={handleOpenDialog}
        />
        {openOption === CFDIListActions.VIEW_PDF && document.documentUrl && (
          <DocumentViewerDialog
            documentUrl={document.documentUrl}
            open={openOption === CFDIListActions.VIEW_PDF}
            onClose={handleCloseDialog}
            fileName={`${uuid}`}
          />
        )}
      </TableCell>
      <CancelCFDIDialog
        open={openOption === CFDIListActions.CANCEL}
        onClose={handleCloseDialog}
        onSubmit={handleCancelCdfi}
      />
      <SendEmailDialog
        open={openOption === CFDIListActions.SEND_EMAIL}
        onClose={handleCloseDialog}
        id={id}
        clientId={client?.id}
      />
      <SendWhatsappDialog
        open={openOption === CFDIListActions.SEND_WHATSAPP}
        onClose={handleCloseDialog}
        id={id}
        clientId={client?.id}
      />
    </TableRow>
  );
};

CFDIIssuedRow.propTypes = {
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  folio: PropTypes.string.isRequired,
  series: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  iva: PropTypes.number.isRequired,
  amountIvaIncluded: PropTypes.number.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rfc: PropTypes.string.isRequired
  }),
  customerInfo: PropTypes.shape({
    legal_name: PropTypes.string.isRequired,
    tax_id: PropTypes.string.isRequired
  }),
  document: PropTypes.object,
  cancelled: PropTypes.bool,
  uuid: PropTypes.string
};

export default CFDIIssuedRow;
