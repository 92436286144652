import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadTaxSystemList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TaxSystemDialog = ({ open, handleClose, submit, taxSystem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = makeInitialState(taxSystem);
  const taxSystems = useSelector(selectCatalogList);

  const resetRef = useRef();
  const { t } = useTranslation();

  const handleSubmitBillingData = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit({
        ...difference
      });
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadTaxSystemList());
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.BILLING_DETAIL.EDIT_BILLING_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmitBillingData}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.taxSystem && errors.taxSystem)}
                >
                  <Autocomplete
                    label={t('COMMON.TAX_SYSTEM')}
                    name="taxSystem"
                    options={taxSystems}
                    id="taxSystem"
                    value={values.taxSystem ?? ''}
                    getOptionLabel={option => {
                      return option.id && option.name
                        ? `${option.id} - ${t(
                            `ENUMS.TAX_SYSTEM.${option.name}`
                          )}`
                        : '';
                    }}
                    clearOnBlur={false}
                    fullWidth
                    clearIcon={null}
                    onChange={(_e, value) => {
                      setFieldValue('taxSystem', value ?? '');
                      setFieldValue('taxSystemId', value ? value.id : '');
                    }}
                    helperText={touched.taxSystem && t(errors.taxSystem)}
                    error={Boolean(touched.taxSystem && errors.taxSystem)}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

TaxSystemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  taxSystem: PropTypes.object
};

export default TaxSystemDialog;
