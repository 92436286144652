import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import CompanyRow from './CompanyRow';

const CompaniesTable = ({
  className,
  companies,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit]
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.TAX_ID')}</TableCell>
      <TableCell>{t('COMMON.NAME')}</TableCell>
      <TableCell>{t('COMMON.ACTION')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return companies.map(company => (
        <CompanyRow
          {...company}
          key={company.taxId}
          openEdit={openEdit(company)}
          handleDelete={handleDelete}
        />
      ));
    },
    [companies]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

CompaniesTable.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default CompaniesTable;
