import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const TooltipTypography = ({ title, text }) => {
  return (
    <>
      <Tooltip title={title}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.43'
          }}
        >
          {text}
        </Typography>
      </Tooltip>
    </>
  );
};

TooltipTypography.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default TooltipTypography;
