import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType, PersonType } from 'src/utils/constants/clients';
import { useParams } from 'react-router';
import selectDocumentType from 'src/utils/selectDocumentType';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';

const DocumentClientInfo = ({ client, isDisabledEdit }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const hideIfe = client.documents.some(
    document => document.documentType === DocumentType.PASSPORT
  );

  const hidePassport = client.documents.some(
    document => document.documentType === DocumentType.IFE_FRONT
  );

  const showHidedDocuments = hideIfe && hidePassport;

  return (
    <>
      {client.personType === PersonType.PHYSICAL ? (
        <>
          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.FONT_IFE')}
            sx={{
              display: hideIfe && !showHidedDocuments ? 'none' : 'block'
            }}
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.IFE_FRONT,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              isDisabledAdd={hideIfe}
              documentType={DocumentType.IFE_FRONT}
              clientId={id}
              documents={Boolean(
                selectDocumentType(DocumentType.IFE_FRONT, client.documents)
              )}
            />
          </DetailRow>

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.BACK_IFE')}
            sx={{
              display: hideIfe && !showHidedDocuments ? 'none' : 'block'
            }}
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.IFE_BACK,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              documents={Boolean(
                selectDocumentType(DocumentType.IFE_BACK, client.documents)
              )}
              documentType={DocumentType.IFE_BACK}
              clientId={id}
            />
          </DetailRow>

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.PASSPORT')}
            sx={{
              display: hidePassport && !showHidedDocuments ? 'none' : 'block'
            }}
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.PASSPORT,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              isDisabledAdd={hidePassport}
              documentType={DocumentType.PASSPORT}
              clientId={id}
              documents={Boolean(
                selectDocumentType(DocumentType.PASSPORT, client.documents)
              )}
            />
          </DetailRow>
        </>
      ) : (
        <>
          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.CONSTITUTIVE_ACT')}
            value={
              <DocumentLink
                link={Boolean(
                  selectDocumentType(
                    DocumentType.CONSTITUTIVE_ACT,
                    client.documents
                  )
                )}
              />
            }
          >
            <DocumentsMenu
              documentType={DocumentType.CONSTITUTIVE_ACT}
              clientId={id}
              isDisabledEdit={isDisabledEdit}
              documents={Boolean(
                selectDocumentType(
                  DocumentType.CONSTITUTIVE_ACT,
                  client.documents
                )
              )}
            />
          </DetailRow>

          <DetailRow
            name="RPP"
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.RPP_REGISTER,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              documentType={DocumentType.RPP_REGISTER}
              clientId={id}
              documents={Boolean(
                selectDocumentType(DocumentType.RPP_REGISTER, client.documents)
              )}
            />
          </DetailRow>

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.EEFF_LAST_QUARTER')}
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.EEFF_LAST_QUARTER,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              documentType={DocumentType.EEFF_LAST_QUARTER}
              clientId={id}
              documents={Boolean(
                selectDocumentType(
                  DocumentType.EEFF_LAST_QUARTER,
                  client.documents
                )
              )}
            />
          </DetailRow>

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.EEFF_LAST_PERIOD')}
            value={
              <DocumentLink
                link={selectDocumentType(
                  DocumentType.EEFF_LAST_PERIOD,
                  client.documents
                )}
              />
            }
          >
            <DocumentsMenu
              isDisabledEdit={isDisabledEdit}
              documentType={DocumentType.EEFF_LAST_PERIOD}
              clientId={id}
              documents={Boolean(
                selectDocumentType(
                  DocumentType.EEFF_LAST_PERIOD,
                  client.documents
                )
              )}
            />
          </DetailRow>
        </>
      )}

      <DetailRow
        name={t('ENUMS.DOCUMENTS.ADDRESS_PROOF')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.ADDRESS_PROOF,
              client.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documentType={DocumentType.ADDRESS_PROOF}
          clientId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.ADDRESS_PROOF, client.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.TAX_STATUS_DOC')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.TAX_STATUS_DOC,
              client.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documentType={DocumentType.TAX_STATUS_DOC}
          clientId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.TAX_STATUS_DOC, client.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.LAST_TAX_RETURN')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.LAST_TAX_RETURN,
              client.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documentType={DocumentType.LAST_TAX_RETURN}
          clientId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.LAST_TAX_RETURN, client.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.RFC_COMPLIANCE')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.RFC_COMPLIANCE,
              client.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documentType={DocumentType.RFC_COMPLIANCE}
          clientId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.RFC_COMPLIANCE, client.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.BANK_STATEMENT')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.BANK_STATEMENT,
              client.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.BANK_STATEMENT, client.documents)
          )}
          documentType={DocumentType.BANK_STATEMENT}
          clientId={id}
        />
      </DetailRow>

      <DetailRow
        name={t('COMMON.OTHERS')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.OTHER, client.documents)}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.OTHER, client.documents)
          )}
          documentType={DocumentType.OTHER}
          clientId={id}
        />
      </DetailRow>
    </>
  );
};

DocumentClientInfo.propTypes = {
  client: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default DocumentClientInfo;
