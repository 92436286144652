import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function CircularStepper({
  steps,
  activeStep,
  disableEnumerations = false
}) {
  return (
    <Grid
      container
      sx={{
        width: '100%',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            width: '95px !important',
            height: '95px !important',
            '@media (max-width: 400px)': {
              width: '75px !important',
              height: '75px !important'
            }
          }}
          thickness={4}
          value={100}
        />

        <CircularProgress
          sx={{
            width: '95px !important',
            height: '95px !important',
            '@media (max-width: 400px)': {
              width: '75px !important',
              height: '75px !important'
            },
            position: 'absolute',
            zIndex: 1
          }}
          value={(activeStep / steps.length) * 100}
          variant="determinate"
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2
          }}
        >
          {}
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.5rem'
            }}
          >
            {disableEnumerations ? '' : `${activeStep + 1} / ${steps.length}`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

CircularStepper.propTypes = {
  disableEnumerations: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired
};
