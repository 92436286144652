import {
  CLEAR_AGENTS,
  CLEAR_AGENT_OPTIONS,
  LOAD_AGENTS,
  LOAD_AGENT_DETAIL,
  SAVE_AGENT_OPTIONS
} from '../actions/agentActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  detail: {
    investors: []
  }
};

const agentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AGENT_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_AGENT_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_AGENTS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_AGENT_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.data
        }
      };
    }
    case CLEAR_AGENTS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default agentsReducer;
