import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getNotifications = serviceBase({
  request: options => axiosClient.get('/notifications', { params: options })
});

export const markAsReadNotification = serviceBase({
  request: notificationId =>
    axiosClient.put(`/notifications/mark-as-read/${notificationId}`)
});

export const markAllNotificationsAsViewd = serviceBase({
  request: () => axiosClient.put('/notifications/mark-all-as-viewed')
});

export default getNotifications;
