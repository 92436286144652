import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteLineOfBusinessAction,
  editLineOfBusinessAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import LineOfBusinessForm from './LineOfBusinessForm';

const LineOfBusinessRow = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openLineOfBusinessForm, setOpenLineOfBusinessForm] = useState(false);
  const [openLineOfBusinessDelete, setOpenLineOfBusinessDelete] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenLineOfBusinessDelete(false);
  };

  const handleCloseModelForm = () => {
    setOpenLineOfBusinessForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenLineOfBusinessForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenLineOfBusinessDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteLineOfBusinessAction(id, handleCloseDeleteModal));
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editLineOfBusinessAction(values, handleCloseModelForm));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <LineOfBusinessForm
        data={{ name, id }}
        open={openLineOfBusinessForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openLineOfBusinessDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.LINES_OF_BUSINESS.DELETE_LINE_OF_BUSINESS_TITLE')}
        description={t('CATALOGS_VIEW.LINES_OF_BUSINESS.DELETE_LINE_OF_BUSINESS_DESCRIPTION')}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

LineOfBusinessRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default LineOfBusinessRow;
