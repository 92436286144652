import axios from 'axios';
import { removeEmptyStringFieldsRecursive } from 'src/utils/removeEmptyStringFieldsRecursive';
import axiosClient from './axiosClient';

const defaultResponseTransformation = res => res.data.data;

const selectErrorMessage = (err, customErrorMessage) => {
  try {
    const serverError = err.response.data;

    if (!serverError.message) {
      return customErrorMessage;
    }

    if (serverError.message) {
      if (typeof serverError.message === 'string') {
        return serverError.message;
      }

      if (typeof serverError.message === 'object') {
        if (typeof serverError.message.message === 'string') {
          return serverError.message.message;
        }

        const actualError = serverError.message;
        const [error] = actualError;

        return error;
      }
    }
  } catch (err1) {
    return 'Something went wrong...';
  }
  return 'Something went wrong...';
};

/**
 * Generates a service according to the passed config object
 * @param {{request: (data: any) => Promise<any>; transformResponse?: (response: AxiosResponse<any>) => any; retry?: number; debug?: boolean}} config configuration object to define the service
 * @returns {(data: any) => Promise<any>} service you can call from your thunks
 */
export const serviceBase = ({
  request,
  transformResponse = defaultResponseTransformation,
  retry = 0,
  debug = false
}) => {
  if (!request) {
    throw Error('Please provide a valid request function to serviceBase!');
  }

  return async data => {
    if (data && typeof data === 'object') {
      removeEmptyStringFieldsRecursive(data);
    }

    try {
      if (debug) {
        console.log('Sending request...');
      }

      const response = await request(data);

      if (!response) {
        throw Error('Somehting was wrong...');
      }

      return transformResponse(response);
    } catch (err) {
      if (retry > 0) {
        if (debug) {
          console.log('retrying');
        }

        return serviceBase({
          request,
          transformResponse,
          retry: retry - 1,
          debug
        })(data);
      }
      if (
        err.message.includes('401') &&
        !window.location.pathname.includes('/login')
      ) {
        localStorage.removeItem('sessionToken');
        window.location.replace('https://www.astrocap.com/');
      }
      throw Error(selectErrorMessage(err, data.customErrorMessage));
    }
  };
};

export const getUploadLink = serviceBase({
  request: ({ format, contentType }) =>
    axiosClient.get(`/aws`, { params: { format, contentType } }),
  retry: 0
});

export const uploadFile = serviceBase({
  request: ({ targetURL, file }) =>
    axios.put(targetURL, file, { timeout: 30000 }),
  retry: 0
});
