import { Button as MuiButton } from '@mui/material';
import styled from '@emotion/styled';

const Button = styled(MuiButton)(({ theme }) => ({
  height: 44,
  letterSpacing: 1.25,
  fontSize: 16,
  fontWeight: '500',
  borderRadius: '4px',
  padding: theme.spacing(1),
  minWidth: '100%',
  margin: '0 auto',
  marginBottom: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    fontSize: 11
  },
  borderColor: '#bdbdbd',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,

  '&:hover': {
    borderColor: '#FFFFFF',
    background: theme.palette.primary.dark,
    opacity: 0.8
  },
  '&:focus': {
    borderColor: '#FFFFFF',
    background: theme.palette.primary.dark,
    opacity: 0.8
  },
  '&:disabled': {
    borderColor: '#FFFFFF',
    background: theme.palette.text.disabled,
    opacity: 0.8,
    color: theme.palette.text.disabled
  }
}));

export default Button;
