/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  UPDATE_AVALS_LIST,
  getClientAvals,
  loadClientDetail
} from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { selectClientAvalsList } from 'src/store/selectors/clientSelectors';
import { PersonType } from 'src/utils/constants/clients';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import AvalsAlert from './AvalsAlert';

const StepTwo = ({ data, handleNext, open, resetRef, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const avalList = useSelector(selectClientAvalsList);
  const legalRepresentativeAval = avalList.find(
    aval => aval.isLegalRepresentative
  );
  const initialState = useMemo(
    () => makeInitialState(data, legalRepresentativeAval),
    [data, legalRepresentativeAval]
  );
  const loadingAvals = useSelector(
    isLoadingSelector([UPDATE_AVALS_LIST.action])
  );
  const maxAvalAbility = data.clientPersonType === PersonType.LEGAL ? 4 : 5;
  const avalIndex = data.clientPersonType === PersonType.LEGAL ? 2 : 1;

  const [avalCount, setAvalCount] = React.useState(1);

  const incrementAvalCount = () => {
    setAvalCount(avalCount + 1);
  };

  const hasAvalsWithPendingDocs = avals => {
    if (avals.length > 0) {
      return avals.some(aval => {
        if (aval) {
          return aval.pendingDocs;
        }
        return false;
      });
    }
    return false;
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(getClientAvals(data.clientId, { sendLimit: false }));
    }
  }, [open, data]);

  useEffect(() => {
    if (data) {
      const currentAvalCount = initialState.avals.length;

      const newAvalCount =
        data.clientPersonType === PersonType.LEGAL
          ? Math.max(0, Math.min(4, currentAvalCount - 2))
          : Math.max(0, Math.min(5, Math.floor(currentAvalCount / 2)));

      setAvalCount(newAvalCount);
    }
  }, [data]);

  const onNext = useCallback(
    values => {
      handleNext(values);
      dispatch(loadClientDetail(values.clientId));
    },
    [handleNext]
  );

  const disabledAddAvalButton = useMemo(() => {
    if (data.clientPersonType === PersonType.LEGAL) {
      const minimumAvals = 2;
      return (
        avalCount + minimumAvals >= avalList.length || !legalRepresentativeAval
      );
    }
    const minimumAvals = 1;
    return avalCount + minimumAvals >= avalList.length;
  }, [avalCount, avalList, data.clientPersonType]);

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <AvalsAlert
                hasAvalsWithPendingDocs={hasAvalsWithPendingDocs}
                loading={loadingAvals}
                avals={avalList}
                legalRepresentativeAval={legalRepresentativeAval}
                personType={values.clientPersonType}
                clientId={values.clientId}
              />

              {values.clientPersonType === PersonType.PHYSICAL && (
                <FormControl
                  fullWidth
                  error={Boolean(touched.avals && errors.avals)}
                >
                  <Autocomplete
                    key="aval-n1"
                    options={avalList.filter(
                      aval =>
                        !values.avals.some(selectedAval => {
                          if (selectedAval) {
                            return selectedAval.id === aval.id;
                          }
                          return false;
                        })
                    )}
                    id="avals"
                    label={`${t('COMMON.AVAL')} (${t('COMMON.OPTIONAL')})`}
                    name="avals"
                    value={values.avals[0] ?? null}
                    loading={loadingAvals}
                    disabled={loadingAvals}
                    loadingText={t('COMMON.LOADING')}
                    getOptionLabel={option =>
                      option ? `${option.fullName}` : ''
                    }
                    fullWidth
                    onChange={(_e, value) => {
                      const updatedAvals = [...values.avals];
                      if (value) {
                        updatedAvals[0] = { ...value } ?? null;
                      }
                      setFieldValue('avals', updatedAvals);
                    }}
                    error={Boolean(touched.avals && errors.avals)}
                    helperText={touched.avals && t(errors.avals)}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                  />
                </FormControl>
              )}

              {values.clientPersonType === PersonType.LEGAL && (
                <>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.avals && errors.avals)}
                  >
                    <Autocomplete
                      key="aval-n1"
                      options={avalList.filter(
                        aval =>
                          !values.avals.some(selectedAval => {
                            if (selectedAval) {
                              return selectedAval.id === aval.id;
                            }
                            return false;
                          })
                      )}
                      id="avals"
                      label={t('ENUMS.AVAL_ROLE.LEGAL_REPRESENTATIVE')}
                      name="avals"
                      value={values.avals[0] ?? null}
                      loading={loadingAvals}
                      loadingText={t('COMMON.LOADING')}
                      disabled
                      getOptionLabel={option =>
                        option ? `${option.fullName}` : ''
                      }
                      fullWidth
                      onChange={(_e, value) => {
                        const updatedAvals = [...values.avals];
                        if (value) {
                          updatedAvals[0] = { ...value } ?? null;
                        }
                        setFieldValue('avals', updatedAvals);
                      }}
                      error={Boolean(touched.avals && errors.avals)}
                      helperText={touched.avals && t(errors.avals)}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={Boolean(touched.avals && errors.avals)}
                  >
                    <Autocomplete
                      key="aval-n2"
                      options={avalList.filter(
                        aval =>
                          !values.avals.some(selectedAval => {
                            if (selectedAval) {
                              return selectedAval.id === aval.id;
                            }
                            return false;
                          })
                      )}
                      id="avals"
                      label={`${t('COMMON.AVAL')} (${t('COMMON.OPTIONAL')})`}
                      name="avals"
                      value={values.avals[1] ?? null}
                      loading={loadingAvals}
                      disabled={loadingAvals || !values.avals[0]}
                      loadingText={t('COMMON.LOADING')}
                      getOptionLabel={option =>
                        option ? `${option.fullName}` : ''
                      }
                      fullWidth
                      onChange={(_e, value) => {
                        const updatedAvals = [...values.avals];

                        if (value) {
                          updatedAvals[1] = { ...value } ?? null;
                        }
                        setFieldValue('avals', updatedAvals);
                      }}
                      error={Boolean(touched.avals && errors.avals)}
                      helperText={touched.avals && t(errors.avals)}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                </>
              )}

              <FormControl
                fullWidth
                error={Boolean(touched.avals && errors.avals)}
              >
                {Array(avalCount)
                  .fill('')
                  .map((avals, index) => (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Autocomplete
                          key={`aval-${index + avalIndex}`}
                          options={avalList.filter(
                            aval =>
                              !values.avals.some(selectedAval => {
                                if (selectedAval) {
                                  return selectedAval.id === aval.id;
                                }
                                return false;
                              })
                          )}
                          id="avals"
                          label={`${t('COMMON.AVAL')} (${t(
                            'COMMON.OPTIONAL'
                          )})`}
                          name="avals"
                          value={values.avals[index + avalIndex] ?? null}
                          loading={loadingAvals}
                          disabled={loadingAvals}
                          loadingText={t('COMMON.LOADING')}
                          getOptionLabel={option =>
                            option ? `${option.fullName}` : ''
                          }
                          fullWidth
                          onChange={(_e, value) => {
                            const updatedAvals = [...values.avals];
                            if (value) {
                              updatedAvals[index + avalIndex] =
                                { ...value } ?? null;
                            }
                            setFieldValue('avals', updatedAvals);
                          }}
                          error={Boolean(touched.avals && errors.avals)}
                          helperText={touched.avals && t(errors.avals)}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                        />
                        <IconButton
                          sx={{
                            float: 'right',
                            width: '20px',
                            height: '20px',
                            marginLeft: '-55px'
                          }}
                          aria-label="delete"
                          onClick={() => {
                            const updatedAvals = values.avals.filter(
                              (_, i) => i !== index + avalIndex
                            );
                            setFieldValue('avals', updatedAvals);
                            setAvalCount(avalCount - 1);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  ))}
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Button
                  type="button"
                  color="primary"
                  variant="text"
                  disabled={
                    avalCount >= maxAvalAbility ||
                    loadingAvals ||
                    avalList.length <= 1 ||
                    disabledAddAvalButton
                  }
                  onClick={incrementAvalCount}
                  startIcon={<AddIcon />}
                >
                  {t('COMMON.ADD_AVAL')}
                </Button>
              </Box>
            </Box>
            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={handleBack}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepTwo.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  resetRef: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepTwo;
