import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { AppBar, Box, Container, Tab, Tabs } from '@mui/material';
import FadeIn from 'react-fade-in';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { InvestorStatus } from 'src/utils/investors';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvestmentsQueryOptions } from 'src/store/selectors/investmentSelector';
import {
  CLEAR_INVESTMENT,
  loadInvestmentList
} from 'src/store/actions/investmentActions';
import Toolbar from './Toolbar';
import InvestmentList from './InvestmentList';
import useStyles from '../styles';

const InvestmentListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryOpts = useSelector(selectInvestmentsQueryOptions);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const selectInvestorStatus = value => {
    switch (Number(value)) {
      case 0:
        return InvestorStatus.ACTIVE;
      case 1:
        return InvestorStatus.INACTIVE;
      default:
        return InvestorStatus.ACTIVE;
    }
  };

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadInvestmentList({
          ...queryOpts,
          status: selectInvestorStatus(newValue)
        })
      );
    },
    [setTabIndex]
  );

  useEffect(() => {
    dispatch(loadInvestmentList({ status: InvestorStatus.ACTIVE }));
    return dispatch({ type: CLEAR_INVESTMENT });
  }, []);

  return (
    <Page className={classes.root} title={t('NAVIGATION.INVESTMENTS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <Toolbar />
            <Box mt={3}>
              <AppBar
                variant="outlined"
                position="static"
                sx={{
                  border: 'none',
                  borderBottom: '1px solid rgba(43,45,66,0.1)',
                  background: 'transparent',
                  flexDirection: 'row'
                }}
              >
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabIndex}
                  onChange={handleChange}
                >
                  <Tab label={t('COMMON.ACTIVES')} />
                  <Tab label={t('COMMON.INACTIVES')} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabIndex} index={0}>
                <InvestmentList status={selectInvestorStatus(tabIndex)} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <InvestmentList status={selectInvestorStatus(tabIndex)} />
              </TabPanel>
            </Box>
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default InvestmentListView;
