export const selectKam = state => state.kams;
export const selectKamsList = state => selectKam(state).items;
export const selectKamsListCount = state => selectKam(state).count;
export const selectKamDetail = state => selectKam(state).detail;
export const selectKamsQueryOptions = state => {
  const { options } = selectKam(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

export const selectKamQuotes = state => selectKam(state).detail.quotes.items;
