import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { requectQuoteById } from 'src/store/actions/quoteActions';
import DialogTransition from 'src/components/DialogTransition';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const RejectQuoteDialog = ({ open, onClose, quoteId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resetRef = useRef();
  const initialState = makeInitialState();

  const handleSubmitReject = useCallback(
    values => {
      dispatch(requectQuoteById(quoteId, values, onClose));
    },
    [quoteId]
  );
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle className={classes.header} id="form-distributor-delete">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('ENUMS.QUOTE_ACTIONS.REJECT_QUOTE')}
        </Typography>
      </DialogTitle>
      <DialogContentText sx={{ textAlign: 'center' }}>
        <Typography variant="body1" color="textPrimary">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.QUOTES.REJECT_QUOTE_MESSAGE')}
        </Typography>
      </DialogContentText>
      <DialogContent className={classes.confirmation} id="reject-quote-dialog">
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmitReject}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            handleBlur,
            handleChange
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.container}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    fullWidth
                    label={`${t('COMMON.COMMENT')}`}
                    margin="normal"
                    name="comment"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.comment}
                    variant="outlined"
                    error={Boolean(touched.comment && errors.comment)}
                    helperText={touched.comment && t(errors.comment)}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '0.5rem auto'
                  }}
                >
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    sx={{ minWidth: '45%' }}
                  >
                    {t('COMMON.CANCEL')}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: '45%' }}
                  >
                    {t('COMMON.ACCEPT')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

RejectQuoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quoteId: PropTypes.number.isRequired
};

export default RejectQuoteDialog;
