import React from 'react';
import PropTypes from 'prop-types';
import EquipmentListView from 'src/views/equipment/Equipments';
import { Redirect, Route } from 'react-router-dom';
import EquipmentDetailView from './EquipmentDetail';

const EquiptmentView = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}`} component={EquipmentListView} />
      <Route
        exact
        path={`${match.url}/equipments`}
        component={() => <Redirect to={`${match.url}`} />}
      />
      <Route path={`${match.url}/:id`} component={EquipmentDetailView} />
    </>
  );
};

EquiptmentView.propTypes = {
  match: PropTypes.object.isRequired
};

export default EquiptmentView;
