import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import {
  CLEAR_ADMINISTRATORS,
  createAdministrator,
  loadAdministrators
} from 'src/store/actions/administratorsActions';
import AddButton from 'src/components/AddButton/AddButton';
import AdministratorDialog from 'src/views/administrator/administrators/AdministratorDialog';
import useStyles from './styles';

const AdministratorsToolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpenForm(true);
  }, [setOpenForm]);

  const handleClose = useCallback(() => {
    setOpenForm(false);
  }, [setOpenForm]);

  const handleCreateAdministrator = useCallback(
    values => {
      dispatch(createAdministrator(values, handleClose));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadAdministrators());
    return () => dispatch({ type: CLEAR_ADMINISTRATORS });
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('ADMINISTRATORS_VIEW.TITLE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <AddButton
              onClick={handleClickOpen}
              title="ADMINISTRATORS_VIEW.CREATE_ADMINISTRATOR"
              key="create-administrator"
            />

            <AdministratorDialog
              open={openForm}
              handleClose={handleClose}
              onSubmit={handleCreateAdministrator}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

AdministratorsToolbar.propTypes = {
  className: PropTypes.string
};

export default AdministratorsToolbar;
