import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import CapitalBalanceListView from './CapitalBalanceList';

const CapitalBalanceView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={CapitalBalanceListView} />
    </>
  );
};

CapitalBalanceView.propTypes = {
  match: PropTypes.object.isRequired
};

export default CapitalBalanceView;
