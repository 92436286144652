import { Box, Paper, Popover, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import theme from 'src/theme';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { MinCountToChargeNotifications } from 'src/utils/constants/notifications';
import NotificationCard from './NotificationCards/NotificationCard';
import Loading from '../Loading';
import NotificationLazyLoad from './NotificationLazyLoad';

const NotificationMenuList = ({
  anchorEl,
  notifications,
  onClose,
  loading
}) => {
  const resetRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, anchorEl]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={{
        zIndex: 9999
      }}
    >
      <FadeIn transitionDuration={200}>
        <Box
          sx={{
            padding: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '8px 8px 0 0'
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.contrastText,
              fontWeight: 'bold',
              fontSize: '1rem'
            }}
          >
            {t('COMMON.NOTIFICATIONS')}
          </Typography>
        </Box>
        <Paper
          sx={{
            height: '400px',
            width: 'min(50ch, 100ch)',
            overflowX: 'hidden',
            overflowY: 'auto',
            borderRadius: '0 0 8px 8px',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper
          }}
        >
          {loading && <Loading label={t('COMMON.LOAD_NOTIFICATIONS')} />}
          {!notifications.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%'
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                  fontSize: '1rem'
                }}
              >
                {t('COMMON.NOTIFICATIONS_EMPTY')}
              </Typography>
            </Box>
          )}

          <Stack
            spacing={1}
            sx={{
              padding: '4px'
            }}
          >
            {notifications &&
              notifications.map((notification, index) => (
                <NotificationCard
                  notification={notification}
                  onClose={onClose}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`notification_${index}`}
                />
              ))}

            {notifications.length > MinCountToChargeNotifications && (
              <NotificationLazyLoad />
            )}
          </Stack>
        </Paper>
      </FadeIn>
    </Popover>
  );
};

NotificationMenuList.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  notifications: PropTypes.array,
  onClose: PropTypes.func,
  loading: PropTypes.bool
};

export default NotificationMenuList;
