import { push } from 'connected-react-router';
import {
  changeAdminUserPassword,
  changeDistributorUserPassword,
  loginAdminWithCredentials,
  loginUserWithCredentials,
  resendUserValidationEmail,
  resetAdminPassword,
  resetUserPassword,
  validateAdminUserEmail,
  validateDistributorUserEmail
} from 'src/services/authService';
import decode from 'jwt-decode';
import UserType, { AuthErrors } from 'src/utils/constants/auth';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { showMessage } from './snackBarActions';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const USER_EMAIL_VERIFICATION = asyncActionCreator(
  'USER_EMAIL_VERIFICATION'
);
export const USER_ASSIGN_PASSWORD = asyncActionCreator('USER_ASSIGN_PASSWORD');
export const RESEND_VALIDATION_EMAIL = asyncActionCreator(
  'RESEND_VALIDATION_EMAIL'
);
export const RESET_PASSWORD = asyncActionCreator('RESET_PASSWORD');
export const CHANGE_PASSWORD = asyncActionCreator('CHANGE_PASSWORD');
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export function login({ email, password, keepLogged, userType }) {
  function getErrorMessage(error) {
    let message;
    if (error.message === AuthErrors.UNAUTHORIZED) {
      message = 'INVALID_EMAIL_OR_PASSWORD';
    } else if (error.message === AuthErrors.PASSWORD_LENGTH) {
      message = 'PASSWORD_LENGTH';
    } else {
      message = error.message;
    }
    return message;
  }

  return async dispatch => {
    try {
      let data;
      if (userType === UserType.DISTRIBUTOR) {
        data = await loginUserWithCredentials({
          email,
          password,
          keepLogged
        });
      } else {
        data = await loginAdminWithCredentials({
          email,
          password,
          keepLogged
        });
      }
      const { token } = data;
      const { payload } = decode(token);
      const { role, system, id, distributorId, status } = payload;
      localStorage.setItem('sessionToken', token);

      dispatch({
        type: LOGIN,
        token,
        role,
        status,
        system,
        id,
        distributorId
      });
      dispatch(push('/'));
    } catch (error) {
      const message = getErrorMessage(error);
      dispatch(showMessage({ message, variant: 'error' }));
      dispatch({
        type: LOGIN_ERROR
      });
    }
  };
}

export function loginWithToken(token) {
  return async dispatch => {
    try {
      const { payload } = decode(token);
      const { location } = document;
      const referrer = location.pathname;
      localStorage.setItem('sessionToken', token);

      const { role, system, id, distributorId, status } = payload;

      dispatch({
        type: LOGIN,
        token,
        role,
        system,
        id,
        status,
        distributorId
      });
      dispatch(push(referrer));
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR
      });
    }
  };
}

export function logout() {
  return dispatch => {
    localStorage.removeItem('sessionToken');
    dispatch({ type: LOGOUT });

    if (process.env.NODE_ENV === 'production') {
      window.location.replace('https://www.astrocap.com/');
    }
  };
}

export const userVerification = (token, userType) => async dispatch => {
  try {
    dispatch({ type: USER_EMAIL_VERIFICATION.start });
    let newToken = '';

    if (userType === UserType.DISTRIBUTOR) {
      newToken = await validateDistributorUserEmail({ token });
    } else if (userType === UserType.ADMIN) {
      newToken = await validateAdminUserEmail({ token });
    }

    const { payload } = decode(newToken);
    const { role, system, id, distributorId } = payload;

    localStorage.setItem('sessionToken', newToken);

    dispatch({
      type: LOGIN,
      role,
      system,
      id,
      distributorId,
      token: newToken
    });

    dispatch({ type: USER_EMAIL_VERIFICATION.success });

    setTimeout(() => {
      dispatch(push('/assign-password'));
    }, 2000);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch(push('/user/login'));
    dispatch({ type: USER_EMAIL_VERIFICATION.failure });
  }
};

export const assignPassword = (id, password) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ASSIGN_PASSWORD.start });
    let newToken = '';
    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      newToken = await changeDistributorUserPassword({ id, password });
    } else if (auth.system === UserType.ADMIN) {
      newToken = await changeAdminUserPassword({ id, password });
    }
    const { payload } = decode(newToken);
    const { role, system, distributorId } = payload;

    localStorage.setItem('sessionToken', newToken);

    dispatch({
      type: LOGIN,
      role,
      system,
      distributorId,
      token: newToken
    });
    dispatch(
      showMessage({
        message: 'ASSIGN_PASSWORD',
        variant: 'success'
      })
    );
    dispatch({ type: USER_ASSIGN_PASSWORD.success });
    setTimeout(() => {
      dispatch(push('/user/home'));
    }, 500);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch({ type: USER_ASSIGN_PASSWORD.failure });
  }
};

export const resendValidationEmail = id => async dispatch => {
  try {
    dispatch({ type: RESEND_VALIDATION_EMAIL.start });
    await resendUserValidationEmail(id);
    dispatch(
      showMessage({
        message: 'RESEND_VALIDATION_EMAIL',
        variant: 'success'
      })
    );
    dispatch({ type: RESEND_VALIDATION_EMAIL.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch({ type: RESEND_VALIDATION_EMAIL.failure });
  }
};

export const resetPassword = (email, userType) => async dispatch => {
  try {
    dispatch({ type: RESET_PASSWORD.start });
    if (userType === UserType.DISTRIBUTOR) {
      await resetUserPassword({ email });
    } else {
      await resetAdminPassword({ email });
    }
    dispatch(
      showMessage({
        message: 'RESET_PASSWORD',
        variant: 'success'
      })
    );
    dispatch({ type: RESET_PASSWORD.success });
    return dispatch(push('/user/login'));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: RESET_PASSWORD.failure });
  }
};

export const changePassword = token => async dispatch => {
  try {
    dispatch({ type: CHANGE_PASSWORD.start });
    const { payload } = decode(token);
    const { role, system, id, distributorId } = payload;

    dispatch({
      type: LOGIN,
      role,
      system,
      id,
      distributorId,
      token
    });

    dispatch({ type: CHANGE_PASSWORD.success });
    return setTimeout(() => {
      dispatch(push('/assign-password'));
    }, 500);
  } catch {
    dispatch(showMessage({ message: 'INVALID_TOKEN', variant: 'error' }));
    return dispatch({ type: CHANGE_PASSWORD.failure });
  }
};

export const removeToken = () => dispatch => {
  localStorage.removeItem('sessionToken');
  dispatch({ type: REMOVE_TOKEN });
};
