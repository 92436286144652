/* eslint-disable import/prefer-default-export */
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { removePointsPhone } from 'src/utils/common';
import {
  getKams,
  updateKamById,
  createKam as createKamService,
  getKamById,
  downloadExportedKams,
  acceptRegistrationRequest,
  rejectRegistrationRequest
} from 'src/services/userKamService';
import UserType from 'src/utils/constants/auth';
import { getDistributorUserDetail } from 'src/services/distributorService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { selectKamsQueryOptions } from '../selectors/kamSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_KAMS = asyncActionCreator('LOAD_KAMS');
export const CLEAR_KAMS = 'CLEAR_KAMS';

export const SAVE_KAMS_OPTIONS = 'SAVE_KAMS_OPTIONS';
export const CLEAR_KAM_OPTIONS = 'CLEAR_KAM_OPTIONS';
export const UPDATE_DISTRIBUTOR_KAM = asyncActionCreator(
  'UPDATE_DISTRIBUTOR_KAM'
);
export const CREATE_DISTRIBUTOR_KAM = asyncActionCreator(
  'CREATE_DISTRIBUTOR_KAM'
);

export const LOAD_KAM_DETAIL = asyncActionCreator('LOAD_KAM_DETAIL');
export const CLEAR_KAM_DETAIL = 'CLEAR_KAM_DETAIL';
export const ACCEPT_REGISTRATION_REQUEST = asyncActionCreator(
  'ACCEPT_REGISTRATION_REQUEST'
);
export const REJECT_REGISTRATION_REQUEST = asyncActionCreator(
  'REJECT_REGISTRATION_REQUEST'
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectKamsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.role) {
    reqOptions.role = queryOpts.role;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadKams = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_KAMS.start });

    dispatch({
      type: SAVE_KAMS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const kams = await getKams(reqOptions);

    return dispatch({
      type: LOAD_KAMS.success,
      ...kams
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_KAMS.failure });
  }
};

export const downloadExcelKamList = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadExportedKams(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadAllKams = (includeDirectors = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_KAMS.start });

    const reqOptions = getOptions(getState, false);

    if (includeDirectors) {
      reqOptions.includeDirectors = true;
    }

    const kams = await getKams(reqOptions);

    return dispatch({
      type: LOAD_KAMS.success,
      ...kams
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_KAMS.failure });
  }
};

/*
  Creates a kam and returns the created kam.
  This function is ment to be used only by the director.
*/
export const createKam = values => {
  return async dispatch => {
    try {
      dispatch({ type: CREATE_DISTRIBUTOR_KAM.start });
      let phone;
      let officePhone;

      if (values.phone) {
        phone = removePointsPhone(values.phone);
      }
      if (values.officePhone) {
        officePhone = removePointsPhone(values.officePhone);
      }

      await createKamService({
        ...values,
        phone,
        officePhone
      });

      dispatch(
        showMessage({
          message: 'CREATE_DISTRIBUTOR_KAM',
          variant: 'success'
        })
      );
      dispatch(loadKams({ page: 0 }));
      return dispatch({ type: CREATE_DISTRIBUTOR_KAM.success });
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: 'error' }));
      return dispatch({ type: CREATE_DISTRIBUTOR_KAM.failure });
    }
  };
};

/*
  Edits a kam by id and returns the updated kam.
  This function is ment to be used only by the director.
*/
export const updateKam = (id, values) => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_DISTRIBUTOR_KAM.start });
      let phone;
      let officePhone;

      if (values.phone) {
        phone = removePointsPhone(values.phone);
      }
      if (values.officePhone) {
        officePhone = removePointsPhone(values.officePhone);
      }

      await updateKamById({
        id,
        values: {
          ...values,
          phone,
          officePhone
        }
      });

      dispatch(
        showMessage({
          message: 'UPDATE_DISTRIBUTOR_KAM',
          variant: 'success'
        })
      );
      dispatch(loadKams({ page: 0 }));
      return dispatch({ type: UPDATE_DISTRIBUTOR_KAM.success });
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: 'error' }));
      return dispatch({ type: UPDATE_DISTRIBUTOR_KAM.failure });
    }
  };
};

export const clearKams = () => ({ type: CLEAR_KAMS });

export const loadKamById = id => async (dispatch, getState) => {
  try {
    const { auth } = getState();

    dispatch({ type: LOAD_KAM_DETAIL.start });

    if (auth.system === UserType.ADMIN) {
      const kam = await getDistributorUserDetail(id);

      return dispatch({
        type: LOAD_KAM_DETAIL.success,
        detail: kam
      });
    }

    const kam = await getKamById(id);

    return dispatch({
      type: LOAD_KAM_DETAIL.success,
      detail: kam
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_KAMS.failure });
  }
};

export const acceptKamRegistrationRequest = (values, handleClose) => async dispatch => {
  try {
    dispatch({ type: ACCEPT_REGISTRATION_REQUEST.start });

    await acceptRegistrationRequest(values);

    dispatch({ type: ACCEPT_REGISTRATION_REQUEST.success });

    handleClose();
    dispatch({ type: CLEAR_KAM_DETAIL });
    dispatch(loadKamById(values.id));
    return dispatch(
      showMessage({
        message: 'KAM_REGITRATION_REQUEST_WAS_ACCEPTED',
        variant: SnackBarVariants.SUCCESS
      })
    );
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_KAMS.failure });
  }
};

export const rejectKamRegistrationRequest = (values, handleClose) => async dispatch => {
  try {
    dispatch({ type: REJECT_REGISTRATION_REQUEST.start });

    await rejectRegistrationRequest(values);

    dispatch({ type: REJECT_REGISTRATION_REQUEST.success });

    handleClose();
    dispatch({ type: CLEAR_KAM_DETAIL });
    dispatch(loadKamById(values.id));
    return dispatch(
      showMessage({
        message: 'KAM_REGITRATION_REQUEST_WAS_REJECTED',
        variant: SnackBarVariants.SUCCESS
      })
    );
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_KAMS.failure });
  }
};
