const makeInitialState = data => {
  return {
    fullName: data ? data.fullName : '',
    phone: data ? data.phone : '',
    email: data ? data.email : '',
    price: data ? data.price : '',
    distributor: data ? data.distributor : '',
    distributorEquipment: data ? data.distributorEquipment : '',
    distributorProfile: data ? data.distributorProfile : '',
    comment: data ? data.comment : '',
    initialPayment: data ? data.initialPayment : 0,
    gracePeriod: data ? data.gracePeriod : ''
  };
};

export default makeInitialState;
