import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import { AssigantionStatus } from 'src/utils/constants/equipment';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import OverflowTipography from 'src/components/OverflowTipography';
import { useTranslation } from 'react-i18next';

const getStatusIcon = assignation => {
  switch (assignation) {
    case AssigantionStatus.ASSIGNED:
      return (
        <StatusIcon
          description="COMMON.ASSIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case AssigantionStatus.PENDING:
      return (
        <StatusIcon
          description="COMMON.PENDING_ASSIGNATION"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case AssigantionStatus.AVAILABLE:
      return (
        <StatusIcon
          description="COMMON.AVAILABLE"
          type={IconStatusAndColor.NONE.status}
        />
      );

    default:
      return null;
  }
};

const EquipmentsRow = ({
  id,
  assignation,
  distributor,
  equipmentType,
  brand,
  model,
  year,
  pendingFields,
  client,
  clientId,
  distributorModel: distributorAssociated,
  serialNumber
}) => {
  const statusIcon = useMemo(() => getStatusIcon(assignation), [assignation]);
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/inventory/${id}`}>{id}</Link>
      </TableCell>
      <TableCell>
        <OverflowTipography title={client} maxWidth="200px">
          {client ? (
            <Link to={`/clients/detail/${clientId}`}>{client}</Link>
          ) : (
            t('COMMON.NO_DATA')
          )}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography maxWidth="300px">
          {equipmentType}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography title={distributor} maxWidth="300px">
          {distributorAssociated ? (
            <Link to={`/distributors/business/${distributorAssociated.id}`}>
              {distributorAssociated.name}
            </Link>
          ) : (
            distributor
          )}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">{brand}</TableCell>
      <TableCell align="center">{model}</TableCell>
      <TableCell align="center">{serialNumber}</TableCell>
      <TableCell align="center">{year}</TableCell>
      <TableCell align="center">{pendingFields}</TableCell>
      <TableCell align="center">{statusIcon}</TableCell>
    </TableRow>
  );
};

EquipmentsRow.propTypes = {
  client: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  assignation: PropTypes.string.isRequired,
  distributor: PropTypes.string.isRequired,
  equipmentType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  pendingFields: PropTypes.number.isRequired,
  distributorModel: PropTypes.object.isRequired,
  serialNumber: PropTypes.string
};

export default EquipmentsRow;
