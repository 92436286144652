import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, IconButton } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeft } from 'react-feather';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import AddButton from 'src/components/AddButton/AddButton';
import { selectModelName } from 'src/store/selectors/catalogSelectors';
import { createModelAction } from 'src/store/actions/catalogActions';
import {
  selectAuthDistributorId,
  selectAuthSystem
} from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import useStyles from './styles';
import ModelForm from './ModelsForm';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { id, brandId } = useParams();
  const modelName = useSelector(selectModelName);
  const distributorId = useSelector(selectAuthDistributorId);
  const system = useSelector(selectAuthSystem);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateModel = useCallback(
    values => {
      const body = values;
      if (brandId) {
        Object.assign(body, { distributorId: Number(id) });
        return dispatch(createModelAction(brandId, body, handleCloseModal));
      }

      if (system === UserType.DISTRIBUTOR) {
        Object.assign(body, { distributorId });
      }

      return dispatch(createModelAction(id, body, handleCloseModal));
    },
    [dispatch, handleCloseModal]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[1]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={goBack} color="primary" component="span">
              <ArrowLeft />
            </IconButton>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {modelName || t('CATALOGS_VIEW.MODELS.TITLE')}
            </Typography>
          </Box>
          <Box className={classes.actionsContainer}>
            <AddButton
              title={t('CATALOGS_VIEW.MODELS.ADD_MODEL')}
              onClick={handleOpenModal}
            />
            <ModelForm
              open={open}
              onClose={handleCloseModal}
              onCreate={handleCreateModel}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  contractStatus: PropTypes.string
};

export default Toolbar;
