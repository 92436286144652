import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import { clientDetailTabsIndex } from 'src/utils/constants/clients';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { loadClientFullName } from 'src/store/actions/clientActions';
import AvalGeneralView from './General/General';
import NuffiView from './NuffiDetail';
import MoffinView from './BuroValidationDetail';

const getGeneralMenuList = system => {
  if (system === UserType.DISTRIBUTOR) {
    return [
      {
        value: clientDetailTabsIndex.GENERAL,
        label: 'COMMON.GENERAL'
      }
    ];
  }
  return [
    {
      value: clientDetailTabsIndex.GENERAL,
      label: 'COMMON.GENERAL'
    },
    {
      value: clientDetailTabsIndex.NUFI_VALIDATION,
      label: 'CLIENTS_VIEW.CLIENT_DETAIL.IDENTITY'
    },
    {
      value: clientDetailTabsIndex.MOFFIN_VALIDATION,
      label: 'CLIENTS_VIEW.CLIENT_DETAIL.MOFFIN.TITLE'
    }
  ];
};

const AvalDetailTabs = ({ isDisabledEdit = false }) => {
  const system = useSelector(selectAuthSystem);
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const { avalId, id: clientId, tab } = useParams();
  const [clientFullName, setClientFullName] = useState('');
  const dispatch = useDispatch();

  const [value, setValue] = useState(false);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      if (system === UserType.DISTRIBUTOR) {
        return push(`/user/aval/${avalId}/${newValue}`);
      }
      return push(
        `/clients/detail/${clientId}/aval/detail/${avalId}/${newValue}`
      );
    },
    [setValue]
  );

  useEffect(() => {
    if (avalId && !tab) {
      setValue(clientDetailTabsIndex.GENERAL);
    }
    if (tab) {
      if (Object.values(clientDetailTabsIndex).includes(tab)) {
        setValue(tab);
      } else {
        push(`/clients/detail/${clientId}aval/detail/${avalId}`);
      }
    }
    dispatch(loadClientFullName(clientId, setClientFullName));
  }, [avalId, tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb
        doNotRedirectIndexList={clientId ? [1, 4] : [1]}
        stringCrumb={clientFullName}
        translationCrumbNumber={[{ index: 2, translation: clientFullName }]}
      />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {getGeneralMenuList(system).map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>
      {system === UserType.SUPER_ADMIN || system === UserType.ADMIN ? (
        <>
          <TabPanel value={value} index={clientDetailTabsIndex.GENERAL}>
            <AvalGeneralView isDisabledEdit={isDisabledEdit} />
          </TabPanel>
          <TabPanel value={value} index={clientDetailTabsIndex.NUFI_VALIDATION}>
            <NuffiView />
          </TabPanel>
          <TabPanel
            value={value}
            index={clientDetailTabsIndex.MOFFIN_VALIDATION}
          >
            <MoffinView />
          </TabPanel>
        </>
      ) : (
        <TabPanel value={value} index={clientDetailTabsIndex.GENERAL}>
          <AvalGeneralView isDisabledEdit={isDisabledEdit} />
        </TabPanel>
      )}
    </Box>
  );
};

AvalDetailTabs.propTypes = {
  isDisabledEdit: PropTypes.bool
};

export default AvalDetailTabs;
