import * as Yup from 'yup';

export default Yup.object().shape({
  equipmentZipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'SCHEMA_ERRORS.ZIP_CODE_INVALID')
    .required('SCHEMA_ERRORS.ZIP_CODE_REQUIRED'),
  equipmentStreet: Yup.string().required('SCHEMA_ERRORS.STREET_REQUIRED'),
  equipmentInsideHomeNumber: Yup.string().nullable(),
  equipmentExternalHomeNumber: Yup.string().required(
    'SCHEMA_ERRORS.EXTERNAL_HOME_NUMBER_REQUIRED'
  ),
  equipmentColonia: Yup.string(),
  equipmentCity: Yup.string().required('SCHEMA_ERRORS.CITY_REQUIRED'),
  equipmentState: Yup.string().required('SCHEMA_ERRORS.STATE_REQUIRED')
});
