import React, { useEffect } from 'react';
import { Box, Card, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTOR_DETAIL,
  loadDistributorDetail
} from 'src/store/actions/distributorActions';
import Preloader from 'src/components/Preloader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import GeneralDistributor from './GeneralDetail';
import DistributorAddress from './AddressDetail';
import DistributorNufi from './NufiDetail/index';
import useStyles from './styles';

const GeneralView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const distributor = useSelector(selectDistributorDetail);

  const loading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_DETAIL.action])
  );
  const success = useSelector(
    successSelector([LOAD_DISTRIBUTOR_DETAIL.action])
  );

  useEffect(() => {
    dispatch(loadDistributorDetail(id));
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      {success && !loading && distributor ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <GeneralDistributor distributor={distributor} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DistributorAddress distributor={distributor} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DistributorNufi distributor={distributor} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Card className={classes.preloaderCard}>
          <Preloader />
        </Card>
      )}
    </Box>
  );
};

export default GeneralView;
