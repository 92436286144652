import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectClientsList } from 'src/store/selectors/clientSelectors';
import {
  selectDistributorCatalogs,
  selectDistributorDetail
} from 'src/store/selectors/distributorSelectors';
import { getClientFullName } from 'src/utils/common';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CLIENTS } from 'src/store/actions/clientActions';
import {
  LOAD_CLIENT_DISTRIBUTOR_AGENT,
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_PARAMETER
} from 'src/store/actions/distributorActions';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DropdownMenu from 'src/components/DropdownMenu';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import { getDefaultDaysForFirstRentList } from 'src/utils/constants/distributors';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import KamAutocomplete from './KamAutocomplete';
import QuoteCreationAlert from './QuoteCreationAlert';

const StepOne = ({ data, handleNext, resetRef }) => {
  const classes = useStyles();
  const distributor = useSelector(selectDistributorDetail);
  const maxInitialPayment = useSelector(selectIndividualParameter);
  const initialState = useMemo(
    () =>
      makeInitialState({
        ...data,
        equipmentInsuranceIncluded: data.equipmentInsuranceIncluded
          ? 'true'
          : 'false',
        gracePeriod: Number(distributor.defaultGracePeriod)
      }),
    [data, distributor]
  );
  const { t } = useTranslation();
  const clients = useSelector(selectClientsList);
  const catalogs = useSelector(selectDistributorCatalogs);
  const role = useSelector(selectAuthRole);
  const isLoadingClients = useSelector(
    isLoadingSelector([LOAD_CLIENTS.action])
  );
  const isLoadingEquipments = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );

  const isLoadingDistributorProfiles = useSelector(
    isLoadingSelector([LOAD_CLIENT_DISTRIBUTOR_AGENT.action])
  );
  const loadingDistributorParameter = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );

  const onNext = useCallback(
    async (values, setFieldError) => {
      const isValidated = validateTermAndInitialPayment(
        values,
        setFieldError,
        {
          minInitialPaymentPercentage: distributor.minInitialPayment,
          maxInitialPaymentPercentage: Number(maxInitialPayment)
        },
        t
      );
      if (!isValidated) return;
      handleNext({ ...values });
    },
    [handleNext, maxInitialPayment, distributor]
  );

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError }) => {
          onNext(values, setFieldError);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          setValues,
          handleChange,
          handleBlur,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <QuoteCreationAlert
                  clients={clients}
                  catalogs={catalogs}
                  loading={
                    isLoadingClients ||
                    isLoadingEquipments ||
                    isLoadingDistributorProfiles
                  }
                  selectedClient={values.client}
                  distributorRole={role}
                />

                <FormControl
                  fullWidth
                  error={Boolean(touched.client && errors.client)}
                >
                  <Autocomplete
                    options={clients}
                    isLoading={isLoadingClients}
                    id="client"
                    name="client"
                    label={t('COMMON.CLIENT')}
                    getOptionLabel={option =>
                      option
                        ? `${option.id} - ${getClientFullName(option)}`
                        : ''
                    }
                    value={values.client}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('client', value ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    helperText={touched.client && t(errors.client)}
                    error={Boolean(touched.client && errors.client)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorEquipment && errors.distributorEquipment
                  )}
                >
                  <Autocomplete
                    options={catalogs}
                    isLoading={isLoadingEquipments}
                    id="distributorEquipment"
                    name="distributorEquipment"
                    label={t('COMMON.EQUIPMENT')}
                    getOptionLabel={option =>
                      option
                        ? `${option.id} - ${option.brand.name} ${option.model}`
                        : ''
                    }
                    value={values.distributorEquipment}
                    fullWidth
                    onChange={(_e, value) => {
                      setValues({
                        ...values,
                        distributorEquipment: value,
                        price: value.price + value.iva ?? 0
                      });
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    helperText={
                      Boolean(touched.distributorEquipment) &&
                      t(errors.distributorEquipment)
                    }
                    error={Boolean(
                      touched.distributorEquipment &&
                        errors.distributorEquipment
                    )}
                  />
                </FormControl>

                {role === DistributorRoles.DIRECTOR && (
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.distributorProfile && errors.distributorProfile
                    )}
                  >
                    <KamAutocomplete
                      options={[]}
                      id="distributorProfile"
                      value={values.distributorProfile ?? ''}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      fullWidth
                      label={`${t('COMMON.KAM')}`}
                      error={
                        touched.distributorProfile && errors.distributorProfile
                      }
                      helperText={
                        touched.distributorProfile &&
                        t(errors.distributorProfile)
                      }
                    />
                  </FormControl>
                )}

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.equipmentInsuranceIncluded}
                  setValue={value =>
                    setFieldValue(
                      'equipmentInsuranceIncluded',
                      value === 'true'
                    )
                  }
                  label={t(
                    'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                  )}
                  error={Boolean(
                    touched.equipmentInsuranceIncluded &&
                      errors.equipmentInsuranceIncluded
                  )}
                  errorText={
                    touched.equipmentInsuranceIncluded &&
                    t(errors.equipmentInsuranceIncluded)
                  }
                />
                <FormControl fullWidth>
                  <DropdownMenu
                    onBlur={handleBlur}
                    label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                    value={values.gracePeriod}
                    name="gracePeriod"
                    setValue={value => setFieldValue('gracePeriod', value)}
                    error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    errorText={touched.gracePeriod && t(errors.gracePeriod)}
                    list={getDefaultDaysForFirstRentList(distributor.maxGracePeriod)}
                    disabled={
                      isLoadingEquipments || loadingDistributorParameter
                    }
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && t(errors.price)}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={() => {
                    handleChange('price');
                  }}
                  disabled={
                    !distributor.isPriceEditable || !values.distributorEquipment
                  }
                  value={values.price}
                  variant="outlined"
                />
                <PaymentMultipleInput
                  firstInputName="paymentInputOption"
                  secondInputName="initialPayment"
                  disabled={!catalogs || !values.distributorEquipment}
                  label={t('COMMON.INITIAL_PAYMENT')}
                />
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepOne;
