import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const BrandsAndModelsAlert = ({ brands, models, loading, selectedBrand }) => {
  const [open] = useState(true);

  const { id } = useParams();

  const { t } = useTranslation();

  if (!loading) {
    if (!brands.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_BRANDS_FIRST')}
            <Link to={`/distributors/business/${id}/brands`}>
              {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_BRANDS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }

    if (!models.length && selectedBrand) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_MODELS_FIRST')}
            <Link
              to={`/distributors/business/${id}/models/${selectedBrand.id}`}
            >
              {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_MODELS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

BrandsAndModelsAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  brands: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  selectedBrand: PropTypes.string
};

export default BrandsAndModelsAlert;
