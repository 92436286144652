import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const SatWsDialog = ({ open, handleClose, submit, client }) => {
  const classes = useStyles();
  const initialState = makeInitialState(client);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleSubmitSatSwRegister = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  const trimWhitespaces = useCallback(
    setFieldValue => e => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
    []
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.BILLING_DETAIL.ADD_CREDENTIALS')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSatSwRegister}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  label={t('COMMON.RFC')}
                  margin="normal"
                  name="rfc"
                  type="text"
                  disabled
                  value={values.rfc}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password ? t(errors.password) : null}
                  label={t('CLIENTS_VIEW.BILLING_DETAIL.CIEC_PASSWORD')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={trimWhitespaces(setFieldValue)}
                  type="text"
                  value={values.password}
                  variant="outlined"
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.ADD')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

SatWsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  client: PropTypes.object
};

export default SatWsDialog;
