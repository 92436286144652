import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';

const OverflowTipography = ({
  children,
  maxWidth = '160px',
  fontSize = 14,
  title,
  ...props
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > maxWidth.replace('px', '')
    );
  }, []);
  return (
    <Tooltip
      title={title ?? children}
      disableHoverListener={!isOverflowed}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            color: 'white !important'
          },
          '& a': {
            color: 'white !important'
          }
        }
      }}
    >
      <Typography
        ref={textElementRef}
        {...props}
        style={{
          fontSize,
          width: maxWidth,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'block',
          overflow: 'hidden'
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

OverflowTipography.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  fontSize: PropTypes.number,
  title: PropTypes.string
};

export default OverflowTipography;
