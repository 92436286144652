import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getAllContracts = serviceBase({
  request: options =>
    axiosClient.get('/contracts', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const createNewContract = serviceBase({
  request: value => axiosClient.post(`/contracts`, value)
});

export const createNewContractDates = serviceBase({
  request: ({ id, values }) => {
    return axiosClient.post(`/contracts/edit-contract/${id}`, values);
  }
});

export const sendToSingContract = serviceBase({
  request: value => axiosClient.post(`/contracts/send-to-sign`, value)
});

export const singContractManually = serviceBase({
  request: ({ id, documentUrl }) =>
    axiosClient.post(`/contracts/${id}/sign-manually`, { documentUrl })
});

export const cancelContract = serviceBase({
  request: contract =>
    axiosClient.post(`/contracts/cancel-assignation`, contract)
});

export const getContractById = serviceBase({
  request: options =>
    axiosClient.get(`/contracts/${options.id}`, {
      params: { fullScheduleView: options.fullScheduleView }
    }),

  retry: 0
});

export const getContractPaymentSchedule = serviceBase({
  request: options =>
    axiosClient.get(`/contracts/${options.id}/payment-schedule`, {
      params: { fullScheduleView: options.fullScheduleView }
    }),

  retry: 0
});

export const getRentBalanceSchedule = serviceBase({
  request: options =>
    axiosClient.get(`/contracts/${options.id}/rent-balance-schedule`, {
      params: { fullScheduleView: options.fullScheduleView }
    }),

  retry: 0
});

export const updateSigleFile = serviceBase({
  request: file => axiosClient.put(`/aws`, file),
  retry: 0
});

export const createCapitalContribution = serviceBase({
  request: value => axiosClient.post('/contracts/capital-contribution', value)
});

export const getContributionsByContract = serviceBase({
  request: id => axiosClient.get(`/contracts/capital-contribution/${id}`),
  retry: 0
});

export const getAmendingAgreementDocList = serviceBase({
  request: id => axiosClient.get(`/contracts/amending-agreement/${id}`),
  retry: 0
});

export const getContractAvalList = serviceBase({
  request: id => axiosClient.get(`/contracts/${id}/aval`),
  retry: 0
});

export const getRentAfterContribution = serviceBase({
  request: ({ id, amount }) =>
    axiosClient.get(`/contracts/capital-contribution/rent/${id}`, {
      params: { amount }
    }),
  retry: 0
});

export const downloadContractPaymentSchedule = serviceBase({
  request: id =>
    axiosClient.get(`/contracts/${id}/payment-schedule/export`, {
      responseType: 'blob'
    }),
  transformResponse: res => {
    try {
      const filename = 'Calendario De Pagos.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const downloadRentBalanceSchedule = serviceBase({
  request: id =>
    axiosClient.get(`/contracts/${id}/rent-balance-schedule/export`, {
      responseType: 'blob',
      params: id
    }),
  transformResponse: res => {
    try {
      const filename = 'Balance de Rentas.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const createContractFromQuoteRequest = serviceBase({
  request: value => axiosClient.post('/contracts/quote', value)
});

export const editContractById = serviceBase({
  request: ({ id, values }) => axiosClient.patch(`/contracts/${id}`, values)
});

export const getMonthlyRent = serviceBase({
  request: params => axiosClient.get('/contracts/rent', { params }),
  retry: 0
});

export const activateContractAutomaticBilling = serviceBase({
  request: id =>
    axiosClient.patch(`/contracts/${id}/automatic-billing/activate`)
});

export const deactivateContractAutomaticBilling = serviceBase({
  request: id =>
    axiosClient.patch(`/contracts/${id}/automatic-billing/desactivate`)
});

export const createUpfrontRent = serviceBase({
  request: values =>
    axiosClient.post(`/contracts/${values.contractId}/upfront-rent`, values)
});

export const generateAutomaticDebitRequestDocument = serviceBase({
  request: contractId =>
    axiosClient.post(`/contracts/${contractId}/automatic-debit-request`)
});

export const sendToSignAutomaticDebitRequestDocument = serviceBase({
  request: contractId =>
    axiosClient.post(
      `/contracts/${contractId}/automatic-debit-request/send-to-sign`
    )
});

export default getAllContracts;
