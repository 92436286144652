import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RetryIcon from '@mui/icons-material/Replay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { billTransaction } from 'src/store/actions/billingActions';

const BillingFailureRowMenu = ({ id }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRetry = useCallback(() => {
    dispatch(billTransaction(id));
    return handleCloseMenu();
  }, [id]);

  return (
    <Box component="div">
      <IconButton
        aria-label="documentsMenu"
        id="documentsMenu"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={id}
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: '27ch',
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: 1
          }
        }}
      >
        <MenuItem onClick={handleRetry}>
          <ListItemIcon>
            <RetryIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {t('TRANSACTION.SEND_TO_REBILL')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

BillingFailureRowMenu.propTypes = {
  id: PropTypes.string.isRequired
};

export default BillingFailureRowMenu;
