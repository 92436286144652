import React, { useCallback, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useParams } from 'react-router';
import {
  CLEAR_QUOTES,
  LOAD_QUOTES,
  loadQuotes
} from 'src/store/actions/quoteActions';
import {
  selectQuotesList,
  selectQuotesListCount,
  selectQuotesQueryOptions
} from 'src/store/selectors/quoteSelectors';
import { QuoteStatus } from 'src/utils/constants/quotes';
import QuotesDetailTable from './QuotesDetailTable';
import useStyles from './styles';

const QuotesDetailList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const quotes = useSelector(selectQuotesList);
  const { id } = useParams();
  const loading = useSelector(isLoadingSelector([LOAD_QUOTES.action]));
  const { page } = useSelector(selectQuotesQueryOptions);
  const count = useSelector(selectQuotesListCount);

  useEffect(() => {
    dispatch(
      loadQuotes({ clientId: Number(id), status: QuoteStatus.IN_REVIEW })
    );
    return dispatch({ type: CLEAR_QUOTES });
  }, [dispatch, id]);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadQuotes({ page: value }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadQuotes({ sort: { property, order } }));
    },
    [dispatch]
  );

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item lg={12}>
          <QuotesDetailTable
            className={classes.table}
            loading={loading}
            quotes={quotes}
            onSortChange={handleSortChange}
            onChangePage={handleChangePage}
            page={page}
            count={count}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuotesDetailList;
