import React, { useEffect, useState } from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import { loadParameter } from 'src/store/actions/parameterActions';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import EditIcon from '@mui/icons-material/Edit';
import { updateEquipment } from 'src/store/actions/equipmentsActions';
import { Parameter } from 'src/utils/constants/parameters';
import EditSaleProductKey from './EditSaleProductKeyDialog';

const ActionOptions = [
  {
    name: 'EDIT',
    label: 'COMMON.EDIT',
    icon: <EditIcon />
  }
];

const EquipmentSaleInformation = ({ equipment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const parameter = useSelector(selectIndividualParameter);

  const handleOpenEditDialog = () => {
    setOpen(true);
  };

  const handleCloseEditDialog = () => {
    setOpen(false);
  };

  const handleSumbit = values => {
    dispatch(updateEquipment(equipment.id, values));
  };

  useEffect(() => {
    dispatch(loadParameter(Parameter.EQUIPMENT_SELL_USE_CDFI));
  }, []);

  return (
    <>
      <DetailRow
        name={t('COMMON.SALE_PRODUCT_KEY')}
        value={equipment.saleProductKey || t('COMMON.NO_DATA')}
      >
        <MoreMenu
          deleteDisabled
          options={ActionOptions}
          handleClickOption={handleOpenEditDialog}
        />
        <EditSaleProductKey
          open={open}
          equipment={equipment}
          handleClose={handleCloseEditDialog}
          submit={handleSumbit}
        />
      </DetailRow>
      <DetailRow name={t('PARAMETER_VIEW.EQUIPMENT_SELL_USE_CDFI')} value={parameter} />
    </>
  );
};

EquipmentSaleInformation.propTypes = {
  equipment: PropTypes.object.isRequired
};
export default EquipmentSaleInformation;
