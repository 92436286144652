/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { URL_DEFAULT_REDIRECT } from 'src/utils/constants/auth';

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Component {...props} />
      ) : (
        window.location.replace(URL_DEFAULT_REDIRECT)
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  loggedIn: PropTypes.bool
};

export default withRouter(
  connect(state => ({
    loggedIn: state.auth.token !== null
  }))(PrivateRoute)
);
