import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Tooltip
} from '@mui/material';
import DetailRow from 'src/components/DetailRow';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEND_TO_VALIDATE_BILLING,
  sendToValidateBilling,
  updateClientBillingData
} from 'src/store/actions/clientActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'src/components/Button';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import LoadingValidationDialog from 'src/components/LoadingValidationDialog';
import useStyles from '../styles';
import BillingDialog from './BillingDialog';
import TaxSystemDialog from './TaxSystemDialog';
import ZipCodeDialog from './ZipCodeDialog';

const DialogOptions = {
  BILLING_NAME: 'BILLING_NAME',
  ZIP_CODE: 'ZIP_CODE',
  TAX_SYSTEM: 'TAX_SYSTEM'
};

const BillingDetail = ({ client }) => {
  const [openDialog, setOpenDialog] = useState('');
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleCloseEdit = () => {
    setOpenDialog('');
  };

  const handleOpenEditBillingNameDialog = () => {
    setOpenDialog(DialogOptions.BILLING_NAME);
  };

  const handleOpenEditZipcodeDialog = () => {
    setOpenDialog(DialogOptions.ZIP_CODE);
  };

  const handleOpenEditTaxSystemDialog = () => {
    setOpenDialog(DialogOptions.TAX_SYSTEM);
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(updateClientBillingData(id, values, () => handleCloseEdit()));
    },
    [client, dispatch]
  );
  const loading = useSelector(
    isLoadingSelector([SEND_TO_VALIDATE_BILLING.action])
  );

  const handleCloseLoadingDialog = () => {
    setOpenLoadingDialog(false);
  };

  const handleOpenLoadingDialog = () => {
    setOpenLoadingDialog(true);
  };

  const handleValidateBilling = () => {
    handleOpenLoadingDialog();
    dispatch(sendToValidateBilling(id, handleCloseLoadingDialog));
  };

  const verifyButtonActive = () => {
    const { rfc, facturapiId, taxSystemId, billingZipCode } = client;
    if (rfc && facturapiId && taxSystemId && billingZipCode) {
      return true;
    }
    if (!rfc || !taxSystemId || !billingZipCode) {
      return true;
    }
    return false;
  };

  return (
    <Card className={classes.tableContainer}>
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.BILLING_DETAIL.BILLING_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent sx={{ padding: 0 }}>
        <Table>
          <TableBody>
            <DetailRow
              name={t('COMMON.BILLING_NAME')}
              value={client.billingName || t('COMMON.NO_DATA')}
            >
              <MoreMenu
                deleteDisabled
                handleClickOption={handleOpenEditBillingNameDialog}
              />
            </DetailRow>

            <BillingDialog
              open={openDialog === DialogOptions.BILLING_NAME}
              handleClose={handleCloseEdit}
              submit={handleSubmit}
              billingData={{ billingName: client.billingName }}
            />

            <DetailRow name="RFC" value={client.rfc || t('COMMON.NO_DATA')} />

            <DetailRow
              name={t('COMMON.ZIP_CODE')}
              value={client.billingZipCode || t('COMMON.NO_DATA')}
            >
              <MoreMenu
                deleteDisabled
                handleClickOption={handleOpenEditZipcodeDialog}
              />

              <ZipCodeDialog
                open={openDialog === DialogOptions.ZIP_CODE}
                handleClose={handleCloseEdit}
                submit={handleSubmit}
                zipcode={client.billingZipCode}
              />
            </DetailRow>

            <DetailRow
              name={t('CLIENTS_VIEW.CLIENT_DETAIL.TAX_SYSTEM')}
              value={
                client.taxSystem
                  ? t(`ENUMS.TAX_SYSTEM.${client.taxSystem.name}`)
                  : t('COMMON.NO_DATA')
              }
            >
              <MoreMenu
                deleteDisabled
                handleClickOption={handleOpenEditTaxSystemDialog}
              />
            </DetailRow>
            <TaxSystemDialog
              open={openDialog === DialogOptions.TAX_SYSTEM}
              handleClose={handleCloseEdit}
              submit={handleSubmit}
              taxSystem={client}
            />
          </TableBody>
        </Table>
        <Box sx={{ width: '90%', margin: 'auto' }}>
          <Tooltip
            title={
              verifyButtonActive() === true && client.facturapiId
                ? t('ENUMS.CLIENT_VALIDATION_STATUS.VALIDATED')
                : ''
            }
          >
            <Button
              variant="contained"
              color="primary"
              disabled={verifyButtonActive()}
              onClick={handleValidateBilling}
            >
              {t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.BILLING_VALIDATE')}
            </Button>
          </Tooltip>
          {loading && (
            <LoadingValidationDialog openDialog={openLoadingDialog} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

BillingDetail.propTypes = {
  client: PropTypes.object.isRequired
};

export default BillingDetail;
