import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import useStyles from './styles';
import WithholdingsListTabs from './WithholdingsListTabs';

const WithholdingsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.ContainerRoot} title={t('NAVIGATION.WITHHOLDINGS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <WithholdingsListTabs />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default WithholdingsListView;
