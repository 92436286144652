import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import TaxSystemRow from './TaxSystemRow';

const TaxSystemTable = ({ className, taxSystemList, loading, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.ID')}</TableCell>
      <TableCell>{t('COMMON.NAME')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return taxSystemList.map(taxSystem => (
        <TaxSystemRow {...taxSystem} key={taxSystem.id} />
      ));
    },
    [taxSystemList]
  );

  return <Table tbody={tbody} theads={theads} loading={loading} {...rest} />;
};

TaxSystemTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  taxSystemList: PropTypes.array.isRequired
};

export default TaxSystemTable;
