import React, { useEffect } from 'react';
import FadeIn from 'react-fade-in';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import Footer from 'src/components/Footer';
import { push } from 'connected-react-router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CHANGE_PASSWORD, changePassword } from 'src/store/actions/authActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from 'src/components/Loading';

import useStyles from '../styles';

const RecoverPasswordConfirmationView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingVerification = useSelector(
    isLoadingSelector([CHANGE_PASSWORD.action])
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      dispatch(changePassword(token));
    } else {
      push('/user/login');
    }
  }, []);

  return (
    <MainLayout>
      <Page
        className={classes.userVerifictionRoot}
        title={t(`AUTH_VIEWS.USER_RECOVER_PASSWORD_TITLE`)}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <div style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </div>
          <Container className={classes.userVerifictionContainter}>
            {!loadingVerification ? (
              <FadeIn transitionDuration={200}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10%'
                  }}
                >
                  <CheckCircleIcon className={classes.verifiedIcon} />
                </Box>
                <Typography className={classes.verifiedText}>
                  {t(`AUTH_VIEWS.YOUR_DATA_HAS_BEEN_SUCCESFULLY_VERIFIED`)}
                </Typography>
              </FadeIn>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loading label={t('AUTH_VIEWS.WE_ARE_VERIFYING_YOUR_DATA')} />
              </Box>
            )}
          </Container>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default RecoverPasswordConfirmationView;
