import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { useParams } from 'react-router';
import { updateDistributor } from 'src/store/actions/distributorActions';
import DetailCard from 'src/components/DetailBox/DetailCard';
import useStyles from '../styles';
import GeneralDistributorInfo from './GeneralDistributorInfo';
import DistributorGeneralDetailDialog from './GeneralDetailDialog';

const GeneralDistributor = ({ distributor, isDisabledEdit = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateDistributor(id, values, () => handleClose()));
    },
    [distributor, dispatch]
  );

  return (
    <DetailCard title={t('CLIENTS_VIEW.CLIENT_DETAIL.GENERAL_CLIENT_DATA')}>
      <GeneralDistributorInfo distributor={distributor} />
      <DistributorGeneralDetailDialog
        open={open}
        distributor={distributor}
        handleClose={handleClose}
        submit={handleSubmit}
      />
      <Grid container direction="column" alignItems="stretch">
        <Grid item className={classes.buttons}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            disabled={isDisabledEdit}
            className={clsx(classes.label, classes.buttons)}
            startIcon={<Edit />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Grid>
      </Grid>
    </DetailCard>
  );
};

GeneralDistributor.propTypes = {
  distributor: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default GeneralDistributor;
