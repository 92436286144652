import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    // eslint-disable-next-line func-names
    .test('email', 'SCHEMA_ERRORS.EMAIL_INVALID', function(value) {
      return !value || validateEmail(value);
    })
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  phone: Yup.string()
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
    // eslint-disable-next-line func-names
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', function(value) {
      return !value || isValidPhoneNumber(`+${value}`);
    })
});
