import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  loadTransactions,
  LOAD_TRANSACTIONS
} from 'src/store/actions/transactionActions';
import {
  selectTransactionsList,
  selectTransactionsListCount,
  selectTransactionsQueryOptions
} from 'src/store/selectors/transactionSelectors';
import Toolbar from './Toolbar';
import TransactionTable from './TransactionTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TransactionsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionsList);
  const count = useSelector(selectTransactionsListCount);
  const { page } = useSelector(selectTransactionsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_TRANSACTIONS.action]));
  const success = useSelector(successSelector([LOAD_TRANSACTIONS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadTransactions({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.TRANSACTION_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.TRANSACTION_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <TransactionTable
      transactions={transactions}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

const TransactionListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.TRANSACTIONS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <TransactionsList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default TransactionListView;
