import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { loadInsurances } from 'src/store/actions/insuranceActions';
import { selectInsuranceQueryOptions } from 'src/store/selectors/insuranceSelector';
import SearchBar from 'src/components/SearchBar';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectInsuranceQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (id, queryOpts) => dispatch(loadInsurances({ ...queryOpts, id })),
    400
  );

  useEffect(() => {
    dispatch(loadInsurances());
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('INSURANCES_VIEW.TITLE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('INSURANCES_VIEW.SEARCH_INSURANCE')}
              type="number"
            />
          </div>
        </div>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
