import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import DownloadButton from 'src/components/DownloadButton';
import {
  downloadCFDIIncome,
  loadCFDIIncome
} from 'src/store/actions/cfdiActions';

import { CLEAR_CFDIS_OPTIONS } from 'src/store/constants/CFDI';
import TimePeriod from 'src/utils/TimePeriod';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadCFDIIncome({
        period: TimePeriod.MONTH
      })
    );

    return () => {
      dispatch({ type: CLEAR_CFDIS_OPTIONS });
    };
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.CFDI_INCOME')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DownloadButton
              onDownload={() => {
                dispatch(downloadCFDIIncome());
              }}
              title="COMMON.DOWNLOAD_CFDIS"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
