import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import Muitheme from 'src/theme';
import DialogTransition from '../DialogTransition';

const Dialog = ({
  open = false,
  handleClose,
  title,
  children,
  widthCloseIcon = true,
  ...rest
}) => {
  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  }));

  const StyledCloseIcon = styled(IconButton)(() => ({
    float: 'right',
    width: '20px',
    height: '20px'
  }));

  const StyledTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
  }));

  return (
    <MuiDialog
      sx={{
        marginBottom: '1.5rem',
        '& .MuiDialog-paper': {
          width: Muitheme.spacing(60)
        }
      }}
      open={open}
      keepMounted
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      TransitionComponent={DialogTransition}
      {...rest}
    >
      <StyledDialogTitle id="form-delete">
        <StyledCloseIcon
          sx={{
            display: widthCloseIcon ? 'block' : 'none'
          }}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </StyledCloseIcon>
        <StyledTitleTypography variant="h2">{title}</StyledTitleTypography>
      </StyledDialogTitle>
      {children}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  widthCloseIcon: PropTypes.bool
};

export default Dialog;
