const selectDocumentType = (type, documents) => {
  if (documents && documents.length > 0) {
    const document = documents.find(doc => doc.documentType === type);
    if (document) {
      return document.documentUrl;
    }
    return '';
  }
  return '';
};

export default selectDocumentType;
