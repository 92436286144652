import { CLEAR_UPFRONT_RENTS, LOAD_UPFRONT_RENTS, SAVE_UPFRONT_RENTS_OPTIONS } from "../actions/upfrontRentsActions";

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
};

const upfrontRentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_UPFRONT_RENTS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
        totalUpfrontRents: action.totalUpfrontRents
      };
    }
    case SAVE_UPFRONT_RENTS_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options
        }
      };
    }
    case CLEAR_UPFRONT_RENTS: {
      return {
        ...state,
        options: initialState.options,
        items: [],
        count: 0
      };
    }

    default:
      return state;
  }
};

export default upfrontRentsReducer;
