import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';

const BuroValidationHistoryRow = ({ createdAt, documentUrl }) => {
  return (
    <TableRow hover>
      <TableCell>{selectFormatDate(createdAt, dateFormatType.DAY)}</TableCell>
      <TableCell>
        <DocumentLink link={documentUrl} />
      </TableCell>
    </TableRow>
  );
};

BuroValidationHistoryRow.propTypes = {
  createdAt: PropTypes.string.isRequired,
  documentUrl: PropTypes.string.isRequired
};

export default BuroValidationHistoryRow;
