import axios from 'axios';
import moment from 'moment-timezone';

const getFileFromExternalLink = (
  link,
  filename = `Documento ${moment().format('DD-MM-YYYY')}`
) => {
  axios.get(link, {
    responseType: 'blob',
    transformResponse: res => {
      try {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res);
        a.download = `${filename}.${link.split('.').pop()}`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      } catch (error) {
        console.log(`Opps, un error: ${error}`);
        console.error(error);
      }
    }
  });
};

export default getFileFromExternalLink;
