import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import { calculatePriceWhitIva } from 'src/utils/common';

const InsuranceInfo = ({ equipment }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('INSURANCES_VIEW.INSURER')}
        value={equipment.insurance.insurer.name ?? ''}
      />

      <DetailRow
        name={t('INSURANCES_VIEW.AGENCY')}
        value={equipment.insurance.agency.name ?? ''}
      />

      <DetailRow
        name={t('INSURANCES_VIEW.COVERAGE_START_DATE')}
        value={
          equipment.insurance.coverageStart
            ? selectFormatDate(
                equipment.insurance.coverageStart,
                dateFormatType.DAY
              )
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('INSURANCES_VIEW.COVERAGE_END_DATE')}
        value={
          equipment.insurance.coverageEnd
            ? selectFormatDate(
                equipment.insurance.coverageEnd,
                dateFormatType.DAY
              )
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.PRICE')}
        value={formatAmountWithDecimals(equipment.insurance.price) ?? ''}
      />

      <DetailRow
        name={`${t('COMMON.IVA')} %`}
        value={
          equipment.insurance.iva
            ? formatAmountWithDecimals(Number(equipment.insurance.iva))
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('INSURANCES_VIEW.POLICY_NUMBER')}
        value={equipment.insurance.policyId ?? ''}
      />

      <DetailRow
        name={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
        value={
          equipment.insurance.price && equipment.insurance.iva
            ? formatAmountWithDecimals(calculatePriceWhitIva(
                equipment.insurance.price,
                equipment.insurance.iva
              ))
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('COMMON.PDF_POLICY')}
        value={<DocumentLink link={equipment.insurance.policyPdfUrl} />}
      />
    </>
  );
};

InsuranceInfo.propTypes = {
  equipment: PropTypes.object.isRequired
};
export default InsuranceInfo;
