import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';
import {
  formatAmountWithDecimals,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import moment from 'moment-timezone';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import useStyles from '../styles';

const QuoteGeneralDetail = ({ quote }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('COMMON.QUOTE_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DetailRow name={t('COMMON.BRAND')} value={quote.brand.name} />

            <DetailRow name={t('COMMON.MODEL')} value={quote.model} />

            <DetailRow
              name={t('COMMON.PRICE_WITH_IVA')}
              value={formatPositiveAmount(quote.priceWithIva)}
            />

            <DetailRow
              name={t('COMMON.RESIDUAL')}
              value={formatPositiveAmount(quote.residual)}
            />

            <DetailRow
              name={t('COMMON.STATUS')}
              value={t(`ENUMS.QUOTE_STATUS.${quote.status}`)}
            />
            {quote.initialPayment >= 0 && (
              <>
                <DetailRow
                  name={t('COMMON.INITIAL_PAYMENT')}
                  value={`$${formatAmountWithDecimals(quote.initialPayment)} `}
                />
              </>
            )}

            <DetailRow
              name={t('COMMON.DAYS_FOR_FIRST_RENT')}
              value={`${translateGracePeriodValueToDaysForFirstRent(
                Number(quote.gracePeriod),
                t
              )}`}
            />

            <DetailRow
              name={t('COMMON.DATE')}
              value={moment(quote.createdAt).format('DD-MMM-YY')}
            />

            {quote.term && (
              <DetailRow name={t('COMMON.TERM')} value={`${quote.term}`} />
            )}

            {quote.comment && (
              <DetailRow name={t('COMMON.COMMENT')} value={quote.comment} />
            )}

            <DetailRow
              name={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED')}
              value={
                quote.equipmentInsuranceIncluded
                  ? t('COMMON.YES')
                  : t('COMMON.NO')
              }
            />
            <DetailRow
              name={t('COMMON.DOCUMENT')}
              value={<DocumentLink link={quote.document.documentUrl} />}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

QuoteGeneralDetail.propTypes = {
  quote: PropTypes.object.isRequired
};

export default QuoteGeneralDetail;
