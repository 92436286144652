import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  dialogRoot: {
    minWidth: theme.spacing(80),
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  confirmation: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    paddingLeft: '1.5rem'
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  buttons: {
    width: '45%',
    borderWidth: '2px'
  },
  buttonMain: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  actionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  tableRoot: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  tableBody: {
    width: '100%',
    display: 'table'
  },
  tableBox: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  reviewDataLabel: {
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  },

  reviewDataSubtitle: {
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  }
}));
