import React, { useEffect } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import { selectClientSatWsDetail } from 'src/store/selectors/clientSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  LOAD_SAT_WS_DETAIL,
  loadClientSatWsDetail
} from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Preloader from 'src/components/Preloader';
import { useTranslation } from 'react-i18next';
import SatWsToolbar from './SatSwToolbar';
import SatWsDetailTabs from './SatWsDetailTabs';
import useStyles from './styles';

const SatWsDetailView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const satWsDetail = useSelector(selectClientSatWsDetail);
  const loadingDetail = useSelector(
    isLoadingSelector([LOAD_SAT_WS_DETAIL.action])
  );

  useEffect(() => {
    dispatch(loadClientSatWsDetail(id));
  }, []);

  if (loadingDetail) {
    return (
      <Card className={classes.preloaderCard}>
        <Preloader />
      </Card>
    );
  }

  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.titleContainer}>
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.BILLING')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <SatWsToolbar client={satWsDetail} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <SatWsDetailTabs client={satWsDetail} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SatWsDetailView;
