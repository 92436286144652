import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Box, Card, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectQuoteDetail } from 'src/store/selectors/quoteSelectors';
import {
  LOAD_QUOTE_DETAIL,
  loadQuoteDetail
} from 'src/store/actions/quoteActions';
import QuoteGeneralDetail from './QuoteGeneralDetail';
import Toolbar from './Toolbar';
import QuoteClientDetail from './ClientDetailCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const QuoteDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const quote = useSelector(selectQuoteDetail);
  const loadingQuote = useSelector(
    isLoadingSelector([LOAD_QUOTE_DETAIL.action])
  );
  const success = useSelector(successSelector([LOAD_QUOTE_DETAIL.action]));

  useEffect(() => {
    dispatch(loadQuoteDetail(id));
  }, [dispatch, id]);

  return (
    <Page className={classes.root} title={`Cotización ${quote ? id : ''}`}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Toolbar quote={quote} loading={loadingQuote} />
          <Box mt={3}>
            {success && !loadingQuote && quote ? (
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} lg={6}>
                  <QuoteGeneralDetail quote={quote} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <QuoteClientDetail quote={quote} />
                </Grid>
              </Grid>
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default QuoteDetailView;
