import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadCFDIPayment } from 'src/store/actions/cfdiActions';
import {
  selectCFDIsPaymentList,
  selectCFDIsPaymentListCount,
  selectCFDISQueryOptions
} from 'src/store/selectors/cfdiSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_CFDIS_PAYMENT } from 'src/store/constants/CFDI';
import CFDIPaymentTable from './CFDIPaymentTable';
import useStyles from './styles';

const CFDIPaymentList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cfdis = useSelector(selectCFDIsPaymentList);
  const count = useSelector(selectCFDIsPaymentListCount);
  const { page } = useSelector(selectCFDISQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CFDIS_PAYMENT.action]));
  const success = useSelector(successSelector([LOAD_CFDIS_PAYMENT.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCFDIPayment({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && !count) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.CFDI_PAYMENT_TITLE" />
      </Card>
    );
  }

  return (
    <CFDIPaymentTable
      cfdis={cfdis}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default CFDIPaymentList;
