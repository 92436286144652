import { union } from 'lodash';

const JSON = {
  mimetypes: ['application/json'],
  extensions: ['.json']
};

const IMAGE = {
  mimetypes: [
    'image/gif', // gif
    'image/jpeg', // jpeg
    'image/jpg', // jpg
    'image/pjpeg', // jpeg
    'image/png', // png
    'image/x-png' // png
  ],
  extensions: ['.gif', '.jpeg', '.jpg', '.png']
};

const PDF = {
  mimetypes: ['application/pdf'],
  extensions: ['.pdf']
};

const DOCUMENT = {
  mimetypes: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'application/vnd.oasis.opendocument.text' // odt
  ],
  extensions: ['.docx', '.odt']
};

const SPREADSHEET = {
  mimetypes: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'application/vnd.oasis.opendocument.spreadsheet' // ods
  ],
  extensions: ['.xlsx', '.ods']
};

const CSV = {
  mimetypes: [
    'text/plain', // csv
    'text/csv', // csv
    'text/x-csv', // csv
    'application/csv', // csv
    'application/x-csv' // csv
  ],
  extensions: ['.csv']
};

const PRESENTATION = {
  mimetypes: [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'application/vnd.oasis.opendocument.presentation' // odp
    // 'application/x-iwork-keynote-sffkey', // key
  ],
  extensions: [
    '.pptx',
    '.odp'
    // '.key'
  ]
};

const AUDIO = {
  mimetypes: [
    'audio/aac', // aac
    'audio/aiff', // aiff
    'audio/flac', // flac
    'audio/m4a', // m4a
    'audio/mp3', // mp3
    'audio/mp4', // mp4a
    'audio/mpeg', // mp3
    'audio/mpeg3', // mp3
    'audio/ogg', // ogg
    'audio/s-wav', // wav
    'audio/vnd.wav', // wav
    'audio/wav', // wav
    'audio/wave', // wav
    'audio/x-aac', // aac
    'audio/x-aiff', // aiff
    'audio/x-flac', // flac
    'audio/x-m4a', // m4a
    'audio/x-mp3', // mp3
    'audio/x-mpeg', // mp3
    'audio/x-mpeg3', // mp3
    'audio/x-ms-wma', // wma
    'audio/x-wav', // wav
    'audio/x-pn-wav' // wav
  ],
  extensions: [
    '.aac',
    '.aiff',
    '.flac',
    '.m4a',
    '.mp3',
    '.mp4a',
    '.ogg',
    '.wav',
    '.wma'
  ]
};

const VIDEO = {
  mimetypes: [
    'application/x-troff-msvideo', // avi
    'video/3gp', // 3gp
    'video/3gpp', // 3gp
    'video/webm', // webm
    'video/avi', // avi
    'video/mp4', // mp4
    'video/mpeg', // mpg && mpeg
    'video/msvideo', // avi
    'video/quicktime', // mov & qt
    'video/x-m4v', // m4v
    'video/x-ms-asf', // asf
    'video/x-ms-wma', // wma
    'video/x-ms-wmv', // wmv
    'video/x-msvideo' // avi
  ],
  extensions: [
    '.3gp',
    '.webm',
    '.asf',
    '.avi',
    '.m4v',
    '.mov',
    '.mp4',
    '.mpeg',
    '.mpg',
    '.qt',
    '.wma',
    '.wmv'
  ]
};

const ZIP = {
  mimetypes: [
    'application/x-zip-compressed', // zip
    'multipart/x-zip', // zip
    'application/octet-stream', // zip
    'application/zip' // zip
  ],
  extensions: ['.zip']
};

const DOC = {
  mimetypes: union(
    PDF.mimetypes,
    DOCUMENT.mimetypes,
    SPREADSHEET.mimetypes,
    PRESENTATION.mimetypes
  ),
  extensions: union(
    PDF.extensions,
    DOCUMENT.extensions,
    SPREADSHEET.extensions,
    PRESENTATION.extensions
  )
};

export {
  DOC,
  ZIP,
  VIDEO,
  AUDIO,
  PRESENTATION,
  SPREADSHEET,
  DOCUMENT,
  PDF,
  CSV,
  IMAGE,
  JSON
};

export const FileTypes = {
  DOC: 'DOC',
  ZIP: 'ZIP',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  PRESENTATION: 'PRESENTATION',
  SPREADSHEET: 'SPREADSHEET',
  DOCUMENT: 'DOCUMENT',
  PDF: 'PDF',
  CSV: 'CSV',
  IMAGE: 'IMAGE',
  JSON: 'JSON'
};

export function getMimeTypes(...defs) {
  return defs.map(def => def.mimetypes).flat();
}

export const getFileType = link => {
  // Get file type even if link has or not query params
  const linkWithoutQueryParams = link.split('?')[0];

  const fileExtension = linkWithoutQueryParams.split('.').pop();

  if (PDF.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.PDF;
  }

  if (IMAGE.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.IMAGE;
  }

  if (CSV.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.CSV;
  }

  if (JSON.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.JSON;
  }

  if (VIDEO.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.VIDEO;
  }

  if (AUDIO.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.AUDIO;
  }

  if (ZIP.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.ZIP;
  }

  if (PRESENTATION.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.PRESENTATION;
  }

  if (SPREADSHEET.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.SPREADSHEET;
  }

  if (DOCUMENT.extensions.includes(`.${fileExtension.toLowerCase()}`)) {
    return FileTypes.DOCUMENT;
  }

  return null;
};
