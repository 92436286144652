import * as Yup from 'yup';

export default Yup.object().shape({
  insurerId: Yup.string().required(
    'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.INSURER_REQUIRED'
  ).typeError("SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.INSURER_REQUIRED"),
  agencyId: Yup.string().required(
    'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.AGENCY_REQUIRED'
  ).typeError("SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.AGENCY_REQUIRED"),
  coverageStart: Yup.date()
    .required('SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.ENTER_INITIAL_DATE_REQUIRED')
    .typeError(
      'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.ENTER_INITIAL_DATE_REQUIRED'
    ),
  coverageEnd: Yup.date()
    .required('SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.ENTER_END_DATE_REQUIRED')
    .typeError('SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.ENTER_END_DATE_REQUIRED'),
  price: Yup.number()
    .min(1, 'SCHEMA_ERRORS.PRICE_GREATER_THAN_ZERO')
    .required('SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.PRICE_REQUIRED'),
  iva: Yup.number()
    .required('SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.IVA_REQUIRED')
    .min(1, 'SCHEMA_ERRORS.IVA_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.IVA_LESS_THAN_ONE'),
  policyId:
    Yup.string().required(
      'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.POLICY_ID_REQUIRED'
    ) || '',
  policyPdfUrl: Yup.mixed().required(
    'SCHEMA_ERRORS.INSURANCE_DETAIL_FORM.POLICY_PDF_URL_REQUIRED'
  )
});
