import transforObjetToArr from 'src/utils/transformObjetToArray';
import * as Yup from 'yup';

const transformArrToObj = arr => {
  const obj = {};
  arr.forEach(item => {
    obj[item.name] = Yup.number()
      .min(
        0,
        'SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO_AND_LESS_THAN_ONE_HUNDRED'
      )
      .max(
        100,
        'SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO_AND_LESS_THAN_ONE_HUNDRED'
      )
      .required('SCHEMA_ERRORS.ENTER_VALUE');
  });
  return obj;
};

const getSchema = data => {
  const schema = Yup.object().shape(transformArrToObj(data));
  return schema;
};

export const validateNufiBlock = values => {
  const errors = {};
  const list = transforObjetToArr(values);
  const sum = list.reduce((acc, item) => {
    return acc + Number(item.value);
  }, 0);

  if (sum !== 100) {
    errors.parameterSum = 'SCHEMA_ERRORS.ERROR_SUM_OF_PARAMETERS';
  }

  return errors;
};

export const validateJudicialRecord = values => {
  const errors = {};
  if (
    values.VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE &&
    Number(values.VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE) !== 100
  ) {
    errors.parameterSum = 'SCHEMA_ERRORS.ERROR_SUM_OF_PARAMETERS';
    return errors;
  }
  const list = transforObjetToArr(values);

  const isPreviusGreaterThanCurrent = list.some((item, index) => {
    if (index === 0) return false;
    return Number(list[index - 1].value) < Number(item.value);
  });

  if (isPreviusGreaterThanCurrent) {
    errors.parameterSum =
      'SCHEMA_ERRORS.THE_VALUE_MUST_BE_LESS_THANT_OR_EQUAL_TO_THE_ADOVE_PARAMETER';
  }

  return errors;
};

export const validateParameterSumForIneScore = values => {
  const errors = {};
  if (
    values.VALIDATE_INE_SCORE &&
    Number(values.VALIDATE_INE_SCORE) !== 100
  ) {
    errors.parameterSum = 'SCHEMA_ERRORS.ERROR_SUM_OF_PARAMETERS';
    return errors;
  }

  return errors;
};

export default getSchema;
