import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { loadQuotes } from 'src/store/actions/quoteActions';

import { QuoteStatus } from 'src/utils/constants/quotes';
import QuotesDetailListTabs from './QuotesDetailListTabs';

const QuotesDetailView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      loadQuotes({
        distributorProfileId: Number(id),
        status: QuoteStatus.IN_REVIEW
      })
    );
  }, [dispatch, id]);

  return <QuotesDetailListTabs />;
};

export default QuotesDetailView;
