import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmount } from 'src/utils/formatAmout';
import { useSelector } from 'react-redux';
import { selectCFDISQueryOptions } from 'src/store/selectors/cfdiSelectors';

const CFDIExpenseRow = ({ expense, period, total }) => {
  const { period: periodType } = useSelector(selectCFDISQueryOptions);

  return (
    <TableRow hover align="center">
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="start"
      >
        {selectFormatDate(
          period,
          periodType === dateFormatType.YEAR
            ? dateFormatType.YEAR
            : dateFormatType.MONTH
        )}
      </TableCell>
      <TableCell align="center">{formatAmount(expense)}</TableCell>
      <TableCell align="center">{formatAmount(total)}</TableCell>
    </TableRow>
  );
};

CFDIExpenseRow.propTypes = {
  expense: PropTypes.number,
  period: PropTypes.string,
  total: PropTypes.number
};

export default CFDIExpenseRow;
