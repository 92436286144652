import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

// eslint-disable-next-line import/prefer-default-export
export const getQuoteList = serviceBase({
  request: options =>
    axiosClient.get('/quote', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const rejectQuote = serviceBase({
  request: ({ id, values }) => axiosClient.post(`/quote/${id}`, values)
});

export const archiveQuoteById = serviceBase({
  request: ({ id }) => axiosClient.post(`/quote/${id}/archive`)
});

export const getQuoteDetail = serviceBase({
  request: id => axiosClient.get(`/quote/${id}`)
});

export const assignKam = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/quote/assign-kam`, { id, ...values })
});

export const adminNewQuoteCreation = serviceBase({
  request: values => axiosClient.post('/quote', values)
});

export const createQuickQuote = serviceBase({
  request: values => axiosClient.post('/quote/quick-quote', values)
});

export const assingClientToQuote = serviceBase({
  request: ({ quoteId, clientId }) =>
    axiosClient.post(`/quote/${quoteId}/assign`, { clientId })
});

export const calculateRent = serviceBase({
  request: values =>
    axiosClient.post('/distributor-profile/calculate-rent', values)
});

export const createAdminContractRequest = serviceBase({
  request: values =>
    axiosClient.post(`/quote/send-to-contract/${values.id}`, values)
});
