import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormLabel, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import DropdownMenu from 'src/components/DropdownMenu';
import theme from 'src/theme';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const FormOptionsType = [
  { name: 'COMMON.YES', key: true },
  { name: 'COMMON.NO', key: false }
];

const StepSeven = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();

  const initialState = useMemo(() => makeInitialState(data), [data]);

  const { t } = useTranslation();

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext, data]
  );

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          height: '20%'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_SEVEN.TITLE')}
        </Typography>
      </Box>

      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '80%'
              }}
            >
              <Box className={classes.container}>
                <FormLabel>
                  {t(
                    'BUREAU_FORM.STEP_SEVEN.IS_DOCTOR_WITH_TITLE_OR_CERTIFICATE'
                  )}
                </FormLabel>
                <DropdownMenu
                  list={FormOptionsType}
                  value={values.isDoctorWithTitle}
                  setValue={value => setFieldValue('isDoctorWithTitle', value)}
                  error={Boolean(
                    touched.isDoctorWithTitle && errors.isDoctorWithTitle
                  )}
                  errorText={
                    touched.isDoctorWithTitle && t(errors.isDoctorWithTitle)
                  }
                />
                <FormLabel>
                  {t('BUREAU_FORM.STEP_SEVEN.YEARS_OPERATING')}
                </FormLabel>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.yearsOperating && errors.yearsOperating
                  )}
                  fullWidth
                  helperText={
                    touched.yearsOperating && t(errors.yearsOperating)
                  }
                  margin="normal"
                  name="yearsOperating"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.yearsOperating}
                  variant="outlined"
                  type="number"
                />
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
StepSeven.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepSeven;
