import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAmortizationBalance = serviceBase({
  request: options =>
    axiosClient.get('/accounting/amortization-balance', {
      params: options
    }),
  retry: 0
});

export const downloadExcelListAmorizationBalance = serviceBase({
  request: options =>
    axiosClient.get('accounting/amortization-balance/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Amortizacion.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});
