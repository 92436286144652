import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dialogRoot: {
    width: theme.spacing(60)
  },
  preloaderCard: {
    height: '82vh'
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '100%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11
    }
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8
    }
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  tooltip: {
    fontSize: 15
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%'
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  confirmation: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    paddingLeft: '1.5rem'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  detailRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  validationIconsContainter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  validationIcons: {
    display: 'flex',
    alignItems: 'center'
  }
}));
