import { pullMoffinReport as pullMoffinReportService } from 'src/services/moffinSevice';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';
import {
  loadAvalBuroValidationDetail,
  loadClientBuroValidationDetail
} from './clientActions';

export const PULL_MOFFIN_REPORT = asyncActionCreator('PULL_MOFFIN_REPORT');

export const pullClientMoffinReport = (
  rfc,
  clientId,
  closeCallback
) => async dispatch => {
  dispatch({ type: PULL_MOFFIN_REPORT.start });

  try {
    const { data } = await pullMoffinReportService(rfc);

    if (closeCallback) {
      closeCallback();
    }

    dispatch({
      type: PULL_MOFFIN_REPORT.success,
      payload: { ...data }
    });

    dispatch(
      showMessage({
        message: 'MESSAGE_SUCCESS_PULL_MOFFIN_REPORT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    return dispatch(loadClientBuroValidationDetail(clientId));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.WARNING })
    );
    closeCallback();
    return dispatch({ type: PULL_MOFFIN_REPORT.failure });
  }
};

export const pullAvalMoffinReport = (
  rfc,
  avalId,
  closeCallback
) => async dispatch => {
  dispatch({ type: PULL_MOFFIN_REPORT.start });

  try {
    const { data } = await pullMoffinReportService(rfc);

    if (closeCallback) {
      closeCallback();
    }

    dispatch({
      type: PULL_MOFFIN_REPORT.success,
      payload: { ...data }
    });

    dispatch(
      showMessage({
        message: 'MESSAGE_SUCCESS_PULL_MOFFIN_REPORT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    return dispatch(loadAvalBuroValidationDetail(avalId));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: PULL_MOFFIN_REPORT.failure });
  }
};
