import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Container } from '@mui/material';
import Page from 'src/components/Page';
import {
  CLEAR_DISTRIBUTORS,
  loadDistributorDetail
} from 'src/store/actions/distributorActions';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import DistributorTabs from './DistributorTabs';
import useStyles from './styles';

const DistributorDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const distributor = useSelector(selectDistributorDetail);

  useEffect(() => {
    dispatch(loadDistributorDetail(id));
    return () => {
      dispatch({ type: CLEAR_DISTRIBUTORS });
    };
  }, []);

  return (
    <Page
      className={classes.root}
      title={`Distribuidor ${distributor.commercialName ?? ''}`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <DistributorTabs />
        </Container>
      </FadeIn>
    </Page>
  );
};

export default DistributorDetailView;
