import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateAval, deleteAval } from 'src/store/actions/clientActions';
import useStyles from '../styles';
import GeneralAvalInfo from './GeneralAvalInfo';
import GeneralDetailDialog from './AvalDialog';
import DeleteAvalDialog from '../DeleteAvalDialog/DeleteAvalDialog';

const GeneralAvalPhysicalClient = ({ aval, isDisabledEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const disabledEdit = isDisabledEdit;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClickOpenDelete = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  const handleCloseDelete = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteAval(aval.id, aval.clientId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateAval(aval.id, values, () => handleClose()));
    },
    [aval, dispatch]
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.AVAL_DETAIL.GENERAL_AVAL_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <GeneralAvalInfo aval={aval} />
          </TableBody>
        </Table>

        <GeneralDetailDialog
          rolEditable={false}
          open={open}
          handleClose={handleClose}
          submit={handleSubmit}
          aval={aval}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              disabled={isDisabledEdit}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>

        <Grid item className={classes.buttons} style={{ marginBottom: '32px' }}>
          <DeleteAvalDialog
            open={openDelete}
            handleClose={handleCloseDelete}
            handleSubmit={handleSubmitDelete}
          />
          <Button
            disabled={disabledEdit}
            onClick={handleClickOpenDelete}
            variant="contained"
            color="primary"
            className={clsx(classes.outlinedDelete, classes.buttons)}
            startIcon={<DeleteIcon />}
          >
            {t('COMMON.DELETE')}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

GeneralAvalPhysicalClient.propTypes = {
  aval: PropTypes.object,
  isDisabledEdit: PropTypes.bool
};

export default GeneralAvalPhysicalClient;
