/* eslint-disable react/prop-types */
import React from 'react';
import { styled } from '@mui/material';
import AstrocapLogo from 'src/assets/images/AstrocapLogo';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'auto',
  backgroundColor: theme.palette.common.white
}));

const Banner = styled('div')(({ theme }) => ({
  width: '39%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  height: '100%'
}));

const MainLayout = ({ children }) => {
  return (
    <Root>
      <Banner
        sx={{
          '@media (max-width: 1280px)': {
            display: 'none'
          }
        }}
      >
        <AstrocapLogo />
      </Banner>
      <Content>{children}</Content>
    </Root>
  );
};

export default MainLayout;
