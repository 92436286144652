import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import theme from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import {
  getDocumentsById,
  LOAD_DOCUMENTS
} from 'src/store/actions/clientActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import DocumentLink from '../DocumentLink/DocumentLink';
import Loading from '../Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentListModal = ({
  avalId,
  clientId,
  contractId,
  promissoryNoteId,
  investorId,
  title,
  open,
  handleClose,
  documentType,
  investmentId,
  loadAmendingAgreements = false
}) => {
  const { t } = useTranslation();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const loading = useSelector(isLoadingSelector([LOAD_DOCUMENTS.action]));
  const success = useSelector(successSelector([LOAD_DOCUMENTS.action]));

  useEffect(() => {
    if (open === documentType) {
      if (avalId) {
        return dispatch(
          getDocumentsById({ avalId, documentType }, setDocuments)
        );
      }
      if (clientId) {
        return dispatch(
          getDocumentsById({ clientId, documentType }, setDocuments)
        );
      }
      if (contractId && loadAmendingAgreements) {
        return dispatch(
          getDocumentsById(
            { contractId, documentType, loadAmendingAgreements },
            setDocuments
          )
        );
      }
      if (contractId && !promissoryNoteId) {
        return dispatch(
          getDocumentsById({ contractId, documentType }, setDocuments)
        );
      }
      if (promissoryNoteId) {
        return dispatch(
          getDocumentsById({ promissoryNoteId, documentType }, setDocuments)
        );
      }
      if (investorId) {
        return dispatch(
          getDocumentsById({ investorId, documentType }, setDocuments)
        );
      }
      if (investmentId) {
        return dispatch(
          getDocumentsById({ investmentId, documentType }, setDocuments)
        );
      }
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '50vh',
          maxHeight: '70vh'
        }
      }}
      open={open === documentType}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          color: theme.palette.primary.main,
          marginTop: theme.spacing(1),
          '& h2': {
            fontWeight: 400
          }
        }}
        id="form-documents-dialog-title"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          sx={{
            float: 'right',
            width: '20px',
            height: '20px'
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography color="primary" variant="h2">
          {t(`ENUMS.DOCUMENTS.${title}`) || t('COMMON.DOCUMENTS')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', padding: 2 }}>
        {success && !loading && documents ? (
          <TableContainer sx={{ maxHeight: '90%' }}>
            <Table aria-label="documents table" stickyHeader>
              <TableHead>
                <TableRow component="tr">
                  <TableCell
                    variant="head"
                    align="left"
                    sx={{ backgroundColor: 'white !important' }}
                  >
                    {t('COMMON.DATE')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="left"
                    sx={{ backgroundColor: 'white !important' }}
                  >
                    {t('COMMON.DOCUMENTS')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map(row => (
                  <TableRow
                    key={row.documentUrl}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      {selectFormatDate(row.createdAt, dateFormatType.DAY)}
                    </TableCell>
                    <TableCell align="left">
                      <DocumentLink link={row.documentUrl} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loading />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

DocumentListModal.propTypes = {
  open: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avalId: PropTypes.string,
  clientId: PropTypes.string,
  contractId: PropTypes.number,
  promissoryNoteId: PropTypes.number,
  loadAmendingAgreements: PropTypes.bool,
  investorId: PropTypes.string,
  investmentId: PropTypes.number
};

export default DocumentListModal;
