import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { AgentStatus } from 'src/utils/constants/distributors';
import useStyles from '../../styles';

const ChangeAgentStatusDialog = ({ open, handleClose, onSubmit, status }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const handleStatusChange = () => {
    if (status === AgentStatus.ACTIVE) {
      return onSubmit({ status: AgentStatus.INACTIVE });
    }
    return onSubmit({ status: AgentStatus.ACTIVE });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle className={classes.header} id="form-distributor-delete">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {status === 'ACTIVE'
            ? t('DISTRIBUTORS_VIEW.AGENTS.DISABLE_KAM')
            : t('DISTRIBUTORS_VIEW.AGENTS.ENABLE_KAM')}
        </Typography>
      </DialogTitle>
      <DialogContentText
        className={classes.confirmation}
        id="form-distributor-delete-dialog"
      >
        {t('DISTRIBUTORS_VIEW.AGENTS.CHANGE_STATUS_KAM_DESCRIPTION')}
      </DialogContentText>
      <Grid
        container
        style={{ marginBottom: '1.5rem' }}
        direction="column"
        alignItems="stretch"
      >
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.label, classes.buttons)}
            onClick={handleStatusChange}
            color="primary"
            variant="outlined"
          >
            {t('COMMON.ACCEPT')}
          </Button>
        </Grid>
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.outlined, classes.buttons)}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            {t('COMMON.CANCEL')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ChangeAgentStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default ChangeAgentStatusDialog;
