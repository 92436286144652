import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { selectAuthId } from 'src/store/selectors/authSelectors';
import PropTypes from 'prop-types';
import {
  DirectorQuotesTabsIndex,
  QuoteStatus
} from 'src/utils/constants/quotes';
import { loadQuotes } from 'src/store/actions/quoteActions';
import KamQuotesToolbar from '../KamQuotesToolbar';
import DirectorQuotesTabs from './DirectorQuotesTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const DirectorQuotesView = ({ isDirectorView = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [areDirectorQuotes, setAreDirectorQuotes] = useState(isDirectorView);
  const id = useSelector(selectAuthId);

  const handleTabChange = useCallback(
    (event, newValue) => {
      if (newValue === DirectorQuotesTabsIndex.QUOTES) {
        dispatch(
          loadQuotes({
            distributorProfileId: null,
            status: QuoteStatus.DRAFT
          })
        );
        setAreDirectorQuotes(false);
      }
      if (newValue === DirectorQuotesTabsIndex.MY_QUOTES) {
        dispatch(
          loadQuotes({
            distributorProfileId: id,
            status: QuoteStatus.DRAFT
          })
        );
        setAreDirectorQuotes(true);
      }
    },
    [isDirectorView]
  );

  return (
    <Page className={classes.root} title={t('USER_NAVIGATION.QUOTES')}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            <KamQuotesToolbar isDirectorView={areDirectorQuotes} />
            <DirectorQuotesTabs
              currentView={DirectorQuotesTabsIndex.MY_QUOTES}
              handleTabChange={handleTabChange}
            />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

DirectorQuotesView.propTypes = {
  isDirectorView: PropTypes.bool
};

export default DirectorQuotesView;
