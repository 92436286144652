import { HIDE_MESSAGE, SHOW_MESSAGE } from '../actions/snackBarActions';

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    autoHideDuration: 5000,
    message: '',
    variant: null
  }
};

const snackBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return {
        state: true,
        options: {
          ...initialState.options,
          ...action.options
        }
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        state: null
      };
    }
    default: {
      return state;
    }
  }
};

export default snackBarReducer;
