const dateFormatType = {
  DAY: 'DAY',
  MONTH_AND_YEAR: 'MONTH_AND_YEAR',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
  ONLY_DAY: 'ONLY_DAY',
  DATE_COMPLETE: 'DATE_COMPLETE'
};

export default dateFormatType;
