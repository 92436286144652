import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import InvestorRow from './InvestorsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ investors, t }) =>
  investors.map(investor => (
    <InvestorRow key={investor.id} t={t} {...investor} />
  ));

const InvestorsTable = ({ className, investors, loading, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="id" align="center" sx={{ width: '5%' }}>
          {t('COMMON.ID')}
        </TableCell>

        <TableCell variant="head" key="fullName" align="center">
          {t('COMMON.FULL_NAME')}
        </TableCell>

        <TableCell variant="head" key="type" align="center">
          {t('COMMON.TYPE')}
        </TableCell>

        <TableCell variant="head" key="antiquity" align="center">
          {t('INVESTORS_VIEW.ANTIQUITY')}
        </TableCell>

        <TableCell variant="head" key="investment" align="center">
          {t('INVESTORS_VIEW.INVESTMENT')}
        </TableCell>

        <TableCell variant="head" key="ownership" align="center">
          {t('INVESTORS_VIEW.OWNERSHIP')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(() => () => <Tbody t={t} investors={investors} />, [
    investors,
    t
  ]);

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

InvestorsTable.propTypes = {
  className: PropTypes.string,
  investors: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool
};

InvestorsTable.defaultProps = {
  count: 0
};

export default InvestorsTable;
