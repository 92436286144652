import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import EquipmentTypeRow from './EquipmentTypeRow';

const EquipmentTypeTable = ({
  className,
  equipmentTypes,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit]
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.EQUIPMENT_TYPE')}</TableCell>
      <TableCell align="center">{t('COMMON.PRODUCT_KEY')}</TableCell>
      <TableCell align="right">{t('COMMON.ACTION')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return equipmentTypes.map(equipmentType => (
        <EquipmentTypeRow
          {...equipmentType}
          key={equipmentType.id}
          openEdit={openEdit(equipmentType)}
          handleDelete={handleDelete}
        />
      ));
    },
    [equipmentTypes]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

EquipmentTypeTable.propTypes = {
  className: PropTypes.string,
  equipmentTypes: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default EquipmentTypeTable;
