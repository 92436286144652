import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useDispatch, useSelector } from 'react-redux';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';

import {
  selectClientSatWsHistoryCount,
  selectClientsQueryOptions
} from 'src/store/selectors/clientSelectors';
import { loadSatWsHistory } from 'src/store/actions/clientActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SatWsHistoryRow from './SatWsHistoryRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ histories }) => {
  return histories.map(history => {
    return <SatWsHistoryRow key={history.id} {...history} />;
  });
};

const SatWsHistoryTable = ({ className, histories, loading, ...rest }) => {
  const { t } = useTranslation();
  const { page } = useSelector(selectClientsQueryOptions);
  const { id } = useParams();
  const count = useSelector(selectClientSatWsHistoryCount);
  const dispatch = useDispatch();

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadSatWsHistory(id, { page: value }));
    },
    [dispatch]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, histories]
  );

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell style={{ width: '10%' }}>{t('COMMON.DATE')}</TableCell>
      <TableCell style={{ width: '45%' }}>
        {t('CLIENTS_VIEW.BILLING_DETAIL.CHANGED_PARAMETERS')}
      </TableCell>
      <TableCell style={{ width: '45%' }}>{t('COMMON.VALUE')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody histories={histories} />, [
    histories
  ]);

  if (!loading && (!histories || histories.length === 0)) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.SAT_WS_HISTORY_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      style={{ tableLayout: 'fixed', width: '100%' }}
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

SatWsHistoryTable.propTypes = {
  className: PropTypes.string,
  histories: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number,
  count: PropTypes.number
};

export default SatWsHistoryTable;
