import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 3,
    marginBottom: theme.spacing()
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
  },
  filename: {
    flex: 1,
    paddingLeft: theme.spacing()
  },
  uploadButton: {
    color: theme.palette.primary.main
  },
  box: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  buttons: {
    width: '45%',
    borderWidth: '2px'
  },
  buttonMain: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  tableRoot: {
    display: 'flex'
  },
  tableBody: {
    width: '100%',
    display: 'table'
  },
  tableBox: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  reviewDataLabel: {
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  },

  reviewDataSubtitle: {
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  },
  datepicker: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}));
