import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import RentResumeListView from './RentResumeList';
import IncomeResumeListView from './IncomeResumeList';

const IncomeView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/rent`} component={RentResumeListView} />
      <Route path={`${match.url}/resume`} component={IncomeResumeListView} />
    </>
  );
};

IncomeView.propTypes = {
  match: PropTypes.object.isRequired
};

export default IncomeView;
