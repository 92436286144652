export const selectInterestBalance = state => state.interestBalance;
export const selectInteresetBalanceList = state =>
  selectInterestBalance(state).items;
export const selectInteresetBalanceListCount = state =>
  selectInterestBalance(state).count;
export const selectInteresetBalanceQueryOptions = state => {
  const { options } = selectInterestBalance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
