import {
  deleteInsurance,
  editInsurance,
  getAllInsurances
} from 'src/services/insuranceService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import uploadSingleFile from './commonActions';
import { showMessage } from './snackBarActions';
import {
  createNewInsurance,
  getInsuranceById
} from '../../services/insuranceService';
import { loadEquipmentDetail } from './equipmentsActions';
import { selectInsuranceQueryOptions } from '../selectors/insuranceSelector';

export const SAVE_INSURANCE_OPTIONS = 'SAVE_INSURANCE_OPTIONS';
export const CLEAR_INSURANCE_OPTIONS = 'CLEAR_INSURANCE_OPTIONS';
export const LOAD_INSURANCES = asyncActionCreator('LOAD_INSURANCES');
export const CREATE_INSURANCE = asyncActionCreator('CREATE_INSURANCE');
export const UPDATE_INSURANCE = asyncActionCreator('UPDATE_INSURANCE');
export const DELETE_INSURANCE = asyncActionCreator('DELETE_INSURANCE');
export const LOAD_INSURANCE_DETAIL = asyncActionCreator(
  'LOAD_INSURANCE_DETAIL'
);
export const CLEAR_INSURANCE_DETAIL = 'CLEAR_INSURANCE_DETAIL';
export const CLEAR_INSURANCES = 'CLEAR_INSURANCES';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectInsuranceQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.equipmentId) {
    reqOptions.equipmentId = queryOpts.equipmentId;
  }
  if (queryOpts.id) {
    reqOptions.policyId = queryOpts.id;
  }
  if (queryOpts.remainingDays) {
    reqOptions.remainingDays = queryOpts.remainingDays;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_INSURANCE_OPTIONS });
};

export const clearClients = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_INSURANCES });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadInsurances = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INSURANCES.start });

    dispatch({
      type: SAVE_INSURANCE_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const data = await getAllInsurances(reqOptions);

    return dispatch({
      type: LOAD_INSURANCES.success,
      ...data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INSURANCES.failure });
  }
};

export const loadInsuranceDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INSURANCE_DETAIL.start });
    const data = await getInsuranceById(id);

    return dispatch({
      type: LOAD_INSURANCE_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INSURANCE_DETAIL.failure });
  }
};

export const createInsurance = (equipmentId, values) => async dispatch => {
  try {
    dispatch({ type: CREATE_INSURANCE.start });
    let { policyPdfUrl } = values;

    if (policyPdfUrl && policyPdfUrl.inDb) {
      policyPdfUrl = '';
    }

    if (policyPdfUrl) {
      policyPdfUrl = await uploadSingleFile(policyPdfUrl);
    }

    const data = await createNewInsurance({
      equipmentId,
      insurance: {
        ...values,
        insurerId: values.insurerId.key,
        agencyId: values.agencyId.key,
        policyPdfUrl
      }
    });

    dispatch(
      showMessage({
        message: 'CREATE_INSURANCE',
        variant: 'success'
      })
    );
    dispatch(loadEquipmentDetail(equipmentId));
    return dispatch({
      type: UPDATE_INSURANCE.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_INSURANCE.failure });
  }
};

export const updateInsurance = (
  equipmentId,
  policyId,
  values
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_INSURANCE.start });
    let { policyPdfUrl } = values;

    if (policyPdfUrl && policyPdfUrl.inDb) {
      policyPdfUrl = '';
    }

    if (policyPdfUrl) {
      policyPdfUrl = await uploadSingleFile(policyPdfUrl);
    }

    const data = await editInsurance({
      policyId,
      insurance: { ...values, policyPdfUrl, iva: Number(values.iva) }
    });

    dispatch(
      showMessage({
        message: 'UPDATE_INSURANCE',
        variant: 'success'
      })
    );
    dispatch(loadEquipmentDetail(equipmentId));
    return dispatch({
      type: UPDATE_INSURANCE.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_INSURANCE.failure });
  }
};

export const unassignInsurance = (equipmentId, policyId) => async dispatch => {
  try {
    dispatch({ type: DELETE_INSURANCE.start });
    const data = await deleteInsurance(policyId);

    dispatch(
      showMessage({
        message: 'DELETE_INSURANCE',
        variant: 'success'
      })
    );
    dispatch(loadEquipmentDetail(equipmentId));
    return dispatch({
      type: DELETE_INSURANCE.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_INSURANCE.failure });
  }
};
