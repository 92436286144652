import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import InvestorsListView from './Investors';
import InvestorDetail from './InvertorDetail';
import InvestmentDetailView from '../investment/InvertmentDetail';
import AgentsListView from './Agents';
import AgentDetail from './AgentDetail';
import InvestmentListView from '../investment/Investments/InvestmentListView';

const InvestorsView = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}/list`} component={InvestorsListView} />
      <Route exact path={`${match.url}/agents`} component={AgentsListView} />
      <Route
        path={`${match.url}/detail/:id/:tab?`}
        component={InvestorDetail}
      />
      <Route
        exact
        path={`${match.url}/investments`}
        component={InvestmentListView}
      />
      <Route
        exact
        path={`${match.url}/investment/:id`}
        component={InvestmentDetailView}
      />
      <Route
        exact
        path={`${match.url}/agents/detail/:id/:tab?`}
        component={AgentDetail}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/list`} />}
      />
    </>
  );
};

InvestorsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default InvestorsView;
