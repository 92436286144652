import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  CLEAR_INVESTOR_IDENTITY_VALIDATION,
  LOAD_INVESTOR_IDENTITY_VALIDATION,
  loadInvestorIdentityValidation
} from 'src/store/actions/investorActions';
import { selectInvestorIdentityValidation } from 'src/store/selectors/investorSelector';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Preloader from 'src/components/Preloader';
import ValidationDetail from './ValidationDetail';
import useStyles from '../styles';

const NuffiView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const investorValidation = useSelector(selectInvestorIdentityValidation);
  const loading = useSelector(
    isLoadingSelector([LOAD_INVESTOR_IDENTITY_VALIDATION.action])
  );
  const success = useSelector(
    successSelector([LOAD_INVESTOR_IDENTITY_VALIDATION.action])
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(loadInvestorIdentityValidation(id));
    return () => {
      dispatch({ type: CLEAR_INVESTOR_IDENTITY_VALIDATION });
    };
  }, [id]);

  return (
    <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.titleContainer}>
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTITY')}
            </Typography>
          </Box>
        </Grid>
        {loading && !success ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        ) : (
          <>
            <Grid item sx={12} lg={12}>
              <ValidationDetail validation={investorValidation} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default NuffiView;
