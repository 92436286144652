import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { GetApp, FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import FILTER_TYPES from 'src/utils/constants/filter';
import { selectCapitalBalanceQueryOptions } from 'src/store/selectors/capitalBalanceSelectors';
import {
  CLEAR_CAPITAL_BALANCE,
  downloadExcelCapitalBalance,
  loadCapitalBalance
} from 'src/store/actions/capitalBalanceActions';
import useStyles from './styles';

const filters = [
  {
    title: 'COMMON.DATE',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date',
        view: ['year', 'month']
      }
    ]
  }
];

function getFilterOptions(options) {
  let month = null;
  let year = null;
  if (options.date) {
    month = options.date.month() + 1;
    year = options.date.year();
  }

  return { year, month };
}

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCapitalBalanceQueryOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    dispatch(loadCapitalBalance());
    return () => dispatch({ type: CLEAR_CAPITAL_BALANCE });
  }, []);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      dispatch(
        loadCapitalBalance({ ...queryOptions, ...getFilterOptions(event) })
      );
    },
    [setFilterValues, queryOptions, getFilterOptions]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelCapitalBalance());
  }, [dispatch]);

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.CAPITAL_BALANCE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <GetApp className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
