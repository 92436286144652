import * as Yup from 'yup';

export default Yup.object().shape({
  options: Yup.array().test(
    'is-required',
    'SCHEMA_ERRORS.OPTIONS_IS_REQUIRED',
    value => {
      return value.length > 0;
    }
  )
});
