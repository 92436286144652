import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';
import ContractDateDetail from './ContractDateDetail';

const DateDetail = ({ contract }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CONTRACT_VIEW.CONTRACT_DETAIL.CONTRACT_DATES')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <ContractDateDetail contract={contract} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

DateDetail.propTypes = {
  contract: PropTypes.object.isRequired
};

export default DateDetail;
