export const selectUpfrontRents = state => state.upfrontRents;
export const selectUpfrontRentsList = state => selectUpfrontRents(state).items;
export const selectUpfrontRentsListCount = state => selectUpfrontRents(state).count;
export const selectTotalUpfrontRents = state => selectUpfrontRents(state).totalUpfrontRents;
export const selectUpfrontRentsQueryOptions = state => {
  const { options } = selectUpfrontRents(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
