import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const AgentAlert = ({ agents, loading }) => {
  const [open] = useState(true);
  const { t } = useTranslation();

  if (!loading) {
    if (!agents.length) {
      return (
        <Collapse in={open}>
          <Alert severity="info">
            {t('INVESTORS_VIEW.CREATION_FORM.ALERT.NO_AGENTS_FIRST')}
            <Link to="/investors/agents">
              {t('INVESTORS_VIEW.CREATION_FORM.ALERT.NO_AGENTS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

export default AgentAlert;

AgentAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  agents: PropTypes.array.isRequired
};
