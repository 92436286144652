import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  loadDistributorDetail,
  sendToValidate
} from 'src/store/actions/distributorActions';
import DetailCard from 'src/components/DetailBox/DetailCard';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { SEND_TO_VALIDATE } from 'src/store/actions/clientActions';
import LoadingValidationDialog from 'src/components/LoadingValidationDialog';
import useStyles from '../styles';
import NufiDistributorInfo from './NufiDistributorInfo';

const DistributorNufiData = ({ distributor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const loading = useSelector(isLoadingSelector([SEND_TO_VALIDATE.action]));

  const handleCloseLoadingDialog = () => {
    setOpen(false);
    dispatch(loadDistributorDetail(id));
  };

  const handleOpenLoadingDialog = () => {
    setOpen(true);
  };

  const handleValidate = () => {
    handleOpenLoadingDialog();
    return dispatch(sendToValidate(id, handleCloseLoadingDialog));
  };

  return (
    <DetailCard title={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.TITLE')}>
      <NufiDistributorInfo distributor={distributor} />
      <Grid container direction="column" alignItems="stretch">
        <Grid item className={classes.buttons}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.buttons}
            onClick={handleValidate}
          >
            {distributor.nufiValidation
              ? t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.REVALIDATE')
              : t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.VALIDATE')}
          </Button>
        </Grid>
      </Grid>
      {loading && (
        <LoadingValidationDialog
          openDialog={open}
          onClose={handleCloseLoadingDialog}
        />
      )}
    </DetailCard>
  );
};

DistributorNufiData.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default DistributorNufiData;
