import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import GeneralView from './General';
import ContactsView from './Contacts';
import CatalogsView from './Catalogs';
import BrandsAndModelsView from './BrandsAndModelsView';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const UserDistributorTabs = ({ distributor }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'USER_NAVIGATION.MY_COMPANY']}
        current={distributor.commercialName}
      />
      <Typography
        sx={{ fontWeight: '400', textTransform: 'capitalize', mb: 2, mt: 2 }}
        color="primary"
        variant="h1"
      >
        {`${t('USER_NAVIGATION.MY_COMPANY')} - ${
          distributor ? distributor.commercialName : ''
        }`}
      </Typography>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.GENERAL')} />
          <Tab label={t('COMMON.CONTACTS')} />
          <Tab label={t('COMMON.CATALOG')} />
          <Tab label={t('COMMON.BRANDS_AND_MODELS')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView distributor={distributor} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContactsView />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CatalogsView />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BrandsAndModelsView />
      </TabPanel>
    </Box>
  );
};

UserDistributorTabs.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default UserDistributorTabs;
