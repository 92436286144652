import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import TableCell from 'src/components/Table/TableCell';
import { QuoteStatus } from 'src/utils/constants/quotes';
import QuotesRow from './QuotesRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ quotes }) => {
  return quotes.map(quote => {
    return <QuotesRow key={quote.id} {...quote} />;
  });
};

const QuotesTable = ({
  className,
  loading,
  onChangePage,
  quotes,
  page,
  status,
  count,
  onSortChange,
  ...rest
}) => {
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
      onSortChange(property, isDesc ? 'asc' : 'desc');
    },
    [order, orderBy, setOrder, setOrderBy, onSortChange]
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={onChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, quotes]
  );

  const theads = useMemo(
    () => () => (
      <TableRow component="tr">
        <TableCell style={{ width: '5%' }}>{t('COMMON.ID')}</TableCell>
        <TableCell
          variant="head"
          key="date"
          sx={{ width: '8%' }}
          sortDirection={orderBy === 'createdAt' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'createdAt'}
            direction={orderBy === 'createdAt' ? order : 'desc'}
            onClick={createSortHandler('createdAt')}
          >
            <Typography variant="head" color="textSecondary" noWrap>
              {t('COMMON.DATE')}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell style={{ width: '8%' }} key="client" align="center">
          {t('COMMON.CLIENT')}
        </TableCell>
        <TableCell style={{ width: '8%' }} key="distributor" align="center">
          {t('COMMON.DISTRIBUTOR')}
        </TableCell>
        <TableCell style={{ width: '8%' }} key="equipment" align="center">
          {t('COMMON.MODEL')}
        </TableCell>
        <TableCell style={{ width: '8%' }} key="kam" align="center">
          {t('COMMON.KAM')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="price">
          {t('COMMON.PRICE')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="iva">
          {t('COMMON.IVA')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="priceWithIva">
          {t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="term">
          {t('COMMON.TERM')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="initial_payment">
          {t('COMMON.INITIAL_PAYMENT')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="last_status">
          {t('COMMON.LAST_STATUS')}
        </TableCell>
        <TableCell style={{ width: '8%' }} align="center" key="insurance">
          {t('NAVIGATION.INSURANCE')}
        </TableCell>
        {status === QuoteStatus.DRAFT && (
          <>
            <TableCell
              style={{ width: '8%' }}
              align="center"
              key="sentForClientApproval"
            >
              {t('QUOTES_VIEW.CLIENT_APPROVAL')}
            </TableCell>
          </>
        )}
        <TableCell style={{ width: '2%' }} key="options">
          {' '}
        </TableCell>
      </TableRow>
    ),
    [quotes]
  );

  const tbody = useMemo(() => () => <Tbody quotes={quotes} />, [quotes]);

  if (!loading && (!quotes || quotes.length === 0)) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.QUOTES_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.QUOTES_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <Table
      style={{ tableLayout: 'fixed', width: '100%' }}
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

QuotesTable.propTypes = {
  className: PropTypes.string,
  quotes: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number,
  count: PropTypes.number,
  onChangePage: PropTypes.func,
  status: PropTypes.string,
  onSortChange: PropTypes.func
};

export default QuotesTable;
