import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  emailList: Yup.array()
    .min(1, 'SCHEMA_ERRORS.EMAIL_REQUIRED')
    .of(
      Yup.string()
        .test(
          'email',
          'SCHEMA_ERRORS.ONE_OR_MORE_EMAIL_WITH_INVALID_FORMAT',
          validateEmail
        )
        .required('SCHEMA_ERRORS.EMAIL_REQUIRED')
    )
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED')
});
