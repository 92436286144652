import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';
import DocumentInvestorInfo from './DocumentClientDetail';

const InvestorDocuments = ({ investor }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('INVESTORS_VIEW.INVESTOR_DETAIL.INVESTOR_DOCUMENTS')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DocumentInvestorInfo investor={investor} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

InvestorDocuments.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorDocuments;
