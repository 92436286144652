import {
  CLEAR_BILLING,
  CLEAR_BILLING_OPTIONS,
  LOAD_BILLING,
  LOAD_BILLING_FAILS_LIST,
  SAVE_BILLING_OPTIONS
} from '../actions/billingActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  billingFailures: [],
  billingFailuresCount: 0,
  count: 0
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BILLING.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
        balance: action.balance
      };
    }
    case SAVE_BILLING_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_BILLING_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_BILLING_FAILS_LIST.success: {
      return {
        ...state,
        billingFailures: action.items,
        billingFailuresCount: action.count
      };
    }
    case CLEAR_BILLING: {
      return {
        ...initialState,
        billingFailuresCount: state.billingFailuresCount
      };
    }
    default: {
      return state;
    }
  }
};

export default billingReducer;
