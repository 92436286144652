import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

// eslint-disable-next-line import/prefer-default-export
export const getQuoteList = serviceBase({
  request: options =>
    axiosClient.get('/distributor-profile/quotes', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const kamNewQuoteCreation = serviceBase({
  request: values => axiosClient.post('/distributor-profile/quotes', values),
  retry: 0
});

export const getQuoteDetailForKam = serviceBase({
  request: id => axiosClient.get(`/distributor-profile/quote/${id}`)
});

export const createQuickQuote = serviceBase({
  request: values =>
    axiosClient.post('/distributor-profile/quick-quote', values)
});

export const assignClientToQuote = serviceBase({
  request: ({ quoteId, clientId }) =>
    axiosClient.post(`/distributor-profile/quote/${quoteId}/assign`, {
      clientId
    })
});

export const sendQuoteToWhatsapp = serviceBase({
  request: ({ quoteId, phone }) =>
    axiosClient.post(`/quote/${quoteId}/send-to-whatsapp`, {
      phone
    })
});
