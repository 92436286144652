import React from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { Link } from 'react-router-dom';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';

const AmortizationBalanceRow = ({
  initialBalance,
  finalBalance,
  amortization,
  monthsOfLife,
  equipment,
  equipmentId,
  price,
  coverageStart
}) => {
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/inventory/${equipmentId}`}>{equipment}</Link>
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(initialBalance)}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(price)}</TableCell>
      <TableCell align="center">
        {selectFormatDate(coverageStart, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(amortization)}</TableCell>
      <TableCell align="center">{monthsOfLife}</TableCell>
      <TableCell align="center">{formatPositiveAmount(finalBalance)}</TableCell>
    </TableRow>
  );
};

AmortizationBalanceRow.propTypes = {
  initialBalance: PropTypes.number,
  finalBalance: PropTypes.number,
  amortization: PropTypes.number,
  monthsOfLife: PropTypes.number,
  equipment: PropTypes.string,
  equipmentId: PropTypes.string,
  price: PropTypes.number,
  coverageStart: PropTypes.string
};

export default AmortizationBalanceRow;
