import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { distributorDetailTabsIndex } from 'src/utils/constants/distributors';
import { useHistory, useParams } from 'react-router';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { useSelector } from 'react-redux';
import BrandsAndModelsView from 'src/views/userViews/UserDistributorView/BrandsAndModelsView';
import GeneralView from './General';
import ContactsView from './Contacts';
import CatalogsView from './Catalogs';
import AgentDetailView from './AgentsDetail/AgentDetail';
import ParameterDetailView from './Parameter';

const distributorDetailMenuList = [
  {
    value: distributorDetailTabsIndex.GENERAL,
    label: 'COMMON.GENERAL'
  },
  {
    value: distributorDetailTabsIndex.CONTACTS,
    label: 'COMMON.CONTACTS'
  },
  {
    value: distributorDetailTabsIndex.CATALOG,
    label: 'COMMON.CATALOG'
  },
  {
    value: distributorDetailTabsIndex.BRANDS_AND_MODELS,
    label: 'COMMON.BRANDS_AND_MODELS'
  },
  {
    value: distributorDetailTabsIndex.PROFILES,
    label: 'COMMON.PROFILES'
  },
  {
    value: distributorDetailTabsIndex.PARAMETERS,
    label: 'NAVIGATION.PARAMETERS'
  }
];

const DistributorTabs = () => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const { id, tab } = useParams();
  const distributor = useSelector(selectDistributorDetail);

  const [value, setValue] = useState(false);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/distributors/business/${id}/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !tab) {
      setValue(distributorDetailTabsIndex.GENERAL);
    }
    if (tab) {
      if (Object.values(distributorDetailTabsIndex).includes(tab)) {
        setValue(tab);
      } else {
        push(`/buisiness/distributors/${id}`);
      }
    }
  }, [id, tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb doNotRedirectIndexList={[0]} />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {distributorDetailMenuList.map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={distributorDetailTabsIndex.GENERAL}>
        <GeneralView distributor={distributor} />
      </TabPanel>
      <TabPanel value={value} index={distributorDetailTabsIndex.CONTACTS}>
        <ContactsView />
      </TabPanel>
      <TabPanel value={value} index={distributorDetailTabsIndex.CATALOG}>
        <CatalogsView />
      </TabPanel>
      {/* This is planned to be remove in the future */}
      <TabPanel
        value={value}
        index={distributorDetailTabsIndex.BRANDS_AND_MODELS}
      >
        <BrandsAndModelsView />
      </TabPanel>
      <TabPanel value={value} index={distributorDetailTabsIndex.PROFILES}>
        <AgentDetailView />
      </TabPanel>
      <TabPanel value={value} index={distributorDetailTabsIndex.PARAMETERS}>
        <ParameterDetailView />
      </TabPanel>
    </Box>
  );
};

export default DistributorTabs;
