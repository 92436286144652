import { Badge, Box, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNotificationsList,
  selectUnveiwedNotificationsCount
} from 'src/store/selectors/notificationSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_NOTIFICATIONS,
  markAllAsViewd
} from 'src/store/actions/notificationsActions';
import PropTypes from 'prop-types';
import NotificationMenuList from './NotificationMenuList';

const NotificationMenu = ({ open }) => {
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(false);
  const notifications = useSelector(selectNotificationsList);
  const notificationUnviewedCount = useSelector(
    selectUnveiwedNotificationsCount
  );
  const loadingNotifications = useSelector(
    isLoadingSelector([LOAD_NOTIFICATIONS.action])
  );
  const dispatch = useDispatch();

  const toggleNotificationCenter = event => {
    setAnchorEl(event.currentTarget);
    dispatch(markAllAsViewd());
  };

  const handleCloseNotificationMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      setAnchorEl(ref.current);
    }
  }, [open]);

  return (
    <Box sx={{ margin: '8px' }}>
      <IconButton size="large" onClick={toggleNotificationCenter} ref={ref}>
        <Badge badgeContent={notificationUnviewedCount} color="primary">
          <NotificationsIcon color="action" />
        </Badge>
      </IconButton>

      <NotificationMenuList
        anchorEl={anchorEl}
        loading={loadingNotifications}
        notifications={notifications}
        onClose={handleCloseNotificationMenu}
      />
    </Box>
  );
};

export default NotificationMenu;

NotificationMenu.propTypes = {
  open: PropTypes.bool
};
