/* eslint-disable no-nested-ternary */
import { TableRow } from '@mui/material';
import React from 'react';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import ValidationIconStatus from 'src/utils/selectValidationIcon';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getRedirectionLink } from 'src/utils/constants/backgroundValidation';

const BackgroundValidationRow = ({ owner, validation, score }) => {
  const { pathname } = useLocation();

  return (
    <TableRow>
      <TableCell align="center" width="15%">
        <Link to={getRedirectionLink(pathname, owner.id)}>
          {owner.fullname}
        </Link>
      </TableCell>
      <TableCell align="center" width="5%">
        {score}
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.curp} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.rfc} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.siger} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.rug} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.blackList} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.professionalCard} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.sat69} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.criminalRecords} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.email} />
      </TableCell>
      <TableCell align="center" width="10%">
        <ValidationIconStatus value={validation.phone} />
      </TableCell>
    </TableRow>
  );
};

export default BackgroundValidationRow;

BackgroundValidationRow.propTypes = {
  owner: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  score: PropTypes.number.isRequired
};
