import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import CFDIIssuedRow from './CFDIIssuedRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ cfdis }) =>
  cfdis.map(cfdi => <CFDIIssuedRow key={cfdi.id} {...cfdi} />);

const CFDIIssuedTable = ({
  className,
  cfdis,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.DATE')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.FOLIO')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.COMPANY_NAME')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.RFC')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.TYPE')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.SERIE')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.AMOUNT')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.IVA')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.AMOUNT_WITH_IVA')}
      </TableCell>
      <TableCell style={{ width: '11%' }} align="center">
        {t('COMMON.STATUS')}
      </TableCell>
      <TableCell style={{ width: '1%' }} align="center">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody cfdis={cfdis} />, [cfdis]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, cfdis]
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CFDIIssuedTable.propTypes = {
  className: PropTypes.string,
  cfdis: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

CFDIIssuedTable.defaultProps = {
  count: 0
};

export default CFDIIssuedTable;
