import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  Grid,
  Dialog
} from '@mui/material';
import { addPointsPhone } from 'src/utils/common';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useDispatch } from 'react-redux';
import { updateUserProfile } from 'src/store/actions/userProfileActions';
import useStyles from './styles';
import EditUserDialog from './EditUserDialog';

const GeneralUserInfo = ({ user }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setOpen(true);
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(updateUserProfile(values));
      handleClose();
    },
    [handleClose, dispatch]
  );

  return (
    <Card variant="elevation">
      <Grid className={classes.boxHeader}>
        <Typography className={classes.header} component="h4" variant="h4">
          {t('USER_PROFILE.MY_INFORMATION')}
        </Typography>
        <MoreMenu deleteDisabled handleClickOption={handleEdit} />
      </Grid>
      <Table classes={{ root: classes.tableRoot }} component="div">
        <TableBody classes={{ root: classes.tableBody }} component="div">
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.NAME')}
            </TableCell>
            <TableCell component="div">
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.contentRow}
              >
                {user.name || t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.PHONE')}
            </TableCell>
            <TableCell component="div">
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.contentRow}
              >
                {addPointsPhone(user.phone) || t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.OFFICE_PHONE')}
            </TableCell>
            <TableCell component="div">
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.contentRow}
              >
                {user.officePhone
                  ? addPointsPhone(user.officePhone)
                  : t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell
              component="div"
              className={classes.GeneralData}
              sx={{ border: 'none' }}
            >
              {t('COMMON.EMAIL')}
            </TableCell>
            <TableCell component="div" sx={{ border: 'none' }}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.contentRow}
              >
                {user.email || t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={open}>
        <EditUserDialog
          open={open}
          user={user}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      </Dialog>
    </Card>
  );
};
GeneralUserInfo.propTypes = {
  user: PropTypes.object.isRequired
};

GeneralUserInfo.propTypes = {
  user: PropTypes.object.isRequired
};

export default GeneralUserInfo;
