import { asyncActionCreator } from 'src/utils/loadingUtils';

export const LOAD_CLIENT_BURO_VALIDATION_HISTORY = asyncActionCreator(
  'LOAD_CLIENT_BURO_VALIDATION_HISTORY'
);
export const LOAD_AVAL_BURO_VALIDATION_HISTORY = asyncActionCreator(
  'LOAD_AVAL_BURO_VALIDATION_HISTORY'
);

export const CLEAR_CLIENT_BURO_VALIDATION_HISTORY =
  'CLEAR_CLIENT_BURO_VALIDATION_HISTORY';
export const CLEAR_AVAL_BURO_VALIDATION_HISTORY =
  'CLEAR_AVAL_BURO_VALIDATION_HISTORY';

export const DISABLE_CLIENT = asyncActionCreator('DISABLE_CLIENT');
export const REJECT_CLIENT = asyncActionCreator('REJECT_CLIENT');
export const CHANGE_CLIENT_STATUS = asyncActionCreator('CHANGE_CLIENT_STATUS');
