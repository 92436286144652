import * as Yup from 'yup';

export default Yup.object().shape({
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.CONTRIBUTIONS_FORM.AMOUNT_POSITIVE')
    .required('SCHEMA_ERRORS.CONTRIBUTIONS_FORM.AMOUNT_REQUIRED'),
  date: Yup.date()
    .typeError('SCHEMA_ERRORS.CONTRIBUTIONS_FORM.DATE_INVALID')
    .required('SCHEMA_ERRORS.CONTRIBUTIONS_FORM.DATE_REQUIRED')
});
