export const START = 'START';
export const NOT_STARTED = 'NOT_STARTED';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';

export const asyncActionCreator = baseName => ({
  action: `${baseName}`,
  failure: `${baseName}_${FAILURE}`,
  start: `${baseName}_${START}`,
  success: `${baseName}_${SUCCESS}`,
  baseName
});
