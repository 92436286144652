import React from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import {
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import { roundNumber } from 'src/utils/common';

const IncomeResumeRow = ({
  initialBalance,
  rent,
  capitalContribution,
  residual,
  surcharge,
  discount,
  payment,
  finalBalance,
  period,
  date,
  initialPayment
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">{selectFormatDate(date, period)}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialBalance))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialPayment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(rent))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(capitalContribution))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(residual))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(surcharge))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(discount))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(payment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(finalBalance))}
      </TableCell>
    </TableRow>
  );
};

IncomeResumeRow.propTypes = {
  initialBalance: PropTypes.number,
  rent: PropTypes.number,
  capitalContribution: PropTypes.number,
  residual: PropTypes.number,
  surcharge: PropTypes.number,
  discount: PropTypes.number,
  payment: PropTypes.number,
  finalBalance: PropTypes.number,
  period: PropTypes.string,
  date: PropTypes.string,
  initialPayment: PropTypes.number
};

export default IncomeResumeRow;
