import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  email: Yup.string()
    .test('email', 'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID', validateEmail)
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  officePhone: Yup.string()
    .test('officePhone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  homePhone: Yup.string()
    .test('homePhone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
});
