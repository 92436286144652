import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min';
import WithholdingsListView from './WithholdingsResume';

const WithholdingsView = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/resume`}
        component={WithholdingsListView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/withholdings`} />}
      />
    </>
  );
};

WithholdingsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default WithholdingsView;
