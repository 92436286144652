import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import AddButton from 'src/components/AddButton/AddButton';
import { createQuoteCategoryAction } from 'src/store/actions/quoteActions';
import useStyles from './styles';
import QuoteCategoryFormDialog from './QuoteCategoryFormDialog';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleOpenQuoteForm = () => {
    setOpen(true);
  };

  const handleCloseQuoteForm = () => {
    setOpen(false);
  };

  const handleCreateQuote = useCallback(
    values => {
      dispatch(createQuoteCategoryAction(values));
      handleCloseQuoteForm();
    },
    [dispatch, handleCloseQuoteForm]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.QUOTE_CATEGORIES')}
          </Typography>
          <AddButton title="COMMON.ADD" onClick={handleOpenQuoteForm} />
        </Box>
        <QuoteCategoryFormDialog
          open={open}
          handleClose={handleCloseQuoteForm}
          onSubmit={handleCreateQuote}
        />
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
