import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    height: '100%'
  },
  login: {
    maxWidth: '80%',
    padding: 0,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(1.5)} ${theme.spacing(10)}`,
    whiteSpace: 'nowrap'
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: '250',
    letterSpacing: -0.5,
    textAlign: 'left',
    marginBottom: theme.spacing()
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: '400',
    letterSpacing: 0.15,
    textAlign: 'center'
  },
  footer: {
    marginLeft: '20%',
    marginTop: 'auto',
    width: '65%',
    textAlign: 'center'
  },
  footerText: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(3),
    fontSize: theme.spacing(1.6),
    fontWeight: '400',
    letterSpacing: 0.25
  },
  userVerifictionRoot: {
    height: '100%',
    padding: `${theme.spacing(4)} ${theme.spacing(10)}`,
    width: '100%'
  },
  userVerifictionContainter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  preloaderCard: {
    height: '82vh'
  },
  verifiedIcon: {
    width: '100px',
    height: '100px',
    color: '#4caf50'
  },
  verifiedText: {
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '5%'
  },
  recoverPasswordText: {
    fontSize: '1rem',
    fontWeight: '500',
    textAlign: 'center'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  }
}));
