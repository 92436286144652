import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import SearchBar from 'src/components/SearchBar';
import { selectQuotesQueryOptions } from 'src/store/selectors/quoteSelectors';
import {
  CLEAR_QUOTES,
  createQuickQuoteAction,
  createQuote,
  loadQuotes
} from 'src/store/actions/quoteActions';
import PropTypes from 'prop-types';
import { QuoteCreationOption, QuoteStatus } from 'src/utils/constants/quotes';
import { selectAuthId } from 'src/store/selectors/authSelectors';
import MenuButton from 'src/components/MenuButton/MenuButton';
import QuickQuote from '@mui/icons-material/ScheduleSend';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import CreateQuoteForm from './CreateQuoteForm';
import CreateQuickQuoteForm from './CreateQuickQuoteForm';

const quoteCreationOptions = [
  {
    label: 'COMMON.CREATE_NORMAL_QUOTE',
    key: QuoteCreationOption.CREATE_QUOTE,
    icon: <AddIcon />
  },
  {
    label: 'COMMON.QUICK_QUOTE',
    key: QuoteCreationOption.QUICK_QUOTE,
    icon: <QuickQuote />
  }
];

const KamQuotesToolbar = ({ isDirectorView }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = useSelector(selectAuthId);
  const queryOptions = useSelector(selectQuotesQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [openQuotesCreationForm, setUserQuotesCreationForm] = useState(false);
  const [
    openQuickQuotesCreationForm,
    setUserQuickQuotesCreationForm
  ] = useState(false);

  const handleOpenCreationForm = useCallback(() => {
    setUserQuotesCreationForm(true);
  }, [setUserQuotesCreationForm]);

  const handleCloseCreationForm = useCallback(() => {
    setUserQuotesCreationForm(false);
  }, [setUserQuotesCreationForm]);

  const handleOpenQuickCreationForm = useCallback(() => {
    setUserQuickQuotesCreationForm(true);
  }, [setUserQuickQuotesCreationForm]);

  const handleCloseQuickCreationForm = useCallback(() => {
    setUserQuickQuotesCreationForm(false);
  }, [setUserQuickQuotesCreationForm]);

  const handleCreateNewQuote = useCallback(
    (values, setData) => {
      dispatch(createQuote(values, () => handleCloseCreationForm(), setData));
    },
    [dispatch, handleCloseCreationForm]
  );

  const handleCreateQuickQuote = useCallback((values, setData) => {
    dispatch(
      createQuickQuoteAction(values, handleCloseQuickCreationForm, setData)
    );
  }, []);

  const debounceSearch = useDebounce(
    (clientName, queryOpts) =>
      dispatch(
        loadQuotes({
          ...queryOpts,
          clientName,
          page: 0
        })
      ),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, {
        ...queryOptions
      });
    },
    [setSearchValue, debounceSearch, queryOptions, isDirectorView]
  );

  const handleOpenQuoteCreation = option => {
    switch (option) {
      case QuoteCreationOption.CREATE_QUOTE:
        handleOpenCreationForm();
        break;
      case QuoteCreationOption.QUICK_QUOTE:
        handleOpenQuickCreationForm();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(
      loadQuotes({
        status: QuoteStatus.DRAFT,
        distributorProfileId: isDirectorView ? id : null
      })
    );
    return () => dispatch({ type: CLEAR_QUOTES });
  }, []);

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontWeight: '400',
              textTransform: 'capitalize'
            }}
            color="primary"
            variant="h1"
          >
            {t('USER_NAVIGATION.QUOTES')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />

            <MenuButton
              label={t('COMMON.CREATE_QUOTE')}
              options={quoteCreationOptions}
              handleClickOption={handleOpenQuoteCreation}
            />

            <CreateQuoteForm
              open={openQuotesCreationForm}
              handleCreateQuote={handleCreateNewQuote}
              handleClose={handleCloseCreationForm}
              key="create-quote-form"
            />

            <CreateQuickQuoteForm
              open={openQuickQuotesCreationForm}
              handleCreateQuickQuote={handleCreateQuickQuote}
              handleClose={handleCloseQuickCreationForm}
              key="create-quick-quote-form"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

KamQuotesToolbar.propTypes = {
  isDirectorView: PropTypes.bool
};

export default KamQuotesToolbar;
