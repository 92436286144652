import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { PersonType } from 'src/utils/constants/clients';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const getPersonTypeByRFC = rfc => {
  if (rfc) {
    if (rfc.length === 12) {
      return PersonType.LEGAL;
    }
    return PersonType.PHYSICAL;
  }
  return true;
};

const StepOne = ({ data, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const onNext = useCallback(
    async values => {
      const personType = getPersonTypeByRFC(values.rfc);
      handleNext({ ...values, personType });
    },
    [handleNext, data]
  );

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.rfc && errors.rfc)}
                  fullWidth
                  helperText={touched.rfc && t(errors.rfc)}
                  label={t('COMMON.RFC')}
                  margin="normal"
                  name="rfc"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e);
                  }}
                  onInput={e => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  type="text"
                  value={values.rfc}
                  variant="outlined"
                />
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
};

export default StepOne;
