import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  denomination: Yup.string().required('SCHEMA_ERRORS.DENOMINATION_REQUIRED'),
  commercialName: Yup.string().required(
    'SCHEMA_ERRORS.COMMERCIAL_NAME_REQUIRED'
  ),
  equipmentTypeId: Yup.string().required(
    'SCHEMA_ERRORS.EQUIPMENT_TYPE_REQUIRED'
  ),
  zipCode: Yup.string()
    .length(5, 'SCHEMA_ERRORS.ZIP_CODE_LENGTH')
    .required('SCHEMA_ERRORS.ZIP_CODE_REQUIRED'),
  city: Yup.string().required('SCHEMA_ERRORS.CITY_REQUIRED'),
  state: Yup.string().required('SCHEMA_ERRORS.STATE_REQUIRED'),
  rfc: Yup.string()
    .matches(
      /^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED'),
  quoteCategory: Yup.string().required('SCHEMA_ERRORS.VALUE_REQUIRED')
});
