import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import { DistributorRoles } from 'src/utils/constants/auth';

const QuoteCreationAlert = ({
  clients,
  catalogs,
  loading,
  distributorRole
}) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading) {
    if (!clients.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_CLIENTS_FIRST')}
            <Link to="/user/clients">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_CLIENTS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }

    if (!catalogs.length) {
      if (distributorRole === DistributorRoles.DIRECTOR) {
        return (
          <Collapse in={open}>
            <Alert severity="error">
              {t(
                'QUOTES_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_DIRECTOR_FIRST'
              )}
              <Link to="/user/distributor">
                {t(
                  'QUOTES_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_DIRECTOR_SECOND'
                )}
              </Link>
            </Alert>
          </Collapse>
        );
      }

      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_KAM')}
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

QuoteCreationAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  catalogs: PropTypes.array.isRequired,
  distributorRole: PropTypes.string.isRequired
};

export default QuoteCreationAlert;
