import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import StatusIcon from 'src/components/StatusIcon';
import { ContributionStatus } from 'src/utils/constants/contracts';
import { IconStatusAndColor } from 'src/utils/constants/common';
import { useTranslation } from 'react-i18next';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';

const getStatusIcon = status => {
  switch (status) {
    case ContributionStatus.COMPLETED:
      return (
        <StatusIcon
          description="CONTRACT_VIEW.PAYMENT_COMPLETED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case ContributionStatus.PENDING:
      return (
        <StatusIcon
          description="COMMON.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );

    default:
      return (
        <StatusIcon
          description="COMMON.NONE"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

const ContributionsRow = ({ date, updateDate, amount, status, concept, iva, amountIvaIncluded }) => {
  const statusIcon = useMemo(() => getStatusIcon(status), [status]);
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center" width="15%">
        {selectFormatDate(date, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center" width="15%">
        {updateDate === date
          ? '-'
          : selectFormatDate(updateDate, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center" width="20%">
        {concept
          ? t(`TRANSACTION.CATEGORY.${concept}`)
          : t(`TRANSACTION.CATEGORY.OTHER`)}
      </TableCell>
      <TableCell align="center" width="20%">
        {formatAmountWithDecimals(amount, 2)}
      </TableCell>
      <TableCell align="center" width="20%">
        {formatAmountWithDecimals(iva, 2)}
      </TableCell>
      <TableCell align="center" width="20%">
        {formatAmountWithDecimals(amountIvaIncluded, 2)}
      </TableCell>
      <TableCell align="center" width="20%">
        {statusIcon}
      </TableCell>
    </TableRow>
  );
};

ContributionsRow.propTypes = {
  date: PropTypes.string,
  updateDate: PropTypes.string,
  amount: PropTypes.number,
  iva: PropTypes.number,
  amountIvaIncluded: PropTypes.number,
  status: PropTypes.string,
  concept: PropTypes.string
};

export default ContributionsRow;
