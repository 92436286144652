import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import BillingConceptRow from './BillingConceptRow';

const BillingConceptTable = ({
  className,
  billingConcepts,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.CODE')}</TableCell>
      <TableCell>{t('COMMON.NAME')}</TableCell>
      <TableCell>{t('COMMON.DESCRIPTION')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return billingConcepts.map(billingConcept => (
        <BillingConceptRow {...billingConcept} key={billingConcept.id} />
      ));
    },
    [billingConcepts]
  );

  return <Table tbody={tbody} theads={theads} loading={loading} {...rest} />;
};

BillingConceptTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  billingConcepts: PropTypes.array.isRequired
};

export default BillingConceptTable;
