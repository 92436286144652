import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadClients } from 'src/store/actions/clientActions';
import ClientStatus from 'src/utils/constants/clients';
import { selectClientsQueryOptions } from 'src/store/selectors/clientSelectors';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import ClientsList from './ClientsList';

const ClientMenuList = [
  {
    value: ClientStatus.ACTIVE,
    label: 'ENUMS.CLIENT_STATUS.ACTIVE',
    description: 'ENUMS.CLIENTS_DESCRIPTION.ACTIVE'
  },
  {
    value: ClientStatus.PROSPECT,
    label: 'ENUMS.CLIENT_STATUS.PROSPECT',
    description: 'ENUMS.CLIENTS_DESCRIPTION.PROSPECT'
  },
  {
    value: ClientStatus.LEFT,
    label: 'ENUMS.CLIENT_STATUS.LEFT',
    description: 'ENUMS.CLIENTS_DESCRIPTION.LEFT'
  },
  {
    value: ClientStatus.REJECTED,
    label: 'ENUMS.CLIENT_STATUS.ARCHIVED',
    description: 'ENUMS.CLIENTS_DESCRIPTION.REJECTED'
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ClientsListTabs = () => {
  const { push } = useHistory();
  const { tab } = useParams();
  const [value, setValue] = useState(ClientStatus.ACTIVE);
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectClientsQueryOptions);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/clients/${newValue}`);
      dispatch(
        loadClients({
          ...queryOpts,
          status: newValue,
          page: 0
        })
      );
    },
    [setValue, queryOpts]
  );

  useEffect(() => {
    if (!tab) {
      setValue(ClientStatus.ACTIVE);
    }
    if (tab) {
      if (Object.values(ClientStatus).includes(tab)) {
        setValue(tab);
      } else {
        push(`/clients`);
      }
    }
  }, [tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          {ClientMenuList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              description={t(item.description)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={value}>
        <ClientsList clientStatus={value} />
      </TabPanel>
    </Box>
  );
};

export default ClientsListTabs;
