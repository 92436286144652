import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadDenominations
} from 'src/store/actions/catalogActions';
import PropTypes from 'prop-types';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import DenominationTable from './DenominationTable';

// eslint-disable-next-line react/prop-types
const DenominationList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const denominations = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadDenominations());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []);

  return (
    <>
      <DenominationTable
        denominations={denominations}
        loading={loading}
        handleOpenEdit={handleOpenEdit}
        handleDelete={handleDelete}
      />
      {denominations.length === 0 && !loading && (
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.DENOMINATION_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.DENOMINATION_DESCRIPTION"
        />
      )}
    </>
  );
};

DenominationList.protoTypes = {
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default DenominationList;
