/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SimpleAccordion from '@mui/material/Accordion';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Accordion = ({ list, openFirst = false }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(openFirst ? 'panel0' : false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ margin: '1rem' }}>
      {list.map((item, index) => {
        return (
          <SimpleAccordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography
                sx={{
                  width: '50%',
                  flexShrink: 0,
                  color: 'text.secondary',
                  fontSize: '1rem',
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  textTransform: 'uppercase'
                }}
              >
                {t(item.title)}
              </Typography>
              {item.subtitle && (
                <Typography sx={{ color: 'text.secondary' }}>
                  {item.subtitle}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.content}</Typography>
            </AccordionDetails>
          </SimpleAccordion>
        );
      })}
    </Box>
  );
};

Accordion.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.elementType
    }).isRequired
  ),
  openFirst: PropTypes.bool
};

export default Accordion;
