import React, { useCallback, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { QuoteStatus } from 'src/utils/constants/quotes';
import { useDispatch } from 'react-redux';
import { loadQuotes } from 'src/store/actions/quoteActions';
import Tab from 'src/components/Tab';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Typography } from '@mui/material';
import QuotesDetailList from './QuotesDetailList';

const selectTabs = tabIndex => {
  switch (tabIndex) {
    case 0:
      return QuoteStatus.IN_REVIEW;
    case 1:
      return QuoteStatus.DRAFT;
    case 2:
      return QuoteStatus.REJECTED;
    case 3:
      return QuoteStatus.ACCEPTED;
    case 4:
      return QuoteStatus.ARCHIVED;
    default:
      return QuoteStatus.IN_REVIEW;
  }
};

const QuotesDetailListTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeTabs = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadQuotes({
          status: selectTabs(newValue),
          clientId: Number(id),
          page: 0
        })
      );
    },
    [setTabIndex]
  );

  return (
    <Box sx={{ flexGrow: 1, mt: '2rem' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0px 20px',
          marginBottom: '1rem'
        }}
      >
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {t('COMMON.QUOTES')}
        </Typography>
      </Box>
      <AppBar
        elevation={0}
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChangeTabs}
        >
          <Tab
            label={t('ENUMS.QUOTE_STATUS.IN_REVIEW')}
            description={t('ENUMS.QUOTES_DESCRIPTION.CONTRACT_REQUESTS')}
          />
          <Tab
            label={t('ENUMS.QUOTE_STATUS.DRAFT')}
            description={t('ENUMS.QUOTES_DESCRIPTION.QUOTES')}
          />
          <Tab
            label={t('ENUMS.QUOTE_STATUS.REJECTED')}
            description={t('ENUMS.QUOTES_DESCRIPTION.REJECTED_REQUESTS')}
          />
          <Tab
            label={t('ENUMS.QUOTE_STATUS.ACCEPTED')}
            description={t('ENUMS.QUOTES_DESCRIPTION.ACCEPTED_REQUESTS')}
          />
          <Tab
            label={t('ENUMS.QUOTE_STATUS.ARCHIVED')}
            description={t('ENUMS.QUOTES_DESCRIPTION.ARCHIVED_REQUESTS')}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={tabIndex}>
        <QuotesDetailList />
      </TabPanel>
    </Box>
  );
};

export default QuotesDetailListTabs;
