import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dialogRoot: {
    width: theme.spacing(60)
  },
  preloaderCard: {
    height: '82vh'
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  tooltip: {
    fontSize: 15
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%'
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },

  box: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  buttons: {
    width: '45%',
    borderWidth: '2px'
  }
}));
