import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectUpfrontRentsList,
  selectUpfrontRentsListCount,
  selectUpfrontRentsQueryOptions
} from 'src/store/selectors/upfrontRentsSelector';
import { LOAD_UPFRONT_RENTS, loadUpfrontRents } from 'src/store/actions/upfrontRentsActions';
import UpfrontRentsTable from './UpfrontRentsTable';

const UpfrontRentsList = () => {
  const dispatch = useDispatch();
  const upfrontRents = useSelector(selectUpfrontRentsList);
  const count = useSelector(selectUpfrontRentsListCount);
  const { page } = useSelector(selectUpfrontRentsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_UPFRONT_RENTS.action]));
  const success = useSelector(successSelector([LOAD_UPFRONT_RENTS.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadUpfrontRents({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.UPFRONT_RENTS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.UPFRONT_RENTS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <UpfrontRentsTable
      upfrontRents={upfrontRents}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default UpfrontRentsList;
