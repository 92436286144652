import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { SigningMethod } from 'src/utils/constants/parameters';
import ParameterRow from '../../common/ParameterRow';

const list = [
  {
    key: SigningMethod.E_FIRMA.key,
    name: SigningMethod.E_FIRMA.name
  },
  {
    key: SigningMethod.ELECTRONIC_SIGNATURE.key,
    name: SigningMethod.ELECTRONIC_SIGNATURE.name
  }
];

const SigningParameterTable = ({
  className,
  parameters,
  handleOpenEdit,
  isDisabledEdit,
  ...rest
}) => {
  const { t } = useTranslation();

  const openEdit = parameter => () => {
    handleOpenEdit(parameter, list);
  };

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('PARAMETERS_VIEW.NAME')}</TableCell>
      <TableCell align="center">{t('PARAMETERS_VIEW.VALUE')}</TableCell>
      <TableCell style={{ width: '1rem' }}>{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <ParameterRow
          {...parameter}
          isDisabledEdit={isDisabledEdit}
          key={parameter.id}
          openEdit={openEdit(parameter)}
        />
      ));
    },
    [parameters, openEdit]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

SigningParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  isDisabledEdit: PropTypes.bool
};

export default SigningParameterTable;
