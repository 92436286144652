import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadCFDIS } from 'src/store/actions/cfdiActions';
import {
  selectCFDISList,
  selectCFDISListCount,
  selectCFDISQueryOptions
} from 'src/store/selectors/cfdiSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_CFDIS } from 'src/store/constants/CFDI';
import CFDIIssuedTable from './CFDIIssuedTable';
import useStyles from './styles';

const CFDIIssuedList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cfdis = useSelector(selectCFDISList);
  const count = useSelector(selectCFDISListCount);
  const { page } = useSelector(selectCFDISQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CFDIS.action]));
  const success = useSelector(successSelector([LOAD_CFDIS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCFDIS({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.CFDI_ISSUED_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.CFDI_ISSUED_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <CFDIIssuedTable
      cfdis={cfdis}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default CFDIIssuedList;
