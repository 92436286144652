import React, { useCallback, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import Tab from 'src/components/Tab';
import { useDispatch } from 'react-redux';
import TimePeriod from 'src/utils/TimePeriod';
import { loadCFDIIncome } from 'src/store/actions/cfdiActions';
import CFDIIncomeList from './CFDIIncomeList';

const selectTabs = tabIndex => {
  switch (tabIndex) {
    case 0:
      return TimePeriod.MONTH;
    case 1:
      return TimePeriod.YEAR;

    default:
      return TimePeriod.MONTH;
  }
};

const CFDIIncomeTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeTabs = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadCFDIIncome({
          period: selectTabs(newValue),
          page: 0
        })
      );
    },
    [setTabIndex]
  );

  return (
    <Box sx={{ flexGrow: 1, mt: '2rem' }}>
      <AppBar
        elevation={0}
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChangeTabs}
        >
          <Tab label={t('COMMON.MONTH')} description={t('COMMON.MONTH')} />
          <Tab label={t('COMMON.YEAR')} description={t('COMMON.YEAR')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={tabIndex}>
        <CFDIIncomeList />
      </TabPanel>
    </Box>
  );
};

export default CFDIIncomeTabs;
