import React, { useState } from 'react';
import DetailCard from 'src/components/DetailBox/DetailCard';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { acceptKamRegistrationRequest, rejectKamRegistrationRequest } from 'src/store/actions/userKamActions';
import KamGeneralDetailInfo from './KamGeneralDetailInfo';
import AcceptKamRegistrationRequestDialog from './AcceptKamRegistrationRequestDialog/AcceptKamRegistrationRequestDialog';
import RejectKamRegistrationRequestDialog from './RejectKamRegistrationRequestDialog/RejectKamRegistrationRequestDialog';

const RegistrationRequestActions = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT'
};

const KamGeneralDetail = ({ kam }) => {
  const [open, setOpen] = useState('');
  const dispacth = useDispatch();

  const handleOpenAcceptRegistrationModal = () => {
    setOpen(RegistrationRequestActions.ACCEPT);
  };

  const handleOpenRejectRegistrationModal = () => {
    setOpen(RegistrationRequestActions.REJECT);
  };

  const handleClose = () => {
    setOpen('');
  };

  const handleAcceptRegistrationRequest = values => {
    dispacth(
      acceptKamRegistrationRequest({
        id: kam.id,
        distributorId: values.distributor.id
      }, handleClose)
    );
  };

  const handleRejectRegistrationRequest = values => {
    dispacth(
      rejectKamRegistrationRequest({
        id: kam.id,
        ...values
      }, handleClose)
    );
  };

  return (
    <>
      <DetailCard title="Datos generales">
        <KamGeneralDetailInfo
          kam={kam}
          open={open}
          handleOpenAcceptRegistrationModal={handleOpenAcceptRegistrationModal}
          handleOpenRejectRegistrationModal={handleOpenRejectRegistrationModal}
          handleClose={handleClose}
        />
        <AcceptKamRegistrationRequestDialog
          open={open === RegistrationRequestActions.ACCEPT}
          onClose={handleClose}
          onSubmit={handleAcceptRegistrationRequest}
        />
        <RejectKamRegistrationRequestDialog
          open={open === RegistrationRequestActions.REJECT}
          onClose={handleClose}
          onSubmit={handleRejectRegistrationRequest}
        />
      </DetailCard>
    </>
  );
};

// PropTypes
KamGeneralDetail.propTypes = {
  kam: PropTypes.object.isRequired
};

export default KamGeneralDetail;
