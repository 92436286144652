import { CivilStatus } from 'src/utils/investors';
import * as Yup from 'yup';

export default Yup.object().shape({
  curp: Yup.string().matches(
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
  ),
  civilStatus: Yup.string().oneOf(Object.keys(CivilStatus)),
  politicallyExposed: Yup.string(),
  politicallyExposedPerson: Yup.object().when('politicallyExposed', {
    is: 'true',
    then: Yup.object().shape({
      name: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      paternalName: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      maternalName: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      kinship: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
      position: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
    })
  })
});
