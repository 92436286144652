import React from 'react';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StepTwoDirector from './StepTwoDirector/StepTwoDirector';
import StepTwoKam from './StepTwoKam/StepTwoKam';

const StepTwo = ({
  data,
  activeStep,
  setData,
  handleNext,
  handleBack,
  resetRef,
  setActiveStep,
  handleClose
}) => {
  const role = useSelector(selectAuthRole);

  if (role === DistributorRoles.DIRECTOR) {
    return (
      <StepTwoDirector
        data={data}
        handleNext={handleNext}
        handleBack={handleBack}
        resetRef={resetRef}
        handleClose={handleClose}
      />
    );
  }

  return (
    <StepTwoKam
      data={data}
      activeStep={activeStep}
      setData={setData}
      handleNext={handleNext}
      handleBack={handleBack}
      resetRef={resetRef}
      setActiveStep={setActiveStep}
      handleClose={handleClose}
    />
  );
};

export default StepTwo;

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  setData: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};
