import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllEquipments = serviceBase({
  request: options =>
    axiosClient.get('/equipment', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    }),
  retry: 0
});

export const downloadeExportedEquipments = serviceBase({
  request: options =>
    axiosClient.get('/equipment/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Equipos.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const createNewEquipment = serviceBase({
  request: equipment => axiosClient.post(`/equipment`, equipment),
  retry: 0
});

export const getEquipmentById = serviceBase({
  request: id => axiosClient.get(`/equipment/${id}`),
  retry: 0
});

export const updateEquipmentById = serviceBase({
  request: ({ id, equipment }) => axiosClient.put(`/equipment/${id}`, equipment)
});

export const getClientEquipmentsById = serviceBase({
  request: id => axiosClient.get(`clients/equipments/${id}`)
});

export default getAllEquipments;
