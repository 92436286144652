import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { PersonType } from 'src/utils/constants/clients';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_AVAL_DETAIL,
  LOAD_AVAL_DETAIL,
  loadAvalDetail
} from 'src/store/actions/clientActions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectClientAvalDetail } from 'src/store/selectors/clientSelectors';
import Preloader from 'src/components/Preloader';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import AlertMessage from 'src/components/Alert/Alert';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import GeneralAvalPhysicalClient from '../AvalGeneralDetailPhysicalClient';
import AvalAddress from '../AddressDetail';
import AvalDocuments from '../DocumentsDetail';
import GeneralAvalLegalClient from '../AvalGeneralDetailLegalClient';
import AvalContactDetail from '../AvalContactDetail';

const AvalGeneralView = () => {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const system = useSelector(selectAuthSystem);
  const avalDetail = useSelector(selectClientAvalDetail);
  const loading = useSelector(isLoadingSelector([LOAD_AVAL_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_AVAL_DETAIL.action]));

  const disabledEdit = useMemo(() => {
    if (system === UserType.ADMIN || system === UserType.SUPER_ADMIN) {
      return false;
    }
    if (system === UserType.DISTRIBUTOR && avalDetail?.isEditable) {
      return false;
    }
    return true;
  }, [avalDetail, system]);

  const { t } = useTranslation();
  const { avalId } = useParams();

  useEffect(() => {
    dispatch(loadAvalDetail(avalId));
    return () => {
      dispatch({ type: CLEAR_AVAL_DETAIL });
    };
  }, []);

  useEffect(() => {
    if (system === UserType.ADMIN || system === UserType.SUPER_ADMIN) {
      if (avalDetail && avalDetail.pendingDocs > 0) {
        setOpenAlert(true);
      }
    }
  }, [avalDetail]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.AVAL')}
            </Typography>
          </Box>
        </Grid>
        {loading && !success ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        ) : (
          <>
            {disabledEdit && (
              <Grid item xs={12}>
                <AlertMessage
                  open
                  severity={AlertSeverity.INFO}
                  title={t('COMMON.INFO')}
                  text={t('TOOLTIP_MESSAGES.AVAL_NOT_EDITABLE')}
                  hideCloseIcon
                />
              </Grid>
            )}
            {system === UserType.ADMIN && (
              <Grid item xs={12} sx={!openAlert && { display: 'none' }}>
                <AlertMessage
                  open={openAlert}
                  handleClose={handleCloseAlert}
                  severity={AlertSeverity.WARNING}
                  title={t('COMMON.WARNING')}
                  text={t('TOOLTIP_MESSAGES.AVAL_HAS_PEDING_DOCS').replace(
                    '{parameter}',
                    avalDetail.pendingDocs
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={7} lg={3}>
              {avalDetail.clientPersonType === PersonType.PHYSICAL ? (
                <GeneralAvalPhysicalClient
                  aval={avalDetail}
                  isDisabledEdit={disabledEdit}
                />
              ) : (
                <GeneralAvalLegalClient
                  aval={avalDetail}
                  isDisabledEdit={disabledEdit}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <AvalContactDetail
                aval={avalDetail}
                isDisabledEdit={disabledEdit}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AvalAddress aval={avalDetail} isDisabledEdit={disabledEdit} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AvalDocuments aval={avalDetail} isDisabledEdit={disabledEdit} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AvalGeneralView;
