/* eslint-disable import/prefer-default-export */

export const MetricsViewOptions = {
  KAM: 'KAM',
  DISTRIBUTOR: 'DISTRIBUTOR'
};

export const QuotesMetricsBalanceOptions = {
  AMOUNT: 'AMOUNT',
  COUNT: 'COUNT'
};
