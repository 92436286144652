import getUserClients, {
  addClientToList,
  createClientAndAddToList,
  createDistributorClientFromAval,
  getClientByRFC,
  getUserClientById
} from 'src/services/userClientService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { PersonType } from 'src/utils/constants/clients';
import { reassignClient } from 'src/services/userKamService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { push } from 'connected-react-router';
import { selectClientsQueryOptions } from '../selectors/clientSelectors';
// eslint-disable-next-line import/no-cycle
import {
  CLEAR_CLIENT_DETAIL,
  LOAD_CLIENTS,
  LOAD_CLIENT_DETAIL,
  SAVE_CLIENT_OPTIONS
} from './clientActions';
import { showMessage } from './snackBarActions';

export const LOAD_CLIENTS_BY_RFC = asyncActionCreator('LOAD_CLIENTS_BY_RFC');
export const ADD_CLIENT_TO_LIST = asyncActionCreator('ADD_CLIENT_TO_LIST');
export const CREATE_DISTRIBUTOR_CLIENT = asyncActionCreator(
  'CREATE_DISTRIBUTOR_CLIENT'
);
export const REASSIGN_CLENT = asyncActionCreator('REASSIGN_CLENT');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectClientsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

const loadUserClients = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CLIENTS.start });

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const clients = await getUserClients(reqOptions);

    return dispatch({
      type: LOAD_CLIENTS.success,
      ...clients
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.error })
    );
    return dispatch({ type: LOAD_CLIENTS.failure });
  }
};

export const loadAllUserClients = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CLIENTS.start });

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, false);

    const clients = await getUserClients(reqOptions);

    return dispatch({
      type: LOAD_CLIENTS.success,
      ...clients
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENTS.failure });
  }
};

export const loadUserClientDetail = id => async dispatch => {
  try {
    dispatch({
      type: LOAD_CLIENT_DETAIL.start
    });

    const data = await getUserClientById(id);

    return dispatch({
      type: LOAD_CLIENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_DETAIL.failure });
  }
};

export const loadUserClientByRFC = rfc => async dispatch => {
  try {
    dispatch({
      type: LOAD_CLIENT_DETAIL.start
    });

    const data = await getClientByRFC(rfc);

    return dispatch({
      type: LOAD_CLIENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_DETAIL.failure });
  }
};

export const addNewClientToList = (client, userId) => async dispatch => {
  try {
    dispatch({
      type: ADD_CLIENT_TO_LIST.start
    });

    await addClientToList({
      clientId: client.id,
      distributorProfileId: userId
    });

    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR_CLIENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: ADD_CLIENT_TO_LIST.success
    });

    return dispatch(loadUserClients());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: ADD_CLIENT_TO_LIST.failure });
  }
};

export const createDistributorClient = (
  values,
  closeCallback,
  setData
) => async dispatch => {
  try {
    dispatch({
      type: CREATE_DISTRIBUTOR_CLIENT.start
    });

    const phone = values.phone.split('.').join('');

    if (values.existingRfc) {
      const id = await createDistributorClientFromAval({
        rfc: values.rfc
      });

      dispatch(
        showMessage({
          message: 'CREATE_DISTRIBUTOR_CLIENT',
          variant: SnackBarVariants.SUCCESS
        })
      );
      return dispatch(push(`/user/client/${id}`));
    }

    if (values.personType === PersonType.LEGAL) {
      const avalPhone = values.aval.phone.split('.').join('');

      await createClientAndAddToList({
        ...values,
        phone,
        aval: { ...values.aval, phone: avalPhone }
      });
    } else {
      await createClientAndAddToList({
        ...values,
        phone
      });
    }

    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR_CLIENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: CREATE_DISTRIBUTOR_CLIENT.success
    });

    setData();

    closeCallback();
    dispatch(loadUserClients());
    return dispatch({ type: CLEAR_CLIENT_DETAIL });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_DISTRIBUTOR_CLIENT.failure });
  }
};

export const reassignClientAction = values => {
  return async dispatch => {
    try {
      dispatch({ type: REASSIGN_CLENT.start });

      await reassignClient({
        oldDistributorProfileId: values.currentKamId,
        newDistributorProfileId: values.newKamId,
        clientId: values.clientId,
        distributorId: values.distributorId
      });

      dispatch(
        showMessage({
          message: 'REASSIGN_CLENT',
          variant: SnackBarVariants.success
        })
      );
      dispatch(loadUserClients({ page: 0 }));
      dispatch(
        showMessage({
          message: 'REASSIGN_CLENT',
          variant: SnackBarVariants.SUCCESS
        })
      );
      return dispatch({ type: REASSIGN_CLENT.success });
    } catch (error) {
      dispatch(
        showMessage({ message: error.message, variant: SnackBarVariants.error })
      );
      return dispatch({ type: REASSIGN_CLENT.failure });
    }
  };
};

export default loadUserClients;
