import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectContractsQueryOptions } from 'src/store/selectors/contractSelectors';
import {
  CLEAR_CONTRACT_OPTIONS,
  createContract,
  loadContracts,
  setContractOptions
} from 'src/store/actions/contractActions';
import ContractStatus, {
  ContractCreationOption
} from 'src/utils/constants/contracts';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import SendIcon from '@mui/icons-material/Send';
import MenuButton from 'src/components/MenuButton/MenuButton';
import SearchBarByType from 'src/components/SearchBarByType';
import useStyles from './styles';
import ContractForm from './ContractForm';
import ContractToDigitalSignForm from './ContractToDigitalSignForm';

const contractCreationOptions = [
  {
    label: 'CONTRACT_VIEW.EXISTS_CONTRACT',
    key: ContractCreationOption.CREATE_CONTRACT,
    icon: <SendAndArchiveIcon />
  },
  {
    label: 'CONTRACT_VIEW.NEW_CONTRACT',
    key: ContractCreationOption.CREATE_CONTRACT_TO_DIGITAL_SIGN,
    icon: <SendIcon />
  }
];

const searchTypes = [
  { value: 'client', label: 'NAME', type: 'text' },
  { value: 'folio', label: 'FOLIO', type: 'text' }
];

const Toolbar = ({ className, contractStatus, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectContractsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('client');
  const [openContractCreation, setOpenContractCreation] = useState(false);
  const [
    openContractToDigitalSignCreation,
    setOpenContractToDigitalSignCreation
  ] = useState(false);

  const handleOpenContractCreationForm = () => {
    setOpenContractCreation(true);
  };

  const handleOpenContractToDigitalSignCreationForm = () => {
    setOpenContractToDigitalSignCreation(true);
  };

  const handleCloseContractCreationForm = () => {
    setOpenContractCreation(false);
  };

  const handleCloseContractToDigitalSignCreationForm = () => {
    setOpenContractToDigitalSignCreation(false);
  };

  const handleCreateContract = useCallback(
    values => {
      dispatch(createContract(values, () => handleCloseContractCreationForm()));
    },
    [dispatch, handleCloseContractCreationForm]
  );

  const handleCreateContractToDigitalSign = useCallback(
    values => {
      dispatch(
        createContract(values, () =>
          handleCloseContractToDigitalSignCreationForm()
        )
      );
    },
    [dispatch, handleCloseContractToDigitalSignCreationForm]
  );

  const debounceSearch = useDebounce(
    search => dispatch(loadContracts({ ...search, page: 0 })),
    400
  );

  useEffect(() => {
    dispatch(loadContracts({ status: ContractStatus.IN_PROGRESS }));
    return () => {
      dispatch({ type: CLEAR_CONTRACT_OPTIONS });
    };
  }, []);

  const handleSearch = useCallback(
    (e, criteria) => {
      const { value } = e.target;
      setSearchValue(value);
      setSearchCriteria(criteria);
      debounceSearch({ [criteria]: value });
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleSearchCriteria = useCallback(() => {
    setSearchValue('');
    dispatch(
      setContractOptions({
        ...queryOptions,
        [searchCriteria]: ''
      })
    );
  }, [dispatch, queryOptions]);

  const handleOpenContractCreationFormsOptions = option => {
    switch (option) {
      case ContractCreationOption.CREATE_CONTRACT:
        handleOpenContractCreationForm();
        break;
      case ContractCreationOption.CREATE_CONTRACT_TO_DIGITAL_SIGN:
        handleOpenContractToDigitalSignCreationForm();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('CONTRACT_VIEW.TITLE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBarByType
              types={searchTypes}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleSearchCriteria={handleSearchCriteria}
            />
            <MenuButton
              label={t('CONTRACT_VIEW.CREATE_CONTRACT')}
              options={contractCreationOptions}
              handleClickOption={handleOpenContractCreationFormsOptions}
            />
          </Box>
        </Box>
        <ContractForm
          open={openContractCreation}
          handleClose={handleCloseContractCreationForm}
          handleCreateContract={handleCreateContract}
        />
        <ContractToDigitalSignForm
          open={openContractToDigitalSignCreation}
          handleClose={handleCloseContractToDigitalSignCreationForm}
          handleCreateContract={handleCreateContractToDigitalSign}
        />
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  contractStatus: PropTypes.string
};

export default Toolbar;
