import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Preloader from 'src/components/Preloader';
import useStyles from '../styles';
import ReviewData from './ReviewData';

const FinalStep = ({ data, handleSubmit, handleBack, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const content = useMemo(() => {
    return !loading ? <ReviewData values={data} /> : <Preloader />;
  }, [loading]);

  const handleOtherSubmit = e => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <FadeIn>
      <form onSubmit={handleOtherSubmit}>
        <Divider light />
        {content}

        <Box
          className={classes.tableBox}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            className={classes.buttons}
            onClick={handleBack}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {t('COMMON.BACK')}
          </Button>
          <LoadingButton
            className={clsx(classes.buttons, classes.buttonMain)}
            type="submit"
            color="secondary"
            variant="contained"
            loadingPosition="start"
            loading={loading}
          >
            {t('COMMON.CREATE')}
          </LoadingButton>
        </Box>
      </form>
    </FadeIn>
  );
};
FinalStep.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FinalStep;
