import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CONTRACT_PAYMENT_SCHEDULE } from 'src/store/actions/contractActions';
import ContractCalendarRow from './ContractCalendarRow';

const Tbody = ({ calendar }) =>
  calendar.map(cal => <ContractCalendarRow key={cal.id} {...cal} />);

const ContractCalendarTable = ({ className, contracts, ...rest }) => {
  const { t } = useTranslation();

  const loading = useSelector(
    isLoadingSelector([LOAD_CONTRACT_PAYMENT_SCHEDULE.action])
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" align="center">
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.PERIOD')}
        </TableCell>
        <TableCell variant="head" align="center">{t('COMMON.DATE')}</TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.INITIAL_BALANCE')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.EQUIPMENT_COST')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.INITIAL_PAYMENT')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '12rem' }}>
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.MONTHLY_PRINCIPAL')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.CAPITAL_CONTRIBUTION')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '8rem' }}>
          {t('CONTRACT_VIEW.RESIDUAL')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.FINAL_BALANCE')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(() => () => <Tbody t={t} calendar={contracts} />, [
    contracts,
    t
  ]);

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

ContractCalendarTable.propTypes = {
  className: PropTypes.string,
  contracts: PropTypes.array.isRequired
};

export default ContractCalendarTable;
