import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { CLEAR_CLIENT_DETAIL } from 'src/store/actions/clientActions';
import { CLEAR_DISTRIBUTORS } from 'src/store/actions/distributorActions';
import { CLEAR_RENT_CALCULATOR } from 'src/store/constants/quotes';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const stepperLabels = [
  'DISTRIBUTORS_VIEW.ASSIGN_DISTRIBUTOR',
  'COMMON.RENT_CALCULATE',
  'CLIENTS_VIEW.CREATION_FORM.PERSONAL_INFORMATION',
  'COMMON.CONFIRMATION'
];

const RentCalculatorForm = ({
  open,
  handleClose,
  handleCreateRentCalculator
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(), [open]);
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const resetRef = useRef();

  useEffect(() => {
    return () => {
      setData(initialState);
      setStep(0);
      dispatch({ type: CLEAR_DISTRIBUTORS });
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = stepsBack => {
    switch (step) {
      case 0:
        setData(initialState);
        handleClose();
        break;
      default:
        setStep(stepsBack ? step - stepsBack : step - 1);
        break;
    }
  };

  const handleSubmit = useCallback(
    values => {
      handleCreateRentCalculator({
        ...data,
        ...values
      });
      setData(initialState);
    },
    [data]
  );

  const handleCloseForm = () => {
    setData(initialState);
    dispatch({ type: CLEAR_CLIENT_DETAIL });
    dispatch({ type: CLEAR_RENT_CALCULATOR });
    handleClose();
  };

  if (!open) return null;

  return (
    <Box>
      <Dialog
        aria-labelledby="form-new-gps-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-gps-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseForm}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('COMMON.RENT_CALCULATOR')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <FormStepper
          index={step}
          labels={stepperLabels}
          sx={{
            marginBottom: '1.5rem',
            marginTop: '1.5rem'
          }}
        />
        <DialogContent>
          <CurrentStep
            activeStep={step}
            data={data}
            setData={setData}
            resetRef={resetRef}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            onCloseForm={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

RentCalculatorForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateRentCalculator: PropTypes.func.isRequired
};

export default RentCalculatorForm;
