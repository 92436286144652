import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ClientStatus from 'src/utils/constants/clients';
import { selectClientsQueryOptions } from 'src/store/selectors/clientSelectors';
import Toolbar from './Toolbar';
import ClientsListTabs from './ClientsListTabs';
import useStyles from './styles';

const ClientsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = useSelector(selectClientsQueryOptions);

  return (
    <Page className={classes.indexRoot} title={t('CLIENTS_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar clientStatus={status || ClientStatus.ACTIVE} />
          <Box mt={3}>
            <ClientsListTabs />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default ClientsListView;
