import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttons: {
    height: 48,
    width: 200,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    minWidth: 'unset',
    paddingBottom: theme.spacing(2)
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8
    }
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    "&[type='number']": {
      MozAppearance: 'textfield'
    },
    '&:placeholder': {
      color: 'red'
    }
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8
    }
  },
  typography: {
    padding: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold'
  }
}));
