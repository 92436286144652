import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { selectClientSex } from 'src/utils/common';

const GeneralAvalInfo = ({ aval }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.FULL_NAME')}
        value={aval.fullName || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.DATE_OF_BIRTH')}
        value={
          aval.birthDate
            ? selectFormatDate(aval.birthDate, dateFormatType.DAY)
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('COMMON.AGE')}
        value={aval.age || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.GENDER')}
        value={selectClientSex(aval.sex, t)}
      />

      <DetailRow name="RFC" value={aval.rfc || t('COMMON.NO_DATA')} />

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER')}
        value={aval.identificationNumber || t('COMMON.NO_DATA')}
      />

      <DetailRow name="CURP" value={aval.curp || t('COMMON.NO_DATA')} />

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.SHAREHOLDING_PERCENTAGE')}
        value={aval.shareholdingPercentage || t('COMMON.NO_DATA')}
      />

      {aval.isLegalRepresentative && (
        <>
          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.POWER_OF_ATTORNEY_NUMBER')}
            value={aval.deedNumberPowerAttorney || t('COMMON.NO_DATA')}
          />
          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.POWER_OF_ATTORNEY_DATE')}
            value={aval.deedDatePowerAttorney || t('COMMON.NO_DATA')}
          />
        </>
      )}
    </>
  );
};

GeneralAvalInfo.propTypes = {
  aval: PropTypes.object
};
export default GeneralAvalInfo;
