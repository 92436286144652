// eslint-disable-next-line import/prefer-default-export
export const CFDIListActions = {
  VIEW_PDF: 'VIEW_PDF',
  SEND_EMAIL: 'SEND_EMAIL',
  SEND_WHATSAPP: 'SEND_WHATSAPP',
  CANCEL: 'CANCEL'
};

export const InvoiceTypes = {
  INCOME: 'INCOME',
  EXPENSE: 'EXPENSE',
  PAYMENT: 'PAYMENT',
  PAYROLL: 'PAYROLL',
  TRANSFER: 'TRANSFER'
};

export const CFDICancelMotives = [
  {
    key: '01',
    name: 'ISSUED_WITH_ERRORS_WITH_RELATION'
  },
  {
    key: '02',
    name: 'ISSUED_WITH_ERRORS_WITHOUT_RELATION'
  },
  { key: '03', name: 'OPERATION_NOT_COMPLETED' },
  {
    key: '04',
    name: 'NOMINATIVE_OPERATION_RELATED_TO_GLOBAL_INVOICE'
  }
];

export const IssuerType = {
  RECEIVING: 'RECEIVING',
  ISSUING: 'ISSUING'
};

export const CDFIDocumentFormat = {
  PDF: 'PDF',
  XML: 'XML'
};
