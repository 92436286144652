import React from 'react';
import PropTypes from 'prop-types';
import { Box, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { NufiMenuActions } from 'src/utils/constants/clients';
import { useDispatch } from 'react-redux';
import { sendFieldToNufiValidation } from 'src/store/actions/clientActions';
import { PersonRole } from 'src/utils/common';
import ValidationIconStatus from 'src/utils/selectValidationIcon';
import useStyles from './styles';

// add send to validation option
const ValidationOptions = [
  {
    name: NufiMenuActions.VALIDATE_FIELD,
    label: `ENUMS.NUFI_MENU_ACTIONS.${NufiMenuActions.VALIDATE_FIELD}`,
    icon: <HowToRegIcon />
  }
];

const ValidationDetailRow = ({ name, value, field, role, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClickValidate = () => {
    switch (role) {
      case PersonRole.CLIENT:
        return dispatch(
          sendFieldToNufiValidation(field, { clientId: Number(id) })
        );
      case PersonRole.DISTRIBUTOR:
        return dispatch(
          sendFieldToNufiValidation(field, { distributorId: Number(id) })
        );
      case PersonRole.AVAL:
        return dispatch(
          sendFieldToNufiValidation(field, { avalId: Number(id) })
        );
      default:
        return null;
    }
  };

  return (
    <TableRow hover>
      <TableCell>{t(`ENUMS.CLIENT_VALIDATION.${name}`)}</TableCell>
      <TableCell align="center">
        <Box className={classes.validationIconsContainter}>
          <Box className={classes.validationIcons}>
            <ValidationIconStatus value={value} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <MoreMenu
          editDisabled
          deleteDisabled
          options={ValidationOptions}
          handleClickOption={() => handleClickValidate()}
        />
      </TableCell>
    </TableRow>
  );
};

ValidationDetailRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.string
};

export default ValidationDetailRow;
