import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import TabPanel from 'src/components/TabPanel/TabPanel';
import GeneralView from './General';
import QuotesDetailView from './QuotesList/QuotesDetailView';

const KamDetailTabs = ({ kam }) => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb doNotRedirectIndexList={[0]} />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.GENERAL')} />
          <Tab label={t('COMMON.QUOTES')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView kam={kam} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <QuotesDetailView />
      </TabPanel>
    </Box>
  );
};

KamDetailTabs.propTypes = {
  kam: PropTypes.object.isRequired
};

export default KamDetailTabs;
