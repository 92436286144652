import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import BillingFailuresList from './BillingFailuresList';
import useStyles from './styles';
import Toolbar from './Toolbar';

const BillingFailuresListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.BILLING_FAILURES')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <BillingFailuresList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default BillingFailuresListView;
