import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Grid,
  Box,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import navBarOptions from 'src/utils/constants/navBar';
import { getLocalStorage, localStorageKey } from 'src/utils/localStorage';
import makeInitialState from './initialState';
import useStyles from './styles';
import schema from './schema';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const EditNavBarOptionsDialog = ({ open, handleClose, submit }) => {
  const { t } = useTranslation();
  const resetRef = useRef();
  const classes = useStyles();
  const savedOptions = getLocalStorage(localStorageKey.NAVBAR_OPTIONS);
  const initialState = useMemo(() => makeInitialState(savedOptions), [
    savedOptions
  ]);

  const options = Object.values(navBarOptions.ADMIN);

  const handleOuterSubmit = useCallback(
    values => {
      submit(values);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COMMON.EDIT_NAVIGATION_OPTIONS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={schema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({ handleSubmit, resetForm, setFieldValue, values, errors }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <FormControl sx={{ m: 1 }} fullWidth error={errors.options}>
                      <InputLabel id="label_text">
                        {t('COMMON.NAVIGATION_OPTIONS')}
                      </InputLabel>
                      <Select
                        labelId="label_text"
                        id="multiple-chip"
                        multiple
                        name="options"
                        error={errors.options}
                        value={values.options}
                        onChange={(event, value) => {
                          const actualValue = value.props.value;

                          if (values.options.includes(actualValue)) {
                            setFieldValue(
                              'options',
                              values.options.filter(v => v !== actualValue)
                            );
                          } else {
                            setFieldValue('options', [
                              ...values.options,
                              actualValue
                            ]);
                          }
                        }}
                        input={
                          <OutlinedInput
                            id="label_text"
                            label={t('COMMON.NAVIGATION_OPTIONS')}
                          />
                        }
                        renderValue={selected => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map(value => (
                              <Chip
                                key={value}
                                label={t(`NAVIGATION.${value}`)}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {options.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={values.options.indexOf(name) > -1}
                            />
                            <ListItemText primary={t(`NAVIGATION.${name}`)} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={errors.options}>
                        {t(errors.options)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditNavBarOptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default EditNavBarOptionsDialog;
