import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import NavItemBadge from './NavItemBadge';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    letterSpacing: 0.1,
    justifyContent: 'flex-start',
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.primary.contrastText
  },
  title: {
    marginRight: 'auto',
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.1,
    textTransform: 'none'
  },
  extraMargin: {
    marginRight: theme.spacing(2.2)
  },
  active: {
    color: theme.palette.secondary.main,
    '& $icon': {
      fill: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({
  className,
  isRootItem,
  href,
  icon: Icon,
  title,
  toggle,
  openDrawer,
  badgeContent = null,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <NavItemBadge
        badgeContent={badgeContent}
        color="error"
        sx={{ width: '100%' }}
      >
        {toggle ? (
          <Button activeClassName={classes.active} className={classes.button}>
            {Icon && (
              <Icon
                className={clsx(classes.icon, {
                  [classes.extraMargin]: isRootItem
                })}
                size="20"
              />
            )}
            <span className={classes.title}>{t(title)}</span>
          </Button>
        ) : (
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
          >
            {Icon && (
              <ListItemIcon>
                <Icon
                  className={clsx(classes.icon, {
                    [classes.extraMargin]: isRootItem
                  })}
                  size="20"
                />
              </ListItemIcon>
            )}
            <ListItemText>
              <span className={classes.title}>
                {openDrawer ? t(title) : ''}
              </span>
            </ListItemText>
          </Button>
        )}
      </NavItemBadge>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  toggle: PropTypes.bool,
  isRootItem: PropTypes.bool,
  openDrawer: PropTypes.bool,
  badgeContent: PropTypes.number
};

export default NavItem;
