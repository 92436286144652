import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_DISTRIBUTOR_CLIENT } from 'src/store/actions/userClientActions';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import FinalStep from './FinalStep';

const CurrentStep = ({
  activeStep,
  data,
  onNext,
  onBack,
  onSubmit,
  onCloseForm
}) => {
  const loading = useSelector(
    isLoadingSelector([CREATE_DISTRIBUTOR_CLIENT.action])
  );

  if (activeStep === 0) {
    return <StepOne data={data} handleNext={onNext} handleBack={onBack} />;
  }

  if (activeStep === 1) {
    return (
      <StepTwo
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        onCloseForm={onCloseForm}
      />
    );
  }

  return (
    <FinalStep
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseForm: PropTypes.func.isRequired
};

export default CurrentStep;
