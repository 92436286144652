export const selectAmortizationBalance = state => state.amortizationBalance;
export const selectAmortizationBalanceList = state =>
  selectAmortizationBalance(state).amortizationItems;
export const selectPolicyBalanceList = state =>
  selectAmortizationBalance(state).policyItems;
export const selectAmortizationBalancesListCount = state =>
  selectAmortizationBalance(state).count;
export const selectAmortizationBalancesQueryOptions = state => {
  const { options } = selectAmortizationBalance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
