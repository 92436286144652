import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EditContractDateDialog from './EditContractDateDialog/EditContractDateDialog';

const EditContract = ({ contract, open, handleClose, submit }) => {

  const handleSubmit = useCallback(
    values => {
      submit({
        id: contract.id,
        ...values
      });
    },
    [contract.id, submit]
  );

  if (!open) return null;

  return (
    <Box>
      <EditContractDateDialog
        open={open}
        contract={contract}
        handleClose={handleClose}
        submit={handleSubmit}
      />
    </Box>
  );
};

EditContract.propTypes = {
  contract: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.any
};

export default EditContract;
