import {
  getParameters,
  editParameter,
  editNuFiParameter,
  getParameterByName,
  editBuroFormParameters,
  editBuroMoralPersonFormParameters
} from 'src/services/parameterService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import { ParameterSection } from 'src/utils/constants/parameters';
import { selectParameterQueryOptions } from '../selectors/parameterSelector';
import { showMessage } from './snackBarActions';

export const LOAD_PARAMETERS = asyncActionCreator('LOAD_PARAMETERS');
export const LOAD_PARAMETER = asyncActionCreator('LOAD_PARAMETER');
export const EDIT_BURO_LEGAL_PERSON_FORM_PARAMETERS = asyncActionCreator(
  'EDIT_BURO_LEGAL_PERSON_FORM_PARAMETERS'
);
export const EDIT_BURO_FORM_PARAMETERS = asyncActionCreator(
  'EDIT_BURO_FORM_PARAMETERS'
);
export const CLEAR_PARAMETERS = 'CLEAR_PARAMETERS';
export const CLEAR_PARAMTER = 'CLEAR_PARAMTER';
export const SAVE_OPTIONS = 'SAVE_OPTIONS';

function getOptions(getState) {
  const queryOpts = selectParameterQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.section) {
    reqOptions.section = queryOpts.section;
  }

  return reqOptions;
}

export const loadParameters = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_PARAMETERS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);
    const data = await getParameters(reqOptions);

    return dispatch({
      type: LOAD_PARAMETERS.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_PARAMETERS.failure });
  }
};

export const clearParameters = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_PARAMETERS });
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const editParameterAction = values => async dispatch => {
  try {
    await editParameter(values);
    dispatch(
      showMessage({
        message: 'UPDATE_PARAMETER',
        variant: SnackBarVariants.SUCCESS
      })
    );
    return dispatch(loadParameters());
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const editNuFiParameterAction = values => async dispatch => {
  try {
    await editNuFiParameter({ parameterList: values });
    dispatch(
      showMessage({
        message: 'UPDATE_PARAMETER',
        variant: SnackBarVariants.SUCCESS
      })
    );
    return dispatch(loadParameters());
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const loadParameter = parameterName => async dispatch => {
  try {
    dispatch({ type: LOAD_PARAMETER.start });
    const parameter = await getParameterByName(parameterName);
    return dispatch({
      type: LOAD_PARAMETER.success,
      data: parameter
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_PARAMETER.failure });
  }
};

export const updateBuroFormParameters = values => async dispatch => {
  try {
    dispatch({ type: EDIT_BURO_FORM_PARAMETERS.start });
    await editBuroFormParameters({ parameterList: values });
    dispatch(
      showMessage({
        message: 'UPDATE_PARAMETER',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: EDIT_BURO_FORM_PARAMETERS.success });
    return dispatch(loadParameters({ section: ParameterSection.BURO }));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    dispatch(loadParameters({ section: ParameterSection.BURO }));
    return dispatch({ type: EDIT_BURO_FORM_PARAMETERS.failure });
  }
};

export const updateBuroMoralPersonFormParameters = values => async dispatch => {
  try {
    dispatch({ type: EDIT_BURO_LEGAL_PERSON_FORM_PARAMETERS.start });
    await editBuroMoralPersonFormParameters({ parameterList: values });
    dispatch(
      showMessage({
        message: 'UPDATE_PARAMETER',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: EDIT_BURO_LEGAL_PERSON_FORM_PARAMETERS.success });
    return dispatch(
      loadParameters({ section: ParameterSection.LEGAL_PERSON_BURO_FORM })
    );
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    dispatch(
      loadParameters({ section: ParameterSection.LEGAL_PERSON_BURO_FORM })
    );
    return dispatch({ type: EDIT_BURO_LEGAL_PERSON_FORM_PARAMETERS.failure });
  }
};
