import React from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_CONTRACT_REQUEST } from 'src/store/actions/contractRequestActions';
import StepOne from './StepOne';
import FinalStep from './FinalStep';

const CurrentStep = ({
  activeStep,
  data,
  onNext,
  onBack,
  onSubmit,
  onCloseForm,
  isQuoteFromRentCalculator
}) => {
  const loading = useSelector(
    isLoadingSelector([CREATE_CONTRACT_REQUEST.action])
  );

  if (activeStep === 0) {
    return (
      <StepOne
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        onCloseForm={onCloseForm}
        isQuoteFromRentCalculator={isQuoteFromRentCalculator}
      />
    );
  }

  return (
    <FinalStep
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  isQuoteFromRentCalculator: PropTypes.bool.isRequired
};

export default CurrentStep;
