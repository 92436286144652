import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';
import { rehydrateToken } from './middleware/rehydrateToken';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const history = createBrowserHistory();
const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), rehydrateToken, thunk)
);
const store = createStore(createRootReducer(history), enhancer);

export default store;
