import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { downloadBusinessMetricsResume } from 'src/store/actions/businessActions';
import DownloadButton from 'src/components/DownloadButton';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleExcelExport = useCallback(() => {
    dispatch(downloadBusinessMetricsResume());
  }, [dispatch]);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.RESUME')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <DownloadButton
              onDownload={handleExcelExport}
              title={t('BUSINESS_VIEW.RESUME.DOWNLOAD_RESUME')}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
