import React, { useCallback, useEffect } from 'react';
import { AppBar, Box, Container, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvestorQueryOptions } from 'src/store/selectors/investorSelector';
import {
  CLEAR_INVESTORS,
  loadInvestors
} from 'src/store/actions/investorActions';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { InvestorStatus } from 'src/utils/investors';
import InvestorsList from './InvestorsList';
import Toolbar from './Toolbar';

const selectInvestorStatus = value => {
  switch (Number(value)) {
    case 0:
      return InvestorStatus.ACTIVE;
    case 1:
      return InvestorStatus.INACTIVE;
    default:
      return InvestorStatus.ACTIVE;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InvestorsListView = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectInvestorQueryOptions);

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadInvestors({
          ...queryOpts,
          status: selectInvestorStatus(newValue)
        })
      );
    },
    [setTabIndex]
  );
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      loadInvestors({
        status: InvestorStatus.ACTIVE
      })
    );
    return () => {
      dispatch({
        type: CLEAR_INVESTORS
      });
    };
  }, []);

  return (
    <Page className={classes.root} title={t('INVESTORS_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <AppBar
              variant="outlined"
              position="static"
              sx={{
                border: 'none',
                borderBottom: '1px solid rgba(43,45,66,0.1)',
                background: 'transparent',
                flexDirection: 'row'
              }}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabIndex}
                onChange={handleChange}
              >
                <Tab label={t('COMMON.ACTIVES')} />
                <Tab label={t('COMMON.INACTIVES')} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
              <InvestorsList />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <InvestorsList />
            </TabPanel>
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default InvestorsListView;
