import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_CATALOG, loadBanks } from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import BanksTable from './BanksTable';

const BanksList = () => {
  const dispatch = useDispatch();
  const banks = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  const success = useSelector(successSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadBanks());
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <BanksTable banks={banks} loading={loading} success={success} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BanksList;
