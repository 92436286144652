import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';

import NotFoundRecords from 'src/components/NotFoundRecords';
import SigningParameterTable from './SigningParameterTable';

const SigningParameterList = ({ handleOpenEdit, parameters, loading }) => {
  if (!parameters.length)
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_DESCRIPTION"
        />
      </Card>
    );

  return (
    <Box mt={2}>
      <SigningParameterTable
        parameters={parameters}
        loading={loading}
        handleOpenEdit={handleOpenEdit}
      />
    </Box>
  );
};

SigningParameterList.propTypes = {
  handleOpenEdit: PropTypes.func,
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default SigningParameterList;
