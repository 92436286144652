import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow, Tooltip } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import { ParameterType } from 'src/utils/constants/parameters';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';

const selectValueForBooleanParam = value => {
  switch (value) {
    case 'true':
      return 'YES';
    case 'false':
      return 'NO';
    default:
      return 'NO';
  }
};

const ParameterRow = ({ name, value, type, openEdit }) => {
  const { t } = useTranslation();

  const disabledEditParameter =
    name === 'ENABLE_NOTIFICATIONS' &&
    process.env.REACT_APP_ENV === 'production';

  const handleOpenEdit = () => {
    openEdit();
  };

  const renderValue = useMemo(() => {
    if (type === ParameterType.BOOLEAN) {
      return t(`COMMON.${selectValueForBooleanParam(value)}`).toUpperCase();
    }
    if (type === ParameterType.ARRAY) {
      <Tooltip title={t('COMMON.VIEW_LIST')}>
        <IconButton color="primary" aria-label="view_list" onClick={openEdit}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>;
    }
    return value;
  }, [type, value]);

  return (
    <TableRow hover>
      <TableCell>{t(`PARAMETER_VIEW.${name}`)}</TableCell>
      <TableCell align="center">{renderValue}</TableCell>
      <TableCell style={{ width: 130 }}>
        <MoreMenu
          deleteDisabled
          handleClickOption={handleOpenEdit}
          disabled={disabledEditParameter}
        />
      </TableCell>
    </TableRow>
  );
};

ParameterRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  openEdit: PropTypes.func
};

export default ParameterRow;
