import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { PersonType } from 'src/utils/constants/clients';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  VALIDATE_CLIENT_CURP,
  validateClientCurp
} from 'src/store/actions/bureauFormActions';
import theme from 'src/theme';
import { LoadingButton } from '@mui/lab';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const PersonTypes = [
  {
    key: PersonType.PHYSICAL,
    name: 'Física'
  },
  {
    key: PersonType.LEGAL,
    name: 'Moral'
  }
];

const StepThree = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loadingValidation = useSelector(
    isLoadingSelector([VALIDATE_CLIENT_CURP.action])
  );

  const { t } = useTranslation();

  const onNext = useCallback(
    async values => {
      if (values.personType === PersonType.PHYSICAL && process.env.REACT_APP_NODE_ENV === 'production') {
        return dispatch(validateClientCurp(values, handleNext));
      }
      return handleNext(values);
    },
    [handleNext, data]
  );

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '50%'
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    marginBottom: '10px',
                    color: theme.palette.primary.main
                  }}
                >
                  {t('BUREAU_FORM.STEP_THREE.TITLE')}
                </Typography>

                <DropdownMenu
                  list={PersonTypes}
                  value={values.personType}
                  setValue={value => setFieldValue('personType', value)}
                  label={t('COMMON.PERSON_TYPE')}
                  error={Boolean(touched.personType && errors.personType)}
                  errorText={touched.personType && t(errors.personType)}
                />

                {values.personType === PersonType.PHYSICAL && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: '5px'
                      }}
                    >
                      {t('BUREAU_FORM.STEP_THREE.FIRST_DESCRIPTION')}
                    </Typography>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.rfc && errors.rfc)}
                      fullWidth
                      helperText={touched.rfc && t(errors.rfc)}
                      label={t('COMMON.RFC')}
                      margin="normal"
                      name="rfc"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.toUpperCase();
                      }}
                      type="text"
                      value={values.rfc}
                      variant="outlined"
                    />
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.curp && errors.curp)}
                      fullWidth
                      helperText={touched.curp && t(errors.curp)}
                      label="CURP"
                      margin="normal"
                      name="curp"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.curp}
                      variant="outlined"
                    />
                  </>
                )}

                {values.personType === PersonType.LEGAL && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: '5px'
                      }}
                    >
                      {t('BUREAU_FORM.STEP_THREE.SECOND_DESCRIPTION')}
                    </Typography>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.rfc && errors.rfc)}
                      fullWidth
                      helperText={touched.rfc && t(errors.rfc)}
                      label={t('COMMON.RFC')}
                      margin="normal"
                      name="rfc"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.toUpperCase();
                      }}
                      type="text"
                      value={values.rfc}
                      variant="outlined"
                    />
                  </>
                )}
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                  disabled={loadingValidation}
                >
                  {t('COMMON.BACK')}
                </Button>
                <LoadingButton
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  loadingIndicator={
                    <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
                  }
                  variant="outlined"
                  loading={loadingValidation}
                  disabled={loadingValidation}
                >
                  {t('COMMON.NEXT')}
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
StepThree.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepThree;
