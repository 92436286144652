import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getCounselorList = serviceBase({
  request: options =>
    axiosClient.get('/counselors', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const createNewCounselor = serviceBase({
  request: body => axiosClient.post('/counselors', body),
  retry: 0
});

export const deleteCounselor = serviceBase({
  request: id => axiosClient.delete(`/counselors/${id}`),
  retry: 0
});

export const assigCounselorsSignatories = serviceBase({
  request: body => axiosClient.patch('/counselors/assign-signatories', body),
  retry: 0
});
