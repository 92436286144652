import {
  CLEAR_DISTRIBUTORS,
  CLEAR_DISTRIBUTORS_OPTIONS,
  LOAD_DISTRIBUTORS,
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_CONTACTS,
  LOAD_DISTRIBUTOR_DETAIL,
  SAVE_DISTRIBUTORS_OPTIONS,
  CLEAR_DISTRIBUTORS_CATALOGS,
  LOAD_DISTRIBUTOR_AGENTS,
  LOAD_DISTRIBUTOR_PARAMETER,
  SET_DISTRIBUTOR_CATALOGS_FAIL_UPLOAD,
  LOAD_KAM_DISTRIBUTOR_PROFILE,
  CLEAR_DISTRIBUTOR_DETAIL
} from '../actions/distributorActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  detail: {},
  distributorKamDetail: {},
  catalog: { items: [], count: 0, failUploadCsv: '' },
  agents: { items: [], count: 0 },
  contacts: { items: [], count: 0 }
};

const distributorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DISTRIBUTORS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case SAVE_DISTRIBUTORS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case LOAD_DISTRIBUTOR_DETAIL.success: {
      return {
        ...state,
        detail: action.data
      };
    }
    case LOAD_KAM_DISTRIBUTOR_PROFILE.success: {
      return {
        ...state,
        distributorKamDetail: action.data
      };
    }
    case CLEAR_DISTRIBUTORS_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_DISTRIBUTOR_CONTACTS.success: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          items: action.items,
          count: action.count
        }
      };
    }
    case LOAD_DISTRIBUTOR_CATALOGS.success: {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          items: action.items,
          count: action.count
        }
      };
    }
    case CLEAR_DISTRIBUTORS_CATALOGS: {
      return {
        ...state,
        options: initialState.options,
        catalogs: initialState.catalog
      };
    }
    case LOAD_DISTRIBUTOR_AGENTS.success: {
      return {
        ...state,
        agents: {
          items: action.items,
          count: action.count
        }
      };
    }
    case LOAD_DISTRIBUTOR_PARAMETER.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.data
        }
      };
    }
    case SET_DISTRIBUTOR_CATALOGS_FAIL_UPLOAD: {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          failUploadCsv: action.payload
        }
      };
    }
    case CLEAR_DISTRIBUTORS: {
      return initialState;
    }
    case CLEAR_DISTRIBUTOR_DETAIL: {
      return {
        ...state,
        detail: initialState.detail
      };
    }
    default: {
      return state;
    }
  }
};

export default distributorReducer;
