import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  },
  tableRoot: {
    display: 'flex',
    height: '90%',
    width: '95%',
    margin: '16px'
  },
  tableBody: {
    height: '100%',
    width: '100%',
    display: 'table'
  },
  GeneralData: {
    fontWeight: 700,
    fontSize: '15px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  },
  closeIcon: {
    float: 'right',
    marginTop: '-10px',
    width: '20px',
    height: '20px'
  },
  contentRow: {
    fontSize: '15px'
  },
  tableRow: {
    height: '100px !important'
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '1.25rem',
    alignContent: 'center'
  },
  tableCell: {
    fontSize: '1.5rem'
  },
  boxHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '1.25rem',
    alignContent: 'center'
  }
}));
