import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AlertWithLink = ({
  open,
  text,
  link,
  hideCloseIcon = false,
  handleClose,
  severity
}) => {
  if (!open) {
    return null;
  }

  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          !hideCloseIcon ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        sx={{ textAlign: 'justify'}}
      >
        {text}
        <br />
        {link}
      </Alert>
    </Collapse>
  );
};
export default AlertWithLink;

AlertWithLink.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  link: PropTypes.element,
  hideCloseIcon: PropTypes.bool,
  handleClose: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'success', 'info'])
};
