import React, { useEffect, useMemo, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'src/components/DropdownMenu';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  name: data ? data.name : '',
  city: data ? data.city : ''
});

const AgentForm = ({ open, data, handleClose, handleCreate }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    return handleCreate;
  }, [data, handleCreate]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-test-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data
              ? t('CATALOGS_VIEW.UPDATE_REGISTER')
              : t('CATALOGS_VIEW.ADD_NEW_REGISTER')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && t(errors.name)}
                      label={t('COMMON.NAME')}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />
                  </FormControl>

                  <DropdownMenu
                    list={[
                      { key: 'Monterrey', name: 'Monterrey' },
                      { key: 'México', name: 'México' }
                    ]}
                    value={values.city}
                    setValue={value => setFieldValue('city', value)}
                    label={t('COMMON.CITY')}
                    error={Boolean(touched.city && errors.city)}
                    errorText={touched.city && t(errors.city)}
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? t('COMMON.UPDATE') : t('COMMON.CREATE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AgentForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired
};

export default AgentForm;
