import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string(),
  paternalName: Yup.string(),
  maternalName: Yup.string(),
  phone: Yup.string().length(12, 'SCHEMA_ERRORS.PHONE_LENGTH'),
  email: Yup.string().test(
    'email',
    'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID',
    validateEmail
  ),
  comission: Yup.number()
    .min(0, 'SCHEMA_ERRORS.COMISSION_MIN_REQUIRED')
    .max(100, 'SCHEMA_ERRORS.COMISSION_MAX_REQUIRED')
});
