import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getTenureFromDate } from 'src/utils/investors';
import { formatAmount } from 'src/utils/formatAmout';
import OverflowTipography from 'src/components/OverflowTipography';

const InvestorRow = ({
  tenure,
  fullName,
  id,
  investment,
  ownership,
  personType,
  agent
}) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center">{id}</TableCell>
      <TableCell align="left">
        <OverflowTipography maxWidth="200px">
          <Link to={`/investors/detail/${id}/general`}>{fullName}</Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {t(`ENUMS.PERSON_TYPE.${personType}`)}
      </TableCell>
      <TableCell align="center">
        {tenure ? getTenureFromDate(tenure, t) : t('COMMON.NO_DATA')}
      </TableCell>
      {agent ? (
        <TableCell align="center">
          <Link to={`/investors/agents/detail/${agent.id}`}>{agent.name}</Link>
        </TableCell>
      ) : (
        <TableCell align="center">{t('COMMON.NO_DATA')}</TableCell>
      )}
      <TableCell align="center">{formatAmount(investment)}</TableCell>
      <TableCell align="center">
        {ownership ? `${(ownership * 100).toFixed(2)}%` : 0}
      </TableCell>
      <TableCell align="center">
        <MoreMenu disabled />
      </TableCell>
    </TableRow>
  );
};

InvestorRow.propTypes = {
  tenure: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ownership: PropTypes.number.isRequired,
  personType: PropTypes.string.isRequired,
  investment: PropTypes.number.isRequired,
  agent: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
};

export default InvestorRow;
