import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { REJECT_REGISTRATION_REQUEST } from 'src/store/actions/userKamActions';
import Preloader from 'src/components/Preloader';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const RejectKamRegistrationRequestDialog = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const isRejectingRegistrationRequest = useSelector(
    isLoadingSelector([REJECT_REGISTRATION_REQUEST.action])
  );

  const initialState = useMemo(() => {
    return makeInitialState();
  }, []);

  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle
        className={classes.header}
        id="assign-kam-dialog-title"
        variant="h2"
        color="primary"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        {t('KAM_REGISTRATION_REQUEST.DIALOG_TITLE.REJECT_REGISTRATION_REQUEST')}
      </DialogTitle>
      <DialogContent id="reject-kam-reqistration-dialog">
        {isRejectingRegistrationRequest ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              resetForm,
              handleBlur,
              handleChange
            }) => {
              resetRef.current = () => resetForm();
              return (
                <Form onSubmit={handleSubmit}>
                  <Box />
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.rejectionReason && errors.rejectionReason)}
                    fullWidth
                    helperText={touched.rejectionReason && t(errors.rejectionReason)}
                    label={t('COMMON.COMMENT')}
                    margin="normal"
                    name="rejectionReason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.rejectionReason}
                    variant="outlined"
                  />
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: '0.5rem auto'
                    }}
                  >
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

RejectKamRegistrationRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RejectKamRegistrationRequestDialog;
