import React, { useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { selectLogin } from 'src/store/selectors/authSelectors';
import Loading from 'src/components/Loading';

const KamAutocomplete = ({
  isLoading,
  options,
  onChange,
  value,
  label,
  error,
  helperText,
  onBlur,
  name,
  onClose,
  setLoadingView,
  onSubmit,
  onInvalidKam,
  ...rest
}) => {
  const { t } = useTranslation();
  const { id } = useSelector(selectLogin);
  const loading = isLoading;

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('distributorProfile', {
      id,
      name: t('COMMON.AUTOASSIGN')
    });
    setLoadingView(false);
    onSubmit();
  }, []);

  useEffect(() => {
    if (values.distributorProfile) {
      if (id !== values.distributorProfile.id) return onInvalidKam();
      return onSubmit();
    }
    return null;
  }, [values.distributorProfile]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Autocomplete
          id="kam-autocomplete"
          options={options}
          getOptionLabel={option =>
            option ? `${option.id} - ${option.name}` : ''
          }
          value={value}
          onChange={onChange}
          loading={loading}
          clearIcon={null}
          isOptionEqualToValue={(option, val) =>
            option.id === val.id || val === ''
          }
          renderInput={params => (
            <TextField
              {...params}
              label={loading ? `${t(`COMMON.LOADING`)} ${label}` : label}
              error={error}
              helperText={helperText}
              onBlur={onBlur}
              name={name}
              {...rest}
            />
          )}
        />
      )}
    </>
  );
};

export default KamAutocomplete;

KamAutocomplete.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  distributor: PropTypes.object,
  client: PropTypes.object,
  onClose: PropTypes.func,
  setLoadingView: PropTypes.func,
  onSubmit: PropTypes.func,
  onInvalidKam: PropTypes.func
};
