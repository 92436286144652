import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectAmortizationBalanceList,
  selectAmortizationBalancesListCount,
  selectAmortizationBalancesQueryOptions,
  selectPolicyBalanceList
} from 'src/store/selectors/amortizationBalanceSelectors';
import {
  CLEAR_AMORTIZATION_LIST,
  loadAmortizationBalance,
  LOAD_AMORTIZATION_BALANCE
} from 'src/store/actions/amortizationBalanceActions';
import AmortizationBalanceType from 'src/utils/constants/accounting';
import AmortizationBalanceTable from './AmortizationBalanceTable';

const selectBalanceListSelector = type => {
  switch (type) {
    case AmortizationBalanceType.ACUMULATED_AMORTIZATION:
      return selectAmortizationBalanceList;
    case AmortizationBalanceType.POLICY_PRICE:
      return selectPolicyBalanceList;
    default:
      return selectAmortizationBalanceList;
  }
};

const AmortizationBalanceList = () => {
  const dispatch = useDispatch();
  const count = useSelector(selectAmortizationBalancesListCount);
  const { page, amortizationBalanceType } = useSelector(
    selectAmortizationBalancesQueryOptions
  );
  const amortizationBalanceList = useSelector(
    selectBalanceListSelector(amortizationBalanceType)
  );
  const loading = useSelector(
    isLoadingSelector([LOAD_AMORTIZATION_BALANCE.action])
  );
  const success = useSelector(
    successSelector([LOAD_AMORTIZATION_BALANCE.action])
  );

  useEffect(() => {
    dispatch(loadAmortizationBalance());
    return () => dispatch({ type: CLEAR_AMORTIZATION_LIST });
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(
        loadAmortizationBalance({ page: value, amortizationBalanceType })
      );
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.POLICIES_AMORTIZATION_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.POLICIES_AMORTIZATION_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <AmortizationBalanceTable
      amortizationBalanceList={amortizationBalanceList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default AmortizationBalanceList;
