import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stepperLabels = [
  'CLIENTS_VIEW.CREATION_FORM.RFC',
  'CLIENTS_VIEW.CREATION_FORM.PERSONAL_INFORMATION',
  'INVESTORS_VIEW.ASSIGN_AGENT',
  'COMMON.CONFIRMATION'
];

const CreateInvestorForm = ({ open, handleClose, handleCreateInvestor }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]);

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = useCallback(() => {
    switch (step) {
      case 0:
        handleClose();
        setData(initialState);
        break;
      default:
        setStep(step - 1);
        break;
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit =
    values => {
      handleCreateInvestor({ ...data, ...values }, () => setData(initialState));
    }

  if (!open) return null;

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-investor-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle className={classes.title} id="form-new-investor-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('INVESTORS_VIEW.CREATE_INVESTOR_TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2, mb: 2 }}>
          <FormStepper index={step} labels={labels} />
          <CurrentStep
            activeStep={step}
            setStep={setStep}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

CreateInvestorForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateInvestor: PropTypes.func.isRequired
};

export default CreateInvestorForm;
