import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Box, TextField } from '@mui/material';
import { paymentTypeList } from 'src/utils/constants/common';
import DropdownMenu from './DropdownMenu';
import MoneyMask from './MoneyMask/MoneyMask';

const PaymentMultipleInput = ({
  firstInputName,
  secondInputName,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    handleChange,
    errors,
    touched,
    values,
    setFieldValue
  } = useFormikContext();
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: '4rem auto',
        gap: '0.5rem'
      }}
    >
      <DropdownMenu
        list={paymentTypeList}
        value={values[firstInputName]}
        setValue={value => setFieldValue(firstInputName, value)}
        error={Boolean(touched[firstInputName] && errors[firstInputName])}
        disabled={disabled}
      />
      <TextField
        InputProps={{
          autoComplete: 'off',
          inputComponent: MoneyMask,
          inputProps: {
            withPrefix: false
          }
        }}
        error={Boolean(touched[secondInputName] && errors[secondInputName])}
        fullWidth
        helperText={touched[secondInputName] && t(errors[secondInputName])}
        margin="normal"
        name={secondInputName}
        onChange={handleChange}
        value={values[secondInputName]}
        variant="outlined"
        disabled={disabled}
        {...props}
      />
    </Box>
  );
};

export default PaymentMultipleInput;

PaymentMultipleInput.propTypes = {
  firstInputName: PropTypes.string,
  secondInputName: PropTypes.string,
  disabled: PropTypes.bool
};
