import { PaymentInputOption } from 'src/utils/constants/quotes';

const makeInitialState = values => {
  return {
    ...values,
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values ? values.price : '',
    equipmentInsuranceIncluded: values
      ? values.equipmentInsuranceIncluded
      : false,
    initialPayment: values ? values.initialPayment : '',
    paymentInputOption: values
      ? values.paymentInputOption
      : PaymentInputOption.PERCENTAGE
  };
};

export default makeInitialState;
