import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { useSelector } from 'react-redux';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { UserRoles } from 'src/utils/constants/auth';
import ContractStatus from 'src/utils/constants/contracts';

const DeleteConfirmationDialog = ({
  open,
  handleClose,
  handleSubmit,
  contractStatus
}) => {
  const { t } = useTranslation();
  const role = useSelector(selectAuthRole);

  if (!open) {
    return null;
  }

  const handleSubmitForm = () => {
    handleSubmit();
    handleClose();
  };
  return (
    <ConfirmationDialog
      open={open}
      onSubmit={handleSubmitForm}
      handleClose={handleClose}
      key="contract-confirmation"
      title={t('CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT')}
      width={100}
    >
      <Box ml={2}>
        {role === UserRoles.SUPER_ADMIN &&
        contractStatus === ContractStatus.IN_PROGRESS ? (
          <List sx={{ listStyleType: 'decimal' }}>
            <ListItem
              sx={{
                display: 'list-item',
                color: 'primary.main',
                fontSize: 18
              }}
            >
              {t('CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT_DESCRIPTION')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                color: 'primary.main',
                fontSize: 18
              }}
            >
              {t('CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT_DESCRIPTION_2')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                color: 'primary.main',
                fontSize: 18
              }}
            >
              {t('CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT_DESCRIPTION_3')}
            </ListItem>
          </List>
        ) : (
          <Typography
            variant="h4"
            width="100%"
            textAlign="center"
            mt={1}
            mb={1}
          >
            {t('CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT_DESCRIPTION')}
          </Typography>
        )}
      </Box>
    </ConfirmationDialog>
  );
};

export default DeleteConfirmationDialog;

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contractStatus: PropTypes.string.isRequired
};
