import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { ReactComponent as ExpandMoreIcon } from 'src/assets/icons/keyboard_arrow_down.svg';
import { ReactComponent as ExpandLessIcon } from 'src/assets/icons/keyboard_arrow_up.svg';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import NavItem from './NavItem';

const useStyles = makeStyles(theme => ({
  itemTitle: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    letterSpacing: 0.1,
    textTransform: 'none'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    fill: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2.2)
  },
  listItem: {
    paddingLeft: theme.spacing(1)
  },
  active: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main
  }
}));

const SubMenu = ({ icon: Icon, children, title, openDrawer }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const isActive = useMemo(() => {
    return !!children.find(({ href }) => location.pathname.includes(href));
  }, [location, children]);

  const handleClick = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <>
      <ListItem
        className={clsx(classes.listItem, { [classes.active]: isActive })}
        disableGutters
        button
        onClick={handleClick}
      >
        {Icon && (
          <ListItemIcon>
            <Icon
              className={clsx(classes.icon, { [classes.active]: isActive })}
              size="20"
            />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            primary: clsx(classes.itemTitle, { [classes.active]: isActive })
          }}
          primary={openDrawer ? t(title) : ''}
        />
        {open ? (
          <ExpandLessIcon fill="white" size="20" />
        ) : (
          <ExpandMoreIcon fill="white" size="20" />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map(child => (
            <NavItem
              className={classes.nested}
              href={child.href}
              key={child.title}
              title={t(child.title)}
              icon={child.icon}
              openDrawer={openDrawer}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

SubMenu.propTypes = {
  icon: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  openDrawer: PropTypes.bool
};

export default SubMenu;
