import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import { Typography, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import SearchBar from 'src/components/SearchBar';
import { selectQuotesQueryOptions } from 'src/store/selectors/quoteSelectors';
import {
  CLEAR_QUOTES,
  CLEAR_QUOTE_OPTIONS,
  createQuickQuoteAction,
  createQuote,
  loadQuotes
} from 'src/store/actions/quoteActions';
import { FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import {
  KamAssignationStatus,
  QuoteCreationOption,
  QuoteStatus
} from 'src/utils/constants/quotes';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FILTER_TYPES from 'src/utils/constants/filter';
import AddIcon from '@mui/icons-material/Add';
import QuickQuote from '@mui/icons-material/ScheduleSend';
import CalculateIcon from '@mui/icons-material/Calculate';
import useStyles from './styles';
import CreateQuoteForm from './CreateQuoteForm';
import CreateQuickQuoteForm from './CreateQuickQuoteForm';
import MenuButton from '../../components/MenuButton/MenuButton';
import RentCalculatorForm from './RentCalculatorForm';

const filters = [
  {
    title: 'COMMON.KAM_ASSIGNATION_STATUS',
    controlGroup: FILTER_TYPES.CHECKBOX,
    items: [
      {
        label: 'ENUMS.KAM_ASSIGNATION_STATUS.ASSIGNED',
        value: KamAssignationStatus.ASSIGNED
      },
      {
        label: 'ENUMS.KAM_ASSIGNATION_STATUS.UNASSIGNED',
        value: KamAssignationStatus.UNASSIGNED
      }
    ]
  }
];

const quoteCreationOptions = [
  {
    label: 'COMMON.CREATE_NORMAL_QUOTE',
    key: QuoteCreationOption.CREATE_QUOTE,
    icon: <AddIcon />
  },
  {
    label: 'COMMON.QUICK_QUOTE',
    key: QuoteCreationOption.QUICK_QUOTE,
    icon: <QuickQuote />
  },
  {
    label: 'COMMON.RENT_CALCULATOR',
    key: QuoteCreationOption.RENT_CALCULATOR,
    icon: <CalculateIcon />
  }
];

const QuotesAdminToolbar = () => {
  const { t } = useTranslation();
  const { tab } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectQuotesQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [openQuotesCreationForm, setUserQuotesCreationForm] = useState(false);
  const [
    openQuickQuotesCreationForm,
    setUserQuickQuotesCreationForm
  ] = useState(false);
  const [openRentCalculator, setOpenRentCalculator] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  const handleOpenCreationForm = useCallback(() => {
    setUserQuotesCreationForm(true);
  }, [setUserQuotesCreationForm]);

  const handleCloseCreationForm = useCallback(() => {
    setUserQuotesCreationForm(false);
  }, [setUserQuotesCreationForm]);

  const handleOpenQuickCreationForm = useCallback(() => {
    setUserQuickQuotesCreationForm(true);
  }, [setUserQuickQuotesCreationForm]);

  const handleCloseQuickCreationForm = useCallback(() => {
    setUserQuickQuotesCreationForm(false);
  }, [setUserQuickQuotesCreationForm]);

  const handleOpenRentCalculator = useCallback(() => {
    setOpenRentCalculator(true);
  }, [setOpenRentCalculator]);

  const handleCloseRentCalculator = useCallback(() => {
    setOpenRentCalculator(false);
  }, [setOpenRentCalculator]);

  const handleCreateNewQuote = useCallback(
    values => {
      dispatch(createQuote(values, () => handleCloseCreationForm()));
    },
    [dispatch, handleCloseCreationForm]
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  function getFilterOptions(options) {
    const kamAssignationStatus = [];

    if (options[KamAssignationStatus.ASSIGNED]) {
      kamAssignationStatus.push(KamAssignationStatus.ASSIGNED);
    }
    if (options[KamAssignationStatus.UNASSIGNED]) {
      kamAssignationStatus.push(KamAssignationStatus.UNASSIGNED);
    }

    return { kamAssignationStatus };
  }

  const debounceFilter = useDebounce(
    queryOpts =>
      dispatch(
        loadQuotes({
          ...queryOpts
        })
      ),
    400
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [setFilterValues, debounceFilter, queryOptions]
  );

  const debounceSearch = useDebounce(
    (value, queryOpts) =>
      dispatch(loadQuotes({ ...queryOpts, page: 0, ...value })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      return debounceSearch({ clientName: value }, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleCreateQuickQuote = useCallback(values => {
    dispatch(createQuickQuoteAction(values, handleCloseQuickCreationForm));
  }, []);

  const handleCreateRentCalculator = useCallback(values => {
    dispatch(createQuickQuoteAction(values, handleCloseRentCalculator));
  }, []);

  useEffect(() => {
    dispatch({ type: CLEAR_QUOTE_OPTIONS });
    dispatch(
      loadQuotes({
        status: tab || QuoteStatus.IN_REVIEW
      })
    );
    return () => dispatch({ type: CLEAR_QUOTES });
  }, [tab]);

  const handleOpenQuoteCreation = option => {
    switch (option) {
      case QuoteCreationOption.CREATE_QUOTE:
        handleOpenCreationForm();
        break;
      case QuoteCreationOption.QUICK_QUOTE:
        handleOpenQuickCreationForm();
        break;
      case QuoteCreationOption.RENT_CALCULATOR:
        handleOpenRentCalculator();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSearchValue('');
  }, []);

  return (
    <>
      <Box className={classes.boxContainer}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontWeight: '400',
              textTransform: 'capitalize'
            }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.QUOTES')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <MenuButton
              label={t('COMMON.CREATE_QUOTE')}
              options={quoteCreationOptions}
              handleClickOption={handleOpenQuoteCreation}
            />
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />

            <CreateQuoteForm
              open={openQuotesCreationForm}
              handleCreateQuote={handleCreateNewQuote}
              handleClose={handleCloseCreationForm}
              key="create-quote-form"
            />

            <CreateQuickQuoteForm
              open={openQuickQuotesCreationForm}
              handleCreateQuickQuote={handleCreateQuickQuote}
              handleClose={handleCloseQuickCreationForm}
              key="create-quick-quote-form"
            />

            <RentCalculatorForm
              open={openRentCalculator}
              handleClose={handleCloseRentCalculator}
              key="rent-calculator-form"
              handleCreateRentCalculator={handleCreateRentCalculator}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QuotesAdminToolbar;
