import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { loadAllUserClients } from 'src/store/actions/userClientActions';
import { loadAllDistributorEquipmentsCatalog } from 'src/store/actions/userDistributorActions';
import { loadUserProfile } from 'src/store/actions/userProfileActions';
import { getDistributorParameters } from 'src/store/actions/distributorActions';
import {
  selectAuthDistributorId,
  selectAuthId
} from 'src/store/selectors/authSelectors';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { loadParameter } from 'src/store/actions/parameterActions';
import { Parameter } from 'src/utils/constants/parameters';
import makeInitialState from './CurrentStep/StepOne/initialState';
import useStyles from './styles';
import CurrentStep from './CurrentStep';

const stepperLabels = ['COMMON.GENERAL', 'COMMON.CONFIRMATION'];

const CreateQuoteForm = ({ open, handleClose, handleCreateQuote }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameter = useSelector(selectDistributorDetail);
  const initialState = useMemo(
    () =>
      makeInitialState({
        equipmentInsuranceIncluded: parameter.isInsuranceIncluded
          ? 'true'
          : 'false',
        gracePeriod: Number(parameter.defaultGracePeriod)
      }),
    [parameter, getDistributorParameters]
  );
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);
  const distributorId = useSelector(selectAuthDistributorId);
  const distributorProfileId = useSelector(selectAuthId);

  const resetRef = useRef();

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = useCallback(() => {
    switch (step) {
      case 0:
        setData(initialState);
        handleClose();
        break;
      case 1:
        setStep(step - 1);
        break;
      default:
        setStep(step - 1);
        break;
    }
  }, [dispatch, step, setStep, handleClose]);

  useEffect(() => {
    if (open) {
      dispatch(loadAllUserClients({ page: 0 }));
      dispatch(loadAllDistributorEquipmentsCatalog());
      dispatch(loadUserProfile());
      dispatch(getDistributorParameters(distributorId));
      dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
    }
    return () => {
      setStep(0);
      setData(initialState);
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [open]);

  const handleSubmit = useCallback(
    values => {
      handleCreateQuote(
        {
          clientId: values.client.id,
          distributorEquipmentId: values.distributorEquipment.id,
          price: values.price,
          distributorProfileId: values.distributorProfile
            ? values.distributorProfile.id
            : distributorProfileId,
          distributorId,
          equipmentInsuranceIncluded:
            values.equipmentInsuranceIncluded === 'true',
          gracePeriod: values.gracePeriod,
          residualPayment: values.residualPayment,
          initialPayment: values.initialPayment
        },
        () => setData(initialState)
      );
    },
    [data]
  );

  const handleCloseForm = () => {
    if (resetRef.current) {
      resetRef.current();
    }
    setData(initialState);
    handleClose();
  };

  if (!open) return null;

  return (
    <Box>
      <Dialog
        id="form-new-quote-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-quote-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleCloseForm();
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('QUOTES_VIEW.CREATE_NEW_QUOTE')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <FormStepper index={step} labels={labels} />
          <CurrentStep
            resetRef={resetRef}
            activeStep={step}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            onCloseForm={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

CreateQuoteForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateQuote: PropTypes.func.isRequired
};

export default CreateQuoteForm;
