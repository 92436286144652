import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MainLayout from 'src/layouts/MainLayout';
import { useDispatch } from 'react-redux';

import {
  clearToken,
  decodeTokenForBureauForm
} from 'src/store/actions/bureauFormActions';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import BureauForm from './BureauForm';

const BureauFormView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    dispatch(decodeTokenForBureauForm(token));

    return () => {
      dispatch(clearToken);
    };
  }, [token]);

  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <I18nMenu />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
            textAlign: 'center',
            height: '100%'
          }}
        >
          <Typography variant="h1">{t('BUREAU_FORM.TITLE')}</Typography>
        </Box>
        <BureauForm />
      </Box>
    </MainLayout>
  );
};

export default BureauFormView;
