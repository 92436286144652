import { PaymentInputOption } from 'src/utils/constants/quotes';

const makeInitialState = data => {
  return {
    fullName: data ? data.fullName : '',
    phone: data ? data.phone : '',
    email: data ? data.email : '',
    price: data ? data.price : '',
    residualPayment: data ? data.residualPayment : '',
    distributor: data ? data.distributor : '',
    distributorEquipment: data ? data.distributorEquipment : '',
    distributorProfile: data ? data.distributorProfile : '',
    comment: data ? data.comment : '',
    term: data ? data.term : '',
    initialPayment: data ? data.initialPayment : 0,
    gracePeriod: data ? data.gracePeriod : null,
    paymentInputOption: data
      ? data.paymentInputOption
      : PaymentInputOption.PERCENTAGE
  };
};

export default makeInitialState;
