import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableSortLabel, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import InsuranceRow from './InsurancesRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ insurances, t }) =>
  insurances.map(insurance => (
    <InsuranceRow key={insurance.id} t={t} {...insurance} />
  ));

const InsurancesTable = ({
  className,
  insurances,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');
  const handleRequestSort = useCallback(
    (_event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
      handleSortChange(property, isDesc ? 'asc' : 'desc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange]
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort]
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <Tooltip title={t('INSURANCES_VIEW.EQUIPMENT_ID')}>
          <TableCell
            variant="head"
            key="id"
            align="center"
            sx={{ width: '5%' }}
          >
            {t('COMMON.ID')}
          </TableCell>
        </Tooltip>

        <TableCell variant="head" key="status" align="center">
          {t('COMMON.STATUS')}
        </TableCell>

        <TableCell
          variant="head"
          key="remainingDays"
          sortDirection={orderBy === 'remainingDays' ? order : false}
          className={classes.tableHead}
          align="center"
        >
          <TableSortLabel
            active={orderBy === 'remainingDays'}
            direction={orderBy === 'remainingDays' ? order : 'desc'}
            onClick={createSortHandler('remainingDays')}
            hideSortIcon={orderBy !== 'remainingDays'}
          >
            {t('COMMON.EXPIRATION')}
          </TableSortLabel>
        </TableCell>

        <TableCell
          variant="head"
          key="policyId"
          style={{ minWidth: '200px' }}
          align="center"
        >
          {t('INSURANCES_VIEW.POLICY_NUMBER')}
        </TableCell>

        <TableCell variant="head" key="insurer" align="center">
          {t('INSURANCES_VIEW.INSURER')}
        </TableCell>

        <TableCell variant="head" key="agency" align="center">
          {t('INSURANCES_VIEW.AGENCY')}
        </TableCell>

        <TableCell variant="head" key="mode" align="center">
          {t('COMMON.MODALITY')}
        </TableCell>
      </TableRow>
    ),
    [classes, t, order, orderBy, createSortHandler]
  );

  const tbody = useMemo(() => () => <Tbody t={t} insurances={insurances} />, [
    insurances,
    t
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, insurances]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

InsurancesTable.propTypes = {
  className: PropTypes.string,
  insurances: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool
};

InsurancesTable.defaultProps = {
  count: 0
};

export default InsurancesTable;
