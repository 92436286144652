import {
  CLEAR_INVESTMENT,
  CLEAR_INVESTMENT_OPTIONS,
  LOAD_INVESTMENT_DETAIL,
  LOAD_INVESTMENT_LIST,
  SAVE_INVESTMENT_OPTIONS
} from '../actions/investmentActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  detail: {
    documents: []
  }
};

const investmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INVESTMENT_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_INVESTMENT_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_INVESTMENT_LIST.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_INVESTMENT_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.data
        }
      };
    }
    case CLEAR_INVESTMENT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default investmentReducer;
