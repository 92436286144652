import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTORS,
  loadDistributors
} from 'src/store/actions/distributorActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { selectDistributorList } from 'src/store/selectors/distributorSelectors';
import { capitalizeName } from 'src/utils/common';
import { ACCEPT_REGISTRATION_REQUEST } from 'src/store/actions/userKamActions';
import Preloader from 'src/components/Preloader';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const AcceptKamRegistrationRequestDialog = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const distributorLists = useSelector(selectDistributorList);
  const isLoadingDistributorLists = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTORS.action])
  );
  const isAcceptingRegistrationRequest = useSelector(
    isLoadingSelector([ACCEPT_REGISTRATION_REQUEST.action])
  );

  const initialState = useMemo(() => {
    return makeInitialState();
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(loadDistributors({}, false));
    }
  }, [open]);

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle
        className={classes.header}
        id="assign-kam-dialog-title"
        variant="h2"
        color="primary"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        Aceptar solicitud de registro
      </DialogTitle>
      <DialogContent id="accept-kam-reqistration-dialog">
        {isAcceptingRegistrationRequest ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <Form onSubmit={handleSubmit}>
                  <Box />
                  <FormControl
                    fullWidth
                    error={Boolean(touched.distributor && errors.distributor)}
                  >
                    <Autocomplete
                      options={distributorLists}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || value === ''
                      }
                      id="distributor"
                      loading={isLoadingDistributorLists}
                      getOptionLabel={option =>
                        option
                          ? `${option.id} - ${capitalizeName(option.name)}`
                          : ''
                      }
                      value={values.distributor ?? ''}
                      fullWidth
                      clearIcon={null}
                      onChange={(_e, value) => {
                        setFieldValue('distributor', value);
                      }}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      label={t('COMMON.DISTRIBUTOR')}
                      helperText={touched.distributor && t(errors.distributor)}
                    />
                  </FormControl>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: '0.5rem auto'
                    }}
                  >
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

AcceptKamRegistrationRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AcceptKamRegistrationRequestDialog;
