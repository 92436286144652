import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const EquipmentsAlert = ({ loading, equipments, distributor }) => {
  const [open] = useState(true);
  const { t } = useTranslation();

  if (!loading) {
    if (!equipments.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_FIRST')}
            <Link to={`/distributors/business/${distributor.id}/catalog`}>
              {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

export default EquipmentsAlert;

EquipmentsAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  equipments: PropTypes.array.isRequired,
  distributor: PropTypes.object.isRequired
};
