import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  loadIncomeResume,
  LOAD_INCOME_RESUME
} from 'src/store/actions/incomeResumeActions';
import {
  selectIncomeResumeList,
  selectIncomeResumeListCount,
  selectIncomeResumeQueryOptions
} from 'src/store/selectors/incomeResumeSelector';
import IncomeResumeTable from './IncomeResumeTable';

const IncomeResumeList = () => {
  const dispatch = useDispatch();
  const incomeResumeList = useSelector(selectIncomeResumeList);
  const count = useSelector(selectIncomeResumeListCount);
  const { page, period } = useSelector(selectIncomeResumeQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_INCOME_RESUME.action]));
  const success = useSelector(successSelector([LOAD_INCOME_RESUME.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadIncomeResume({ page: value, period }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.INCOME_RESUME_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.INCOME_RESUME_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <IncomeResumeTable
      incomeResumeList={incomeResumeList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default IncomeResumeList;
