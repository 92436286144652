import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  paternalName: Yup.string().required('SCHEMA_ERRORS.PATERNAL_NAME_REQUIRED'),
  maternalName: Yup.string().required('SCHEMA_ERRORS.MATERNAL_NAME_REQUIRED'),
  rfc: Yup.string()
    .matches(
      /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED'),
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  email: Yup.string()
    .test('email', 'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID', validateEmail)
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  secondaryPhone: Yup.string()
    .test('secondaryPhone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
});
