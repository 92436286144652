import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import loadQuoteCategories, {
  LOAD_QUOTE_CATEGORIES
} from 'src/store/actions/quoteActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import QuoteCategoriesTable from './QuoteCategoriesTable';

const QuoteCategoriesList = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCatalogList);
  const loading = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );
  const success = useSelector(successSelector([LOAD_QUOTE_CATEGORIES.action]));

  useEffect(() => {
    dispatch(loadQuoteCategories());
  }, []);

  if (success && !loading && categories.length === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.QUOTE_CATEGORIES_TITLE" />
      </Card>
    );
  }

  return <QuoteCategoriesTable categories={categories} loading={loading} />;
};

export default QuoteCategoriesList;
