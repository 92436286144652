import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const NavItemBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 30,
    top: 25,
    padding: '0 4px'
  }
}));

export default NavItemBadge;
