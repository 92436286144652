import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { LOAD_AGENT_LIST, loadAllAgents } from 'src/store/actions/agentActions';
import { Clear } from '@mui/icons-material';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';
import AgentAlert from './AgentAlert';

const StepThree = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [agentList, setAgentList] = useState([]);
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loading = useSelector(isLoadingSelector([LOAD_AGENT_LIST.action]));

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  useEffect(() => {
    dispatch(loadAllAgents(setAgentList));
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={onNext}
          enableReinitialize
        >
          {({ handleSubmit, values, errors, touched, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 3 }}>
                  <AgentAlert agents={agentList} loading={loading} />
                </Box>
                <FormControl
                  fullWidth
                  error={Boolean(touched.role && errors.role)}
                >
                  <Autocomplete
                    label={`${t('COMMON.AGENT')} (${t('COMMON.OPTIONAL')})`}
                    name="agent"
                    options={[
                      { fullName: t('COMMON.NO_AGENT'), id: 0 },
                      ...agentList
                    ]}
                    id="agent"
                    value={values.agent ?? ''}
                    getOptionLabel={option =>
                      option ? ` ${option.fullName}` : t('COMMON.NO_AGENT')
                    }
                    clearOnBlur={false}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    sx={{ marginBottom: 1, marginTop: 2 }}
                    clearIcon={<Clear />}
                    onChange={(_e, value) => {
                      if (value && value.id === 0) {
                        setFieldValue('agent', null);
                      } else {
                        setFieldValue('agent', value ?? null);
                      }
                    }}
                    error={Boolean(touched.agent && errors.agent)}
                    helperText={touched.agent && t(errors.agent)}
                  />
                </FormControl>

                <Box
                  className={classes.box}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    className={classes.buttons}
                    onClick={() => handleBack()}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.NEXT')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

StepThree.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};
export default StepThree;
