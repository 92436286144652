import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addPointsPhone } from 'src/utils/common';
import DetailRow from 'src/components/DetailRow';
import useStyles from '../../styles';

const QuotePendingAssignmentDetail = ({ quote }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.CLIENT_DETAIL.TITLE')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DetailRow
              name={t('COMMON.CLIENT')}
              value={quote.clientData.fullName || '-'}
            />
            <DetailRow
              name={t('COMMON.EMAIL')}
              value={quote.clientData.email || '-'}
            />
            <DetailRow
              name={t('COMMON.PHONE')}
              value={
                quote.clientData.phone
                  ? addPointsPhone(quote.clientData.phone)
                  : '-'
              }
            />
            <DetailRow
              name={t('COMMON.COMMENT')}
              value={quote.clientData.comment || '-'}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

QuotePendingAssignmentDetail.propTypes = {
  quote: PropTypes.object.isRequired
};

export default QuotePendingAssignmentDetail;
