export const BackgroundValidationOwner = {
  CLIENT: 'client',
  DISTRIBUTOR: 'distributor',
  INVESTOR: 'investor',
  AVAL: 'aval'
};

export const BackgroundValidationTabList = [
  {
    value: BackgroundValidationOwner.CLIENT,
    label: 'NAVIGATION.CLIENT'
  },
  {
    value: BackgroundValidationOwner.DISTRIBUTOR,
    label: 'NAVIGATION.DISTRIBUTOR'
  },
  {
    value: BackgroundValidationOwner.INVESTOR,
    label: 'NAVIGATION.INVESTOR'
  },
  {
    value: BackgroundValidationOwner.AVAL,
    label: 'NAVIGATION.AVAL'
  }
];

export const getRedirectionLink = (pathname, id) => {
  const isClient = pathname.includes('client');
  const isAval = pathname.includes('aval');
  const isDistributor = pathname.includes('distributor');
  const isInvestor = pathname.includes('investor');

  if (isClient) {
    return `/clients/detail/${id}/identity`;
  }
  if (isAval) {
    return `/aval/detail/${id}/identity`;
  }
  if (isDistributor) {
    return `/distributors/business/${id}`;
  }
  if (isInvestor) {
    return `/investors/detail/${id}/identity`;
  }
  return '';
};
