import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadCompanies
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import CompanyTable from './CompanyTable';

// eslint-disable-next-line react/prop-types
const CompanyList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const companies = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadCompanies());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return (
    <CompanyTable
      companies={companies}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};

export default CompanyList;
