import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import theme from 'src/theme';

const MenuButton = ({
  label = '',
  handleClickOption,
  options = [],
  disabled = false,
  styles = {},
  isLoading = false,
  icon,
  ...rest
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionCliclk = option => {
    handleClickOption(option);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t(label)}>
        <Button
          sx={{
            height: 44,
            letterSpacing: 1.25,
            fontSize: 16,
            fontWeight: '500',
            minWidth: 'unset',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#FFFFFF',
            '&:hover': {
              background: '#FFFFFF',
              opacity: 0.8
            },
            '&:focus': {
              background: '#FFFFFF',
              opacity: 0.8
            },
            ...styles
          }}
          color="primary"
          disabled={disabled}
          onClick={handleClick}
        >
          {isLoading ? (
            <Typography>
              <CircularProgress size={15} />
              {t('COMMON.LOADING')}
            </Typography>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {icon && (
                <ListItemIcon
                  sx={{ minWidth: 'unset', color: theme.palette.primary.main }}
                >
                  {icon}
                </ListItemIcon>
              )}
              {label && t(label)}
            </div>
          )}
        </Button>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        {...rest}
      >
        {options.map(option => {
          return (
            <MenuItem
              disabled={option.disabled || false}
              key={option.key}
              onClick={() => {
                onOptionCliclk(option.key);
              }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <Typography>{t(option.label)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

MenuButton.propTypes = {
  label: PropTypes.string,
  handleClickOption: PropTypes.func.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  styles: PropTypes.object,
  icon: PropTypes.element
};

export default MenuButton;
