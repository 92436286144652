import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Preloader from 'src/components/Preloader';
import DropdownMenu from 'src/components/DropdownMenu';
import { CFDICancelMotives } from 'src/utils/constants/cfdi';
import { CANCEL_CFDI } from 'src/store/constants/CFDI';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const CancelCFDIDialog = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const isLoadingInvoiceCancellation = useSelector(
    isLoadingSelector([CANCEL_CFDI.action])
  );

  const initialState = useMemo(() => {
    return makeInitialState();
  }, []);

  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle
        className={classes.header}
        id="assign-kam-dialog-title"
        variant="h2"
        color="primary"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        {t('ENUMS.CFDI_LIST_ACTIONS.CANCEL')}
      </DialogTitle>
      <DialogContent id="assign-kam-dialog">
        {isLoadingInvoiceCancellation ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <Form onSubmit={handleSubmit}>
                  <Box />
                  <DropdownMenu
                    list={CFDICancelMotives.map(item => ({
                      name: t(`ENUMS.CFDI_CANCEL_MOTIVES.${item.name}`),
                      key: item.key
                    }))}
                    value={values.motive}
                    setValue={value => setFieldValue('motive', value)}
                    label={t('COMMON.CANCEL_MOTIVE')}
                    error={Boolean(touched.motive && errors.motive)}
                    errorText={touched.motive && t(errors.motive)}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: '0.5rem auto'
                    }}
                  >
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

CancelCFDIDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CancelCFDIDialog;
