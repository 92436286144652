import React from 'react';
import PropTypes from 'prop-types';
import {
  CancelRounded,
  NotInterested,
  CheckCircle,
  ErrorOutline
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconStatusAndColor from 'src/utils/constants/common';

const StatusIcon = ({ description, type }) => {
  const { t } = useTranslation();

  const selectIconToRender = () => {
    switch (type) {
      case IconStatusAndColor.OK.status:
        return <CheckCircle style={{ color: IconStatusAndColor.OK.color }} />;

      case IconStatusAndColor.WARNING.status:
        return (
          <ErrorOutline style={{ color: IconStatusAndColor.WARNING.color }} />
        );

      case IconStatusAndColor.ERROR.status:
        return (
          <CancelRounded style={{ color: IconStatusAndColor.ERROR.color }} />
        );

      case IconStatusAndColor.NONE.status:
        return <NotInterested />;

      default:
        return <NotInterested />;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Tooltip
        title={
          description ? (
            <span style={{ whiteSpace: 'pre-line' }}>{t(description)}</span>
          ) : (
            ''
          )
        }
      >
        {selectIconToRender()}
      </Tooltip>
    </Box>
  );
};

StatusIcon.propTypes = {
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf([IconStatusAndColor]).isRequired
};
export default StatusIcon;
