import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import getFileFromExternalLink from 'src/utils/document';
import useStyles from './styles';
import DocumentViewerDialog from '../DocumentViewer/DocumentViewerDialog';

const DocumentLink = ({ link, download = false, fileName }) => {
  const [openViewer, setOpenViewer] = useState(false);

  const handleOpenViewer = () => {
    setOpenViewer(true);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
  };

  const { t } = useTranslation();
  const classes = useStyles();
  return link ? (
    <>
      {!download ? (
        <>
          <a className={classes.documentLink} onClick={handleOpenViewer}>
            <Typography
              variant="body2"
              component="div"
              className={classes.documentText}
            >
              {t('COMMON.DOCUMENT_VIEW')}
              <ExternalLink style={{ marginLeft: '8px' }} size={20} />
            </Typography>
          </a>
          <DocumentViewerDialog
            open={openViewer}
            onClose={handleCloseViewer}
            documentUrl={link}
            fileName={fileName}
          />
        </>
      ) : (
        <a
          className={classes.documentLink}
          onClick={() => {
            try {
              getFileFromExternalLink(link);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Typography
            variant="body2"
            component="div"
            className={classes.documentText}
          >
            {t('COMMON.DOCUMENT_VIEW')}
            <ExternalLink style={{ marginLeft: '8px' }} size={20} />
          </Typography>
        </a>
      )}
    </>
  ) : (
    <>{t('COMMON.NO_DATA')}</>
  );
};

DocumentLink.propTypes = {
  link: PropTypes.string.isRequired,
  download: PropTypes.bool,
  fileName: PropTypes.string
};

export default DocumentLink;
