import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';

const ContractDateDetail = ({ contract }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow name={t('CONTRACT_VIEW.PAY_DAY')} value={contract.payday} />

      <DetailRow
        name={t('CONTRACT_VIEW.HOOK_DATE')}
        value={selectFormatDate(contract.startDate, dateFormatType.DAY)}
      />

      <DetailRow
        name={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
        value={selectFormatDate(contract.firstRentDate, dateFormatType.DAY)}
      />

      <DetailRow
        name={t('CONTRACT_VIEW.LAST_RENT_DATE')}
        value={selectFormatDate(contract.endDate, dateFormatType.DAY)}
      />

      {contract.gracePeriod > 0 && (
        <DetailRow
          name={t('COMMON.DAYS_FOR_FIRST_RENT')}
          value={translateGracePeriodValueToDaysForFirstRent(
            contract.gracePeriod,
            t
          )}
        />
      )}
    </>
  );
};

ContractDateDetail.propTypes = {
  contract: PropTypes.object.isRequired
};
export default ContractDateDetail;
