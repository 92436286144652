const UserType = {
  ADMIN: 'ADMIN',
  DISTRIBUTOR: 'DISTRIBUTOR',
  SUPER_ADMIN: 'SUPER_ADMIN'
};
export const UserRoles = {
  ADMIN: 'ADMIN',
  DISTRIBUTOR: 'DISTRIBUTOR',
  SUPER_ADMIN: 'SUPER_ADMIN'
};

export const DistributorRoles = {
  DIRECTOR: 'DIRECTOR',
  KAM: 'KAM'
};

export const UserTypePath = {
  admin: UserType.ADMIN,
  user: UserType.DISTRIBUTOR
};

export const AuthErrors = {
  UNAUTHORIZED: 'Unauthorized',
  PASSWORD_LENGTH: 'password must be longer than or equal to 8 characters'
};

export const URL_DEFAULT_REDIRECT = process.env.REACT_APP_URL_DEFAULT_REDIRECT;

export default UserType;
