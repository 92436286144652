import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';

import DownloadButton from 'src/components/DownloadButton';
import useDebounce from 'src/hooks/useDebounce';
import {
  downloadCFDIReceived,
  loadCFDIsReceived,
  setCFDIsOptions
} from 'src/store/actions/cfdiActions';
import { FilterList } from '@mui/icons-material';

import Filter from 'src/components/Filter/Filter';
import {
  selectCFDISQueryOptions,
  selectCFDIsReceivedLastSync
} from 'src/store/selectors/cfdiSelectors';
import { loadParameters } from 'src/store/actions/parameterActions';
import { ParameterSection } from 'src/utils/constants/parameters';
import { selectParameterList } from 'src/store/selectors/parameterSelector';
import {
  BillingSubSectionList,
  filterParameterByBillingSubSection
} from 'src/views/parameter/ParameterListView/BillingParameter/BillingParameterList';
import SearchBarByType from 'src/components/SearchBarByType';
import { CLEAR_CFDIS_OPTIONS } from 'src/store/constants/CFDI';
import AlertMessage from 'src/components/Alert/Alert';
import FILTER_TYPES from 'src/utils/constants/filter';
import { IssuerType } from 'src/utils/constants/cfdi';
import dateFormatType from 'src/utils/dateFormatType';
import selectFormatDate from 'src/utils/selectDateFormat';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import useStyles from './styles';
import DownloadCFDIReceivedListDialog from './DownloadCFDIReceivedListDialog/DownloadCFDIReceivedListDialog';

const searchTypes = [
  { value: 'client', label: 'NAME', type: 'text' },
  { value: 'folio', label: 'FOLIO', type: 'text' },
  { value: 'rfc', label: 'RFC', type: 'text' }
];

const typeFilters = [
  {
    title: 'COMMON.DATE',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date',
        view: ['year', 'month']
      }
    ]
  }
];

function getFilterOptions(options) {
  let month = null;
  let year = null;

  if (options.date) {
    month = options.date.month() + 1;
    year = options.date.year();
  }

  return { month, year };
}

const Toolbar = ({ className, ...rest }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValues, setFilterValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDownloadCFDIList, setOpenDownCFDIList] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('name');
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCFDISQueryOptions);
  const parameters = useSelector(selectParameterList);
  const lastSync = useSelector(selectCFDIsReceivedLastSync);

  /* 
  TODO: Implement downloadCFDIList in zip
  const handleOpenDownloadCFDIList = () => {
    setOpenDownCFDIList(true);
  }; */

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDownloadCFDIList = () => {
    setOpenDownCFDIList(false);
  };

  const seriesOptions = filterParameterByBillingSubSection(
    parameters,
    BillingSubSectionList.INVOICE_TYPES
  ).map(parameter => {
    return {
      label: parameter.value,
      value: parameter.value
    };
  });

  const filters = [...typeFilters];

  const debounceSearch = useDebounce(
    (search, queryOpts) =>
      dispatch(loadCFDIsReceived({ ...queryOpts, ...search, page: 0 })),
    400
  );

  const handleSearch = useCallback(
    (e, criteria) => {
      const { value } = e.target;
      setSearchValue(value);
      setSearchCriteria(criteria);
      debounceSearch({ [criteria]: value }, queryOptions);
    },
    [setSearchValue, debounceSearch, searchCriteria]
  );

  const handleSearchCriteria = useCallback(() => {
    setSearchValue('');
    dispatch(
      setCFDIsOptions({
        ...queryOptions,
        [searchCriteria]: ''
      })
    );
  }, [dispatch, queryOptions]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event, seriesOptions)
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions
    ]
  );

  useEffect(() => {
    dispatch(
      loadCFDIsReceived({
        issuer: IssuerType.RECEIVING
      })
    );
    dispatch(
      loadParameters({
        section: ParameterSection.BILLING
      })
    );

    return () => {
      dispatch({ type: CLEAR_CFDIS_OPTIONS });
    };
  }, []);

  useEffect(() => {
    if (lastSync) {
      setOpenAlert(true);
    }
  }, [lastSync]);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.CFDI_RECEIVED')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <SearchBarByType
              types={searchTypes}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleSearchCriteria={handleSearchCriteria}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '30%'
              }}
            >
              <Filter
                anchorEl={anchorEl}
                filterValues={filterValues}
                handleClose={handleCloseFilter}
                handleChange={handleFilterChange}
                filters={filters}
              />
              <Button
                className={clsx(
                  classes.buttons,
                  classes.outlined,
                  classes.horizontalPadding
                )}
                color="primary"
                onClick={handleClickFilter}
              >
                <FilterList className={classes.icon} />
                {t('COMMON.FILTER')}
              </Button>
              {/*           
              TODO: Implement downloadCFDIList in zip
              <Button
                className={clsx(classes.buttons, classes.outlined)}
                onClick={handleOpenDownloadCFDIList}
              >
                <Download sx={{ marginRight: 1 }} />
                <Typography variant="span">
                  {t('COMMON.DOWNLOAD_CFDIS')}
                </Typography>
              </Button> */}

              <DownloadButton
                onDownload={() => {
                  dispatch(
                    downloadCFDIReceived({
                      issuer: IssuerType.RECEIVING
                    })
                  );
                }}
                title="COMMON.DOWNLOAD_CFDIS"
              />

              <DownloadCFDIReceivedListDialog
                open={openDownloadCFDIList}
                onClose={handleCloseDownloadCFDIList}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={!openAlert && { display: 'none' }} mt={3}>
          <AlertMessage
            open
            handleClose={handleCloseAlert}
            autoTranslation={false}
            severity={AlertSeverity.INFO}
            title={t('TOOLTIP_MESSAGES.LAST_SYNC')}
            text={t('TOOLTIP_MESSAGES.LAST_SYNC_DATE').replace(
              '{parameter}',
              selectFormatDate(lastSync, dateFormatType.DATE_COMPLETE)
            )}
          />
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
