import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import RentBalanceScheduleTable from './RentBalanceScheduleTable';

const RentBalanceScheduleDetail = ({ rentBalanceSchedule }) => {
  return (
    <Box mt={3}>
      <RentBalanceScheduleTable rentBalanceSchedule={rentBalanceSchedule} />
    </Box>
  );
};

RentBalanceScheduleDetail.propTypes = {
  rentBalanceSchedule: PropTypes.array
};

export default RentBalanceScheduleDetail;
