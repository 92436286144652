import React, { useCallback, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectKamsList,
  selectKamsListCount,
  selectKamsQueryOptions
} from 'src/store/selectors/kamSelectors';
import { LOAD_KAMS, loadKams } from 'src/store/actions/userKamActions';
import KamsTable from './KamsTable';
import KamListToolbar from './KamListToolbar';

const KamList = () => {
  const dispatch = useDispatch();
  const kams = useSelector(selectKamsList);
  const count = useSelector(selectKamsListCount);
  const { page } = useSelector(selectKamsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_KAMS.action]));
  const success = useSelector(successSelector([LOAD_KAMS.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadKams({ page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadKams({ page: 0 }));
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <KamListToolbar />
        <Grid item xs={12}>
          <KamsTable
            kams={kams}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KamList;
