import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadBackgroundValidations } from 'src/store/actions/backgroundValidationActions';
import {
  selectBackgroundValidationsList,
  selectBackgroundValidationsListCount,
  selectBackgroundValidationsQueryOptions
} from 'src/store/selectors/backgroundValidationSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { LOAD_BACKGROUND_VALIDATIONS } from 'src/store/constants/CFDI';
import BackgroundValidationTable from './BackgroundValidationTable';

const useStyles = makeStyles(() => ({
  prealoderCard: {
    height: '82vh'
  }
}));

const BackgroundValidationList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const backgroundValidationList = useSelector(selectBackgroundValidationsList);
  const count = useSelector(selectBackgroundValidationsListCount);
  const { page } = useSelector(selectBackgroundValidationsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_BACKGROUND_VALIDATIONS.action])
  );
  const success = useSelector(
    successSelector([LOAD_BACKGROUND_VALIDATIONS.action])
  );

  const { tab } = useParams();

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadBackgroundValidations({ page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      loadBackgroundValidations({ validationOwner: tab, page: 0 }, true)
    );
  }, [dispatch, tab]);

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.IDENTITY_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.IDENTITY_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <Box mt={2}>
      <BackgroundValidationTable
        validations={backgroundValidationList}
        count={count}
        page={page}
        handleChangePage={handleChangePage}
        loading={loading}
      />
    </Box>
  );
};

export default BackgroundValidationList;
