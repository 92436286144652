import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPercentageToValue } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  deleteQuoteCategoryAction,
  editQuoteCategoryAction
} from 'src/store/actions/quoteActions';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import QuoteCategoryFormDialog from './QuoteCategoryFormDialog';

const QuoteCategoriesRow = ({
  id,
  name,
  twelveMonthsRate,
  eighteenMonthsRate,
  twentyFourMonthsRate,
  thirtySixMonthsRate,
  fortyEigthMonthsRate,
  sixtyMonthsRate,
  seventyTwoMonthsRate
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const STANDAR_CATEGORY_ID = 1;

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenDeleteDialog(true);
    }
  };

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteQuoteCategoryAction(id));
    handleCloseDelete();
  }, [handleCloseDelete]);

  const handleUpdateContact = useCallback(
    values => {
      dispatch(editQuoteCategoryAction(values));
      handleCloseEdit();
    },
    [dispatch, id]
  );

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(twelveMonthsRate))}
      </TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(eighteenMonthsRate))}
      </TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(twentyFourMonthsRate))}
      </TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(thirtySixMonthsRate))}
      </TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(fortyEigthMonthsRate))}
      </TableCell>
      <TableCell align="center">
        {sixtyMonthsRate
          ? addPercentageToValue(formatAmountWithDecimals(sixtyMonthsRate))
          : '-'}
      </TableCell>
      <TableCell align="center">
        {seventyTwoMonthsRate
          ? addPercentageToValue(formatAmountWithDecimals(seventyTwoMonthsRate))
          : '-'}
      </TableCell>
      <TableCell align="right">
        <MoreMenu
          handleClickOption={handleClickMore}
          deleteDisabled={id === STANDAR_CATEGORY_ID}
        />
      </TableCell>
      <QuoteCategoryFormDialog
        open={openEditDialog}
        handleClose={handleCloseEdit}
        onSubmit={handleUpdateContact}
        quote={{
          name,
          twelveMonthsRate,
          eighteenMonthsRate,
          twentyFourMonthsRate,
          thirtySixMonthsRate,
          fortyEigthMonthsRate,
          sixtyMonthsRate,
          seventyTwoMonthsRate,
          id
        }}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        onSubmit={handleSubmitDelete}
        title={t('QUOTES_VIEW.DELETE_QUOTE_CATEGORY')}
        description={t('QUOTES_VIEW.DELETE_QUOTE_CATEGORY_DESCRIPTION')}
      />
    </TableRow>
  );
};

QuoteCategoriesRow.propTypes = {
  name: PropTypes.string.isRequired,
  twelveMonthsRate: PropTypes.number.isRequired,
  eighteenMonthsRate: PropTypes.number.isRequired,
  twentyFourMonthsRate: PropTypes.number.isRequired,
  thirtySixMonthsRate: PropTypes.number.isRequired,
  fortyEigthMonthsRate: PropTypes.number.isRequired,
  sixtyMonthsRate: PropTypes.number.isRequired,
  seventyTwoMonthsRate: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
};

export default QuoteCategoriesRow;
