import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  deleteModelAction,
  editModelAction
} from 'src/store/actions/catalogActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthDistributorId,
  selectAuthSystem
} from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import ModelForm from './ModelsForm';

const ModelsRow = ({ id: modelId, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, brandId } = useParams();
  const [openModelForm, setOpenModelForm] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const distributorId = useSelector(selectAuthDistributorId);
  const system = useSelector(selectAuthSystem);

  const handleCloseDeleteModal = () => {
    setOpenModalDelete(false);
  };

  const handleCloseModelForm = () => {
    setOpenModelForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenModelForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenModalDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    if (brandId) {
      return dispatch(
        deleteModelAction(brandId, modelId, id, handleCloseDeleteModal)
      );
    }
    return dispatch(
      deleteModelAction(
        id,
        modelId,
        system === UserType.DISTRIBUTOR ? distributorId : id,
        handleCloseDeleteModal
      )
    );
  }, []);

  const handleEdit = useCallback(
    values => {
      if (brandId) {
        return dispatch(
          editModelAction(
            brandId,
            id,
            { ...values, id: modelId },
            handleCloseModelForm
          )
        );
      }
      if (system === UserType.DISTRIBUTOR) {
        return dispatch(
          editModelAction(id, distributorId, { ...values, id: modelId }, handleCloseModelForm)
        );
      }
      return dispatch(
        editModelAction(id, null, { ...values, id: modelId }, handleCloseModelForm)
      );
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <ModelForm
        data={{ name, id }}
        open={openModelForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openModalDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.MODELS.DELETE_MODEL_TITLE')}
        description={t('CATALOGS_VIEW.MODELS.DELETE_MODEL_DESCRIPTION')}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

ModelsRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default ModelsRow;
