import {
  downloadExportedCapitalBalance,
  getCapitalBalance
} from 'src/services/capitalBalanceService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectCapitalBalanceQueryOptions } from '../selectors/capitalBalanceSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_CAPITAL_BALANCE = asyncActionCreator('LOAD_CAPITAL_BALANCE');
export const DOWNLOAD_CAPITAL_BALANCE = asyncActionCreator(
  'DOWNLOAD_CAPITAL_BALANCE'
);
export const CLEAR_CAPITAL_BALANCE = 'CLEAR_CAPITAL_BALANCE';
export const SAVE_CAPITAL_BALANCE_OPTIONS = 'SAVE_CAPITAL_BALANCE_OPTIONS';
export const CLEAR_CAPITAL_BALANCE_OPTIONS = 'CLEAR_CAPITAL_BALANCE_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectCapitalBalanceQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }
  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_CAPITAL_BALANCE_OPTIONS });
};

export const loadCapitalBalance = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CAPITAL_BALANCE.start });

    dispatch({
      type: SAVE_CAPITAL_BALANCE_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const capitalBalance = await getCapitalBalance(reqOptions);

    return dispatch({
      type: LOAD_CAPITAL_BALANCE.success,
      ...capitalBalance
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CAPITAL_BALANCE.failure });
  }
};

export const downloadExcelCapitalBalance = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOWNLOAD_CAPITAL_BALANCE.start
    });

    dispatch({
      type: SAVE_CAPITAL_BALANCE_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExportedCapitalBalance(reqOptions);

    return dispatch({
      type: DOWNLOAD_CAPITAL_BALANCE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_CAPITAL_BALANCE.failure });
  }
};
