import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  selectDistributorAgents,
  selectDistributorList
} from 'src/store/selectors/distributorSelectors';
import { capitalizeName } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_DISTRIBUTORS,
  LOAD_DISTRIBUTOR_AGENTS,
  loadDistributorAgents,
  loadDistributors
} from 'src/store/actions/distributorActions';
import { CLEAR_KAM_DETAIL } from 'src/store/actions/userKamActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import KamAutocomplete from './KamAutocomplete';
import DistributorAndKamAlert from './DistributorAndKamAlert';

const StepOne = ({ data, handleNext, resetRef }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const distributors = useSelector(selectDistributorList);
  const distributorProfiles = useSelector(selectDistributorAgents);
  const isLoadingDistributor = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTORS.action])
  );
  const isLoadingDistributorProfiles = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext]
  );

  const handleDistributorChange = useCallback((distributor, setFieldValue) => {
    dispatch({ type: CLEAR_KAM_DETAIL });
    setFieldValue('distributor', distributor);
    setFieldValue('distributorProfile', '');
    setFieldValue('distributorEquipment', '');
    setFieldValue('price', '');
    dispatch(loadDistributorAgents(distributor.id, null, false));
  }, []);

  useEffect(() => {
    dispatch(loadDistributors({}, false));
  }, []);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <DistributorAndKamAlert
                  distributors={distributors}
                  selectedDistributor={values.distributor}
                  loading={isLoadingDistributor || isLoadingDistributorProfiles}
                  kams={distributorProfiles}
                />
                <FormControl
                  fullWidth
                  error={Boolean(touched.distributor && errors.distributor)}
                >
                  <Autocomplete
                    options={distributors}
                    id="distributor"
                    loading={isLoadingDistributor}
                    getOptionLabel={option =>
                      option
                        ? `${option.id} - ${capitalizeName(option.name)}`
                        : ''
                    }
                    value={values.distributor ?? ''}
                    fullWidth
                    clearIcon={null}
                    onChange={(_e, value) => {
                      handleDistributorChange(value, setFieldValue, values);
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    label={t('COMMON.DISTRIBUTOR')}
                    error={Boolean(touched.distributor && errors.distributor)}
                    helperText={touched.distributor && t(errors.distributor)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorProfile && errors.distributorProfile
                  )}
                >
                  <KamAutocomplete
                    options={distributorProfiles}
                    id="distributorProfile"
                    isLoading={isLoadingDistributor}
                    value={values.distributorProfile ?? ''}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('distributorProfile', value ?? '');
                    }}
                    label={`${t('COMMON.KAM')} (${t('COMMON.OPTIONAL')})`}
                    error={
                      touched.distributorProfile && errors.distributorProfile
                    }
                    helperText={
                      touched.distributorProfile && t(errors.distributorProfile)
                    }
                  />
                </FormControl>
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepOne;
