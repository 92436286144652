import React from 'react';
import PropTypes from 'prop-types';
import MaterailTab from '@mui/material/Tab';
import { Tooltip } from '@mui/material';

const Tab = ({ label, description, ...props }) => {
  return (
    <MaterailTab
      label={
        <Tooltip title={description ?? null}>
          <span>{label}</span>
        </Tooltip>
      }
      {...props}
    />
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string
};

export default Tab;
