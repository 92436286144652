import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadWithdolgins
} from 'src/store/actions/cfdiActions';
import {
  selectCFDISQueryOptions,
  selectWithholdingsCount,
  selectWithholdingsList
} from 'src/store/selectors/cfdiSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_WITHHOLDINGS } from 'src/store/constants/CFDI';
import useStyles from './styles';
import WithholdingsTable from './WithholdingsTable';

const WithholdingsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const withholdings = useSelector(selectWithholdingsList);
  const count = useSelector(selectWithholdingsCount);
  const { page } = useSelector(selectCFDISQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_WITHHOLDINGS.action]));
  const success = useSelector(successSelector([LOAD_WITHHOLDINGS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadWithdolgins({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && !count ) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.WITHHOLDINGS_TITLE" />
      </Card>
    );
  }

  return (
    <WithholdingsTable
      withholdings={withholdings}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default WithholdingsList;
