import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getDifferenceBetweenStates } from 'src/utils/common';
import PhoneInput from 'src/components/PhoneInput';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const KamDialog = ({
  open,
  handleClose,
  onSubmit,
  kam,
  isEmailValidated = false
}) => {
  const classes = useStyles();
  const initialState = makeInitialState(kam);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (kam) {
        const difference = getDifferenceBetweenStates(initialState, values);
        if (Object.entries(difference).length === 0) {
          handleClose();
          return;
        }
        onSubmit({
          ...difference
        });
      } else {
        onSubmit({
          ...values
        });
      }
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {kam
            ? t('DISTRIBUTORS_VIEW.AGENTS.EDIT_KAM')
            : t('DISTRIBUTORS_VIEW.AGENTS.CREATE_KAM')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    label={t('COMMON.PHONE')}
                    value={values.phone}
                    onBlur={handleBlur}
                    error={Boolean(touched.phone && errors.phone)}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="officePhone"
                    label={t('COMMON.OFFICE_PHONE')}
                    error={Boolean(touched.officePhone && errors.officePhone)}
                    value={values.officePhone}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.officePhone && errors.officePhone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.officePhone)}
                    </FormHelperText>
                  )}
                </FormControl>
                <Tooltip
                  title={
                    isEmailValidated
                      ? t('DISTRIBUTORS_VIEW.AGENTS.EMAIL_VALIDATED_MESSAGE')
                      : ''
                  }
                >
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && t(errors.email)}
                    label={t('COMMON.EMAIL')}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    disabled={isEmailValidated}
                    value={values.email}
                    variant="outlined"
                  />
                </Tooltip>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.dialogButton)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {kam ? t('COMMON.EDIT') : t('COMMON.CREATE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

KamDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  kam: PropTypes.object,
  isEmailValidated: PropTypes.bool
};

export default KamDialog;
