import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from 'src/components/Table/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../styles';

const CompanyRow = ({ name, taxId, openEdit, handleDelete }) => {
  const classes  = useStyles();

  return (
    <TableRow hover>
      <TableCell>{taxId}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={openEdit}
          key="edit"
          aria-label="Edit"
          color="inherit"
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={() => handleDelete(taxId)}
          key="delete"
          aria-label="Delete"
          color="inherit"
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

CompanyRow.propTypes = {
  taxId: PropTypes.string,
  name: PropTypes.string,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default CompanyRow;
