import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import {
  clearCounselorOptions,
  loadCounselors
} from 'src/store/actions/counselorActions';
import useStyles from '../styles';

const Toolbar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCounselors());
    return () => dispatch(clearCounselorOptions());
  }, []);

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.IDENTITY')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Toolbar;
