import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_BUSINESS_METRICS_RESUME,
  loadBusinessMetricsResume
} from 'src/store/actions/businessActions';
import {
  selectBusinessMetricsResumeCount,
  selectBusinessMetricsResumeList,
  selectBusinessQueryOptions
} from 'src/store/selectors/businessSelectors';
import MetricsTable from './ResumeTable';

const ResumeList = () => {
  const dispatch = useDispatch();
  const metricsResume = useSelector(selectBusinessMetricsResumeList);
  const count = useSelector(selectBusinessMetricsResumeCount);
  const { page } = useSelector(selectBusinessQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_BUSINESS_METRICS_RESUME.action])
  );
  const success = useSelector(
    successSelector([LOAD_BUSINESS_METRICS_RESUME.action])
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadBusinessMetricsResume({ page: value }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadBusinessMetricsResume({ sort: { property, order } }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.METRICS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.METRICS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <MetricsTable
      metricsResume={metricsResume}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
      onSortChange={handleSortChange}
    />
  );
};

export default ResumeList;
