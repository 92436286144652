import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import AmortizationBalanceRow from './AmortizationBalanceRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ amortizationBalanceList }) =>
  amortizationBalanceList.map(amortizationBalance => (
    <AmortizationBalanceRow
      key={amortizationBalance.equipment}
      {...amortizationBalance}
    />
  ));

const AmortizationBalanceTable = ({
  className,
  amortizationBalanceList,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="equipment">
          {t('COMMON.EQUIPMENT')}
        </TableCell>

        <TableCell variant="head" key="initialBalance" align="center">
          {t('AMORTIZATION_BALANCE.INITIAL_BALANCE')}
        </TableCell>

        <TableCell variant="head" key="price" align="center">
          {t('AMORTIZATION_BALANCE.PRICE')}
        </TableCell>

        <TableCell variant="head" key="coverageStart" align="center">
          {t('AMORTIZATION_BALANCE.COVERAGE_START')}
        </TableCell>

        <TableCell variant="head" key="amortization" align="center">
          {t('AMORTIZATION_BALANCE.AMORTIZATION')}
        </TableCell>

        <TableCell variant="head" key="monthOfLife" align="center">
          {t('AMORTIZATION_BALANCE.MONTHS_OF_LIFE')}
        </TableCell>

        <TableCell variant="head" key="finalBalance" align="center">
          {t('AMORTIZATION_BALANCE.FINAL_BALANCE')}
        </TableCell>
      </TableRow>
    ),
    [classes]
  );

  const tbody = useMemo(
    () => () => <Tbody amortizationBalanceList={amortizationBalanceList} />,
    [amortizationBalanceList]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, amortizationBalanceList]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AmortizationBalanceTable.propTypes = {
  className: PropTypes.string,
  amortizationBalanceList: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

AmortizationBalanceTable.defaultProps = {
  count: 0
};

export default AmortizationBalanceTable;
