import momentLocalTimezone from './momentLocalTimezone';

const getMonthDiff = (startDate, endDate) => {
  const start = momentLocalTimezone(startDate).format('MM-YYYY');
  const end = momentLocalTimezone(endDate).format('MM-YYYY');

  const [startMonth, startYear] = start.split('-');
  const [endMonth, endYear] = end.split('-');

  if (startYear === endYear) {
    return Number(endMonth) - Number(startMonth);
  }

  return (
    12 * (Number(endYear) - Number(startYear)) +
    Number(endMonth) -
    Number(startMonth)
  );
};

export default getMonthDiff;
