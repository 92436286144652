import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPointsPhone } from 'src/utils/common';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AgentsRow = ({
  fullName,
  id,
  email,
  phone,
  investorsCount,
  participationAmount,
  averageInvestmentPerInvestor,
  comission
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/investors/agents/detail/${id}`}>{`${id}`}</Link>
      </TableCell>
      <TableCell align="center">{fullName}</TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">{addPointsPhone(phone)}</TableCell>
      <TableCell align="center">{investorsCount}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(participationAmount)}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(averageInvestmentPerInvestor)}
      </TableCell>
      <TableCell align="center">{comission ? `${comission}%` : '0%'}</TableCell>

      <TableCell align="center">
        <MoreMenu disabled />
      </TableCell>
    </TableRow>
  );
};

AgentsRow.propTypes = {
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  investorsCount: PropTypes.number.isRequired,
  participationAmount: PropTypes.number.isRequired,
  averageInvestmentPerInvestor: PropTypes.number.isRequired,
  comission: PropTypes.number.isRequired
};

export default AgentsRow;
