import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { addPointsPhone } from 'src/utils/common';
import {
  Link,
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { AvalListMenuActions, PersonType } from 'src/utils/constants/clients';
import ChangeStatus from '@mui/icons-material/ImportExport';
import { assignDefaultLegalRepresentative } from 'src/store/actions/clientActions';

const AvalListMenuOptions = [
  {
    name: AvalListMenuActions.ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE,
    label: `ENUMS.AVAL_LIST_MENU_ACTIONS.${AvalListMenuActions.ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE}`,
    icon: <ChangeStatus />
  }
];

const AvalDetailRow = ({
  id,
  fullName,
  phone,
  email,
  pendingDocs,
  clientPersonType,
  isAdminView,
  isLegalRepresentative
}) => {
  const dispatch = useDispatch();
  const { id: clientId } = useParams();
  const { pathname } = useLocation();

  const handleAssignDefaultLegalRepresentative = () => {
    dispatch(assignDefaultLegalRepresentative(id, clientId));
  };
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`${pathname}/detail/${id}`}>{fullName}</Link>
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="center">{addPointsPhone(phone)}</TableCell>
      {isAdminView && <TableCell align="center">{pendingDocs}</TableCell>}
      {clientPersonType === PersonType.LEGAL && (
        <>
          <TableCell align="center">
            {isLegalRepresentative && <CheckCircleIcon color="success" />}
          </TableCell>
          <TableCell align="right">
            <MoreMenu
              editDisabled
              deleteDisabled
              disabled={isLegalRepresentative}
              options={AvalListMenuOptions}
              handleClickOption={handleAssignDefaultLegalRepresentative}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

AvalDetailRow.propTypes = {
  fullName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  pendingDocs: PropTypes.number.isRequired,
  isAdminView: PropTypes.bool,
  clientPersonType: PropTypes.string,
  isLegalRepresentative: PropTypes.bool
};

export default AvalDetailRow;
