import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { NufiMenuActions } from 'src/utils/constants/clients';
import { useDispatch } from 'react-redux';
import { sendFieldToNufiValidation } from 'src/store/actions/clientActions';
import { nufiValidationFields } from 'src/utils/constants/validation';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ValidationIconStatus from 'src/utils/selectValidationIcon';
import useStyles from '../styles';

const ValidationOptions = [
  {
    name: NufiMenuActions.VALIDATE_FIELD,
    label: `ENUMS.NUFI_MENU_ACTIONS.${NufiMenuActions.VALIDATE_FIELD}`,
    icon: <HowToRegIcon />
  }
];

const NufiDistributorInfo = ({ distributor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const { nufiValidation, nufiScore } = distributor;

  const validateField = field => {
    return dispatch(
      sendFieldToNufiValidation(field, { distributorId: Number(id) })
    );
  };

  const validateOption = field => {
    return (
      <MoreMenu
        editDisabled
        deleteDisabled
        options={ValidationOptions}
        handleClickOption={() => validateField(field)}
      />
    );
  };
  return (
    <>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.RFC')}>
        <Box className={classes.detailRow}>
          <Box className={classes.validationIconsContainter}>
            <Box className={classes.validationIcons}>
              <ValidationIconStatus value={nufiValidation.rfc} />
            </Box>
            {validateOption(nufiValidationFields.RFC)}
          </Box>
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.SIGER')}>
        <Box className={classes.validationIconsContainter}>
          <Box className={classes.validationIcons}>
            <ValidationIconStatus value={nufiValidation.siger} />
          </Box>
          {validateOption(nufiValidationFields.SIGER)}
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.RUG')}>
        <Box className={classes.validationIconsContainter}>
          <Box className={classes.validationIcons}>
            <ValidationIconStatus value={nufiValidation.rug} />
          </Box>
          {validateOption(nufiValidationFields.RUG)}
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.OFAC')}>
        <Box className={classes.validationIconsContainter}>
          <Box className={classes.validationIcons}>
            <ValidationIconStatus value={nufiValidation.ofac} />
          </Box>
          {validateOption(nufiValidationFields.OFAC)}
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.SAT69')}>
        <Box className={classes.validationIconsContainter}>
          <Box className={classes.validationIcons}>
            <ValidationIconStatus value={nufiValidation.sat69} />
          </Box>
          {validateOption(nufiValidationFields.SAT69)}
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.NUFI_SCORE')}>
        <Box className={classes.detailRow}>
          {nufiScore || t('COMMON.NO_DATA')}
        </Box>
      </DetailRow>
      <DetailRow name={t('DISTRIBUTORS_VIEW.NUFI_VALIDATION.LAST_UPDATE')}>
        <Box className={classes.detailRow}>
          {selectFormatDate(
                nufiValidation.lastUpdate,
                dateFormatType.DAY
              ) || t('COMMON.NO_DATA')}
        </Box>
      </DetailRow>
    </>
  );
};

NufiDistributorInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};
export default NufiDistributorInfo;
