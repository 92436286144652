import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CONTRACT_PAYMENT_SCHEDULE } from 'src/store/actions/contractActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import ContributionsRow from './ContributionsRow';

const Tbody = ({ contributions }) =>
  contributions.map(el => <ContributionsRow key={el.id} {...el} />);

const ContributionsTable = ({ className, contributions, ...rest }) => {
  const { t } = useTranslation();

  const loading = useSelector(
    isLoadingSelector([LOAD_CONTRACT_PAYMENT_SCHEDULE.action])
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" align="center">
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.CREATION_DATE')}
        </TableCell>
        <TableCell variant="head" align="center">
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.PAYMENT_DATE')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.CONCEPT')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.AMOUNT')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.IVA')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.AMOUNT_WITH_IVA')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '15rem' }}>
          {t('COMMON.STATE')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(
    () => () => <Tbody t={t} contributions={contributions} />,
    [contributions, t]
  );

  if (!contributions.length) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.CONTRIBUTION_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

ContributionsTable.propTypes = {
  className: PropTypes.string,
  contributions: PropTypes.array.isRequired
};

export default ContributionsTable;
