import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';
import PercentajeInputMask from 'src/components/inputMask/PercentageInputMask';
import theme from 'src/theme';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../styles';

const QuoteCategoryFormDialog = ({ open, quote, handleClose, onSubmit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(quote), [quote]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      onSubmit(values);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {quote
            ? t('QUOTES_VIEW.UPDATE_QUOTE_CATEGORY')
            : t('QUOTES_VIEW.ADD_QUOTE_CATEGORY')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  inputProps={{ maxLength: 50, autoComplete: 'off' }}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="twelveMonthsRate-label"
                    error={Boolean(
                      touched.twelveMonthsRate && errors.twelveMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.TWELVE_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="twelveMonthsRate-label"
                    name="twelveMonthsRate"
                    label={t('ENUMS.MONTH_RATES.TWELVE_MONTHS_RATE')}
                    placeholder="0%"
                    value={values.twelveMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.twelveMonthsRate && errors.twelveMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.twelveMonthsRate && errors.twelveMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.twelveMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="eighteenMonthsRate-label"
                    error={Boolean(
                      touched.eighteenMonthsRate && errors.eighteenMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.EIGHTEEN_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="eighteenMonthsRate-label"
                    name="eighteenMonthsRate"
                    placeholder="0%"
                    label={t('ENUMS.MONTH_RATES.EIGHTEEN_MONTHS_RATE')}
                    value={values.eighteenMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.eighteenMonthsRate && errors.eighteenMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.eighteenMonthsRate && errors.eighteenMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.eighteenMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="twentyFourMonthsRate-label"
                    error={Boolean(
                      touched.twentyFourMonthsRate &&
                        errors.twentyFourMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.TWENTY_FOUR_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="twentyFourMonthsRate-label"
                    name="twentyFourMonthsRate"
                    label={t('ENUMS.MONTH_RATES.TWENTY_FOUR_MONTHS_RATE')}
                    value={values.twentyFourMonthsRate}
                    placeholder="0%"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.twentyFourMonthsRate &&
                        errors.twentyFourMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.twentyFourMonthsRate && errors.twentyFourMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.twentyFourMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="thirtySixMonthsRate-label"
                    error={Boolean(
                      touched.thirtySixMonthsRate && errors.thirtySixMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.THIRTY_SIX_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="thirtySixMonthsRate-label"
                    name="thirtySixMonthsRate"
                    placeholder="0%"
                    label={t('ENUMS.MONTH_RATES.THIRTY_SIX_MONTHS_RATE')}
                    value={values.thirtySixMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.thirtySixMonthsRate && errors.thirtySixMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.thirtySixMonthsRate && errors.thirtySixMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.thirtySixMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="fortyEigthMonthsRate-label"
                    error={Boolean(
                      touched.fortyEigthMonthsRate &&
                        errors.twelvfortyEigthMonthsRateeMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.FORTY_EIGHT_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="fortyEigthMonthsRate-label"
                    name="fortyEigthMonthsRate"
                    placeholder="0%"
                    label={t('ENUMS.MONTH_RATES.FORTY_EIGHT_MONTHS_RATE')}
                    value={values.fortyEigthMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.fortyEigthMonthsRate &&
                        errors.fortyEigthMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.fortyEigthMonthsRate && errors.fortyEigthMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.fortyEigthMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="sixtyMonthsRate-label"
                    error={Boolean(
                      touched.sixtyMonthsRate && errors.sixtyMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.SIXTY_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="sixtyMonthsRate-label"
                    name="sixtyMonthsRate"
                    placeholder="0%"
                    label={t('ENUMS.MONTH_RATES.SIXTY_MONTHS_RATE')}
                    value={values.sixtyMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.sixtyMonthsRate && errors.sixtyMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.sixtyMonthsRate && errors.sixtyMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.sixtyMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel
                    htmlFor="seventyTwoMonthsRate-label"
                    error={Boolean(
                      touched.seventyTwoMonthsRate &&
                        errors.seventyTwoMonthsRate
                    )}
                  >
                    {t('ENUMS.MONTH_RATES.SEVENTY_TWO_MONTHS_RATE')}
                  </InputLabel>
                  <OutlinedInput
                    id="seventyTwoMonthsRate-label"
                    name="seventyTwoMonthsRate"
                    placeholder="0%"
                    label={t('ENUMS.MONTH_RATES.SEVENTY_TWO_MONTHS_RATE')}
                    value={values.seventyTwoMonthsRate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputComponent={PercentajeInputMask}
                    error={Boolean(
                      touched.seventyTwoMonthsRate &&
                        errors.seventyTwoMonthsRate
                    )}
                  />
                  {Boolean(
                    touched.seventyTwoMonthsRate && errors.seventyTwoMonthsRate
                  ) && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      {t(errors.seventyTwoMonthsRate)}
                    </FormHelperText>
                  )}
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Typography variant="h6" color="primary" align="center">
                      {t('QUOTES_VIEW.QUOTE_CATEGORY_DESCRIPTION')}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {quote ? t('COMMON.EDIT') : t('COMMON.CREATE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

QuoteCategoryFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  quote: PropTypes.object
};

export default QuoteCategoryFormDialog;
