import React, { useCallback, useState } from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';

import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import { useParams } from 'react-router';
import {
  deleteDistributorCatalog,
  updateDistributorCatalog
} from 'src/store/actions/distributorActions';
import { useDispatch } from 'react-redux';
import CatalogDialog from './CreateCatalog';
import DeleteDistributorCatalogDialog from './DeleteDistributorCatalogDialog/DeleteDistributorCatalogDialog';

const CatalogsRow = ({
  id,
  brand,
  model,
  price,
  iva,
  priceWithIva,
  isMovable,
  isDisableActions
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { id: distributorId } = useParams();

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenDeleteDialog(true);
    }
  };

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteDistributorCatalog(id, distributorId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  const handleUpdateContact = useCallback(
    values => {
      dispatch(updateDistributorCatalog(id, distributorId, values));
      handleCloseEdit();
    },
    [dispatch, id]
  );
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell align="center">{brand ? brand.name : t('COMMON.NO_DATA')}</TableCell>
      <TableCell align="center">{model}</TableCell>
      <TableCell align="center">{formatPositiveAmount(price)}</TableCell>
      <TableCell align="center">{formatPositiveAmount(iva)}</TableCell>
      <TableCell align="center">{formatPositiveAmount(priceWithIva)}</TableCell>
      <TableCell align="center">
        {isMovable ? t('COMMON.YES') : t('COMMON.NO')}
      </TableCell>
      <TableCell align="right">
        <MoreMenu
          handleClickOption={handleClickMore}
          disabled={isDisableActions}
        />
      </TableCell>
      <CatalogDialog
        open={openEditDialog}
        handleClose={handleCloseEdit}
        onSubmit={handleUpdateContact}
        catalog={{
          id,
          brand,
          model,
          price,
          iva,
          priceWithIva,
          isMovable
        }}
      />
      <DeleteDistributorCatalogDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
    </TableRow>
  );
};

CatalogsRow.propTypes = {
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  iva: PropTypes.string.isRequired,
  priceWithIva: PropTypes.string.isRequired,
  isMovable: PropTypes.string.isRequired,
  isDisableActions: PropTypes.bool.isRequired
};

export default CatalogsRow;
