import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import DistributorsRow from './DistributorsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ distributors, t }) =>
  distributors.map(distributor => (
    <DistributorsRow key={distributor.id} t={t} {...distributor} />
  ));

const DistributorsTable = ({
  className,
  distributors,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="id">
          ID
        </TableCell>
        <TableCell variant="head" key="name">
          {t('COMMON.NAME')}
        </TableCell>

        <TableCell variant="head" key="brand" align="center">
          {t('COMMON.COMERCIAL_NAME')}
        </TableCell>
        <TableCell variant="head" align="center" key="equipment-type">
          {t('COMMON.EQUIPMENT_TYPE')}
        </TableCell>

        <TableCell variant="head" key="quotes-in-review" align="center">
          {t('COMMON.REQUESTS')}
        </TableCell>
        <TableCell variant="head" key="quotes-accepted" align="center">
          {t('COMMON.QUOTES_ACCEPTED')}
        </TableCell>
        <TableCell variant="head" key="nufi-score" align="center">
          {t('COMMON.NUFI')}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(
    () => () => <Tbody t={t} distributors={distributors} />,
    [distributors, t]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, distributors]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

DistributorsTable.propTypes = {
  className: PropTypes.string,
  distributors: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

DistributorsTable.defaultProps = {
  count: 0
};

export default DistributorsTable;
