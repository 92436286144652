import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const loginAdminWithCredentials = serviceBase({
  request: data => axiosClient.post('/auth/login', data),
  transformResponse: res => res.data.data,
  retry: 0
});

export const loginUserWithCredentials = serviceBase({
  request: data => axiosClient.post('/distributor-profile/auth/login', data),
  transformResponse: res => res.data.data,
  retry: 0
});

export const validateDistributorUserEmail = serviceBase({
  request: data => axiosClient.post('/distributor-profile/verify-email', data),
  transformResponse: res => res.data.data
});

export const validateAdminUserEmail = serviceBase({
  request: data => axiosClient.post('/user/verify-email', data),
  transformResponse: res => res.data.data
});

export const changeDistributorUserPassword = serviceBase({
  request: data =>
    axiosClient.post('/distributor-profile/assign-password', data)
});

export const changeAdminUserPassword = serviceBase({
  request: data => axiosClient.post('/user/assign-password', data)
});

export const resendUserValidationEmail = serviceBase({
  request: id =>
    axiosClient.post(`/distributor-profile/verification-email/${id}`)
});

export const resetUserPassword = serviceBase({
  request: data =>
    axiosClient.post('/distributor-profile/auth/recover-password', data)
});

export const resetAdminPassword = serviceBase({
  request: data => axiosClient.post('/auth/recover-password', data)
});

export default loginAdminWithCredentials;
