import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const { ParameterType } = require('./constants/parameters');

const selectValueForBooleanParam = value => {
  switch (value) {
    case 'true':
      return 'YES';
    case 'false':
      return 'NO';
    default:
      return 'NO';
  }
};

const renderParameterValue = (name, type, value, t, openEdit) => {
  if (name === 'SEND_BURO_REPORT_FREQUENCY') {
    return t(`COMMON.${value}`);
  }

  if (value === 'E_FIRMA' || value === 'ELECTRONIC_SIGNATURE') {
    return t(`PARAMETER_VIEW.${value}`);
  }

  if (type === ParameterType.BOOLEAN) {
    return t(`COMMON.${selectValueForBooleanParam(value)}`).toUpperCase();
  }

  if (type === ParameterType.ARRAY) {
    return (
      <Tooltip title={t('COMMON.VIEW_LIST')}>
        <IconButton color="primary" aria-label="view_list" onClick={openEdit}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return value;
};

export default renderParameterValue;
