export const selectNotification = state => state.notifications;
export const selectNotificationsList = state => selectNotification(state).items;
export const selectNotificationsListCount = state =>
  selectNotification(state).count;
export const selectUnreadNotificationsCount = state =>
  selectNotification(state).unreadNotificationsCount;
export const selectUnveiwedNotificationsCount = state =>
  selectNotification(state).unviewedNotificationsCount;
export const selectNotificationQueryOptions = state => {
  const { options } = selectNotification(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
