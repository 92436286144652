import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export default Yup.object().shape({
  phones: Yup.array().of(
    Yup.string().test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
  )
});
