import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import React, { useCallback, useState } from 'react';
import DocumentListModal from 'src/components/DocumentListModal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddDocumentModal from '../AddDocumentModal/AddDocumentModal';

const modalType = {
  ADD: 'ADD',
  HISTORY: 'HISTORY'
};

const DocumentsMenu = ({
  documentType,
  hideMoreIcon,
  clientId,
  avalId,
  contractId,
  promissoryNoteId,
  investorId,
  investmentId,
  documents,
  isDisabledEdit = false,
  isDisabledAdd = false,
  loadAmendingAgreements = false
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorHistoryModal, setAnchorHistoryModal] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = useCallback((e, type) => {
    switch (type) {
      case modalType.ADD:
        setOpenAddModal(documentType);
        handleCloseMenu();
        break;
      case modalType.HISTORY:
        setAnchorHistoryModal(documentType);
        handleCloseMenu();
        break;
      default:
        break;
    }
  }, []);

  const handleCloseModal = type => {
    switch (type) {
      case modalType.ADD:
        setOpenAddModal(false);
        break;
      case modalType.HISTORY:
        setAnchorHistoryModal(null);
        break;
      default:
        break;
    }
  };

  return (
    <Box component="div">
      <IconButton
        disabled={isDisabledEdit}
        aria-label="documentsMenu"
        id="documentsMenu"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        sx={{ display: hideMoreIcon && 'none' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={documentType}
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: '27ch',
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: 1
          }
        }}
      >
        {!isDisabledAdd && (
          <MenuItem
            onClick={e => handleOpenModal(e, modalType.ADD)}
            disabled={isDisabledAdd}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {documents
                ? t('COMMON.UPDATE_DOCUMENT')
                : t('COMMON.ADD_DOCUMENT')}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={e => handleOpenModal(e, modalType.HISTORY)}
          disabled={!documents}
        >
          <ListItemIcon>
            <ListAltIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t('COMMON.HISTORY')}</Typography>
        </MenuItem>
      </Menu>

      <DocumentListModal
        title={documentType}
        open={anchorHistoryModal}
        avalId={avalId}
        clientId={clientId}
        promissoryNoteId={promissoryNoteId}
        contractId={contractId}
        investorId={investorId}
        investmentId={investmentId}
        documentType={documentType}
        handleClose={() => handleCloseModal(modalType.HISTORY)}
        loadAmendingAgreements={loadAmendingAgreements}
      />

      <AddDocumentModal
        open={openAddModal}
        documentType={documentType}
        clientId={clientId}
        contractId={contractId}
        investorId={investorId}
        investmentId={investmentId}
        promissoryNoteId={promissoryNoteId}
        handleClose={() => handleCloseModal(modalType.ADD)}
        avalId={avalId}
        documents={documents}
      />
    </Box>
  );
};

DocumentsMenu.propTypes = {
  clientId: PropTypes.string,
  avalId: PropTypes.string,
  documentType: PropTypes.string.isRequired,
  documents: PropTypes.bool,
  contractId: PropTypes.number,
  promissoryNoteId: PropTypes.number,
  isDisabledEdit: PropTypes.bool,
  isDisabledAdd: PropTypes.bool,
  loadAmendingAgreements: PropTypes.bool,
  investorId: PropTypes.string,
  investmentId: PropTypes.string,
  hideMoreIcon: PropTypes.bool
};

export default DocumentsMenu;
