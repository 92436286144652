import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  selectRentResumeCount,
  selectRentResumeList,
  selectRentResumeQueryOptions
} from 'src/store/selectors/rentResumeSelector';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_RENT_RESUME,
  loadRentResume
} from 'src/store/actions/rentResumeActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import RentResumeTable from './RentResumeTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RentResumeList = ({ balanceType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rentResumeList = useSelector(selectRentResumeList);
  const count = useSelector(selectRentResumeCount);
  const { page } = useSelector(selectRentResumeQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_RENT_RESUME.action]));
  const success = useSelector(successSelector([LOAD_RENT_RESUME.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadRentResume({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.RENT_RESUME_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.RENT_RESUME_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <RentResumeTable
      rentResumeList={rentResumeList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
      balanceType={balanceType}
    />
  );
};

RentResumeList.propTypes = {
  balanceType: PropTypes.string
};

export default RentResumeList;
