import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'src/store/actions/snackBarActions';
import { SnackBarVariants } from 'src/utils/constants/common';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import { LOAD_KAMS, loadAllKams } from 'src/store/actions/userKamActions';
import { selectKamsList } from 'src/store/selectors/kamSelectors';
import useStyles from '../../styles';
import validationSchema from '../schema';
import makeInitialState from '../initialState';
import KamAutocomplete from './KamAutocomplete';
import KamAlert from '../KamAlert';

const StepTwoDirector = ({
  data,
  handleNext,
  handleBack,
  resetRef,
  handleClose
}) => {
  const classes = useStyles();
  const initialState = useMemo(
    () =>
      makeInitialState({
        ...data,
        distributorProfile: data.distributorProfile
      }),
    [data]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingView, setLoadingView] = useState(true);
  const kamList = useSelector(selectKamsList);

  const loadingDistributorAgents = useSelector(
    isLoadingSelector([LOAD_KAMS.action])
  );

  const handleCloseForm = useCallback(() => {
    handleClose();
    dispatch(
      showMessage({
        message: t('ERRORS.THIS_CLIENT_IS_ADD_TO_OTHER_KAM'),
        variant: SnackBarVariants.ERROR
      })
    );
  }, []);

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext]
  );

  useEffect(() => {
    dispatch(loadAllKams(true));
    setLoadingView(false);
  }, []);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                {loadingDistributorAgents ? (
                  <Loading />
                ) : (
                  <>
                    <KamAlert
                      kams={kamList}
                      loading={loadingDistributorAgents}
                    />
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.distributorProfile && errors.distributorProfile
                      )}
                    >
                      <KamAutocomplete
                        options={kamList}
                        isLoading={loadingView}
                        id="distributorProfile"
                        onSubmit={handleSubmit}
                        setLoadingView={setLoadingView}
                        value={values.distributorProfile ?? ''}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        fullWidth
                        onClose={handleCloseForm}
                        onChange={(_e, value) => {
                          setFieldValue('distributorProfile', value ?? '');
                        }}
                        label={`${t('COMMON.KAM')} (${t('COMMON.OPTIONAL')})`}
                        error={
                          touched.distributorProfile &&
                          errors.distributorProfile
                        }
                        helperText={
                          touched.distributorProfile &&
                          t(errors.distributorProfile)
                        }
                      />
                    </FormControl>
                  </>
                )}
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={handleBack}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepTwoDirector.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object,
  handleClose: PropTypes.func
};

export default StepTwoDirector;
