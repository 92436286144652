import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableCell
          align="center"
          colSpan={2}
          className={classes.reviewDataSubtitle}
        >
          {t('QUOTES_VIEW.CONTRACT_REQUEST')}
        </TableCell>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INITIAL_PAYMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${values.initialPayment}% ($${values.initialPaymentAmount})`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.TERM')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${values.term}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.MORE_INFO')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.moreInfo || '-'}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.INITIAL_PAYMENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.initialPaymentDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.FIRST_RENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.firstRentDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
