import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { BuroTabsIndex } from 'src/utils/constants/buroForm';
import BuroParametersMoral from './BuroParameterListMoral';
import BuroParameters from './BuroParameterList';

const BuroTabsList = [
  {
    value: BuroTabsIndex.PERSON,
    label: 'COMMON.PERSON'
  },
  {
    value: BuroTabsIndex.COMPANY,
    label: 'COMMON.COMPANY'
  }
];

const BuroParamatersTabs = ({ parameters, loading }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { id, subtab } = useParams();
  const [value, setValue] = useState(subtab || BuroTabsIndex.PERSON);
  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/parameters/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !subtab) {
      setValue(BuroTabsIndex.PERSON);
    }
    if (subtab) {
      if (Object.values(BuroTabsIndex).includes(subtab)) {
        setValue(subtab);
      } else {
        push(`/parameters/${value}`);
      }
    }
  }, [id, subtab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          {BuroTabsList.map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={BuroTabsIndex.PERSON}>
        <BuroParameters parameters={parameters} loading={loading} />
      </TabPanel>
      <TabPanel value={value} index={BuroTabsIndex.COMPANY}>
        <BuroParametersMoral parameters={parameters} loading={loading} />
      </TabPanel>
    </Box>
  );
};

BuroParamatersTabs.propTypes = {
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default BuroParamatersTabs;
