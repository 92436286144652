import React from 'react';
import PropTypes from 'prop-types';
import QuotePendingAssignmentDetail from './QuotePendingAssignmentDetail';
import QuoteClientDetail from './QuoteClientDetail';

const ClientDetail = ({ quote }) => {
  if (!quote.client?.id) {
    return <QuotePendingAssignmentDetail quote={quote} />;
  }

  return <QuoteClientDetail quote={quote} />;
};

export default ClientDetail;

ClientDetail.propTypes = {
  quote: PropTypes.object.isRequired
};
