import { Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import Loading from 'src/components/Loading';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles from '../NuffiDetail/styles';

const LoadingSatWsInformationDataDialog = ({ openDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} className={classes.dialogContainer}>
      <DialogContent className={classes.dialogContent}>
        <Loading
          label={t(
            'CLIENTS_VIEW.BILLING_DETAIL.LOADING_SAT_WS_INFORMATION_TITLE'
          )}
        >
          <Typography className={classes.subTitle}>
            {t(
              'CLIENTS_VIEW.BILLING_DETAIL.LOADING_SAT_WS_INFORMATION_SUBTITLE'
            )}
          </Typography>
        </Loading>
      </DialogContent>
    </Dialog>
  );
};

LoadingSatWsInformationDataDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired
};

export default LoadingSatWsInformationDataDialog;
