import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  departmentsList,
  positionsList
} from 'src/utils/constants/distributors';
import { getDifferenceBetweenStates } from 'src/utils/common';
import PhoneInput from 'src/components/PhoneInput';
import DialogTransition from 'src/components/DialogTransition';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const ContactDialog = ({ open, handleClose, onSubmit, contact }) => {
  const classes = useStyles();
  const initialState = makeInitialState(contact);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (contact) {
        const difference = getDifferenceBetweenStates(initialState, values);
        if (Object.entries(difference).length === 0) {
          handleClose();
          return;
        }
        onSubmit({
          ...difference
        });
      } else {
        onSubmit({
          ...values
        });
      }
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {contact
            ? t('DISTRIBUTORS_VIEW.EDIT_CONTACT')
            : t('DISTRIBUTORS_VIEW.CREATE_CONTACT')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <DropdownMenu
                  list={departmentsList.map(item => ({
                    name: t(`ENUMS.DEPARTMENT.${item.name}`),
                    key: item.key
                  }))}
                  value={values.department}
                  setValue={value => setFieldValue('department', value)}
                  label={t('COMMON.DEPARTMENT')}
                  error={Boolean(touched.department && errors.department)}
                  errorText={touched.department && t(errors.department)}
                />

                <DropdownMenu
                  list={positionsList.map(item => ({
                    name: t(`ENUMS.POSITION.${item.name}`),
                    key: item.key
                  }))}
                  value={values.position}
                  setValue={value => setFieldValue('position', value)}
                  label={t('COMMON.POSITION')}
                  error={Boolean(touched.position && errors.position)}
                  errorText={touched.position && t(errors.position)}
                />

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="homePhone-label"
                    name="phone"
                    label={t('COMMON.PHONE')}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  label={t('COMMON.EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {contact ? t('COMMON.EDIT') : t('COMMON.CREATE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contact: PropTypes.object
};

export default ContactDialog;
