import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { InvestorStatus } from 'src/utils/investors';
import InvestmentRow from './InvestmentRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ investments, status }) => {
  return investments.map(investment => {
    return (
      <InvestmentRow key={investment.id} status={status} {...investment} />
    );
  });
};

const InvestmentTable = ({
  className,
  investments,
  loading,
  count,
  page,
  handleChangePage,
  status,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell align="center" style={{ width: '5%' }}>
        ID
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('COMMON.INVESTOR')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('INVESTMENTS_VIEW.DATE')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('INVESTMENTS_VIEW.APPLICATION_DATE')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('COMMON.AMOUNT')}
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        {t('INVESTMENTS_VIEW.SUPPORT')}
      </TableCell>
      {status === InvestorStatus.ACTIVE && (
        <TableCell align="center" style={{ width: '10%' }}>
          {t('INVESTMENTS_VIEW.SERIE_NUMBER')}
        </TableCell>
      )}
      {status === InvestorStatus.ACTIVE && (
        <TableCell align="center" style={{ width: '10%' }}>
          {t('INVESTMENTS_VIEW.INVESTMENT_TITLE')}
        </TableCell>
      )}
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => <Tbody investments={investments} status={status} />,
    [investments]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, investments]
  );

  if (!loading && (!investments || count === 0)) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.INVESTMENT_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.INVESTMENT__DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

InvestmentTable.propTypes = {
  className: PropTypes.string,
  investments: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  submitEdit: PropTypes.func,
  count: PropTypes.bool,
  page: PropTypes.bool,
  handleChangePage: PropTypes.func,
  status: PropTypes.string
};

export default InvestmentTable;
