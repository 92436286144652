import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/GetApp';
import {
  downloadRentBalanceScheduleAction,
  loadRentBalanceSchedule
} from 'src/store/actions/contractActions';
import { selectRentBalanceSchedule } from 'src/store/selectors/contractSelectors';
import useStyles from '../styles';
import RentBalanceScheduleDetail from './RentBalanceScheduleDetail';

const RentBalanceSchedule = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const rentBalanceSchedule = useSelector(selectRentBalanceSchedule);

  const handleExcelExport = useCallback(() => {
    dispatch(downloadRentBalanceScheduleAction(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadRentBalanceSchedule({ id }));
  }, [dispatch, id]);

  return (
    <Box mt={3} display="flex" flexDirection="column">
      <Box
        className={classes.actionsContainer}
        lg={12}
        flex
        justifyContent="flex-end"
      >
        <Button
          className={clsx(classes.buttons, classes.outlined)}
          onClick={handleExcelExport}
          color="primary"
          title={t('COMMON.DOWNLOAD_EXCEL')}
        >
          <FileDownloadIcon className={classes.icon} />
        </Button>
      </Box>
      <Box lg={12}>
        <RentBalanceScheduleDetail rentBalanceSchedule={rentBalanceSchedule} />
      </Box>
    </Box>
  );
};

export default RentBalanceSchedule;
