import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { loadEquipmentTypesList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DistributorGeneralDetailDialog = ({
  open,
  distributor,
  handleClose,
  submit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(distributor), [
    distributor
  ]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  const dispatch = useDispatch();
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit(difference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadEquipmentTypesList(setEquipmentTypes));
    }

    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.GENERAL_CLIENT_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.commercialName && errors.commercialName
                  )}
                  fullWidth
                  helperText={
                    touched.commercialName && t(errors.commercialName)
                  }
                  label={t('COMMON.COMERCIAL_NAME')}
                  margin="normal"
                  name="commercialName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.commercialName}
                  variant="outlined"
                />

                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.equipmentType && errors.equipmentType
                  )}
                >
                  <Autocomplete
                    options={equipmentTypes}
                    id="equipmentType"
                    getOptionLabel={option => (option ? `${option.name}` : '')}
                    value={values.equipmentType}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('equipmentType', value);
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    helperText={
                      touched.equipmentType && t(errors.equipmentType)
                    }
                    label={t('COMMON.EQUIPMENT_TYPE')}
                    error={Boolean(
                      touched.equipmentType && errors.equipmentType
                    )}
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DistributorGeneralDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  distributor: PropTypes.object
};

export default DistributorGeneralDetailDialog;
