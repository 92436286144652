import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ValidateIcon from '@mui/icons-material/ContentPasteSearch';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEND_TO_VALIDATE,
  sendToValidateIdentity
} from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import selectFormatDate from 'src/utils/selectDateFormat';
import { useParams } from 'react-router';
import dateFormatType from 'src/utils/dateFormatType';
import LoadingValidationDialog from 'src/components/LoadingValidationDialog';
import useStyles from './styles';
import ValidationDetailTableTabs from './ValidationDetailTableTabs';

const ValidationDetail = ({ validation }) => {
  const classes = useStyles();
  const { avalId } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector([SEND_TO_VALIDATE.action]));

  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();

  const handleCloseLoadingDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenLoadingDialog = () => {
    setOpenDialog(true);
  };

  const handleValidate = useCallback(() => {
    handleOpenLoadingDialog();
    return dispatch(
      sendToValidateIdentity(null, avalId, handleCloseLoadingDialog)
    );
  }, [validation]);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} lg={12}>
          <Grid container className={classes.validationContainer}>
            <Grid
              item
              lg={4}
              md={5}
              className={classes.validation}
              sx={{ flexDirection: { xs: 'column', lg: 'row', md: 'row' } }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box className={classes.validationIcon}>
                  <ValidateIcon fill="#28A745" />
                </Box>
                <Box>
                  <Typography
                    style={{ marginBottom: 8 }}
                    color="textPrimary"
                    component="p"
                    variant="body2"
                  >
                    {t('COMMON.SCORE')}
                  </Typography>
                  <Typography color="primary" component="h2" variant="h2">
                    {validation.nufiScore
                      ? validation.nufiScore.toFixed(0)
                      : '-'}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.lastUpdateContent}>
                <Typography className={classes.subTitle}>
                  {t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.LAST_UPDATE')}
                </Typography>
                <Typography>
                  {validation.nufiValidation
                    ? selectFormatDate(
                        validation.nufiValidation.lastUpdate,
                        dateFormatType.DAY
                      )
                    : '-'}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={4} className={classes.buttonContainer}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.buttons}
                onClick={handleValidate}
              >
                {validation.nufiValidation
                  ? t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.REVALIDATE')
                  : t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.VALIDATE')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <ValidationDetailTableTabs
            nufiValidation={validation.nufiValidation}
            includeSiger={validation.includeSiger}
          />
        </Grid>
      </Grid>
      {loading && (
        <LoadingValidationDialog
          openDialog={openDialog}
          onClose={handleCloseLoadingDialog}
        />
      )}
    </Box>
  );
};

ValidationDetail.propTypes = {
  validation: PropTypes.object.isRequired
};

export default ValidationDetail;
