import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  loadClientAdministratives,
  LOAD_ADMINISTRATIVES,
  updateAdministrative,
  CLEAR_ADMINSITRATIVE_OPTIONS
} from 'src/store/actions/clientActions';
import { useParams } from 'react-router';
import {
  selectAdministrativeCount,
  selectAdministrativeList,
  selectAdministrativeOptions
} from 'src/store/selectors/clientSelectors';
import AdministrativesTable from './AdministrativesTable';
import useStyles from './styles';
import AdministrativeDialog from './CreateAdministrativesDialog';
import { createAdministrative } from '../../../../store/actions/clientActions';

const AdministrativesList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const administratives = useSelector(selectAdministrativeList);
  const count = useSelector(selectAdministrativeCount);
  const { page } = useSelector(selectAdministrativeOptions);
  const { id } = useParams();
  const loading = useSelector(isLoadingSelector([LOAD_ADMINISTRATIVES.action]));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(loadClientAdministratives(id));
    return dispatch({ type: CLEAR_ADMINSITRATIVE_OPTIONS });
  }, [dispatch, id]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  });

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmitCreate = useCallback(values => {
    dispatch(
      createAdministrative(Number(id), values, () => handleCloseDialog())
    );
  }, []);

  const handleSubmitEdit = useCallback((administrativeId, values) => {
    dispatch(
      updateAdministrative(administrativeId, id, values, () =>
        handleCloseDialog()
      )
    );
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadClientAdministratives(id, { page: value }));
    },
    [dispatch]
  );

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={7} lg={12}>
          <Grid container className={classes.transactionContainer}>
            <Grid
              item
              lg={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <Typography
                color="primary"
                variant="h1"
                sx={{
                  margin: '0px 20px',
                  fontWeight: '400',
                  textTransform: 'capitalize'
                }}
              >
                {t('COMMON.CONTACTS')}
              </Typography>

              <Button
                className={classes.buttons}
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpen}
              >
                {t('DISTRIBUTORS_VIEW.CREATE_CONTACT')}
              </Button>

              <AdministrativeDialog
                open={open}
                handleClose={handleCloseDialog}
                submit={handleSubmitCreate}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <AdministrativesTable
            className={classes.table}
            loading={loading}
            administratives={administratives}
            submitEdit={handleSubmitEdit}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdministrativesList;
