import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { PersonType } from 'src/utils/constants/clients';
import { useDispatch } from 'react-redux';
import { loadDenominationsList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import theme from 'src/theme';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepFour = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [denominations, setDenominations] = useState([]);

  const initialState = useMemo(() => makeInitialState(data), [data]);

  const { t } = useTranslation();

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext, data]
  );

  useEffect(() => {
    if (data.personType === PersonType.LEGAL) {
      dispatch(loadDenominationsList(setDenominations));
    }
  }, []);

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <Box sx={{ height: '20%' }}>
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_FOUR.TITLE')}
        </Typography>
      </Box>

      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              component="form"
              sx={{
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box className={classes.container}>
                {data.personType === PersonType.PHYSICAL && (
                  <Typography
                    variant="body1"
                    className={classes.subtitle}
                    sx={{
                      fontzSize: '10px',
                      color: 'text.secondary'
                    }}
                  >
                    {t('BUREAU_FORM.STEP_FOUR.CURP_DATA_DESCRIPTION')}
                  </Typography>
                )}
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={data.name}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                {values.personType === PersonType.PHYSICAL && (
                  <>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.paternalName && errors.paternalName
                      )}
                      fullWidth
                      helperText={
                        touched.paternalName && t(errors.paternalName)
                      }
                      label={t('COMMON.PATERNAL_NAME')}
                      margin="normal"
                      name="paternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={data.paternalName}
                      type="text"
                      value={values.paternalName}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.maternalName && errors.maternalName
                      )}
                      fullWidth
                      helperText={
                        touched.maternalName && t(errors.maternalName)
                      }
                      label={t('COMMON.MATERNAL_NAME')}
                      margin="normal"
                      name="maternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={data.maternalName}
                      type="text"
                      value={values.maternalName}
                      variant="outlined"
                    />
                  </>
                )}
                {values.personType === PersonType.LEGAL && (
                  <>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.denomination && errors.denomination
                      )}
                    >
                      <Autocomplete
                        options={denominations}
                        id="denomination"
                        getOptionLabel={option => `${option.name}`}
                        value={denominations.find(
                          equipment => equipment.name === values.name
                        )}
                        error={Boolean(
                          touched.denomination && t(errors.denomination)
                        )}
                        fullWidth
                        onChange={(_e, value) => {
                          setFieldValue('denomination', value?.name ?? '');
                        }}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        label={t('COMMON.DENOMINATION')}
                        helperText={
                          touched.denomination && t(errors.denomination)
                        }
                      />
                    </FormControl>
                  </>
                )}
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
StepFour.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepFour;
