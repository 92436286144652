import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  '@keyframes orbit-spinner-orbit-one-animation': {
    '0%': {
      transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)'
    },
    '100%': {
      transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)'
    }
  },
  '@keyframes orbit-spinner-orbit-two-animation': {
    '0%': {
      transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)'
    },
    '100%': {
      transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)'
    }
  },
  '@keyframes orbit-spinner-orbit-three-animation': {
    '0%': {
      transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)'
    },
    '100%': {
      transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)'
    }
  },
  orbitSpinner: {
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    perspective: '800px',
    '& *': {
      boxSizing: 'border-box'
    }
  },
  orbit: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    '&:nth-child(1)': {
      left: '0%',
      top: '0%',
      animation: '$orbit-spinner-orbit-one-animation 1200ms linear infinite',
      borderBottom: '3px solid #ff1d5e'
    },
    '&:nth-child(2)': {
      right: '0%',
      top: '0%',
      animation: '$orbit-spinner-orbit-two-animation 1200ms linear infinite',
      borderRight: '3px solid #ff1d5e'
    },
    '&:nth-child(3)': {
      right: '0%',
      bottom: '0%',
      animation: '$orbit-spinner-orbit-three-animation 1200ms linear infinite',
      borderTop: '3px solid #ff1d5e'
    }
  }
}));

function Preloader({ size = 100, ...rest }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...rest}>
      <Box
        className={classes.orbitSpinner}
        style={{ height: size, width: size }}
      >
        <CircularProgress color="secondary" disableShrink />
      </Box>
    </Box>
  );
}

Preloader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  animationDuration: PropTypes.number
};

export default Preloader;
