import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { ASSIGN_KAM } from 'src/store/actions/quoteActions';
import Preloader from 'src/components/Preloader';
import { loadAllClientAdministratives } from 'src/store/actions/clientActions';
import { sendInvoiceToClientAction } from 'src/store/actions/cfdiActions';
import Dialog from 'src/components/Dialog/Dialog';
import { selectAdministrativeList } from 'src/store/selectors/clientSelectors';
import Button from 'src/components/Button';
import MultipleSelectChip from 'src/components/MultipleSelect/MutipleSelect';
import theme from 'src/theme';
import makeInitialState from './initialState';
import validationSchema from './schema';

const SendEmailDialog = ({ open, onClose, id, clientId }) => {
  const dispatch = useDispatch();
  const administrativesList = useSelector(selectAdministrativeList);
  const isLoadingAdministratives = useSelector(
    isLoadingSelector([ASSIGN_KAM.action])
  );
  const initialState = makeInitialState();

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(loadAllClientAdministratives(clientId));
    }
  }, [open]);

  const handleSendEmail = values => {
    dispatch(
      sendInvoiceToClientAction({
        id,
        emailList: values.emailList
      })
    );
    onClose();
  };

  if (!open) return null;

  const getRole = option => {
    if (option.isClientInstance) {
      return 'Cliente';
    }
    if (option.role) {
      return option.role.name;
    }
    if (option.isAval) {
      return 'Aval';
    }
    return '';
  };

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      TransitionComponent={DialogTransition}
      title={t('ENUMS.CFDI_LIST_ACTIONS.SEND_EMAIL')}
      sx={{
        marginBottom: '1.5rem',
        '& .MuiDialog-paper': {
          width: theme.spacing(70)
        }
      }}
    >
      <DialogContent
        sx={{
          m: 2
        }}
      >
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSendEmail}
        >
          {({ errors, handleSubmit, touched, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <MultipleSelectChip
                  options={administrativesList.map(admin => {
                    return {
                      value: admin.email,
                      label: `${admin.id} - ${admin.fullName} - ${getRole(
                        admin
                      )}`
                    };
                  })}
                  label={t('COMMON.ADMINISTRATIVES')}
                  fullWidth
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  errors={Boolean(touched.emailList && errors.emailList)}
                  helperText={touched.emailList && t(errors.emailList)}
                  setFieldValue={setFieldValue}
                  fieldName="emailList"
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoadingAdministratives}
                  >
                    {t('COMMON.SEND')}
                    {isLoadingAdministratives && <Preloader />}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

SendEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired
};

export default SendEmailDialog;
