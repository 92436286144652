/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const DinamicInputs = ({
  values,
  onChange,
  loading,
  error,
  helperText,
  name,
  ...props
}) => {
  const maxInputAbility = 10;

  const [inputCount, setInputCount] = useState(1);

  const [inputValues, setInputValues] = useState([]);

  const incrementInputCount = () => {
    setInputCount(inputCount + 1);
    setInputValues([...inputValues, null]);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (values) {
      const currentAvalCount = values.length;
      setInputCount(currentAvalCount);
      setInputValues(values);
    }
  }, [values]);

  return (
    <Box
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      {Array(inputCount)
        .fill('')
        .map((input, index) => (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
              fullWidth
              key={`box-${index}`}
            >
              <TextField
                {...props}
                key={`input-${index}`}
                options={inputValues}
                id="dinamicInput"
                name={name}
                value={inputValues[index] ?? null}
                loading={loading}
                disabled={loading}
                fullWidth
                onChange={e => {
                  const updateValues = [...inputValues];
                  updateValues[index] = e.target.value ?? null;
                  onChange(updateValues);
                }}
                FormHelperTextProps={{
                  sx: {
                    margin: 0
                  }
                }}
                error={error[index]}
                helperText={helperText[index]}
                sx={{ marginBottom: 2, marginTop: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      key={`button-${index}`}
                      aria-label="delete"
                      onClick={() => {
                        const updatedAvals = inputValues.filter(
                          (_, i) => i !== index
                        );
                        onChange(updatedAvals);
                        setInputCount(inputCount - 1);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )
                }}
              />
            </Box>
          </>
        ))}
      <Box
        key="addButton"
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Button
          type="button"
          key="addButton"
          color="primary"
          variant="text"
          disabled={inputCount >= maxInputAbility}
          onClick={incrementInputCount}
          startIcon={<AddIcon />}
        >
          {t('COMMON.ADD')}
        </Button>
      </Box>
    </Box>
  );
};
DinamicInputs.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string
};

export default DinamicInputs;
