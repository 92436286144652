import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import renderParameterValue from 'src/utils/parametersHelper';

const ParameterRow = ({
  name,
  value,
  type,
  openEdit,
  isDisabledEdit = false
}) => {
  const { t } = useTranslation();

  const handleOpenEdit = () => {
    openEdit();
  };

  return (
    <TableRow hover>
      <TableCell>{t(`PARAMETER_VIEW.${name}`)}</TableCell>
      <TableCell align="center">
        {renderParameterValue(name, type, value, t, openEdit)}
      </TableCell>
      <TableCell>
        <MoreMenu
          deleteDisabled
          handleClickOption={handleOpenEdit}
          disabled={isDisabledEdit}
        />
      </TableCell>
    </TableRow>
  );
};

ParameterRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isDisabledEdit: PropTypes.bool,
  openEdit: PropTypes.func,
  type: PropTypes.string
};

export default ParameterRow;
