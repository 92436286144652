import {
  assignClientToQuote,
  getClientExistingByRFC,
  getQuoteInfo,
  resendNip,
  sendToCreateBureauForm,
  sendToSubmitForm,
  sendToValidateNip,
  validateClientContactInfo,
  validateClientCurpService
} from 'src/services/bureauFormService';
import { URL_DEFAULT_REDIRECT } from 'src/utils/constants/auth';
import { SnackBarVariants } from 'src/utils/constants/common';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import moment from 'moment-timezone';
import { push } from 'connected-react-router';
import { removeLocalStorage, setLocalStorage } from 'src/utils/localStorage';
import { transformFormData } from 'src/utils/constants/BureauForm';
import decode from 'jwt-decode';
import { showMessage } from './snackBarActions';

export const VALIDATE_CLIENT_BY_RFC = asyncActionCreator(
  'VALIDATE_CLIENT_BY_RFC'
);
export const ASSOCIATE_CLIENT_TO_QUOTE = asyncActionCreator(
  'ASSOCIATE_CLIENT_TO_QUOTE'
);
export const SEND_CLIENT_TO_VALIDATION = asyncActionCreator(
  'SEND_CLIENT_TO_VALIDATION'
);
export const CREATE_BURO_FORM = asyncActionCreator('CREATE_BURO_FORM');
export const CREATE_LEGAL_PERSON_BURO_FORM = asyncActionCreator(
  'CREATE_LEGAL_PERSON_BURO_FORM'
);
export const RESEND_NIP = asyncActionCreator('RESEND_NIP');
export const GET_QUOTE_INFORMATION = asyncActionCreator(
  'GET_QUOTE_INFORMATION'
);
export const VALIDATE_CLIENT_CONTACT_INFO = asyncActionCreator(
  'VALIDATE_CLIENT_CONTACT_INFO'
);
export const VALIDATE_CLIENT_CURP = asyncActionCreator('VALIDATE_CLIENT_CURP');

export const GET_TOKEN_DATA = 'GET_TOKEN_DATA';

export const CLEAR_CLIENT_VALIDATION = 'CLEAR_CLIENT_VALIDATION';
export const CLEAR_BUREAU_FORM_ID = 'CLEAR_BUREAU_FORM_ID';
export const CLEAR_TEMPORAL_TOKEN = 'CLEAR_TEMPORAL_TOKEN';

export const validateClientExistByRfc = rfc => async dispatch => {
  try {
    dispatch({
      type: VALIDATE_CLIENT_BY_RFC.start
    });

    const response = await getClientExistingByRFC(rfc);
    dispatch({
      type: VALIDATE_CLIENT_BY_RFC.success,
      payload: response
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    dispatch({ type: VALIDATE_CLIENT_BY_RFC.failure });
  }
};

export const getQuoteInformation = () => async dispatch => {
  try {
    dispatch({
      type: GET_QUOTE_INFORMATION.start
    });
    const response = await getQuoteInfo();
    dispatch({
      type: GET_QUOTE_INFORMATION.success,
      payload: response
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    dispatch({ type: GET_QUOTE_INFORMATION.failure });
  }
};

export const associateClientToQuote = (
  clientId,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({
      type: ASSOCIATE_CLIENT_TO_QUOTE.start
    });
    await assignClientToQuote(clientId);
    dispatch({
      type: ASSOCIATE_CLIENT_TO_QUOTE.success
    });
    onCloseDialog();
    return window.location.replace(URL_DEFAULT_REDIRECT);
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: ASSOCIATE_CLIENT_TO_QUOTE.failure });
  }
};

export const decodeTokenForBureauForm = token => async dispatch => {
  const redirect = () => {
    setTimeout(() => {
      const redirectUrl =
        process.env.REACT_APP_URL_DEFAULT_REDIRECT || 'https://astrocap.com/';
      return window.location.replace(redirectUrl);
    }, 5000);
  };
  try {
    const decodeToken = decode(token);

    if (moment().isAfter(moment.unix(decodeToken.exp))) {
      dispatch(
        showMessage({
          message: 'EXPIRED_TOKEN',
          variant: SnackBarVariants.ERROR
        })
      );
      redirect();
    } else {
      setLocalStorage('temporalToken', token);
      dispatch({
        type: GET_TOKEN_DATA,
        payload: decodeToken
      });
    }
  } catch (error) {
    dispatch(
      showMessage({
        message: 'INVALID_TOKEN',
        variant: SnackBarVariants.ERROR
      })
    );
    redirect();
  }
};

export const clearToken = () => async dispatch => {
  removeLocalStorage('temporalToken');
  dispatch({
    type: CLEAR_TEMPORAL_TOKEN
  });
  dispatch(push('/'));
};

export const createBureauForm = (values, onNext) => async dispatch => {
  try {
    dispatch({
      type: CREATE_BURO_FORM.start
    });
    const dataTransform = transformFormData(values);
    const formId = await sendToCreateBureauForm(dataTransform);
    dispatch({
      type: CREATE_BURO_FORM.success,
      payload: formId
    });
    onNext(values);
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    dispatch({ type: CREATE_BURO_FORM.failure });
  }
};

export const clearBureauFormId = () => async dispatch => {
  dispatch({
    type: CLEAR_BUREAU_FORM_ID
  });
};

export const resendNipCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESEND_NIP.start
    });
    const { formId } = getState().bureauForm;
    if (!formId) {
      dispatch(
        showMessage({
          message: 'NO_FORM_ID',
          variant: SnackBarVariants.ERROR
        })
      );
    } else {
      await resendNip(formId);
    }
    dispatch({
      type: RESEND_NIP.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    dispatch({ type: RESEND_NIP.failure });
  }
};

export const submitBureauForm = (values, onNext) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SEND_CLIENT_TO_VALIDATION.start
    });

    const { bureauForm } = getState();
    const { nip } = values;
    const dataTransform = transformFormData(values);
    const validated = await sendToValidateNip({
      nip,
      formId: bureauForm.formId
    });

    if (!validated) {
      dispatch(
        showMessage({
          message: 'INVALID_NIP',
          variant: SnackBarVariants.ERROR
        })
      );
      return dispatch({ type: SEND_CLIENT_TO_VALIDATION.failure });
    }

    const result = await sendToSubmitForm({
      formId: bureauForm.formId,
      values: dataTransform
    });
    dispatch({
      type: SEND_CLIENT_TO_VALIDATION.success,
      payload: result
    });
    dispatch(
      showMessage({
        message: 'BUREAU_FORM_SEND_SUCCESS',
        variant: SnackBarVariants.SUCCESS
      })
    );
    return onNext();
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: SEND_CLIENT_TO_VALIDATION.failure });
  }
};

export const validateClientContactInformation = (
  data,
  onNext
) => async dispatch => {
  try {
    dispatch({
      type: VALIDATE_CLIENT_CONTACT_INFO.start
    });
    const contactValidation = await validateClientContactInfo(data);
    dispatch({
      type: VALIDATE_CLIENT_CONTACT_INFO.success
    });
    onNext({ ...data, ...contactValidation });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    dispatch({ type: VALIDATE_CLIENT_CONTACT_INFO.failure });
  }
};

export const validateClientCurp = (values, onNext) => async dispatch => {
  try {
    dispatch({
      type: VALIDATE_CLIENT_CURP.start
    });

    const response = await validateClientCurpService({ curp: values.curp });
    dispatch({
      type: VALIDATE_CLIENT_CURP.success
    });
    return onNext({ ...values, ...response });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: VALIDATE_CLIENT_CURP.failure });
  }
};
