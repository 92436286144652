import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import AddButton from 'src/components/AddButton/AddButton';
import { selectCounselorsQueryOptions } from 'src/store/selectors/counselorSelectors';
import {
  assignSignatories,
  clearCounselorOptions,
  createCounselor,
  loadCounselors
} from 'src/store/actions/counselorActions';
import useStyles from './styles';
import CreateCounselorsDialog from './CreateCounselorsDialog';
import AssignedSignatoriesDialog from './AssignedSignatoriesDialog';

const Toolbar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCounselorsQueryOptions);
  const [open, setOpen] = useState(false);
  const [openSignatories, setOpenSignatories] = useState(false);

  const handleOpenCreateCounselorForm = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseCreateCounselorForm = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpenSignatoriesForm = useCallback(() => {
    setOpenSignatories(true);
  }, [setOpenSignatories]);

  const handleCloseSignatoriesForm = useCallback(() => {
    setOpenSignatories(false);
  }, [setOpenSignatories]);

  const handleCreateCounselor = useCallback(
    values => {
      dispatch(createCounselor(values, handleCloseCreateCounselorForm));
    },
    [dispatch, queryOptions, handleCloseCreateCounselorForm]
  );

  const handleAssignSignatories = useCallback(values => {
    dispatch(assignSignatories(values, handleCloseSignatoriesForm));
  }, []);

  useEffect(() => {
    dispatch(loadCounselors());
    return () => dispatch(clearCounselorOptions());
  }, []);

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('COUNSELORS.TITLE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding
              )}
              color="primary"
              onClick={handleOpenSignatoriesForm}
            >
              {t('COUNSELORS.ASSIGN_SIGNATORIES')}
            </Button>

            <AddButton
              key="add-counselor"
              onClick={handleOpenCreateCounselorForm}
            />
          </Box>
          <CreateCounselorsDialog
            open={open}
            handleClose={handleCloseCreateCounselorForm}
            submit={handleCreateCounselor}
          />
          <AssignedSignatoriesDialog
            open={openSignatories}
            handleClose={handleCloseSignatoriesForm}
            submit={handleAssignSignatories}
          />
        </Box>
      </Box>
    </>
  );
};

export default Toolbar;
