import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotFoundIcon from 'src/icons/NotFoundIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    minHeight: '80vh'
  },
  image: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(40),
    height: theme.spacing(40)
  }
}));

function NotFoundRecords({ title, description, children, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <NotFoundIcon className={classes.image} />

      <Typography variant="h2" gutterBottom>
        {t(title)}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {t(description)}
      </Typography>
      {children}
    </Box>
  );
}

NotFoundRecords.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node
};

export default NotFoundRecords;
