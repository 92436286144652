import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_CATALOG,
  loadAdministrativesRoles
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import AdministrativeRolesTable from './AdministrativeRolesTable';

const AdministrativeRolesList = () => {
  const dispatch = useDispatch();
  const roles = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));
  const success = useSelector(successSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadAdministrativesRoles());
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <AdministrativeRolesTable
            roles={roles}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdministrativeRolesList;
