import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import RotateIcon from '@mui/icons-material/Rotate90DegreesCcw';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Img } from 'react-image';
import { FileTypes, getFileType } from 'src/utils/fileTypes';
import { Document, Page, pdfjs } from 'react-pdf';
import getFileFromExternalLink from 'src/utils/document';
import useStyles from './styles';
import DialogTransition from '../DialogTransition';

const DocumentViewerDialog = ({ open, onClose, documentUrl, fileName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [rotation, setRotation] = useState(0);
  const [zoomed, setZoomed] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const zoomImage = () => {
    setZoomed(!zoomed);
  };

  const handleRotate = () => {
    setRotation(rotation + 90);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = pdf => {
    setNumPages(pdf.numPages);
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      classes={{ paper: classes.root }}
      keepMounted
    >
      <DialogTitle>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {getFileType(documentUrl) !== FileTypes.PDF ? (
        <>
          <DialogContent className={classes.dialogContent}>
            <Img
              src={documentUrl}
              alt="Imagen"
              id="myImage"
              style={{
                transform: `rotate(${rotation}deg) scale(${zoomed ? 2 : 1})`,
                transition: 'transform 0.3s ease',
                borderRadius: '.5rem',
                display: 'block',
                maxHeight: '70%',
                maxWidth: '40%',
                objectFit: 'cover',
                margin: 'auto'
              }}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttons}
              onClick={handleRotate}
              variant="contained"
              color="primary"
              startIcon={<RotateIcon />}
            >
              {t('COMMON.ROTATE')}
            </Button>
            <Button
              className={classes.buttons}
              onClick={zoomImage}
              variant="contained"
              color="primary"
              startIcon={zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
            >
              {zoomed ? t('COMMON.ZOOM_OUT') : t('COMMON.ZOOM_IN')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent className={classes.dialogContent}>
            <Document
              file={documentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              key="document"
              className={classes.document}
              loading={<CircularProgress color="secondary" />}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.5}
                  renderMode="canvas"
                  className={classes.document}
                />
              ))}
            </Document>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Grid container justifyContent="flex-end">
              <Button
                className={classes.buttons}
                onClick={() => {
                  getFileFromExternalLink(documentUrl, fileName);
                }}
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
              >
                {t('COMMON.DOWNLOAD')}
              </Button>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

DocumentViewerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documentUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string
};

export default DocumentViewerDialog;
