import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import InteresetBalanceListView from './InterestBalanceList';

const InterestBalanceView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={InteresetBalanceListView} />
    </>
  );
};

InterestBalanceView.propTypes = {
  match: PropTypes.object.isRequired
};

export default InterestBalanceView;
