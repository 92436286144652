import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import AlertMessage from 'src/components/Alert/Alert';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import GeneralEquipmentInfo from './GeneralDetail';
import DocumentsClientDetail from './DocumentsDetail';
import InsuranceDetail from './InsuranceDetail/index';
import SaleInformation from './EquipmentSaleInformation';

const GeneralView = ({ equipment }) => {
  return (
    <Box mt={3}>
      <AlertMessage
        title='Información de venta incompleta'
        text="La información de venta del equipo está incompleta.  Esto puede causar problemas en la facturación del último mes del contrato."
        severity={AlertSeverity.WARNING}
        open={!equipment.saleProductKey}
        hideCloseIcon
      />
      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} md={6} lg={3}>
          <GeneralEquipmentInfo equipment={equipment} />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <InsuranceDetail equipment={equipment} />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DocumentsClientDetail equipment={equipment} />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SaleInformation equipment={equipment} />
        </Grid>
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  equipment: PropTypes.object
};

export default GeneralView;
