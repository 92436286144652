export default (values, legalRepresentativeAval) => ({
  ...values,
  avals: legalRepresentativeAval
    ? [
        {
          ...values.avals[0],
          ...legalRepresentativeAval
        }
      ]
    : values.avals || []
});
