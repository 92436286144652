import { SnackBarVariants } from 'src/utils/constants/common';
import {
  cancelCfdi,
  downloadExportedCFDIExpense,
  downloadExportedCFDIIncome,
  downloadExportedCFDIIssued,
  exportWithholdings,
  getAllCFDIs,
  getWithholdings,
  downloadExportedCFDIPayment,
  loadCFDIsExpense,
  loadCFDIsIncome,
  loadCFDIsPayment,
  sendInvoiceEmailToClient,
  getCFDIReceived,
  downloadCFDIsReceivedInZip,
  downloadExportedCFDIReceived,
  downloadInvoices,
  getPendingPaymentIncomeCDFIs,
  sendInvoiceToClientWhatsapp
} from 'src/services/cfdiService';
import { showMessage } from './snackBarActions';
import {
  getCFDIOptions,
  getCFDIReceivedOptions,
  getCFDIsExpenseOptions,
  getCFDIsIncomeOptions,
  getCFDIsPaymentOptions
} from '../options/cfdiOptions';
import {
  CANCEL_CFDI,
  CLEAR_CFDIS_OPTIONS,
  DOWNLOAD_CFDIS_LIST,
  DOWNLOAD_CFDI_EXPENSE,
  DOWNLOAD_CFDI_INCOME,
  DOWNLOAD_CFDI_PAYMENT,
  DOWNLOAD_CFDI_RECEIVED,
  DOWNLOAD_CFDI_RECEIVED_IN_ZIP,
  DOWNLOAD_INVOICES_LIST_IN_ZIP,
  DOWNLOAD_WITHHOLDINGS,
  LOAD_CFDIS,
  LOAD_CFDIS_EXPENSE,
  LOAD_CFDIS_INCOME,
  LOAD_CFDIS_PAYMENT,
  LOAD_CFDIS_RECEIVED,
  LOAD_PENDING_PAYMENT_INCOME_CFDI,
  LOAD_WITHHOLDINGS,
  SAVE_CFDIS_OPTIONS,
  SEND_INVOICE_TO_WHATSAPP
} from '../constants/CFDI';

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_CFDIS_OPTIONS });
};

export const setCFDIsOptions = options => dispatch => {
  dispatch({
    type: SAVE_CFDIS_OPTIONS,
    options
  });
};

export const loadCFDIS = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CFDIS.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });
    const reqOptions = getCFDIOptions(getState);

    const transaction = await getAllCFDIs(reqOptions);

    return dispatch({
      type: LOAD_CFDIS.success,
      ...transaction
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CFDIS.failure });
  }
};

export const downloadCFDIList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_CFDIS_LIST.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIOptions(getState, false);

    await downloadExportedCFDIIssued({
      ...reqOptions,
      customErrorMessage: 'CFDIS_NOT_FOUND'
    });

    return dispatch({
      type: DOWNLOAD_CFDIS_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDIS_LIST.failure });
  }
};

export const loadCFDIIncome = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CFDIS_INCOME.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsIncomeOptions(getState);

    const transaction = await loadCFDIsIncome(reqOptions);

    return dispatch({
      type: LOAD_CFDIS_INCOME.success,
      ...transaction
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CFDIS_INCOME.failure });
  }
};

export const downloadCFDIIncome = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_CFDI_INCOME.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsIncomeOptions(getState, false);

    await downloadExportedCFDIIncome({
      ...reqOptions,
      customErrorMessage: 'CFDIS_NOT_FOUND'
    });

    return dispatch({
      type: DOWNLOAD_CFDI_INCOME.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDI_INCOME.failure });
  }
};

export const cancelCfdiAction = values => async dispatch => {
  try {
    dispatch({ type: CANCEL_CFDI.start });

    await cancelCfdi(values);

    dispatch(
      showMessage({
        message: 'CANCEL_CDFI',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: CANCEL_CFDI.success
    });

    return dispatch(loadCFDIS());
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CANCEL_CFDI.failure });
  }
};

export const loadCFDIExpense = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CFDIS_EXPENSE.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsExpenseOptions(getState);

    const transaction = await loadCFDIsExpense(reqOptions);

    return dispatch({
      type: LOAD_CFDIS_EXPENSE.success,
      ...transaction
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CFDIS_EXPENSE.failure });
  }
};

export const downloadCFDIExpense = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_CFDI_EXPENSE.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsExpenseOptions(getState, false);

    await downloadExportedCFDIExpense({
      ...reqOptions,
      customErrorMessage: 'CFDIS_NOT_FOUND'
    });

    return dispatch({
      type: DOWNLOAD_CFDI_EXPENSE.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDI_EXPENSE.failure });
  }
};

export const loadCFDIPayment = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CFDIS_PAYMENT.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsPaymentOptions(getState);

    const transaction = await loadCFDIsPayment(reqOptions);

    return dispatch({
      type: LOAD_CFDIS_PAYMENT.success,
      ...transaction
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CFDIS_PAYMENT.failure });
  }
};

export const downloadCFDIPayment = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_CFDI_PAYMENT.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsPaymentOptions(getState, false);

    await downloadExportedCFDIPayment({
      ...reqOptions,
      customErrorMessage: 'CFDIS_NOT_FOUND'
    });

    return dispatch({
      type: DOWNLOAD_CFDI_PAYMENT.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDI_PAYMENT.failure });
  }
};

export const sendInvoiceToClientAction = values => async dispatch => {
  try {
    await sendInvoiceEmailToClient(values);

    dispatch(
      showMessage({
        message: 'SEND_EMAIL_SUCCESS',
        variant: SnackBarVariants.SUCCESS
      })
    );
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const sendInvoiceToClientWhatsappAction = (
  values,
  onClose
) => async dispatch => {
  try {
    dispatch({ type: SEND_INVOICE_TO_WHATSAPP.start });

    await sendInvoiceToClientWhatsapp(values);

    onClose();

    dispatch(
      showMessage({
        message: 'SEND_WHATSAPP_SUCCESS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: SEND_INVOICE_TO_WHATSAPP.success });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const loadWithdolgins = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_WITHHOLDINGS.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsIncomeOptions(getState);

    const data = await getWithholdings(reqOptions);

    return dispatch({
      type: LOAD_WITHHOLDINGS.success,
      ...data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_WITHHOLDINGS.failure });
  }
};

export const downloadWithholdings = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_WITHHOLDINGS.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsIncomeOptions(getState, false);

    await exportWithholdings({
      ...reqOptions
    });

    return dispatch({
      type: DOWNLOAD_WITHHOLDINGS.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_WITHHOLDINGS.failure });
  }
};

export const loadCFDIsReceived = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CFDIS_RECEIVED.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIOptions(getState);

    const cfdis = await getCFDIReceived(reqOptions);

    return dispatch({
      type: LOAD_CFDIS_RECEIVED.success,
      ...cfdis
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CFDIS_RECEIVED.failure });
  }
};

export const downloadCFDIReceived = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_CFDI_RECEIVED.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIsIncomeOptions(getState, false);

    await downloadExportedCFDIReceived({
      ...reqOptions
    });

    return dispatch({
      type: DOWNLOAD_CFDI_RECEIVED.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDI_RECEIVED.failure });
  }
};

export const downloadCFDIsReceivedListInZip = (options, onClose) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DOWNLOAD_CFDI_RECEIVED_IN_ZIP.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIReceivedOptions(getState, false);

    await downloadCFDIsReceivedInZip({
      ...reqOptions,
      customErrorMessage: 'INVOICES_NOT_FOUND'
    });

    dispatch(
      showMessage({
        message: 'DOWNLOAD_CFDI_RECEIVED_IN_ZIP',
        variant: SnackBarVariants.SUCCESS
      })
    );

    onClose();

    return dispatch({
      type: DOWNLOAD_CFDI_RECEIVED_IN_ZIP.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_CFDI_RECEIVED_IN_ZIP.failure });
  }
};

export const downloadInvoicesListInZip = (options, onClose) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DOWNLOAD_INVOICES_LIST_IN_ZIP.start });

    dispatch({
      type: SAVE_CFDIS_OPTIONS,
      options
    });

    const reqOptions = getCFDIOptions(getState, false);

    await downloadInvoices({
      ...reqOptions,
      customErrorMessage: 'INVOICES_NOT_FOUND'
    });

    dispatch(
      showMessage({
        message: 'DOWNLOAD_INVOICES_LIST',
        variant: SnackBarVariants.SUCCESS
      })
    );

    onClose();

    return dispatch({
      type: DOWNLOAD_INVOICES_LIST_IN_ZIP.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_INVOICES_LIST_IN_ZIP.failure });
  }
};

export const loadPendingPaymentIncomeCFDIs = (
  contractId,
  setList
) => async dispatch => {
  try {
    dispatch({ type: LOAD_PENDING_PAYMENT_INCOME_CFDI.start });

    const cfdis = await getPendingPaymentIncomeCDFIs(contractId);

    setList(cfdis);

    return dispatch({
      type: LOAD_PENDING_PAYMENT_INCOME_CFDI.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_PENDING_PAYMENT_INCOME_CFDI.failure });
  }
};
