import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  selectClientsList,
  selectClientsListCount,
  selectClientsQueryOptions
} from 'src/store/selectors/clientSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  CLEAR_CLIENT_OPTIONS,
  LOAD_CLIENTS
} from 'src/store/actions/clientActions';
import ClientsTable from 'src/views/client/Clients/ClientsTable';
import ClientStatus from 'src/utils/constants/clients';
import loadUserClients from 'src/store/actions/userClientActions';

const UserClientsList = () => {
  const dispatch = useDispatch();
  const clients = useSelector(selectClientsList);
  const count = useSelector(selectClientsListCount);
  const { page } = useSelector(selectClientsQueryOptions);
  const queryOptions = useSelector(selectClientsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CLIENTS.action]));
  const success = useSelector(successSelector([LOAD_CLIENTS.action]));
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      loadUserClients({
        ...queryOptions
      })
    );
    return () => dispatch({ type: CLEAR_CLIENT_OPTIONS });
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadUserClients({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.CLIENTS_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.CLIENTS_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <ClientsTable
      isAdminView={false}
      clients={clients}
      count={count}
      page={page}
      clientStatus={ClientStatus.ACTIVE}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default UserClientsList;
