import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  closeIcon: {
    float: 'right',
    marginTop: '-20px',
    width: '20px',
    height: '20px'
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`
    }
  },
  root: {
    minWidth: theme.spacing(58)
  }
}));
