import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPointsPhone } from 'src/utils/common';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import { useDispatch } from 'react-redux';
import { deleteCounselorAction } from 'src/store/actions/counselorActions';

const CounselorsRow = ({ fullName, id, email, phone, isSignatory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.DELETE) {
      setOpenDeleteDialog(true);
    }
  };

  const handleUpdateContact = useCallback(() => {
    dispatch(deleteCounselorAction(id, handleCloseDelete));
  }, [dispatch, id]);

  return (
    <TableRow hover>
      <TableCell align="center">{id}</TableCell>
      <TableCell align="center">{fullName}</TableCell>
      <TableCell align="center">{email ?? t('COMMON.NO_DATA')}</TableCell>
      <TableCell align="center">
        {phone ? addPointsPhone(phone) : t('COMMON.NO_DATA')}
      </TableCell>
      <TableCell align="center">
        {isSignatory ? <StatusIcon type={IconStatusAndColor.OK.status} /> : ' '}
      </TableCell>
      <TableCell align="center">
        <MoreMenu editDisabled handleClickOption={handleClickMore} />
      </TableCell>

      <ConfirmationDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        onSubmit={handleUpdateContact}
        title={t('COUNSELORS.DELETE_COUNSELOR')}
        description={t('COUNSELORS.DELETE_COUNSELOR_DESCRIPTION')}
      />
    </TableRow>
  );
};

CounselorsRow.propTypes = {
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isSignatory: PropTypes.bool.isRequired
};

export default CounselorsRow;
