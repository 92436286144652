export const selectAgent = state => state.agents;
export const selectAgentList = state => selectAgent(state).items;
export const selectAgentListCount = state => selectAgent(state).count;
export const selectAgentDetail = state => selectAgent(state).detail;
export const selectAgentQueryOptions = state => {
  const { options } = selectAgent(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
