import React from 'react';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import AgentTabs from './AgentTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const AgentDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={`${t('NAVIGATION.AGENT')} ${id ?? ''}`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            <AgentTabs />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default AgentDetail;
