import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';

import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Preloader from 'src/components/Preloader';
import { ArrowLeft } from 'react-feather';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  LOAD_INVESTMENT_DETAIL,
  loadInvestmentDetail
} from 'src/store/actions/investmentActions';
import AlertMessage from 'src/components/Alert/Alert';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import InvestmentPaymentStatus from 'src/utils/constants/investments';
import { selectInvestmentsDetail } from 'src/store/selectors/investmentSelector';
import GeneralDetail from './GeneralDetail/GeneralDetail';
import DocumentInvestmentDetail from './DocumentsDetail/DocumentInvestmentDetail';
import TitleDetail from './TitleDetail/TitleDetail';

const InvestmentDetailView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { goBack } = useHistory();
  const loading = useSelector(
    isLoadingSelector([LOAD_INVESTMENT_DETAIL.action])
  );
  const success = useSelector(successSelector([LOAD_INVESTMENT_DETAIL.action]));
  const investment = useSelector(selectInvestmentsDetail);

  useEffect(() => {
    dispatch(loadInvestmentDetail(id));
  }, [dispatch, id]);

  return (
    <Box mt={3} sx={{ marginLeft: 2, marginRight: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} ml={3}>
          <Breadcrumb history={['INVESTMENTS_VIEW.TITLE']} current={id} />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton onClick={goBack} color="primary" component="span">
              <ArrowLeft size={30} />
            </IconButton>
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.INVESTMENT')}
            </Typography>
          </Box>
        </Grid>

        {success && !loading ? (
          <>
            {investment.paymentStatus === InvestmentPaymentStatus.PENDING && (
              <Grid item xs={12}>
                <AlertMessage
                  open
                  severity={AlertSeverity.WARNING}
                  hideCloseIcon
                  title="COMMON.WARNING"
                  text="COMMON.PENDING_PAYMENT_WARNING"
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <GeneralDetail />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DocumentInvestmentDetail />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TitleDetail />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InvestmentDetailView;
