/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LOAD_CLIENTS, loadAllClients } from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectClientsList } from 'src/store/selectors/clientSelectors';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import AlertWithLink from 'src/components/Alert/AlertWithLink/AlertWithLink';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { hasAddressCompleted } from 'src/utils/common';
import { AddressType } from 'src/utils/constants/address';
import { selectAddressByType } from 'src/utils/address';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepTwo = ({
  data,
  handleNext,
  isQuoteAssignedToClient,
  open,
  resetRef,
  handleBack
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const loadingClients = useSelector(isLoadingSelector([LOAD_CLIENTS.action]));

  const clientList = useSelector(selectClientsList);
  useEffect(() => {
    if (open) {
      dispatch(loadAllClients());
    }
  }, [open]);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  const onCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => {
          resetForm();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <>
                <AlertWithLink
                  severity="warning"
                  text={t('INFO.LUCK_BANK_INFORMATION')}
                  open={openAlert}
                  link={
                    <Link
                      to={`/clients/detail/${values.clientId}/billing/bank_account`}
                    >
                      {t(
                        'CONTRACT_VIEW.AUTOMATIC_PAYMENT_BANK_ACCOUNT_REDIRECT'
                      )}
                    </Link>
                  }
                  handleClose={onCloseAlert}
                />
              </>
              <FormControl
                fullWidth
                error={Boolean(touched.clientId && errors.clientId)}
              >
                <>
                  <Autocomplete
                    options={clientList}
                    id="clientId"
                    label={t('COMMON.CLIENT')}
                    isLoading={loadingClients}
                    disabled={loadingClients || isQuoteAssignedToClient}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ''
                    }
                    getOptionLabel={option => {
                      return option.fullName
                        ? `${option.fullName} - ID:${option.id}`
                        : '';
                    }}
                    value={
                      clientList.find(
                        client => client.id === values.clientId
                      ) ?? null
                    }
                    fullWidth
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    onChange={(_e, value) => {
                      setFieldValue('clientId', value?.id ?? '');
                      setFieldValue(
                        'clientPersonType',
                        value?.personType ?? null
                      );
                      if (
                        !value.bankInformation?.bankAccount &&
                        !value.bankInformation?.clabe
                      ) {
                        setOpenAlert(true);
                      }
                      const address =
                        selectAddressByType(
                          value.addresses,
                          AddressType.WORK
                        ) || {};
                      if (hasAddressCompleted(address)) {
                        setFieldValue('isExistingAddress', true);
                        setFieldValue('equipmentLocation', {
                          street: address.street,
                          insideHomeNumber: address.insideHomeNumber,
                          externalHomeNumber: address.externalHomeNumber,
                          colonia: address.colonia,
                          city: address.city,
                          state: address.state,
                          zipCode: address.zipCode
                        });
                      }
                    }}
                    tooltipText={
                      isQuoteAssignedToClient && t('COMMON.CLIENT_SELECTED')
                    }
                    helperText={touched.clientId && t(errors.clientId)}
                    error={Boolean(touched.clientId && errors.clientId)}
                  />
                </>
              </FormControl>
            </Box>
            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => {
                  handleBack();
                }}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
                disabled={loadingClients}
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepTwo.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  resetRef: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  isQuoteAssignedToClient: PropTypes.number
};

export default StepTwo;
