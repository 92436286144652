import StatusIcon from 'src/components/StatusIcon';
// eslint-disable-next-line import/no-named-as-default
import IconStatusAndColor from './constants/common';

const ValidationStatus = {
  VALIDATED: 'VALIDATED',
  NOT_VALIDATED: 'NOT_VALIDATED',
  NO_VALIDATION: 'NO_VALIDATION',
  VALIDATION_WITH_ERRORS: 'VALIDATION_WITH_ERRORS'
};

const ValidationIconStatus = ({ value }) => {
  const validatedIcon = StatusIcon({
    description: 'ENUMS.CLIENT_VALIDATION_STATUS.VALIDATED',
    type: IconStatusAndColor.OK.status
  });

  const notValidatedIcon = StatusIcon({
    description: 'ENUMS.CLIENT_VALIDATION_STATUS.NOT_VALIDATED',
    type: IconStatusAndColor.ERROR.status
  });

  const noValidationIcon = StatusIcon({
    description: 'COMMON.NO_DATA',
    type: IconStatusAndColor.NONE.status
  });

  const validationWithErrorsIcon = StatusIcon({
    description: 'ENUMS.CLIENT_VALIDATION_STATUS.ERROR_IN_VALIDATION',
    type: IconStatusAndColor.WARNING.status
  });

  const selectValidationIcon = () => {
    switch (value) {
      case ValidationStatus.VALIDATED:
        return validatedIcon;
      case ValidationStatus.NOT_VALIDATED:
        return notValidatedIcon;
      case ValidationStatus.NO_VALIDATION:
        return noValidationIcon;
      case ValidationStatus.VALIDATION_WITH_ERRORS:
        return validationWithErrorsIcon;
      default:
        return noValidationIcon;
    }
  };

  return selectValidationIcon();
};

export default ValidationIconStatus;
