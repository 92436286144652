/* eslint-disable import/prefer-default-export */

import { selectBackgroundValidationsQueryOptions } from '../selectors/backgroundValidationSelectors';

export function getBackgroundValidationOptions(getState, sendLimit = true) {
  const queryOpts = selectBackgroundValidationsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.validationOwner) {
    reqOptions.validationOwner = queryOpts.validationOwner;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  return reqOptions;
}
