/* eslint-disable func-names */
import * as Yup from 'yup';

export default Yup.object().shape({
  contract: Yup.object().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  date: Yup.date('SCHEMA_ERRORS.REQUIRED_FIELD').required(
    'SCHEMA_ERRORS.REQUIRED_FIELD'
  ),
  paymentForm: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  cfdis: Yup.array().test(
    'cfdis',
    'SCHEMA_ERRORS.NEED_MINIMUM_ONE_CFDI',
    function(value) {
      const errors = [];
      if (value.length === 0) {
        errors.push(
          this.createError({
            path: 'cfdis',
            message: 'SCHEMA_ERRORS.NEED_MINIMUM_ONE_CFDI'
          })
        );
      }
      for (let i = 0; i < value.length; i++) {
        if (!value[i]) {
          errors.push(
            this.createError({
              path: `cfdis[${i}]`,
              message: 'SCHEMA_ERRORS.REQUIRED_FIELD'
            })
          );
        }
        if (value[i]?.amountIvaIncluded === 0) {
          errors.push(
            this.createError({
              path: `cfdis[${i}]`,
              message: 'SCHEMA_ERRORS.VALUE_GREATER_THAN_ZERO'
            })
          );
        }

        if (value[i]?.amountToPay <= 0) {
          errors.push(
            this.createError({
              path: `cfdis[${i}]`,
              message: 'SCHEMA_ERRORS.VALUE_GREATER_THAN_ZERO'
            })
          );
        }
        if (value[i]?.amountToPay > value[i]?.amountIvaIncluded) {
          errors.push(
            this.createError({
              path: `cfdis[${i}]`,
              message:
                'SCHEMA_ERRORS.AMOUNT_TO_PAY_MUST_BE_LESS_THAN_OR_EQUAL_TO_AMOUNT_IVA_INCLUDED'
            })
          );
        }
      }

      if (errors.length > 0) {
        return new Yup.ValidationError(errors);
      }
      return true;
    }
  )
});
