import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  LOAD_DISTRIBUTORS,
  loadDistributors
} from 'src/store/actions/distributorActions';
import {
  selectDistributorList,
  selectDistributorListCount,
  selectDistributorQueryOptions
} from 'src/store/selectors/distributorSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import DistributorsTable from './DistributorsTable';

const DistributorsList = () => {
  const dispatch = useDispatch();
  const distributors = useSelector(selectDistributorList);
  const count = useSelector(selectDistributorListCount);
  const { page } = useSelector(selectDistributorQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_DISTRIBUTORS.action]));
  const success = useSelector(successSelector([LOAD_DISTRIBUTORS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadDistributors({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTORS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTORS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <DistributorsTable
      distributors={distributors}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default DistributorsList;
