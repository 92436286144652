import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string()
    .test('rfc', 'SCHEMA_ERRORS.RFC_FORMAT_INVALID', value => {
      if (value) {
        if (value.length === 12) {
          return /^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
            value
          );
        }
        return /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
          value
        );
      }
      return true;
    })
    .required('SCHEMA_ERRORS.RFC_REQUIRED')
});

export const validatePersonTypeByRFC = rfc => {
  if (rfc) {
    if (rfc.length === 12) {
      return { personType: PersonType.LEGAL };
    }
    return { personType: PersonType.PHYSICAL };
  }
  return true;
};
