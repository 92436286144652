import React, { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_QUOTES, loadQuotes } from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  selectQuotesList,
  selectQuotesListCount,
  selectQuotesQueryOptions
} from 'src/store/selectors/quoteSelectors';
import QuotesTable from './QuotesTable';
import useStyles from './styles';

const QuotesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector([LOAD_QUOTES.action]));
  const { page, status } = useSelector(selectQuotesQueryOptions);
  const count = useSelector(selectQuotesListCount);
  const quoteList = useSelector(selectQuotesList);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadQuotes({ page: value }));
    },
    [dispatch]
  );
  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadQuotes({ sort: { property, order } }));
    },
    [dispatch]
  );

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item lg={12}>
          <QuotesTable
            onChangePage={handleChangePage}
            className={classes.table}
            loading={loading}
            quotes={quoteList}
            count={count}
            page={page}
            status={status}
            onSortChange={handleSortChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuotesList;
