export default values => ({
  ...values,
  name: values ? values.name : '',
  twelveMonthsRate: values ? values.twelveMonthsRate : '',
  eighteenMonthsRate: values ? values.eighteenMonthsRate : '',
  twentyFourMonthsRate: values ? values.twentyFourMonthsRate : '',
  thirtySixMonthsRate: values ? values.thirtySixMonthsRate : '',
  fortyEigthMonthsRate: values ? values.fortyEigthMonthsRate : '',
  sixtyMonthsRate: values ? values?.sixtyMonthsRate : '',
  seventyTwoMonthsRate: values ? values?.seventyTwoMonthsRate : ''
});
