import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import AdministrativesRow from './AdministrativesRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ administratives, submitEdit }) => {
  const notificatedAdmin = administratives.filter(
    administrative => administrative.sendNotifications
  );

  return administratives.map(administrative => {
    const disabledSwitch =
      notificatedAdmin.length === 1 && administrative.sendNotifications;
    return (
      <AdministrativesRow
        key={administrative.id}
        {...administrative}
        disabledSwitch={disabledSwitch}
        submitEdit={submitEdit}
      />
    );
  });
};

const AdministrativesTable = ({
  className,
  administratives,
  loading,
  submitEdit,
  count,
  page,
  handleChangePage,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell style={{ width: '20%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.NAME')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.ROLE')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.EMAIL')}
      </TableCell>
      <TableCell align="center" style={{ width: '15%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.PHONE')}
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.IS_AVAL')}
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        {t(
          'CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.IS_LEGAL_REPRESENTATIVE'
        )}
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.SEND_NOTIFICATIONS')}
      </TableCell>
      <TableCell style={{ width: '5%' }}> </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody administratives={administratives} submitEdit={submitEdit} />
    ),
    [administratives]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, administratives]
  );

  if (!loading && (!administratives || count === 0)) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTOR_CONTACTS_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

AdministrativesTable.propTypes = {
  className: PropTypes.string,
  administratives: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  submitEdit: PropTypes.func,
  count: PropTypes.bool,
  page: PropTypes.bool,
  handleChangePage: PropTypes.func
};

export default AdministrativesTable;
