import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { selectBillingsQueryOptions } from 'src/store/selectors/billingSelector';
import { loadBillingFailures } from 'src/store/actions/billingActions';
import useDebounce from 'src/hooks/useDebounce';
import selectFormatDate from 'src/utils/selectDateFormat';
import SearchBar from 'src/components/SearchBar';
import moment from 'moment-timezone';
import dateFormatType from 'src/utils/dateFormatType';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectBillingsQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (clientName, queryOpts) =>
      dispatch(loadBillingFailures({ ...queryOpts, page: 0, clientName })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.BILLING_FAILURES')}
            </Typography>
            <Typography
              color="primary"
              variant="h3"
              style={{
                fontWeight: '350',
                marginLeft: '10px',
                textAlign: 'end'
              }}
            >
              {selectFormatDate(moment(), dateFormatType.MONTH_AND_YEAR)}
            </Typography>
          </Box>

          <Box className={classes.actionsContainer}>
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('CLIENTS_VIEW.SEARCH_BY_NAME')}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
