import React, { useEffect, useMemo, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DialogTransition from 'src/components/DialogTransition';
import validationSchema from './schema';
import useStyles from '../../styles';
import makeInitialState from './initialState';

const LineOfBusinessForm = ({ open, data, onClose, onCreate, onEdit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return onEdit;
    }
    return onCreate;
  }, [data, onEdit, onCreate]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} key="dialog-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data
              ? t('CATALOGS_VIEW.LINES_OF_BUSINESS.UPDATE_LINE_OF_BUSINESS')
              : t('CATALOGS_VIEW.LINES_OF_BUSINESS.ADD_LINE_OF_BUSINESS')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && t(errors.name)}
                    label={t('COMMON.NAME')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? t('COMMON.UPDATE') : t('COMMON.CREATE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={onClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

LineOfBusinessForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default LineOfBusinessForm;
