import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const QuoteCreationAlert = ({
  loading,
  kams,
  clients,
  distributors,
  selectedClient,
  selectedDistributor
}) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading) {
    if (selectedClient && selectedDistributor) {
      if (!kams.length) {
        return (
          <Collapse in={open}>
            <Alert severity="info">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_KAMS_FIRST')}
              <Link
                to={`/distributors/business/${selectedDistributor.id}/profiles`}
              >
                {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_KAMS_SECOND')}
              </Link>
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_KAMS_THIRD')}
            </Alert>
          </Collapse>
        );
      }
    }

    if (!clients.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_CLIENTS_FIRST')}
            <Link to="/clients">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_CLIENTS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }

    if (!distributors.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_FIRST')}
            <Link to="/distributors/business">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

export default QuoteCreationAlert;

QuoteCreationAlert.propTypes = {
  kams: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedClient: PropTypes.object.isRequired,
  selectedDistributor: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired
};
