import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min';
import CFDIIssuedListView from './CFDIIssued';
import CFDIIncomeListView from './CFDIIncome';
import WithholdingsView from './WIthholdings';
import CFDIExpenseListView from './CFDIExpense';
import CFDIPaymentListView from './CFDIPayment';
import CFDIReceivedListView from './CFDIReceived';

const TaxAccountingView = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/cfdi-issued`}
        component={CFDIIssuedListView}
      />
      <PrivateRoute
        path={`${match.url}/cfdi-income`}
        component={CFDIIncomeListView}
      />
      <PrivateRoute
        path={`${match.url}/cfdi-expense`}
        component={CFDIExpenseListView}
      />
      <PrivateRoute
        path={`${match.url}/cfdi-payment`}
        component={CFDIPaymentListView}
      />
      <PrivateRoute
        path={`${match.url}/withholdings`}
        component={WithholdingsView}
      />
      <Route
        path={`${match.url}/cfdi-received`}
        component={CFDIReceivedListView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/cfdi-issued`} />}
      />
    </>
  );
};

TaxAccountingView.propTypes = {
  match: PropTypes.object.isRequired
};

export default TaxAccountingView;
