import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExitToApp } from '@mui/icons-material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/store/actions/authActions';
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu.svg';
import { loadNotifications } from 'src/store/actions/notificationsActions';
import NotificationMenu from 'src/components/NotificationMenu';
import { useTranslation } from 'react-i18next';
import { selectAuthToken } from 'src/store/selectors/authSelectors';
import I18nMenu from './I18nMenu';

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    letterSpacing: 0.1,
    justifyContent: 'flex',
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: `calc(100% - ${theme.spacing(13)})`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  rootShift: {
    width: `calc(100% - ${theme.spacing(36)})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

const TopBar = ({ toggleNavbar, open, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const token = useSelector(selectAuthToken);

  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_BASE}notifications/${token}`
    );

    eventSource.onmessage = () => {
      dispatch(loadNotifications({ page: 0 }, t, setIsNotificationMenuOpen));
    };
    dispatch(loadNotifications({ page: 0 }, t, setIsNotificationMenuOpen));
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.rootShift]: open
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <IconButton color="primary" onClick={toggleNavbar}>
          <MenuIcon fill="current" />
        </IconButton>
        <Box flexGrow={1} />
        <NotificationMenu open={isNotificationMenuOpen} />
        <I18nMenu />
        <div
          style={{
            marginLeft: 20,
            display: 'flex',
            width: 50,
            height: 50,
            backgroundColor: '#110A5B',
            borderRadius: 25,
            border: '2px solid #DCDBDA'
          }}
        >
          <Tooltip title="Cerrar Sesión">
            <IconButton className={classes.button} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  toggleNavbar: PropTypes.func,
  open: PropTypes.bool
};

export default TopBar;
