import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Table, TableBody } from '@mui/material';
import CardHeader from './CardHeader';

const DetailCard = ({ title, children }) => {
  return (
    <Card variant="elevation">
      <CardHeader title={title} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

DetailCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default DetailCard;
