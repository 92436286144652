const makeInitialState = data => {
  const initialState = {};
  if (data) {
    data.forEach(item => {
      initialState[item.name] = item.value;
    });
  }
  return initialState;
};
export default makeInitialState;
