import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import BackgroundValidationListView from './BackgroundValidationListView';

const IdentityView = ({ match }) => {
  return (
    <>
      <Route
        path={`${match.url}/:tab?`}
        component={BackgroundValidationListView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/client`} />}
      />
    </>
  );
};

IdentityView.propTypes = {
  match: PropTypes.object.isRequired
};

export default IdentityView;
