export const selectInvestor = state => state.investors;
const selectInvestments = state => selectInvestor(state).detail.investments;
export const selectInvestorList = state => selectInvestor(state).items;
export const selectInvestorListCount = state => selectInvestor(state).count;
export const selectInvestorDetail = state => selectInvestor(state).detail;
export const selectInvestorQueryOptions = state => {
  const { options } = selectInvestor(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

export const selectInvestorInvesmentDetail = state =>
  selectInvestments(state).detail;
export const selectInvestorInvesments = state =>
  selectInvestor(state).detail.investments;
export const selectInvestorInvesmentsList = state =>
  selectInvestor(state).detail.investments.items;
export const selectInvestorInvesmentsListCount = state =>
  selectInvestor(state).detail.investments.count;
export const selectInvestorInvestmentsQueryOptions = state => {
  const { options } = selectInvestor(state).detail.investments;

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

export const selectInvestorIdentityValidation = state =>
  selectInvestor(state).identityValidation;
