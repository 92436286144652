import React from 'react';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Preloader from 'src/components/Preloader';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';

const Table = ({
  className,
  theads: Theads,
  tbody: Tbody,
  pagination: Pagination,
  loading,
  ...rest
}) => {
  return (
    <Card className={className} {...rest}>
      <PerfectScrollbar>
        <Box>
          <FadeIn transitionDuration={200}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxHeight: '70vh'
                }}
              >
                <Preloader style={{ height: '68vh' }} />
              </Box>
            ) : (
              <TableContainer
                sx={{
                  maxHeight: '70vh'
                }}
              >
                <MuiTable stickyHeader>
                  <TableHead>
                    <Theads />
                  </TableHead>
                  <TableBody>
                    <Tbody />
                  </TableBody>
                </MuiTable>
              </TableContainer>
            )}

            {Pagination ? <Pagination /> : null}
          </FadeIn>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  theads: PropTypes.func.isRequired,
  tbody: PropTypes.func.isRequired,
  pagination: PropTypes.func,
  loading: PropTypes.bool
};

export default Table;
