import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { SatWsCredentialStatus } from 'src/utils/constants/clients';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import {
  UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS,
  sendCredentialsToSatWs,
  updateClientInformationWithSatWs
} from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import useStyles from './styles';
import SatWsDialog from './SatWsDialog';
import LoadingSatWsInformationDataDialog from './LoadingSatWsInformationDataDialog';

const selectSatWsStatus = status => {
  switch (status) {
    case SatWsCredentialStatus.PENDING:
      return {
        label: 'ENUMS.SAT_CREDENTIAL_STATUS.PENDING',
        icon: IconStatusAndColor.WARNING.status
      };

    case SatWsCredentialStatus.VALID:
      return {
        label: 'ENUMS.SAT_CREDENTIAL_STATUS.VALID',
        icon: IconStatusAndColor.OK.status
      };

    case SatWsCredentialStatus.INVALID:
      return {
        label: 'ENUMS.SAT_CREDENTIAL_STATUS.INVALID',
        icon: IconStatusAndColor.ERROR.status
      };

    case SatWsCredentialStatus.DEACTIVATED:
      return {
        label: 'ENUMS.SAT_CREDENTIAL_STATUS.DEACTIVATED',
        icon: IconStatusAndColor.ERROR.status
      };

    case SatWsCredentialStatus.ERROR:
      return {
        label: 'ENUMS.SAT_CREDENTIAL_STATUS.ERROR',
        icon: IconStatusAndColor.ERROR.status
      };

    default:
      return {
        label: '-',
        icon: IconStatusAndColor.NONE.status
      };
  }
};

const selectButtonAction = status => {
  if (status === SatWsCredentialStatus.VALID) {
    return 'CLIENTS_VIEW.BILLING_DETAIL.OBTAIN_DATA_FROM_SAT';
  }
  if (status === SatWsCredentialStatus.INVALID) {
    return 'CLIENTS_VIEW.BILLING_DETAIL.RESEND_CREDENTIALS';
  }
  return 'CLIENTS_VIEW.BILLING_DETAIL.ADD_CREDENTIALS';
};

const SatWsToolbar = ({ client }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSatSwDialog, setOpenSatSwDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const loadingSatWsUpdateInformation = useSelector(
    isLoadingSelector([UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS.action])
  );
  const { t } = useTranslation();
  const { id } = useParams();

  const handleCloseSatSwDialog = () => {
    setOpenSatSwDialog(false);
  };

  const handleOpenLoadingDialog = () => {
    setOpenLoadingDialog(true);
  };

  const handleCloseLoadingDialog = () => {
    setOpenLoadingDialog(false);
  };

  const handleOpenSatSwDialog = () => {
    if (
      !client.credentialStatus ||
      client.credentialStatus === SatWsCredentialStatus.INVALID ||
      client.credentialStatus === SatWsCredentialStatus.ERROR
    ) {
      return setOpenSatSwDialog(true);
    }
    handleOpenLoadingDialog();
    return dispatch(
      updateClientInformationWithSatWs(id, handleCloseLoadingDialog)
    );
  };

  const handleSubmitCredentials = useCallback(
    values => {
      dispatch(sendCredentialsToSatWs(id, values, handleCloseSatSwDialog));
    },
    [client, dispatch]
  );

  return (
    <Box className={classes.billingContainer}>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Box
            className={classes.billing}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <Box
              className={
                client.facturapiId
                  ? classes.billingIconSuccess
                  : classes.billingIconError
              }
            >
              <StatusIcon
                type={
                  client.facturapiId
                    ? IconStatusAndColor.OK.status
                    : IconStatusAndColor.NONE.status
                }
              />
            </Box>
            <Box>
              <Typography
                style={{ marginBottom: 8 }}
                color="textPrimary"
                component="p"
                variant="body2"
              >
                {t(
                  'CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.BILLING_VALIDATION_STATUS'
                )}
              </Typography>
              <Typography color="primary" component="h3" variant="h3">
                {client.facturapiId
                  ? t('ENUMS.CLIENT_VALIDATION_STATUS.VALIDATED')
                  : t('ENUMS.CLIENT_VALIDATION_STATUS.NOT_VALIDATED')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.billing}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <Box
              className={
                client.satWsCredentialId
                  ? classes.billingIconSuccess
                  : classes.billingIconError
              }
            >
              <StatusIcon
                type={selectSatWsStatus(client.credentialStatus).icon}
              />
            </Box>
            <Box>
              <Typography
                style={{ marginBottom: 8 }}
                color="textPrimary"
                component="p"
                variant="body2"
              >
                {t('CLIENTS_VIEW.BILLING_DETAIL.CREDENTIAL_STATUS')}
              </Typography>
              <Typography color="primary" component="h3" variant="h3">
                {t(selectSatWsStatus(client.credentialStatus).label)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 2,
            alignItems: 'end'
          }}
        >
          <Button
            className={classes.buttons}
            color="primary"
            variant="contained"
            onClick={handleOpenSatSwDialog}
            disabled={client.credentialStatus === SatWsCredentialStatus.PENDING}
          >
            {t(selectButtonAction(client.credentialStatus))}
          </Button>
          <SatWsDialog
            open={openSatSwDialog}
            handleClose={handleCloseSatSwDialog}
            submit={handleSubmitCredentials}
            client={client}
          />
        </Grid>
      </Grid>
      {loadingSatWsUpdateInformation && (
        <LoadingSatWsInformationDataDialog openDialog={openLoadingDialog} />
      )}
    </Box>
  );
};

SatWsToolbar.propTypes = {
  client: PropTypes.object
};

export default SatWsToolbar;
