import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailCard from 'src/components/DetailBox/DetailCard';
import { useSelector } from 'react-redux';
import DetailRow from 'src/components/DetailRow';
import moment from 'moment-timezone';
import { formatAmount } from 'src/utils/formatAmout';
import { selectInvestmentsDetail } from 'src/store/selectors/investmentSelector';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import { InvestorStatus } from 'src/utils/investors';

const GeneralDetail = () => {
  const { t } = useTranslation();
  const investment = useSelector(selectInvestmentsDetail);

  const getInvestmentPaymentStatusIcon = value => {
    switch (value) {
      case 'PENDING':
        return (
          <StatusIcon
            description="COMMON.PENDING"
            type={IconStatusAndColor.WARNING.status}
          />
        );
      case 'COMPLETED':
        return (
          <StatusIcon
            description="COMMON.PAID"
            type={IconStatusAndColor.OK.status}
          />
        );
      default:
        return (
          <StatusIcon
            description="COMMON.NO_DATA"
            type={IconStatusAndColor.NONE.status}
          />
        );
    }
  };

  return (
    <DetailCard title={t('COMMON.GENERAL')}>
      <DetailRow name={t('COMMON.ID')} value={investment.id} />
      <DetailRow
        name={t('COMMON.AMOUNT')}
        value={formatAmount(investment.amount)}
      />
      <DetailRow
        name={t('INVESTMENTS_VIEW.DATE')}
        value={moment(investment.date).format('DD/MM/YYYY')}
      />
      <DetailRow
        name={t('INVESTMENTS_VIEW.APPLICATION_DATE')}
        value={moment(investment.date).format('MMMM-YYYY')}
      />
      <DetailRow
        name={t('COMMON.STATUS')}
        value={t(`ENUMS.INVESTMENT_STATUS.${investment.status}`)}
      />
      {investment.status === InvestorStatus.ACTIVE && (
        <DetailRow
          name={t('INVESTMENTS_VIEW.SERIE_NUMBER')}
          value={investment.titleNumber}
        />
      )}
      {investment.paymentStatus && (
        <DetailRow
          name={t('COMMON.PAYMENT_STATUS')}
          value={getInvestmentPaymentStatusIcon(investment.paymentStatus)}
        />
      )}
    </DetailCard>
  );
};

export default GeneralDetail;
