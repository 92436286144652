import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  avals: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required('ERRORS.NEED_MINIMUM_ONE_AVAL'),
        fullName: Yup.string().required('ERRORS.NEED_MINIMUM_ONE_AVAL')
      })
    )
    // eslint-disable-next-line func-names
    .test('avalRequired', 'ERRORS.NEED_MINIMUM_ONE_AVAL', function(value) {
      const { clientPersonType } = this.parent;

      if (clientPersonType === PersonType.LEGAL) {
        if (!value.length)
          return this.createError({
            path: 'avals',
            message: 'ERRORS.NEED_ONE_REPRESENTATIVE_LEGAL_AND_ONE_AVAL'
          });
        if (!value[0].id) {
          return this.createError({
            path: 'avals',
            message: 'ERRORS.NEED_ONE_REPRESENTATIVE_LEGAL_AND_ONE_AVAL'
          });
        }
      }

      return true;
    })
});
