export const TransactionsOrigin = {
  MANUAL: 'MANUAL',
  PAYMENT: 'PAYMENT'
};

export const TransactionsType = {
  PAYMENT: 'PAYMENT',
  CHARGE: 'CHARGE'
};

export const TransactionsCategory = {
  RENT: 'RENT',
  INTEREST: 'INTEREST',
  OTHER: 'OTHER',
  DISCOUNT: 'DISCOUNT',
  CAPITAL_CONTRIBUTION: 'CAPITAL_CONTRIBUTION',
  INITIAL_PAYMENT: 'INITIAL_PAYMENT',
  RESIDUAL: 'RESIDUAL',
  UPFRONT_RENT: 'UPFRONT_RENT'
};

export const TransactionsStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const TransactionActions = {
  SEE_DOCUMENT: 'SEE_DOCUMENT',
  ASSIGN_TRANSACTION_TO_CONTRACT: 'ASSIGN_TRANSACTION_TO_CONTRACT'
};

export const CreateTransactionOptions = {
  CREATE_CHARGE: 'CREATE_CHARGE',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT'
};

export const PaymentForm = {
  CASH: '01', // Efectivo
  NOMINATIVE_CHECK: '02', // Cheque nominativo
  BANK_TRANSFER: '03', // Transferencia electrónica de fondos
  CREDIT_CARD: '04', // Tarjeta de crédito
  ELECTRONIC_WALLET: '05', // Monedero electrónico
  ELECTRONIC_MONEY: '06', // Dinero electrónico
  FOOD_VOUCHERS: '08', // Vales de despensa
  PAYMENT_IN_KIND: '12', // Dación en pago
  SUBROGATION_PAYMENT: '13', // Pago por subrogación
  CONSIGNMENT_PAYMENT: '14', // Pago por consignación
  CONDONATION: '15', // Condonación
  COMPENSATION: '17', // Compensación
  NOVATION: '23', // Novación
  CONFUSION: '24', // Confusión
  DEBT_REMISSION: '25', // Remisión de deuda
  EXPIRATION: '26', // Prescripción o caducidad
  CREDITOR_SATISFACTION: '27', // A satisfacción del acreedor
  DEBIT_CARD: '28', // Tarjeta de débito
  SERVICES_CARD: '29', // Tarjeta de servicios
  ADVANCE_APPLICATION: '30', // Aplicación de anticipos
  PAYMENT_INTERMEDIARY: '31', // Intermediario pagos
  PENDING_TO_DEFINE: '99' // Por definir
};

export const PaymentFormList = [
  {
    key: PaymentForm.CASH,
    name: 'ENUMS.PAYMENT_FORM.CASH'
  },
  {
    key: PaymentForm.NOMINATIVE_CHECK,
    name: 'ENUMS.PAYMENT_FORM.NOMINATIVE_CHECK'
  },
  {
    key: PaymentForm.BANK_TRANSFER,
    name: 'ENUMS.PAYMENT_FORM.BANK_TRANSFER'
  },
  {
    key: PaymentForm.CREDIT_CARD,
    name: 'ENUMS.PAYMENT_FORM.CREDIT_CARD'
  },
  {
    key: PaymentForm.ELECTRONIC_WALLET,
    name: 'ENUMS.PAYMENT_FORM.ELECTRONIC_WALLET'
  },
  {
    key: PaymentForm.ELECTRONIC_MONEY,
    name: 'ENUMS.PAYMENT_FORM.ELECTRONIC_MONEY'
  },
  {
    key: PaymentForm.FOOD_VOUCHERS,
    name: 'ENUMS.PAYMENT_FORM.FOOD_VOUCHERS'
  },
  {
    key: PaymentForm.PAYMENT_IN_KIND,
    name: 'ENUMS.PAYMENT_FORM.PAYMENT_IN_KIND'
  },
  {
    key: PaymentForm.SUBROGATION_PAYMENT,
    name: 'ENUMS.PAYMENT_FORM.SUBROGATION_PAYMENT'
  },
  {
    key: PaymentForm.CONSIGNMENT_PAYMENT,
    name: 'ENUMS.PAYMENT_FORM.CONSIGNMENT_PAYMENT'
  },
  {
    key: PaymentForm.CONDONATION,
    name: 'ENUMS.PAYMENT_FORM.CONDONATION'
  },
  {
    key: PaymentForm.COMPENSATION,
    name: 'ENUMS.PAYMENT_FORM.COMPENSATION'
  },
  {
    key: PaymentForm.NOVATION,
    name: 'ENUMS.PAYMENT_FORM.NOVATION'
  },
  {
    key: PaymentForm.CONFUSION,
    name: 'ENUMS.PAYMENT_FORM.CONFUSION'
  },
  {
    key: PaymentForm.DEBT_REMISSION,
    name: 'ENUMS.PAYMENT_FORM.DEBT_REMISSION'
  },
  {
    key: PaymentForm.EXPIRATION,
    name: 'ENUMS.PAYMENT_FORM.EXPIRATION'
  },
  {
    key: PaymentForm.CREDITOR_SATISFACTION,
    name: 'ENUMS.PAYMENT_FORM.CREDITOR_SATISFACTION'
  },
  {
    key: PaymentForm.DEBIT_CARD,
    name: 'ENUMS.PAYMENT_FORM.DEBIT_CARD'
  },
  {
    key: PaymentForm.SERVICES_CARD,
    name: 'ENUMS.PAYMENT_FORM.SERVICES_CARD'
  },
  {
    key: PaymentForm.ADVANCE_APPLICATION,
    name: 'ENUMS.PAYMENT_FORM.ADVANCE_APPLICATION'
  },
  {
    key: PaymentForm.PAYMENT_INTERMEDIARY,
    name: 'ENUMS.PAYMENT_FORM.PAYMENT_INTERMEDIARY'
  },
  {
    key: PaymentForm.PENDING_TO_DEFINE,
    name: 'ENUMS.PAYMENT_FORM.PENDING_TO_DEFINE'
  }
];
