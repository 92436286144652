import {
  CLEAR_COUNSELOR_OPTIONS,
  LOAD_COUNSELORS,
  SAVE_COUNSELOR_OPTIONS
} from '../actions/counselorActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0
};

const counselorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COUNSELOR_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_COUNSELOR_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_COUNSELORS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }

    default: {
      return state;
    }
  }
};

export default counselorReducer;
