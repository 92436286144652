import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            component="div"
            className={classes.reviewDataLabel}
            sx={{ borderTop: 'none' }}
          >
            {t('CLIENTS_VIEW.CREATION_FORM.PERSON_TYPE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {t(`CLIENTS_VIEW.CREATION_FORM.${values.personType}`)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.NAME')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.name}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.PATERNAL_NAME')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.paternalName}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.MATERNAL_NAME')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.maternalName}
            </Typography>
          </TableCell>
        </TableRow>

        {values.phone && (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('COMMON.PHONE')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.phone}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        {values.email && (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('COMMON.EMAIL')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.email}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        {values.agent && (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('COMMON.AGENT')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.agent.fullName}
              </Typography>
            </TableCell>
          </TableRow>
        )  
        }

        <TableRow component="div">
          <TableCell
            component="div"
            className={classes.reviewDataLabel}
            sx={{ borderBottom: 'none' }}
          >
            {t('COMMON.RFC')}
          </TableCell>
          <TableCell component="div" sx={{ borderBottom: 'none' }}>
            <Typography variant="body2" color="textSecondary">
              {values.rfc}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
