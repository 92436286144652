import * as Yup from 'yup';
import { ParameterType } from 'src/utils/constants/parameters';
import { transformJsonToArray } from 'src/utils/common';
import { isValidPhoneNumber } from 'libphonenumber-js';

export default Yup.object().shape({
  value: Yup.string()
    // eslint-disable-next-line func-names
    .test('value', 'ERRORS.NEED_MINIMUM_ONE_AVAL', function(value) {
      const { type, name } = this.parent;
      const errors = [];
      if (type === ParameterType.ARRAY) {
        if (name.includes('EMAIL')) {
          const values = transformJsonToArray(value);
          if (values.length === 0) {
            return this.createError({
              path: 'value',
              message: 'SCHEMA_ERRORS.NEED_MINIMUM_ONE_EMAIL'
            });
          }

          for (let i = 0; i < values.length; i++) {
            const isValid = Yup.string()
              .email('SCHEMA_ERRORS.EMAIL_FORMAT_INVALID')
              .isValidSync(values[i]);

            if (!isValid) {
              errors.push(
                this.createError({
                  path: `value[${i}]`,
                  message: `SCHEMA_ERRORS.EMAIL_FORMAT_INVALID`
                })
              );
            }
          }
          if (errors.length > 0) {
            return new Yup.ValidationError(errors);
          }
        }
      }
      if (type === ParameterType.PHONE_NUMBER) {
        const isValidNumber = isValidPhoneNumber(`+${value}`);

        if (!isValidNumber) {
          return this.createError({
            path: 'value',
            message: 'SCHEMA_ERRORS.PHONE_INVALID'
          });
        }
      }

      return true;
    })
    .required('SCHEMA_ERRORS.ENTER_VALUE')
});
