import {
  CLEAR_CFDIS,
  CLEAR_CFDIS_OPTIONS,
  LOAD_CFDIS,
  LOAD_CFDIS_EXPENSE,
  LOAD_CFDIS_INCOME,
  SAVE_CFDIS_OPTIONS,
  LOAD_CFDIS_PAYMENT,
  LOAD_WITHHOLDINGS,
  LOAD_CFDIS_RECEIVED,
} from '../constants/CFDI';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  income: {
    items: [],
    count: 0
  },
  withholdings: {
    items: [],
    count: 0
  },
  expense: {
    items: [],
    count: 0
  },
  payment: {
    items: [],
    count: 0
  },
  received: {
    items: [],
    count: 0,
    lastSync: null
  },
};

const cfdiReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CFDIS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case SAVE_CFDIS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_CFDIS_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_CFDIS_INCOME.success: {
      return {
        ...state,
        income: {
          items: action.items,
          count: action.count
        }
      };
    }
    case LOAD_CFDIS_EXPENSE.success: {
      return {
        ...state,
        expense: {
          items: action.items,
          count: action.count
        }
      };
    }

    case LOAD_WITHHOLDINGS.success: {
      return {
        ...state,
        withholdings: {
          items: action.items,
          count: action.count
        }
      };
    }

    case LOAD_CFDIS_PAYMENT.success: {
      return {
        ...state,
        payment: {
          items: action.items,
          count: action.count
        }
      };
    }
    case LOAD_CFDIS_RECEIVED.success: {
      return {
        ...state,
        received: {
          items: action.items,
          count: action.count,
          lastSync: action.lastSync
        }
      };
    }
    case CLEAR_CFDIS: {
      return {
        ...state,
        items: [],
        count: 0,
        balance: 0,
        income: {
          items: [],
          count: 0
        },
        withholdings: {
          items: [],
          count: 0
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default cfdiReducer;
