import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers';
import DropdownMenu from 'src/components/DropdownMenu';
import { DaysForFirstRentAfterContractStartList } from 'src/utils/constants/distributors';
import { calculateFirstRentDateFromGracePeriod } from 'src/utils/contracts';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import generateNumbersList from 'src/utils/generateNumbersList';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepOne = ({ data, handleNext, resetRef }) => {
  const classes = useStyles();
  const initialState = makeInitialState(data);

  const { t } = useTranslation();

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <DatePicker
                label={t('COMMON.START_DATE')}
                value={values.startDate}
                margin="normal"
                inputFormat="DD/MM/YYYY"
                disabled
                onChange={newValue => {
                  setFieldValue('startDate', moment(newValue));
                  setFieldValue(
                    'firstRentDate',
                    calculateFirstRentDateFromGracePeriod(
                      moment(newValue),
                      values.gracePeriod
                    )
                  );
                  setFieldValue(
                    'endDate',
                    calculateFirstRentDateFromGracePeriod(
                      moment(newValue),
                      values.gracePeriod
                    ).add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.startDate && errors.startDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={touched.startDate && t(errors.startDate)}
                  />
                )}
              />

              <DropdownMenu
                list={DaysForFirstRentAfterContractStartList}
                label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                value={values.gracePeriod}
                disabled={data.gracePeriod != null}
                name="gracePeriod"
                setValue={value => {
                  setFieldValue('gracePeriod', value);
                  setFieldValue(
                    'firstRentDate',
                    calculateFirstRentDateFromGracePeriod(
                      moment(values.startDate),
                      value
                    )
                  );
                  setFieldValue(
                    'endDate',
                    calculateFirstRentDateFromGracePeriod(
                      moment(values.startDate),
                      value
                    ).add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                errorText={touched.gracePeriod && t(errors.gracePeriod)}
              />

              <DatePicker
                label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                value={values.firstRentDate}
                margin="normal"
                inputFormat="DD/MM/YYYY"
                disabled
                onChange={newValue => {
                  setFieldValue('firstRentDate', moment(newValue).toDate());
                }}
                error={Boolean(touched.firstRentDate && errors.firstRentDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={
                      touched.firstRentDate && t(errors.firstRentDate)
                    }
                  />
                )}
              />

              <DatePicker
                label={t('COMMON.END_DATE')}
                value={values.endDate}
                margin="normal"
                inputFormat="DD/MM/YYYY"
                disabled
                error={Boolean(touched.endDate && errors.endDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={touched.endDate && t(errors.endDate)}
                  />
                )}
              />
              <Autocomplete
                options={generateNumbersList(1, 25)}
                id="payday"
                label={t('NAVIGATION.PAY_DAY')}
                value={values.payday}
                fullWidth
                onInputChange={(e, value) => {
                  setFieldValue('payday', Number(value));
                }}
                sx={{ marginBottom: 1, marginTop: 2 }}
                helperText={touched.payday && t(errors.payday)}
                error={Boolean(touched.payday && errors.payday)}
              />
            </Box>
            <Box
              className={classes.box}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  resetRef: PropTypes.object,
  handleNext: PropTypes.func
};

export default StepOne;
