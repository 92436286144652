import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  downloadUpfrontRents,
  getUpfrontRents
} from 'src/services/upfrontRentsService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { selectUpfrontRentsQueryOptions } from '../selectors/upfrontRentsSelector';
import { showMessage } from './snackBarActions';

// Sync actions
export const CLEAR_UPFRONT_RENTS = 'CLEAR_UPFRONT_RENTS';
export const SAVE_UPFRONT_RENTS_OPTIONS = 'SAVE_UPFRONT_RENTS_OPTIONS';
export const CLEAR_UPFRONT_RENTS_OPTIONS = 'CLEAR_UPFRONT_RENTS_OPTIONS';

// Async actions
export const LOAD_UPFRONT_RENTS = asyncActionCreator('LOAD_UPFRONT_RENTS');
export const DOWNLOAD_UPFRONT_RENTS = asyncActionCreator(
  'DOWNLOAD_UPFRONT_RENTS'
);

export const getUpfrontRentsOptions = (getState, sendLimit = true) => {
  const queryOpts = selectUpfrontRentsQueryOptions(getState());
  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.date) {
    reqOptions.date = queryOpts.date;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  return reqOptions;
};

export const clearUpfrontRents = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_UPFRONT_RENTS });
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const loadUpfrontRents = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_UPFRONT_RENTS.start });

    dispatch({
      type: SAVE_UPFRONT_RENTS_OPTIONS,
      options
    });

    const reqOptions = getUpfrontRentsOptions(getState);

    const upfrontRents = await getUpfrontRents(reqOptions);

    return dispatch({
      type: LOAD_UPFRONT_RENTS.success,
      ...upfrontRents
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_UPFRONT_RENTS.failure });
  }
};

export const exportUpfrontRentList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_UPFRONT_RENTS.start });

    dispatch({
      type: DOWNLOAD_UPFRONT_RENTS,
      options
    });

    const reqOptions = getUpfrontRentsOptions(getState);

    await downloadUpfrontRents(reqOptions);

    return dispatch({
      type: DOWNLOAD_UPFRONT_RENTS.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DOWNLOAD_UPFRONT_RENTS.failure });
  }
};
