import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import TransactionListView from './Transactions';

const TransactionView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={TransactionListView} />
    </>
  );
};

TransactionView.propTypes = {
  match: PropTypes.object.isRequired
};

export default TransactionView;
