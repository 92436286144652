import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AdminPrivateRoute from 'src/components/PrivateRotes/AdminPrivateRoute';
import QuotesAdminView from './QuotesAdminView';
import QuoteDetailView from './QuotesDetail';

const QuotesView = ({ match }) => {
  return (
    <>
      <AdminPrivateRoute
        exact
        path={`${match.url}/:tab?`}
        component={QuotesAdminView}
      />

      <Route path={`${match.url}/detail/:id`} component={QuoteDetailView} />
    </>
  );
};

QuotesView.propTypes = {
  match: PropTypes.object.isRequired
};

export default QuotesView;
