import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import {
  deleteDistributorContact,
  updateDistributorContact
} from 'src/store/actions/distributorActions';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CommonActions } from 'src/utils/constants/clients';
import { addPointsPhone } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import ContactDialog from './CreateContact';
import DeleteDistributorContactDialog from './DeleteDistributorContactDialog/DeleteDistributorContactDialog';

const ContactsRow = ({
  id,
  name,
  position,
  department,
  phone,
  email,
  notification,
  isDisabledActions = false,
  distributorProfileId
}) => {
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { id: distributorId } = useParams();
  const { t } = useTranslation();

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenDeleteDialog(true);
    }
  };

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteDistributorContact(id, distributorId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  const handleUpdateContact = useCallback(
    values => {
      dispatch(updateDistributorContact(id, distributorId, values));
      handleCloseEdit();
    },
    [dispatch, id]
  );
  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell align="center">
        {t(`ENUMS.DEPARTMENT.${department}`)}
      </TableCell>
      <TableCell align="center">{t(`ENUMS.POSITION.${position}`)}</TableCell>
      <TableCell align="center">{addPointsPhone(phone)}</TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            notification
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
        />
      </TableCell>
      <TableCell align="center">
        <MoreMenu
          handleClickOption={handleClickMore}
          editDisabled={distributorProfileId}
          disabled={isDisabledActions}
        />
      </TableCell>
      <ContactDialog
        open={openEditDialog}
        handleClose={handleCloseEdit}
        onSubmit={handleUpdateContact}
        contact={{
          name,
          position,
          department,
          phone,
          email,
          notification
        }}
      />
      <DeleteDistributorContactDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
    </TableRow>
  );
};

ContactsRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  notification: PropTypes.string.isRequired,
  isDisabledActions: PropTypes.bool,
  distributorProfileId: PropTypes.string.isRequired
};

export default ContactsRow;
