import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import ContractsRow from './ContractsRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ contracts, t }) =>
  contracts.map(contract => (
    <ContractsRow key={contract.id} t={t} {...contract} />
  ));

const ContractsTable = ({
  className,
  contracts,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const minWidth = '9rem';

  const theads = useMemo(
    () => () => (
      <TableRow
        sx={{
          '& .MuiTableCell-head': { whiteSpace: 'nowrap', textAlign: 'center' }
        }}
      >
        <TableCell variant="head" key="id">
          ID
        </TableCell>
        <TableCell variant="head" key="client" style={{ minWidth }}>
          {t('COMMON.CLIENT')}
        </TableCell>
        <TableCell variant="head" key="equipment" style={{ minWidth }}>
          {t('COMMON.EQUIPMENT')}
        </TableCell>
        <TableCell variant="head" key="equipment" style={{ minWidth }}>
          {t('CONTRACT_VIEW.FOLIO')}
        </TableCell>
        <TableCell variant="head" key="signature-date" style={{ minWidth }}>
          {t('CONTRACT_VIEW.SIGNATURE_DATE')}
        </TableCell>
        <TableCell variant="head" key="first-rent" style={{ minWidth }}>
          {t('CONTRACT_VIEW.FIRST_RENT')}
        </TableCell>
        <TableCell variant="head" key="last-rent" style={{ minWidth }}>
          {t('CONTRACT_VIEW.LAST_RENT')}
        </TableCell>
        <TableCell variant="head" key="gross-investment" style={{ minWidth }}>
          {t('CONTRACT_VIEW.GROSS_INVESTMENT')}
        </TableCell>
        <TableCell variant="head" key="initial-payment" style={{ minWidth }}>
          {t('CONTRACT_VIEW.INITIAL_PAYMENT')}
        </TableCell>
        <TableCell variant="head" key="net-investment" style={{ minWidth }}>
          {t('CONTRACT_VIEW.NET_INVESTMENT')}
        </TableCell>
        <TableCell variant="head" key="residual" style={{ minWidth }}>
          {t('CONTRACT_VIEW.RESIDUAL')}
        </TableCell>
        <TableCell variant="head" key="term" style={{ minWidth }}>
          {t('CONTRACT_VIEW.TERM')}
        </TableCell>
        <TableCell variant="head" key="rate" style={{ minWidth }}>
          {t('CONTRACT_VIEW.RATE')}
        </TableCell>
        <TableCell variant="head" key="payment" style={{ minWidth }}>
          {t('CONTRACT_VIEW.CURRENT_RENT')}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(() => () => <Tbody t={t} contracts={contracts} />, [
    contracts,
    t
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, contracts]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

ContractsTable.propTypes = {
  className: PropTypes.string,
  contracts: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

ContractsTable.defaultProps = {
  count: 0
};

export default ContractsTable;
