import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import theme from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_AGENT_HISTORY,
  loadAgentHistory
} from 'src/store/actions/investorActions';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from '../Loading';
import DialogTransition from '../DialogTransition';

const AgentHistoryDialog = ({ id, open, handleClose }) => {
  const { t } = useTranslation();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const loading = useSelector(isLoadingSelector([LOAD_AGENT_HISTORY.action]));
  const success = useSelector(successSelector([LOAD_AGENT_HISTORY.action]));

  useEffect(() => {
    if (open) {
      dispatch(loadAgentHistory(id, setAgents));
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '50vh',
          maxHeight: '70vh'
        }
      }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle
        sx={{
          color: theme.palette.primary.main,
          marginTop: theme.spacing(1),
          '& h2': {
            fontWeight: 400
          }
        }}
        id="form-documents-dialog-title"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          sx={{
            float: 'right',
            width: '20px',
            height: '20px'
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography color="primary" variant="h2">
          {t('AGENTS.AGENT_HISTORY')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', padding: 2 }}>
        {success && !loading && agents.length ? (
          <TableContainer sx={{ maxHeight: '90%' }}>
            <Table aria-label="documents table" stickyHeader>
              <TableHead>
                <TableRow component="tr">
                  <TableCell
                    variant="head"
                    align="left"
                    sx={{ backgroundColor: 'white !important' }}
                  >
                    {t('COMMON.START_DATE')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="left"
                    sx={{ backgroundColor: 'white !important' }}
                  >
                    {t('COMMON.END_DATE')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="left"
                    sx={{ backgroundColor: 'white !important' }}
                  >
                    {t('COMMON.NAME')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map(row => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      {row.startDate
                        ? selectFormatDate(row.startDate, dateFormatType.DAY)
                        : t('COMMON.NO_DATA')}
                    </TableCell>
                    <TableCell align="left">
                      {row.endDate
                        ? selectFormatDate(row.endDate, dateFormatType.DAY)
                        : t('COMMON.NO_DATA')}
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/investors/agents/detail/${row.id}`}>
                        {row.fullName}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loading />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

AgentHistoryDialog.propTypes = {
  open: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string
};

export default AgentHistoryDialog;
