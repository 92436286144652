import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import IncidentRow from './IncidentRow';

const IncidentTable = ({
  className,
  incidents,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    incident => () => {
      handleOpenEdit(incident);
    },
    [handleOpenEdit]
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.ID')}</TableCell>
      <TableCell>{t('COMMON.NAME')}</TableCell>
      <TableCell>{t('COMMON.ACTION')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return incidents.map(incident => (
        <IncidentRow
          {...incident}
          openEdit={openEdit(incident)}
          handleDelete={handleDelete}
          key={incident.id}
        />
      ));
    },
    [incidents]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

IncidentTable.propTypes = {
  className: PropTypes.string,
  incidents: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default IncidentTable;
