import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { NotificationParameters } from 'src/utils/constants/parameters';
import NotificationsParameterRow from './NotificationParameterRow';

const DisabledParameters = [
  NotificationParameters.ENABLE_BILLING_NOTIFICATIONS,
  NotificationParameters.ENABLE_NOTIFICATIONS
];

const NotificationsParameterTable = ({ className, parameters, ...rest }) => {
  const { t } = useTranslation();

  const isDisabledEditParameter = parameters.some(
    parameter =>
      parameter &&
      parameter.name === 'ENABLE_NOTIFICATIONS' &&
      parameter.value === 'false'
  );

  const disabledParameter = parameter => {
    return DisabledParameters.includes(parameter.name);
  };

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('PARAMETERS_VIEW.NAME')}</TableCell>
      <TableCell align="center">{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <NotificationsParameterRow
          {...parameter}
          isDisabledEdit={isDisabledEditParameter}
          disabledSwitch={disabledParameter(parameter)}
          key={parameter.id}
        />
      ));
    },
    [parameters]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

NotificationsParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default NotificationsParameterTable;
