import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import KamGeneralDetail from './GeneralDetail/KamGeneralDetail';

const GeneralView = ({ kam }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={5}>
          <KamGeneralDetail kam={kam} />
        </Grid>
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  kam: PropTypes.object
};

export default GeneralView;
