import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import {
  assigCounselorsSignatories,
  createNewCounselor,
  deleteCounselor,
  getCounselorList
} from 'src/services/counselorService';
import { selectCounselorsQueryOptions } from '../selectors/counselorSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_COUNSELOR_OPTIONS = 'SAVE_COUNSELOR_OPTIONS';
export const CLEAR_COUNSELOR_OPTIONS = 'CLEAR_COUNSELOR_OPTIONS';
export const LOAD_COUNSELORS = asyncActionCreator('LOAD_COUNSELORS');
export const LOAD_COUNSELORS_LIST = asyncActionCreator('LOAD_COUNSELORS_LIST');
export const LOAD_COUNSELORS_SIGNATORIES = asyncActionCreator(
  'LOAD_COUNSELORS_SIGNATORIES'
);
export const CREATE_COUNSELOR = asyncActionCreator('CREATE_COUNSELOR');
export const DELETE_COUNSELOR = asyncActionCreator('DELETE_COUNSELOR');
export const ASSIGN_COUNSELORS_SIGNATORIES = asyncActionCreator(
  'ASSIGN_COUNSELORS_SIGNATORIES'
);

const getOptions = (getState, sendLimit = true) => {
  const queryOpts = selectCounselorsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.isSignatory) {
    reqOptions.isSignatory = queryOpts.isSignatory;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
};

export const clearCounselorOptions = () => ({
  type: CLEAR_COUNSELOR_OPTIONS
});

export const loadCounselors = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_COUNSELORS.start });

    dispatch({
      type: SAVE_COUNSELOR_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const counselors = await getCounselorList(reqOptions);

    return dispatch({
      type: LOAD_COUNSELORS.success,
      ...counselors
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_COUNSELORS.failure });
  }
};

export const createCounselor = (body, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_COUNSELOR.start });

    await createNewCounselor(body);

    dispatch(
      showMessage({
        message: 'CREATE_COUNSELOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: CREATE_COUNSELOR.success });
    closeCallback();
    return dispatch(loadCounselors());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_COUNSELOR.failure });
  }
};

export const deleteCounselorAction = (id, closeCallback) => async dispatch => {
  try {
    dispatch({ type: DELETE_COUNSELOR.start });

    await deleteCounselor(id);

    dispatch(
      showMessage({
        message: 'DELETE_COUNSELOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: DELETE_COUNSELOR.success });
    closeCallback();
    return dispatch(loadCounselors());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DELETE_COUNSELOR.failure });
  }
};

export const loadCounselorsSignatories = setState => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_COUNSELORS_SIGNATORIES.start });
    dispatch({
      type: SAVE_COUNSELOR_OPTIONS,
      options: { isSignatory: true }
    });

    const reqOptions = getOptions(getState, false);

    const counselors = await getCounselorList(reqOptions);

    setState(counselors.items);

    dispatch({
      type: SAVE_COUNSELOR_OPTIONS,
      options: { isSignatory: null }
    });

    return dispatch({
      type: LOAD_COUNSELORS_SIGNATORIES.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_COUNSELORS_SIGNATORIES.failure });
  }
};

export const loadCounselorsList = setState => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_COUNSELORS_LIST.start });

    dispatch({
      type: SAVE_COUNSELOR_OPTIONS,
      options: { isSignatory: null }
    });

    const reqOptions = getOptions(getState, false);

    const counselors = await getCounselorList(reqOptions);

    setState(counselors.items);

    return dispatch({
      type: LOAD_COUNSELORS_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_COUNSELORS_LIST.failure });
  }
};

export const assignSignatories = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: ASSIGN_COUNSELORS_SIGNATORIES.start });

    await assigCounselorsSignatories(values);

    dispatch(
      showMessage({
        message: 'ASSIGN_COUNSELORS_SIGNATORIES',
        variant: SnackBarVariants.SUCCESS
      })
    );
    closeCallback();
    dispatch(loadCounselors());
    return dispatch({ type: ASSIGN_COUNSELORS_SIGNATORIES.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: ASSIGN_COUNSELORS_SIGNATORIES.failure });
  }
};
