import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_INVESTORS_LIST,
  loadInvestorsList
} from 'src/store/actions/investorActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectCounselorsList } from 'src/store/selectors/counselorSelectors';
import InvestmentAlert from 'src/views/investment/Investments/CreateInvestmentForm/InvestmentAlert';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';

const CreateCounselorsDialog = ({ open, handleClose, submit }) => {
  const { t } = useTranslation();
  const resetRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(), []);
  const counselors = useSelector(selectCounselorsList);
  const loadingInvestors = useSelector(
    isLoadingSelector([LOAD_INVESTORS_LIST.action])
  );
  const [investorList, setInvestorList] = useState([]);

  const getInvestorList = useCallback(() => {
    return investorList.filter(
      investor =>
        !counselors.some(counselor => counselor.investorId === investor.id)
    );
  }, [loadingInvestors, investorList]);

  const handleOuterSubmit = useCallback(
    values => {
      submit(values);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadInvestorsList(setInvestorList));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COUNSELORS.CREATE_COUNSELOR')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            setFieldValue,
            handleSubmit,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="stretch">
                  <InvestmentAlert
                    investors={investorList}
                    loading={loadingInvestors}
                  />
                  <Grid item>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={getInvestorList()}
                        id="investorId"
                        getOptionLabel={option => {
                          return option ? t(option.fullName) : '';
                        }}
                        value={
                          investorList.find(
                            investor => investor.id === values.investorId
                          ) ?? ''
                        }
                        keepMounted
                        isLoading={loadingInvestors}
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        onChange={(_e, value) => {
                          setFieldValue('investorId', value?.id ?? null);
                        }}
                        helperText={touched.investorId && t(errors.investorId)}
                        label={t('COMMON.INVESTOR')}
                        error={Boolean(touched.investorId && errors.investorId)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CreateCounselorsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default CreateCounselorsDialog;
