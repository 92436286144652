import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import {
  BackgroundValidationOwner,
  BackgroundValidationTabList
} from 'src/utils/constants/backgroundValidation';
import BackgroundValidationList from './BackgroundValidationList';

const BackgroundValidationTabs = () => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const { id, tab } = useParams();

  const [value, setValue] = useState(tab);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/identity/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (tab) {
      if (Object.values(BackgroundValidationOwner).includes(tab)) {
        setValue(tab);
      } else {
        push(`/identity/${BackgroundValidationOwner.CLIENT}`);
      }
    }
  }, [id, tab]);

  if (!value || !tab) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {BackgroundValidationTabList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              disabled={item.disabled}
            />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={BackgroundValidationOwner.CLIENT}>
        <BackgroundValidationList />
      </TabPanel>
      <TabPanel value={value} index={BackgroundValidationOwner.AVAL}>
        <BackgroundValidationList />
      </TabPanel>
      <TabPanel value={value} index={BackgroundValidationOwner.DISTRIBUTOR}>
        <BackgroundValidationList />
      </TabPanel>
      <TabPanel value={value} index={BackgroundValidationOwner.INVESTOR}>
        <BackgroundValidationList />
      </TabPanel>
    </Box>
  );
};

export default BackgroundValidationTabs;
