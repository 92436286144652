import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import FadeIn from 'react-fade-in';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Page from 'src/components/Page';
import { login } from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from 'src/components/Footer';
import { selectUserType } from 'src/utils/common';
import UserType from 'src/utils/constants/auth';
import theme from 'src/theme';
import validationSchema from './schema';
import makeIntiailValues from './initialState';
import useStyles from '../styles';

const LoginView = ({ userType }) => {
  const { t } = useTranslation();
  const { TITLE, SUBTITLE, REDIRECT_TEXT, REDIRECT_URL } = selectUserType(
    userType
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = makeIntiailValues();
  const resetRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const timeoutRef = useRef(null);

  const handleLogin = useCallback(
    ({ email, password, keepLogged }, actions) => {
      timeoutRef.current = setTimeout(() => {
        actions.setSubmitting(false);
      }, [200]);
      dispatch(login({ email, password, keepLogged, userType }));
    },
    [dispatch, timeoutRef, userType]
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  const trimWhitespaces = useCallback(
    setFieldValue => e => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
    []
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, userType]);

  return (
    <MainLayout>
      <Page
        className={classes.root}
        title={t('LOGIN_VIEW.TITLE')}
        sx={{
          padding: `${theme.spacing(4)} ${theme.spacing(10)}`,
          '@media (max-width: 1280px)': {
            padding: `${theme.spacing(4)} ${theme.spacing(3.5)}`
          }
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </Box>
          <Container
            className={classes.login}
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '75%'
              }
            }}
          >
            <FadeIn transitionDuration={200}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
                enableReinitialize
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                  handleChange,
                  resetForm
                }) => {
                  resetRef.current = () => {
                    resetForm(initialValues);
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        mb={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography
                          className={classes.title}
                          sx={{
                            fontSize: {
                              xs: theme.spacing(3.5),
                              sm: theme.spacing(4),
                              md: theme.spacing(5),
                              lg: theme.spacing(6)
                            }
                          }}
                        >
                          {t(`LOGIN_VIEW.${TITLE}`)}
                        </Typography>
                        <Typography
                          className={classes.subtitle}
                          sx={{
                            fontSize: {
                              xs: theme.spacing(1.5),
                              sm: theme.spacing(2),
                              md: theme.spacing(2),
                              lg: theme.spacing(2.5)
                            }
                          }}
                        >
                          {t(`LOGIN_VIEW.${SUBTITLE}`)}
                        </Typography>
                      </Box>
                      <Box className={classes.center}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email ? t(errors.email) : null}
                          label={t('COMMON.EMAIL')}
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={trimWhitespaces(setFieldValue)}
                          type="text"
                          value={values.email}
                          variant="outlined"
                        />
                        <TextField
                          fullWidth
                          error={Boolean(touched.password && errors.password)}
                          helperText={
                            touched.password ? t(errors.password) : null
                          }
                          label={t('LOGIN_VIEW.PASSWORD')}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={trimWhitespaces(setFieldValue)}
                          InputProps={{
                            autoComplete: 'off',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          variant="outlined"
                        />
                        <br />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="keepLogged"
                                color="primary"
                                value={values.keepLogged}
                                checked={values.keepLogged}
                                onChange={handleChange}
                              />
                            }
                            label={t('LOGIN_VIEW.KEEP_ME_LOGGED')}
                          />

                          {userType === UserType.DISTRIBUTOR && (
                            <Typography className={classes.recoverPasswordText}>
                              <Link to="/user/forgot-password">
                                {t('LOGIN_VIEW.RECOVER_PASSWORD')}
                              </Link>
                            </Typography>
                          )}
                          {userType === UserType.ADMIN && (
                            <Typography className={classes.recoverPasswordText}>
                              <Link to="/admin/forgot-password">
                                {t('LOGIN_VIEW.RECOVER_PASSWORD')}
                              </Link>
                            </Typography>
                          )}
                        </Box>
                        <Box
                          my={3}
                          className={classes.center}
                          sx={{
                            width: {
                              xs: '100%',
                              sm: '100%',
                              md: '75%'
                            }
                          }}
                        >
                          <Button
                            className={classes.button}
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            type="submit"
                            variant="contained"
                          >
                            {t('LOGIN_VIEW.LOGIN')}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </FadeIn>
          </Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography>
              {t(`LOGIN_VIEW.${REDIRECT_TEXT}`)}
              &nbsp;
              <Link to={REDIRECT_URL}>{t('LOGIN_VIEW.CLICK_HERE')}</Link>
            </Typography>
          </Box>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

LoginView.propTypes = {
  userType: PropTypes.string
};

export default LoginView;
