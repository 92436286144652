import {
  CLEAR_BUREAU_FORM_ID,
  CLEAR_CLIENT_VALIDATION,
  CREATE_BURO_FORM,
  CREATE_LEGAL_PERSON_BURO_FORM,
  GET_QUOTE_INFORMATION,
  GET_TOKEN_DATA,
  SEND_CLIENT_TO_VALIDATION,
  VALIDATE_CLIENT_BY_RFC
} from '../actions/bureauFormActions';

const initialState = {
  clientDetail: null,
  tokenData: null,
  formId: null,
  quoteDetail: null,
  formResult: null
};

const bureauFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_CLIENT_BY_RFC.success: {
      return {
        ...state,
        clientDetail: action.payload
      };
    }
    case CLEAR_CLIENT_VALIDATION: {
      return {
        ...state,
        clientDetail: null
      };
    }
    case GET_TOKEN_DATA: {
      return {
        ...state,
        tokenData: action.payload
      };
    }
    case CREATE_BURO_FORM.success: {
      return {
        ...state,
        formId: action.payload
      };
    }
    case CREATE_LEGAL_PERSON_BURO_FORM.success: {
      return {
        ...state,
        formId: action.payload
      };
    }

    case GET_QUOTE_INFORMATION.success: {
      return {
        ...state,
        quoteDetail: action.payload
      };
    }
    case SEND_CLIENT_TO_VALIDATION.success: {
      return {
        ...state,
        formResult: action.payload
      };
    }
    case CLEAR_BUREAU_FORM_ID: {
      return {
        ...state,
        formId: null
      };
    }

    default: {
      return state;
    }
  }
};

export default bureauFormReducer;
