import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectClientContractList } from 'src/store/selectors/clientSelectors';
import { getContractDescription } from 'src/utils/contracts';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { LOAD_CLIENT_CONTRACTS } from 'src/store/actions/clientActions';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepOne = ({ data, handleNext, resetRef }) => {
  const classes = useStyles();
  const initialState = useMemo(
    () =>
      makeInitialState({
        ...data
      }),
    [data]
  );
  const { t } = useTranslation();
  const contracts = useSelector(selectClientContractList);
  const isLoadingContract = useSelector(
    isLoadingSelector([LOAD_CLIENT_CONTRACTS.action])
  );

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext]
  );

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={values => {
          onNext(values);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.contract && errors.contract)}
                >
                  <Autocomplete
                    options={contracts}
                    isLoading={isLoadingContract}
                    id="contract"
                    name="contract"
                    label={t('COMMON.CONTRACTS')}
                    disabled={isLoadingContract}
                    getOptionLabel={option =>
                      option ? `${getContractDescription(option)}` : ''
                    }
                    value={values.client}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('contract', value ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    helperText={touched.contract && t(errors.contract)}
                    error={Boolean(touched.contract && errors.contract)}
                  />
                </FormControl>
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepOne;
