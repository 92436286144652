import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  CardHeader
} from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useStyles from './styles';

const DistributorUserInfo = ({ distributor }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('USER_PROFILE.MY_DISTRIBUTOR')}
          </Typography>
        }
      />
      <Table classes={{ root: classes.tableRoot }} component="div">
        <TableBody classes={{ root: classes.tableBody }} component="div">
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.NAME')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {distributor ? distributor.name : t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.COMERCIAL_NAME')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {distributor ? (
                  <Link to="/user/distributor">
                    {distributor.commercialName}
                  </Link>
                ) : (
                  t('COMMON.NO_DATA')
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell component="div" className={classes.GeneralData}>
              {t('COMMON.CITY')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {distributor && distributor.address
                  ? distributor.address.city
                  : t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow component="div" className={classes.tableRow}>
            <TableCell
              component="div"
              className={classes.GeneralData}
              sx={{ border: 'none' }}
            >
              {t('COMMON.STATE')}
            </TableCell>
            <TableCell component="div" sx={{ border: 'none' }}>
              <Typography variant="body2" color="textSecondary">
                {distributor && distributor.address
                  ? distributor.address.state
                  : t('COMMON.NO_DATA')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};
DistributorUserInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

DistributorUserInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default DistributorUserInfo;
