import React, { useCallback, useState } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import {
  createServiceAction,
  deleteServiceAction,
  editServiceAction
} from 'src/store/actions/catalogActions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ServiceList from './ServiceList';
import Toolbar from '../Toolbar';
import ServiceForm from './ServiceForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ServicesListView = () => {
  const classes  = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleOpenEdit = useCallback(
    values => {
      setOpen(true);
      setData(values);
    },
    [setOpen, setData]
  );

  const handleCreate = useCallback(
    values => {
      delete values.serviceId;
      dispatch(createServiceAction(values));
      handleClose();
    },
    [handleClose, dispatch]
  );

  const handleEdit = useCallback(
    values => {
      dispatch(editServiceAction(values));
      handleClose();
    },
    [handleClose, dispatch]
  );

  const handleDelete = useCallback(
    id => {
      dispatch(deleteServiceAction(id));
      handleClose();
    },
    [handleClose, dispatch]
  );

  return (
    <Page
      className={classes.root}
      title={t('NAVIGATION.CATALOG_LIST.SERVICES')}
    >
      <Container maxWidth={false}>
        <Toolbar
          catalogLabel={t('NAVIGATION.CATALOG_LIST.SERVICES')}
          handleOpen={handleOpen}
        />
        <Box mt={3}>
          <ServiceList
            handleOpenEdit={handleOpenEdit}
            handleDelete={handleDelete}
          />
        </Box>
      </Container>
      <ServiceForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Page>
  );
};

export default ServicesListView;
