import { Box, Button, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Preloader from 'src/components/Preloader';
import useStyles from '../styles';
import ReviewData from './ReviewData';

const FinalStep = ({
  data,
  handleSubmit,
  handleBack,
  loading,
  loadingRentAfterContribution
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isRentLessThanZero, setIsRentLessThanZero] = useState(false);

  const content = useMemo(() => {
    return !loading ? (
      <ReviewData values={data} setIsRentLessThanZero={setIsRentLessThanZero} />
    ) : (
      <Preloader />
    );
  }, [loading]);

  const disabledActions = useMemo(() => {
    return isRentLessThanZero || loadingRentAfterContribution || loading;
  }, [isRentLessThanZero, loadingRentAfterContribution, loading]);

  const handleOtherSubmit = e => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <>
      {loadingRentAfterContribution ? (
        <Preloader />
      ) : (
        <FadeIn>
          <form onSubmit={handleOtherSubmit}>
            <Divider light />
            {content}
            {isRentLessThanZero && (
              <Typography
                fontSize="0.8em"
                mt="0.6rem"
                color="#d32f2f"
                align="center"
              >
                {t('CONTRACT_VIEW.CONTRACT_DETAIL.RENT_LESS_THAN_ZERO')}
              </Typography>
            )}
            <Box
              className={classes.tableBox}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={handleBack}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={
                  disabledActions
                    ? classes.buttons
                    : clsx(classes.buttons, classes.buttonMain)
                }
                type="submit"
                color="primary"
                variant="outlined"
                disabled={disabledActions}
              >
                {t('COMMON.CREATE')}
              </Button>
            </Box>
          </form>
        </FadeIn>
      )}
    </>
  );
};
FinalStep.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingRentAfterContribution: PropTypes.bool.isRequired
};

export default FinalStep;
