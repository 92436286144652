import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { loadAvalBuroValidationHistory } from 'src/store/actions/clientActions';
import { selectAvalBuroValidationHistory } from 'src/store/selectors/clientSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CLEAR_AVAL_BURO_VALIDATION_HISTORY,
  LOAD_AVAL_BURO_VALIDATION_HISTORY
} from 'src/store/constants/clients';
import BuroValidationHistoryTable from './BuroValidationHistoryTable';

const BuroValidationHistoryList = () => {
  const dispatch = useDispatch();
  const { avalId } = useParams();
  const histories = useSelector(selectAvalBuroValidationHistory);
  const loadingHistories = useSelector(
    isLoadingSelector([LOAD_AVAL_BURO_VALIDATION_HISTORY.action])
  );

  useEffect(() => {
    dispatch(loadAvalBuroValidationHistory(avalId));
    return () => {
      dispatch({ type: CLEAR_AVAL_BURO_VALIDATION_HISTORY });
    };
  }, []);

  return (
    <BuroValidationHistoryTable
      loading={loadingHistories}
      histories={histories}
      key="buro-validation-history-table"
    />
  );
};

export default BuroValidationHistoryList;
