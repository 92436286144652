import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  Autocomplete,
  Switch,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  calculatePriceWhitIva,
  getDifferenceBetweenStates
} from 'src/utils/common';
import {
  LOAD_BRANDS_LIST,
  LOAD_MODELS,
  getModelsListAction,
  loadBrandsList
} from 'src/store/actions/catalogActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import { loadParameter } from 'src/store/actions/parameterActions';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import DialogTransition from 'src/components/DialogTransition';
import { Parameter } from 'src/utils/constants/parameters';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';
import BrandsAndModelsAlert from './BrandsAndModelsAlert';

const CatalogDialog = ({ open, handleClose, onSubmit, catalog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const percentageIva = useSelector(selectIndividualParameter);
  const initialState = makeInitialState({ ...catalog, iva: percentageIva });
  const resetRef = useRef();
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const loadingModels = useSelector(isLoadingSelector([LOAD_MODELS.action]));
  const loadingBrands = useSelector(
    isLoadingSelector([LOAD_BRANDS_LIST.action])
  );
  const [hasLoadedBrands, setHasLoadedBrands] = useState(false);
  const [hasLoadedModels, setHasLoadedModels] = useState(false);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (catalog) {
        const difference = getDifferenceBetweenStates(initialState, values);
        if (Object.entries(difference).length === 0) {
          handleClose();
          return;
        }
        onSubmit({
          ...difference
        });
      } else {
        onSubmit({
          ...values
        });
      }

      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  const brandErrorText = useCallback(
    (touched, errors) => {
      if (touched.brand && errors.brand) {
        return t(errors.brand);
      }
      if (!loadingBrands && !brands.length && hasLoadedBrands) {
        return t('DISTRIBUTORS_VIEW.NO_BRANDS');
      }
      return '';
    },
    [brands, t]
  );

  const modelErrorText = useCallback(
    (touched, errors) => {
      if (touched.model && errors.model) {
        return t(errors.model);
      }
      if (!models.length && hasLoadedModels) {
        return t('DISTRIBUTORS_VIEW.NO_MODEL_FOR_THIS_BRAND');
      }
      return '';
    },
    [models, t]
  );

  const handleBrandChange = useCallback((value, setFieldValue) => {
    setFieldValue('brand', value ?? '');
    if (!value) {
      setModels([]);
      return;
    }
    setFieldValue('model', '');
    dispatch(getModelsListAction(value.id, setModels));
    setHasLoadedModels(true);
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(loadParameter(Parameter.IVA));
      dispatch(loadBrandsList(setBrands));
      if (catalog && catalog.brand) {
        dispatch(getModelsListAction(catalog.brand.id, setModels));
      }
      setHasLoadedBrands(true);
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
      enableReinitialize
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {catalog
            ? t('DISTRIBUTORS_VIEW.EDIT_CATALOG')
            : t('DISTRIBUTORS_VIEW.CREATE_CATALOG')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 3 }}>
                  <BrandsAndModelsAlert
                    brands={brands}
                    models={models}
                    loading={loadingBrands || loadingModels}
                    selectedBrand={values.brand}
                  />
                </Box>
                <FormControl
                  fullWidth
                  error={Boolean(touched.brand && errors.brand)}
                >
                  <Autocomplete
                    options={brands}
                    loading={loadingBrands}
                    id="brand"
                    getOptionLabel={option => `${option.name}`}
                    value={
                      brands.find(brand => brand.name === values.brand?.name) ??
                      null
                    }
                    fullWidth
                    noOptionsText={t('DISTRIBUTORS_VIEW.NO_BRANDS')}
                    onChange={(_e, value) =>
                      handleBrandChange(value, setFieldValue)
                    }
                    error={!!brandErrorText(touched, errors)}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        label={t('COMMON.BRAND')}
                        type="text"
                        FormHelperTextProps={{ sx: { color: 'red' } }}
                        helperText={brandErrorText(touched, errors)}
                        error={!!brandErrorText(touched, errors)}
                      />
                    )}
                  />
                </FormControl>

                <Autocomplete
                  options={models}
                  loading={loadingModels}
                  key={values.model?.name}
                  id="models"
                  noOptionsText={t('DISTRIBUTORS_VIEW.NO_MODEL_FOR_THIS_BRAND')}
                  getOptionLabel={option => `${option.name}`}
                  fullWidth
                  value={
                    models.find(model => model.name === values.model) ?? null
                  }
                  onChange={(_e, value) => {
                    setFieldValue('model', value ? `${value.name}` : '');
                  }}
                  sx={{ marginBottom: 1, marginTop: 1 }}
                  error={!!modelErrorText(touched, errors)}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      name="model"
                      label={t('COMMON.MODEL')}
                      helperText={modelErrorText(touched, errors)}
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      error={!!modelErrorText(touched, errors)}
                    />
                  )}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && t(errors.price)}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.price}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  label={`${t('COMMON.IVA')} %`}
                  margin="normal"
                  name="iva"
                  disabled
                  type="number"
                  value={formatAmountWithDecimals(values.iva)}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  disabled
                  fullWidth
                  label={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
                  margin="normal"
                  name="priceWhitIva"
                  onBlur={handleBlur}
                  value={calculatePriceWhitIva(values.price, values.iva)}
                  variant="outlined"
                />

                <FormControl>
                  <Typography>
                    {t('DISTRIBUTORS_VIEW.EQUIPMENT_IS_MOBILE')}
                  </Typography>
                  <Switch
                    checked={values.isMovable}
                    onChange={(_e, value) => {
                      setFieldValue('isMovable', value);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {catalog ? t('COMMON.EDIT') : t('COMMON.CREATE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CatalogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  catalog: PropTypes.object
};

export default CatalogDialog;
