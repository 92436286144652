import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';

const DeleteInsuranceDialog = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      keepMounted
      style={{ marginBottom: '16px' }}
    >
      <DialogTitle className={classes.header} id="form-insurance-delete">
        <Typography className={classes.header} color="primary" variant="h2">
          {t('INSURANCES_VIEW.DELETE_INSURANCE')}
        </Typography>
      </DialogTitle>
      <DialogContentText
        className={classes.confirmation}
        id="form-insurance-delete-dialog"
      >
        {t('INSURANCES_VIEW.DELETE_DISCLAIMER')}
      </DialogContentText>

      <Grid
        container
        style={{ marginBottom: '16px' }}
        direction="column"
        alignItems="stretch"
      >
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.label, classes.buttons)}
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
          >
            {t('COMMON.ACCEPT')}
          </Button>
        </Grid>
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.outlined, classes.buttons)}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            {t('COMMON.CANCEL')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

DeleteInsuranceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default DeleteInsuranceDialog;
