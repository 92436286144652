import {
  CLEAR_PARAMETERS,
  LOAD_PARAMETER,
  LOAD_PARAMETERS,
  SAVE_OPTIONS,
  CLEAR_PARAMTER
} from '../actions/parameterActions';

const initialState = {
  items: [],
  options: {},
  parameter: null
};

const parameterReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARAMETERS.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case CLEAR_PARAMETERS: {
      return initialState;
    }
    case SAVE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case LOAD_PARAMETER.success: {
      return {
        ...state,
        parameter: action.data
      };
    }
    case CLEAR_PARAMTER: {
      return {
        ...state,
        parameter: null
      };
    }
    default: {
      return state;
    }
  }
};

export default parameterReducer;
