import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  createAdminUser,
  getAdminUsers,
  resendAdministratorValidationEmail,
  updateAdminUser
} from 'src/services/administratorsService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { removePointsPhone } from 'src/utils/common';
import { selectAdministratorsQueryOptions } from '../selectors/administratorSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_ADMINISTATORS = asyncActionCreator('LOAD_ADMINISTATORS');
export const CREATE_ADMINISTRATOR = asyncActionCreator('CREATE_ADMINISTRATOR');
export const UPDATE_ADMINISTRATOR = asyncActionCreator('UPDATE_ADMINISTRATOR');
export const DELETE_ADMINISTRATOR = asyncActionCreator('DELETE_ADMINISTRATOR');
export const RESEND_ADMINISTRATOR_VALIDATION_EMAIL = asyncActionCreator(
  'RESEND_ADMINISTRATOR_VALIDATION_EMAIL'
);
export const CLEAR_ADMINISTRATORS = 'CLEAR_ADMINISTRATORS';
export const SAVE_ADMINISTRATORS_OPTIONS = 'SAVE_ADMINISTRATORS_OPTIONS';
export const CLEAR_ADMINISTRATORS_OPTIONS = 'CLEAR_ADMINISTRATORS_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectAdministratorsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadAdministrators = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_ADMINISTATORS.start });
    dispatch({
      type: SAVE_ADMINISTRATORS_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);
    const users = await getAdminUsers(reqOptions);
    dispatch({
      type: LOAD_ADMINISTATORS.success,
      ...users
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );

    dispatch({ type: LOAD_ADMINISTATORS.failure });
  }
};

export const createAdministrator = (
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_ADMINISTRATOR.start });
    const phone = removePointsPhone(values.phone);

    await createAdminUser({ ...values, phone });

    dispatch(
      showMessage({
        message: 'CREATE_ADMINISTRATOR',
        variant: 'success'
      })
    );
    closeCallback();
    dispatch({ type: CREATE_ADMINISTRATOR.success });
    return dispatch(loadAdministrators());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_ADMINISTRATOR.failure });
  }
};

export const updateAdministrator = (
  id,
  values,
  handleClose
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_ADMINISTRATOR.start });
    let phone = '';

    if (values.phone) {
      phone = removePointsPhone(values.phone);
    }

    await updateAdminUser({
      id,
      values: {
        ...values,
        phone
      }
    });
    dispatch(
      showMessage({
        message: 'UPDATE_ADMINISTRATOR',
        variant: 'success'
      })
    );
    handleClose();
    dispatch({ type: UPDATE_ADMINISTRATOR.success });
    return dispatch(loadAdministrators());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_ADMINISTRATOR.failure });
  }
};

export const resendValidationEmail = id => async dispatch => {
  try {
    dispatch({ type: RESEND_ADMINISTRATOR_VALIDATION_EMAIL.start });
    await resendAdministratorValidationEmail(id);
    dispatch(
      showMessage({
        message: 'RESEND_VALIDATION_EMAIL',
        variant: 'success'
      })
    );
    dispatch({ type: RESEND_ADMINISTRATOR_VALIDATION_EMAIL.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch({ type: RESEND_ADMINISTRATOR_VALIDATION_EMAIL.failure });
  }
};
