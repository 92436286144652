import * as Yup from 'yup';

export default Yup.object().shape({
  distributor: Yup.string().required(
    'SCHEMA_ERRORS.EQUIPMENT_FORM.DISTRIBUTOR_REQUIRED'
  ),
  distributorEquipment: Yup.mixed().required(
    'SCHEMA_ERRORS.EQUIPMENT_REQUIRED'
  ),
  moreInfo: Yup.string(),
  price: Yup.number()
    .min(1, 'SCHEMA_ERRORS.PRICE_GREATER_THAN_ZERO')
    .nullable(),
  iva: Yup.number()
    .min(1, 'SCHEMA_ERRORS.IVA_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.EQUIPMENT_FORM.IVA_LESS_THAN_ONE')
    .nullable()
});
