import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, IconButton, Box, Tooltip } from '@mui/material';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import GeneralView from './General';
import ContractView from './Contract';
import useStyles from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function EquipmentTabs({ equipment }) {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  const selectTabStatus = (label, error) => {
    if (error) {
      return (
        <Tooltip title={t('COMMON.NO_INFORMATION')}>
          <span>{label}</span>
        </Tooltip>
      );
    }
    return <span>{label}</span>;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.EQUIPMENT']}
        current={equipment.id}
      />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.GENERAL')} className={classes.tab} />
          <Tab
            label={selectTabStatus(t('COMMON.CONTRACT'), !equipment.contract)}
            disabled={!equipment.contract}
            className={classes.tab}
          />
          <Tab
            label={t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.CLIENTS_HISTORY')}
            disabled
            className={classes.tab}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView equipment={equipment} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContractView equipment={equipment} />
      </TabPanel>
    </Box>
  );
}

EquipmentTabs.propTypes = {
  equipment: PropTypes.object.isRequired
};
