// eslint-disable-next-line import/prefer-default-export
export const nufiValidationFields = {
  CURP: 'curp',
  RFC: 'rfc',
  SIGER: 'siger',
  RUG: 'rug',
  OFAC: 'ofac',
  SAT69: 'sat69',
  PROFESSIONAL_CARD: 'professionalCard',
  BLACKLIST: 'blackList',
  INTERPOL: 'interpol',
  CRIMINAL_RECORDS: 'criminalRecords',
  INE: 'ine'
};
