import React, { useEffect, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_CLIENT_DISTRIBUTOR_AGENT,
  getDistributorProfileByClient
} from 'src/store/actions/distributorActions';
import { CLEAR_KAM_DETAIL } from 'src/store/actions/userKamActions';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const KamAutocomplete = ({
  isLoading,
  options,
  onChange,
  value,
  label,
  error,
  helperText,
  onBlur,
  name,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoadingKam = useSelector(
    isLoadingSelector([LOAD_CLIENT_DISTRIBUTOR_AGENT.action])
  );

  const loading = isLoading || isLoadingKam;

  const { values, setFieldValue } = useFormikContext();

  const [kamFieldDisabled, setKamFieldDisabled] = useState(
    !values.distributor ||
    !values.client ||
    isLoadingKam ||
    isLoading
  );

  const setDistributorProfile = distributorProfile => {
    setFieldValue('distributorProfile', distributorProfile);
  };

  useEffect(() => {
    dispatch({ type: CLEAR_KAM_DETAIL });
    if (values.client && values.distributor) {
      dispatch(
        getDistributorProfileByClient(
          values.distributor.id,
          values.client.id,
          setDistributorProfile,
          setKamFieldDisabled,
        )
      );
    }
  }, [values.client, values.distributor]);

  return (
    <>
      <Autocomplete
        id="kam-autocomplete"
        options={options}
        getOptionLabel={option =>
          option ? `${option.id} - ${option.name}` : ''
        }
        value={value}
        onChange={onChange}
        loading={loading}
        disabled={kamFieldDisabled}
        isOptionEqualToValue={(option, val) =>
          option.id === val.id || val === ''
        }
        renderInput={params => (
          <TextField
            InputProps={{
              autoComplete: 'off'
            }}
            {...params}
            label={loading ? `${t(`COMMON.LOADING`)} ${label}` : label}
            error={error}
            helperText={helperText}
            onBlur={onBlur}
            name={name}
            {...rest}
          />
        )}
      />
    </>
  );
};

export default KamAutocomplete;

KamAutocomplete.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  distributor: PropTypes.object,
  client: PropTypes.object
};
