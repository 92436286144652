import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn } from 'formik';
import {
  Box,
  Button,
  TextField
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  LOAD_ADDRESS_BY_ZIP_CODE,
  getAddressByZipCode
} from 'src/store/actions/clientActions';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { hasAddressCompleted } from 'src/utils/common';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepFour = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialState = useMemo(() => {
    return makeInitialState(data);
  }, [data]);
  const [addressData, setAddressData] = useState({
    cities: [],
    colonias: [],
    states: []
  });
  const isLoadingAddress = useSelector(
    isLoadingSelector([LOAD_ADDRESS_BY_ZIP_CODE.action])
  );

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  const handleZipCodeChange = useCallback((e, setFieldValue) => {
    const { value } = e.target;
    if (value.length === 5) {
      dispatch(getAddressByZipCode(value, setAddressData));
    }
    setFieldValue('equipmentLocation.colonia', '');
    setFieldValue('equipmentLocation.city', '');
    setFieldValue('equipmentLocation.state', '');
  });

  useEffect(() => {
    if (hasAddressCompleted(data.equipmentLocation) && data.isExistingAddress) {
      dispatch(
        getAddressByZipCode(data.equipmentLocation.zipCode, setAddressData)
      );
      onNext(data);
    }
    if (data.equipmentLocation.zipCode && !data.isExistingAddress) {
      dispatch(
        getAddressByZipCode(data.equipmentLocation.zipCode, setAddressData)
      );
    }
  }, []);

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <TextField
                InputProps={{
                  autoComplete: 'off'
                }}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.street') &&
                    getIn(errors, 'equipmentLocation.street')
                )}
                fullWidth
                helperText={
                  getIn(touched, 'equipmentLocation.street') &&
                  t(getIn(errors, 'equipmentLocation.street'))
                }
                label={t('COMMON.STREET')}
                margin="normal"
                name="equipmentLocation.street"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.equipmentLocation.street}
                variant="outlined"
              />

              <TextField
                InputProps={{
                  autoComplete: 'off'
                }}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.externalHomeNumber') &&
                    getIn(errors, 'equipmentLocation.externalHomeNumber')
                )}
                helperText={
                  getIn(touched, 'equipmentLocation.externalHomeNumber') &&
                  t(getIn(errors, 'equipmentLocation.externalHomeNumber'))
                }
                fullWidth
                label={t('COMMON.EXTERNAL_HOME_NUMBER')}
                margin="normal"
                name="equipmentLocation.externalHomeNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.equipmentLocation.externalHomeNumber}
                variant="outlined"
              />

              <TextField
                InputProps={{
                  autoComplete: 'off'
                }}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.insideHomeNumber') &&
                    getIn(errors, 'equipmentLocation.insideHomeNumber')
                )}
                helperText={
                  getIn(touched, 'equipmentLocation.insideHomeNumber') &&
                  t(getIn(errors, 'equipmentLocation.insideHomeNumber'))
                }
                fullWidth
                label={t('COMMON.INSIDE_HOME_NUMBER')}
                margin="normal"
                name="equipmentLocation.insideHomeNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.equipmentLocation.insideHomeNumber}
                variant="outlined"
              />

              <TextField
                InputProps={{
                  autoComplete: 'off'
                }}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.zipCode') &&
                    getIn(errors, 'equipmentLocation.zipCode')
                )}
                helperText={
                  getIn(touched, 'equipmentLocation.zipCode') &&
                  t(getIn(errors, 'equipmentLocation.zipCode'))
                }
                fullWidth
                label={t('COMMON.ZIP_CODE')}
                margin="normal"
                name="equipmentLocation.zipCode"
                onBlur={handleBlur}
                onChange={e => {
                  handleZipCodeChange(e, setFieldValue);
                  handleChange(e);
                }}
                type="text"
                value={values.equipmentLocation.zipCode}
                variant="outlined"
              />

              <DropdownMenu
                list={addressData.cities.map(city => ({
                  key: city,
                  name: city
                }))}
                value={values.equipmentLocation.city}
                placeholder={values.equipmentLocation.city}
                setValue={value =>
                  setFieldValue('equipmentLocation.city', value)
                }
                label={t('COMMON.CITY')}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.city') &&
                    getIn(errors, 'equipmentLocation.city')
                )}
                errorText={
                  getIn(touched, 'equipmentLocation.city') &&
                  t(getIn(errors, 'equipmentLocation.city'))
                }
                isLoading={isLoadingAddress}
              />

              <DropdownMenu
                list={addressData.colonias.map(col => ({
                  key: col,
                  name: col
                }))}
                value={values.equipmentLocation.colonia}
                setValue={value =>
                  setFieldValue('equipmentLocation.colonia', value)
                }
                label={t('COMMON.COLONIA')}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.colonia') &&
                    getIn(errors, 'equipmentLocation.colonia')
                )}
                errorText={
                  getIn(touched, 'equipmentLocation.colonia') &&
                  t(getIn(errors, 'equipmentLocation.colonia'))
                }
                isLoading={isLoadingAddress}
              />

              <DropdownMenu
                list={addressData.states.map(state => ({
                  key: state,
                  name: state
                }))}
                value={values.equipmentLocation.state}
                setValue={value =>
                  setFieldValue('equipmentLocation.state', value)
                }
                label={t('COMMON.STATE')}
                error={Boolean(
                  getIn(touched, 'equipmentLocation.state') &&
                    getIn(errors, 'equipmentLocation.state')
                )}
                errorText={
                  getIn(touched, 'equipmentLocation.state') &&
                  t(getIn(errors, 'equipmentLocation.state'))
                }
                isLoading={isLoadingAddress}
              />
            </Box>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>

              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepFour.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
  // existingAddress: PropTypes.object
};

export default StepFour;
