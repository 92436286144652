import React from 'react';
import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%',
    minHeight: '77px'
  }
}));

const DetailRow = ({ name, value, children, ...props }) => {
  const classes = useStyles();

  return (
    <TableRow {...props}>
      <TableCell className={classes.tableCell}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          flexWrap="nowrap"
        >
          <Grid item direction="column" alignItems="flex-start">
            <Box className={classes.title}>{name}</Box>
            <Box
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  marginRight: 1
                }}
              >
                {value}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {children}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

DetailRow.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node
};

export default DetailRow;
