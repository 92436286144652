import * as Yup from 'yup';

export default Yup.object().shape({
  price: Yup.number()
    .typeError('SCHEMA_ERRORS.VALUE_REQUIRED')
    .min(1, 'SCHEMA_ERRORS.COMMON.IVA_GREATER_THAN_ZERO')
    .required('SCHEMA_ERRORS.EQUIPMENT_FORM.PRICE_REQUIRED'),
  iva: Yup.number()
    .typeError('SCHEMA_ERRORS.VALUE_REQUIRED')
    .min(1, 'SCHEMA_ERRORS.COMMON.IVA_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.EQUIPMENT_FORM.IVA_LESS_THAN_ONE')
    .required('SCHEMA_ERRORS.EQUIPMENT_FORM.IVA_REQUIRED'),
  invoicePdfUrl: Yup.mixed(),
  invoiceXmlUrl: Yup.mixed()
});
