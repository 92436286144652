import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import InsurancesListView from './Insurances';

const InsurancesView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/list`} component={InsurancesListView} />
    </>
  );
};

InsurancesView.propTypes = {
  match: PropTypes.object.isRequired
};

export default InsurancesView;
