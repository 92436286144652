import moment from 'moment-timezone';
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllCFDIs = serviceBase({
  request: options =>
    axiosClient.get('/invoice', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const cancelCfdi = serviceBase({
  request: ({ id, motive }) =>
    axiosClient.post(`/invoice/${id}/cancel`, { motive })
});

export const downloadExportedCFDIIssued = serviceBase({
  request: options =>
    axiosClient.get('/invoice/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const date = moment(
        `${res.config.params.year}-${res.config.params.month}-01`
      ).format('MMMM-YYYY');
      const filename = `CFDI Emitidos - ${date}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const loadCFDIsIncome = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-income-resume', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const downloadExportedCFDIIncome = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-income-resume/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `CFDI Ingresos - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const loadCFDIsExpense = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-expense-resume', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const loadCFDIsPayment = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-payment-resume', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const downloadExportedCFDIExpense = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-expense-resume/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `CFDI Egreso - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const downloadExportedCFDIPayment = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-payment-resume/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `CFDI Pagos - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const sendInvoiceEmailToClient = serviceBase({
  request: ({ id, emailList }) =>
    axiosClient.post(`/invoice/send-invoice-to-client/${id}`, {
      emailList
    })
});

export const sendInvoiceToClientWhatsapp = serviceBase({
  request: ({ id, phones }) =>
    axiosClient.post(`/invoice/send/whatsapp/${id}`, {
      phones,
    })
});

export const getWithholdings = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-withholding-resume', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const exportWithholdings = serviceBase({
  request: options =>
    axiosClient.get('/invoice/tax-accounting-withholding-resume/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `Retenciones - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const getCFDIReceived = serviceBase({
  request: options =>
    axiosClient.get('/invoice', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const downloadExportedCFDIReceived = serviceBase({
  request: options =>
    axiosClient.get('/invoice/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `CFDI Recibidos.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const downloadCFDIsReceivedInZip = serviceBase({
  request: options =>
    axiosClient.get('/invoices/received/export_zip', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `CFDI Emitido - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.zip`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const downloadInvoices = serviceBase({
  request: options =>
    axiosClient.get('/invoice/export/zip', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `Facturas - ${moment(
        `${res.config.params.month}/01/${res.config.params.year}`
      ).format('MMMM-YYYY')}.zip`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const getPendingPaymentIncomeCDFIs = serviceBase({
  request: contractId =>
    axiosClient.get(`/invoice/contract/${contractId}/pending-payment`)
});
