import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectAdministratorsCount,
  selectAdministratorsList,
  selectAdministratorsQueryOptions
} from 'src/store/selectors/administratorSelectors';
import {
  LOAD_ADMINISTATORS,
  loadAdministrators
} from 'src/store/actions/administratorsActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import AdministratorsTable from './AdministratorsTable';

const AdministratorsList = () => {
  const dispatch = useDispatch();
  const administrators = useSelector(selectAdministratorsList);
  const count = useSelector(selectAdministratorsCount);
  const { page } = useSelector(selectAdministratorsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_ADMINISTATORS.action]));
  const success = useSelector(successSelector([LOAD_ADMINISTATORS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadAdministrators({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.ADMINISTRATORS_TITLE" />
      </Card>
    );
  }

  return (
    <AdministratorsTable
      administrators={administrators}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default AdministratorsList;
