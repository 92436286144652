import { Card } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  CLEAR_BILLING_OPTIONS,
  LOAD_BILLING_FAILS_LIST,
  loadBillingFailures
} from 'src/store/actions/billingActions';
import {
  selectBillingFailuresCount,
  selectBillingFailuresList,
  selectBillingsQueryOptions
} from 'src/store/selectors/billingSelector';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import BillingFailuresTable from './BillingFailuresTable';
import useStyles from './styles';

const BillingFailuresList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const billingFailures = useSelector(selectBillingFailuresList);
  const count = useSelector(selectBillingFailuresCount);
  const { page } = useSelector(selectBillingsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_BILLING_FAILS_LIST.action])
  );
  const success = useSelector(
    successSelector([LOAD_BILLING_FAILS_LIST.action])
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadBillingFailures({ page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadBillingFailures());
    return () => {
      dispatch({ type: CLEAR_BILLING_OPTIONS });
    };
  }, []);

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.BILLING_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.BILLING_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <BillingFailuresTable
      billingFailures={billingFailures}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default BillingFailuresList;
