import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  IconButton,
  Button,
  Grid,
  FormHelperText,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import PhoneInput from 'src/components/PhoneInput';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';

const CreateAgentialog = ({ open, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(), []);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    values => {
      submit(values);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('AGENTS.CREATE_AGENT')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && t(errors.name)}
                      label={t('COMMON.NAME')}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.paternalName && errors.paternalName
                      )}
                      fullWidth
                      helperText={
                        touched.paternalName && t(errors.paternalName)
                      }
                      label={t('COMMON.PATERNAL_NAME')}
                      margin="normal"
                      name="paternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.paternalName}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.maternalName && errors.maternalName
                      )}
                      fullWidth
                      helperText={
                        touched.maternalName && t(errors.maternalName)
                      }
                      label={t('COMMON.MATERNAL_NAME')}
                      margin="normal"
                      name="maternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.maternalName}
                      variant="outlined"
                    />

                    <FormControl fullWidth margin="normal">
                      <PhoneInput
                        id="phone-label"
                        name="phone"
                        label={t('COMMON.MOBILE_PHONE')}
                        value={values.phone}
                        error={Boolean(touched.phone && errors.phone)}
                        onBlur={handleBlur}
                        onChange={setFieldValue}
                      />
                      {Boolean(touched.phone && errors.phone) && (
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {t(errors.phone)}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && t(errors.email)}
                      label={t('COMMON.EMAIL')}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CreateAgentialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default CreateAgentialog;
