import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ParameterTable from './ParameterTable';

const ParameterList = ({ handleOpenEdit, parameters, loading }) => {
  return (
    <Box mt={2}>
      <ParameterTable
        parameters={parameters}
        loading={loading}
        handleOpenEdit={handleOpenEdit}
      />
    </Box>
  );
};

ParameterList.propTypes = {
  handleOpenEdit: PropTypes.func,
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default ParameterList;
