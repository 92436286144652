import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';

const ContractConfirmationDialog = ({
  open,
  handleClose,
  handleSubmit,
  isFirstRentWithInitialPayment
}) => {
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  const handleSubmitForm = () => {
    handleSubmit();
    handleClose();
  };
  return (
    <ConfirmationDialog
      open={open}
      onSubmit={handleSubmitForm}
      handleClose={handleClose}
      key="contract-confirmation"
      title={t('CONTRACT_VIEW.ON_ACCEPT')}
      width={100}
    >
      <Box ml={2}>
        <List sx={{ listStyleType: 'decimal', textAlign: 'justify' }}>
          <ListItem
            sx={{
              display: 'list-item',
              color: 'primary.main',
              fontSize: 18
            }}
          >
            {t('CONTRACT_VIEW.CONTRACT_DOCUMENT_WILL_BE_GENERATED')}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              color: 'primary.main',
              fontSize: 18
            }}
          >
            {t('CONTRACT_VIEW.CONTRACT_WILL_BE_INACTIVE_UNTIL_SIGN')}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              color: 'primary.main',
              fontSize: 18
            }}
          >
            {t('CONTRACT_VIEW.CONTRACT_CAN_BE_SEND_FOR_SIGNING')}
          </ListItem>
          {isFirstRentWithInitialPayment && (
            <ListItem
              sx={{
                display: 'list-item',
                color: 'primary.main',
                fontSize: 18
              }}
            >
              {t(
                'CONTRACT_VIEW.FIRST_RENT_WILL_BE_BILLED_WITH_CONTRACT_SIGNING'
              )}
            </ListItem>
          )}
        </List>
      </Box>
      <Typography variant="h6" width="100%" textAlign="center" mb={-2}>
        {t(
          'CONTRACT_VIEW.REMEMBER_TO_CONFIRM_WITH_YOUR_CLIENT_BEFORE_CONTINUE'
        )}
      </Typography>
    </ConfirmationDialog>
  );
};

export default ContractConfirmationDialog;

ContractConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFirstRentWithInitialPayment: PropTypes.bool.isRequired
};
