/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { useTranslation } from 'react-i18next';
import { getEquipmentDescription } from 'src/utils/equipments';

const ContractsRow = ({
  id,
  equipment,
  firstRentDate,
  endDate,
  rent,
  folio
}) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/contracts/${id}`}>{id}</Link>
      </TableCell>
      <TableCell align="left">
        {equipment ? (
          <Link to={`/inventory/${equipment.id}`}>
            {getEquipmentDescription(equipment)}
          </Link>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="center">{folio ?? t('COMMON.NO_DATA')}</TableCell>
      <TableCell align="center">
        {selectFormatDate(firstRentDate, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(endDate, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(rent)}</TableCell>
    </TableRow>
  );
};

ContractsRow.propTypes = {
  id: PropTypes.number.isRequired,
  equipment: PropTypes.object.isRequired,
  firstRentDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  rent: PropTypes.number.isRequired,
  folio: PropTypes.string
};

export default ContractsRow;
