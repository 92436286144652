import React from 'react';
import { Box, Grid } from '@mui/material';
import CatalogsDetail from 'src/views/business/DistributorDetail/CatalogsDetail/CatalogsDetail';
import { useSelector } from 'react-redux';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';

const CatalogsView = () => {
  const role = useSelector(selectAuthRole);
  const disabledActions = role !== DistributorRoles.DIRECTOR;
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CatalogsDetail isDisableActions={disabledActions} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CatalogsView;
