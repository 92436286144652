import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { LOAD_AGENT_LIST, loadAllAgents } from 'src/store/actions/agentActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { UPDATE_INVESTOR } from 'src/store/actions/investorActions';
import Loading from '../Loading';
import Autocomplete from '../Autocomplete/Autocomplete';
import makeInitialValues from './initialState';
import DialogTransition from '../DialogTransition';
import validation from './schema';

const AgentAssignationDialog = ({ open, handleClose, onSubmit, agent }) => {
  const dispatch = useDispatch();
  const initialValues = makeInitialValues(agent);
  const [agents, setAgents] = useState([]);
  const { t } = useTranslation();
  const loading = useSelector(isLoadingSelector([LOAD_AGENT_LIST.action]));
  const loadingAssign = useSelector(
    isLoadingSelector([UPDATE_INVESTOR.action])
  );
  const [isInitialValue, setIsInitialValue] = useState(true);

  const handleChange = (value, setFieldValue) => {
    if (value && value.id === 0) {
      setFieldValue('agentId', null);
    } else {
      setFieldValue('agentId', value ? value.id : '');
    }
    if ((value && value.id !== initialValues.agentId) || !value) {
      setIsInitialValue(false);
    } else {
      setIsInitialValue(true);
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(loadAllAgents(setAgents));
    }
  }, [open]);

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '30fr',
          maxHeight: '70vh'
        }
      }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle>
        <DialogTitle
          sx={{
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            '& h2': {
              fontWeight: 400
            }
          }}
          id="form-documents-dialog-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            sx={{
              float: 'right',
              width: '20px',
              height: '20px'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="primary" variant="h2">
            {t('AGENTS.AGENT_ASSIGNATION')}
          </Typography>
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        {loading || loadingAssign ? (
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loading />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
            enableReinitialize
          >
            {({
              values,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
              touched
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  <FormControl fullWidth>
                    <Autocomplete
                      options={[
                        { fullName: t('COMMON.NO_AGENT'), id: 0 },
                        ...agents
                      ]}
                      id="agentId"
                      label={t('NAVIGATION.AGENT')}
                      getOptionLabel={option =>
                        option.name
                          ? `${option.fullName}`
                          : t('COMMON.NO_AGENT')
                      }
                      value={agents.find(a => a.id === values.agentId) || ''}
                      fullWidth
                      clearIcon={<CloseIcon />}
                      onChange={(_e, value) => {
                        handleChange(value, setFieldValue);
                      }}
                      sx={{ marginBottom: 1, marginTop: 2 }}
                      helperText={touched.agentId && t(errors.agentId)}
                      error={Boolean(touched.agentId && errors.agentId)}
                    />
                  </FormControl>

                  <Button
                    disabled={isSubmitting || isInitialValue}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: theme.spacing(2)
                    }}
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

AgentAssignationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  agent: PropTypes.string
};
export default AgentAssignationDialog;
