import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';

const ResumeRow = ({
  date,
  quotesInProgress,
  quotesInReview,
  quotesAccepted,
  quotesRejected,
  quotesArchived,
  quotesInDraft
}) => {
  return (
    <TableRow hover>
      <TableCell>{selectFormatDate(date, dateFormatType.MONTH)}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(quotesInProgress) ?? 0}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(quotesInReview) ?? 0}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(quotesInDraft) ?? 0}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(quotesAccepted) ?? 0}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(quotesRejected) ?? 0}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(quotesArchived) ?? 0}
      </TableCell>
    </TableRow>
  );
};

ResumeRow.propTypes = {
  date: PropTypes.number.isRequired,
  quotesInProgress: PropTypes.string.isRequired,
  quotesInReview: PropTypes.object.isRequired,
  quotesAccepted: PropTypes.object.isRequired,
  quotesRejected: PropTypes.object.isRequired,
  quotesArchived: PropTypes.object.isRequired,
  quotesInDraft: PropTypes.object.isRequired
};

export default ResumeRow;
