import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  personType: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  email: Yup.string().test(
    'email',
    'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID',
    validateEmail
  ),
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  paternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.PATERNAL_NAME_REQUIRED')
  }),
  maternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.MATERNAL_NAME_REQUIRED')
  })
});
