import { BankAccountType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  bankName: Yup.string(),
  bankAccountType: Yup.string(),
  bankAccount: Yup.string()
    .when('bankAccountType', {
      is: BankAccountType.SANTANDER_CHECKS,
      then: Yup.string()
        .length(11, 'SCHEMA_ERRORS.INVALID_FORMAT_SANTANDER_CHECKS')
        .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    })
    .when('bankAccountType', {
      is: BankAccountType.DEBIT_CARD,
      then: Yup.string()
        .length(16, 'SCHEMA_ERRORS.INVALID_FORMAT_DEBIT_CARD')
        .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    })
    .when('bankAccountType', {
      is: BankAccountType.CLABE,
      then: Yup.string()
        .length(18, 'SCHEMA_ERRORS.INVALID_FORMAT_CLABE')
        .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    })
});
