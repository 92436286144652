import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getIncomeResume = serviceBase({
         request: options =>
           axiosClient.get('/accounting/income-resume', {
             params: options,
             paramsSerializer: params => {
               return qs.stringify(params, { arrayFormat: 'repeat' });
             }
           }),
         retry: 0
       });

export const downloadExportedIncomeResume = serviceBase({
         request: options =>
           axiosClient.get('/accounting/income-resume/export', {
             responseType: 'blob',
             params: options
           }),
         transformResponse: res => {
           try {
             const filename = 'Ingreso resumen.xlsx';
             const a = document.createElement('a');
             a.href = window.URL.createObjectURL(res.data);
             a.download = filename;
             a.style.display = 'none';
             document.body.appendChild(a);
             a.click();
           } catch (error) {
             console.log(`Opps, un error: ${error}`);
             console.error(error);
           }
         },
         retry: 0
       });
