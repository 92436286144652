/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import {
  formatAmountWithDecimals,
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { useTranslation } from 'react-i18next';
import OverflowTipography from 'src/components/OverflowTipography';
import {
  addPercentageToValue,
  getClientFullName,
  roundNumber
} from '../../../utils/common';

const ContractsRow = ({
  id,
  client,
  equipment,
  signatureDate,
  firstRentDate,
  endDate,
  rate,
  residualPayment,
  initialPayment,
  equipmentCost,
  term,
  rent,
  folio
}) => {
  const clientName = useMemo(() => (client ? getClientFullName(client) : ''), [
    client
  ]);
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/contracts/${id}`}>{id}</Link>
      </TableCell>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <OverflowTipography title={clientName}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/clients/detail/${client.id}`}
            maxWidth="200px"
          >
            {clientName}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {equipment ? (
          <Link to={`/inventory/${equipment.id}`}>{equipment.id}</Link>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="center">{folio ?? t('COMMON.NO_DATA')}</TableCell>
      <TableCell align="center">
        {selectFormatDate(signatureDate, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(firstRentDate, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(endDate, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(equipmentCost))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(initialPayment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(equipmentCost - initialPayment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(residualPayment))}
      </TableCell>
      <TableCell align="center">{term}</TableCell>
      <TableCell align="center">
        {addPercentageToValue(formatAmountWithDecimals(rate, 1))}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(rent)}</TableCell>
    </TableRow>
  );
};

ContractsRow.propTypes = {
  id: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
  equipment: PropTypes.object.isRequired,
  signatureDate: PropTypes.string.isRequired,
  firstRentDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  initialPayment: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  rent: PropTypes.number.isRequired,
  equipmentCost: PropTypes.number.isRequired,
  residualPayment: PropTypes.number.isRequired,
  folio: PropTypes.string
};

export default ContractsRow;
