import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RentResumeBalanceType } from 'src/utils/constants/accounting';
import { loadRentResume } from 'src/store/actions/rentResumeActions';
import RentResumeList from './RentResumeList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function selectBalanceType(value) {
  switch (Number(value)) {
    case 0:
      return RentResumeBalanceType.CLIENT;
    case 1:
      return RentResumeBalanceType.CONTRACT;
    default:
      return RentResumeBalanceType.CLIENT;
  }
}

const RentResumenListTabs = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadRentResume({
          balanceType: selectBalanceType(newValue),
          page: 0
        })
      );
    },
    [setTabIndex]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.CLIENT')} />
          <Tab disabled label={t('COMMON.CONTRACT')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <RentResumeList balanceType={RentResumeBalanceType.CLIENT} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RentResumeList balanceType={RentResumeBalanceType.CONTRACT} />
      </TabPanel>
    </Box>
  );
};

export default RentResumenListTabs;
