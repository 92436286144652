import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { PersonType } from 'src/utils/constants/clients';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CONTRACT_VIEW.CONTRACT_DATA')}
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.INITIAL_PAYMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatAmountWithDecimals(values.initialPayment)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.SIGNATURE_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.startDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.FIRST_RENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.firstRentDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.LAST_RENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.endDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.DAYS_FOR_FIRST_RENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {translateGracePeriodValueToDaysForFirstRent(
                values.gracePeriod,
                t
              )}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.PAY_DAY')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.payday}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CONTRACT_VIEW.EQUIPMENT_ADDRESS')}
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STREET')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.street}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EXTERNAL_HOME_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.externalHomeNumber}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INSIDE_HOME_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.insideHomeNumber ?? t('COMMON.NO_DATA')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.ZIP_CODE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.zipCode}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.COLONIA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.colonia}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.CITY')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.city}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.state}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('COMMON.AVALS')}
          </TableCell>
        </TableRow>

        {values.clientPersonType === PersonType.LEGAL ? (
          <>
            {values.avals.map((aval, index) => (
              <TableRow component="div">
                <TableCell component="div" className={classes.reviewDataLabel}>
                  {index === 0
                    ? t('ENUMS.AVAL_ROLE.LEGAL_REPRESENTATIVE')
                    : `${t('COMMON.AVAL')} ${index}`}
                </TableCell>
                <TableCell component="div">
                  <Typography variant="body2" color="textSecondary">
                    {aval.fullName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <>
            {values.avals.map((aval, index) => (
              <TableRow component="div">
                <TableCell component="div" className={classes.reviewDataLabel}>
                  {index === 0
                    ? t('COMMON.AVAL')
                    : `${t('COMMON.AVAL')} ${index + 1}`}
                </TableCell>
                <TableCell component="div">
                  <Typography variant="body2" color="textSecondary">
                    {aval.fullName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
