import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';

const TaxSystemRow = ({ name, id }) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>{t(`ENUMS.TAX_SYSTEM.${name}`)}</TableCell>
    </TableRow>
  );
};

TaxSystemRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
};

export default TaxSystemRow;
