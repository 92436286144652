import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import Button from 'src/components/Button';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { updateContract } from 'src/store/actions/contractActions';
import AddressDialog from 'src/components/AddressDialog/AddressDialog';
import AddressDetailBox from 'src/components/AddressDetailBox/AddressDetailBox';
import useStyles from '../../styles';

const EquipmentAddressDetail = ({ location }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(
        updateContract(
          id,
          {
            equipmentLocation: { ...values }
          },
          handleClose
        )
      );
    },
    [id, dispatch]
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CONTRACT_VIEW.CONTRACT_DETAIL.EQUIPMENT_ADDRESS')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <AddressDetailBox address={location} />
          </TableBody>
        </Table>
      </CardContent>

      <AddressDialog
        open={open}
        address={location}
        handleClose={handleClose}
        submit={handleSubmit}
      />
      <Grid container direction="column" alignItems="stretch">
        <Grid item ml={1} mr={1}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            startIcon={<Edit />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

EquipmentAddressDetail.propTypes = {
  location: PropTypes.object.isRequired
};

export default EquipmentAddressDetail;
