import decode from 'jwt-decode';
import moment from 'moment-timezone';

import * as AuthActions from 'src/store/actions/authActions';
import { selectIsLoggedIn, selectAuthToken } from '../selectors/authSelectors';

let preventRun = false;

const NoRehydratePaths = {
  '/user/verification': '/user/verification',
  '/user/recover-password': '/user/recover-password',
  '/admin/verification': '/admin/verification'
};

export const rehydrateToken = store => next => action => {
  const state = store.getState();
  const token = selectAuthToken(state) || localStorage.getItem('sessionToken');
  const isLoggedIn = selectIsLoggedIn(state);

  const { location } = document;
  const referrer = location.pathname;

  if (token && !preventRun) {
    preventRun = true;
    const payload = decode(token);
    const expiration = moment.unix(payload.exp);

    if (moment().isAfter(expiration)) {
      next(AuthActions.logout());
    } else if (!isLoggedIn) {
      if (NoRehydratePaths[referrer]) {
        next(AuthActions.removeToken());
      } else {
        next(AuthActions.loginWithToken(token));
      }
    }
    preventRun = false;
  }

  next(action);
};

export default rehydrateToken;
