import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavItem from './NavItem';
import SubMenu from './SubMenu';

const useStyles = makeStyles(theme => ({
  list: {
    marginBottom: theme.spacing(4)
  },
  heading: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    letterSpacing: 0.1,
    opacity: 0.4,
    textTransform: 'uppercase'
  }
}));

const Menu = ({ item, openDrawer, routesWithBadge }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getSubHeader = title => (
    <ListSubheader
      disableGutters
      classes={{ root: classes.heading }}
      component="div"
      sx={{ position: 'static' }}
      id={title.toLowerCase()}
    >
      {t(title)}
    </ListSubheader>
  );

  return (
    <List classes={{ root: classes.list }} subheader={getSubHeader(item.title)}>
      {item.children.map(child => {
        if (child.children && child.children.length) {
          return (
            <SubMenu
              key={child.title}
              openDrawer={openDrawer}
              badgeContent={child.badgeContent}
              {...child}
            />
          );
        }

        const badgeContent = routesWithBadge[child.href];

        return (
          <NavItem
            isRootItem
            href={child.href}
            key={child.title}
            title={t(child.title)}
            icon={child.icon}
            openDrawer={openDrawer}
            badgeContent={badgeContent}
          />
        );
      })}
    </List>
  );
};

Menu.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    children: PropTypes.array,
    title: PropTypes.string.isRequired
  }),
  openDrawer: PropTypes.bool,
  routesWithBadge: PropTypes.object
};

Menu.defaultProps = {
  item: {}
};

export default Menu;
