import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

/* eslint-disable import/prefer-default-export */
export const pullMoffinReport = serviceBase({
  request: rfc =>
    axiosClient.get(
      `/credit-profile/pull-reports?service=bureau_pm&service=bureau_pf&status=SUCCESS&search=${rfc}`
    )
});
