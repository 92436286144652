import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import {
  exportInvestmentList,
  getInvestmentList,
  getInvestmentDetail,
  createInvestment,
  sendTitleToSign
} from 'src/services/investmentService';
import { push } from 'connected-react-router';
import { showMessage } from './snackBarActions';
import { selectInvestmentsQueryOptions } from '../selectors/investmentSelector';

export const SAVE_INVESTMENT_OPTIONS = 'SAVE_INVESTMENT_OPTIONS';
export const CLEAR_INVESTMENT_OPTIONS = 'CLEAR_INVESTMENT_OPTIONS';
export const CLEAR_INVESTMENT = 'CLEAR_INVESTMENT';
export const LOAD_INVESTMENT_LIST = asyncActionCreator('LOAD_INVESTMENT_LIST');
export const LOAD_INVESTMENT_DETAIL = asyncActionCreator(
  'LOAD_INVESTMENT_DETAIL'
);
export const CREATE_INVESTMENT = asyncActionCreator('CREATE_INVESTMENT');
export const SEND_TITLE_TO_SIGN = asyncActionCreator('SEND_TITLE_TO_SIGN');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectInvestmentsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.investorName) {
    reqOptions.investorName = queryOpts.investorName;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadInvestmentList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INVESTMENT_LIST.start });

    dispatch({
      type: SAVE_INVESTMENT_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);

    const data = await getInvestmentList(reqOptions);

    return dispatch({
      type: LOAD_INVESTMENT_LIST.success,
      ...data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTMENT_LIST.failure });
  }
};

export const loadInvestmentDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INVESTMENT_DETAIL.start });

    const data = await getInvestmentDetail(id);

    dispatch({ type: CLEAR_INVESTMENT });

    return dispatch({
      type: LOAD_INVESTMENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTMENT_DETAIL.failure });
  }
};

export const downloadInvestmentList = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await exportInvestmentList(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createInvestmentAction = (data, handleClose) => async dispatch => {
  try {
    dispatch({ type: CREATE_INVESTMENT.start });

    const response = await createInvestment({
      ...data,
      investorId: data.investor.id,
      type: 'DEPOSIT'
    });

    dispatch(
      showMessage({
        message: 'CREATE_INVESTMENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    handleClose();
    dispatch({ type: CREATE_INVESTMENT.success });
    return dispatch(push(`/investors/investment/${response.id}`));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_INVESTMENT.failure });
  }
};

export const sendInvestmentTitleToSign = id => async dispatch => {
  try {
    dispatch({ type: SEND_TITLE_TO_SIGN.start });

    await sendTitleToSign(id);

    dispatch(
      showMessage({
        message: 'SEND_TITLE_TO_SIGN',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: SEND_TITLE_TO_SIGN.success });
    return dispatch(loadInvestmentDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEND_TITLE_TO_SIGN.failure });
  }
};
