import { useEffect, useState } from 'react';

const useIsLargeScreen = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(
        window.innerWidth >=
          parseInt(process.env.REACT_APP_MOBILE_RESOLUTION || '600', 10)
      );
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isLargeScreen;
};

export default useIsLargeScreen;
