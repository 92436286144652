import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFile from 'src/components/UploadFile';
import {
  calculatePriceWhitIva,
  getDifferenceBetweenStates
} from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import {
  LOAD_PARAMETER,
  loadParameter
} from 'src/store/actions/parameterActions';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import { AssigantionStatus } from 'src/utils/constants/equipment';
import { Parameter } from 'src/utils/constants/parameters';
import Tooltip from 'src/components/Tooltip';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentsDetailDialog = ({ open, equipment, handleClose, submit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ivaParameter = useSelector(selectIndividualParameter);
  const loadingIvaPercentage = useSelector(
    isLoadingSelector([LOAD_PARAMETER.action])
  );

  const initialState = useMemo(
    () => makeInitialState({ ...equipment, iva: ivaParameter }),
    [equipment, ivaParameter]
  );
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit({ ...difference, iva: values.iva });
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadParameter(Parameter.IVA));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  const isPriceEditable = () => {
    if (equipment.assignation !== AssigantionStatus.AVAILABLE) {
      return false;
    }

    if (equipment.distributorModel) {
      return equipment.distributorModel.isPriceEditable;
    }

    return true;
  };

  const getPriceEditableTooltipMessage = () => {
    if (equipment.assignation !== AssigantionStatus.AVAILABLE) {
      return t(
        'EQUIPMENT_VIEW.EQUIPMENT_DETAIL.EQUIPMENT_HAS_A_CONTRACT_ASSODICATED'
      );
    }

    if (
      equipment.distributorModel &&
      !equipment.distributorModel.isPriceEditable
    ) {
      return t(
        'EQUIPMENT_VIEW.EQUIPMENT_DETAIL.DISTRIBUTOR_DOES_NOT_ALLOW_TO_EDIT_PRICE'
      );
    }

    return null;
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.EQUIPMENT_DOCUMENTS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {loadingIvaPercentage ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <Tooltip
                    condition={!isPriceEditable()}
                    title={getPriceEditableTooltipMessage()}
                  >
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.price && errors.price)}
                      fullWidth
                      helperText={touched.price && t(errors.price)}
                      label={t('COMMON.PRICE')}
                      margin="normal"
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.price}
                      variant="outlined"
                      disabled={!isPriceEditable()}
                    />
                  </Tooltip>

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    fullWidth
                    label={`${t('COMMON.IVA')} %`}
                    disabled
                    margin="normal"
                    name="iva"
                    type="number"
                    value={formatAmountWithDecimals(values.iva)}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    disabled
                    fullWidth
                    label={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
                    margin="normal"
                    name="priceWhitIva"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={formatAmountWithDecimals(
                      calculatePriceWhitIva(values.price, values.iva)
                    )}
                    variant="outlined"
                  />

                  <UploadFile
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    accept={{ 'application/pdf': ['.pdf'] }}
                    valueLabel="invoicePdfUrl"
                    textLabel={t('COMMON.UPLOAD_PDF_INVOICE')}
                  />
                  <UploadFile
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    accept={{ 'text/xml': ['.xml'] }}
                    valueLabel="invoiceXmlUrl"
                    textLabel={t('COMMON.UPLOAD_XML_INVOICE')}
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

DocumentsDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  equipment: PropTypes.object
};

export default DocumentsDetailDialog;
