import React, { useCallback, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import Tab from 'src/components/Tab';
import { QuoteStatus } from 'src/utils/constants/quotes';
import { useDispatch } from 'react-redux';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  CLEAR_QUOTE_OPTIONS,
  loadQuotes
} from 'src/store/actions/quoteActions';
import QuotesList from './QuotesList';

const QuoteMenuList = [
  {
    value: QuoteStatus.IN_REVIEW,
    label: 'ENUMS.QUOTE_STATUS.IN_REVIEW',
    description: 'ENUMS.QUOTES_DESCRIPTION.CONTRACT_REQUESTS'
  },
  {
    value: QuoteStatus.DRAFT,
    label: 'ENUMS.QUOTE_STATUS.DRAFT',
    description: 'ENUMS.QUOTES_DESCRIPTION.QUOTES'
  },
  {
    value: QuoteStatus.REJECTED,
    label: 'ENUMS.QUOTE_STATUS.REJECTED',
    description: 'ENUMS.QUOTES_DESCRIPTION.REJECTED_REQUESTS'
  },
  {
    value: QuoteStatus.ACCEPTED,
    label: 'ENUMS.QUOTE_STATUS.ACCEPTED',
    description: 'ENUMS.QUOTES_DESCRIPTION.ACCEPTED_REQUESTS'
  },
  {
    value: QuoteStatus.ARCHIVED,
    label: 'ENUMS.QUOTE_STATUS.ARCHIVED',
    description: 'ENUMS.QUOTES_DESCRIPTION.ARCHIVED_REQUESTS'
  }
];

const QuotesListTabs = () => {
  const { push } = useHistory();
  const { tab } = useParams();
  const [value, setValue] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeTabs = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/quotes/${newValue}`);
      dispatch({ type: CLEAR_QUOTE_OPTIONS });

      dispatch(
        loadQuotes({
          status: newValue,
          page: 0
        })
      );
    },
    [setValue]
  );

  useEffect(() => {
    if (!tab) {
      setValue(QuoteStatus.IN_REVIEW);
    }
    if (tab) {
      if (Object.values(QuoteStatus).includes(tab)) {
        setValue(tab);
      } else {
        push(`/quotes`);
      }
    }
  }, [tab]);

  return (
    <Box sx={{ flexGrow: 1, mt: '2rem' }}>
      <AppBar
        elevation={0}
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChangeTabs}
        >
          {QuoteMenuList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              description={t(item.description)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={value}>
        <QuotesList />
      </TabPanel>
    </Box>
  );
};

export default QuotesListTabs;
