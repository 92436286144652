import {
  BillingParameter,
  BillingPaymentForm,
  BillingPaymentMethod
} from './constants/parameters';

const selectBillingList = ({ parameter, list, t }) => {
  switch (parameter.name) {
    case BillingParameter.INVOICE_USE_CFDI:
      return list.map(item => {
        return {
          key: item.code,
          name: `${item.code} - ${t(`ENUMS.BILLING_CONCEPT.${item.name}`)}`
        };
      });
    case BillingParameter.EQUIPMENT_SELL_USE_CDFI:
      return list.map(item => {
        return {
          key: item.code,
          name: `${item.code} - ${t(`ENUMS.BILLING_CONCEPT.${item.name}`)}`
        };
      });
    case BillingParameter.BILLING_PAYMENT_METHOD:
      return [
        {
          key: BillingPaymentMethod.PUE.key,
          name: BillingPaymentMethod.PUE.name
        },
        {
          key: BillingPaymentMethod.PPD.key,
          name: BillingPaymentMethod.PPD.name
        }
      ];
    case BillingParameter.BILLING_PAYMENT_FORM:
      return [
        {
          key: BillingPaymentForm.CASH.key,
          name: BillingPaymentForm.CASH.name
        },
        {
          key: BillingPaymentForm.TRANSFER.key,
          name: BillingPaymentForm.TRANSFER.name
        },
        {
          key: BillingPaymentForm.UNDEFINED.key,
          name: BillingPaymentForm.UNDEFINED.name
        }
      ];
    default:
      return [];
  }
};

export default selectBillingList;
