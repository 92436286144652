import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  billingContainer: {
    marginTop: theme.spacing(2)
  },
  billing: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(3, 2),
    width: '100%'
  },
  billingIconSuccess: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  billingIconError: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgb(246 0 0 / 37%);',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset'
  },
  tableContainer: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowY: 'scroll',
    overflowX: 'hidden',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%'
  },
  preloaderCard: {
    height: '82vh'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px'
  }
}));
