import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment, MenuItem, Paper, Select } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const SearchBarByType = ({
  types,
  searchValue,
  placeholder,
  handleSearch,
  handleSearchCriteria,
  ...props
}) => {
  const { t } = useTranslation();
  const [searchCriteria, setSearchCriteria] = useState({});

  useEffect(() => {
    setSearchCriteria(types[0]);
  }, []);

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <Paper
      elevation={0}
      sx={{
        ml: 2,
        padding: '6px 6px',
        border: '1px solid #EEEEEE',
        display: 'flex',
        alignItems: 'center',
        width: { lg: '30vw', sm: 'auto', xs: 'auto' }
      }}
    >
      <Input
        sx={{ width: '100%' }}
        startAdornment={Addornment}
        placeholder={placeholder || t('COMMON.SEARCH')}
        disableUnderline
        value={searchValue}
        inputProps={{
          'aria-label': 'Search'
        }}
        type={searchCriteria.type ?? 'text'}
        onChange={e => handleSearch(e, searchCriteria.value)}
        {...props}
      />
      <Select
        id="criteria-selection"
        sx={{ width: '20%', ml: 2 }}
        variant="standard"
        defaultValue={searchCriteria.value ?? types[0].value}
        onChange={e => {
          const { value } = e.target;
          setSearchCriteria(types.find(type => type.value === value));
          handleSearchCriteria();
        }}
      >
        {types.map(type => (
          <MenuItem key={type.value} value={type.value} sx={{ width: '100%' }}>
            {t(`COMMON.${type.label}`)}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

SearchBarByType.propTypes = {
  types: PropTypes.array.isRequired,
  searchValue: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  handleSearchCriteria: PropTypes.func.isRequired
};

export default SearchBarByType;
