import React from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import { roundNumber } from 'src/utils/common';
import OverflowTipography from 'src/components/OverflowTipography';

const InterestBalanceRow = ({
  period,
  clientFullName,
  clientId,
  equipmentId,
  equipment,
  initialBalance,
  subscription,
  interest,
  interestChange,
  finalBalance
}) => {
  return (
    <TableRow hover>
      <TableCell>
        {clientFullName.length > 45 ? (
          <Tooltip title={clientFullName}>
            <Link to={`/clients/detail/${clientId}`}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.43'
                }}
              >
                {`${clientFullName.slice(0, 45)}...`}
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {clientFullName}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell align="left">
        <OverflowTipography title={equipment} maxWidth="190px">
          <Link
            style={{ textDecoration: 'none' }}
            to={`/inventory/${equipmentId}`}
          >
            {equipment}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">{period}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialBalance))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(subscription))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(interest))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(interestChange))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(finalBalance))}
      </TableCell>
    </TableRow>
  );
};

InterestBalanceRow.propTypes = {
  period: PropTypes.string.isRequired,
  clientFullName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  equipmentId: PropTypes.string.isRequired,
  initialBalance: PropTypes.number.isRequired,
  subscription: PropTypes.number.isRequired,
  interest: PropTypes.number.isRequired,
  interestChange: PropTypes.number.isRequired,
  finalBalance: PropTypes.number.isRequired,
  equipment: PropTypes.string.isRequired
};

export default InterestBalanceRow;
