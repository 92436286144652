import React from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import {
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import { Link } from 'react-router-dom';
import { capitalizeName, roundNumber } from 'src/utils/common';
import { RentResumeBalanceType } from 'src/utils/constants/accounting';
import OverflowTipography from 'src/components/OverflowTipography';

const RentResumeRow = ({
  initialBalance,
  rent,
  capitalContribution,
  residual,
  surcharge,
  discount,
  payment,
  finalBalance,
  clientFullname,
  clientId,
  initialPayment,
  contractId,
  balanceType
}) => {
  return (
    <TableRow hover>
      {balanceType === RentResumeBalanceType.CONTRACT && (
        <TableCell align="center">
          <Link to={`/contracts/${contractId}`}>
            <OverflowTipography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
              maxWidth="150px"
            >
              {contractId}
            </OverflowTipography>
          </Link>
        </TableCell>
      )}
      <TableCell>
        <Link to={`/clients/detail/${clientId}`}>
          <OverflowTipography maxWidth="250px">
            {capitalizeName(clientFullname)}
          </OverflowTipography>
        </Link>
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialBalance))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialPayment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(rent))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(capitalContribution))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(residual))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(surcharge))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(discount))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(payment))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(finalBalance))}
      </TableCell>
    </TableRow>
  );
};

RentResumeRow.propTypes = {
  initialBalance: PropTypes.number,
  rent: PropTypes.number,
  capitalContribution: PropTypes.number,
  residual: PropTypes.number,
  surcharge: PropTypes.number,
  discount: PropTypes.number,
  payment: PropTypes.number,
  finalBalance: PropTypes.number,
  clientFullname: PropTypes.string,
  clientId: PropTypes.number,
  initialPayment: PropTypes.number,
  contractId: PropTypes.number,
  balanceType: PropTypes.string
};

export default RentResumeRow;
