import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  LOAD_SAT_WS_HISTORY,
  loadSatWsHistory
} from 'src/store/actions/clientActions';
import {
  selectClientSatWsHistory,
  selectClientsQueryOptions
} from 'src/store/selectors/clientSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import SatWsHistoryTable from './SatWsHistoryTable';

const SatWsHistoryList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const options = useSelector(selectClientsQueryOptions);
  const histories = useSelector(selectClientSatWsHistory);
  const loadingHistories = useSelector(
    isLoadingSelector([LOAD_SAT_WS_HISTORY.action])
  );

  useEffect(() => {
    dispatch(loadSatWsHistory(id, options));
  }, []);

  return (
    <SatWsHistoryTable
      loading={loadingHistories}
      histories={histories}
      key="SatWsHistoriesTable"
    />
  );
};

export default SatWsHistoryList;
