import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { calculateYearsAndMonthsFromDays } from 'src/utils/common';

const GeneralDistributorInfo = ({ distributor }) => {
  const { t } = useTranslation();

  const getYearsOfPartnership = () => {
    if (!distributor.daysOfPartnership && distributor.daysOfPartnership !== 0)
      return t('COMMON.NO_DATA');
    const { months, years } = calculateYearsAndMonthsFromDays(
      distributor.daysOfPartnership
    );

    switch (true) {
      case years === 0 && months === 0:
        return t('COMMON.LESS_THAN_A_MONTH');
      case years === 0 && months === 1:
        return `${months} ${t('COMMON.MONTH')}`;
      case years === 0 && months > 0:
        return `${months} ${t('COMMON.MONTHS')}`;
      case years === 1 && months === 0:
        return `${years} ${t('COMMON.YEAR')}`;
      default:
        return `${years} ${t('COMMON.YEAR')} ${months} ${t('COMMON.MONTHS')}`;
    }
  };

  return (
    <>
      <DetailRow
        name={t('COMMON.COMPANY_NAME')}
        value={distributor.name || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.COMERCIAL_NAME')}
        value={distributor.commercialName || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.EQUIPMENT_TYPE_WITH_SAT_CATALOG')}
        value={distributor.equipmentType.name || t('COMMON.NO_DATA')}
      />

      <DetailRow name="RFC" value={distributor.rfc || t('COMMON.NO_DATA')} />

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.DISTRIBUTOR_DISCHARGE')}
        value={getYearsOfPartnership()}
      />

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.OPERATING_YEARS')}
        value={distributor.age ? distributor.age : t('COMMON.NO_DATA')}
      />
    </>
  );
};

GeneralDistributorInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};
export default GeneralDistributorInfo;
