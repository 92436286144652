import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DropdownMenu from 'src/components/DropdownMenu';
import { TermList } from 'src/utils/constants/distributors';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { useDispatch } from 'react-redux';
import { calculateMonthlyRent } from 'src/store/actions/contractActions';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { PaymentInputOption } from 'src/utils/constants/quotes';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';

const CalculateNetInvestment = ({ values, setFieldValue }) => {
  useEffect(() => {
    const { initialPayment, equipmentCost, paymentInputOption } = values;
    // TODO: define the formula for net investment with initialPayment with iva
    const initialPaymentAmount =
      paymentInputOption === PaymentInputOption.PERCENTAGE
        ? (initialPayment / 100) * equipmentCost
        : initialPayment;

    setFieldValue('netInvestment', equipmentCost - initialPaymentAmount);
  }, [values.initialPayment, values.equipmentCost, values.paymentInputOption]);

  return null;
};

CalculateNetInvestment.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const CalculateRent = ({ values, setFieldValue }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      rate,
      term,
      residualPayment,
      equipmentCost,
      initialPayment,
      paymentInputOption
    } = values;

    if (
      !rate ||
      !term ||
      !residualPayment ||
      !equipmentCost ||
      initialPayment === undefined
    ) {
      setFieldValue('rent', 0);
      return;
    }

    dispatch(
      calculateMonthlyRent(
        {
          rate,
          term,
          residualPayment,
          equipmentCost,
          initialPayment:
            paymentInputOption === PaymentInputOption.PERCENTAGE
              ? (initialPayment / 100) * equipmentCost
              : initialPayment
        },
        setFieldValue
      )
    );
  }, [
    values.rate,
    values.term,
    values.residualPayment,
    values.initialPayment,
    values.equipmentCost,
    values.paymentInputOption
  ]);

  return null;
};

CalculateRent.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const StepThree = ({ data, handleBack, handleNext, resetRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              InputProps={{
                autoComplete: 'off'
              }}
              error={Boolean(touched.rate && errors.rate)}
              fullWidth
              helperText={touched.rate && t(errors.rate)}
              label={`${t('CONTRACT_VIEW.RATE')} %`}
              margin="normal"
              name="rate"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.rate}
              variant="outlined"
            />

            <DropdownMenu
              list={TermList}
              value={values.term}
              setValue={value => setFieldValue('term', value)}
              label={t('CONTRACT_VIEW.TERM')}
              error={Boolean(touched.term && errors.term)}
              errorText={touched.term && t(errors.term)}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.equipmentCost && errors.equipmentCost)}
              fullWidth
              helperText={touched.equipmentCost && t(errors.equipmentCost)}
              label={t('CONTRACT_VIEW.EQUIPMENT_COST')}
              margin="normal"
              name="equipmentCost"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.equipmentCost}
              variant="outlined"
              disabled
            />

            <PaymentMultipleInput
              firstInputName="paymentInputOption"
              secondInputName="initialPayment"
              label={t('COMMON.INITIAL_PAYMENT')}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.netInvestment && errors.netInvestment)}
              fullWidth
              helperText={touched.netInvestment && t(errors.netInvestment)}
              label={t('CONTRACT_VIEW.NET_INVESTMENT')}
              margin="normal"
              name="netInvestment"
              onBlur={handleBlur}
              disabled
              value={values.netInvestment}
              variant="outlined"
            />

            <CalculateNetInvestment
              values={values}
              setFieldValue={setFieldValue}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.residualPayment && errors.residualPayment)}
              fullWidth
              helperText={touched.residualPayment && t(errors.residualPayment)}
              label={t('CONTRACT_VIEW.RESIDUAL')}
              margin="normal"
              name="residualPayment"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.residualPayment}
              variant="outlined"
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.rent && errors.rent)}
              fullWidth
              helperText={touched.rent && t(errors.rent)}
              label={t('COMMON.RENT')}
              margin="normal"
              name="rent"
              onBlur={handleBlur}
              disabled
              value={values.rent}
              variant="outlined"
            />

            <CalculateRent values={values} setFieldValue={setFieldValue} />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepThree.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired
};
export default StepThree;
