import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  paternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.PATERNAL_NAME_REQUIRED')
  }),
  maternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.MATERNAL_NAME_REQUIRED')
  }),
  denomination: Yup.string().when('personType', {
    is: PersonType.LEGAL,
    then: Yup.string().required('SCHEMA_ERRORS.DENOMINATION_REQUIRED')
  })
});
