import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectEquipmentQueryOptions } from 'src/store/selectors/equipmentSelectors';
import SearchBar from 'src/components/SearchBar';
import {
  downloadBusinessMetrics,
  loadBusinessMetrics
} from 'src/store/actions/businessActions';
import DownloadButton from 'src/components/DownloadButton';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectEquipmentQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (name, queryOpts) =>
      dispatch(loadBusinessMetrics({ ...queryOpts, name, page: 0 })),
    400
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadBusinessMetrics());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.METRICS')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
            <DownloadButton
              onDownload={handleExcelExport}
              title={t('COMMON.DOWNLOAD_METRICS')}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
