import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import PhoneInput from 'src/components/PhoneInput';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'src/theme';
import {
  VALIDATE_CLIENT_CONTACT_INFO,
  validateClientContactInformation
} from 'src/store/actions/bureauFormActions';
import { LoadingButton } from '@mui/lab';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepTwo = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loadingValidation = useSelector(
    isLoadingSelector([VALIDATE_CLIENT_CONTACT_INFO.action])
  );

  const { t } = useTranslation();

  const onNext = useCallback(async values => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      return dispatch(validateClientContactInformation(values, handleNext));
    }
    return handleNext(values);
  }, []);

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '20%'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_TWO.TITLE')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px'
          }}
        >
          {t('BUREAU_FORM.STEP_TWO.DESCRIPTION')}
        </Typography>
      </Box>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '80%',
                marginTop: '10px'
              }}
            >
              <Box className={classes.container}>
                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    label={t('COMMON.MOBILE_PHONE')}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  label={t('COMMON.EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                  disabled={loadingValidation}
                >
                  {t('COMMON.BACK')}
                </Button>
                <LoadingButton
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  loadingIndicator={
                    <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />
                  }
                  variant="outlined"
                  loading={loadingValidation}
                  disabled={loadingValidation}
                >
                  {t('COMMON.NEXT')}
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepTwo;
