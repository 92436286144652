import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import EquiptmentView from 'src/views/equipment';
import AdminPrivateRoute from 'src/components/PrivateRotes/AdminPrivateRoute';
import CatalogView from 'src/views/catalog';
import { selectUserRedirectBySystemRol } from 'src/utils/common';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import UserPrivateRoute from 'src/components/PrivateRotes/UserPrivateRoute';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import SuperAdminPrivateRoute from 'src/components/PrivateRotes/SuperAdminPrivateRoute';
import { useSelector } from 'react-redux';
import ParameterView from './parameter';
import ClientsView from './client';
import InsurancesView from './insurance';
import ContractView from './contract';
import TransactionView from './transaction';
import BillingView from './billing';
import InterestBalanceView from './interestBalance';
import CapitalBalanceView from './capitalBalance';
import IncomeView from './income';
import BusinessView from './business';
import PolicyView from './policy';
import UserKamsView from './userViews/kam';
import UserView from './userViews';
import KamDetailView from './userViews/kam/KamDetailView';
import QuotersView from './quoter';
import QuotesView from './quotes';
import AdministratorsView from './administrator';
import InverstorsView from './investor';
import CounselorsView from './counselor';
import TaxAccountingView from './taxAccounting';
import AvalDetailView from './client/AvalDetail';
import IdentityView from './backgroundValidation';
import UpfrontRentsListView from './upfrontRents';

const DashboardViews = ({ match }) => {
  const systemRol = useSelector(selectAuthSystem);
  return (
    <DashboardLayout>
      <AdminPrivateRoute path="/inventory" component={EquiptmentView} />
      <PrivateRoute path="/catalogs" component={CatalogView} />
      <AdminPrivateRoute path="/parameters" component={ParameterView} />
      <AdminPrivateRoute path="/clients" component={ClientsView} />
      <AdminPrivateRoute
        path="/aval/detail/:avalId/:tab?"
        component={AvalDetailView}
      />
      <AdminPrivateRoute path="/contracts" component={ContractView} />
      <AdminPrivateRoute path="/insurances" component={InsurancesView} />
      <AdminPrivateRoute path="/transactions" component={TransactionView} />
      <AdminPrivateRoute path="/billing" component={BillingView} />
      <AdminPrivateRoute path="/investors" component={InverstorsView} />
      <AdminPrivateRoute path="/counselors" component={CounselorsView} />
      <AdminPrivateRoute
        path="/interest-balance"
        component={InterestBalanceView}
      />
      <AdminPrivateRoute
        path="/capital-balance"
        component={CapitalBalanceView}
      />
      <AdminPrivateRoute path="/income" component={IncomeView} />
      <AdminPrivateRoute path="/policies" component={PolicyView} />
      <AdminPrivateRoute path="/distributors" component={BusinessView} />
      <AdminPrivateRoute
        exact
        path="/distributor-profile/:id"
        component={KamDetailView}
      />
      <AdminPrivateRoute path="/quoters" component={QuotersView} />
      <AdminPrivateRoute path="/tax-accounting" component={TaxAccountingView} />
      <AdminPrivateRoute path="/identity" component={IdentityView} />
      <AdminPrivateRoute path="/upfront-rents" component={UpfrontRentsListView} />
      <PrivateRoute path="/quotes" component={QuotesView} />
      <UserPrivateRoute path="/user" component={UserView} />
      <Route
        exact
        path={`${match.url}`}
        component={() => (
          <Redirect to={selectUserRedirectBySystemRol(systemRol)} />
        )}
      />
      <UserPrivateRoute path="/user/kams" component={UserKamsView} />
      <SuperAdminPrivateRoute
        path="/super-admin"
        component={AdministratorsView}
      />
    </DashboardLayout>
  );
};

DashboardViews.propTypes = {
  match: PropTypes.object.isRequired
};
export default DashboardViews;
