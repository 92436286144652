import * as Yup from 'yup';

export default Yup.object().shape({
  economicActivities: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('SCHEMA_ERRORS.BOTH_VALLUES_REQUIRED'),
        name: Yup.string().required(),
        percentage: Yup.number()
          .required('SCHEMA_ERRORS.BOTH_VALLUES_REQUIRED')
      })
    )
});
