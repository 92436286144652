import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  CardHeader as MuiCardHeader,
  Divider
} from '@mui/material';
import useStyles from './styles';

const CardHeader = ({ title, withBottomDivider = true }) => {
  const classes = useStyles();

  return (
    <>
      <MuiCardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {title}
          </Typography>
        }
      />
      {withBottomDivider && <Divider className={classes.tableCell} />}
    </>
  );
};

export default CardHeader;

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  withBottomDivider: PropTypes.bool
};
