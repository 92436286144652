import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'moment/locale/es';

import './i18n';

moment.tz.setDefault('America/Mexico_City');

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
