import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  CLEAR_CLIENT_BURO_VALIDATION_DETAIL,
  LOAD_CLIENT_BURO_VALIDATION_DETAIL,
  loadAvalBuroValidationDetail
} from 'src/store/actions/clientActions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { selectBuroValidation } from 'src/store/selectors/clientSelectors';
import Preloader from 'src/components/Preloader';
import { getMoffinCreditProfileScore } from 'src/utils/clientsHelper';
import BuroValidationDetail from './BuroValidationDetail';
import useStyles from '../styles';

const MoffinView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { avalId } = useParams();
  const classes = useStyles();
  const clientBuroValidation = useSelector(selectBuroValidation);

  const loading = useSelector(
    isLoadingSelector([LOAD_CLIENT_BURO_VALIDATION_DETAIL.action])
  );
  const success = useSelector(
    successSelector([LOAD_CLIENT_BURO_VALIDATION_DETAIL.action])
  );

  useEffect(() => {
    dispatch(loadAvalBuroValidationDetail(avalId));
    return () => {
      dispatch({ type: CLEAR_CLIENT_BURO_VALIDATION_DETAIL });
    };
  }, [avalId]);

  return (
    <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.titleContainer}>
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('CLIENTS_VIEW.CLIENT_DETAIL.MOFFIN.TITLE')}
            </Typography>
          </Box>
        </Grid>

        {loading && !success ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        ) : (
          <Grid item sx={12} lg={12}>
            <BuroValidationDetail
              validation={getMoffinCreditProfileScore(clientBuroValidation)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MoffinView;
