import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import ContractDetailView from './ContractDetailView';
import ContractListView from './contracts';
import AvalDetailView from '../client/AvalDetail';

const ContractView = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}`} component={ContractListView} />
      <Route
        exact
        path={`${match.url}/contracts`}
        component={() => <Redirect to={`${match.url}`} />}
      />
      <Route
        exact
        path={`${match.url}/:id/avals/:avalId/:tab?/:subtab?`}
        component={AvalDetailView}
      />
      <Route path={`${match.url}/:id/:tab?`} component={ContractDetailView} />
    </>
  );
};

ContractView.propTypes = {
  match: PropTypes.object.isRequired
};

export default ContractView;
