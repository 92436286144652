export const selectClient = state => state.clients;
export const selectClientsList = state => selectClient(state).items;
export const selectClientDetail = state => selectClient(state).detail;
export const selectClientAvals = state => selectClient(state).detail.avals;
export const selectClientsListCount = state => selectClient(state).count;
export const selectClientsQueryOptions = state => {
  const { options } = selectClient(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

export const selectClientContractOptions = state =>
  selectClient(state).detail.contracts.options;

export const selectClientAddressesList = state => {
  return selectClient(state).detail.addresses;
};

export const selectClientContractList = state =>
  selectClient(state).detail.contracts.items;

export const selectClientContractsListCount = state =>
  selectClient(state).detail.contracts.count;

export const selectClientContractsTotalCount = state =>
  selectClient(state).detail.contracts.totalCount;

export const selectClientQuotes = state =>
  selectClient(state).detail.quotes.items;

// Sat Ws
export const selectClientSatWsDetail = state =>
  selectClient(state).detail.satWsDetail;

export const selectClientSatWsHistory = state =>
  selectClient(state).detail.satWsHistory.items;

export const selectClientSatWsHistoryCount = state =>
  selectClient(state).detail.satWsHistory.count;

// Administratives

export const selectAdministrativeList = state =>
  selectClient(state).detail.administratives.items;

export const selectAdministrativeCount = state =>
  selectClient(state).detail.administratives.count;

// Identity Validation
export const selectIdentityValidation = state =>
  selectClient(state).detail.identityValidation;

// Rating Validation
export const selectRatingValidation = state =>
  selectClient(state).detail.ratingValidation;

// Buro Validation
export const selectBuroValidation = state =>
  selectClient(state).detail.buroValidation;

export const selectClientBuroValidationHistory = state =>
  selectClient(state).detail.buroValidationHistory;

export const selectAvalBuroValidationHistory = state =>
  selectClient(state).detail.clientAvalDetail.buroValidationHistory;

export const selectAdministrativeOptions = state =>
  selectClient(state).detail.administratives.options;

export const selectClientAvalsList = state =>
  selectClient(state).detail.clientAvals.items;

export const selectClientDefaultLegalRepresentative = state =>
  selectClient(state).detail.clientAvals.items.find(
    aval => aval.isLegalRepresentative
  );

export const selectClientAvalCount = state =>
  selectClient(state).detail.clientAvals.count;

export const selectClientPersonType = state =>
  selectClient(state).detail.clientAvals.clientPersonType;

export const selectClientAvalDetail = state =>
  selectClient(state).detail.clientAvalDetail;

// Existing Person With Rfc

export const selectExistingAvalWithRfc = state =>
  selectClient(state).existingAvalWithRfc;

export const selectExistingClientWithRfc = state =>
  selectClient(state).existingClientWithRfc;

// bank account

export const selectClientBankAccount = state =>
  selectClient(state).detail.bankAccount;
