import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';
import GeneralContractDetail from './GeneralContractDetail';

const GeneralDetail = ({ contract }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Card variant="elevation">
        <CardHeader
          className={classes.header}
          title={
            <Typography
              className={classes.tableCell}
              component="h4"
              variant="h4"
            >
              {t('CONTRACT_VIEW.CONTRACT_DETAIL.CONTRACT_DETAIL')}
            </Typography>
          }
        />
        <Divider className={classes.tableCell} />
        <CardContent style={{ padding: 0, marginBottom: '16px' }}>
          <Table>
            <TableBody>
              <GeneralContractDetail contract={contract} />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

GeneralDetail.propTypes = {
  contract: PropTypes.object.isRequired
};

export default GeneralDetail;
