import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import KamListView from './KamListView';

const KamsView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/list`} component={KamListView} />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/list`} />}
      />
    </>
  );
};

KamsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default KamsView;
