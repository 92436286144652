export const MinCountNotifications = 5;
export const MinCountToChargeNotifications = 4;

export const NotificationStatus = {
  READ: 'READ',
  UNREAD: 'UNREAD'
};

export const NotificationType = {
  NEW_CONTRACT_REQUEST: 'NEW_CONTRACT_REQUEST',
  CONTRACT_REQUEST_APPROVED: 'CONTRACT_REQUEST_APPROVED',
  CONTRACT_REQUEST_REJECTED: 'CONTRACT_REQUEST_REJECTED',
  QUOTE_ASSIGNED: 'QUOTE_ASSIGNED',
  NUFI_VALIDATION_COMPLETED: 'NUFI_VALIDATION_COMPLETED',
  NUFI_VALIDATION_COMPLETED_SINGLE: 'NUFI_VALIDATION_COMPLETED_SINGLE',
  NUFI_VALIDATION_CURP_FAILED: 'NUFI_VALIDATION_CURP_FAILED',
  DISTRIBUTOR_PROFILE_REGISTRATION_REQUEST: 'DISTRIBUTOR_PROFILE_REGISTRATION_REQUEST',
  BURO_VALIDATION_FORM_COMPLETED: 'BURO_VALIDATION_FORM_COMPLETED',
};

export const NotificationCategory = {
  QUOTES: 'QUOTES',
  NUFI: 'NUFI',
  DISTRIBUTOR_PROFILE_REGISTRATION: 'DISTRIBUTOR_PROFILE_REGISTRATION',
  BURO_VALIDATION: 'BURO_VALIDATION',
};
