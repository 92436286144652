const makeInitialState = values => {
  return {
    ...values,
    distributor: values ? values.distributor : null,
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values ? values.price : '',
    distributorProfile: values ? values.distributorProfile : ''
  };
};

export default makeInitialState;
