import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import { UPDATE_EQUIPMENT } from 'src/store/actions/equipmentsActions';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditSaleProductKey = ({
  open,
  equipment,
  handleClose,
  submit
}) => {
  const classes = useStyles();
  const isLoading = useSelector(isLoadingSelector([UPDATE_EQUIPMENT.action]));

  const initialState = useMemo(() => makeInitialState(equipment), [equipment]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit({ ...difference });
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {`${t('COMMON.EDIT')} ${t('COMMON.SALE_PRODUCT_KEY').toLowerCase()}`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {isLoading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    fullWidth
                    label={t('COMMON.SALE_PRODUCT_KEY')}
                    margin="normal"
                    name="saleProductKey"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.saleProductKey}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

EditSaleProductKey.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  equipment: PropTypes.object
};

export default EditSaleProductKey;
