import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  validationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  validation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4)
  },
  validationIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start'
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      boxShadow: 'none',
      width: '40vw',
      height: '40vh'
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  lastUpdateContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'start',
    justifyContent: 'start'
  },
  validationIconsContainter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  validationIcons: {
    display: 'flex',
    alignItems: 'center'
  }
}));
