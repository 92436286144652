import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string()
    .matches(
      /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED')
});
