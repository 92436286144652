import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { defaultMenuOptions, CommonActions } from 'src/utils/constants/clients';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const MoreMenu = ({
  options = defaultMenuOptions,
  disabled,
  handleClickOption,
  message = '',
  editDisabled = false,
  deleteDisabled = false,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [listOptions, setListOptions] = useState([]);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionClick = option => {
    handleClickOption(option);
    setAnchorEl(null);
  };

  useEffect(() => {
    let filteredOptions = [...options];

    if (deleteDisabled) {
      filteredOptions = filteredOptions.filter(
        option => option.name !== CommonActions.DELETE
      );
    }

    if (editDisabled) {
      filteredOptions = filteredOptions.filter(
        option => option.name !== CommonActions.EDIT
      );
    }

    const defaultOptionsWithIcon = filteredOptions.map(option => {
      if (option.name === CommonActions.EDIT) {
        return {
          ...option,
          icon: <EditIcon />
        };
      }
      if (option.name === CommonActions.DELETE) {
        return {
          ...option,
          icon: <DeleteIcon />
        };
      }
      return option;
    });

    setListOptions(defaultOptionsWithIcon);
  }, [editDisabled, deleteDisabled, options]);

  return (
    <>
      <Tooltip title={message}>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disabled={disabled}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        {...rest}
      >
        {listOptions.map(option => {
          if (option.disabled) {
            return (
              <Tooltip title={t(option.message)}>
                <Box>
                  <MenuItem
                    key={option.name}
                    onClick={() => onOptionClick(option.name)}
                    disabled={option.disabled ?? false}
                  >
                    {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                    <Typography>{t(option.label)}</Typography>
                  </MenuItem>
                </Box>
              </Tooltip>
            );
          }
          return (
            <MenuItem
              key={option.name}
              onClick={() => onOptionClick(option.name)}
              disabled={option.disabled ?? false}
            >
              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
              <Typography>{t(option.label)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

MoreMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  disabled: PropTypes.bool,
  handleClickOption: PropTypes.func.isRequired,
  message: PropTypes.string,
  editDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool
};

export default MoreMenu;
