import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { successSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CLIENT_DETAIL } from 'src/store/actions/clientActions';
import Loading from 'src/components/Loading';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const AddClientToListDialog = ({ open, handleClose, onSubmit, data }) => {
  const classes = useStyles();
  const success = useSelector(successSelector([LOAD_CLIENT_DETAIL.action]));

  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle className={classes.header} id="form-distributor-delete">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CREATION_FORM.ADD_CLIENT_TO_LIST')}
        </Typography>
      </DialogTitle>
      {success ? (
        <>
          <DialogContent
            className={classes.confirmation}
            id="form-distributor-delete-dialog"
          >
            <DialogContentText>
              {t('CLIENTS_VIEW.CREATION_FORM.ADD_CLIENT_TO_LIST_CONFIRMATION')}
            </DialogContentText>
            <Table classes={{ root: classes.tableRoot }} component="div">
              <TableBody classes={{ root: classes.tableBody }} component="div">
                <TableRow component="div">
                  <TableCell
                    align="start"
                    colSpan={2}
                    className={classes.reviewDataSubtitle}
                  >
                    {t('CLIENTS_VIEW.CREATION_FORM.CLIENT_DATA')}
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('CLIENTS_VIEW.CREATION_FORM.PERSON_TYPE')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {t(`CLIENTS_VIEW.CREATION_FORM.${data.personType}`)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.NAME')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.fullName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.RFC')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.rfc}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              className={clsx(classes.buttons)}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.CANCEL')}
            </Button>

            <Button
              className={clsx(classes.buttonMain, classes.buttons)}
              onClick={onSubmit}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.ADD')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

AddClientToListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default AddClientToListDialog;
