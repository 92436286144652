import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { createDistributorCatalog } from 'src/store/actions/distributorActions';
import PropTypes from 'prop-types';
import theme from 'src/theme';
import clsx from 'clsx';
import CatalogsList from './CatalogsList';
import CatalogDialog from './CreateCatalog';
import useStyles from '../ContactsList/styles';
import UploadCatalogInExcelFormatDialog from './UploadCatalogInExcelFormatDialog/UploadCatalogInExcelFormatDialog';
import FailUploadCatalogEquipmentDialog from './UploadCatalogInExcelFormatDialog/FailUploadCatalogEquipmentDialog';

const CatalogsDetail = ({ isDisableActions = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openFailUpload, setOpenFailUpload] = useState(false);

  const handleOpenFailUploadCatalogEquipmentDialog = () => {
    setOpenFailUpload(true);
  };

  const handleCloseFailUploadCatalogEquipmentDialog = () => {
    setOpenFailUpload(false);
  };

  const handleOpenCatalogForm = () => {
    setOpen(true);
  };

  const handleCloseCatalogForm = () => {
    setOpen(false);
  };

  const handleOpenUploadCatalogForm = () => {
    setOpenUpload(true);
  };

  const handleCloseUploadCatalogForm = () => {
    setOpenUpload(false);
  };

  const handleCreateDistributorCatalog = useCallback(
    values => {
      dispatch(createDistributorCatalog(id, values));
    },
    [dispatch, handleCloseCatalogForm]
  );
  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid
          item
          lg={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'start'
          }}
        >
          <Button
            className={classes.buttons}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            disabled={isDisableActions}
            onClick={handleOpenCatalogForm}
          >
            {t('DISTRIBUTORS_VIEW.CREATE_CATALOG')}
          </Button>

          <Tooltip title={t('DISTRIBUTORS_VIEW.UPLOAD_CATALOG_IN_EXCEL')}>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              sx={{
                marginLeft: '10px',
                backgroundColor: theme.palette.common.white
              }}
              variant="contained"
              disabled={isDisableActions}
              onClick={handleOpenUploadCatalogForm}
            >
              <UploadFileIcon className={classes.icon} />
            </Button>
          </Tooltip>

          <CatalogDialog
            open={open}
            onSubmit={handleCreateDistributorCatalog}
            handleClose={handleCloseCatalogForm}
          />
          <UploadCatalogInExcelFormatDialog
            open={openUpload}
            distributorId={id}
            handleClose={handleCloseUploadCatalogForm}
            handleOpenFailUpload={handleOpenFailUploadCatalogEquipmentDialog}
          />
          <FailUploadCatalogEquipmentDialog
            open={openFailUpload}
            handleClose={handleCloseFailUploadCatalogEquipmentDialog}
          />
        </Grid>
        <Grid item lg={12}>
          <CatalogsList isDisableActions={isDisableActions} />
        </Grid>
      </Grid>
    </Box>
  );
};

CatalogsDetail.propTypes = {
  isDisableActions: PropTypes.bool
};

export default CatalogsDetail;
