const makeInitialState = () => {
  return {
    clientId: null,
    clientName: null,
    clientPersonType: null,
    equipmentId: null,
    rate: null,
    cat: null,
    initialPaymentPorcentaje: null,
    term: null,
    equipmentCost: null,
    initialPayment: null,
    netInvestment: null,
    rent: null,
    residualPayment: null,
    createdAt: null,
    startDate: null,
    endDate: null,
    firstRent: null,
    equipmentLocation: {
      street: null,
      insideHomeNumber: null,
      externalHomeNumber: null,
      colonia: null,
      city: null,
      state: null,
      zipCode: null
    },
    includeClientAddress: false,
    configuredEquipmentLocation: false,
    avals: [],
    sendToDigitalSign: false
  };
};

export default makeInitialState;
