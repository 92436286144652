import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EXTERNAL_PAYMENT_METHODS } from 'src/utils/common';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import {
  TransactionActions,
  TransactionsType
} from 'src/utils/constants/transactions';
import { Link } from 'react-router-dom';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { Tooltip } from '@mui/material';
import OverflowTipography from 'src/components/OverflowTipography';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import Assign from '@mui/icons-material/PublishedWithChanges';
import { useDispatch } from 'react-redux';
import { assignTransactionToContract } from 'src/store/actions/transactionActions';
import AssignTransactionToContractDialog from './AssignTransactionToContractDialog';

const getTransactionOptions = (document, equipmentId) => {
  return [
    {
      name: TransactionActions.SEE_DOCUMENT,
      label: `ENUMS.QUOTE_ACTIONS.SEE_DOCUMENT`,
      disabled: !document,
      icon: <FileOpenIcon />
    },
    {
      name: TransactionActions.ASSIGN_TRANSACTION_TO_CONTRACT,
      label: `TRANSACTION.ASSOCIATE_TRANSACTION_TO_CONTRACT`,
      disabled: equipmentId,
      icon: <Assign />
    }
  ];
};

const TransactionRow = ({
  type,
  origin,
  category,
  amountIvaIncluded,
  comment,
  date,
  reference,
  completedAt,
  clientId,
  client,
  equipmentId,
  document,
  contractId,
  contractFolio,
  id
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
  const [
    openAssignTransactionToContractDialog,
    setOpenAssignTransactionToContractDialog
  ] = useState(false);

  const handleCloseDocumentViewer = () => {
    setOpenDocumentViewer(false);
  };

  const handleCloseAssignInvoiceToContractDialog = () => {
    setOpenAssignTransactionToContractDialog(false);
  };

  const handleClickMore = option => {
    if (option === TransactionActions.SEE_DOCUMENT) {
      setOpenDocumentViewer(true);
    }
    if (option === TransactionActions.ASSIGN_TRANSACTION_TO_CONTRACT) {
      setOpenAssignTransactionToContractDialog(true);
    }
  };

  const handleAssignInvoiceToContract = useCallback(
    async values => {
      dispatch(
        assignTransactionToContract(
          id,
          values,
          handleCloseAssignInvoiceToContractDialog
        )
      );
    },
    [id]
  );

  return (
    <TableRow hover align="center">
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {selectFormatDate(date, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center">{moment(date).format('HH:mm')}</TableCell>
      <TableCell align="center">
        <Link to={`/contracts/${contractId}`}>{contractFolio}</Link>
      </TableCell>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {client && client.length > 90 ? (
          <Tooltip title={client}>
            <Link to={`/clients/detail/${clientId}`}>
              <OverflowTipography maxWidth="250px">
                {`${client.slice(0, 90)}...`}
              </OverflowTipography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <OverflowTipography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {client}
            </OverflowTipography>
          </Link>
        )}
      </TableCell>
      <TableCell align="center">
        {equipmentId ? (
          <Link to={`/inventory/${equipmentId}`}>{equipmentId}</Link>
        ) : (
          <TooltipTypography
            title={t('TRANSACTION.NO_EQUIPMENT_ASSOCIATED')}
            text="--"
          />
        )}
      </TableCell>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="center"
      >
        {t(`TRANSACTION.CATEGORY.${category}`)}
      </TableCell>
      <TableCell align="center">{t(`TRANSACTION.ORIGIN.${origin}`)}</TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {type === TransactionsType.PAYMENT
            ? '--'
            : formatPositiveAmount(amountIvaIncluded)}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {type === TransactionsType.PAYMENT
            ? formatPositiveAmount(amountIvaIncluded)
            : '--'}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">{reference || '--'}</TableCell>
      <TableCell align="center">
        {EXTERNAL_PAYMENT_METHODS.includes(origin) && !!completedAt
          ? moment(completedAt).format('HH:mm')
          : '--'}
      </TableCell>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="center"
      >
        <OverflowTipography>{comment || '--'}</OverflowTipography>
      </TableCell>
      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MoreMenu
          deleteDisabled
          editDisabled
          options={getTransactionOptions(document, contractId)}
          handleClickOption={handleClickMore}
        />
      </TableCell>
      {openDocumentViewer && document.documentUrl && (
        <DocumentViewerDialog
          documentUrl={document.documentUrl}
          open={openDocumentViewer}
          onClose={handleCloseDocumentViewer}
        />
      )}
      {openAssignTransactionToContractDialog && (
        <AssignTransactionToContractDialog
          handleAssignInvoiceToContract={handleAssignInvoiceToContract}
          open={openAssignTransactionToContractDialog}
          transaction={{ id, clientId, equipmentId }}
          handleClose={handleCloseAssignInvoiceToContractDialog}
        />
      )}
    </TableRow>
  );
};

TransactionRow.propTypes = {
  type: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  amountIvaIncluded: PropTypes.number.isRequired,
  comment: PropTypes.string,
  date: PropTypes.string.isRequired,
  reference: PropTypes.string,
  completedAt: PropTypes.string,
  clientId: PropTypes.string,
  client: PropTypes.string,
  equipmentId: PropTypes.string,
  document: PropTypes.object,
  contractId: PropTypes.string,
  contractFolio: PropTypes.string,
  id: PropTypes.string
};

export default TransactionRow;
