/* eslint-disable import/prefer-default-export */
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAdminUsers = serviceBase({
  request: options =>
    axiosClient.get('/user', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const createAdminUser = serviceBase({
  request: values => axiosClient.post('/user', values),
  retry: 0
});

export const updateAdminUser = serviceBase({
  request: ({ id, values }) => axiosClient.put(`/user/${id}`, values),
  retry: 0
});

export const resendAdministratorValidationEmail = serviceBase({
  request: id => axiosClient.post(`/user/verification-email/${id}`),
  retry: 0
});
