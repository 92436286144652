import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import NotFoundRecords from 'src/components/NotFoundRecords';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectInteresetBalanceList,
  selectInteresetBalanceListCount,
  selectInteresetBalanceQueryOptions
} from 'src/store/selectors/interestBalanceSelectors';
import {
  loadInterestBalance,
  LOAD_INTEREST_BALANCE
} from 'src/store/actions/interesetBalanceActions';
import Toolbar from './Toolbar';
import InterestBalanceTable from './InterestBalanceTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InterestBalanceList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const interestBalance = useSelector(selectInteresetBalanceList);
  const count = useSelector(selectInteresetBalanceListCount);
  const { page } = useSelector(selectInteresetBalanceQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_INTEREST_BALANCE.action])
  );
  const success = useSelector(successSelector([LOAD_INTEREST_BALANCE.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInterestBalance({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.BALANCE_INTEREST_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.BALANCE_INTEREST_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <InterestBalanceTable
      interestBalance={interestBalance}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

const InteresetBalanceListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.INTEREST_BALANCE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <InterestBalanceList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default InteresetBalanceListView;
