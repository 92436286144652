import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType } from 'src/utils/constants/clients';
import { useParams } from 'react-router';
import selectDocumentType from 'src/utils/selectDocumentType';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';
import DetailCard from 'src/components/DetailBox/DetailCard';
import { useSelector } from 'react-redux';
import { selectInvestmentsDetail } from 'src/store/selectors/investmentSelector';

const DocumentInvestmentDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const investment = useSelector(selectInvestmentsDetail);

  return (
    <DetailCard title={t('COMMON.DOCUMENTS')}>
      <DetailRow
        name={t('INVESTMENTS_VIEW.DOCUMENTS.PROOF_OF_PAYMENT')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.PROOF_OF_PAYMENT,
              investment.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.PROOF_OF_PAYMENT}
          investmentId={Number(id)}
          documents={Boolean(
            selectDocumentType(
              DocumentType.PROOF_OF_PAYMENT,
              investment.documents
            )
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('INVESTMENTS_VIEW.DOCUMENTS.RECORD')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.RECORD, investment.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.RECORD}
          investmentId={Number(id)}
          documents={Boolean(
            selectDocumentType(DocumentType.RECORD, investment.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('INVESTMENTS_VIEW.DOCUMENTS.ACCOUNT_STATEMENT')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.ACCOUNT_STATEMENT,
              investment.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.ACCOUNT_STATEMENT}
          investmentId={Number(id)}
          documents={Boolean(
            selectDocumentType(
              DocumentType.ACCOUNT_STATEMENT,
              investment.documents
            )
          )}
        />
      </DetailRow>
      <DetailRow
        name={t('COMMON.OTHERS')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.OTHER, investment.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.OTHER}
          investmentId={Number(id)}
          documents={Boolean(
            selectDocumentType(DocumentType.OTHER, investment.documents)
          )}
        />
      </DetailRow>
    </DetailCard>
  );
};

export default DocumentInvestmentDetail;
