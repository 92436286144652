import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Switch, TableRow, Tooltip } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import { editParameterAction } from 'src/store/actions/parameterActions';
import { useDispatch } from 'react-redux';

const NotificationsParameterRow = ({
  name,
  id,
  value,
  isDisabledEdit = false,
  disabledSwitch = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEdit = useCallback(
    values => {
      dispatch(editParameterAction(values));
    },
    [dispatch]
  );

  const isChecked = useCallback(() => {
    if (
      name === 'ENABLE_NOTIFICATIONS' ||
      name === 'SEND_BILLING_EMAIL_NOTIFICATIONS'
    ) {
      return value === 'true';
    }

    return isDisabledEdit ? false : value === 'true';
  }, [name, value]);

  const disabled =
    isDisabledEdit &&
    name !== 'ENABLE_NOTIFICATIONS' &&
    name !== 'SEND_BILLING_EMAIL_NOTIFICATIONS';

  return (
    <TableRow hover>
      <TableCell>{t(`PARAMETER_VIEW.${name}`)}</TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            disabled ? t('PARAMETER_VIEW.NOTIFICATIONS_DISABLED_EDIT') : ''
          }
          placement="top"
        >
          <Box sx={{ width: '100%' }}>
            <Switch
              checked={isChecked()}
              onChange={() => handleEdit({ name, value: !isChecked(), id })}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={disabled || disabledSwitch}
            />
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

NotificationsParameterRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isDisabledEdit: PropTypes.bool,
  id: PropTypes.number,
  disabledSwitch: PropTypes.bool
};

export default NotificationsParameterRow;
