import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Box, Card, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  loadEquipmentDetail,
  LOAD_EQUIPMENT_DETAIL
} from 'src/store/actions/equipmentsActions';
import { selectEquipmentDetail } from 'src/store/selectors/equipmentSelectors';
import EquipmentTabs from './EquipmentTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const EquipmentDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const equipment = useSelector(selectEquipmentDetail);
  const loadingEquipment = useSelector(
    isLoadingSelector([LOAD_EQUIPMENT_DETAIL.action])
  );

  const success = useSelector(successSelector([LOAD_EQUIPMENT_DETAIL.action]));

  useEffect(() => {
    dispatch(loadEquipmentDetail(id));
  }, [dispatch, id]);

  return (
    <Page className={classes.root} title={`Equipo ${equipment ? id : ''}`}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loadingEquipment && equipment ? (
              <EquipmentTabs equipment={equipment} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default EquipmentDetailView;
