import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectClientContractList,
  selectClientContractOptions,
  selectClientContractsListCount
} from 'src/store/selectors/clientSelectors';
import {
  LOAD_CLIENT_CONTRACTS,
  loadClientContracts
} from 'src/store/actions/clientActions';
import ContractsTable from './ContractsTable';
import useStyles from './styles';

const ContractsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contracts = useSelector(selectClientContractList);
  const count = useSelector(selectClientContractsListCount);
  const { page } = useSelector(selectClientContractOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_CLIENT_CONTRACTS.action])
  );
  const success = useSelector(successSelector([LOAD_CLIENT_CONTRACTS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadClientContracts({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.CONTRACT_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.CONTRACT_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <ContractsTable
      contracts={contracts}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default ContractsList;
