import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));
