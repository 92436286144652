import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';

const EquipmentAlert = ({ equipments, loading }) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading && !equipments.length) {
    return (
      <Collapse in={open}>
        <Alert severity="error">
          {t('CONTRACT_VIEW.CONTRACT_FORM.ALERT.NO_EQUIPMENTS_FIRST')}
          <Link to="/inventory">
            {t('CONTRACT_VIEW.CONTRACT_FORM.ALERT.NO_EQUIPMENTS_SECOND')}
          </Link>
        </Alert>
      </Collapse>
    );
  }

  return null;
};

export default EquipmentAlert;

EquipmentAlert.propTypes = {
  equipments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};
