import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, IconButton } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const classes = useStyles();

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={goBack} color="primary" component="span">
              <ArrowLeft />
            </IconButton>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.CATALOG_LIST.SANTANDER_BANKS')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  contractStatus: PropTypes.string
};

export default Toolbar;
