import {
  downloadExportedInteresetBalance,
  getInterestBalance
} from 'src/services/interestBalanceService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectInteresetBalanceQueryOptions } from '../selectors/interestBalanceSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_INTEREST_BALANCE = asyncActionCreator(
  'LOAD_INTEREST_BALANCE'
);
export const DOWNLOAD_INTERESET_BALANCE = asyncActionCreator(
  'DOWNLOAD_INTERESET_BALANCE'
);
export const CLEAR_INTERESET_BALANCE = 'CLEAR_INTERESET_BALANCE';
export const SAVE_INTERESET_BALANCE_OPTIONS = 'SAVE_INTERESET_BALANCE_OPTIONS';
export const CLEAR_INTERESET_BALANCE_OPTIONS =
  'CLEAR_INTERESET_BALANCE_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectInteresetBalanceQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }
  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_INTERESET_BALANCE_OPTIONS });
};

export const loadInterestBalance = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INTEREST_BALANCE.start });

    dispatch({
      type: SAVE_INTERESET_BALANCE_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const interesetBalance = await getInterestBalance(reqOptions);

    return dispatch({
      type: LOAD_INTEREST_BALANCE.success,
      ...interesetBalance
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INTEREST_BALANCE.failure });
  }
};

export const downloadExcelInterestBalance = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOWNLOAD_INTERESET_BALANCE.start
    });

    dispatch({
      type: SAVE_INTERESET_BALANCE_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExportedInteresetBalance(reqOptions);

    return dispatch({
      type: DOWNLOAD_INTERESET_BALANCE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_INTERESET_BALANCE.failure });
  }
};
