import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';

const NufiParameterRow = ({ name, value }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell>{t(`PARAMETER_VIEW.${name}`)}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>{}</TableCell>
    </TableRow>
  );
};

NufiParameterRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
};

export default NufiParameterRow;
