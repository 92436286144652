import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import DistributorsListView from './Distributors';
import DistributorDetailView from './DistributorDetail';
import MetricsView from './Metrics';
import ResumeView from './Resume';
import ModelsListView from '../catalog/ModelsListView';

const BusinessView = ({ match }) => {
  return (
    <>
      <Route
        exact
        path={`${match.url}/business`}
        component={DistributorsListView}
      />
      <Route
        exact
        path={`${match.url}/business/:id/models/:brandId`}
        component={ModelsListView}
      />
      <Route
        exact
        path={`${match.url}/business/:id/:tab?`}
        component={DistributorDetailView}
      />
      <Route exact path={`${match.url}/metrics`} component={MetricsView} />

      <Route exact path={`${match.url}/resume`} component={ResumeView} />

      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/business`} />}
      />
    </>
  );
};

BusinessView.propTypes = {
  match: PropTypes.object.isRequired
};

export default BusinessView;
