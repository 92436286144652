import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import AmortizationBalanceListView from './AmortizationBalanceList';

const PolicyView = ({ match }) => {
  return (
    <>
      <Route
        path={`${match.url}/amortization-balance`}
        component={AmortizationBalanceListView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/amortization-balance`} />}
      />
    </>
  );
};

PolicyView.propTypes = {
  match: PropTypes.object.isRequired
};

export default PolicyView;
