/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserType, {
  URL_DEFAULT_REDIRECT,
  UserRoles
} from 'src/utils/constants/auth';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUserRedirectBySystemRol } from 'src/utils/common';

const SuperAdminPrivateRoute = ({
  component: Component,
  loggedIn,
  systemRole,
  system,
  role,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <>
            {systemRole && role ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: selectUserRedirectBySystemRol(system),
                  state: { referrer: props.location }
                }}
              />
            )}
          </>
        ) : (
          window.location.replace(URL_DEFAULT_REDIRECT)
        )
      }
    />
  );
};

SuperAdminPrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  loggedIn: PropTypes.bool,
  system: PropTypes.string,
  systemRole: PropTypes.bool,
  role: PropTypes.bool
};

export default withRouter(
  connect(state => ({
    loggedIn: state.auth.token !== null,
    systemRole: state.auth.system === UserType.ADMIN,
    role: state.auth.role === UserRoles.SUPER_ADMIN,
    system: state.auth.system
  }))(SuperAdminPrivateRoute)
);
