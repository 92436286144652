import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const buildCrud = (basename, pluralBasename) => ({
  create: serviceBase({
    request: values => axiosClient.post(`/catalog/${basename}`, values),
    retry: 0
  }),
  read: serviceBase({
    request: () => axiosClient.get(`/catalog/${pluralBasename}`),
    retry: 0
  }),
  update: serviceBase({
    request: values => axiosClient.put(`/catalog/${basename}`, values),
    retry: 0
  }),
  delete: serviceBase({
    request: id => axiosClient.delete(`/catalog/${basename}/${id}`),
    retry: 0
  })
});

const {
  create: createIncident,
  read: getIncidents,
  update: editIncident,
  delete: deleteIncident
} = buildCrud('incident', 'incidents');

const {
  create: createLocation,
  read: getLocations,
  update: editLocation,
  delete: deleteLocation
} = buildCrud('location', 'locations');

const {
  create: createService,
  read: getServices,
  update: editService,
  delete: deleteService
} = buildCrud('service', 'services');

const { create: createBrand, read: getBrands } = buildCrud('brand', 'brands');

const {
  create: createCompany,
  read: getCompanies,
  update: editCompany,
  delete: deleteCompany
} = buildCrud('company', 'companies');

const {
  create: createAgent,
  read: getAgents,
  update: editAgent,
  delete: deleteAgent
} = buildCrud('agency', 'agencies');

const {
  create: createInsurer,
  read: getInsurers,
  update: editInsurer,
  delete: deleteInsurer
} = buildCrud('insurer', 'insurers');

const {
  create: createProvider,
  read: getProviders,
  update: editProvider,
  delete: deleteProvider
} = buildCrud('provider', 'providers');

const {
  create: createQuoteCategory,
  read: getQuoteCategories,
  update: editQuoteCategory,
  delete: deleteQuoteCategory
} = buildCrud('quote-category', 'quote-categories');

const {
  create: createKinhip,
  read: getKinships,
  update: editKinship,
  delete: deleteKinship
} = buildCrud('kinship-type', 'kinship-types');

const {
  create: createLineOfBusiness,
  read: getLineOfBusiness,
  update: editLineOfBusiness,
  delete: deleteLineOfBusiness
} = buildCrud('line-of-business-type', 'line-of-business-types');

const {
  create: createEconomicActivity,
  read: getEconomicActivities,
  update: editEconomicActivity,
  delete: deleteEconomicActivity
} = buildCrud('economic_activity', 'economic_activities');

const {
  create: createIndustry,
  read: getIndustries,
  update: editIndustry,
  delete: deleteIndustry
} = buildCrud('industry', 'industries');

const { read: getTaxSystems } = buildCrud('tax_system', 'tax_systems');

const { read: getBillingConceptList } = buildCrud(
  'billing_concept',
  'billing_concepts'
);

const {
  create: createEquipmentType,
  read: getEquipmentTypes,
  update: editEquipmentType,
  delete: deleteEquipmentType
} = buildCrud('equipment_type', 'equipment-types');

const {
  create: createDenomination,
  read: getDenominations,
  update: editDenomination,
  delete: deleteDenomination
} = buildCrud('denomination', 'denominations');

const { create: createModel } = buildCrud('model', 'models');

const {
  create: createAdministrativeRole,
  read: getAdministrativeRoles,
  update: editAdministrativeRole,
  delete: deleteAdministrativeRole
} = buildCrud('administrative-role', 'administrative-roles');

const {
  create: createBank,
  read: getBanks,
  update: editBank,
  delete: deleteBank
} = buildCrud('bank', 'banks');

const getModels = serviceBase({
  request: id => axiosClient.get(`/catalog/models/${id}`)
});

const editModel = serviceBase({
  request: ({ values }) =>
    axiosClient.put(`/catalog/model/${values.id}`, values)
});

const deleteModel = serviceBase({
  request: values => axiosClient.delete(`/catalog/model/${values.id}`, values)
});

const editBrand = serviceBase({
  request: values => axiosClient.put(`/catalog/brand/${values.id}`, values)
});

const deleteBrand = serviceBase({
  request: values =>
    axiosClient.delete(`/catalog/brand/${values.id}`, { data: values })
});

const getDistributorModels = serviceBase({
  request: ({ id, distributorId }) =>
    axiosClient.get(`/catalog/models/${id}`, {
      params: {
        distributorId
      }
    })
});

const getDistributorBrands = serviceBase({
  request: id =>
    axiosClient.get(`/catalog/brands`, {
      params: {
        distributorId: id
      }
    })
});

export {
  createAgent,
  getAgents,
  editAgent,
  deleteAgent,
  createIncident,
  getIncidents,
  editIncident,
  deleteIncident,
  getCompanies,
  createCompany,
  editCompany,
  deleteCompany,
  getServices,
  createService,
  editService,
  deleteService,
  getBrands,
  createBrand,
  editBrand,
  deleteBrand,
  getLocations,
  createLocation,
  editLocation,
  deleteLocation,
  createInsurer,
  getInsurers,
  editInsurer,
  deleteInsurer,
  createProvider,
  getProviders,
  editProvider,
  deleteProvider,
  getTaxSystems,
  createEquipmentType,
  getEquipmentTypes,
  editEquipmentType,
  deleteEquipmentType,
  createDenomination,
  getDenominations,
  editDenomination,
  deleteDenomination,
  getBillingConceptList,
  createQuoteCategory,
  getQuoteCategories,
  editQuoteCategory,
  deleteQuoteCategory,
  createModel,
  editModel,
  deleteModel,
  getModels,
  createKinhip,
  getKinships,
  editKinship,
  deleteKinship,
  createLineOfBusiness,
  getLineOfBusiness,
  editLineOfBusiness,
  deleteLineOfBusiness,
  getDistributorBrands,
  getDistributorModels,
  getIndustries,
  editIndustry,
  deleteIndustry,
  createIndustry,
  createAdministrativeRole,
  getAdministrativeRoles,
  editAdministrativeRole,
  deleteAdministrativeRole,
  createEconomicActivity,
  getEconomicActivities,
  editEconomicActivity,
  deleteEconomicActivity,
  getBanks,
  createBank,
  editBank,
  deleteBank
};
