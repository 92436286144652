import React from 'react';
import { Box, Grid } from '@mui/material';
import CatalogsDetail from './CatalogsDetail/CatalogsDetail';

const CatalogsView = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CatalogsDetail />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CatalogsView;
