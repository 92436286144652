import React from 'react';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useStyles from './styles';

const Dropzone = ({
  handleFileChange,
  handleFileReject,
  acceptedFileFormat,
  disabledIcon = false,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileFormat,
    onDropAccepted: handleFileChange,
    onDropRejected: handleFileReject,
    maxFiles: 1,
    preventDropOnDocument: true
  });

  return (
    <Box {...getRootProps()} className={classes.root} {...props}>
      <input {...getInputProps()} />
      {!disabledIcon && <CloudUploadIcon fontSize="20px" />}
      <Typography variant="body1">{t('COMMON.DROPZONE_TEXT')}</Typography>
    </Box>
  );
};

Dropzone.propTypes = {
  handleFileChange: PropTypes.func,
  handleFileReject: PropTypes.func,
  acceptedFileFormat: PropTypes.object,
  disabledIcon: PropTypes.bool
};

export default Dropzone;
