import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { roundNumberToDecimals } from 'src/utils/common';

const RentBalanceScheduleRow = ({
  period,
  date,
  monthlyPrincipal,
  interest,
  rent,
  iva,
  total
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">{period}</TableCell>
      <TableCell align="center">{date}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumberToDecimals(monthlyPrincipal))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumberToDecimals(interest))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumberToDecimals(rent))}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(roundNumberToDecimals(iva))}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumberToDecimals(total))}
      </TableCell>
    </TableRow>
  );
};

RentBalanceScheduleRow.propTypes = {
  period: PropTypes.number,
  date: PropTypes.string,
  monthlyPrincipal: PropTypes.number,
  interest: PropTypes.number,
  rent: PropTypes.number,
  iva: PropTypes.number,
  total: PropTypes.number
};

export default RentBalanceScheduleRow;
