import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteIndustryAction,
  editIndustryAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import IndustriesForm from './IndustryForm';

const IndustriesRow = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openIndustryForm, setOpenIndustryForm] = useState(false);
  const [openIndustryDelete, setOpenIndustryDelete] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenIndustryDelete(false);
  };

  const handleCloseModelForm = () => {
    setOpenIndustryForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenIndustryForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenIndustryDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteIndustryAction(id, handleCloseDeleteModal));
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editIndustryAction(values, handleCloseModelForm));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <IndustriesForm
        data={{ name, id }}
        open={openIndustryForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openIndustryDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.INDUSTRIES.DELETE_INDUSTRY_TITLE')}
        description={t('CATALOGS_VIEW.INDUSTRIES.DELETE_INDUSTRY_DESCRIPTION')}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

IndustriesRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default IndustriesRow;
