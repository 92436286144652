import {
  CLEAR_INVESTOR_OPTIONS,
  LOAD_INVESTORS,
  SAVE_INVESTOR_OPTIONS,
  CLEAR_INVESTORS,
  LOAD_INVESTOR_DETAIL,
  CLEAR_INVESTOR_DETAIL,
  LOAD_INVESTOR_KYC_DETAIL,
  LOAD_INVESTOR_INVESMENTS,
  SAVE_INVESTOR_INVESTMENTS_OPTIONS,
  LOAD_INVESTOR_IDENTITY_VALIDATION,
  CLEAR_INVESTOR_IDENTITY_VALIDATION,
} from '../actions/investorActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
    // sort: { property: 'remainingDays', order: 'asc' },
  },
  items: [],
  count: 0,
  detail: {
    address: {},
    documents: [],
    kyc: {},
    investments: {
      items: [],
      count: 0,
      options: {
        limit: +process.env.REACT_APP_TABLE_SIZE,
        page: 0
      }
    }
  },
  identityValidation: {}
};

const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INVESTOR_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_INVESTOR_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_INVESTORS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_INVESTOR_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.data
        }
      };
    }
    case LOAD_INVESTOR_KYC_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          kyc: {
            ...state.detail.kyc,
            ...action.data
          }
        }
      };
    }
    case LOAD_INVESTOR_INVESMENTS.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          investments: {
            ...state.detail.investments,
            items: action.items,
            count: action.count
          }
        }
      };
    }
    case SAVE_INVESTOR_INVESTMENTS_OPTIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          investments: {
            ...state.detail.investments,
            options: { ...state.options, ...(action.options || {}) }
          }
        }
      };
    }

    case LOAD_INVESTOR_IDENTITY_VALIDATION.success: {
      return {
        ...state,
        identityValidation: action.payload
      };
    }

    case CLEAR_INVESTOR_IDENTITY_VALIDATION: {
      return {
        ...state,
        identityValidation: initialState.identityValidation
      };
    }

    case CLEAR_INVESTOR_DETAIL: {
      return {
        ...state,
        detail: initialState.detail
      };
    }
    case CLEAR_INVESTORS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default investorReducer;
