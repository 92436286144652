import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import BuroValidationHistoryRow from './BuroValidationHistoryRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ historyList }) => {
  return historyList.map(history => {
    return <BuroValidationHistoryRow key={history.documentUrl} {...history} />;
  });
};

const BuroValidationHistoryTable = ({
  className,
  histories,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell style={{ width: '10%' }}>{t('COMMON.DATE')}</TableCell>
      <TableCell style={{ width: '45%' }}>{t('COMMON.DOCUMENT')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody historyList={histories} />, [
    histories
  ]);

  if (!loading && (!histories || histories.length === 0)) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.BURO_VALIDATION_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      style={{ tableLayout: 'fixed', width: '100%' }}
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

BuroValidationHistoryTable.propTypes = {
  className: PropTypes.string,
  histories: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default BuroValidationHistoryTable;
