import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadDenominationsList,
  loadEquipmentTypesList
} from 'src/store/actions/catalogActions';
import { getAddressByZipCode } from 'src/store/actions/clientActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  LOAD_QUOTE_CATEGORIES,
  loadQuoteCategoriesList
} from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';
import DistributorAlert from './DistributorAlert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DistributorForm = ({ open, handleClose, submit }) => {
  const [denominations, setDenominations] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [addressData, setAddressData] = useState({});
  const [isLoadedZipcode, setIsLoadedZipcode] = useState(false);
  const [addressCityList, setAddressCityList] = useState([]);
  const [addressStateList, setAddressStateList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const classes = useStyles();
  const isLoadingCategoriesList = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );
  const dispatch = useDispatch();
  const initialState = makeInitialState();
  const resetRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(loadDenominationsList(setDenominations));
      dispatch(loadEquipmentTypesList(setEquipmentTypes));
      dispatch(loadQuoteCategoriesList(setCategoryList));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [dispatch, open]);

  useEffect(() => {
    if (Object.entries(addressData).length > 0) {
      setIsLoadedZipcode(true);
      setAddressCityList(
        addressData.cities.map(city => ({
          key: city,
          name: city
        }))
      );
      setAddressStateList(
        addressData.states.map(state => ({
          key: state,
          name: state
        }))
      );
    }
  }, [addressData]);

  const handleZipCodeChange = useCallback(e => {
    const { value } = e.target;
    if (value.length === 5) {
      dispatch(getAddressByZipCode(value, setAddressData));
    }
  });

  const handleCreateDistributor = useCallback(
    (values, { resetForm }) => {
      submit({
        ...values
      });
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="distributor-form">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.CREATE_DISTRIBUTOR')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '0.5rem' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleCreateDistributor}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <DistributorAlert
                  denominations={denominations}
                  equipmentTypes={equipmentTypes}
                  categoryList={categoryList}
                  loading={isLoadingCategoriesList}
                />
                ;
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && t(errors.name)}
                      label={t('COMMON.NAME')}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.denomination && errors.denomination
                      )}
                    >
                      <Autocomplete
                        options={denominations}
                        id="denomination"
                        label={t('COMMON.DENOMINATION')}
                        getOptionLabel={option =>
                          option.name ? `${option.name}` : ''
                        }
                        value={
                          denominations.find(
                            denomination =>
                              denomination.name === values.denomination
                          ) ?? null
                        }
                        fullWidth
                        onChange={(_e, value) => {
                          setFieldValue(
                            'denomination',
                            value ? value.name : ''
                          );
                        }}
                        sx={{ marginBottom: 1, marginTop: 2 }}
                        helperText={
                          touched.denomination && t(errors.denomination)
                        }
                        error={Boolean(
                          touched.denomination && errors.denomination
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.commercialName && errors.commercialName
                      )}
                      fullWidth
                      helperText={
                        touched.commercialName && t(errors.commercialName)
                      }
                      label={t('COMMON.COMERCIAL_NAME')}
                      margin="normal"
                      name="commercialName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.commercialName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.equipmentTypeId && errors.equipmentTypeId
                      )}
                    >
                      <Autocomplete
                        options={equipmentTypes}
                        id="equipmentTypeId"
                        getOptionLabel={option =>
                          option.name ? `${option.name}` : ''
                        }
                        value={
                          equipmentTypes.find(
                            equipment => equipment.id === values.equipmentTypeId
                          ) ?? null
                        }
                        fullWidth
                        onChange={(_e, value) => {
                          setFieldValue('equipmentTypeId', value?.id ?? '');
                        }}
                        sx={{ marginBottom: 1, marginTop: 2 }}
                        helperText={
                          touched.equipmentTypeId && t(errors.equipmentTypeId)
                        }
                        label={t('COMMON.EQUIPMENT_TYPE')}
                        error={Boolean(
                          touched.equipmentTypeId && errors.equipmentTypeId
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.rfc && errors.rfc)}
                      fullWidth
                      helperText={touched.rfc && t(errors.rfc)}
                      label={t('COMMON.RFC')}
                      margin="normal"
                      name="rfc"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.rfc}
                      variant="outlined"
                    />
                  </Grid>

                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.quoteCategory && errors.quoteCategory
                    )}
                  >
                    <Autocomplete
                      options={categoryList}
                      id="quoteCategory"
                      loading={isLoadingCategoriesList}
                      getOptionLabel={option =>
                        option.name ? `${option.name}` : ''
                      }
                      label={t('DISTRIBUTORS_VIEW.PARAMETERS.CATEGORY')}
                      value={
                        categoryList.find(
                          category => category.id === values.quoteCategory?.id
                        ) ?? null
                      }
                      fullWidth
                      margin="normal"
                      onChange={(_e, value) => {
                        setFieldValue('quoteCategory', value ?? '');
                        setFieldValue('quoteCategoryId', value?.id ?? '');
                      }}
                      sx={{ marginBottom: 1, marginTop: 2 }}
                      error={Boolean(
                        touched.quoteCategory && errors.quoteCategory
                      )}
                      helperText={
                        touched.quoteCategory && t(errors.quoteCategory)
                      }
                    />
                  </FormControl>

                  <Grid item>
                    <TextField
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      fullWidth
                      helperText={touched.zipCode && t(errors.zipCode)}
                      label={t('COMMON.ZIP_CODE')}
                      inputProps={{ maxLength: 5, autoComplete: 'off' }}
                      margin="normal"
                      name="zipCode"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleZipCodeChange(e);
                        handleChange(e);
                      }}
                      type="text"
                      value={values.zipCode}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item>
                    <DropdownMenu
                      list={addressCityList}
                      value={values.city}
                      placeholder={values.city}
                      setValue={value => setFieldValue('city', value)}
                      label={t('COMMON.CITY')}
                      error={Boolean(touched.city && errors.city)}
                      errorText={touched.city && t(errors.city)}
                      disabled={!isLoadedZipcode}
                    />
                  </Grid>

                  <Grid item>
                    <DropdownMenu
                      list={addressStateList}
                      value={values.state}
                      setValue={value => setFieldValue('state', value)}
                      label={t('COMMON.STATE')}
                      error={Boolean(touched.state && errors.state)}
                      errorText={touched.state && t(errors.state)}
                      disabled={!isLoadedZipcode}
                    />
                  </Grid>

                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CREATE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DistributorForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default DistributorForm;
