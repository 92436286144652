import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import useStyles from './styles';
import QuoteCategoriesRow from './QuoteCategoriesRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ categories }) =>
  categories.map(category => (
    <QuoteCategoriesRow key={category.id} {...category} />
  ));

const QuoteCategoriesTable = ({ className, categories, loading, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell
          variant="head"
          key="name"
          sx={{ width: '20%' }}
        >
          {t('COMMON.NAME')}
        </TableCell>
        <TableCell
          variant="head"
          key="twelveMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.TWELVE_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="eighteenMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.EIGHTEEN_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="twentyFourMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.TWENTY_FOUR_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="thirtySixMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.THIRTY_SIX_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="fortyEightMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.FORTY_EIGHT_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="sixtyMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.SIXTY_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="seventyTwoMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.SEVENTY_TWO_MONTHS')}
        </TableCell>
        <TableCell variant="head" sx={{ width: '5%' }}>
          {}
        </TableCell>
      </TableRow>
    ),
    [classes]
  );

  const tbody = useMemo(() => () => <Tbody t={t} categories={categories} />, [
    categories,
    t
  ]);

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

QuoteCategoriesTable.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

QuoteCategoriesTable.defaultProps = {
  count: 0
};

export default QuoteCategoriesTable;
