import { getNames } from 'src/utils/common';

export default values => ({
  name: values && values.fullName ? getNames(values.fullName) : '',
  paternalName:
    values && values.fullName ? values.fullName.split(' ').slice(-2, -1) : '',
  maternalName:
    values && values.fullName ? values.fullName.split(' ').slice(-1) : '',
  kinship: values?.kinship ?? '',
  curp: values?.curp ?? '',
  rfc: values?.rfc ?? '',
  identificationNumber: values?.identificationNumber ?? '',
  sex: values?.sex ?? '',
  nationality: values?.nationality ?? ''
});
