import {
  CLEAR_INSURANCES,
  CLEAR_INSURANCE_OPTIONS,
  LOAD_INSURANCES,
  SAVE_INSURANCE_OPTIONS,
  LOAD_INSURANCE_DETAIL,
  CLEAR_INSURANCE_DETAIL
} from '../actions/insuranceActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
    // sort: { property: 'remainingDays', order: 'asc' },
  },
  items: [],
  count: 0,
  detail: null
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INSURANCE_OPTIONS: {
      return {
        items: [...state.items, action.payload],
        count: state.count,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_INSURANCE_OPTIONS: {
      return {
        options: initialState.options,
        items: state.items,
        count: state.count
      };
    }
    case LOAD_INSURANCES.success: {
      return {
        options: state.options,
        items: action.items,
        count: action.count
      };
    }
    case CLEAR_INSURANCES: {
      return initialState;
    }
    default: {
      return state;
    }
    case LOAD_INSURANCE_DETAIL.success: {
      return {
        ...state,
        detail: action.data
      };
    }
    case CLEAR_INSURANCE_DETAIL: {
      return {
        ...state,
        detail: null
      };
    }
  }
};

export default insuranceReducer;
