import { asyncActionCreator } from 'src/utils/loadingUtils';
import getNotifications, {
  markAllNotificationsAsViewd,
  markAsReadNotification
} from 'src/services/notificationService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { toast } from 'react-toastify';
import { buildToastNotificationMessage } from 'src/utils/nofiticationsSelected';
import { showMessage } from './snackBarActions';
import { selectNotificationQueryOptions } from '../selectors/notificationSelectors';

export const LOAD_NOTIFICATIONS = asyncActionCreator('LOAD_NOTIFICATIONS');
export const MARK_AS_READ = asyncActionCreator('MARK_AS_READ');
export const MARK_AS_VIEWD = asyncActionCreator('MARK_AS_VIEWD');
export const LOAD_NEXT_NOTIFICATIONS = asyncActionCreator(
  'LOAD_NEXT_NOTIFICATIONS'
);
export const SAVE_NOTIFICATION_OPTIONS = 'SAVE_NOTIFICATION_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectNotificationQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset = queryOpts.page * 5;
  }

  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadNotifications = (options, t, setOpen) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_NOTIFICATIONS.start });

    dispatch({
      type: SAVE_NOTIFICATION_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);
    const data = await getNotifications(reqOptions);

    const { items } = data;

    items.forEach(notification => {
      if (!notification.notified) {
        toast(buildToastNotificationMessage(notification, t), {
          onClick: () => {
            setOpen(true);
            // eslint-disable-next-line no-use-before-define
            dispatch(markAllAsViewd());
          }
        });
      }
    });

    return dispatch({
      type: LOAD_NOTIFICATIONS.success,
      ...data
    });
  } catch (err) {
    dispatch({ type: LOAD_NOTIFICATIONS.failure });
    return dispatch(
      showMessage({ message: err.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const markAsRead = (notificationId, closeCallback) => async dispatch => {
  try {
    dispatch({ type: MARK_AS_READ.start });

    await markAsReadNotification(notificationId);

    dispatch({
      type: MARK_AS_READ.success
    });
    closeCallback();
    return dispatch(loadNotifications({ page: 0 }));
  } catch (err) {
    dispatch({ type: MARK_AS_READ.failure });
    return dispatch(
      showMessage({ message: err.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const markAllAsViewd = () => async dispatch => {
  try {
    dispatch({ type: MARK_AS_VIEWD.start });

    await markAllNotificationsAsViewd();

    return dispatch({
      type: MARK_AS_VIEWD.success
    });
  } catch (err) {
    dispatch({ type: MARK_AS_VIEWD.failure });
    return dispatch(
      showMessage({ message: err.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const loadNextNotifications = setState => async (dispatch, getState) => {
  try {
    const { count, items } = getState().notifications;

    if (count === items.length) {
      return setState(false);
    }

    dispatch({ type: LOAD_NEXT_NOTIFICATIONS.start });

    dispatch({
      type: SAVE_NOTIFICATION_OPTIONS,
      options: { page: selectNotificationQueryOptions(getState()).page + 1 }
    });

    const reqOptions = getOptions(getState);

    const data = await getNotifications(reqOptions);

    dispatch({
      type: LOAD_NEXT_NOTIFICATIONS.success,
      ...data
    });
    return setState(false);
  } catch (err) {
    dispatch({ type: LOAD_NEXT_NOTIFICATIONS.failure });
    return dispatch(
      showMessage({ message: err.message, variant: SnackBarVariants.ERROR })
    );
  }
};
