import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import {
  editInvestor,
  loadInvestorKYCDetail
} from 'src/store/actions/investorActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DetailCard from 'src/components/DetailBox/DetailCard';
import DetailRow from 'src/components/DetailRow';
import { getPersonFullname } from 'src/utils/common';
import CardHeader from 'src/components/DetailBox/CardHeader';
import useStyles from '../../styles';
import GeneralKYCDialog from './GeneralKYCDialog';

const GeneralKYC = ({ investor }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    values => {
      if (values.politicallyExposed === false) {
        values.politicallyExposedPerson = null;
      }
      dispatch(
        editInvestor(id, values, handleCloseDialog, loadInvestorKYCDetail)
      );
    },
    [investor, id]
  );

  const getPoliticallytExposedTranslation = politicallyExposed => {
    if (politicallyExposed) {
      return t('COMMON.YES');
    }
    return t('COMMON.NO');
  };

  return (
    <Card variant="elevation">
      <DetailCard title={t('INVESTORS_VIEW.INVESTOR_DETAIL.GENERAL_KYC')}>
        <DetailRow
          name={t('COMMON.CURP')}
          value={investor.curp ? investor.curp : t('COMMON.NO_DATA')}
        />
        <DetailRow
          name={t('INVESTORS_VIEW.INVESTOR_DETAIL.CIVIL_STATUS')}
          value={
            investor.civilStatus
              ? t(`CIVIL_STATUS.${investor.civilStatus}`)
              : t('COMMON.NO_DATA')
          }
        />
        <DetailRow
          name={t('INVESTORS_VIEW.INVESTOR_DETAIL.POLITICALLY_EXPOSED')}
          value={
            typeof investor.politicallyExposed === 'boolean'
              ? getPoliticallytExposedTranslation(investor.politicallyExposed)
              : t('COMMON.NO_DATA')
          }
        />
        {investor.politicallyExposed !== undefined &&
          investor.politicallyExposed && (
            <>
              <CardHeader
                title={t('Datos de persona políticamente expuesta')}
              />

              <DetailRow
                name={t('COMMON.NAME')}
                value={
                  getPersonFullname(investor.politicallyExposedPerson) ||
                  t('COMMON.NO_DATA')
                }
              />

              <DetailRow
                name={t('COMMON.POSITION')}
                value={
                  investor.politicallyExposedPerson?.position ||
                  t('COMMON.NO_DATA')
                }
              />

              <DetailRow
                name={t('COMMON.KINSHIP')}
                value={
                  t(
                    `ENUMS.KINSHIP.${investor.politicallyExposedPerson?.kinship}`
                  ) || t('COMMON.NO_DATA')
                }
              />
            </>
          )}

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleOpenDialog}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </DetailCard>
      <GeneralKYCDialog
        open={open}
        investor={investor}
        handleClose={handleCloseDialog}
        submit={handleSubmit}
      />
    </Card>
  );
};

GeneralKYC.propTypes = {
  investor: PropTypes.object.isRequired
};

export default GeneralKYC;
