/* eslint-disable no-useless-escape */
import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    .test('email', 'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID', validateEmail)
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  password: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.PASS_REQUIRED')
});
