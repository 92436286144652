import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadNextNotifications } from 'src/store/actions/notificationsActions';
import Loading from '../Loading';

const NotificationLazyLoad = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const elementRef = useRef(null);

  const handleReloadNotification = () => {
    setShow(true);
    dispatch(loadNextNotifications(setShow));
  };

  useEffect(() => {
    const onChange = entries => {
      if (entries[0].isIntersecting) {
        handleReloadNotification();
      }
      if (!entries[0].isIntersecting) {
        setShow(false);
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: '100px'
    });

    observer.observe(elementRef.current);
  }, []);

  return <Box ref={elementRef}>{show ? <Loading label=" " /> : null}</Box>;
};

export default NotificationLazyLoad;
