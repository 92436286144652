import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import AgentsRow from './AgentsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ agents }) =>
  agents.map(agent => <AgentsRow key={agent.id} {...agent} />);

const AgentsTable = ({
  className,
  agents,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="id" align="center" sx={{ width: '5%' }}>
          {t('COMMON.ID')}
        </TableCell>

        <TableCell variant="head" key="fullName" align="center">
          {t('COMMON.FULL_NAME')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('COMMON.EMAIL')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('COMMON.PHONE')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('AGENTS.INVEESTORS_COUNT')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('AGENTS.PARTICIPATION_AMOUNT')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('AGENTS.AVERAGE_INVESTMENT_PER_INVESTOR')}
        </TableCell>
        <TableCell variant="head" key="fullName" align="center">
          {t('AGENTS.COMISSION')}
        </TableCell>

        <TableCell variant="head" key="actions" align="center">
          {' '}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(() => () => <Tbody agents={agents} />, [agents]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, agents]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AgentsTable.propTypes = {
  className: PropTypes.string,
  agents: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool
};

AgentsTable.defaultProps = {
  count: 0
};

export default AgentsTable;
