import getClients, {
  createNewAdministrative,
  createNewClient,
  createOrUpdateDocumentById,
  deleteAdministrativeById,
  downloadExportedClients,
  getAddress,
  getClientAdministratives,
  getClientById,
  getDocuments,
  sendMoffinAuthorizationMessageById,
  sendToValidateBillingById,
  sendClientToValidateById,
  updateAdministrativeById,
  updateClientById,
  getSatWsDetail,
  sendSatWsCredentials,
  getSatWsHistory,
  updateClientInformationWhitSatWs,
  getAvalsByClientId,
  getIdentityValidationsByClientId,
  getBuroValidationsByClientId,
  getAvalDetail,
  getIdentityValidationsByAvalId,
  getBuroValidationsByAvalId,
  searchAvalByRfc,
  createClientFromAval,
  searchClientOrAvalByRfc,
  createAvalFromClient,
  assignDefaultLegalRepresentative as assignDefaultLegalRepresentativeService,
  getClientBankAccountInformation,
  getClientBuroValidationHistory,
  getAvalBuroValidationHistory,
  changeClientStatusById,
  getClientFullNameById,
  copyAvalFromAvalWithSameRfc,
  getRatingValidationsByClientId
} from 'src/services/clientService';
import { removePointsPhone } from 'src/utils/common';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import getAllContracts, {
  getAmendingAgreementDocList
} from 'src/services/contractService';
import {
  createDistributorClientAval,
  deleteDistributorClientAvalById,
  getUserAvalByClientId,
  getUserAvalById,
  updateDistributorClientAvalById,
  updateUserClient
} from 'src/services/userClientService';
import UserType from 'src/utils/constants/auth';
import { push } from 'connected-react-router';
import getAddresses, {
  updateClientAddressById
} from 'src/services/AddressService';
import { sendFieldToNufiValidation as sendFieldToNufiValidationService } from 'src/services/nufiService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { loadContractDetail } from './contractActions';
import {
  selectAdministrativeOptions,
  selectClientContractOptions,
  selectClientsQueryOptions
} from '../selectors/clientSelectors';
import uploadSingleFile, { getCommonOptions } from './commonActions';
import { showMessage } from './snackBarActions';
import {
  updateAvalById,
  createNewAval,
  deleteAvalById
} from '../../services/clientService';
import {
  DocumentOwner,
  InstanceType,
  PersonType
} from '../../utils/constants/clients';
// eslint-disable-next-line import/no-cycle
import { loadUserClientDetail } from './userClientActions';
import { loadInvestorDetail } from './investorActions';
import { loadInvestmentDetail } from './investmentActions';
import {
  CHANGE_CLIENT_STATUS,
  LOAD_AVAL_BURO_VALIDATION_HISTORY,
  LOAD_CLIENT_BURO_VALIDATION_HISTORY
} from '../constants/clients';

export const SAVE_CLIENT_OPTIONS = 'SAVE_CLIENT_OPTIONS';
export const CLEAR_CLIENT_OPTIONS = 'CLEAR_CLIENT_OPTIONS';
export const LOAD_CLIENTS = asyncActionCreator('LOAD_CLIENTS');
export const CREATE_CLIENT = asyncActionCreator('CREATE_CLIENT');
export const LOAD_CLIENT_DETAIL = asyncActionCreator('LOAD_CLIENT_DETAIL');
export const UPDATE_CLIENT = asyncActionCreator('UPDATE_CLIENT');
export const UPDATE_CLIENT_BILLING_DATA = asyncActionCreator(
  'UPDATE_CLIENT_BILLING_DATA'
);
export const LOAD_ADDRESS_BY_ZIP_CODE = asyncActionCreator(
  'LOAD_ADDRESS_BY_ZIP_CODE'
);
export const CLEAR_CLIENT_DETAIL = 'CLEAR_CLIENT_DETAIL';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';

export const SAVE_CLIENT_CONTRACT_OPTIONS = 'SAVE_CLIENT_CONTRACT_OPTIONS';
export const CLEAR_CLIENT_CONTRACT_OPTIONS = 'CLEAR_CLIENT_CONTRACT_OPTIONS';
export const LOAD_CONTRACT_CLIENT_OPTIONS = asyncActionCreator(
  'LOAD_CONTRACT_CLIENT_OPTIONS'
);

// Avals
export const CREATE_AVAL = asyncActionCreator('CREATE_AVAL');
export const UPDATE_AVAL = asyncActionCreator('UPDATE_AVAL');
export const DELETE_AVAL = asyncActionCreator('DELETE_AVAL');
export const UPDATE_AVALS_LIST = asyncActionCreator('UPDATE_AVALS_LIST');
export const LOAD_AVAL_DETAIL = asyncActionCreator('LOAD_AVAL_DETAIL');
export const CLEAR_AVAL_DETAIL = 'CLEAR_AVAL_DETAIL';
export const LOAD_CLIENT_AVALS_LIST = asyncActionCreator(
  'LOAD_CLIENT_AVALS_LIST'
);

// Administratives
export const CREATE_ADMINISTRATIVE = asyncActionCreator(
  'CREATE_ADMINISTRATIVE'
);
export const UPDATE_ADMINISTRATIVE = asyncActionCreator(
  'UPDATE_ADMINISTRATIVE'
);
export const DELETE_ADMINISTRATIVE = asyncActionCreator(
  'DELETE_ADMINISTRATIVE'
);
export const UPDATE_ADMINISTRATIVES_LIST = asyncActionCreator(
  'UPDATE_ADMINISTRATIVES_LIST'
);
export const SAVE_ADMINISTRATIVES_OPTIONS = 'SAVE_ADMINISTRATIVES_OPTIONS';
export const LOAD_ADMINISTRATIVES = asyncActionCreator('LOAD_ADMINISTRATIVES');
export const CLEAR_ADMINISTRATIVES = 'CLEAR_ADMINISTRATIVES';
export const CLEAR_ADMINSITRATIVE_OPTIONS = 'CLEAR_ADMINSITRATIVE_OPTIONS';
export const LOAD_CLIENT_CONTRACTS = asyncActionCreator(
  'LOAD_CLIENT_CONTRACTS'
);

// Documents
export const UPLOAD_DOCUMENT = asyncActionCreator('UPLOAD_DOCUMENT');
export const LOAD_DOCUMENTS = asyncActionCreator('LOAD_DOCUMENTS');
export const CREATE_OR_UPDATE_DOCUMENT = asyncActionCreator(
  'CREATE_OR_UPDATE_DOCUMENT'
);

// Validation
export const SEND_TO_VALIDATE = asyncActionCreator('SEND_TO_VALIDATE');
export const UPDATE_VALIDATION_STATE = asyncActionCreator(
  'UPDATE_VALIDATION_STATE'
);

export const SEND_MOFFIN_AUTHORIZATION_MESSAGE = asyncActionCreator(
  'SEND_MOFFIN_AUTHORIZATION_MESSAGE'
);

export const UPDATE_MOFFIN_VALIDATION_STATE = asyncActionCreator(
  'UPDATE_MOFFIN_VALIDATION_STATE'
);

export const SEND_TO_VALIDATE_BILLING = asyncActionCreator(
  'SEND_TO_VALIDATE_BILLING'
);
export const UPDATE_BILLING_VALIDATION_STATE = asyncActionCreator(
  'UPDATE_BILLING_VALIDATION_STATE'
);

export const ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE = asyncActionCreator(
  'ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE'
);

// Nuffi validations and details
export const LOAD_CLIENT_IDENTITY_VALIDATION = asyncActionCreator(
  'GET_CLIENT_IDENTITY_VALIDATION'
);

// Rating validations and details
export const LOAD_CLIENT_RATING_VALIDATION = asyncActionCreator(
  'GET_CLIENT_RATING_VALIDATION'
);

export const CLEAR_CLIENT_IDENTITY_VALIDATION =
  'CLEAR_CLIENT_IDENTITY_VALIDATION';

export const CLEAR_CLIENT_RATING_VALIDATION = 'CLEAR_CLIENT_RATING_VALIDATION';

// Moffin validations and details

export const LOAD_CLIENT_BURO_VALIDATION_DETAIL = asyncActionCreator(
  'LOAD_CLIENT_BURO_VALIDATION_DETAIL'
);
export const CLEAR_CLIENT_BURO_VALIDATION_DETAIL =
  'CLEAR_CLIENT_BURO_VALIDATION_DETAIL';

// Sat WS validations and details
export const LOAD_SAT_WS_DETAIL = asyncActionCreator('LOAD_SAT_WS_DETAIL');
export const SEND_TO_VALIDATE_SAT_WS = asyncActionCreator(
  'SEND_TO_VALIDATE_SAT_WS'
);
export const LOAD_SAT_WS_HISTORY = asyncActionCreator('LOAD_SAT_WS_HISTORY');
export const UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS = asyncActionCreator(
  'UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS'
);
export const CLEAR_SAT_WS_HISTORY = 'CLEAR_SAT_WS_HISTORY';
export const SEARCH_AVAL_WITH_RFC = asyncActionCreator('SEARCH_AVAL_WITH_RFC');
export const CLEAR_AVAL_WITH_RFC = 'CLEAR_AVAL_WITH_RFC';
export const SEARCH_CLIENT_OR_AVAL_WITH_RFC = asyncActionCreator(
  'SEARCH_CLIENT_OR_AVAL_WITH_RFC'
);
export const CLEAR_CLIENT_WITH_RFC = 'CLEAR_CLIENT_WITH_RFC';

// bank accounts

export const LOAD_CLIENT_BANK_ACCOUNTS = asyncActionCreator(
  'LOAD_CLIENT_BANK_ACCOUNTS'
);

export const UPDATE_CLIENT_BANK_ACCOUNTS = asyncActionCreator(
  'UPDATE_CLIENT_BANK_ACCOUNTS'
);

export const LOAD_CLIENT_FULL_NAME = asyncActionCreator(
  'LOAD_CLIENT_FULL_NAME'
);

// Address actions

export const LOAD_CLIENT_ADDRESSES = asyncActionCreator(
  'LOAD_CLIENT_ADDRESSES'
);

export const UPDATE_CLIENT_ADDRESSES = asyncActionCreator(
  'UPDATE_CLIENT_ADDRESSES'
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectClientsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.folio) {
    reqOptions.folio = queryOpts.folio;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.notIncludeRejected) {
    reqOptions.notIncludeRejected = queryOpts.notIncludeRejected;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getContractOptions(getState, sendLimit = true) {
  const queryOpts = selectClientContractOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }

  if (queryOpts.clientId) {
    reqOptions.clientId = queryOpts.clientId;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  reqOptions.includeContractsCount = true;

  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_CLIENT_OPTIONS });
};

export const clearClients = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_CLIENTS });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadClientFullName = (id, setFullName) => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_FULL_NAME.start });
    const data = await getClientFullNameById(id);
    setFullName(data);
    return dispatch({
      type: LOAD_CLIENT_FULL_NAME.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_FULL_NAME.failure });
  }
};

export const loadClientAddresses = clientId => async dispatch => {
  dispatch({ type: LOAD_CLIENT_ADDRESSES.start });

  try {
    const response = await getAddresses({ clientId });
    return dispatch({
      type: LOAD_CLIENT_ADDRESSES.success,
      payload: response
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_ADDRESSES.failure });
  }
};

export const loadClients = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CLIENTS.start });

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const clients = await getClients(reqOptions);

    return dispatch({
      type: LOAD_CLIENTS.success,
      ...clients
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENTS.failure });
  }
};

export const loadAllClients = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CLIENTS.start });

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options: {
        ...options,
        notIncludeRejected: true
      }
    });
    const reqOptions = getOptions(getState, false);

    const clients = await getClients(reqOptions);

    return dispatch({
      type: LOAD_CLIENTS.success,
      ...clients
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENTS.failure });
  }
};

export const loadAllClientAdministratives = id => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_ADMINISTRATIVES.start });

    const reqOptions = getCommonOptions(
      getState,
      selectAdministrativeOptions,
      false
    );

    const administratives = await getClientAdministratives({
      id,
      options: reqOptions
    });

    return dispatch({
      type: LOAD_ADMINISTRATIVES.success,
      ...administratives
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_ADMINISTRATIVES.failure });
  }
};

export const loadClientAdministratives = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_ADMINISTRATIVES.start });

    dispatch({
      type: SAVE_ADMINISTRATIVES_OPTIONS,
      options
    });
    const reqOptions = getCommonOptions(getState, selectAdministrativeOptions);

    const administratives = await getClientAdministratives({
      id,
      options: reqOptions
    });

    return dispatch({
      type: LOAD_ADMINISTRATIVES.success,
      ...administratives
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_ADMINISTRATIVES.failure });
  }
};

export const downloadExcelClients = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadExportedClients(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createClient = (
  values,
  closeCallback,
  setData
) => async dispatch => {
  try {
    dispatch({ type: CREATE_CLIENT.start });

    const phone = values.phone.split('.').join('');

    if (values.existingRfc) {
      const id = await createClientFromAval({ rfc: values.rfc });
      closeCallback();
      dispatch(
        showMessage({
          message: 'CREATE_CLIENT',
          variant: 'success'
        })
      );
      return dispatch(push(`/clients/detail/${id}`));
    }

    if (values.personType === PersonType.LEGAL) {
      const { id } = await createNewClient({
        ...values,
        phone
      });

      closeCallback();
      dispatch(
        showMessage({
          message: 'CREATE_CLIENT',
          variant: 'success'
        })
      );
      dispatch(push(`/clients/detail/${id}`));
    } else {
      const { id } = await createNewClient({
        ...values,
        phone
      });

      setData();

      closeCallback();
      dispatch(
        showMessage({
          message: 'CREATE_CLIENT',
          variant: SnackBarVariants.SUCCESS
        })
      );
      dispatch(push(`/clients/detail/${id}`));
    }
    return dispatch({ type: CREATE_CLIENT.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_CLIENT.failure });
  }
};

export const loadClientDetail = id => async dispatch => {
  try {
    dispatch({
      type: LOAD_CLIENT_DETAIL.start
    });

    const data = await getClientById(id);

    return dispatch({
      type: LOAD_CLIENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENT_DETAIL.failure });
  }
};

export const loadClientSatWsDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_SAT_WS_DETAIL.start });

    const data = await getSatWsDetail(id);

    return dispatch({
      type: LOAD_SAT_WS_DETAIL.success,
      payload: data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_SAT_WS_DETAIL.failure });
  }
};

export const updateClientBillingData = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT_BILLING_DATA.start });

    await updateClientById({
      id,
      client: values
    });
    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT_BILLING_DATA',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: UPDATE_CLIENT_BILLING_DATA.success, values });
    return dispatch(loadClientSatWsDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT_BILLING_DATA.failure });
  }
};

export const getClientAvals = (id, options) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_AVALS_LIST.start });

    const { auth } = getState();

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, options);

    if (auth.system === UserType.DISTRIBUTOR) {
      const avals = await getUserAvalByClientId({ id, options: reqOptions });
      return dispatch({
        type: UPDATE_AVALS_LIST.success,
        ...avals
      });
    }

    const avals = await getAvalsByClientId({ id, options: reqOptions });
    return dispatch({
      type: UPDATE_AVALS_LIST.success,
      ...avals
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_AVALS_LIST.failure });
  }
};

export const loadAvalDetail = avalId => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_AVAL_DETAIL.start });

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      const aval = await getUserAvalById(avalId);
      return dispatch({
        type: LOAD_AVAL_DETAIL.success,
        aval
      });
    }

    const aval = await getAvalDetail(avalId);
    return dispatch({
      type: LOAD_AVAL_DETAIL.success,
      aval
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AVAL_DETAIL.failure });
  }
};

export const updateClient = (id, values, closeCallback) => async (
  dispatch,
  getState
) => {
  try {
    let body = values;

    dispatch({ type: UPDATE_CLIENT.start });

    const { auth } = getState();

    let { phone, homePhone, officePhone } = values;

    if (phone) {
      phone = removePointsPhone(phone);
      body = {
        ...body,
        phone
      };
    }

    if (homePhone) {
      homePhone = removePointsPhone(homePhone);
      body = {
        ...body,
        homePhone
      };
    }

    if (officePhone) {
      officePhone = removePointsPhone(officePhone);
      body = {
        ...body,
        officePhone
      };
    }

    if (values.taxSystem) {
      body = {
        ...body,
        taxSystemId: values.taxSystem.id
      };
    }

    if (!values.insideHomeNumber) {
      body = {
        ...body,
        insideHomeNumber: null
      };
    }

    if (auth.system === UserType.DISTRIBUTOR) {
      await updateUserClient({
        id,
        data: body
      });
      dispatch(loadUserClientDetail(id));
    } else {
      await updateClientById({
        id,
        client: body
      });
      dispatch(loadClientDetail(id));
    }

    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT',
        variant: 'success'
      })
    );
    return dispatch({ type: UPDATE_CLIENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT.failure });
  }
};

export const updateClientAddress = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT_ADDRESSES.start });

    await updateClientAddressById({
      id,
      data: values
    });
    closeCallback();
    dispatch(loadClientAddresses(id));

    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT_ADDRESSES',
        variant: SnackBarVariants.SUCCESS
      })
    );
    return dispatch({ type: UPDATE_CLIENT_ADDRESSES.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_CLIENT_ADDRESSES.failure });
  }
};

export const createAdministrative = (
  clientId,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_ADMINISTRATIVE.start });

    const phone = removePointsPhone(values.phone);

    await createNewAdministrative({
      ...values,
      phone,
      clientId,
      roleId: values.role.id
    });

    dispatch(
      showMessage({
        message: 'CREATE_ADMINISTRATIVE',
        variant: 'success'
      })
    );
    dispatch(loadClientAdministratives(clientId));
    closeCallback();
    return dispatch({ type: CREATE_ADMINISTRATIVE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: CREATE_ADMINISTRATIVE.failure });
  }
};

export const updateAdministrative = (
  id,
  clientId,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_ADMINISTRATIVE.start });

    let { phone } = values;

    if (phone) {
      phone = removePointsPhone(phone);
    }

    const administrative = {
      ...values,
      phone,
      roleId: values.role ? values.role.id : null
    };

    await updateAdministrativeById({ id, administrative });
    dispatch(
      showMessage({
        message: 'UPDATE_ADMINISTRATIVE',
        variant: 'success'
      })
    );
    dispatch(loadClientAdministratives(clientId));
    closeCallback();
    return dispatch({ type: UPDATE_ADMINISTRATIVE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch(loadClientAdministratives(clientId));
    closeCallback();
    return dispatch({ type: UPDATE_ADMINISTRATIVE.failure });
  }
};

export const deleteAdministrative = (id, clientId) => async dispatch => {
  try {
    dispatch({ type: DELETE_ADMINISTRATIVE.start });

    await deleteAdministrativeById(id);

    dispatch(
      showMessage({
        message: 'DELETE_ADMINISTRATIVE',
        variant: 'success'
      })
    );

    dispatch({
      type: DELETE_ADMINISTRATIVE.success
    });

    return dispatch(loadClientAdministratives(clientId));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_ADMINISTRATIVE.failure });
  }
};

export const createAval = (clientId, values, closeCallback, setData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CREATE_AVAL.start });

    const { auth } = getState();

    const phone = values.phone.split('.').join('');

    if (values.existingRfc) {
      if (values.instanceType === InstanceType.CLIENT) {
        await createAvalFromClient({
          rfc: values.rfc,
          associatedClientId: clientId
        });
      }
      await copyAvalFromAvalWithSameRfc({
        rfc: values.rfc,
        clientId
      });
    } else if (auth.system === UserType.DISTRIBUTOR) {
      await createDistributorClientAval({ ...values, phone, clientId });
    } else {
      await createNewAval({ ...values, phone, clientId });
    }

    dispatch(
      showMessage({
        message: 'CREATE_AVAL',
        variant: 'success'
      })
    );
    setData();
    closeCallback();
    dispatch({ type: CREATE_AVAL.success });
    return dispatch(getClientAvals(clientId));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_AVAL.failure });
  }
};

export const updateAval = (id, values, closeCallback) => async (
  dispatch,
  getState
) => {
  try {
    let body = values;

    dispatch({ type: UPDATE_AVAL.start });

    const { auth } = getState();

    let { phone } = values;

    if (phone) {
      phone = removePointsPhone(phone);
      body = {
        ...body,
        phone
      };
    }

    if (values.homePhone) {
      body = {
        ...body,
        homePhone: removePointsPhone(values.homePhone)
      };
    }

    if (values.officePhone) {
      body = {
        ...body,
        officePhone: removePointsPhone(values.officePhone)
      };
    }

    if (!values.insideHomeNumber) {
      body = {
        ...body,
        insideHomeNumber: null
      };
    }

    if (auth.system === UserType.DISTRIBUTOR) {
      await updateDistributorClientAvalById({
        id,
        aval: body
      });
    } else {
      await updateAvalById({
        id,
        aval: body
      });
    }
    dispatch(
      showMessage({
        message: 'UPDATE_AVAL',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch(loadAvalDetail(id));
    return dispatch({ type: UPDATE_AVAL.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.SUCCESS })
    );
    closeCallback();
    return dispatch({ type: UPDATE_AVAL.failure });
  }
};

export const updateClientDocument = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT.start });

    await updateClientById({
      id,
      client: {
        ...values
      }
    });
    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT',
        variant: 'success'
      })
    );
    dispatch(loadClientDetail(id));
    return dispatch({ type: UPDATE_CLIENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT.failure });
  }
};

export const updateAvalDocument = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_AVAL.start });

    await updateAvalById({
      id,
      aval: {
        ...values
      }
    });
    dispatch(
      showMessage({
        message: 'UPDATE_AVAL',
        variant: 'success'
      })
    );
    dispatch(loadAvalDetail(id));
    return dispatch({ type: UPDATE_CLIENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT.failure });
  }
};

export const deleteAval = (id, clientId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_AVAL.start });

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      await deleteDistributorClientAvalById(id);
    } else {
      await deleteAvalById(id);
    }

    dispatch(
      showMessage({
        message: 'DELETE_AVAL',
        variant: 'success'
      })
    );

    dispatch({
      type: DELETE_AVAL.success
    });

    if (auth.system === UserType.DISTRIBUTOR) {
      return dispatch(push(`/user/client/${clientId}`));
    }

    return dispatch(push(`/clients/detail/${clientId}/aval`));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_AVAL.failure });
  }
};

export const loadClientAvalsList = (id, setState) => async (
  dispatch,
  getState
) => {
  try {
    const { auth } = getState();
    const reqOptions = getOptions(getState, false);

    dispatch({ type: LOAD_CLIENT_AVALS_LIST.start });

    if (auth.system === UserType.DISTRIBUTOR) {
      const { items } = await getUserAvalByClientId({
        id,
        options: reqOptions
      });
      setState(items);
    } else {
      const { items } = await getAvalsByClientId({ id, options: reqOptions });
      setState(items);
    }

    return dispatch({ type: LOAD_CLIENT_AVALS_LIST.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_AVALS_LIST.failure });
  }
};

export const getAddressByZipCode = (
  zipCode,
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_ADDRESS_BY_ZIP_CODE.start });

    const address = await getAddress(zipCode);

    setState(address);

    return dispatch({ type: LOAD_ADDRESS_BY_ZIP_CODE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));

    setState({
      cities: [],
      colonias: [],
      municipalities: [],
      states: []
    });
    return dispatch({ type: LOAD_ADDRESS_BY_ZIP_CODE.failure });
  }
};

export const getDocumentsById = (
  {
    avalId,
    clientId,
    contractId,
    promissoryNoteId,
    documentType,
    loadAmendingAgreements,
    investorId,
    investmentId
  },
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_DOCUMENTS.start });
    if (avalId) {
      const documents = await getDocuments({ avalId, documentType });
      setState(documents);
    }
    if (clientId) {
      const documents = await getDocuments({ clientId, documentType });
      setState(documents);
    }
    if (contractId && !promissoryNoteId && !loadAmendingAgreements) {
      const documents = await getDocuments({ contractId, documentType });
      setState(documents);
    }
    if (promissoryNoteId) {
      const documents = await getDocuments({ promissoryNoteId, documentType });
      setState(documents);
    }
    if (contractId && loadAmendingAgreements) {
      const documents = await getAmendingAgreementDocList(contractId);
      setState(documents);
    }
    if (investorId) {
      const documents = await getDocuments({ investorId, documentType });
      setState(documents);
    }
    if (investmentId) {
      const documents = await getDocuments({ investmentId, documentType });
      setState(documents);
    }

    return dispatch({ type: LOAD_DOCUMENTS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DOCUMENTS.failure });
  }
};

export const createOrUpdateDocument = ({
  avalId,
  clientId,
  contractId,
  promissoryNoteId,
  documentType,
  investorId,
  investmentId,
  file
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_OR_UPDATE_DOCUMENT.start });
    const { auth } = getState();

    if (avalId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        avalId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.AVAL
      });
      if (auth.system === UserType.DISTRIBUTOR) {
        dispatch(loadAvalDetail(avalId));
      } else {
        dispatch(loadAvalDetail(avalId));
      }
    }
    if (clientId && !avalId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        clientId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.CLIENT
      });
      if (auth.system === UserType.DISTRIBUTOR) {
        dispatch(loadUserClientDetail(clientId));
      } else {
        dispatch(loadClientDetail(clientId));
      }
    }

    if (promissoryNoteId && contractId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        promissoryNoteId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.PROMISSORY_NOTE
      });
      dispatch(loadContractDetail({ id: contractId }));
    }

    if (contractId && !promissoryNoteId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        contractId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.CONTRACT
      });
      dispatch(loadContractDetail({ id: contractId }));
    }
    if (investorId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        investorId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.INVESTOR
      });
      dispatch(loadInvestorDetail(investorId));
    }

    if (investmentId) {
      const documentUrl = await uploadSingleFile(file);
      await await createOrUpdateDocumentById({
        investmentId,
        documentType,
        documentUrl,
        documentOwner: DocumentOwner.INVESTMENT
      });
      dispatch(loadInvestmentDetail(investmentId));
    }

    dispatch(
      showMessage({
        message: 'UPDATE_DOCUMENT',
        variant: 'success'
      })
    );

    return dispatch({ type: CREATE_OR_UPDATE_DOCUMENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_OR_UPDATE_DOCUMENT.failure });
  }
};

export const sendFieldToNufiValidation = (field, body) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_VALIDATE.start });

    await sendFieldToNufiValidationService({ field, body });

    dispatch(
      showMessage({
        message: 'SEND_TO_VALIDATE_SUCCESS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    return dispatch({
      type: SEND_TO_VALIDATE.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEND_TO_VALIDATE.failure });
  }
};

export const updateMoffinValidationState = id => async dispatch => {
  try {
    dispatch({ type: UPDATE_MOFFIN_VALIDATION_STATE.start });

    const { creditProfileValidation, avals } = await getClientById(id);

    return dispatch({
      type: UPDATE_MOFFIN_VALIDATION_STATE.success,
      creditProfileValidation,
      avals
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_MOFFIN_VALIDATION_STATE.failure });
  }
};

export const loadClientContracts = (options, sendLimit = true) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_CLIENT_CONTRACTS.start });

    dispatch({
      type: SAVE_CLIENT_CONTRACT_OPTIONS,
      options
    });

    const reqOptions = getContractOptions(getState, sendLimit);

    const data = await getAllContracts(reqOptions);

    return dispatch({
      type: LOAD_CLIENT_CONTRACTS.success,
      payload: { ...data }
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENT_CONTRACTS.failure });
  }
};

export const updateBillingValidationState = id => async dispatch => {
  try {
    dispatch({ type: UPDATE_BILLING_VALIDATION_STATE.start });

    const { facturapiId } = await getClientById(id);

    return dispatch({
      type: UPDATE_BILLING_VALIDATION_STATE.success,
      facturapiId
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_BILLING_VALIDATION_STATE.failure });
  }
};

export const sendToValidateBilling = (id, closeCallback) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_VALIDATE_BILLING.start });

    await sendToValidateBillingById(id);

    dispatch(
      showMessage({
        message: 'VALIDATE_SUCCESS',
        variant: 'success'
      })
    );

    closeCallback();

    dispatch(updateBillingValidationState(id));

    return dispatch({
      type: SEND_TO_VALIDATE_BILLING.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_VALIDATE_BILLING.failure });
  }
};

export const sendCredentialsToSatWs = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_VALIDATE_SAT_WS.start });

    await sendSatWsCredentials(values);

    dispatch(
      showMessage({
        message: 'SEND_TO_VALIDATE_SAT_WS_SUCCESS',
        variant: 'success'
      })
    );
    dispatch(loadClientSatWsDetail(id));
    return dispatch({ type: SEND_TO_VALIDATE_SAT_WS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_VALIDATE_SAT_WS.failure });
  }
};

export const loadSatWsHistory = (id, options) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_SAT_WS_HISTORY.start });

    dispatch({
      type: SAVE_CLIENT_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);

    const history = await getSatWsHistory({ id, options: reqOptions });

    return dispatch({
      type: LOAD_SAT_WS_HISTORY.success,
      ...history
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_SAT_WS_HISTORY.failure });
  }
};

export const updateClientInformationWithSatWs = (
  id,
  onClose
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS.start });

    await updateClientInformationWhitSatWs(id);

    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS',
        variant: 'success'
      })
    );
    dispatch(loadClientSatWsDetail(id));
    dispatch({ type: UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS.success });
    return onClose();
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_CLIENT_INFORMATION_WHITH_SAT_WS.failure });
  }
};

export const loadClientIdentityValidation = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_IDENTITY_VALIDATION.start });

    const data = await getIdentityValidationsByClientId(id);
    return dispatch({
      type: LOAD_CLIENT_IDENTITY_VALIDATION.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_IDENTITY_VALIDATION.failure });
  }
};

export const loadClientRatingValidation = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_RATING_VALIDATION.start });
    const data = await getRatingValidationsByClientId(id);
    return dispatch({
      type: LOAD_CLIENT_RATING_VALIDATION.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_RATING_VALIDATION.failure });
  }
};

export const loadAvalIdentityValidation = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_IDENTITY_VALIDATION.start });

    const data = await getIdentityValidationsByAvalId(id);

    return dispatch({
      type: LOAD_CLIENT_IDENTITY_VALIDATION.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_IDENTITY_VALIDATION.failure });
  }
};

export const loadClientBuroValidationDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.start });

    const data = await getBuroValidationsByClientId(id);

    return dispatch({
      type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.failure });
  }
};

export const loadAvalBuroValidationDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.start });

    const data = await getBuroValidationsByAvalId(id);

    return dispatch({
      type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_DETAIL.failure });
  }
};

export const sendToValidateIdentity = (
  clientId,
  avalId,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_VALIDATE.start });

    if (avalId) {
      await sendClientToValidateById({ avalId: Number(avalId) });
    }
    if (clientId && !avalId) {
      await sendClientToValidateById({ clientId: Number(clientId) });
    }

    dispatch(
      showMessage({
        message: 'VALIDATE_SUCCESS',
        variant: 'success'
      })
    );

    if (avalId) {
      dispatch(loadAvalIdentityValidation(avalId));
    } else {
      dispatch(loadClientIdentityValidation(clientId));
    }

    closeCallback();

    return dispatch({
      type: SEND_TO_VALIDATE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_VALIDATE.failure });
  }
};

export const sendMoffinAuthorizationMessage = (
  clientId,
  avalId,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_MOFFIN_AUTHORIZATION_MESSAGE.start });

    if (avalId) {
      await sendMoffinAuthorizationMessageById({ avalId: Number(avalId) });
    }
    if (clientId && !avalId) {
      await sendMoffinAuthorizationMessageById({ clientId: Number(clientId) });
    }

    dispatch(
      showMessage({
        message: 'SEND_MOFFIN_AUTHORIZATION_MESSAGE_SUCCESS',
        variant: 'success'
      })
    );

    if (avalId) {
      dispatch(loadAvalBuroValidationDetail(avalId));
    } else {
      dispatch(loadClientBuroValidationDetail(clientId));
    }

    closeCallback();

    return dispatch({
      type: SEND_TO_VALIDATE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_VALIDATE.failure });
  }
};

export const searchClientOrAvalWithRfc = rfc => async dispatch => {
  try {
    dispatch({ type: SEARCH_CLIENT_OR_AVAL_WITH_RFC.start });

    const data = await searchClientOrAvalByRfc(rfc);

    return dispatch({
      type: SEARCH_CLIENT_OR_AVAL_WITH_RFC.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEARCH_CLIENT_OR_AVAL_WITH_RFC.failure });
  }
};

export const searchAvalWithRfc = rfc => async dispatch => {
  try {
    dispatch({ type: SEARCH_AVAL_WITH_RFC.start });

    const data = await searchAvalByRfc(rfc);

    return dispatch({
      type: SEARCH_AVAL_WITH_RFC.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEARCH_AVAL_WITH_RFC.failure });
  }
};

export const assignDefaultLegalRepresentative = avalId => async dispatch => {
  try {
    dispatch({ type: ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE.start });

    await assignDefaultLegalRepresentativeService(avalId);

    dispatch(
      showMessage({
        message: 'ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE_SUCCESS',
        variant: 'success'
      })
    );

    return dispatch({
      type: ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE.success,
      avalId
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE.failure });
  }
};

export const loadClientBankAccountInformation = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_BANK_ACCOUNTS.start });

    const data = await getClientBankAccountInformation(id);

    return dispatch({
      type: LOAD_CLIENT_BANK_ACCOUNTS.success,
      payload: data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENT_BANK_ACCOUNTS.failure });
  }
};

export const updateClientBankAccountInformation = (
  clientId,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT_BANK_ACCOUNTS.start });

    await updateClientById({ id: clientId, client: values });

    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT_BANK_ACCOUNTS_SUCCESS',
        variant: 'success'
      })
    );

    dispatch(loadClientBankAccountInformation(clientId));

    closeCallback();

    return dispatch({
      type: UPDATE_CLIENT_BANK_ACCOUNTS.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT_BANK_ACCOUNTS.failure });
  }
};

export const updateClientLineOfBusiness = (clientId, lineOfBusiness, closeCallback) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLIENT.start });

    const client = { lineOfBusiness };
    await updateClientById({ id: clientId, client });

    dispatch(loadClientRatingValidation(clientId));

    dispatch(
      showMessage({
        message: 'UPDATE_CLIENT_LINE_OF_BUSINESS_SUCCESS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    closeCallback();

    return dispatch({
      type: UPDATE_CLIENT.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: SnackBarVariants.ERROR }));
    closeCallback();
    return dispatch({ type: UPDATE_CLIENT.failure });
  }
};

export const loadClientBuroValidationHistory = id => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_HISTORY.start });

    const history = await getClientBuroValidationHistory({
      id
    });
    return dispatch({
      type: LOAD_CLIENT_BURO_VALIDATION_HISTORY.success,
      history
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CLIENT_BURO_VALIDATION_HISTORY.failure });
  }
};

export const loadAvalBuroValidationHistory = id => async dispatch => {
  try {
    dispatch({ type: LOAD_AVAL_BURO_VALIDATION_HISTORY.start });

    const history = await getAvalBuroValidationHistory({
      id
    });

    return dispatch({
      type: LOAD_AVAL_BURO_VALIDATION_HISTORY.success,
      history
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AVAL_BURO_VALIDATION_HISTORY.failure });
  }
};

export const changeClientStatus = (
  id,
  status,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CLIENT_STATUS.start });

    await changeClientStatusById({ id, status });

    dispatch(
      showMessage({
        message: 'CHANGE_CLIENT_STATUS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    closeCallback();

    dispatch({
      type: CHANGE_CLIENT_STATUS.success
    });
    return dispatch(loadClients());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CHANGE_CLIENT_STATUS.failure });
  }
};
