import React, { useEffect, useMemo, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  id: data ? data.id : '',
  name: data ? data.name : '',
  productKey: data ? data.productKey : ''
});

const EquipmentTypeForm = ({
  open,
  data,
  handleClose,
  handleCreate,
  handleEdit
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-test-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data
              ? t('CATALOGS_VIEW.UPDATE_REGISTER')
              : t('CATALOGS_VIEW.ADD_NEW_REGISTER')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && t(errors.name)}
                    label={t('COMMON.EQUIPMENT_TYPE')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.productKey && errors.productKey)}
                    fullWidth
                    helperText={touched.productKey && t(errors.productKey)}
                    label={t('COMMON.PRODUCT_KEY')}
                    margin="normal"
                    name="productKey"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.productKey}
                    variant="outlined"
                  />
                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? t('COMMON.UPDATE') : t('COMMON.CREATE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EquipmentTypeForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default EquipmentTypeForm;
