import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType } from 'src/utils/constants/clients';
import { useParams } from 'react-router';
import selectDocumentType from 'src/utils/selectDocumentType';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';
import DetailCard from 'src/components/DetailBox/DetailCard';
import { Box } from '@mui/material';
import IconStatusAndColor from 'src/utils/constants/common';
import StatusIcon from 'src/components/StatusIcon';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvestmentsDetail } from 'src/store/selectors/investmentSelector';
import { Send } from '@mui/icons-material';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  SEND_TITLE_TO_SIGN,
  sendInvestmentTitleToSign
} from 'src/store/actions/investmentActions';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import ResendSigningEmailButton from 'src/components/ResendSigningEmailButton/ResendSigningEmailButton';
import { TitleSignStatus } from 'src/utils/constants/investments';
import useStyles from '../../styles';

const TitleDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const investment = useSelector(selectInvestmentsDetail);
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector([SEND_TITLE_TO_SIGN.action]));

  const isNotSignedPreviousInvestment =
    !investment.titleSignStatus && !investment.paymentStatus;

  const onGenerateDocument = () => {
    dispatch(sendInvestmentTitleToSign(id));
  };

  const getInvestmentTitleSignStatus = () => {
    switch (investment.titleSignStatus) {
      case TitleSignStatus.PENDING:
        return (
          <StatusIcon
            description="ENUMS.DOCUMENT_STATUS.PENDING"
            type={IconStatusAndColor.WARNING.status}
          />
        );
      case TitleSignStatus.COMPLETED:
        return (
          <StatusIcon
            description="ENUMS.DOCUMENT_STATUS.SIGNED"
            type={IconStatusAndColor.OK.status}
          />
        );
      default:
        return (
          <StatusIcon
            description="COMMON.NO_DATA"
            type={IconStatusAndColor.NONE.status}
          />
        );
    }
  };

  return (
    <>
      <DetailCard title={t('INVESTMENTS_VIEW.DOCUMENTS.TITLE')}>
        <DetailRow
          name={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box component="div" className={classes.title}>
                {t('INVESTMENTS_VIEW.DOCUMENTS.TITLE')}
              </Box>
              <Box sx={{ marginLeft: 1 }}>{getInvestmentTitleSignStatus()}</Box>
            </Box>
          }
          value={
            <DocumentLink
              link={selectDocumentType(
                DocumentType.TITLE,
                investment.documents
              )}
            />
          }
        >
          <DocumentsMenu
            documentType={DocumentType.TITLE}
            investmentId={Number(id)}
            hideMoreIcon
            documents={Boolean(
              selectDocumentType(DocumentType.TITLE, investment.documents)
            )}
          />
        </DetailRow>
      </DetailCard>
      {isNotSignedPreviousInvestment && (
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onGenerateDocument}
          loading={isLoading}
          loadingPosition="start"
          className={clsx(classes.buttons, classes.horizontalPadding)}
          startIcon={<Send />}
        >
          {t('COMMON.SEND_TO_SIGN')}
        </LoadingButton>
      )}
      {investment.titleSignStatus === TitleSignStatus.PENDING && (
        <Box sx={{ marginTop: 1.5 }}>
          <ResendSigningEmailButton
            weetrustId={investment.weetrustDocId}
            sx={{ width: '100%' }}
          />
        </Box>
      )}
    </>
  );
};

export default TitleDetail;
