import React, { useCallback, useState } from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectBusinessQueryOptions } from 'src/store/selectors/businessSelectors';
import { loadBusinessMetricsResume } from 'src/store/actions/businessActions';
import { QuotesMetricsBalanceOptions } from 'src/utils/constants/business';
import ResumeList from './ResumeList';

const selectMetricsResumeOptions = value => {
  switch (Number(value)) {
    case 0:
      return QuotesMetricsBalanceOptions.AMOUNT;
    case 1:
      return QuotesMetricsBalanceOptions.COUNT;
    default:
      return QuotesMetricsBalanceOptions.AMOUNT;
  }
};

const MetricsTabsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectBusinessQueryOptions);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadBusinessMetricsResume({
          ...queryOpts,
          balance: selectMetricsResumeOptions(newValue)
        })
      );
    },
    [setTabIndex]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('BUSINESS_VIEW.RESUME.AMOUNT')} />
          <Tab label={t('BUSINESS_VIEW.RESUME.QUOTES_NUMBER')} />
        </Tabs>
      </AppBar>

      <TabPanel value={tabIndex} index={0}>
        <ResumeList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ResumeList />
      </TabPanel>
    </Box>
  );
};

export default MetricsTabsList;
