/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserType, { URL_DEFAULT_REDIRECT } from 'src/utils/constants/auth';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUserRedirectBySystemRol } from 'src/utils/common';

const UserPrivateRoute = ({
  component: Component,
  loggedIn,
  systemRole,
  system,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <>
          {systemRole ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: selectUserRedirectBySystemRol(system),
                state: { referrer: props.location }
              }}
            />
          )}
        </>
      ) : (
        window.location.replace(URL_DEFAULT_REDIRECT)
      )
    }
  />
);

UserPrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  loggedIn: PropTypes.bool,
  systemRole: PropTypes.bool,
  system: PropTypes.string
};

export default withRouter(
  connect(state => ({
    loggedIn: state.auth.token !== null,
    systemRole: state.auth.system === UserType.DISTRIBUTOR,
    system: state.auth.system
  }))(UserPrivateRoute)
);
