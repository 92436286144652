import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import QuoteCategoriesView from './QuoteCategories';

const QuotersView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/categories`} component={QuoteCategoriesView} />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/categories`} />}
      />
    </>
  );
};

QuotersView.propTypes = {
  match: PropTypes.object.isRequired
};

export default QuotersView;
