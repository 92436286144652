import moment from 'moment-timezone';
import parsePhoneNumberFromString from 'libphonenumber-js';

import {
  AvalRole,
  Gender,
  PersonType,
  ValidationType,
  validationSection
} from './constants/clients';
import ContractStatus from './constants/contracts';
import UserType from './constants/auth';

// TODO: Change this when branch related to IVA changes is merged
export const IVA = 16;

export const selectUserType = userType => {
  if (userType === UserType.DISTRIBUTOR)
    return {
      TITLE: 'WELCOME',
      SUBTITLE: 'USER_SUBTITLE',
      REDIRECT_TEXT: 'ARE_YOU_ADMIN',
      REDIRECT_URL: '/admin/login'
    };
  return {
    TITLE: 'WELCOME',
    SUBTITLE: 'ADMIN_SUBTITLE',
    REDIRECT_TEXT: 'ARE_YOU_USER',
    REDIRECT_URL: '/user/login'
  };
};

export const selectUserRedirectBySystemRol = userType => {
  if (userType === UserType.DISTRIBUTOR) return '/user/home';
  return '/clients';
};

export const addPointsPhone = phone => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumberFromString(`+${phone}`);

  if (!phoneNumber) return '';

  const t1 = phoneNumber.nationalNumber?.slice(0, 2);
  const t2 = phoneNumber.nationalNumber?.slice(2, 6);
  const t3 = phoneNumber.nationalNumber?.slice(6);
  return `${t1}.${t2}.${t3}`;
};

export const removePointsPhone = phone => {
  return phone.split('.').join('');
};

export const getDifferenceBetweenStates = (initialState, currentChange) => {
  const result = {};
  Object.keys(initialState).forEach(key => {
    if (initialState[key] !== currentChange[key]) {
      result[key] = currentChange[key];
    }
  });
  return result;
};

export const calculatePriceWhitIva = (price, iva) => {
  if (typeof price === 'string') price = parseFloat(price);
  if (typeof iva === 'string') iva = parseFloat(iva);
  return Number((price + (price * iva) / 100).toFixed(1));
};

export const isEmptyObj = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getNames = fullname => {
  if (fullname.split(' ').length === 4) {
    return fullname.split(' ', 2).join(' ');
  }
  return fullname.split(' ', 1);
};

export const clientHasLinkedContract = client => {
  return client.contracts.items.some(
    contract =>
      contract.status === ContractStatus.DRAFT ||
      contract.status === ContractStatus.IN_PROGRESS ||
      contract.status === ContractStatus.PENDING_SIGNATURE ||
      contract.status === ContractStatus.SIGNED
  );
};

export const capitalizeName = name => {
  return name
    .split(' ')
    .map(word => {
      return word.charAt(0) + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getClientFullName = client => {
  if (client.personType === PersonType.PHYSICAL) {
    const name = `${client.name} ${client.paternalName} ${client.maternalName}`;
    return capitalizeName(name);
  }

  return capitalizeName(`${client.name} ${client.denomination}`);
};

export const getAvalFullName = aval => {
  const name = `${aval.name} ${aval.paternalName} ${aval.maternalName}`;
  return capitalizeName(name);
};

export const getInvestorFullName = investor => {
  const name = `${investor.name} ${investor.paternalName} ${investor.maternalName}`;
  return capitalizeName(name);
};

export const getRoles = obj => {
  return Object.keys(obj).map(key => obj[key].role);
};

export const isAvalLegalRepresentative = aval =>
  aval.role.some(item => item.role === AvalRole.LEGAL_REPRESENTATIVE);

export const PersonRole = {
  CLIENT: 'CLIENT',
  AVAL: 'AVAL',
  DISTRIBUTOR: 'DISTRIBUTOR'
};

export const filterClientValidations = (
  validations,
  section,
  personType,
  includeSiger = false
) => {
  const filterList = [];

  if (!validations) return filterList;

  if (personType === PersonType.LEGAL) {
    const validationTypes = ValidationType.legalPerson[section];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(validations)) {
      if (key === validationTypes[key]) {
        filterList.push({
          name: key.toUpperCase(),
          value,
          field: validationTypes[key],
          role: validations.clientId
            ? PersonRole.CLIENT
            : PersonRole.DISTRIBUTOR,
          id: validations.clientId
            ? validations.clientId
            : validations.distributorId
        });
      }
    }
  }

  if (personType === PersonType.PHYSICAL || personType === PersonType.AVAL) {
    const validationType = ValidationType.physicalPerson[section];

    if (section === validationSection.VALIDATE_IDENTITY) {
      if (includeSiger) {
        validationType.siger = 'siger';
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(validations)) {
      if (key === validationType[key]) {
        filterList.push({
          name: key.toUpperCase(),
          value,
          field: validationType[key],
          role: validations.clientId ? PersonRole.CLIENT : PersonRole.AVAL,
          id: validations.clientId ? validations.clientId : validations.avalId
        });
      }
    }
  }
  return filterList;
};

export const EXTERNAL_PAYMENT_METHODS = [
  'OPEN_PAY',
  'PAYCASH',
  'OPEN_PAY_CARD',
  'BODEGA_AURRERA',
  'BBVA',
  'SORIANA',
  'WALMART',
  'SEVEN_ELEVEN'
];

export const isCurrentMonthAndYear = dateString => {
  const inputDate = moment(dateString);
  const today = moment();

  if (inputDate.isSame(today, 'month') && inputDate.isSame(today, 'year')) {
    return true;
  }
  return false;
};

export const isContractInGracePeriod = contract => {
  return moment().isBefore(contract.firstRentDate);
};

export const roundNumber = number => {
  return Math.floor(number);
};

export const roundNumberToDecimals = (number, decimalsPositions = 2) => {
  return Number(number.toFixed(decimalsPositions));
};

export const addPercentageToValue = number => {
  return `${number}%`;
};

export const addIVAToValue = value => {
  return value * (1 + IVA / 100);
};

export const hasAddressCompleted = address => {
  if (
    address.street &&
    address.externalHomeNumber &&
    address.zipCode &&
    address.city &&
    address.colonia &&
    address.state
  ) {
    return true;
  }
  return false;
};

export const calculateYearsAndMonthsFromDays = days => {
  const date = moment().add(days, 'days');
  const currentDate = moment();

  const years = date.diff(currentDate, 'years');
  date.subtract(years, 'years'); // Subtract years to calculate months
  const months = date.diff(currentDate, 'months');

  return { years, months };
};

export const selectClientSex = (sex, t) => {
  if (!sex) return t('COMMON.NO_DATA');
  switch (sex) {
    case Gender.MALE:
      return t('COMMON.MALE');
    case Gender.FEMALE:
      return t('COMMON.FEMALE');
    default:
      return t('COMMON.NO_DATA');
  }
};

export const transformJsonToArray = json => {
  if (!json) return [];
  if (typeof json === 'string') {
    const formatedJson = json.slice(1, -1);
    return formatedJson.split(',');
  }
  return json;
};

export const transformArrayToString = array => {
  if (!array) return '';
  if (typeof array === 'string') return array;
  const filteredArray = array.filter(
    value => value !== null && value !== undefined
  );

  const formattedArray = `{${filteredArray.join(',')}}`;
  return formattedArray;
};

export const getPersonFullname = person => {
  if (person) {
    const name = `${person.name} ${person.paternalName} ${person.maternalName}`;
    return capitalizeName(name);
  }

  return null;
};

// Regex to validate email in yup schemas with the same creteria as @IsEmail from Class Validator used in the backend
export const validateEmail = value =>
  value &&
  value.length > 0 &&
  value.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isNumericString = str => {
  if (typeof str !== 'string' || str.trim() === '') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

export const getPersonTypeByRFC = rfc => {
  if (rfc) {
    if (rfc.length === 12) {
      return PersonType.LEGAL;
    }
    return PersonType.PHYSICAL;
  }
  return true;
};

  export const formatCountdown = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
