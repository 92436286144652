import React from 'react';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useSelector } from 'react-redux';
import { Box, Card } from '@mui/material';
import { selectAgentDetail } from 'src/store/selectors/agentSelectors';
import { LOAD_AGENT_DETAIL } from 'src/store/actions/agentActions';
import InvestorsTable from './InvestorsTable';

const InvestorsList = () => {
  const agent = useSelector(selectAgentDetail);
  const loading = useSelector(isLoadingSelector([LOAD_AGENT_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_AGENT_DETAIL.action]));

  if (success && !loading && agent.investors.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.INVESTORS_TITLE"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100% !important'
          }}
        />
      </Card>
    );
  }

  return (
    <Box>
      <InvestorsTable investors={agent.investors} loading={loading} />
    </Box>
  );
};

export default InvestorsList;
