import { createDistributorContractRequest } from 'src/services/userContractRequestService';
import { SnackBarVariants } from 'src/utils/constants/common';
import UserType from 'src/utils/constants/auth';
import { createAdminContractRequest } from 'src/services/quoteService';
import { showMessage } from './snackBarActions';
import { loadQuotes } from './quoteActions';

const { asyncActionCreator } = require('src/utils/loadingUtils');

export const CREATE_CONTRACT_REQUEST = asyncActionCreator(
  'CREATE_CONTRACT_REQUEST'
);

export const createNewContractRequest = (values, closeCallback) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CREATE_CONTRACT_REQUEST.start });

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      await createDistributorContractRequest({
        ...values,
        term: Number(values.term),
        gracePeriod: values.gracePeriod
      });
    } else {
      await createAdminContractRequest({
        ...values,
        term: Number(values.term),
        gracePeriod: values.gracePeriod
      });
    }

    closeCallback();
    dispatch(
      showMessage({
        message: CREATE_CONTRACT_REQUEST.baseName,
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch(loadQuotes({ page: 0 }));
    return dispatch({ type: CREATE_CONTRACT_REQUEST.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: CREATE_CONTRACT_REQUEST.failure });
  }
};
