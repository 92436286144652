import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getAddresses = serviceBase({
  request: options => {
    return axiosClient.get(`/clients/${options.clientId}/address`, {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });
  },
  retry: 0
});

export const updateClientAddressById = serviceBase({
  request: options => {
    return axiosClient.patch(`/clients/${options.id}/address`, options.data, {
      params: options.params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });
  },
  retry: 0
});

export default getAddresses;