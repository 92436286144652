import qs from 'qs';
import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getAllInvestors = serviceBase({
  request: options =>
    axiosClient.get('/investors', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    }),
  retry: 0
});

export const createInverstor = serviceBase({
  request: values => axiosClient.post('/investors', values),
  retry: 0
});

export const searchInvestorByRfc = serviceBase({
  request: rfc => axiosClient.get(`/investors/${rfc}/search`),
  retry: 0
});

export const downloadExportedInvestors = serviceBase({
  request: options =>
    axiosClient.get('/investors/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Inversores.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const getInvestorDetail = serviceBase({
  request: id => axiosClient.get(`/investors/${id}`),
  retry: 0
});

export const getInvesmentsByInvestorId = serviceBase({
  request: (investorId, options) =>
    axiosClient.get('/investment', {
      params: { ...options, investorId }
    }),
  retry: 0
});

export const updateInvestor = serviceBase({
  request: ({ id, body }) => axiosClient.patch(`/investors/${id}`, body),
  retry: 0
});

export const getInvestorKYCDetail = serviceBase({
  request: id => axiosClient.get(`/investors/${id}/kyc`),
  retry: 0
});

export const getInvestorIdentityValidations = serviceBase({
  request: id => axiosClient.get(`/investors/${id}/identity-validation`)
});

export const sendInvestorToIdentityValidate = serviceBase({
  request: id => axiosClient.post(`/nufi-validation-score/investor/${id}`)
});
export const createInvestorKycDocument = serviceBase({
  request: id => axiosClient.post(`/investors/${id}/kyc/generate`),
  retry: 0
});

export const sendKYCToSign = serviceBase({
  request: id => axiosClient.post(`/investors/${id}/kyc/send-to-sign`),
  retry: 0
});

export const assignAgenToInvestor = serviceBase({
  request: body => axiosClient.post('/investors/assign-agent', body),
  retry: 0
});

export const unassignAgent = serviceBase({
  request: id => axiosClient.post(`/investors/unassign-agent/${id}`),
  retry: 0
});

export const getAgentInvestorHistory = serviceBase({
  request: id => axiosClient.get(`/investors/${id}/agent-history`),
  retry: 0
});
