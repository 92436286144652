import React, { useCallback } from 'react';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import {
  selectInvestorList,
  selectInvestorListCount,
  selectInvestorQueryOptions
} from 'src/store/selectors/investorSelector';
import {
  LOAD_INVESTORS,
  loadInvestors
} from 'src/store/actions/investorActions';
import InvestorsTable from './InvestorsTable';

const InvestorsList = () => {
  const dispatch = useDispatch();
  const investors = useSelector(selectInvestorList);
  const count = useSelector(selectInvestorListCount);
  const { page } = useSelector(selectInvestorQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_INVESTORS.action]));
  const success = useSelector(successSelector([LOAD_INVESTORS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInvestors({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.INVESTORS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.INVESTORS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <InvestorsTable
      investors={investors}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default InvestorsList;
