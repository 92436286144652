export const selectInvestments = state => state.investments;
export const selectInvestmentsList = state => selectInvestments(state).items;
export const selectInvestmentsListCount = state => selectInvestments(state).count;
export const selectInvestmentsDetail = state => selectInvestments(state).detail;
export const selectInvestmentsQueryOptions = state => {
  const { options } = selectInvestments(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
