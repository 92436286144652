import React, { useCallback } from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddressDetailBox = ({ address, includeCountry = false }) => {
  const { t } = useTranslation();

  const getAddressField = useCallback(
    (addressList, field) => {
      if (addressList && addressList[field]) {
        if (field === 'country') {
          return t(`COUNTRIES.${addressList[field]}`);
        }
        return addressList[field];
      }
      return t('COMMON.NO_DATA');
    },
    [address]
  );

  return (
    <>
      <DetailRow
        name={t('COMMON.STREET')}
        value={getAddressField(address, 'street')}
      />

      {address && address.insideHomeNumber && (
        <DetailRow
          name={t('COMMON.INSIDE_HOME_NUMBER')}
          value={getAddressField(address, 'insideHomeNumber')}
        />
      )}

      <DetailRow
        name={t('COMMON.EXTERNAL_HOME_NUMBER')}
        value={getAddressField(address, 'externalHomeNumber')}
      />

      <DetailRow
        name={t('COMMON.COLONIA')}
        value={getAddressField(address, 'colonia')}
      />

      <DetailRow
        name={t('COMMON.CITY')}
        value={getAddressField(address, 'city')}
      />

      <DetailRow
        name={t('COMMON.STATE')}
        value={getAddressField(address, 'state')}
      />

      <DetailRow
        name={t('COMMON.ZIP_CODE')}
        value={getAddressField(address, 'zipCode')}
      />

      {includeCountry && (
        <DetailRow
          name={t('COMMON.COUNTRY')}
          value={getAddressField(address, 'country')}
        />
      )}
    </>
  );
};

AddressDetailBox.propTypes = {
  address: PropTypes.object.isRequired,
  includeCountry: PropTypes.bool
};
export default AddressDetailBox;
