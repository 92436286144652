import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import ResendSigningEmailButton from 'src/components/ResendSigningEmailButton/ResendSigningEmailButton';
import ContractStatus, {
  AmendingAgreementSignStatus
} from 'src/utils/constants/contracts';
import GeneralDetail from './GeneralInfo';
import DateDetail from './DateDetail';
import ContractDocuments from './DocumentDetail';
import EquipmentAddressDetail from './EquipmentAddressDetail';
import DetailHeader from './DetailHeader';

const GeneralView = ({ contract }) => {
  const hasPendingAmendingAgreementSignature =
    contract.lastCapitalContribution &&
    contract.lastCapitalContribution.signStatus ===
      AmendingAgreementSignStatus.PENDING_SIGNATURE &&
    contract.status !== ContractStatus.CANCELLED;

  return (
    <Box mt={3}>
      <DetailHeader contract={contract} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <GeneralDetail contract={contract} />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <DateDetail contract={contract} />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <EquipmentAddressDetail location={contract.equipmentLocation} />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ContractDocuments contract={contract} />
          {hasPendingAmendingAgreementSignature && (
            <Box mt={1.5}>
              <ResendSigningEmailButton
                weetrustId={contract.lastCapitalContribution.weetrustDocId}
                sx={{ width: '100%' }}
                buttonText="COMMON.RESEND_AMMENDING_AGREEMENT"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  contract: PropTypes.object
};

export default GeneralView;
