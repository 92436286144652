import * as Yup from 'yup';

export default Yup.object().shape({
  investor: Yup.object().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  amount: Yup.number().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  skipPaymentFlow: Yup.boolean().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  date: Yup.date().when('skipPaymentFlow', {
    is: true,
    then: Yup.date().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  })
});
