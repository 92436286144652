import { Card } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadCFDIExpense } from 'src/store/actions/cfdiActions';
import {
  selectCFDIsExpenseList,
  selectCFDIsExpenseListCount,
  selectCFDISQueryOptions
} from 'src/store/selectors/cfdiSelectors';
import { LOAD_CFDIS_EXPENSE } from 'src/store/constants/CFDI';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import CFDIExpenseTable from './CFDIExpenseTable';
import useStyles from './styles';

const CFDIExpenseList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cfdis = useSelector(selectCFDIsExpenseList);
  const count = useSelector(selectCFDIsExpenseListCount);
  const { page } = useSelector(selectCFDISQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CFDIS_EXPENSE.action]));
  const success = useSelector(successSelector([LOAD_CFDIS_EXPENSE.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCFDIExpense({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && !count) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.CFDI_EXPENSE_TITLE" />
      </Card>
    );
  }

  return (
    <CFDIExpenseTable
      cfdis={cfdis}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default CFDIExpenseList;
