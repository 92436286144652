import {
  CLEAR_CLIENTS,
  CLEAR_CLIENT_DETAIL,
  CLEAR_CLIENT_OPTIONS,
  LOAD_CLIENTS,
  LOAD_CLIENT_CONTRACTS,
  LOAD_CLIENT_DETAIL,
  SAVE_CLIENT_OPTIONS,
  UPDATE_AVALS_LIST,
  UPDATE_VALIDATION_STATE,
  UPDATE_BILLING_VALIDATION_STATE,
  UPDATE_CLIENT_BILLING_DATA,
  LOAD_SAT_WS_DETAIL,
  LOAD_SAT_WS_HISTORY,
  CLEAR_SAT_WS_HISTORY,
  LOAD_ADMINISTRATIVES,
  LOAD_CLIENT_IDENTITY_VALIDATION,
  LOAD_CLIENT_BURO_VALIDATION_DETAIL,
  SAVE_ADMINISTRATIVES_OPTIONS,
  CLEAR_ADMINSITRATIVE_OPTIONS,
  LOAD_AVAL_DETAIL,
  CLEAR_AVAL_DETAIL,
  CLEAR_CLIENT_IDENTITY_VALIDATION,
  CLEAR_CLIENT_BURO_VALIDATION_DETAIL,
  SAVE_CLIENT_CONTRACT_OPTIONS,
  SEARCH_AVAL_WITH_RFC,
  CLEAR_AVAL_WITH_RFC,
  SEARCH_CLIENT_OR_AVAL_WITH_RFC,
  CLEAR_CLIENT_WITH_RFC,
  ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE,
  LOAD_CLIENT_BANK_ACCOUNTS,
  CLEAR_CLIENT_CONTRACT_OPTIONS,
  LOAD_CLIENT_ADDRESSES,
  LOAD_CLIENT_RATING_VALIDATION,
  CLEAR_CLIENT_RATING_VALIDATION
} from '../actions/clientActions';
import {
  CLEAR_AVAL_BURO_VALIDATION_HISTORY,
  CLEAR_CLIENT_BURO_VALIDATION_HISTORY,
  LOAD_AVAL_BURO_VALIDATION_HISTORY,
  LOAD_CLIENT_BURO_VALIDATION_HISTORY
} from '../constants/clients';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    sort: { property: 'id', order: 'asc' }
  },
  items: [],
  count: 0,
  detail: {
    isEditable: true,
    contracts: {
      items: [],
      count: 0,
      totalCount: 0,
      options: {
        limit: +process.env.REACT_APP_TABLE_SIZE,
        page: 0
      }
    },
    quotes: {
      items: [],
      count: 0
    },
    satWsDetail: {},
    satWsHistory: {
      items: [],
      count: 0
    },
    avals: [],
    address: {},
    addresses: [],
    administratives: {
      items: [],
      count: 0,
      options: {
        limit: +process.env.REACT_APP_TABLE_SIZE,
        page: 0
      }
    },
    identityValidation: {
      avals: []
    },
    ratingValidation: {},
    buroValidation: {
      avals: [],
      creditProfileValidationData: {}
    },
    buroValidationHistory: [],
    clientAvals: {
      items: [],
      count: 0
    },
    clientAvalDetail: {
      address: {},
      buroValidationHistory: []
    },
    documents: [],
    bankAccount: {}
  },
  distributorProfile: {},
  existingAvalWithRfc: {},
  existingClientWithRfc: {}
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CLIENTS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case CLEAR_CLIENTS: {
      return initialState;
    }
    case SAVE_CLIENT_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_CLIENT_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_CLIENT_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.data
        }
      };
    }
    case CLEAR_CLIENT_DETAIL: {
      return {
        ...state,
        detail: initialState.detail
      };
    }
    case SAVE_CLIENT_CONTRACT_OPTIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            options: { ...state.detail.contracts.options, ...action.options }
          }
        }
      };
    }
    case CLEAR_CLIENT_CONTRACT_OPTIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            options: initialState.detail.contracts.options
          }
        }
      };
    }
    case UPDATE_AVALS_LIST.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvals: {
            items: action.items,
            count: action.count,
            clientPersonType: action.clientPersonType
          }
        }
      };
    }
    case LOAD_AVAL_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvalDetail: {
            ...action.aval
          }
        }
      };
    }

    case CLEAR_AVAL_DETAIL: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvalDetail: initialState.detail.clientAvalDetail
        }
      };
    }
    case LOAD_CLIENT_CONTRACTS.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          contracts: {
            ...state.detail.contracts,
            items: action.payload.items,
            count: action.payload.count,
            totalCount: action.payload.clientContractsCount
          }
        }
      };
    }

    case UPDATE_CLIENT_BILLING_DATA.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          billingName: action.values.billingName
        }
      };
    }

    case UPDATE_VALIDATION_STATE.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientValidation: action.clientValidation,
          nufiScore: action.nufiScore,
          avals: action.avals
        }
      };
    }
    case UPDATE_BILLING_VALIDATION_STATE.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          facturapiId: action.facturapiId
        }
      };
    }
    case LOAD_SAT_WS_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          satWsDetail: action.payload
        }
      };
    }
    case LOAD_SAT_WS_HISTORY.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          satWsHistory: {
            items: action.items,
            count: action.count
          }
        }
      };
    }
    case LOAD_ADMINISTRATIVES.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          administratives: {
            ...state.detail.administratives,
            items: action.items,
            count: action.count
          }
        }
      };
    }
    case CLEAR_SAT_WS_HISTORY: {
      return {
        ...state,
        detail: {
          ...state.detail,
          satWsHistory: initialState.detail.satWsHistory
        }
      };
    }

    case LOAD_CLIENT_IDENTITY_VALIDATION.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          identityValidation: action.payload
        }
      };
    }

    case CLEAR_CLIENT_IDENTITY_VALIDATION: {
      return {
        ...state,
        detail: {
          ...state.detail,
          identityValidation: initialState.detail.identityValidation
        }
      };
    }
    case LOAD_CLIENT_RATING_VALIDATION.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ratingValidation: action.payload
        }
      };
    }

    case CLEAR_CLIENT_RATING_VALIDATION: {
      return {
        ...state,
        detail: {
          ...state.detail,
          ratingValidation: initialState.detail.ratingValidation
        }
      };
    }
    case LOAD_CLIENT_BURO_VALIDATION_DETAIL.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          buroValidation: action.payload
        }
      };
    }

    case CLEAR_CLIENT_BURO_VALIDATION_DETAIL: {
      return {
        ...state,
        detail: {
          ...state.detail,
          buroValidation: initialState.detail.buroValidation
        }
      };
    }

    case SAVE_ADMINISTRATIVES_OPTIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          administratives: {
            ...state.detail.administratives,
            options: {
              ...state.detail.administratives.options,
              ...action.options
            }
          }
        }
      };
    }
    case LOAD_CLIENT_ADDRESSES.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          addresses: action.payload
        }
      };
    }
    case CLEAR_ADMINSITRATIVE_OPTIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          administratives: {
            ...state.detail.administratives,
            options: initialState.detail.administratives.options
          }
        }
      };
    }

    case SEARCH_AVAL_WITH_RFC.success: {
      return {
        ...state,
        existingAvalWithRfc: action.payload
      };
    }

    case CLEAR_AVAL_WITH_RFC: {
      return {
        ...state,
        existingAvalWithRfc: initialState.existingAvalWithRfc
      };
    }

    case SEARCH_CLIENT_OR_AVAL_WITH_RFC.success: {
      return {
        ...state,
        existingClientWithRfc: action.payload
      };
    }

    case ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvals: {
            ...state.detail.clientAvals,
            items: state.detail.clientAvals.items.map(aval => {
              if (aval.id === action.avalId) {
                return {
                  ...aval,
                  isLegalRepresentative: true
                };
              }
              if (aval.isLegalRepresentative) {
                return {
                  ...aval,
                  isLegalRepresentative: false
                };
              }
              return aval;
            })
          }
        }
      };
    }

    case LOAD_CLIENT_BURO_VALIDATION_HISTORY.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          buroValidationHistory: action.history
        }
      };
    }

    case LOAD_AVAL_BURO_VALIDATION_HISTORY.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvalDetail: {
            ...state.detail.clientAvalDetail,
            buroValidationHistory: action.history
          }
        }
      };
    }

    case CLEAR_AVAL_BURO_VALIDATION_HISTORY: {
      return {
        ...state,
        detail: {
          ...state.detail,
          clientAvalDetail: {
            ...state.detail.clientAvalDetail,
            buroValidationHistory:
              initialState.detail.clientAvalDetail.buroValidationHistory
          }
        }
      };
    }

    case CLEAR_CLIENT_BURO_VALIDATION_HISTORY: {
      return {
        ...state,
        detail: {
          ...state.detail,
          buroValidationHistory: initialState.detail.buroValidationHistory
        }
      };
    }

    case CLEAR_CLIENT_WITH_RFC: {
      return {
        ...state,
        existingClientWithRfc: initialState.existingClientWithRfc
      };
    }

    case LOAD_CLIENT_BANK_ACCOUNTS.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          bankAccount: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default clientReducer;
