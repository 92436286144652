import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

const getUserDistributor = serviceBase({
  request: () => axiosClient.get('/distributor-profile/distributor'),
  retry: 0
});

export const getUserDistributorContacts = serviceBase({
  request: options =>
    axiosClient.get('/distributor-profile/distributor/contacts', {
      params: options
    })
});

export const createNewUserDistributorContact = serviceBase({
  request: ({ values }) =>
    axiosClient.post('/distributor-profile/distributor/contacts', values)
});

export const updateUserDistributorContact = serviceBase({
  request: ({ id, values }) =>
    axiosClient.patch(`/distributor-profile/distributor/contacts/${id}`, values)
});

export const deleteUserDistributorContact = serviceBase({
  request: id =>
    axiosClient.delete(`/distributor-profile/distributor/contacts/${id}`)
});

export const getUserDistributorCatalogs = serviceBase({
  request: ({ options }) =>
    axiosClient.get('/distributor-profile/distributor/equipments', {
      params: options
    })
});

export const createNewUserDistributorCatalog = serviceBase({
  request: data =>
    axiosClient.post('/distributor-profile/distributor/equipments', data)
});

export const updateUserDistributorCatalog = serviceBase({
  request: ({ id, values }) => {
    return axiosClient.patch(
      `/distributor-profile/distributor/equipment/${id}`,
      values
    );
  }
});

export const deleteUserDistributorCatalog = serviceBase({
  request: id =>
    axiosClient.delete(`/distributor-profile/distributor/equipment/${id}`)
});

export const getDistributorSelfParameters = serviceBase({
  request: () => axiosClient.get('/distributor-profile/distributor/parameters')
});

export const uploadDistributorCatalogWithExcel = serviceBase({
  request: value =>
    axiosClient.post('/distributor-profile/distributor/equipment/upload', value)
});

export const getKamAssociattedWithClient = serviceBase({
  request: clientId =>
    axiosClient.get(`/distributor-profile/kam/client/${clientId}`)
});

export default getUserDistributor;
