import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getBillings = serviceBase({
  request: options =>
    axiosClient.get('/billing', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const downloadExportedBilling = serviceBase({
  request: options =>
    axiosClient.get('/billing/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'facturación.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const getBillingFailures = serviceBase({
  request: options =>
    axiosClient.get('/billing/unbilled-transacitons', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const sendToBillTransaction = serviceBase({
  request: transactionId =>
    axiosClient.post(`/billing/bill-transaction/${transactionId}`)
});
