export const selectCapitalBalance = state => state.capitalBalance;
export const selectCapitalBalanceList = state =>
  selectCapitalBalance(state).items;
export const selectCapitalBalanceListCount = state =>
  selectCapitalBalance(state).count;
export const selectCapitalBalanceQueryOptions = state => {
  const { options } = selectCapitalBalance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
