import React, { useCallback, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import GeneralView from 'src/views/client/ClientDetail/General';
import AvalView from 'src/views/client/ClientDetail/AvalDetail';
import { useSelector } from 'react-redux';
import { selectLogin } from 'src/store/selectors/authSelectors';
import UserType, { DistributorRoles } from 'src/utils/constants/auth';
import TabPanel from 'src/components/TabPanel/TabPanel';
import QuotesDetail from 'src/views/client/ClientDetail/QuotesDetail';
import { selectClientDetail } from 'src/store/selectors/clientSelectors';

const UserClientDetailTabs = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const { role, system } = useSelector(selectLogin);
  const { isEditable } = useSelector(selectClientDetail);

  const isAdminView = system === UserType.ADMIN;
  const isClientEditable = system === UserType.DISTRIBUTOR && isEditable;

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb doNotRedirectIndexList={[2]} />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.GENERAL')} />
          <Tab label={t('COMMON.AVALS')} />
          {role === DistributorRoles.KAM && <Tab label={t('COMMON.QUOTES')} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AvalView
          isAdminView={isAdminView}
          isDisabledEdit={!isClientEditable}
        />
      </TabPanel>
      {role === DistributorRoles.KAM && (
        <TabPanel value={value} index={2}>
          <QuotesDetail />
        </TabPanel>
      )}
    </Box>
  );
};

export default UserClientDetailTabs;
