import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInvestorInvesmentsList,
  selectInvestorInvesmentsListCount,
  selectInvestorInvestmentsQueryOptions
} from 'src/store/selectors/investorSelector';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_INVESTOR_INVESMENTS,
  loadInvestmentsByInvestorId
} from 'src/store/actions/investorActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InvestmentTable from './InvestmentTable';
import useStyles from './styles';

const InvestmentList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const investments = useSelector(selectInvestorInvesmentsList);
  const count = useSelector(selectInvestorInvesmentsListCount);
  const { page } = useSelector(selectInvestorInvestmentsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_INVESTOR_INVESMENTS.action])
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInvestmentsByInvestorId(id, { page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadInvestmentsByInvestorId(id, {}));
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={7} lg={12}>
          <Grid container className={classes.transactionContainer}>
            <Grid
              item
              lg={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <Typography
                color="primary"
                variant="h1"
                sx={{
                  margin: '0px 20px',
                  fontWeight: '400',
                  textTransform: 'capitalize'
                }}
              >
                {t('INVESTMENTS_VIEW.TITLE')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <InvestmentTable
            className={classes.table}
            loading={loading}
            investments={investments}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestmentList;
