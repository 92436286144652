import { LoadingButton } from '@mui/lab';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RESEND_SIGN_EMAIL,
  resendSignEmailAction
} from 'src/store/actions/commonActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ResendSigningEmailButton = ({
  weetrustId,
  buttonText = 'COMMON.RESEND_SIGNING_URL',
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loadingResendSignEmail = useSelector(
    isLoadingSelector([[RESEND_SIGN_EMAIL.action]])
  );

  const handleResendSignEmail = useCallback(() => {
    dispatch(resendSignEmailAction(weetrustId));
  }, []);

  return (
    <LoadingButton
      {...rest}
      variant="contained"
      color="primary"
      onClick={handleResendSignEmail}
      loading={loadingResendSignEmail}
      loadingPosition="start"
      className={classes.buttons}
      startIcon={<RefreshIcon />}
    >
      {t(buttonText)}
    </LoadingButton>
  );
};

export default ResendSigningEmailButton;

ResendSigningEmailButton.propTypes = {
  weetrustId: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};
