import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  LOAD_CLIENT_CONTRACTS,
  loadClientContracts
} from 'src/store/actions/clientActions';
import { selectClientContractList } from 'src/store/selectors/clientSelectors';
import ContractStatus from 'src/utils/constants/contracts';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import {
  TransactionsCategory,
  TransactionsType
} from 'src/utils/constants/transactions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_MANUAL_TRANSACTION } from 'src/store/actions/transactionActions';
import Preloader from 'src/components/Preloader';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import { loadParameter } from 'src/store/actions/parameterActions';
import { Parameter } from 'src/utils/constants/parameters';
import { getAmountWithIva } from 'src/utils/constants/common';
import { selectAmountFromCategory } from 'src/utils/contracts';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const categoryOptions = [
  TransactionsCategory.RENT,
  TransactionsCategory.UPFRONT_RENT,
  TransactionsCategory.RESIDUAL,
  TransactionsCategory.INITIAL_PAYMENT
];

const GenerateManualChargeDialog = ({
  open,
  handleClose,
  submit,
  clientId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const contracts = useSelector(selectClientContractList);
  const ivaParameter = useSelector(selectIndividualParameter) || 16;
  const loading = useSelector(
    isLoadingSelector([CREATE_MANUAL_TRANSACTION.action])
  );
  const initialState = useMemo(() => makeInitialState(), []);
  const resetRef = useRef();
  const { t } = useTranslation();
  const isLoadingContracts = useSelector(
    isLoadingSelector([LOAD_CLIENT_CONTRACTS.action])
  );

  useEffect(() => {
    if (open) {
      dispatch(
        loadClientContracts(
          { clientId, status: ContractStatus.IN_PROGRESS },
          false
        )
      );
      dispatch(loadParameter(Parameter.IVA));
    }
  }, [dispatch, id, open]);

  const handleSubmitTransaction = useCallback(
    (values, { resetForm }) => {
      submit(
        {
          ...values,
          type: TransactionsType.CHARGE,
          amount: getAmountWithIva(values.amount, ivaParameter),
          equipmentId: Number(values.contract.equipment.id),
          contractId: values.contract.id
        },
        handleClose
      );
      resetForm(initialState);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {`${t('COMMON.REGISTER')} ${t(`TRANSACTION.TYPE.CHARGE`)} - ${t(
            'COMMON.ISSUE_INCOME_INVOICE'
          )}`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {loading && <Preloader />}
        {!loading && (
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleSubmitTransaction}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <form onSubmit={handleSubmit}>
                  <DropdownMenu
                    list={contracts.map(contract => ({
                      key: contract,
                      name: `${t('COMMON.CONTRACT')} ${contract.id} - ${t(
                        'CONTRACT_VIEW.RENT_WITH_IVA'
                      )} $${formatAmountWithDecimals(contract.rentIvaIncluded)}`
                    }))}
                    value={values.contract}
                    setValue={value =>
                      setFieldValue('contract', value)
                    }
                    label={t('COMMON.CONTRACT')}
                    defaultValue={values.contract}
                    error={Boolean(touched.contract && errors.contract)}
                    errorText={touched.contract && t(errors.contract)}
                    disabled={isLoadingContracts}
                    isLoading={isLoadingContracts}
                  />

                  <DropdownMenu
                    list={categoryOptions.map(category => {
                      return {
                        key: category,
                        name: `${t(`TRANSACTION.CATEGORY.${category}`)}`
                      };
                    })}
                    value={values.category}
                    setValue={value => {
                      setFieldValue('category', value)
                      const amount = selectAmountFromCategory(value, values.contract);
                      setFieldValue('amount', amount);
                    }}
                    label={t('COMMON.CATEGORY')}
                    defaultValue={values.category}
                    error={Boolean(touched.category && errors.category)}
                    errorText={touched.category && t(errors.category)}
                    disabled={!values.contract}
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MoneyMask
                    }}
                    error={Boolean(touched.amount && errors.amount)}
                    fullWidth
                    helperText={touched.amount && t(errors.amount)}
                    label={t('COMMON.AMOUNT')}
                    margin="normal"
                    name="amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount}
                    variant="outlined"
                    disabled={
                      values.category === TransactionsCategory.UPFRONT_RENT ||
                      !values.contract
                    }
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MoneyMask,
                      readOnly: true,
                      step: 0.1
                    }}
                    label={`${t('COMMON.IVA')} %`}
                    fullWidth
                    margin="normal"
                    name="iva"
                    value={formatAmountWithDecimals(ivaParameter, 1)}
                    variant="outlined"
                    disabled
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MoneyMask,
                      readOnly: true
                    }}
                    label={t('COMMON.AMOUNT_WITH_IVA')}
                    fullWidth
                    margin="normal"
                    name="amountWithIva"
                    value={formatAmountWithDecimals(
                      getAmountWithIva(values.amount, ivaParameter),
                      2
                    )}
                    variant="outlined"
                    disabled
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.comment && errors.comment)}
                    fullWidth
                    helperText={touched.comment && t(errors.comment)}
                    label={t('COMMON.COMMENT')}
                    margin="normal"
                    name="comment"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.comment}
                    variant="outlined"
                    disabled={!values.contract}
                  />

                  <FormControlLabel
                    key="do-not-bill-transaction"
                    control={
                      <Checkbox
                        name="doNotBillTransaction"
                        color="primary"
                        checked={values.doNotBillTransaction}
                        onChange={() => {
                          setFieldValue(
                            'doNotBillTransaction',
                            !values.doNotBillTransaction
                          );
                        }}
                      />
                    }
                    label={t('TRANSACTION.DO_NOT_BILL_TRANSACTION')}
                  />

                  {values.category === TransactionsCategory.UPFRONT_RENT && (
                    <DropdownMenu
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') }
                      ]}
                      value={values.continueBilling}
                      setValue={value =>
                        setFieldValue('continueBilling', value === 'true')
                      }
                      label="¿Desea continuar facturación del próximo mes?"
                      error={Boolean(
                        touched.continueBilling && errors.continueBilling
                      )}
                      errorText={
                        touched.continueBilling && t(errors.continueBilling)
                      }
                    />
                  )}

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.REGISTER')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

GenerateManualChargeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  clientId: PropTypes.number
};

export default GenerateManualChargeDialog;
