import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_EQUIPMENT_TYPES,
  loadEquipmentTypesList
} from 'src/store/actions/catalogActions';
import { getDifferenceBetweenStates } from 'src/utils/common';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GeneralDetailDialog = ({ open, equipment, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(equipment), [equipment]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const loadingEquipmentTypeList = useSelector(
    isLoadingSelector([LOAD_EQUIPMENT_TYPES.action])
  );
  const dispatch = useDispatch();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit(difference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadEquipmentTypesList(setEquipmentTypeList));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.GENERAL_EQUIPMENT_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.serialNumber && errors.serialNumber)}
                  fullWidth
                  helperText={touched.serialNumber && t(errors.serialNumber)}
                  label={t('EQUIPMENT_VIEW.SERIAL_NUMBER')}
                  margin="normal"
                  name="serialNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.serialNumber}
                  variant="outlined"
                />

                <Autocomplete
                  options={equipmentTypeList}
                  onBlur={handleBlur}
                  isLoading={loadingEquipmentTypeList}
                  error={Boolean(touched.equipmentType && errors.equipmentType)}
                  helperText={touched.equipmentType && t(errors.equipmentType)}
                  label={t('EQUIPMENT_VIEW.EQUIPMENT_TYPE')}
                  name="equipmentTypeId"
                  id="equipmentTypeId"
                  getOptionLabel={option =>
                    option.name ? `${option.name}` : ''
                  }
                  fullWidth
                  onChange={(_e, value) => {
                    setFieldValue('equipmentType', value ?? '');
                  }}
                  sx={{ marginTop: 1 }}
                  value={values.equipmentType || equipment.equipmentType}
                />

                <DatePicker
                  label={`${t('EQUIPMENT_VIEW.PURCHASE_DATE')} (${t(
                    'COMMON.OPTIONAL'
                  )})`}
                  value={values.purchaseDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={newValue => {
                    setFieldValue('purchaseDate', newValue);
                  }}
                  error={Boolean(touched.purchaseDate && errors.purchaseDate)}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      sx={{ width: '100%' }}
                      className={classes.datepicker}
                      helperText={
                        touched.purchaseDate && t(errors.purchaseDate)
                      }
                      error={Boolean(
                        touched.purchaseDate && errors.purchaseDate
                      )}
                    />
                  )}
                />

                <TextField
                  error={Boolean(touched.moreInfo && errors.moreInfo)}
                  fullWidth
                  helperText={touched.moreInfo && t(errors.moreInfo)}
                  label={t('COMMON.MORE_INFO')}
                  name="moreInfo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  margin="normal"
                  type="text"
                  maxRows={4}
                  value={values.moreInfo}
                  variant="outlined"
                  inputProps={{ maxLength: 120, autoComplete: 'off' }}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

GeneralDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  equipment: PropTypes.object
};

export default GeneralDetailDialog;
