export const selectBusiness = state => state.business;
export const selectBusinessQueryOptions = state => {
  const { options } = selectBusiness(state);
  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

// Business metrics selectors
export const selectBusinessMetricsList = state =>
  selectBusiness(state).metrics.items;
export const selectBusinessMetricsCount = state =>
  selectBusiness(state).metrics.count;

// Business metrics resume selectors
export const selectBusinessMetricsResumeList = state =>
  selectBusiness(state).resume.items;
export const selectBusinessMetricsResumeCount = state =>
  selectBusiness(state).resume.count;
