import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { loadDistributorAgents } from 'src/store/actions/distributorActions';
import { selectDistributorAgents } from 'src/store/selectors/distributorSelectors';
import DialogTransition from 'src/components/DialogTransition';
import { AgentStatus } from 'src/utils/constants/distributors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { ASSIGN_KAM } from 'src/store/actions/quoteActions';
import Preloader from 'src/components/Preloader';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const AssignKamDialog = ({
  open,
  onClose,
  distributorId,
  onSubmit,
  kamAssignedToClient
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resetRef = useRef();
  const kamList = useSelector(selectDistributorAgents);
  const isLoadingAssignKam = useSelector(
    isLoadingSelector([ASSIGN_KAM.action])
  );
  const initialState = useMemo(() => {
    return makeInitialState(kamAssignedToClient);
  }, [kamAssignedToClient]);

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(
        loadDistributorAgents(distributorId, { status: AgentStatus.ACTIVE }, false)
      );
    }
  }, [open]);

  const kamOptions = useMemo(
    () => kamList.map(kam => ({ id: kam.id, name: kam.name })),
    [kamList]
  );

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle
        className={classes.header}
        id="assign-kam-dialog-title"
        variant="h2"
        color="primary"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        {t('ENUMS.QUOTE_ACTIONS.ASSIGN_KAM')}
      </DialogTitle>
      <DialogContent id="assign-kam-dialog">
        {isLoadingAssignKam ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <Form onSubmit={handleSubmit}>
                  <Box />
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.distributorProfile && errors.distributorProfile
                    )}
                  >
                    <Autocomplete
                      options={kamOptions}
                      label={t('ENUMS.QUOTE_ACTIONS.ASSIGN_KAM')}
                      id="distributorProfile"
                      value={values.distributorProfile || kamAssignedToClient}
                      getOptionLabel={option =>
                        option ? `${option.id} - ${option.name}` : ''
                      }
                      fullWidth
                      onChange={(_e, value) => {
                        setFieldValue('distributorProfile', value ?? '');
                      }}
                      helperText={
                        touched.distributorProfile &&
                        t(errors.distributorProfile)
                      }
                      error={Boolean(
                        touched.distributorProfile && errors.distributorProfile
                      )}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: '0.5rem auto'
                    }}
                  >
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

AssignKamDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  distributorId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  kamAssignedToClient: PropTypes.object
};

export default AssignKamDialog;
