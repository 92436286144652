import React from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import TaxSystemList from './TaxSystemList';
import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TaxSystemView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={t('NAVIGATION.CATALOG_LIST.TAX_SYSTEM')}
    >
      <Container maxWidth={false}>
        <Toolbar catalogLabel={t('NAVIGATION.CATALOG_LIST.TAX_SYSTEM')} />
        <Box mt={3}>
          <TaxSystemList />
        </Box>
      </Container>
    </Page>
  );
};

export default TaxSystemView;
