import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectLineOfBusinessList } from 'src/store/selectors/catalogSelectors';
import { loadLineOfBusiness } from 'src/store/actions/catalogActions';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';
import { updateClientLineOfBusiness } from 'src/store/actions/clientActions';
import LinesOfBusinessDialog from './LineOfBusinessDialog';

const ClientRateView = ({ clientRatingValidation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const linesOfBusiness = useSelector(selectLineOfBusinessList);
  const [openDialog, setOpenDialog] = useState(false);

  const getClientAge = birthDate =>
    momentLocalTimezone().diff(momentLocalTimezone(birthDate), 'years');

  useEffect(() => {
    dispatch(loadLineOfBusiness());
  }, [dispatch]);

  const handleClickMore = () => setOpenDialog(true);

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const handleLineOfBusinessSave = lineOfBusiness => {
    dispatch(
      updateClientLineOfBusiness(
        clientRatingValidation.client.id,
        lineOfBusiness.name,
        () => {
          handleCloseEdit();
        }
      )
    );
  };
  return (
    <>
      <DetailRow
        name={t('RATING_VIEW.BURO')}
        value={
          Number(clientRatingValidation.moffinScore) || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.IDENTITY')}
        value={
          clientRatingValidation.nufiScore.identityScore || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.JUDICIAL_RECORD')}
        value={
          clientRatingValidation.nufiScore.criminalRecordsScore ||
          t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.CONTACT_INFORMATION')}
        value={
          clientRatingValidation.nufiScore.contactScore || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.AGE')}
        value={
          getClientAge(clientRatingValidation.client.birthDate) ||
          t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.EXPERIENCE')}
        value={
          clientRatingValidation.client.yearsOperating || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.STATE')}
        value={
          clientRatingValidation.client.addresses[0].state ||
          t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.COVERAGE')}
        value={
          clientRatingValidation?.totalCoverage
            ? `${clientRatingValidation.totalCoverage} %`
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.AVAL_COVERAGE')}
        value={
          clientRatingValidation?.totalCoverageAval
            ? `${clientRatingValidation.totalCoverageAval} %`
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('RATING_VIEW.LINE_OF_BUSINESS')}
        value={
          clientRatingValidation?.client?.lineOfBusiness || t('COMMON.NO_DATA')
        }
      >
        <MoreMenu deleteDisabled handleClickOption={handleClickMore} />
        <LinesOfBusinessDialog
          open={openDialog}
          handleClose={handleCloseEdit}
          handleSave={handleLineOfBusinessSave}
          linesOfBusiness={linesOfBusiness}
          firstOption={
            clientRatingValidation?.client?.lineOfBusiness || linesOfBusiness[0]
          }
        />
      </DetailRow>
    </>
  );
};

ClientRateView.propTypes = {
  clientRatingValidation: PropTypes.object.isRequired
};

export default ClientRateView;
