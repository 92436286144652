import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';

import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import Toolbar from './Toolbar';
import InsurancesTable from './InsurancesTable';
import {
  selectInsuranceList,
  selectInsuranceListCount,
  selectInsuranceQueryOptions
} from '../../../store/selectors/insuranceSelector';
import {
  LOAD_INSURANCES,
  loadInsurances
} from '../../../store/actions/insuranceActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InsuranceList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const insurances = useSelector(selectInsuranceList);
  const count = useSelector(selectInsuranceListCount);
  const { page } = useSelector(selectInsuranceQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_INSURANCES.action]));
  const success = useSelector(successSelector([LOAD_INSURANCES.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInsurances({ page: value }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadInsurances({ sort: { property, order } }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.INSURANCE_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.INSURANCE_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <InsurancesTable
      insurances={insurances}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const InsurancesListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('INSURANCES_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <InsuranceList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default InsurancesListView;
