import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import { CLEAR_AVAL_WITH_RFC } from 'src/store/actions/clientActions';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stepperLabels = [
  'CLIENTS_VIEW.CREATION_FORM.RFC',
  'CLIENTS_VIEW.CREATION_FORM.PERSONAL_INFORMATION',
  'COMMON.CONFIRMATION'
];

const ClientsForm = ({ open, handleClose, handleCreateClient }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]);

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = useCallback(() => {
    switch (step) {
      case 0:
        handleClose();
        setData(initialState);
        break;
      case 1:
        setData({ ...initialState, rfc: data.rfc });
        setStep(step - 1);
        break;
      case 2: {
        if (data.existingRfc) {
          setStep(0);
          dispatch({ type: CLEAR_AVAL_WITH_RFC });
          setData({ 
            ...initialState, 
            rfc: data.rfc,
            name: '',
            maternalName: '',
            paternalName: '',
            email: '',
            phone: '',
            existingRfc: false,
          });
          break;
        }
        setStep(step - 1);
        break;
      }
      default:
        setStep(step - 1);
        break;
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit = useCallback(
    value => {
      handleCreateClient({ ...data, ...value }, () => setData(initialState));
      dispatch({ type: CLEAR_AVAL_WITH_RFC });
    },
    [data]
  );

  if (!open) return null;

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-client-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle className={classes.title} id="form-new-client-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('CLIENTS_VIEW.ADD_NEW_CLIENT')}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2, mb: 2 }}>
          <FormStepper index={step} labels={labels} />
          <CurrentStep
            activeStep={step}
            setStep={setStep}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ClientsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateClient: PropTypes.func.isRequired
};

export default ClientsForm;
