import TimePeriod from 'src/utils/TimePeriod';
import {
  CLEAR_INCOME_RESUME,
  CLEAR_INCOME_RESUME_OPTIONS,
  LOAD_INCOME_RESUME,
  SAVE_INCOME_RESUME_OPTIONS
} from '../actions/incomeResumeActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    period: TimePeriod.MONTH,
    page: 0
  },
  items: [],
  count: 0
};

const incomeResumenReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INCOME_RESUME.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case SAVE_INCOME_RESUME_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_INCOME_RESUME_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case CLEAR_INCOME_RESUME: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default incomeResumenReducer;
