import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';

const EquipmentAlert = ({
  equipments,
  distributors,
  selectedDistributor,
  loading
}) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (equipments.length && distributors.length) {
    return null;
  }

  return (
    <>
      {!loading && !distributors.length ? (
        <Collapse in={open}>
          <Alert severity="error">
            {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_FIRST')}
            <Link to="/distributors/business">
              {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      ) : (
        !loading &&
        !equipments.length &&
        selectedDistributor && (
          <Collapse in={open}>
            <Alert severity="error">
              {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_FIRST')}
              <Link
                to={`/distributors/business/${selectedDistributor.id}/catalog`}
              >
                {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_SECOND')}
              </Link>
              {t('EQUIPMENT_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENTS_THIRD')}
            </Alert>
          </Collapse>
        )
      )}
    </>
  );
};

export default EquipmentAlert;

EquipmentAlert.propTypes = {
  selectedDistributor: PropTypes.string.isRequired,
  equipments: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};
