import { validateEmail } from 'src/utils/common';
import { isValidPhoneNumber } from 'libphonenumber-js';

import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    .test('email', 'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID', validateEmail)
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  facebook: Yup.string().url('SCHEMA_ERRORS.FACEBOOK_URL_INVALID'),
  instagram: Yup.string().matches(
    /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/,
    'SCHEMA_ERRORS.INSTAGRAM_FORMAT_INVALID'
  ),
  homePhone: Yup.string().test(
    'homePhone',
    'SCHEMA_ERRORS.PHONE_INVALID',
    value => {
      if (!value) return true;
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    }
  ),
  officePhone: Yup.string().test(
    'officePhone',
    'SCHEMA_ERRORS.PHONE_INVALID',
    value => {
      if (!value) return true;
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    }
  )
});
