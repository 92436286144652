import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import SearchBar from 'src/components/SearchBar';
import {
  createInvestorAction,
  downloadExcelInvestors,
  loadInvestors
} from 'src/store/actions/investorActions';
import { selectInvestorQueryOptions } from 'src/store/selectors/investorSelector';
import FileDownloadIcon from '@mui/icons-material/GetApp';
import AddButton from 'src/components/AddButton/AddButton';
import useStyles from './styles';
import CreateInvestorForm from './CreateInvestorForm';

const Toolbar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectInvestorQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);

  const debounceSearch = useDebounce(
    (name, queryOpts) => dispatch(loadInvestors({ ...queryOpts, name })),
    400
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelInvestors());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleOpenCreateInvestorForm = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseCreateInvestorForm = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateInvestor = useCallback(
    values => {
      dispatch(
        createInvestorAction(
          { ...values, agentId: values.agent?.id },
          handleCloseCreateInvestorForm
        )
      );
    },
    [dispatch, queryOptions, handleCloseCreateInvestorForm]
  );

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('INVESTORS_VIEW.TITLE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('INVESTORS_VIEW.SEARCH_INVESTOR')}
            />
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <FileDownloadIcon className={classes.icon} />
            </Button>
            <AddButton
              key="add-investor"
              onClick={handleOpenCreateInvestorForm}
            />
            <CreateInvestorForm
              open={open}
              handleClose={handleCloseCreateInvestorForm}
              handleCreateInvestor={handleCreateInvestor}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Toolbar;
