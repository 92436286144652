import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import {
  createAgent,
  downloadExportedAgents,
  getAgentDetail,
  getAllAgents,
  updateAgent
} from 'src/services/agentService';
import { removePointsPhone } from 'src/utils/common';
import { showMessage } from './snackBarActions';
import { selectAgentQueryOptions } from '../selectors/agentSelectors';

export const SAVE_AGENT_OPTIONS = 'SAVE_AGENT_OPTIONS';
export const CLEAR_AGENT_OPTIONS = 'CLEAR_INSVESTOR_OPTIONS';
export const LOAD_AGENTS = asyncActionCreator('LOAD_AGENTS');
export const CREATE_AGENT = asyncActionCreator('CREATE_AGENT');
export const LOAD_AGENT_DETAIL = asyncActionCreator('LOAD_AGENT_DETAIL');
export const UPDATE_AGENT = asyncActionCreator('UPDATE_AGENT');
export const LOAD_AGENT_LIST = asyncActionCreator('LOAD_AGENT_LIST');
export const CLEAR_AGENTS = 'CLEAR_AGENTS';


function getOptions(getState, sendLimit = true) {
  const queryOpts = selectAgentQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_AGENT_OPTIONS });
};

export const loadAgentsList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_AGENTS.start });

    dispatch({
      type: SAVE_AGENT_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const data = await getAllAgents(reqOptions);

    return dispatch({
      type: LOAD_AGENTS.success,
      ...data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AGENTS.failure });
  }
};

export const downloadExcelAgents = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadExportedAgents(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createAgentAction = (values, handleClose) => async dispatch => {
  try {
    dispatch({ type: CREATE_AGENT.start });

    await createAgent({
      ...values,
      phone: removePointsPhone(values.phone)
    });

    handleClose();

    dispatch(
      showMessage({
        message: 'CREATE_AGENT',
        variant: 'success'
      })
    );

    dispatch({ type: CREATE_AGENT.success });
    return dispatch(loadAgentsList());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_AGENT.failure });
  }
};

export const loadAgentDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_AGENT_DETAIL.start });

    const data = await getAgentDetail(id);

    return dispatch({
      type: LOAD_AGENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AGENT_DETAIL.failure });
  }
};

export const editAgent = (id, values, closeCallback) => async dispatch => {
  try {
    let body = values;
    dispatch({ type: UPDATE_AGENT.start });

    let { phone } = values;

    if (values.phone) {
      phone = removePointsPhone(values.phone);
      body = {
        ...body,
        phone
      };
    }

    await updateAgent({
      id,
      body
    });

    closeCallback();

    dispatch(
      showMessage({
        message: 'UPDATE_AGENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: UPDATE_AGENT.success });

    return dispatch(loadAgentDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_AGENT.failure });
  }
};

export const loadAllAgents = setState => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_AGENT_LIST.start });
    const reqOptions = getOptions(getState, false);
    const data = await getAllAgents(reqOptions);

    setState(data.items);
    return dispatch({ type: LOAD_AGENT_LIST.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AGENT_LIST.failure });
  }
};
