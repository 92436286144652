import {
  CLEAR_CONTRIBUTIONS,
  CLEAR_RENT_AFTER_CONTRIBUTION,
  LOAD_CONTRIBUTIONS,
  LOAD_RENT_AFTER_CONTRIBUTION
} from '../actions/contractActions';

const initialState = {
  data: null,
  rentAfterContribution: 0
};

const contributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTRIBUTIONS.success: {
      return {
        ...state,
        data: action.data
      };
    }
    case CLEAR_CONTRIBUTIONS: {
      return initialState;
    }
    case LOAD_RENT_AFTER_CONTRIBUTION.success: {
      return {
        ...state,
        rentAfterContribution: action.payload
      };
    }
    case CLEAR_RENT_AFTER_CONTRIBUTION: {
      return {
        ...state,
        rentAfterContribution: initialState.rentAfterContribution
      };
    }

    default: {
      return state;
    }
  }
};

export default contributionReducer;
