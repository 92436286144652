const makeInitialState = () => {
  return {
    rfc: '',
    name: '',
    maternalName: '',
    paternalName: '',
    phone: '',
    email: '',
    personType: '',
    denomination: '',
    industry: '',
    identificationNumber: '',
    sex: '',
    nip: '',
    curp: '',
    emailValidate: false,
    phoneValidate: false,
    useClientAddress: false,
    equipmentStreet: '',
    equipmentInsideHomeNumber: '',
    equipmentExternalHomeNumber: '',
    equipmentColonia: '',
    equipmentCity: '',
    equipmentState: '',
    equipmentZipCode: '',
    isDoctorWithTitle: false,
    yearsOperating: '',
    monthlyIncome: ''
  };
};

export default makeInitialState;
