import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import { Box, Card, Container } from '@mui/material';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  CLEAR_DISTRIBUTORS,
  LOAD_KAM_DISTRIBUTOR_PROFILE
} from 'src/store/actions/distributorActions';
import { useTranslation } from 'react-i18next';
import loadUserDistributor from 'src/store/actions/userDistributorActions';
import { selectKamDistributorDetail } from 'src/store/selectors/distributorSelectors';
import useStyles from './styles';
import UserDistributorTabs from './UserDistributorTabs';

const UserDistributorView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const distributor = useSelector(selectKamDistributorDetail);
  const { t } = useTranslation();
  const loading = useSelector(
    isLoadingSelector([LOAD_KAM_DISTRIBUTOR_PROFILE.action])
  );
  const success = useSelector(
    successSelector([LOAD_KAM_DISTRIBUTOR_PROFILE.action])
  );

  useEffect(() => {
    dispatch(loadUserDistributor());
    dispatch({ type: CLEAR_DISTRIBUTORS });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title={`${t('USER_NAVIGATION.MY_COMPANY')} - ${
        distributor ? distributor.commercialName : ''
      }`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loading && distributor ? (
              <UserDistributorTabs distributor={distributor} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default UserDistributorView;
