import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import ClientsListView from './Clients';
import ClientDetailView from './ClientDetail';
import AvalDetailView from './AvalDetail';

const ClientsView = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.url}/:tab?`} component={ClientsListView} />
      <Route
        exact
        path={`${match.url}/detail/:id/aval/detail/:avalId/:tab?/:subtab?`}
        component={AvalDetailView}
      />
      <Route
        exact
        path={`${match.url}/detail/:id/:tab?/:subtab?`}
        component={ClientDetailView}
      />
      <Route
        exact
        path={`${match.url}/list`}
        component={() => <Redirect to={`${match.url}`} />}
      />
    </>
  );
};

ClientsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default ClientsView;
