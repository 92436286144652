import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  CLEAR_DISTRIBUTORS_OPTIONS,
  LOAD_DISTRIBUTOR_CONTACTS,
  createDistributorContact,
  loadDistributorContactsList
} from 'src/store/actions/distributorActions';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  selectDistributorContactsCount,
  selectDistributorContactsList,
  selectDistributorQueryOptions
} from 'src/store/selectors/distributorSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import useStyles from './styles';
import ContactsTable from './ContactsTable';
import ContactDialog from './CreateContact';

const ContactsList = ({ isDisabledActions = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const contacts = useSelector(selectDistributorContactsList);
  const count = useSelector(selectDistributorContactsCount);
  const { page } = useSelector(selectDistributorQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CONTACTS.action])
  );

  const handleOpenContactForm = () => {
    setOpen(true);
  };

  const handleCloseContactForm = () => {
    setOpen(false);
  };

  const handleDistributorContact = useCallback(
    values => {
      dispatch(createDistributorContact(id, values));
    },
    [dispatch, handleCloseContactForm]
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadDistributorContactsList(id, { page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadDistributorContactsList(id));
    return () => dispatch({ type: CLEAR_DISTRIBUTORS_OPTIONS });
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid
          item
          lg={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'start'
          }}
        >
          <Button
            className={classes.buttons}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenContactForm}
            disabled={isDisabledActions}
          >
            {t('DISTRIBUTORS_VIEW.CREATE_CONTACT')}
          </Button>
          <ContactDialog
            open={open}
            onSubmit={handleDistributorContact}
            handleClose={handleCloseContactForm}
          />
        </Grid>
        <Grid item xs={12}>
          <ContactsTable
            contacts={contacts}
            isDisabledActions={isDisabledActions}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ContactsList.propTypes = {
  isDisabledActions: PropTypes.bool
};

export default ContactsList;
