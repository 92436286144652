import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addPointsPhone } from 'src/utils/common';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';

const GeneralInvestorInfo = ({ investor }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow name={t('COMMON.NAME')} value={investor.name} />
      <DetailRow
        name={t('COMMON.PATERNAL_NAME')}
        value={investor.paternalName}
      />
      <DetailRow
        name={t('COMMON.MATERNAL_NAME')}
        value={investor.maternalName}
      />

      <DetailRow
        name={t('COMMON.DATE_OF_BIRTH')}
        value={
          investor.birthDate
            ? selectFormatDate(investor.birthDate, dateFormatType.DAY)
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('COMMON.EMAIL')}
        value={investor.email ? investor.email : t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('COMMON.PHONE')}
        value={
          investor.phone ? addPointsPhone(investor.phone) : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('COMMON.SECONDARY_PHONE')}
        value={
          investor.secondaryPhone
            ? addPointsPhone(investor.secondaryPhone)
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow name={t('COMMON.RFC')} value={investor.rfc} />
    </>
  );
};

GeneralInvestorInfo.propTypes = {
  investor: PropTypes.object.isRequired
};
export default GeneralInvestorInfo;
