import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import DocumentClientInfo from './DocumentClientDetail';

const ClientDocuments = ({ client, isDisabledEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_DOCUMENTS')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DocumentClientInfo
              client={client}
              isDisabledEdit={isDisabledEdit}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

ClientDocuments.propTypes = {
  client: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default ClientDocuments;
