import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllInsurances = serviceBase({
  request: options =>
    axiosClient.get('/insurance', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    }),
  retry: 0
});

export const createNewInsurance = serviceBase({
  request: ({ equipmentId, insurance }) =>
    axiosClient.post(`/insurance/assign/${equipmentId}`, insurance),
  retry: 0
});

export const deleteInsurance = serviceBase({
  request: id => axiosClient.delete(`/insurance/unassign/${id}`),
  retry: 0
});

export const getInsuranceById = serviceBase({
  request: id => axiosClient.get(`/insurance/${id}`),
  retry: 0
});

export const editInsurance = serviceBase({
  request: ({ policyId, insurance }) =>
    axiosClient.put(`/insurance/edit/${policyId}`, insurance)
});
