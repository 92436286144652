import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getDistributors = serviceBase({
  request: options =>
    axiosClient.get('/distributor', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const downloadExportedDistributors = serviceBase({
  request: options =>
    axiosClient.get('/distributor/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Distribuidores.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const getDistributorById = serviceBase({
  request: id => axiosClient.get(`/distributor/${id}`),
  retry: 0
});

export const updateDistributorById = serviceBase({
  request: ({ id, values }) => axiosClient.patch(`/distributor/${id}`, values)
});

export const createNewDistributor = serviceBase({
  request: value => axiosClient.post(`/distributor`, value)
});

export const getDistributorContacts = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/distributor/${id}/contacts`, { params: options })
});

export const createNewDistributorContact = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/distributor/${id}/contacts`, values)
});

export const updateDistributorContactById = serviceBase({
  request: ({ id, values }) => {
    return axiosClient.patch(`/distributor/contacts/${id}`, values);
  }
});

export const deleteDistributorContactById = serviceBase({
  request: id => axiosClient.delete(`/distributor/contacts/${id}`)
});

export const getDistributorCatalogs = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/distributor/${id}/equipments`, {
      params: options
    })
});

export const createNewDistributorCatalog = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/distributor/${id}/equipments`, values)
});

export const uploadDistributorCatalogWithExcel = serviceBase({
  request: ({ id, formData }) =>
    axiosClient.post(`/distributor/${id}/equipments/upload`, formData)
});

export const updateDistributorCatalogById = serviceBase({
  request: ({ id, values }) =>
    axiosClient.patch(`/distributor/equipment/${id}`, values)
});

export const deleteDistributorCatalogById = serviceBase({
  request: id => axiosClient.delete(`/distributor/equipment/${id}`)
});

export const getDistributorAgents = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/distributor/${id}/profiles`, {
      params: options
    })
});

export const createNewDistributorAgent = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/distributor/${id}/profiles`, values)
});

export const updateDistributorAgentById = serviceBase({
  request: ({ id, values }) =>
    axiosClient.patch(`/distributor/profile/${id}`, values)
});

export const getDistributorParamsById = serviceBase({
  request: id => axiosClient.get(`/distributor/${id}/parameters`)
});

export const getDistributorUserDetail = serviceBase({
  request: id => axiosClient.get(`/distributor/profile/${id}`)
});

export const getDistributorProfileByClient = serviceBase({
  request: ({ id, clientId }) =>
    axiosClient.get(`/distributor/${id}/kam/${clientId}`)
});

export default getDistributors;
