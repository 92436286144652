import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import {
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import { roundNumber } from 'src/utils/common';

const ContractCalendarRow = ({
  period,
  date,
  initialBalance,
  finalBalance,
  monthlyPrincipal,
  contribution,
  residual,
  initialPayment,
  equipmentPrice
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">{period}</TableCell>
      <TableCell align="center">{date.replace('.', '')}</TableCell>
      <TableCell align="center">{formatPositiveAmount(roundNumber(initialBalance))}</TableCell>
      <TableCell align="center">{formatPositiveAmount(roundNumber(equipmentPrice))}</TableCell>
      <TableCell align="center">{formatNegativeAmount(roundNumber(initialPayment))}</TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(monthlyPrincipal))}
      </TableCell>
      <TableCell align="center">{formatNegativeAmount(roundNumber(contribution))}</TableCell>
      <TableCell align="center">{formatNegativeAmount(roundNumber(residual))}</TableCell>
      <TableCell align="center">{formatPositiveAmount(roundNumber(finalBalance))}</TableCell>
    </TableRow>
  );
};

ContractCalendarRow.propTypes = {
  period: PropTypes.number,
  date: PropTypes.string,
  initialBalance: PropTypes.number,
  finalBalance: PropTypes.number,
  monthlyPrincipal: PropTypes.number,
  contribution: PropTypes.number,
  residual: PropTypes.number,
  initialPayment: PropTypes.number,
  equipmentPrice: PropTypes.number
};

export default ContractCalendarRow;
