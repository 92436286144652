import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import PropTypes from 'prop-types';

const CounselorsAlert = ({ counselors, loading }) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading) {
    if (!counselors.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t(
              'COUNSELORS.SIGNATORIES_ASSIGNATION_FORM.ALERT.NO_COUNSELORS_FIRST'
            )}
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

CounselorsAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  counselors: PropTypes.array.isRequired
};

export default CounselorsAlert;
