export const selectBureauForm = state => state.bureauForm;
export const selectBureauFormClientDetail = state =>
  selectBureauForm(state).clientDetail;
export const selectBureauFormTokenData = state =>
  selectBureauForm(state).tokenData;
export const selectBureauFormId = state => selectBureauForm(state).formId;
export const selectBureauFormQuoteDetail = state =>
  selectBureauForm(state).quoteDetail;
export const selectBureauFormResult = state =>
  selectBureauForm(state).formResult;
