import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getInvestmentDetail = serviceBase({
  request: id => axiosClient.get(`/investment/${id}`)
});

export const getInvestmentList = serviceBase({
  request: options =>
    axiosClient.get('/investment', {
      params: { ...options }
    }),
  retry: 0
});

export const exportInvestmentList = serviceBase({
  request: options =>
    axiosClient.get('/investment/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Inversiones.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const createInvestment = serviceBase({
  request: data => axiosClient.post('/investment', data)
});

export const sendTitleToSign = serviceBase({
  request: id => axiosClient.post(`/investment/${id}/send-title-to-sign`)
});
