import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { PersonType } from 'src/utils/constants/clients';

const AvalsAlert = ({
  avals,
  clientId,
  loading,
  hasAvalsWithPendingDocs,
  legalRepresentativeAval,
  personType
}) => {
  const [open] = useState(true);
  const { t } = useTranslation();

  if (!loading) {
    if (!avals.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('CONTRACT_VIEW.CONTRACT_FORM.ALERT.NO_AVALS_FIRST')}
            <Link to={`/clients/detail/${clientId}/aval`}>
              {t('CONTRACT_VIEW.CONTRACT_FORM.ALERT.NO_AVALS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
    if (hasAvalsWithPendingDocs(avals)) {
      return (
        <Collapse in={open}>
          <Alert severity="warning">
            {t('SCHEMA_ERRORS.AVALS_HAVE_PENDING_DOCS')}
          </Alert>
        </Collapse>
      );
    }

    if (!legalRepresentativeAval && personType === PersonType.LEGAL) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('SCHEMA_ERRORS.CLIENT_WITHOUT_LEGAL_REPRESENTATIVE_1')}
            <Link to={`/clients/detail/${clientId}/aval`}>
              {t('SCHEMA_ERRORS.CLIENT_WITHOUT_LEGAL_REPRESENTATIVE_2')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

export default AvalsAlert;

AvalsAlert.propTypes = {
  avals: PropTypes.array.isRequired,
  clientId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasAvalsWithPendingDocs: PropTypes.func.isRequired,
  legalRepresentativeAval: PropTypes.object.isRequired,
  personType: PropTypes.string.isRequired
};
