import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField, Typography, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import {
  getAddressByZipCode,
  LOAD_ADDRESS_BY_ZIP_CODE
} from 'src/store/actions/clientActions';
import useIsLargeScreen from 'src/hooks/useIsLargeScreen';
import theme from 'src/theme';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepFive = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLargeScreen = useIsLargeScreen();

  const [addressData, setAddressData] = useState({
    cities: [],
    colonias: [],
    states: []
  });
  const isLoadingAddress = useSelector(
    isLoadingSelector([LOAD_ADDRESS_BY_ZIP_CODE.action])
  );
  const handleZipCodeChange = useCallback((e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue('zipCode', value);
    if (value.length === 5) {
      dispatch(getAddressByZipCode(value, setAddressData));
    }
    setFieldValue('colonia', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
  });

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext, data]
  );

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          height: '20%'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_FIVE.TITLE')}
        </Typography>
      </Box>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              component="form"
              sx={{
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box className={classes.container}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.street && errors.street)}
                    sx={{
                      width: ' 48%'
                    }}
                    helperText={touched.street && t(errors.street)}
                    label={t('COMMON.STREET')}
                    margin="normal"
                    name="street"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.street}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.externalHomeNumber && errors.externalHomeNumber
                    )}
                    sx={{
                      width: '24%'
                    }}
                    helperText={
                      touched.externalHomeNumber && t(errors.externalHomeNumber)
                    }
                    label={
                      isLargeScreen ? t('COMMON.EXTERNAL_HOME_NUMBER') : 'Ext.'
                    }
                    margin="normal"
                    name="externalHomeNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.externalHomeNumber}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.insideHomeNumber && errors.insideHomeNumber
                    )}
                    sx={{
                      width: '24%'
                    }}
                    helperText={
                      touched.insideHomeNumber && t(errors.insideHomeNumber)
                    }
                    label={
                      isLargeScreen ? t('COMMON.INSIDE_HOME_NUMBER') : 'Int.'
                    }
                    margin="normal"
                    name="insideHomeNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.insideHomeNumber}
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.zipCode && errors.zipCode)}
                    sx={{
                      width: '48%'
                    }}
                    helperText={touched.zipCode && t(errors.zipCode)}
                    label={t('COMMON.ZIP_CODE')}
                    margin="normal"
                    name="zipCode"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleZipCodeChange(e, setFieldValue);
                    }}
                    type="text"
                    value={values.zipCode}
                    variant="outlined"
                  />

                  <FormControl
                    sx={{
                      width: '50%'
                    }}
                    error={Boolean(touched.city && errors.city)}
                  >
                    <Autocomplete
                      freeSolo
                      onBlur={handleBlur}
                      options={addressData.cities.map(city => ({
                        key: city,
                        name: city
                      }))}
                      getOptionLabel={option => {
                        return option.name ? `${option.name}` : '';
                      }}
                      value={
                        addressData.cities
                          .map(city => ({
                            key: city,
                            name: city
                          }))
                          .find(city => city.name === values.city) ?? {
                          key: values.city,
                          name: values.city
                        }
                      }
                      placeholder={values.city}
                      onChange={(e, value) => {
                        setFieldValue(
                          'city',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('city', value);
                      }}
                      label={t('COMMON.CITY')}
                      error={Boolean(touched.city && errors.city)}
                      errorText={touched.city && t(errors.city)}
                      isLoading={isLoadingAddress}
                      disabled={!values.zipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControl
                    sx={{
                      width: '48%'
                    }}
                    error={Boolean(touched.colonia && errors.colonia)}
                  >
                    <Autocomplete
                      options={addressData.colonias.map(col => ({
                        key: col,
                        name: col
                      }))}
                      value={
                        addressData.colonias
                          .map(col => ({
                            key: col,
                            name: col
                          }))
                          .find(col => col.name === values.colonia) ?? {
                          key: values.colonia,
                          name: values.colonia
                        }
                      }
                      getOptionLabel={option =>
                        option.name ? `${option.name}` : ''
                      }
                      onChange={(e, value) => {
                        setFieldValue(
                          'colonia',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('colonia', value);
                      }}
                      freeSolo
                      label={t('COMMON.COLONIA')}
                      error={Boolean(touched.colonia && errors.colonia)}
                      errorText={touched.colonia && t(errors.colonia)}
                      isLoading={isLoadingAddress}
                      disabled={!values.zipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      width: '50%'
                    }}
                    error={Boolean(touched.state && errors.state)}
                  >
                    <Autocomplete
                      options={addressData.states.map(state => ({
                        key: state,
                        name: state
                      }))}
                      getOptionLabel={option =>
                        option.name ? `${option.name}` : ''
                      }
                      freeSolo
                      value={
                        addressData.states
                          .map(state => ({
                            key: state,
                            name: state
                          }))
                          .find(state => state.name === values.state) ?? {
                          key: values.state,
                          name: values.state
                        }
                      }
                      onChange={(e, value) => {
                        setFieldValue(
                          'state',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('state', value);
                      }}
                      label={t('COMMON.STATE')}
                      error={Boolean(touched.state && errors.state)}
                      errorText={touched.state && t(errors.state)}
                      isLoading={isLoadingAddress}
                      disabled={!values.zipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

StepFive.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepFive;
