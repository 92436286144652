import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%'
  },
  header: {
    color: theme.palette.primary.main,
    // marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  }
}));
