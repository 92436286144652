import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.PASS_REQUIRED'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'SCHEMA_ERRORS.THE_PASSWORDS_DO_NOT_MATCH'
    )
    .required('SCHEMA_ERRORS.PASS_REQUIRED')
});
