import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import BillingFailuresRow from './BillingFailuresRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ billingFailures }) =>
  billingFailures.map(billing => (
    <BillingFailuresRow key={billing.id} {...billing} />
  ));

const BillingFailuresTable = ({
  className,
  billingFailures,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="client" align="center">
          {t('COMMON.CLIENT')}
        </TableCell>

        <TableCell variant="head" key="amount" align="center">
          {t('COMMON.AMOUNT')}
        </TableCell>
        <TableCell variant="head" key="date" align="center">
          {t('COMMON.DATE')}
        </TableCell>
        <TableCell key="category" align="center">{t('TRANSACTION.CATEGORY_TITLE')}</TableCell>
        <TableCell variant="head" key="rent" align="center">
          {t('COMMON.ERROR')}
        </TableCell>
        <TableCell variant="head" key="actions" align="center">
          {}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(
    () => () => <Tbody billingFailures={billingFailures} />,
    [billingFailures]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, billingFailures]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

BillingFailuresTable.propTypes = {
  className: PropTypes.string,
  billingFailures: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

BillingFailuresTable.defaultProps = {
  count: 0
};

export default BillingFailuresTable;
