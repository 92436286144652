import {
  CLEAR_BACKGROUND_VALIDATIONS,
  LOAD_BACKGROUND_VALIDATIONS,
  SAVE_BACKGROUND_VALIDATION_OPTIONS
} from '../constants/CFDI';

const initialState = {
  validations: [],
  count: 0,
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  }
};

const backgroundValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BACKGROUND_VALIDATIONS.success: {
      return {
        ...state,
        validations: action.payload.items,
        count: action.payload.count
      };
    }
    case SAVE_BACKGROUND_VALIDATION_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case LOAD_BACKGROUND_VALIDATIONS.fail: {
      return {
        ...state,
        validations: [],
        count: 0
      };
    }
    case CLEAR_BACKGROUND_VALIDATIONS: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

export default backgroundValidationReducer;
