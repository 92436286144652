import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteEconomicActivityAction,
  editEconomicActivityAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import EconomicActivitiesForm from './EconomicActivitiesForm';

const EconomicActivitiesRow = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openEconomicActivityForm, setOpenEconomicActivityForm] = useState(
    false
  );
  const [openEconomicActivityDelete, setOpenEconomicActivityDelete] = useState(
    false
  );

  const handleCloseDeleteModal = () => {
    setOpenEconomicActivityDelete(false);
  };

  const handleCloseModal = () => {
    setOpenEconomicActivityForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEconomicActivityForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenEconomicActivityDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteEconomicActivityAction(id, handleCloseDeleteModal));
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editEconomicActivityAction(values, handleCloseModal));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu
          handleClickOption={handleClickMore}
          deleteDisabled={id === 1}
        />
      </TableCell>

      <EconomicActivitiesForm
        data={{ name, id }}
        open={openEconomicActivityForm}
        onClose={handleCloseModal}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openEconomicActivityDelete}
        handleClose={handleCloseDeleteModal}
        title={t(
          'CATALOGS_VIEW.ECONOMIC_ACTIVITIES.DELETE_ECONOMIC_ACTIVITY_TITLE'
        )}
        description={t(
          'CATALOGS_VIEW.ECONOMIC_ACTIVITIES.DELETE_ECONOMIC_ACTIVITY_DESCRIPTION'
        )}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

EconomicActivitiesRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default EconomicActivitiesRow;
