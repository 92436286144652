import React, { useCallback, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory } from 'react-router';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TabSubMenu from 'src/components/TabSubMenu/TabSubMenu';
import { DirectorQuotesTabsIndex } from 'src/utils/constants/quotes';
import TabPanel from 'src/components/TabPanel/TabPanel';
import PropTypes from 'prop-types';
import QuotesListTabs from '../QuotesListTabs';

const QuotesTabsMenu = {
  QUOTES: 'QUOTES'
};

const GeneralMenuList = [
  {
    value: DirectorQuotesTabsIndex.MY_QUOTES,
    label: 'USER_NAVIGATION.MY_QUOTES'
  },
  {
    value: DirectorQuotesTabsIndex.QUOTES,
    label: 'USER_NAVIGATION.QUOTES'
  }
];

const DirectorQuotesTabs = ({ currentView, handleTabChange }) => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = useState(currentView);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenSelectSubMenu = (e, type) => {
    switch (type) {
      case QuotesTabsMenu.QUOTES:
        setAnchorEl(e.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = useCallback(
    (_, newValue) => {
      if (newValue === value) {
        return;
      }
      setValue(newValue);
      handleTabChange(_, newValue);
      handleClose();
    },
    [setValue, value]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t(`USER_NAVIGATION.${value}`)}
            icon={<KeyboardArrowDownIcon />}
            iconPosition="end"
            onClick={e => {
              handleOpenSelectSubMenu(e, QuotesTabsMenu.QUOTES);
            }}
          />
        </Tabs>
        <TabSubMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleChange={handleChange}
          open={open}
          list={GeneralMenuList}
        />
      </AppBar>

      <TabPanel value={value} index={DirectorQuotesTabsIndex.MY_QUOTES}>
        <QuotesListTabs />
      </TabPanel>
      <TabPanel value={value} index={DirectorQuotesTabsIndex.QUOTES}>
        <QuotesListTabs isDirectorView />
      </TabPanel>
    </Box>
  );
};

DirectorQuotesTabs.propTypes = {
  currentView: PropTypes.string,
  handleTabChange: PropTypes.func
};

export default DirectorQuotesTabs;
