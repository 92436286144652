import {
  downloadExcelListAmorizationBalance,
  getAmortizationBalance
} from 'src/services/amortizationBalanceService';
import AmortizationBalanceType from 'src/utils/constants/accounting';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  selectAmortizationBalanceList,
  selectAmortizationBalancesQueryOptions,
  selectPolicyBalanceList
} from '../selectors/amortizationBalanceSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_AMORTIZATION_BALANCE = asyncActionCreator(
  'LOAD_AMORTIZATION_BALANCE'
);
export const CLEAR_AMORTIZATION_LIST = 'CLEAR_AMORTIZATION_LIST';
export const DOWNLOAD_AMORTIZATION_BALANCE = asyncActionCreator(
  'DOWNLOAD_AMORTIZATION_BALANCE'
);
export const CLEAR_AMORTIZATION_BALANCE = 'CLEAR_AMORTIZATION_BALANCE';
export const SAVE_AMORTIZATION_BALANCE_OPTIONS =
  'SAVE_AMORTIZATION_BALANCE_OPTIONS';
export const CLEAR_AMORTIZATION_BALANCE_OPTIONS =
  'CLEAR_AMORTIZATION_BALANCE_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectAmortizationBalancesQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.periodStartDate && queryOpts.periodEndDate) {
    reqOptions.periodStartDate = queryOpts.periodStartDate;
    reqOptions.periodEndDate = queryOpts.periodEndDate;
  }
  if (queryOpts.amortizationBalanceType) {
    reqOptions.amortizationBalanceType = queryOpts.amortizationBalanceType;
  }
  if (queryOpts.equipment) {
    reqOptions.equipment = queryOpts.equipment;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_AMORTIZATION_BALANCE_OPTIONS });
};

export const loadAmortizationBalance = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_AMORTIZATION_BALANCE.start });
    dispatch({
      type: SAVE_AMORTIZATION_BALANCE_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const interesetBalance = await getAmortizationBalance(reqOptions);

    let balanceList = {
      amortizationItems: [],
      policyItems: []
    };

    if (
      reqOptions.amortizationBalanceType ===
      AmortizationBalanceType.ACUMULATED_AMORTIZATION
    ) {
      balanceList = {
        ...interesetBalance,
        amortizationItems: interesetBalance.items,
        policyItems: selectPolicyBalanceList(getState())
      };
    } else {
      balanceList = {
        ...interesetBalance,
        amortizationItems: selectAmortizationBalanceList(getState()),
        policyItems: interesetBalance.items
      };
    }

    return dispatch({
      type: LOAD_AMORTIZATION_BALANCE.success,
      ...balanceList
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AMORTIZATION_BALANCE.failure });
  }
};

export const downloadExcelAmortizationBalance = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOWNLOAD_AMORTIZATION_BALANCE.start
    });

    dispatch({
      type: SAVE_AMORTIZATION_BALANCE_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExcelListAmorizationBalance(reqOptions);

    return dispatch({
      type: DOWNLOAD_AMORTIZATION_BALANCE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_AMORTIZATION_BALANCE.failure });
  }
};
