import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default values => {
  return {
    ...values,
    term: values.term || null,
    startDate: momentLocalTimezone(),
    firstRentDate: momentLocalTimezone()
      .add(1, 'month')
      .add(values.gracePeriod || 0, 'days'),
    endDate: momentLocalTimezone()
      .add(values.gracePeriod || 0, 'days')
      .add(values.term, 'months'),
    sendToDigitalSign: true,
    payday: Math.min(momentLocalTimezone().date(), 25)
  };
};
