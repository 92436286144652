import * as Yup from 'yup';

export default Yup.object().shape({
  client: Yup.object().required('SCHEMA_ERRORS.CLIENT_REQUIRED'),
  distributorEquipment: Yup.object().required(
    'SCHEMA_ERRORS.EQUIPMENT_REQUIRED'
  ),
  price: Yup.number()
    .required('SCHEMA_ERRORS.PRICE_REQUIRED')
    .min(1, 'SCHEMA_ERRORS.PRICE_GREATER_THAN_ZERO'),
  equipmentInsuranceIncluded: Yup.boolean().required(
    'SCHEMA_ERRORS.REQUIRED_FIELD'
  ),
  gracePeriod: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  initialPayment: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  paymentInputOption: Yup.string().required(' ')
});
