import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from '../styles';
import InsuranceDetailDialog from './IsuranceDetailDialog';
import {
  updateInsurance,
  createInsurance,
  unassignInsurance
} from '../../../../store/actions/insuranceActions';
import InsuranceInfo from './InsuranceDetail';
import DeleteInsuranceDialog from './DeleteInsuranceDialog/DeleteInsuranceDialog';

const InsuranceDetail = ({ equipment }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      if (!equipment.insurance) {
        dispatch(createInsurance(equipment.id, values));
        handleClose();
      } else {
        dispatch(
          updateInsurance(
            equipment.id,
            equipment.insurance.policyId,
            values,
            () => handleClose()
          )
        );
      }
    },
    [equipment, dispatch]
  );

  const handleClickOpenDelete = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  const handleCloseDelete = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const handleSubmitDelete = useCallback(() => {
    dispatch(unassignInsurance(equipment.id, equipment.insurance.policyId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.INSURANCE')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        {equipment.insurance && (
          <Table>
            <TableBody>
              <InsuranceInfo equipment={equipment} />
            </TableBody>
          </Table>
        )}
        <InsuranceDetailDialog
          open={open}
          handleClose={handleClose}
          equipment={equipment}
          submit={handleSubmit}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {equipment.insurance
                ? t('COMMON.EDIT')
                : t('INSURANCES_VIEW.CREATE_INSURANCE')}
            </Button>
          </Grid>
          {equipment.insurance && (
            <Grid
              item
              className={classes.buttons}
              style={{ marginBottom: '32px' }}
            >
              <DeleteInsuranceDialog
                open={openDelete}
                handleClose={handleCloseDelete}
                handleSubmit={handleSubmitDelete}
              />
              <Button
                onClick={handleClickOpenDelete}
                variant="contained"
                color="primary"
                className={clsx(classes.outlinedDelete, classes.buttons)}
                startIcon={<DeleteIcon />}
              >
                {t('COMMON.DELETE')}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

InsuranceDetail.propTypes = {
  equipment: PropTypes.object
};

export default InsuranceDetail;
