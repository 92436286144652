import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { useTranslation } from 'react-i18next';
import { SatWsClientChangeParameter } from 'src/utils/constants/clients';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import OverflowTipography from 'src/components/OverflowTipography';

const selectParameterType = (parameterType, value, t) => {
  switch (parameterType) {
    case SatWsClientChangeParameter.TAX_RETURN:
      return <DocumentLink link={value} />;

    case SatWsClientChangeParameter.TAX_COMPLIANCE_DOC:
      return <DocumentLink link={value} />;

    case SatWsClientChangeParameter.TAX_STATUS_DOC:
      return <DocumentLink link={value} />;

    case SatWsClientChangeParameter.TAX_COMPLIANCE_STATUS:
      return t(`ENUMS.TAX_COMPLIANCE_STATUS.${value.toUpperCase()}`);

    case SatWsClientChangeParameter.LEGAL_NAME:
      return value;

    case SatWsClientChangeParameter.ADDRESS:
      // eslint-disable-next-line no-case-declarations
      const address = JSON.parse(value);
      return (
        <OverflowTipography>
          {`• ${t('COMMON.STREET')}: ${address.street}.\n
        • ${t('COMMON.INSIDE_HOME_NUMBER')}: ${address.insideHomeNumber}. 
        • ${t('COMMON.EXTERNAL_HOME_NUMBER')}: ${address.externalHomeNumber}. 
        • ${t('COMMON.COLONIA')}: ${address.colonia}. 
        • ${t('COMMON.CITY')}: ${address.city}. 
        • ${t('COMMON.STATE')}: ${address.state}. 
        • ${t('COMMON.ZIP_CODE')}: ${address.zipCode}. 
        `}
        </OverflowTipography>
      );

    case SatWsClientChangeParameter.TAX_SYSTEM:
      return t(`ENUMS.TAX_SYSTEM.${value}`);

    default:
      return null;
  }
};

const SatWsHistoryRow = ({ date, parameter, value }) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell>{selectFormatDate(date, dateFormatType.DAY)}</TableCell>
      <TableCell>
        {t(`ENUMS.SAT_WS_CLIENT_PARAMETER_CHANGE.${parameter}`)}
      </TableCell>
      <TableCell>{selectParameterType(parameter, value, t)}</TableCell>
    </TableRow>
  );
};

SatWsHistoryRow.propTypes = {
  date: PropTypes.string.isRequired,
  parameter: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default SatWsHistoryRow;
