import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import { InsuranceStatus } from 'src/utils/constants/insurance';
import { Link } from 'react-router-dom';

const InsuranceRow = ({
  equipment,
  insuranceStatus,
  remainingDays,
  policyId,
  insurer,
  agency
}) => {
  const { t } = useTranslation();

  const getInsuranceStatus = () => {
    const { status, message } = insuranceStatus;

    const getInsuranceDescription = messageList => {
      let description = '';
      if (messageList.length > 1) {
        messageList.forEach(msg => {
          description += `• ${t(`INSURANCES_VIEW.MESSAGE.${msg}`)}. \n `;
        });
      } else {
        description = t(`INSURANCES_VIEW.MESSAGE.${messageList[0]}`);
      }
      return description;
    };

    switch (status) {
      case InsuranceStatus.ERROR:
        return {
          description: getInsuranceDescription(message),
          type: IconStatusAndColor.ERROR.status
        };
      case InsuranceStatus.ACTIVE:
        return {
          description: t('INSURANCES_VIEW.MESSAGE.ACTIVE'),
          type: IconStatusAndColor.OK.status
        };
      default:
        return {
          description: t('COMMON.NO_INFORMATION'),
          type: IconStatusAndColor.NONE.status
        };
    }
  };

  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/inventory/${equipment.id}`}>{equipment.id}</Link>
      </TableCell>
      <TableCell align="center">
        <StatusIcon {...getInsuranceStatus()} />
      </TableCell>
      <TableCell align="center">{remainingDays ?? 0}</TableCell>
      <TableCell align="center">{policyId ?? ''}</TableCell>
      <TableCell align="center">{agency.name ?? ''}</TableCell>
      <TableCell align="center">{insurer.name ?? ''}</TableCell>
      <TableCell align="center">
        {t(`EQUIPMENT_VIEW.${equipment.mode}`) ?? ''}
      </TableCell>
    </TableRow>
  );
};

InsuranceRow.propTypes = {
  remainingDays: PropTypes.number.isRequired,
  policyId: PropTypes.string.isRequired,
  insurer: PropTypes.instanceOf({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  agency: PropTypes.instanceOf({
    id: PropTypes.number,
    name: PropTypes.string,
    city: PropTypes.string
  }).isRequired,
  insuranceStatus: PropTypes.instanceOf({
    status: PropTypes.string,
    error: PropTypes.string
  }).isRequired,
  equipment: PropTypes.instanceOf({
    id: PropTypes.number,
    organizationRfc: PropTypes.string,
    status: PropTypes.string,
    mode: PropTypes.string,
    brandId: PropTypes.number,
    serialNumber: PropTypes.string
  }).isRequired
};

export default InsuranceRow;
