import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmount } from 'src/utils/formatAmout';
import OverflowTipography from 'src/components/OverflowTipography';
import { useTranslation } from 'react-i18next';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { CFDIListActions } from 'src/utils/constants/cfdi';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';

export const getCFDIListOptions = document => {
  return [
    {
      name: CFDIListActions.PDF,
      label: `ENUMS.CFDI_LIST_ACTIONS.${CFDIListActions.VIEW_PDF}`,
      icon: <InsertDriveFileRoundedIcon />,
      disabled: !document,
      message: 'COMMON.NO_DOCUMENTS_AVAILABLE'
    }
  ];
};

const CFDIReceivedRow = ({
  createdAt,
  folio,
  amount,
  issuerInfo,
  document,
  cfdiUse
}) => {
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
  const { t } = useTranslation();

  const handleClickOption = option => {
    switch (option) {
      case CFDIListActions.VIEW_PDF:
        setOpenDocumentViewer(true);
        break;

      default:
        break;
    }
  };

  return (
    <TableRow hover align="center">
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="center"
      >
        {selectFormatDate(createdAt, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center">{folio}</TableCell>
      <TableCell align="center">
        <OverflowTipography align="center">
          {issuerInfo?.legal_name}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {cfdiUse ?? t('COMMON.NOT_AVAILABLE')}
      </TableCell>
      <TableCell align="center">{formatAmount(amount)}</TableCell>
      <TableCell align="right">
        <MoreMenu
          deleteDisabled
          editDisabled
          key={folio}
          options={getCFDIListOptions(document)}
          handleClickOption={handleClickOption}
        />
        {openDocumentViewer && document.documentUrl && (
          <DocumentViewerDialog
            documentUrl={document.documentUrl}
            open={openDocumentViewer}
            onClose={() => setOpenDocumentViewer(false)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

CFDIReceivedRow.propTypes = {
  createdAt: PropTypes.string.isRequired,
  folio: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  issuerInfo: PropTypes.shape({
    tax_id: PropTypes.number.isRequired,
    legal_name: PropTypes.string.isRequired,
    tax_system: PropTypes.string.isRequired
  }).isRequired,
  document: PropTypes.object,
  cfdiUse: PropTypes.string
};

export default CFDIReceivedRow;
