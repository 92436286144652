export default values => {
  return {
    equipmentLocation: {
      street: values.equipmentLocation.street ?? '',
      insideHomeNumber: values.equipmentLocation.insideHomeNumber ?? '',
      externalHomeNumber: values.equipmentLocation.externalHomeNumber ?? '',
      colonia: values.equipmentLocation.colonia ?? '',
      city: values.equipmentLocation.city ?? '',
      state: values.equipmentLocation.state ?? '',
      zipCode: values.equipmentLocation.zipCode ?? ''
    },
    includeClientAddress: !values.configuredEquipmentLocation
      ? !!values.equipmentLocation.street
      : values.includeClientAddress
  };
};
