import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getUserClients = serviceBase({
  request: options =>
    axiosClient.get('/distributor-profile/client/list', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const getUserClientById = serviceBase({
  request: id => axiosClient.get(`/distributor-profile/client/detail/${id}`)
});

export const getClientByRFC = serviceBase({
  request: rfc => axiosClient.get(`/distributor-profile/client/${rfc}`)
});

export const addClientToList = serviceBase({
  request: data => axiosClient.post('/distributor-profile/client/list', data)
});

export const createClientAndAddToList = serviceBase({
  request: data => axiosClient.post('/distributor-profile/client', data)
});

export const createDistributorClientFromAval = serviceBase({
  request: data =>
    axiosClient.post(`/distributor-profile/client/create-from-aval`, data)
});

export const createDistributorClientAval = serviceBase({
  request: data => axiosClient.post(`/distributor-profile/aval`, data)
});

export const updateDistributorClientAvalById = serviceBase({
  request: ({ id, aval }) =>
    axiosClient.patch(`/distributor-profile/aval/${id}`, aval)
});

export const deleteDistributorClientAvalById = serviceBase({
  request: id => axiosClient.delete(`/distributor-profile/aval/${id}`)
});

export const updateUserClient = serviceBase({
  request: ({ id, data }) =>
    axiosClient.patch(`/distributor-profile/client/${id}`, data)
});

export const getUserAvalByClientId = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/distributor-profile/avals/${id}`, {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const getUserAvalById = serviceBase({
  request: id => axiosClient.get(`/distributor-profile/aval/${id}`)
});

export default getUserClients;
