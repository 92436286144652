import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  personType: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  email: Yup.string()
    .test('email', 'SCHEMA_ERRORS.EMAIL_FORMAT_INVALID', validateEmail)
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED'),
  paternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.PATERNAL_NAME_REQUIRED')
  }),
  maternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.MATERNAL_NAME_REQUIRED')
  }),
  industry: Yup.string().when('personType', {
    is: PersonType.LEGAL,
    then: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  }),
  denomination: Yup.string().when('personType', {
    is: PersonType.LEGAL,
    then: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  }),
  sex: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.GENDER_REQUIRED')
  }),
  curp: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .matches(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_REQUIRED')
  }),
  identificationNumber: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .max(
        30,
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.IDENTIFICATION_NUMBER_TOO_LONG'
      )
      .required(
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.IDENTIFICATION_NUMBER_REQUIRED'
      )
  }),
  nationality: Yup.string().required('SCHEMA_ERRORS.COUNTRY_OF_BIRTH_REQUIRED')
});
