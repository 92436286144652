import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_CONTRACT_FROM_QUOTE } from 'src/store/actions/contractActions';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import FinalStep from './FinalStep';

const CurrentStep = ({
  activeStep,
  data,
  onNext,
  onBack,
  onSubmit,
  open,
  resetRef,
  clientAddress,
  isQuoteAssignedToClient
}) => {
  const loading = useSelector(
    isLoadingSelector([CREATE_CONTRACT_FROM_QUOTE.action])
  );

  if (activeStep === 0) {
    return (
      <StepOne
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        open={open}
        resetRef={resetRef}
      />
    );
  }

  if (activeStep === 1) {
    return (
      <StepTwo
        data={data}
        open={open}
        isQuoteAssignedToClient={isQuoteAssignedToClient}
        handleNext={onNext}
        handleBack={onBack}
        resetRef={resetRef}
        existingAddress={clientAddress}
      />
    );
  }

  if (activeStep === 2) {
    return (
      <StepThree
        data={data}
        open={open}
        handleNext={onNext}
        handleBack={onBack}
        resetRef={resetRef}
        existingAddress={clientAddress}
      />
    );
  }
  if (activeStep === 3) {
    return (
      <StepFour
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        resetRef={resetRef}
        existingAddress={clientAddress}
      />
    );
  }
  return (
    <FinalStep
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired,
  clientAddress: PropTypes.object,
  isQuoteAssignedToClient: PropTypes.number
};

export default CurrentStep;
