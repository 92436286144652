export const selectDistributor = state => state.distributor;
export const selectDistributorList = state => selectDistributor(state).items;
export const selectDistributorListCount = state =>
  selectDistributor(state).count;
export const selectDistributorDetail = state => selectDistributor(state).detail;
export const selectKamDistributorDetail = state =>
  selectDistributor(state).distributorKamDetail;

export const selectDistributorQueryOptions = state => {
  const { options } = selectDistributor(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

// Distributor Contacts

export const selectDistributorContactsList = state =>
  selectDistributor(state).contacts.items;
export const selectDistributorContactsCount = state =>
  selectDistributor(state).contacts.count;

// Distributor Catalog
export const selectDistributorCatalogs = state =>
  selectDistributor(state).catalog.items;
export const selectDistributorCatalogsCount = state =>
  selectDistributor(state).catalog.count;

export const selectDistributorCatalogsFailUpload = state =>
  selectDistributor(state).catalog.failUploadCsv;

// Distributor Agents

export const selectDistributorAgents = state =>
  selectDistributor(state).agents.items;
export const selectDistributorAgentsCount = state =>
  selectDistributor(state).agents.count;
