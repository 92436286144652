import React from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { selectContractsQueryOptions } from 'src/store/selectors/contractSelectors';
import Toolbar from './Toolbar';
import ContractListTabs from './ContractListTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ContractListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = useSelector(selectContractsQueryOptions);

  return (
    <Page className={classes.root} title={t('CONTRACT_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar contractStatus={status} />
          <Box mt={3}>
            <ContractListTabs />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default ContractListView;
