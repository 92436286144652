import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
    LOAD_CLIENT_ADDRESSES,
  loadClientAddresses
} from 'src/store/actions/clientActions';
import { selectClientAddressesList } from 'src/store/selectors/clientSelectors';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddressType from 'src/components/AddressDialog/AddressEnums';
import Preloader from 'src/components/Preloader';
import ClientAddress from '.';

const GeneralAddressView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector([LOAD_CLIENT_ADDRESSES.action]));
  const success = useSelector(successSelector([LOAD_CLIENT_ADDRESSES.action]));
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadClientAddresses(id));
  }, [dispatch, id]);

  const addresses = useSelector(selectClientAddressesList);

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.ADDRESS')}
            </Typography>
          </Box>
        </Grid>

        {success && !loading ? (
          <>
            {addresses &&
              addresses.map(address => {
                const addressName =
                  address.addressType ===  AddressType.HOME
                    ? t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_PERSONAL_ADDRESS')
                    : t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_LEGAL_ADDRESS');
                return (
                  <Grid item xs={12} md={6} lg={6}>
                    <ClientAddress
                      address={address}
                      addressName={addressName}
                    />
                  </Grid>
                );
              })}
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GeneralAddressView;
