import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Button, Box, Tooltip } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { Add, GetApp } from '@mui/icons-material';
import useDebounce from 'src/hooks/useDebounce';
import { selectEquipmentQueryOptions } from 'src/store/selectors/equipmentSelectors';
import {
  CLEAR_DISTRIBUTORS,
  createDistributor,
  downloadDistributor,
  loadDistributors
} from 'src/store/actions/distributorActions';
import SearchBar from 'src/components/SearchBar';
import useStyles from './styles';
import DistributorForm from './DistributorForm';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectEquipmentQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [openForm, setOpenForm] = useState(false);

  const debounceSearch = useDebounce(
    (name, queryOpts) =>
      dispatch(loadDistributors({ ...queryOpts, name, page: 0 })),
    400
  );

  useEffect(() => {
    dispatch(loadDistributors());
    return () => dispatch({ type: CLEAR_DISTRIBUTORS });
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpenForm(true);
  }, [setOpenForm]);

  const handleClose = useCallback(() => {
    setOpenForm(false);
  }, [setOpenForm]);

  const handleCreateDistributor = useCallback(
    values => {
      dispatch(createDistributor(values, () => handleClose()));
    },
    [dispatch, handleClose]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadDistributor());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('DISTRIBUTORS_VIEW.TITLE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
            <Tooltip title={t('DISTRIBUTORS_VIEW.CREATE_DISTRIBUTOR')}>
              <Button
                className={clsx(classes.buttons, classes.outlined)}
                onClick={handleClickOpen}
                color="secondary"
              >
                <Add className={classes.icon} />
              </Button>
            </Tooltip>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <GetApp className={classes.icon} />
            </Button>
          </Box>
          <DistributorForm
            open={openForm}
            handleClose={handleClose}
            submit={handleCreateDistributor}
          />
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
