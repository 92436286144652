import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box } from '@mui/material';

import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { loadTransactions } from 'src/store/actions/transactionActions';
import { selectTransactionsQueryOptions } from 'src/store/selectors/transactionSelectors';
import DownloadButton from 'src/components/DownloadButton';
import SearchBar from 'src/components/SearchBar';
import useDebounce from 'src/hooks/useDebounce';
import useStyles from './styles';
import DownloadTransationListDialog from './DownloadTransactionListDialog/DownloadTransationListDialog';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openDownloadTransactions, setOpenDownloadTransactions] = useState(
    false
  );

  const queryOptions = useSelector(selectTransactionsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearch = useDebounce(
    (clientName, queryOpts) =>
      dispatch(loadTransactions({ ...queryOpts, clientName, page: 0 })),
    400
  );

  const handleOpenDownloadTransactions = () => {
    setOpenDownloadTransactions(true);
  };

  const handleCloseDownloadTransactions = () => {
    setOpenDownloadTransactions(false);
  };

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  useEffect(() => {
    dispatch(loadTransactions());
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box style={{ display: 'flex', gap: '0.5rem' }}>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.TRANSACTIONS')}
            </Typography>
            <Typography
              style={{ fontWeight: '300', alignSelf: 'center' }}
              color="primary"
              variant="h3"
            >
              {t('COMMON.AMOUNT_IVA_INCLUDED')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
            <Box sx={{ display: 'flex', marginLeft: 4 }}>
              <DownloadButton
                onDownload={handleOpenDownloadTransactions}
                title="COMMON.DOWNLOAD_TRANSACTIONS"
              />
            </Box>
          </Box>
        </Box>
        <DownloadTransationListDialog
          open={openDownloadTransactions}
          onClose={handleCloseDownloadTransactions}
        />
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
