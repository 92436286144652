import React, { useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import FadeIn from 'react-fade-in';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import Footer from 'src/components/Footer';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { RESET_PASSWORD, resetPassword } from 'src/store/actions/authActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import theme from 'src/theme';
import { UserTypePath } from 'src/utils/constants/auth';
import validationSchema from './schema';
import makeIntiailValues from './initialState';
import useStyles from '../styles';

const RecoverPasswordView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userType = UserTypePath[pathname.split('/')[1]];
  const initialValues = makeIntiailValues();

  const loadingRecoverPassword = useSelector(
    isLoadingSelector([RESET_PASSWORD.action])
  );

  const timeoutRef = useRef(null);

  const handleChangePassword = useCallback(
    ({ email }, actions) => {
      timeoutRef.current = setTimeout(() => {
        actions.setSubmitting(false);
      }, [200]);
      dispatch(resetPassword(email, userType));
    },
    [dispatch, timeoutRef]
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  const trimWhitespaces = useCallback(
    setFieldValue => e => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
    []
  );

  return (
    <MainLayout>
      <Page
        className={classes.root}
        title={t('LOGIN_VIEW.RECOVER_PASSWORD')}
        sx={{
          padding: {
            xs: `${theme.spacing(4)} ${theme.spacing(3.5)}`,
            sm: `${theme.spacing(4)} ${theme.spacing(10)}`
          }
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <div style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </div>
          <Container
            className={classes.login}
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '75%'
              }
            }}
          >
            <FadeIn transitionDuration={200}>
              {!loadingRecoverPassword ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleChangePassword}
                >
                  {({
                    errors,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}>
                        <Typography
                          className={classes.title}
                          sx={{
                            fontSize: {
                              xs: theme.spacing(3.5),
                              sm: theme.spacing(5),
                              md: theme.spacing(6),
                              lg: theme.spacing(7)
                            }
                          }}
                        >
                          {t('LOGIN_VIEW.RECOVER_PASSWORD')}
                        </Typography>
                      </Box>
                      <Box className={classes.center}>
                        <TextField
                          InputProps={{
                            autoComplete: 'off'
                          }}
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email ? t(errors.email) : null}
                          label={t('COMMON.EMAIL')}
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={trimWhitespaces(setFieldValue)}
                          type="text"
                          value={values.email}
                          variant="outlined"
                        />

                        <Box
                          my={3}
                          className={classes.center}
                          sx={{
                            width: '100%'
                          }}
                        >
                          <Button
                            className={classes.button}
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {t('COMMON.CONFIRM')}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Loading
                    label={t('AUTH_VIEWS.THE_PASSWORD_IS_BEING_RESET')}
                  />
                </Box>
              )}
            </FadeIn>
          </Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography>
              {t('LOGIN_VIEW.YOU_HAVE_AN_ACCOUNT')}
              &nbsp;
              <Link to="/user/login">{t('LOGIN_VIEW.CLICK_HERE')}</Link>
            </Typography>
          </Box>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default RecoverPasswordView;
