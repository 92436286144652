import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ContractIcon from '@mui/icons-material/ReceiptLong';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { loadClientContracts } from 'src/store/actions/clientActions';
import ContractStatus from 'src/utils/constants/contracts';
import { selectClientContractsTotalCount } from 'src/store/selectors/clientSelectors';
import useStyles from './styles';
import ContractListTabs from './ContractListTabs';

const ContractsDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const totalCount = useSelector(selectClientContractsTotalCount);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadClientContracts({
        clientId: id,
        page: 0,
        status: ContractStatus.IN_PROGRESS
      })
    );
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.CONTRACTS')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={12}>
          <Grid container className={classes.transactionContainer}>
            <Grid item lg={4} className={classes.transaction}>
              <Box className={classes.transactionIcon}>
                <ContractIcon fill="#28A745" />
              </Box>
              <Grid item>
                <Typography
                  style={{ marginBottom: 8 }}
                  color="textPrimary"
                  component="p"
                  variant="body2"
                >
                  {t('COMMON.CONTRACTS')}
                </Typography>
                <Typography color="primary" component="h2" variant="h2">
                  {totalCount || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Box />
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <ContractListTabs />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractsDetail;
