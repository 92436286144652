import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from 'src/components/AddButton/AddButton';
import PropTypes from 'prop-types';

const BrandsAndModelsToolbar = ({ onOpen, disabledActions }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0'
      }}
    >
      <Typography
        sx={{
          fontWeight: '400',
          textTransform: 'capitalize',
          mb: 2,
          mt: 2
        }}
        color="primary"
        variant="h1"
      >
        {t('COMMON.BRANDS_AND_MODELS')}
      </Typography>

      <AddButton
        onClick={onOpen}
        disabled={disabledActions}
        style={{ visibility: disabledActions ? 'hidden' : 'visible' }}
        label={t('NAVIGATION.CATALOG_LIST.BRANDS')}
      />
    </Box>
  );
};

BrandsAndModelsToolbar.propTypes = {
  onOpen: PropTypes.func.isRequired,
  disabledActions: PropTypes.bool.isRequired
};

export default BrandsAndModelsToolbar;
