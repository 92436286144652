import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import AccountStatementRow from './AccountStatementRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ transactions }) =>
  transactions.map(transaction => {
    return <AccountStatementRow key={transaction.id} {...transaction} />;
  });

const AccountStatementTable = ({
  className,
  transactions,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const headColumnWith = '7%';

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.DATE')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.TIME')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.CATEGORY_TITLE')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.BILLING_TITLE')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.ORIGIN_TITLE')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.CONTRACT')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.INTERNAL_FOLIO_INVOICE')}
      </TableCell>
      <TableCell style={{ width: '10%' }} align="center">
        {t('TRANSACTION.COMMENT')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.CHARGE')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('TRANSACTION.PAYMENT')}
      </TableCell>
      <TableCell style={{ width: headColumnWith }} align="center">
        {t('COMMON.ACTION')}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody transactions={transactions} />, [
    transactions
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, transactions]
  );

  if (!loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.TRANSACTION_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.TRANSACTION_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AccountStatementTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

AccountStatementTable.defaultProps = {
  count: 0
};

export default AccountStatementTable;
