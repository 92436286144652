import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NufiParameter } from 'src/utils/constants/parameters';
import validationSchema, {
  validateJudicialRecord,
  validateNufiBlock,
  validateParameterSumForIneScore
} from './schema';
import makeInitialState from './initialState';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditNufiParameterDialog = ({
  open,
  handleClose,
  handleEdit,
  parameterList,
  validateTheJudicialRecords = false
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(parameterList), [
    parameterList
  ]);
  const schemas = validationSchema(parameterList);
  const { t } = useTranslation();
  const handleOuterSubmit = useMemo(() => handleEdit, [handleEdit]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="parameter-update-form"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="parameter-update-form">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            {t('PARAMETERS_VIEW.NUFI_PARAMETER.UPDATE_BLOCK_VALUE')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            height: 'auto',
            maxHeight: 'calc(100vh - 200px)'
          }}
        >
          <Formik
            initialValues={initialState}
            validationSchema={schemas}
            onSubmit={handleOuterSubmit}
            validate={values => {
              const params = Object.keys(values);
              if (validateTheJudicialRecords) {
                return validateJudicialRecord(values);
              }
              if (
                params.includes(
                  NufiParameter.VALIDATE_IDENTIFICATION.VALIDATE_INE_SCORE
                ) &&
                params.includes(
                  NufiParameter.VALIDATE_IDENTIFICATION.NOT_VALIDATED_INE_SCORE
                )
              ) {
                return validateParameterSumForIneScore(values);
              }
              return validateNufiBlock(values);
            }}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  {parameterList.length &&
                    parameterList.map(parameter => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        error={Boolean(
                          touched[parameter.name] && t(errors[parameter.name])
                        )}
                        fullWidth
                        helperText={
                          touched[parameter.name] && t(errors[parameter.name])
                        }
                        label={t(`PARAMETER_VIEW.${parameter.name}`)}
                        margin="normal"
                        name={parameter.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values[parameter.name]}
                        variant="outlined"
                        key={`parameter-${parameter.name}`}
                      />
                    ))}

                  {errors.parameterSum && (
                    <Typography color="error" align="center" variant="body2">
                      {t(errors.parameterSum)}
                    </Typography>
                  )}

                  <Box
                    className={classes.box}
                    display="flex"
                    justifyContent="flex-end"
                    component="div"
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={Boolean(errors.sumaDeParametros)}
                    >
                      {t('PARAMETERS_VIEW.UPDATE')}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditNufiParameterDialog.propTypes = {
  open: PropTypes.bool,
  handleEdit: PropTypes.func,
  parameterList: PropTypes.array,
  handleClose: PropTypes.func,
  validateTheJudicialRecords: PropTypes.bool
};

export default EditNufiParameterDialog;
