import React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';
import useIsLargeScreen from 'src/hooks/useIsLargeScreen';
import { Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import { UserRoles } from 'src/utils/constants/auth';
import Dialog from './Dialog/Dialog';

const RedirectToMobile = () => {
  const isLargeScreen = useIsLargeScreen();
  const { pathname } = window.location;
  const systemRole = useSelector(selectAuthSystem);
  const { t } = useTranslation();

  // TODO: Uncomment when is approved by Testing
  // const env = process.env.NODE_ENV;

  const mobileAllowedRoutes = [
    '/recover-password',
    '/assign-password',
    '/forgot-password',
    '/admin/login',
    '/user/verification',
    '/buro-validation-form'
  ];

  const mobileAllowedIncludesPath = mobileAllowedRoutes.some(route =>
    pathname.includes(route)
  );

  const redirect = () => {
    setTimeout(() => {
      const environment = process.env.NODE_ENV;
      if (environment === 'production') {
        window.location.replace('https://distributor-prod.astrocap.com/');
      }
      if (environment === 'development') {
        window.location.replace('https://distributor-staging.astrocap.com/');
      }
      return window.location.replace(
        'https://distributor-staging.astrocap.com/'
      );
    }, 5000);
  };

  const doNotRedirect =
    isLargeScreen ||
    mobileAllowedIncludesPath ||
    (systemRole && systemRole !== UserRoles.DISTRIBUTOR);
  // || env !== 'production'

  if (doNotRedirect) {
    return null;
  }

  return (
    <Dialog open widthCloseIcon={false}>
      <DialogContent>
        <Box display="flex" alignItems="center" mb={2} gap={2}>
          <Info color="primary" fontSize="large" />
          <Typography variant="body1" align="left" alignSelf="center">
            {`${t('REDIRECT_TO_MOBILE_DIALOG.TEXT')}`}
          </Typography>
          {redirect()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectToMobile;
