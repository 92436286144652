import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import { addPointsPhone } from 'src/utils/common';
import { formatAmount, formatPositiveAmount } from 'src/utils/formatAmout';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const system = useSelector(selectAuthSystem);
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CLIENTS_VIEW.CREATION_FORM.CLIENT')}
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.NAME')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.fullName}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.PHONE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.phone ? addPointsPhone(values.phone) : '-'}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EMAIL')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.email || '-'}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.COMMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.comment || '-'}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CLIENTS_VIEW.CREATION_FORM.EQUIPMENT')}
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EQUIPMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${values.distributorEquipment.brand.name} - ${values.distributorEquipment.model}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INITIAL_PAYMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`$${formatPositiveAmount(values.initialPayment)}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.PRICE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`$${formatAmount(values.price)}`}
            </Typography>
          </TableCell>
        </TableRow>

        {system === UserType.ADMIN && (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('COMMON.DISTRIBUTOR')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.distributor.name}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.KAM')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.distributorProfile.name || '-'}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.DAYS_FOR_FIRST_RENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${translateGracePeriodValueToDaysForFirstRent(
                values.gracePeriod,
                t
              )}` || t('COMMON.NO_DATA')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentInsuranceIncluded
                ? t('COMMON.YES')
                : t('COMMON.NO')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
