import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(60)
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '100%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11
    }
  }
}));
