import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CONTRACT_AVAL_LIST } from 'src/store/actions/contractActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import AvalRow from './AvalRow';

const Tbody = ({ avals }) =>
  avals.map(endorsement => <AvalRow key={endorsement.id} {...endorsement} />);

const AvalTable = ({ className, avals, ...rest }) => {
  const { t } = useTranslation();

  const loading = useSelector(
    isLoadingSelector([LOAD_CONTRACT_AVAL_LIST.action])
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" align="left">
          {t('COMMON.NAME')}
        </TableCell>
        <TableCell variant="head" align="center">
          {t('COMMON.EMAIL')}
        </TableCell>
        <TableCell variant="head" align="center">
          {t('COMMON.PHONE')}
        </TableCell>
        <TableCell variant="head" align="center">
          {t('COMMON.LEGAL_REPRESENTATIVE')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(() => () => <Tbody avals={avals} />, [avals, t]);

  if (!avals.length) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.AVALS_NOT_FOUND_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.AVALS_NOT_FOUND_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

AvalTable.propTypes = {
  className: PropTypes.string,
  avals: PropTypes.array.isRequired
};

export default AvalTable;
