export const MXState = {
  BCN: 'Baja California',
  AGS: 'Aguascalientes',
  BCS: 'Baja California Sur',
  CAM: 'Campeche',
  CDMX: 'Ciudad de México',
  CHS: 'Chiapas',
  CHI: 'Chihuahua',
  COA: 'Coahuila',
  COL: 'Colima',
  DGO: 'Durango',
  EM: 'Estado de México',
  GTO: 'Guanajuato',
  GRO: 'Guerrero',
  HGO: 'Hidalgo',
  JAL: 'Jalisco',
  MICH: 'Michoacán',
  MOR: 'Morelos',
  NAY: 'Nayarit',
  NL: 'Nuevo León',
  OAX: 'Oaxaca',
  PUE: 'Puebla',
  QRO: 'Querétaro',
  QR: 'Quintana Roo',
  SLP: 'San Luis Potosí',
  SIN: 'Sinaloa',
  SON: 'Sonora',
  TAB: 'Tabasco',
  TAM: 'Tamaulipas',
  TLAX: 'Tlaxcala',
  VER: 'Veracruz',
  YUC: 'Yucatán',
  ZAC: 'Zacatecas'
};

export const MxStateOptions = Object.entries(MXState).map(([key, value]) => ({
  name: value,
  key
}));
