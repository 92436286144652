import momentLocalTimezone from 'src/utils/momentLocalTimezone';

const makeInitialState = values => ({
  ...values,
  startDate: values.startDate ? momentLocalTimezone(values.startDate) : momentLocalTimezone(),
  firstRentDate: values.firstRentDate ? momentLocalTimezone(values.firstRentDate) : momentLocalTimezone().add(1, 'month'),
  gracePeriod: values.gracePeriod,
  endDate: values.endDate ? momentLocalTimezone(values.endDate) : momentLocalTimezone().add(values.term, 'months')
});

export default makeInitialState;
