import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DistributorStatsInfo = ({ distributor }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.KAMS_AND_DIRECTORS')}
        value={distributor.kamsAndDirectors || 0}
      />

      <DetailRow
        name={t('DISTRIBUTORS_VIEW.DETAIL.QUOTES_IN_DRAFT')}
        value={distributor.quotesInDraft || 0}
      />

      <DetailRow
        name={t('DISTRIBUTORS_VIEW.DETAIL.QUOTES_IN_REVIEW')}
        value={distributor.quotesInReview || 0}
      />

      <DetailRow
        name={t('DISTRIBUTORS_VIEW.DETAIL.QUOTES_ACCEPTED')}
        value={distributor.quotesAccepted || 0}
      />
    </>
  );
};

DistributorStatsInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};
export default DistributorStatsInfo;
