import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';

const TabSubMenu = ({ anchorEl, open, handleClose, handleChange, list }) => {
  const { t } = useTranslation();
  return (
    <Menu
      id="tab-sub-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      PaperProps={{
        sx: {
          width: 200,
          maxWidth: '100%'
        }
      }}
    >
      {list &&
        list.map((item, index) => {
          return (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-MenuItem`}
              onClick={e => handleChange(e, item.value)}
              sx={{
                margin: '8px 8px',
                borderBottom: '1px solid rgba(43,45,66,0.1)'
              }}
            >
              <Typography
                color="textPrimary"
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: '1.43',
                  letterSpacing: '0.01071em',
                  textTransform: 'none',
                  color: theme.palette.primary.main
                }}
              >
                {t(item.label)}
              </Typography>
            </MenuItem>
          );
        })}
    </Menu>
  );
};

TabSubMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  list: PropTypes.array.isRequired
};

export default TabSubMenu;
