import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import LineOfBusinessRow from './LineOfBusinessRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ linesOfBusiness }) =>
  linesOfBusiness.map(lineOfBusiness => <LineOfBusinessRow key={lineOfBusiness.id} {...lineOfBusiness} />);

const LineOfBusinessTable = ({ linesOfBusiness, loading, success, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell sx={{ width: '90%' }} variant="head" key="name">
        {t('COMMON.NAME')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" key="options">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody linesOfBusiness={linesOfBusiness} />, [linesOfBusiness]);

  if (success && !loading && !linesOfBusiness.length) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.MODEL_TITLE" />
      </Card>
    );
  }

  return <Table {...rest} tbody={tbody} theads={theads} loading={loading} />;
};

LineOfBusinessTable.propTypes = {
  linesOfBusiness: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default LineOfBusinessTable;
