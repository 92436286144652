import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

const getUserProfile = serviceBase({
  request: () => axiosClient.get('/distributor-profile'),
  retry: 0
});

export const updateUserInfo = serviceBase({
  request: values => axiosClient.patch('/distributor-profile', values),
  retry: 0
});

export default getUserProfile;
