import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stepperLabels = [
  'CONTRACT_VIEW.CONTRACT_DETAIL.CREATE_CONTRIBUTION',
  'COMMON.CONFIRMATION'
];

const ContributionsForm = ({
  open,
  handleClose,
  handleCreateContribution,
  currentRent
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState(currentRent);
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);

  const resetRef = useRef();

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleCloseDialog = () => {
    handleClose();
    setData(initialState);
  };

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      handleClose();
      setData(initialState);
    } else {
      setStep(step - 1);
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit = useCallback(
    value => {
      handleCreateContribution({ ...data, ...value });
      setData(initialState);
    },
    [data]
  );

  useEffect(() => {
    if (open) {
      setData(initialState);
    }
  }, [open]);

  return (
    <Dialog
      id="form-new-contribution"
      classes={{ paper: classes.root }}
      open={open}
      scroll="body"
      TransitionComponent={Transition}
      keepMounted
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.title} id="form-new-contribution-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleCloseDialog();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title} color="primary" variant="h2">
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.CREATE_CONTRIBUTION')}
        </Typography>
      </DialogTitle>
      <Divider light variant="middle" />
      <DialogContent
        sx={{
          height: 'auto',
          maxHeight: 'calc(100vh - 200px)'
        }}
      >
        <FormStepper index={step} labels={stepperLabels} />
        <CurrentStep
          activeStep={step}
          data={data}
          resetRef={resetRef}
          onNext={handleNext}
          onBack={handleBackOrClose}
          onSubmit={handleSubmit}
        />
      </DialogContent>
      <Typography fontSize="0.8em" color="primary" mb={2} align="center">
        {t('CONTRACT_VIEW.CREATE_APORTATION_DISCLAIMER')}
      </Typography>
    </Dialog>
  );
};

ContributionsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateContribution: PropTypes.func.isRequired,
  currentRent: PropTypes.number.isRequired
};

export default ContributionsForm;
