import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Preloader from 'src/components/Preloader';
import {
  LOAD_AGENT_DETAIL,
  loadAgentDetail
} from 'src/store/actions/agentActions';
import { selectAgentDetail } from 'src/store/selectors/agentSelectors';
import GeneralDetail from './GeneralDetail';
import InvestorsList from './InvestorsList/InvestorsList';

const GeneralView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const agent = useSelector(selectAgentDetail);
  const loading = useSelector(isLoadingSelector([LOAD_AGENT_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_AGENT_DETAIL.action]));

  useEffect(() => {
    dispatch(loadAgentDetail(id));
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        {success && !loading ? (
          <>
            <Grid item xs={4}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  style={{ fontWeight: '400', textTransform: 'capitalize' }}
                  color="primary"
                  variant="h1"
                >
                  {t('COMMON.GENERAL')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  style={{ fontWeight: '400', textTransform: 'capitalize' }}
                  color="primary"
                  variant="h1"
                >
                  {t('NAVIGATION.INVESTORS')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <GeneralDetail agent={agent} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <InvestorsList />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GeneralView;
