export const selectEquipment = state => state.equipments;
export const selectEquipmentList = state => selectEquipment(state).items;
export const selectEquipmentDetail = state => selectEquipment(state).detail;
export const selectEquipmentListCount = state => selectEquipment(state).count;
export const selectEquipmentQueryOptions = state => {
  const { options } = selectEquipment(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
