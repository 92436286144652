import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { AgentDetailTabsIndex } from 'src/utils/investors';
import GeneralView from './General';

const GeneralMenuList = [
  {
    value: AgentDetailTabsIndex.GENERAL,
    label: 'COMMON.GENERAL'
  }
];

const AgentTabs = () => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const { id, tab } = useParams();

  const [value, setValue] = useState(false);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/investors/agents/detail/${id}/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !tab) {
      setValue(AgentDetailTabsIndex.GENERAL);
    }
    if (tab) {
      if (Object.values(AgentDetailTabsIndex).includes(tab)) {
        setValue(tab);
      } else {
        push(`/investors/agents/detail/${id}`);
      }
    }
  }, [id, tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb doNotRedirectIndexList={[2]} />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {GeneralMenuList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              disabled={item.disabled}
            />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={AgentDetailTabsIndex.GENERAL}>
        <GeneralView />
      </TabPanel>
    </Box>
  );
};

export default AgentTabs;
