/* eslint-disable react/prop-types */
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import React from 'react';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import ParameterListView from 'src/views/parameter/ParameterListView';
import BuroParamatersTabs from './ParameterListView/BuroParameter/BuroParamatersTabs';

const ParameterView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={ParameterListView} />
      <Route
        exact
        path={`${match.url}/parameters/:subtab`}
        component={BuroParamatersTabs}
      />
    </>
  );
};

export default ParameterView;
