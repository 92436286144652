import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addPointsPhone } from 'src/utils/common';
import { formatPositiveAmount } from 'src/utils/formatAmout';

const GeneralAgentInfo = ({ agent }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow name={t('COMMON.NAME')} value={agent.fullName} />

      <DetailRow
        name={t('COMMON.EMAIL')}
        value={agent.email ? agent.email : t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('COMMON.PHONE')}
        value={agent.phone ? addPointsPhone(agent.phone) : t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('AGENTS.PARTICIPATION_AMOUNT')}
        value={formatPositiveAmount(agent.participationAmount)}
      />
      <DetailRow
        name={t('AGENTS.COMISSION')}
        value={agent.comission ? `${agent.comission}%` : t('COMMON.NO_DATA')}
      />
    </>
  );
};

GeneralAgentInfo.propTypes = {
  agent: PropTypes.object.isRequired
};
export default GeneralAgentInfo;
