import * as Yup from 'yup';

export default Yup.object().shape({
  startDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  firstRentDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(
      Yup.ref('startDate'),
      'SCHEMA_ERRORS.FIRST_RENT_DATE_CANNOT_BE_BEFORE_START_DATE'
    ),
  gracePeriod: Yup.number().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  endDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(new Date(), 'SCHEMA_ERRORS.END_DATE_CANNOT_BE_BEFORE_CURRENT_DATE'),
    payday: Yup.number()
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(1, 'SCHEMA_ERRORS.PAYDAY_MIN')
    .max(25, 'SCHEMA_ERRORS.PAYDAY_MAX')
});
