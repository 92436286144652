import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFile from 'src/components/UploadFile';
import clsx from 'clsx';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';

const StepTwo = ({ data, handleBack, handleNext, resetRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  const handleUploadChange = useCallback(
    (setFieldValue, name) => e => {
      if (e.target.files[0]) {
        setFieldValue(name, e.target.files[0]);
      }
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleChange,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <UploadFile
                values={values}
                errors={errors}
                touched={touched}
                handleUploadChange={handleUploadChange}
                setFieldValue={setFieldValue}
                accept={{ 'application/pdf': ['.pdf'] }}
                valueLabel="invoicePdfUrl"
                textLabel={t('COMMON.UPLOAD_PDF_INVOICE')}
              />
              <UploadFile
                values={values}
                errors={errors}
                touched={touched}
                handleUploadChange={handleUploadChange}
                setFieldValue={setFieldValue}
                accept={{ 'text/xml': ['.xml'] }}
                valueLabel="invoiceXmlUrl"
                textLabel={t('COMMON.UPLOAD_XML_INVOICE')}
              />
            </Box>

            <TextField
              InputProps={{
                autoComplete: 'off'
              }}
              error={Boolean(touched.serialNumber && errors.serialNumber)}
              fullWidth
              helperText={touched.serialNumber && t(errors.serialNumber)}
              label={t('EQUIPMENT_VIEW.SERIAL_NUMBER')}
              margin="normal"
              name="serialNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.serialNumber}
              variant="outlined"
            />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired
};
export default StepTwo;
