import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import KamList from './KamList';

const KamListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.indexRoot} title={t('KAMS_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <KamList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default KamListView;
