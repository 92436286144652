import React from 'react';

const AstrocapLogo = () => {
  const width = '80%';
  const height = '80%';
  const viewBox = '0 0 260 43';
  const fill = 'white';
  const xmlns = 'http://www.w3.org/2000/svg';

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
    >
      <path d="M33.4769 0H22.6879L0 31.5309L10.8069 31.5077L33.4769 0Z" />
      <path d="M40.8441 10.7623H35.0545L12.3666 42.2932L18.1792 42.2701L40.8441 10.7623Z" />
      <path d="M43.1829 18.5293L33.8439 31.5102H36.529L45.868 18.5293H43.1829Z" />
      <path d="M60.6796 11.4111H63.3008L68.8372 24.5027H65.8657L64.6843 21.5894H59.222L58.0405 24.5027H55.1458L60.6796 11.4111ZM63.6691 19.0622L61.9531 14.8468L60.2372 19.0622H63.6691Z" />
      <path d="M74.9441 22.6213L76.6242 20.5985C77.6777 21.5764 79.0478 22.1369 80.4805 22.1761C81.6441 22.1761 82.3448 21.7128 82.3448 20.9511V20.9125C82.3448 20.1893 81.9024 19.8187 79.7415 19.2603C77.1408 18.5937 75.4607 17.868 75.4607 15.2867V15.2507C75.4769 14.6859 75.6099 14.1307 75.8513 13.6205C76.0926 13.1103 76.437 12.6563 76.8624 12.2876C77.2878 11.9188 77.7849 11.6433 78.322 11.4786C78.8591 11.3139 79.4244 11.2637 79.9819 11.3312C81.7234 11.3031 83.42 11.8874 84.7793 12.9834L83.3037 15.1375C82.3395 14.3769 81.1688 13.9282 79.9461 13.8507C78.8567 13.8507 78.2839 14.3525 78.2839 14.9831V15.0139C78.2839 15.8683 78.8388 16.1463 81.0713 16.7228C83.6924 17.4099 85.1629 18.357 85.1629 20.6217V20.6757C85.1629 23.2492 83.2066 24.7059 80.4192 24.7059C78.4016 24.7117 76.4525 23.9697 74.9441 22.6213Z" />
      <path d="M95.5696 14.1547H91.6289V11.5168H102.334V14.1547H98.4031V24.5157H95.5696V14.1547Z" />
      <path d="M109.694 11.5161H115.601C116.284 11.4729 116.969 11.5672 117.615 11.7934C118.262 12.0196 118.857 12.3733 119.366 12.8337C119.738 13.23 120.027 13.697 120.217 14.2075C120.407 14.7179 120.494 15.2614 120.473 15.8061V15.8422C120.514 16.7266 120.265 17.6001 119.765 18.3283C119.265 19.0565 118.54 19.599 117.703 19.8723L120.859 24.5047H117.535L114.768 20.3458H112.535V24.5047H109.692L109.694 11.5161ZM115.415 17.8315C116.801 17.8315 117.593 17.0878 117.593 15.9914V15.9554C117.593 14.7278 116.744 14.0973 115.361 14.0973H112.548V17.8315H115.415Z" />
      <path d="M127.653 18.053V18.0169C127.707 16.2243 128.452 14.5231 129.729 13.2727C131.007 12.0222 132.717 11.3203 134.499 11.3153C136.281 11.3103 137.995 12.0025 139.28 13.2458C140.564 14.4891 141.318 16.186 141.383 17.9783V18.0169C141.328 19.8095 140.583 21.5105 139.305 22.7608C138.028 24.011 136.317 24.7125 134.535 24.7171C132.753 24.7218 131.039 24.0293 129.755 22.7858C128.47 21.5423 127.717 19.8452 127.653 18.053ZM138.414 18.053V18.0169C138.432 17.4879 138.344 16.9606 138.156 16.4663C137.967 15.972 137.682 15.5208 137.317 15.1396C136.952 14.7584 136.515 14.4549 136.031 14.2471C135.547 14.0394 135.027 13.9316 134.501 13.9302C133.979 13.928 133.462 14.0323 132.981 14.2369C132.5 14.4414 132.065 14.7419 131.703 15.1203C131.34 15.4986 131.058 15.9469 130.873 16.4382C130.687 16.9294 130.603 17.4533 130.624 17.9783V18.0169C130.606 18.5459 130.694 19.0731 130.882 19.5672C131.07 20.0614 131.356 20.5123 131.721 20.8933C132.086 21.2744 132.523 21.5776 133.007 21.7851C133.491 21.9926 134.011 22.1001 134.537 22.1011C135.059 22.1033 135.576 21.999 136.057 21.7944C136.538 21.5899 136.973 21.2894 137.335 20.911C137.697 20.5327 137.98 20.0844 138.165 19.5931C138.351 19.1019 138.435 18.578 138.414 18.053Z" />
      <path d="M148.803 18.0532V18.0172C148.78 17.1405 148.932 16.2681 149.25 15.4516C149.568 14.6351 150.045 13.8912 150.655 13.264C151.264 12.6369 151.992 12.1392 152.796 11.8006C153.599 11.4619 154.463 11.2892 155.334 11.2926C156.27 11.2633 157.202 11.4313 158.069 11.7859C158.937 12.1405 159.721 12.6737 160.372 13.3514L159.377 14.4271C158.855 13.8687 158.227 13.4227 157.529 13.1161C156.83 12.8096 156.078 12.6488 155.316 12.6437C154.637 12.6516 153.966 12.7971 153.343 13.0715C152.721 13.346 152.159 13.7437 151.693 14.2411C151.226 14.7384 150.864 15.3252 150.628 15.9664C150.391 16.6077 150.286 17.2902 150.317 17.9735V18.0121C150.285 18.6972 150.39 19.3818 150.626 20.0254C150.861 20.6689 151.223 21.2583 151.689 21.7587C152.155 22.259 152.717 22.6601 153.34 22.9383C153.963 23.2164 154.635 23.3659 155.316 23.3779C156.103 23.3774 156.882 23.2101 157.6 22.8868C158.319 22.5636 158.962 22.0917 159.487 21.5018L160.446 22.4488C159.801 23.1837 159.005 23.7687 158.113 24.1633C157.221 24.5579 156.255 24.7526 155.28 24.7341C154.415 24.7394 153.557 24.5689 152.758 24.233C151.959 23.897 151.236 23.4025 150.631 22.7788C150.026 22.1552 149.553 21.4153 149.239 20.6034C148.925 19.7915 148.776 18.9241 148.803 18.0532Z" />
      <path d="M172.96 11.4111H174.328L180.209 24.5027H178.642L177.128 21.067H170.098L168.564 24.5027H167.07L172.96 11.4111ZM176.56 19.7494L173.625 13.12L170.673 19.7494H176.56Z" />
      <path d="M187.948 11.5168H192.781C195.699 11.5168 197.617 13.0763 197.617 15.6344V15.6705C197.617 18.4756 195.292 19.9245 192.543 19.9245H189.405V24.5105H187.948V11.5168ZM192.597 18.594C194.737 18.594 196.141 17.4411 196.141 15.7322V15.6962C196.141 13.8381 194.758 12.8653 192.671 12.8653H189.405V18.5863L192.597 18.594Z" />
      <path d="M205.589 11.5168H207.046V24.5157H205.589V11.5168Z" />
      <path d="M219.43 12.873H215.083V11.5168H225.23V12.873H220.883V24.5157H219.405L219.43 12.873Z" />
      <path d="M236.279 11.4111H237.645L243.526 24.5027H241.959L240.445 21.067H233.415L231.88 24.5027H230.385L236.279 11.4111ZM239.877 19.7494L236.944 13.12L233.99 19.7494H239.877Z" />
      <path d="M251.267 11.5168H252.725V23.162H260V24.5157H251.272L251.267 11.5168Z" />
    </svg>
  );
};

export default AstrocapLogo;
