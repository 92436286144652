import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterList from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  LOAD_DISTRIBUTOR_AGENTS,
  createDistributorAgent,
  loadDistributorAgents
} from 'src/store/actions/distributorActions';
import { AgentRoles, AgentStatus } from 'src/utils/constants/distributors';
import clsx from 'clsx';
import useDebounce from 'src/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import {
  selectDistributorAgents,
  selectDistributorAgentsCount,
  selectDistributorQueryOptions
} from 'src/store/selectors/distributorSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import Filter from 'src/components/Filter/Filter';
import useStyles from './styles';
import AgentDialog from './AgentDialog';
import AgentTable from './AgentTable';

const filters = [
  {
    title: 'COMMON.ROLE',
    controlGroup: 'checkbox',
    items: [
      {
        label: 'COMMON.DIRECTOR',
        value: AgentRoles.DIRECTOR
      },
      {
        label: 'KAM',
        value: AgentRoles.KAM
      }
    ]
  },
  {
    title: 'COMMON.STATUS',
    controlGroup: 'checkbox',
    items: [
      {
        label: 'ENUMS.STATUS.ACTIVE',
        value: AgentStatus.ACTIVE
      },
      {
        label: 'ENUMS.STATUS.INACTIVE',
        value: AgentStatus.INACTIVE
      }
    ]
  }
];

function getFilterOptions(options) {
  const role = [];
  const status = [];

  if (options[AgentRoles.DIRECTOR]) {
    role.push(AgentRoles.DIRECTOR);
  }
  if (options[AgentRoles.KAM]) {
    role.push(AgentRoles.KAM);
  }

  if (options[AgentStatus.ACTIVE]) {
    status.push(AgentStatus.ACTIVE);
  }
  if (options[AgentStatus.INACTIVE]) {
    status.push(AgentStatus.INACTIVE);
  }

  return { role, status };
}

const AgentDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const agents = useSelector(selectDistributorAgents);
  const count = useSelector(selectDistributorAgentsCount);
  const queryOptions = useSelector(selectDistributorQueryOptions);
  const { page } = useSelector(selectDistributorQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );
  const success = useSelector(
    successSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  const debounceFilter = useDebounce(
    queryOpts => dispatch(loadDistributorAgents(id, { ...queryOpts })),
    400
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadDistributorAgents(id, { page: value }));
    },
    [dispatch]
  );

  const handleOpenAgentForm = () => {
    setOpen(true);
  };

  const handleCloseAgentForm = () => {
    setOpen(false);
  };

  const handleCreateDistributorAgent = useCallback(
    values => {
      dispatch(createDistributorAgent(id, values));
    },
    [dispatch, handleCloseAgentForm]
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [setFilterValues, debounceFilter, queryOptions, getFilterOptions]
  );

  useEffect(() => {
    dispatch(loadDistributorAgents(id));
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item lg={12} className={classes.actionsContainer}>
          <Button
            className={clsx(
              classes.buttons,
              classes.outlined,
              classes.horizontalPadding
            )}
            sx={{ marginRight: 2 }}
            color="primary"
            onClick={handleClickFilter}
          >
            <FilterList className={classes.icon} />
            {t('COMMON.FILTER')}
          </Button>
          <Button
            className={classes.buttons}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenAgentForm}
          >
            {t('DISTRIBUTORS_VIEW.AGENTS.CREATE_KAM')}
          </Button>
          <Filter
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleChange={handleFilterChange}
            filters={filters}
          />
          <AgentDialog
            open={open}
            handleClose={handleCloseAgentForm}
            onSubmit={handleCreateDistributorAgent}
          />
        </Grid>
        <Grid item xs={12}>
          <AgentTable
            agents={agents}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentDetailView;
