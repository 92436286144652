import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  box: {
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(2)}px 0`
  },
  buttons: {
    width: '45%',
    borderWidth: '2px'
  },
  buttonMain: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  tableRoot: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      whiteSpace: 'nowrap'
    }
  },
  tableBody: {
    width: '100%',
    display: 'table'
  },
  tableBox: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  reviewDataLabel: {
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.primary.darkm
  },

  reviewDataSubtitle: {
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark
  },
  reviewBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start'
    },
    alignItems: 'center',
    marginBottom: '1rem',
    gap: '0.5rem',
    width: '100%'
  }
}));
