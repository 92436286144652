/* eslint-disable import/prefer-default-export */
import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getBackgroundValidations = serviceBase({
  request: ({ options }) =>
    axiosClient.get(`/nufi-validation-score`, {
      params: options
    })
});
