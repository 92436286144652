import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import { useSelector } from 'react-redux';
import { selectCFDISQueryOptions } from 'src/store/selectors/cfdiSelectors';

const CFDIIncomeRow = ({
  rent,
  surchage,
  initialPayment,
  capitalContribution,
  residual,
  other,
  period,
  total
}) => {
  const { period: periodType } = useSelector(selectCFDISQueryOptions);

  return (
    <TableRow hover align="center">
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        align="center"
      >
        {selectFormatDate(
          period,
          periodType === dateFormatType.YEAR
            ? dateFormatType.YEAR
            : dateFormatType.MONTH
        )}
      </TableCell>
      <TableCell align="center">{formatAmountWithDecimals(rent, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(initialPayment, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(capitalContribution, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(residual, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(surchage, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(other, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(total, 2)}</TableCell>
    </TableRow>
  );
};

CFDIIncomeRow.propTypes = {
  rent: PropTypes.number,
  surchage: PropTypes.number,
  initialPayment: PropTypes.number,
  capitalContribution: PropTypes.number,
  residual: PropTypes.number,
  other: PropTypes.number,
  period: PropTypes.string,
  total: PropTypes.number
};

export default CFDIIncomeRow;
