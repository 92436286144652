import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import Loading from 'src/components/Loading';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles from '../views/client/ClientDetail/NuffiDetail/styles';

const LoadingValidationDialog = ({ openDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} className={classes.dialogContainer}>
      <DialogContent className={classes.dialogContent}>
        <Loading
          label={t(
            'CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.LOADING_VALIDATE_MESSAGE_TITLE'
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

LoadingValidationDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired
};

export default LoadingValidationDialog;
