import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

// eslint-disable-next-line import/prefer-default-export
export const createDistributorContractRequest = serviceBase({
  request: values =>
    axiosClient.post(
      `/distributor-profile/quotes/send-to-contract/${values.id}`,
      values
    )
});
