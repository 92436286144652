import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import {
  editInvestor,
  loadInvestorKYCDetail
} from 'src/store/actions/investorActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InfoIcon from '@mui/icons-material/Info';
import DetailCard from 'src/components/DetailBox/DetailCard';
import DetailRow from 'src/components/DetailRow';
import { partnerOrShareholderId } from 'src/utils/constants/common';
import useStyles from '../../styles';
import EconomicActivitiesDialog from './EconomicActivitiesDialog';

const EconomicActivities = ({ investor }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    values => {
      const formattedValues = values.economicActivities.map(activity => {
        delete activity.name;
        return activity;
      });
      dispatch(
        editInvestor(
          id,
          { economicActivities: formattedValues },
          () => setOpen(false),
          loadInvestorKYCDetail
        )
      );
    },
    [investor, id]
  );

  return (
    <Card variant="elevation">
      <DetailCard
        title={t('INVESTORS_VIEW.INVESTOR_DETAIL.ECONOMIC_ACTIVITIES')}
      >
        {investor.economicActivities && !investor.economicActivities.length ? (
          <Box sx={{ width: '100%', padding: '20px', textAlign: 'center' }}>
            <InfoIcon fontSize="large" sx={{ marginBottom: '15px' }} />
            <Typography variant="h4">
              {t('NOT_FOUND_RECORDS_MESSAGE.ECONOMIC_ACTIVITIES_TITLE')}
            </Typography>
          </Box>
        ) : (
          investor.economicActivities?.map(activity => {
            return (
              <DetailRow
                key={activity.id}
                name={activity.name}
                value={`${activity.percentage}%`}
              />
            );
          })
        )}
        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleOpenDialog}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </DetailCard>
      <EconomicActivitiesDialog
        open={open}
        handleClose={handleCloseDialog}
        submit={handleSubmit}
        investor={investor}
        disabledCondition={economicActivity =>
          economicActivity.id === partnerOrShareholderId
        }
      />
    </Card>
  );
};

EconomicActivities.propTypes = {
  investor: PropTypes.object.isRequired
};

export default EconomicActivities;
