import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DownloadButton from 'src/components/DownloadButton';
import SearchBar from 'src/components/SearchBar';
import useDebounce from 'src/hooks/useDebounce';
import { selectInvestmentsQueryOptions } from 'src/store/selectors/investmentSelector';
import {
  createInvestmentAction,
  downloadInvestmentList,
  loadInvestmentList
} from 'src/store/actions/investmentActions';
import { Add } from '@mui/icons-material';
import useStyles from './styles';
import CreateInvestmentForm from './CreateInvestmentForm';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const queryOptions = useSelector(selectInvestmentsQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const debounceSearch = useDebounce(
    (investorName, queryOpts) =>
      dispatch(loadInvestmentList({ ...queryOpts, investorName, page: 0 })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleDownloadInvestmentList = () => {
    dispatch(downloadInvestmentList());
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(createInvestmentAction(values, handleClose));
    },
    [dispatch, queryOptions]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.INVESTMENTS')}
          </Typography>
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('COMMON.SEACRH_BY_INVESTOR')}
            />
            <Box sx={{ display: 'flex', marginRight: 2, marginLeft: 2 }}>
              <DownloadButton
                onDownload={handleDownloadInvestmentList}
                title="COMMON.DOWNLOAD_INVESTMENTS"
              />
            </Box>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleOpen}
              color="secondary"
            >
              <Add className={classes.icon} />
            </Button>
          </Box>
          <CreateInvestmentForm
            open={open}
            handleClose={handleClose}
            submit={handleSubmit}
          />
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
