import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { loadBillingConceptsList } from 'src/store/actions/catalogActions';
import selectBillingList from 'src/utils/selectBillingList';
import ParameterRow from '../../common/ParameterRow';

const BillingParameterTable = ({
  className,
  parameters,
  handleOpenEdit,
  isDisabledEdit,
  ...rest
}) => {
  const { t } = useTranslation();
  const billingConcepts = useSelector(selectCatalogList);
  const dispatch = useDispatch();

  const openEdit = (parameter, list) => () => {
    handleOpenEdit(
      parameter,
      selectBillingList({
        parameter,
        list,
        t
      })
    );
  };

  useEffect(() => {
    dispatch(loadBillingConceptsList());
  }, [parameters]);

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('PARAMETERS_VIEW.NAME')}</TableCell>
      <TableCell align="center">{t('PARAMETERS_VIEW.VALUE')}</TableCell>
      <TableCell style={{ width: '1rem' }}>{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <ParameterRow
          {...parameter}
          isDisabledEdit={isDisabledEdit}
          key={parameter.id}
          openEdit={openEdit(parameter, billingConcepts)}
        />
      ));
    },
    [parameters, openEdit]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

BillingParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  isDisabledEdit: PropTypes.bool
};

export default BillingParameterTable;
