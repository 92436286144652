import React, { useCallback, useEffect, useState } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Filter from 'src/components/Filter/Filter';
import useDebounce from 'src/hooks/useDebounce';
import {
  CLEAR_KAM_OPTIONS,
  createKam,
  downloadExcelKamList,
  loadKams
} from 'src/store/actions/userKamActions';
import { selectKamsQueryOptions } from 'src/store/selectors/kamSelectors';
import { AgentRoles, AgentStatus } from 'src/utils/constants/distributors';
import SearchBar from 'src/components/SearchBar';
import AddButton from 'src/components/AddButton/AddButton';
import DownloadButton from 'src/components/DownloadButton';
import useStyles from './styles';
import KamDialog from './KamsDialog';

const filters = [
  {
    title: 'COMMON.STATUS',
    controlGroup: 'checkbox',
    items: [
      {
        label: 'ENUMS.STATUS.ACTIVE',
        value: AgentStatus.ACTIVE
      },
      {
        label: 'ENUMS.STATUS.INACTIVE',
        value: AgentStatus.INACTIVE
      }
    ]
  }
];

function getFilterOptions(options) {
  const role = [];
  const status = [];

  if (options[AgentRoles.DIRECTOR]) {
    role.push(AgentRoles.DIRECTOR);
  }
  if (options[AgentRoles.KAM]) {
    role.push(AgentRoles.KAM);
  }

  if (options[AgentStatus.ACTIVE]) {
    status.push(AgentStatus.ACTIVE);
  }
  if (options[AgentStatus.INACTIVE]) {
    status.push(AgentStatus.INACTIVE);
  }

  return { role, status };
}

const KamListToolbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateKam, setOpenCreateKam] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const queryOptions = useSelector(selectKamsQueryOptions);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const debounceFilter = useDebounce(
    queryOpts => dispatch(loadKams({ ...queryOpts })),
    400
  );

  const debounceSearch = useDebounce(
    (name, queryOpts) => dispatch(loadKams({ ...queryOpts, name, page: 0 })),
    400
  );

  const handleOpenCreateKam = useCallback(() => {
    setOpenCreateKam(true);
  }, [setOpenCreateKam]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [setFilterValues, debounceFilter, queryOptions, getFilterOptions]
  );

  const handleCreateKam = useCallback(
    values => {
      dispatch(createKam(values));
      setOpenCreateKam(false);
    },
    [setOpenCreateKam]
  );

  const handleCloseCreateKam = useCallback(() => {
    setOpenCreateKam(false);
  }, [setOpenCreateKam]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelKamList());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_KAM_OPTIONS });
    };
  }, [dispatch]);

  return (
    <>
      <Grid item lg={12} className={classes.actionsContainer}>
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {t(`KAMS_VIEW.KAMS`)}
        </Typography>
        <Grid>
          <Grid display="flex" justifyContent="flex-end">
            <Grid mr="1rem" alignContent="center">
              <SearchBar
                handleSearch={handleSearch}
                searchValue={searchValue}
              />
            </Grid>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding
              )}
              sx={{ marginRight: '1rem' }}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <AddButton
              style={{ marginRight: '1rem' }}
              onClick={handleOpenCreateKam}
            />
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <DownloadButton onDownload={handleExcelExport} />
          </Grid>
        </Grid>
      </Grid>
      <KamDialog
        open={openCreateKam}
        handleClose={handleCloseCreateKam}
        onSubmit={handleCreateKam}
      />
    </>
  );
};

export default KamListToolbar;
