import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectKamsList } from 'src/store/selectors/kamSelectors';
import { reassignClientAction } from 'src/store/actions/userClientActions';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../kam/KamListView/styles';

const ReassignClientDialog = ({ open, handleClose, kam, clientId }) => {
  const classes = useStyles();
  const initialState = makeInitialState(kam);
  const kams = useSelector(selectKamsList);
  const dispatch = useDispatch();
  const resetRef = useRef();
  const { t } = useTranslation();

  const kamFilteredList = kams.filter(k => k.id !== kam.id);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      dispatch(
        reassignClientAction({
          ...values,
          clientId,
          distributorId: kam.distributorId
        })
      );
      resetForm(initialState);
      handleClose();
    },
    [handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.REASSIGN_CLIENT_DIALOG.TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  label={t('CLIENTS_VIEW.REASSIGN_CLIENT_DIALOG.CURRENT_KAM')}
                  margin="normal"
                  name="kam"
                  type="text"
                  value={`${kam.id} - ${kam.name} `}
                  variant="outlined"
                  disabled
                />

                <DropdownMenu
                  list={kamFilteredList.map(k => ({
                    key: k.id,
                    name: `${k.id} - ${k.name} `
                  }))}
                  value={values.newKamId}
                  label={t('CLIENTS_VIEW.REASSIGN_CLIENT_DIALOG.NEW_KAM')}
                  setValue={value => setFieldValue('newKamId', value)}
                  error={Boolean(touched.newKamId && errors.newKamId)}
                  errorText={touched.newKamId && t(errors.newKamId)}
                  onBlur={handleBlur('newKamId')}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.dialogButton)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ReassignClientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.isRequired,
  kam: PropTypes.object
};

export default ReassignClientDialog;
