import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  LOAD_CONTRIBUTIONS,
  newCapitalContribution,
  loadContributions
} from 'src/store/actions/contractActions';
import PropTypes from 'prop-types';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectContributions } from 'src/store/selectors/contractSelectors';
import Add from '@mui/icons-material/Add';
import { isContractInGracePeriod, isCurrentMonthAndYear } from 'src/utils/common';
import useStyles from '../styles';
import ContributionsList from './ContributionsList';
import ContractStatus from '../../../../utils/constants/contracts';
import ContributionsForm from './CreateContributionForm';

const Contributions = ({ contract }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const contributions = useSelector(selectContributions);
  const loading = useSelector(isLoadingSelector([LOAD_CONTRIBUTIONS.action]));
  const success = useSelector(successSelector([LOAD_CONTRIBUTIONS.action]));
  const disabledContribution =
    contract.status === ContractStatus.ENDED ||
    isCurrentMonthAndYear(contract.endDate) ||
    isCurrentMonthAndYear(contract.startDate) ||
    isContractInGracePeriod(contract);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(newCapitalContribution(Number(id), values, handleClose));
    },
    [setOpen]
  );

  useEffect(() => {
    dispatch(loadContributions(id));
  }, [dispatch, id]);

  return (
    <Box mt={3} display="flex" flexDirection="column">
      <Box
        className={classes.actionsContainer}
        lg={12}
        flex
        justifyContent="flex-end"
      >
        <Tooltip
          title={
            disabledContribution && t('CONTRACT_VIEW.CONTRIBUTION_DISABLED')
          }
        >
          <span>
            <Button
              className={clsx(classes.buttons)}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              disabled={disabledContribution}
            >
              <Add sx={{ marginRight: 1 }} />
              {t('CONTRACT_VIEW.NEW_CONTRIBUTION')}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box lg={12}>
        <ContributionsList
          contributions={contributions}
          loading={loading}
          success={success}
        />
        <ContributionsForm
          open={open}
          handleClose={handleClose}
          handleCreateContribution={handleSubmit}
          contractId={id}
          currentRent={contract.rent}
        />
      </Box>
    </Box>
  );
};

Contributions.propTypes = {
  contract: PropTypes.object.isRequired
};

export default Contributions;
