import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const DistributorAlert = ({
  denominations,
  equipmentTypes,
  categoryList,
  loading
}) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading) {
    if (!denominations.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_DENOMINATIONS_FIRST')}
            <Link to="/catalogs/denominations">
              {t(
                'DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_DENOMINATIONS_SECOND'
              )}
            </Link>
          </Alert>
        </Collapse>
      );
    }

    if (!equipmentTypes.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t(
              'DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENT_TYPES_FIRST'
            )}
            <Link to="/catalogs/equipment-types">
              {t(
                'DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_EQUIPMENT_TYPES_SECOND'
              )}
            </Link>
          </Alert>
        </Collapse>
      );
    }

    if (!categoryList.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_CATEGORIES_FIRST')}
            <Link to="/quoters/categories">
              {t('DISTRIBUTORS_VIEW.CREATION_FORM.ALERT.NO_CATEGORIES_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

export default DistributorAlert;

DistributorAlert.propTypes = {
  denominations: PropTypes.array.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  categoryList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};
