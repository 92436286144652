import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthDistributorId,
  selectAuthRole,
  selectAuthSystem
} from 'src/store/selectors/authSelectors';
import UserType, { DistributorRoles } from 'src/utils/constants/auth';
import {
  createBrandAction,
  deleteBrandAction,
  editBrandAction
} from 'src/store/actions/catalogActions';
import BrandList from 'src/views/catalog/BrandsListView/BrandList';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import BrandsAndModelsToolbar from './BrandsAndModels/BrandsAndModelsToolbar';
import DisitributorBrandForm from './BrandsAndModels/BrandForm';

const BrandsAndModelsView = () => {
  const role = useSelector(selectAuthRole);
  const distributorId = useSelector(selectAuthDistributorId);
  const system = useSelector(selectAuthSystem);
  const disabledActions =
    role !== DistributorRoles.DIRECTOR && system !== UserType.ADMIN;
  const { id: adminDistributorId } = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleOpenEdit = useCallback(
    values => {
      setOpen(true);
      setData(values);
    },
    [setOpen, setData]
  );

  const handleCreate = useCallback(
    values => {
      delete values.serviceId;
      dispatch(
        createBrandAction({
          ...values,
          distributorId:
            system === UserType.DISTRIBUTOR
              ? Number(distributorId)
              : Number(adminDistributorId)
        })
      );
      handleClose();
    },
    [handleClose, dispatch]
  );

  const handleEdit = useCallback(
    values => {
      dispatch(editBrandAction(values, handleClose));
    },
    [handleClose, dispatch]
  );

  const handleDelete = useCallback(
    id => {
      dispatch(
        deleteBrandAction({
          id,
          distributorId:
            system === UserType.DISTRIBUTOR
              ? Number(distributorId)
              : Number(adminDistributorId)
        })
      );
      handleClose();
    },
    [handleClose, dispatch]
  );

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BrandsAndModelsToolbar
            onOpen={handleOpen}
            disabledActions={disabledActions}
          />
        </Grid>
        <Grid item xs={12}>
          <BrandList
            handleOpenEdit={handleOpenEdit}
            handleDelete={handleDelete}
            distributorId={
              system === UserType.DISTRIBUTOR
                ? distributorId
                : adminDistributorId
            }
          />
        </Grid>
      </Grid>
      <DisitributorBrandForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Box>
  );
};

export default BrandsAndModelsView;
