export default values => ({
  invoicePdfUrl: values.invoicePdfUrl
    ? {
        name: 'EQUIPMENT_VIEW.EQUIPMENT_DETAIL.VIEW_INVOICE_PDF',
        link: values.invoicePdfUrl,
        inDb: true
      }
    : null,
  invoiceXmlUrl: values.invoiceXmlUrl
    ? {
        name: 'EQUIPMENT_VIEW.EQUIPMENT_DETAIL.VIEW_INVOICE_XML',
        link: values.invoiceXmlUrl,
        inDb: true
      }
    : null,
  price: values.price ?? 0,
  iva: values.iva
});
