import { Box, MenuItem, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DropdownMenu = ({
  list,
  value,
  setValue,
  label,
  error,
  errorText,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, [value, setValue]);

  return (
    <Box>
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        {...props}
        value={isLoading ? 'loading' : value}
        onChange={handleChange}
        label={label}
        margin="normal"
        variant="outlined"
        style={{ width: '100%' }}
        select
        helperText={errorText}
        error={error}
        disabled={disabled}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }
        }}
      >
        {isLoading
          ? [...list, { key: 'loading', name: 'COMMON.LOADING' }].map(item => (
            <MenuItem value={item.key} key={item.key}>
              {t(item.name)}
            </MenuItem>
            ))
          : list.map((item) => {
              return (
                <MenuItem value={item.key} key={`${crypto.randomUUID()}`}>
                  {t(item.name)}
                </MenuItem>
              );
            })}
      </TextField>
    </Box>
  );
};

export default DropdownMenu;

DropdownMenu.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.object
      ])
    })
  ),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object
  ]),
  setValue: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  isLoading: PropTypes.bool
};
