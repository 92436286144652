import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Typography,
  Button,
  Box,
  Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/GetApp';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { selectClientsQueryOptions } from 'src/store/selectors/clientSelectors';
import ClientStatus from 'src/utils/constants/clients';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  CLEAR_CLIENT_OPTIONS,
  createClient,
  downloadExcelClients,
  loadClients
} from 'src/store/actions/clientActions';
import useStyles from './styles';
import ClientsForm from './ClientForm';

const Toolbar = ({ className, clientStatus, ...rest }) => {
  const { t } = useTranslation();
    const { tab } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectClientsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('name');

  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const debounceSearch = useDebounce(
    (criteria, value) =>
      dispatch(
        loadClients({
          [criteria]: value,
          page: 0
        })
      ),
    400
  );

  useEffect(() => {
    dispatch({ type: CLEAR_CLIENT_OPTIONS });
    dispatch(
      loadClients({
       status: tab || ClientStatus.ACTIVE
      })
    );
    return () => dispatch({ type: CLEAR_CLIENT_OPTIONS });
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value);
    },
    [setSearchValue, dispatch, debounceSearch, queryOptions, clientStatus]
  );

  const handleSearchCriteria = useCallback(
    ev => {
      dispatch(
        loadClients({
          [searchCriteria]: ''
        })
      );
      setSearchCriteria(ev.target.value);
      setSearchValue('');
    },
    [setSearchCriteria, dispatch, debounceSearch, queryOptions, clientStatus]
  );

  const handleCreateClient = useCallback(
    (values, setData) => {
      dispatch(createClient(values, handleClose, setData));
    },
    [dispatch, handleClose]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelClients());
  }, [dispatch]);

  const Addornment = (
    <InputAdornment position="start">
      <SearchIcon size={20} />
    </InputAdornment>
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400', textTransform: 'capitalize' }}
            color="primary"
            variant="h1"
          >
            {t(`CLIENTS_VIEW.${clientStatus.toUpperCase()}_CLIENTS`)}
          </Typography>
          <Box className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <>
                <Input
                  style={{ width: '100%' }}
                  classes={{ input: classes.input }}
                  startAdornment={Addornment}
                  placeholder={
                    searchCriteria === 'id'
                      ? t('CLIENTS_VIEW.SEARCH_BY_ID')
                      : t('CLIENTS_VIEW.SEARCH_BY_NAME')
                  }
                  className="flex mx-8"
                  disableUnderline
                  value={searchValue}
                  inputProps={{
                    'aria-label': 'Search'
                  }}
                  type={searchCriteria === 'id' ? 'number' : 'string'}
                  onChange={handleSearch}
                />

                <Select
                  id="criteria-selection"
                  style={{ width: '30%' }}
                  variant="standard"
                  defaultValue="name"
                  onChange={handleSearchCriteria}
                >
                  <MenuItem value="id">
                    {t('CLIENTS_VIEW.SEARCH_BY_ID')}
                  </MenuItem>
                  <MenuItem value="name">
                    {t('CLIENTS_VIEW.SEARCH_BY_NAME')}
                  </MenuItem>
                </Select>
              </>
            </Paper>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <FileDownloadIcon className={classes.icon} />
            </Button>
            <Tooltip title={t('CLIENTS_VIEW.ADD_NEW_CLIENT')}>
              <Button
                className={clsx(classes.buttons, classes.outlined)}
                onClick={handleClickOpen}
                color="secondary"
              >
                <Add className={classes.icon} />
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <ClientsForm
          open={open}
          handleCreateClient={handleCreateClient}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  clientStatus: PropTypes.string
};

export default Toolbar;
