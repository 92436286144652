import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailCard from 'src/components/DetailBox/DetailCard';
import DistributorStatsInfo from './DistributorStatsInfo';

const DistributorStats = ({ distributor }) => {
  const { t } = useTranslation();

  return (
    <DetailCard title={t('COMMON.RESUME')}>
      <DistributorStatsInfo distributor={distributor} />
    </DetailCard>
  );
};

DistributorStats.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default DistributorStats;
