import * as Yup from 'yup';

export default Yup.object().shape({
  startDate: Yup.date().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  endDate: Yup.date().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  payday: Yup.number()
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(1, 'SCHEMA_ERRORS.PAYDAY_MIN')
    .max(25, 'SCHEMA_ERRORS.PAYDAY_MAX')
});
