import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import ValidationDetailRow from './ValidationDetailRow';

const ValidationDetailTable = ({ className, validationList, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.NAME')}</TableCell>
      <TableCell align="center">
        {t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.VALIDATION_STATUS')}
      </TableCell>
      <TableCell align="right">{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return validationList.map((validation, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ValidationDetailRow {...validation} key={`validationRow${index}`} />
      ));
    },
    [validationList]
  );

  if (validationList.length === 0)
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.VALIDATION_TITLE" />
      </Card>
    );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

ValidationDetailTable.propTypes = {
  className: PropTypes.string,
  validationList: PropTypes.array.isRequired
};

export default ValidationDetailTable;
