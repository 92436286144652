import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import AgentRow from './AgentRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ agents }) =>
  agents.map(agent => <AgentRow key={agent.id} {...agent} />);

const AgentTable = ({
  agents,
  page,
  count,
  handleChangePage,
  loading,
  success,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell sx={{ width: '15%' }} variant="head" key="name">
        {t('COMMON.NAME')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" key="phone">
        {t('COMMON.PHONE')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" key="office-phone">
        {t('COMMON.OFFICE_PHONE')}
      </TableCell>
      <TableCell
        sx={{ width: '15%' }}
        variant="head"
        key="email"
        align="center"
      >
        {t('COMMON.EMAIL')}
      </TableCell>
      <TableCell sx={{ width: '5%' }} variant="head" key="role" align="center">
        {t('COMMON.ROLE')}
      </TableCell>
      <TableCell
        sx={{ width: '10%' }}
        variant="head"
        key="email_validated"
        align="center"
      >
        {t('COMMON.EMAIL_VALIDATED')}
      </TableCell>
      <TableCell
        sx={{ width: '5%' }}
        variant="head"
        key="status"
        align="center"
      >
        {t('COMMON.STATUS')}
      </TableCell>
      <TableCell
        sx={{ width: '10%' }}
        variant="head"
        key="status"
        align="center"
      >
        {t('COMMON.REQUESTS')}
      </TableCell>
      <TableCell
        sx={{ width: '10%' }}
        variant="head"
        key="status"
        align="center"
      >
        {t('COMMON.QUOTES_ACCEPTED')}
      </TableCell>
      <TableCell sx={{ width: '3%' }} variant="head" key="options">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody agents={agents} />, [agents]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, agents]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTOR_AGENTS_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AgentTable.propTypes = {
  agents: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default AgentTable;
