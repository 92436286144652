import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentViewerDialog from '../DocumentViewer/DocumentViewerDialog';

const ViewDocumentButton = ({ documentId, documentUrl, disabled = false }) => {
  const { t } = useTranslation();
  const [openViewer, setOpenViewer] = useState(false);
  const canOpenInViewer = !!documentUrl;

  const handleOnClick = () => {
    setOpenViewer(true);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
  };

  return (
    <Tooltip
      title={
        disabled ? (
          <span style={{ whiteSpace: 'pre-line' }}>
            {t('COMMON.NO_DOWNLOAD_DATA')}
          </span>
        ) : (
          <span>
            {canOpenInViewer
              ? t('COMMON.OPEN_DOCUMENT')
              : t('COMMON.DOWNLOAD_INVOICE')}
          </span>
        )
      }
    >
      <span>
        <Button onClick={handleOnClick} disabled={disabled}>
          <FileOpenIcon />
        </Button>

        {openViewer && (
          <DocumentViewerDialog
            open={openViewer}
            onClose={handleCloseViewer}
            documentUrl={documentUrl}
            key={documentId}
          />
        )}
      </span>
    </Tooltip>
  );
};

ViewDocumentButton.propTypes = {
  documentId: PropTypes.string.isRequired,
  documentUrl: PropTypes.string,
  disabled: PropTypes.bool
};

export default ViewDocumentButton;
