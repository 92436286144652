export const formatAmount = (value, decimals = 2) =>
  (Number(value) || 0).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

export const formatAmountWithDecimals = (value, decimals = 3) => {
  return (Number(value) || 0).toLocaleString('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: decimals
  });
};
export const formatNegativeAmount = value => {
  return value > 0
    ? `(${formatAmount(Number(value))})`
    : formatAmount(Math.abs(Number(value)));
};

export const formatPositiveAmount = value => {
  return Number(value) < 0
    ? `(${formatAmount(Math.abs(Number(value)))})`
    : formatAmount(Number(value));
};

export const removesScientificNotationFromNumber = value => {
  if (typeof value === 'number') {
    if (value.toString().includes('e')) {
      return Number(value)
        .toFixed(16)
        .replace(/\.?0+$/, '');
    }

    return Number(value).toString();
  }

  return value;
};
