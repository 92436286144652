import React from 'react';
import moment from 'moment-timezone';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectClientSex } from 'src/utils/common';

const GeneralAvalInfo = ({ aval }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.FULL_NAME')}
        value={aval.fullName || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.KINSHIP')}
        value={aval.kinship || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.DATE_OF_BIRTH')}
        value={
          aval.birthDate
            ? moment(aval.birthDate).format('DD-MMM-YY')
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.AGE')}
        value={aval.age || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.GENDER')}
        value={selectClientSex(aval.sex, t)}
      />

      <DetailRow name="RFC" value={aval.rfc || t('COMMON.NO_DATA')} />

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER')}
        value={aval.identificationNumber || t('COMMON.NO_DATA')}
      />

      <DetailRow name="CURP" value={aval.curp || t('COMMON.NO_DATA')} />

      <DetailRow
        name={t('COMMON.COUNTRY_OF_BIRTH')}
        value={t(`COUNTRIES.${aval.nationality}`)}
      />
    </>
  );
};

GeneralAvalInfo.propTypes = {
  aval: PropTypes.object
};
export default GeneralAvalInfo;
