import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Preloader from 'src/components/Preloader';

import {
  LOAD_INVESTOR_KYC_DETAIL,
  loadInvestorKYCDetail
} from 'src/store/actions/investorActions';
import { selectInvestorDetail } from 'src/store/selectors/investorSelector';
import GeneralKYC from './GeneralKYC';
import EconomicActivities from './EconomicActivities';
import DocumentKYC from './DocumentKYC';

const KYCView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const investor = useSelector(selectInvestorDetail);
  const loading = useSelector(
    isLoadingSelector([LOAD_INVESTOR_KYC_DETAIL.action])
  );
  const success = useSelector(
    successSelector([LOAD_INVESTOR_KYC_DETAIL.action])
  );

  useEffect(() => {
    dispatch(loadInvestorKYCDetail(id));
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('INVESTORS_VIEW.INVESTOR_DETAIL.GENERAL_KYC')}
            </Typography>
          </Box>
        </Grid>

        {success && !loading ? (
          <>
            <Grid item xs={12} md={6} lg={4}>
              <GeneralKYC investor={investor.kyc} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EconomicActivities investor={investor.kyc} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DocumentKYC investor={investor.kyc} />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default KYCView;
