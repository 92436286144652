import React, { useCallback } from 'react';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import {
  selectAgentList,
  selectAgentListCount,
  selectAgentQueryOptions
} from 'src/store/selectors/agentSelectors';
import { LOAD_AGENTS, loadAgentsList } from 'src/store/actions/agentActions';
import AgentsTable from './AgentsTable';

const AgentListist = () => {
  const dispatch = useDispatch();
  const agents = useSelector(selectAgentList);
  const count = useSelector(selectAgentListCount);
  const { page } = useSelector(selectAgentQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_AGENTS.action]));
  const success = useSelector(successSelector([LOAD_AGENTS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadAgentsList({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.AGENTS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.AGENTS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <AgentsTable
      agents={agents}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default AgentListist;
