import { PaymentInputOption } from 'src/utils/constants/quotes';

const makeInitialState = values => {
  return {
    client: values ? values.client : '',
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values && (values.price || 0),
    distributorProfile: values ? values.distributorProfile : '',
    distributorProfileId: values ? values.distributorProfileId : '',
    equipmentInsuranceIncluded: values ? values.equipmentInsuranceIncluded : '',
    gracePeriod: values ? values.gracePeriod : '',
    initialPayment: values ? values.initialPayment : '',
    residualPayment: values ? values.residualPayment : '',
    paymentInputOption:
      values.paymentInputOption || PaymentInputOption.PERCENTAGE
  };
};

export default makeInitialState;
