import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CommonActions } from 'src/utils/constants/clients';
import { setModelName } from 'src/store/actions/catalogActions';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import LaunchIcon from '@mui/icons-material/Launch';

const BrandRow = ({ name, type, id, openEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const { id: distributorId } = useParams();

  const handleSetBrandName = () => {
    return dispatch(setModelName(name));
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      openEdit();
    }
    if (option === CommonActions.DELETE) {
      handleDelete(id);
    }
  };

  return (
    <TableRow hover>
      <TableCell
        style={{
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left'
        }}
      >
        {name}
      </TableCell>
      <TableCell
        style={{
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left'
        }}
      >
        {type}
      </TableCell>
      <TableCell
        style={{
          width: '10%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}
      >
        <Link
          onClick={handleSetBrandName}
          to={
            distributorId
              ? `/distributors/business/${distributorId}/models/${id}`
              : `/catalogs/models/${id}`
          }
        >
          <LaunchIcon fontSize="small" />
        </Link>
      </TableCell>
      <TableCell
        style={{
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'right'
        }}
      >
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>
    </TableRow>
  );
};

BrandRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default BrandRow;
