import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_MODELS, loadModels } from 'src/store/actions/catalogActions';
import {
  selectModelsList
} from 'src/store/selectors/catalogSelectors';
import {
  selectAuthDistributorId,
  selectAuthSystem
} from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import ModelsTable from './ModelsTable';

const ModelsList = () => {
  const dispatch = useDispatch();
  const { id, brandId } = useParams();
  const models = useSelector(selectModelsList);
  const loading = useSelector(isLoadingSelector([LOAD_MODELS.action]));
  const success = useSelector(successSelector([LOAD_MODELS.action]));
  const kamDistributorId = useSelector(selectAuthDistributorId);
  const system = useSelector(selectAuthSystem);

  useEffect(() => {
    if (brandId) {
      dispatch(
        loadModels(
          brandId,
          id,
        )
      );
    } else {
      dispatch(
        loadModels(
          id,
          system === UserType.DISTRIBUTOR
            ? Number(kamDistributorId)
            : null
        )
      );
    }
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <ModelsTable models={models} loading={loading} success={success} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModelsList;
