import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControl,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import { loadAdministrativeRolesList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { useDispatch } from 'react-redux';
import PhoneInput from 'src/components/PhoneInput';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdministrativeDialog = ({
  open,
  handleClose,
  submit,
  administrative
}) => {
  const classes = useStyles();
  const initialState = makeInitialState(administrative);
  const dispatch = useDispatch();
  const [administrativeRoles, setAdministrativeRoles] = useState([]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleSubmitAdministrative = useCallback(
    (values, { resetForm }) => {
      if (administrative) {
        const difference = getDifferenceBetweenStates(initialState, values);
        if (Object.entries(difference).length === 0) {
          handleClose();
          return;
        }
        submit(administrative.id, {
          ...difference
        });
      } else {
        submit({
          ...values
        });
      }
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadAdministrativeRolesList(setAdministrativeRoles));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {administrative
            ? t('DISTRIBUTORS_VIEW.EDIT_CONTACT')
            : t('DISTRIBUTORS_VIEW.CREATE_CONTACT')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmitAdministrative}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.fullName && errors.fullName)}
                  fullWidth
                  helperText={touched.fullName && t(errors.fullName)}
                  label={t('COMMON.FULL_NAME')}
                  margin="normal"
                  name="fullName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.fullName}
                  variant="outlined"
                />

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    label={t('COMMON.MOBILE_PHONE')}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  label={t('COMMON.EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <FormControl
                  fullWidth
                  error={Boolean(touched.role && errors.role)}
                >
                  <Autocomplete
                    label={t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.ROLE')}
                    name="role"
                    options={administrativeRoles}
                    id="role"
                    value={values.role ?? ''}
                    getOptionLabel={option => (option ? ` ${option.name}` : '')}
                    clearOnBlur={false}
                    fullWidth
                    sx={{ marginBottom: 1, marginTop: 2 }}
                    clearIcon={null}
                    onChange={(_e, value) => {
                      setFieldValue('role', value ?? null);
                    }}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && t(errors.role)}
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
      {/* disclaimer */}
      <Typography fontSize="0.8em" color="#153750" alignSelf="center" mb={2}>
        {t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.DISCLAIMER')}
      </Typography>
    </Dialog>
  );
};

AdministrativeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  administrative: PropTypes.object
};

export default AdministrativeDialog;
