import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Link,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { selectBureauFormQuoteDetail } from 'src/store/selectors/bureauFormSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  GET_QUOTE_INFORMATION,
  getQuoteInformation
} from 'src/store/actions/bureauFormActions';
import { formatAmount } from 'src/utils/formatAmout';
import Loading from 'src/components/Loading';
import useStyles from '../styles';

const StepOne = ({ data, handleNext }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const quoteInformation = useSelector(selectBureauFormQuoteDetail);
  const loadingQuote = useSelector(
    isLoadingSelector([GET_QUOTE_INFORMATION.action])
  );
  const { t } = useTranslation();

  const onNext = useCallback(async () => {
    handleNext({});
  }, [handleNext, data]);

  useEffect(() => {
    dispatch(getQuoteInformation());
  }, []);

  if (loadingQuote || !quoteInformation)
    return (
      <Box
        sx={{
          height: {
            xs: 'auto',
            md: '50vh'
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Loading />
      </Box>
    );

  return (
    <Box
      sx={{
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: 'auto',
          md: '50vh'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '50%'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.TITLE')}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px'
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.FIRST_DESCRIPTION')
            .replace('{equipmentName}', quoteInformation.equipmentName)
            .replace('{distributorName}', quoteInformation.distributorName)}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: '5px'
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.SECOND_DESCRIPTION')
            .replace('{term}', quoteInformation.term)
            .replace('{rent}', formatAmount(quoteInformation.rent))}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            fontWeight: 'bold'
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.DOCUMENT_REQUEST')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '50%',
          marginTop: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="body1"
            className={classes.subtitle}
            sx={{
              fontzSize: '10px',
              color: 'text.secondary'
            }}
          >
            {t('BUREAU_FORM.STEP_ONE.APROXIMATE_TIME')}
          </Typography>

          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '40px'
            }}
          >
            <Checkbox onClick={() => setAcceptedTerms(!acceptedTerms)} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  margin: '0 5px'
                }}
              >
                {t('COMMON.ACCEPT')}
              </Typography>
              <Link
                href="https://www.astrocap.com/terminos-y-condiciones"
                variant="a"
                target="_blank"
                sx={{
                  display: 'inline-block'
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline'
                  }}
                >
                  {t('LOGIN_VIEW.TERMS_AND_CONDITIONS')}
                </Typography>
              </Link>
              <Typography
                variant="body1"
                sx={{
                  margin: '0 5px'
                }}
              >
                y
              </Typography>
              <Link
                href="https://www.astrocap.com/privacidad"
                variant="a"
                target="_blank"
                sx={{
                  display: 'inline-block'
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline'
                  }}
                >
                  {t('LOGIN_VIEW.PRIVACY_POLICY')}
                </Typography>
              </Link>
            </Box>
          </FormControl>
        </Box>

        <Box className={classes.box} display="flex" justifyContent="flex-end">
          <Button
            className={clsx(
              classes.buttons,
              acceptedTerms && classes.buttonMain
            )}
            onClick={onNext}
            color="primary"
            variant="outlined"
            disabled={!acceptedTerms}
          >
            {t('COMMON.NEXT')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func
};

export default StepOne;
