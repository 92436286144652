import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';
import { getClientFullName } from 'src/utils/common';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import { MoffinScoreRange } from 'src/utils/constants/clients';
import IconStatusAndColor from 'src/utils/constants/common';
import StatusIcon from 'src/components/StatusIcon';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import useStyles from '../../styles';

const QuoteClientDetail = ({ quote }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const system = useSelector(selectAuthSystem);

  const statusTypeAndColor = () => {
    if (!quote.clientMoffinStatus) return IconStatusAndColor.NONE;
    switch (quote.clientMoffinStatus) {
      case MoffinScoreRange.PASS:
        return {
          ...IconStatusAndColor.OK,
          description: 'COMMON.APPROVED'
        };

      case MoffinScoreRange.WARNING:
        return {
          ...IconStatusAndColor.WARNING,
          description: 'COMMON.WARNING'
        };

      case MoffinScoreRange.FAIL:
        return {
          ...IconStatusAndColor.ERROR,
          description: 'COMMON.REJECTED'
        };
      default:
        return IconStatusAndColor.NONE;
    }
  };

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.CLIENT_DETAIL.TITLE')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DetailRow
              name={t('COMMON.CLIENT')}
              value={
                <Link to={`/clients/detail/${quote.client.id}`}>
                  {getClientFullName(quote.client)}
                </Link>
              }
            />

            {system === UserType.ADMIN && (
              <>
                <DetailRow
                  name={t('COMMON.KAM')}
                  value={
                    quote.distributorProfile ? (
                      <Link
                        to={`/distributor-profile/${quote.distributorProfile.id}`}
                      >
                        {quote.distributorProfile.name}
                      </Link>
                    ) : (
                      t('COMMON.NO_DATA')
                    )
                  }
                />
                {quote.clientNufiScore && (
                  <DetailRow
                    name={t('COMMON.NUFI_SCORE')}
                    value={formatAmountWithDecimals(quote.clientNufiScore)}
                  />
                )}
                {quote.clientMoffinScore && (
                  <DetailRow
                    name={t('COMMON.MOFFIN_SCORE')}
                    value={formatAmountWithDecimals(quote.clientMoffinScore)}
                  >
                    <StatusIcon
                      type={statusTypeAndColor().status}
                      description={statusTypeAndColor().description}
                      size="small"
                    />
                  </DetailRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

QuoteClientDetail.propTypes = {
  quote: PropTypes.object.isRequired
};

export default QuoteClientDetail;
