import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import ParameterRow from './ParameterRow';

const ParameterTable = ({ className, parameters, handleOpenEdit, ...rest }) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    parameter => () => {
      handleOpenEdit(parameter);
    },
    [handleOpenEdit]
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('PARAMETERS_VIEW.NAME')}</TableCell>
      <TableCell align="center">{t('PARAMETERS_VIEW.VALUE')}</TableCell>
      <TableCell>{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <ParameterRow
          {...parameter}
          key={parameter.id}
          openEdit={openEdit(parameter)}
        />
      ));
    },
    [parameters]
  );

  if (!parameters.length)
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_DESCRIPTION"
        />
      </Card>
    );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

ParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func
};

export default ParameterTable;
