import React from 'react';
import PropTypes from 'prop-types';
import PhoneInputBasic from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const PhoneInput = ({
  name,
  onChange,
  value,
  label,
  error,
  disabled = false,
  ...props
}) => {
  return (
    <PhoneInputBasic
      name={name}
      onChange={phone => {
        return onChange(name, phone);
      }}
      value={value}
      specialLabel={label}
      isValid={!error}
      disabled={disabled}
      {...props}
      inputStyle={{
        width: '100%',
        height: '56px',
        fontSize: '1rem',
        color: disabled ? 'rgba(95, 97, 98, 0.6)' : '#153750'
      }}
      country="mx"
      buttonStyle={{
        background: 'none'
      }}
      inputProps={{
        name,
        required: true,
        disabled
      }}
    />
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};
export default PhoneInput;
