import * as Yup from 'yup';

export default Yup.object().shape({
  rate: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  term: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  equipmentCost: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  initialPayment: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  netInvestment: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  rent: Yup.number()
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  residualPayment: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
});
