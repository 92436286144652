import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_BRANDS, loadBrandsList } from 'src/store/actions/catalogActions';
import DialogTransition from 'src/components/DialogTransition';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import validationSchema from './schema';
import makeInitialState from './initialState';
import useStyles from '../../../../catalog/styles';

const DisitributorBrandForm = ({
  open,
  data,
  handleClose,
  handleCreate,
  handleEdit
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);
  const loadingBrands = useSelector(isLoadingSelector([LOAD_BRANDS.action]));
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  useEffect(() => {
    if (open) {
      dispatch(loadBrandsList(setBrands, true));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        onClose={handleClose}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-test-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data
              ? t('CATALOGS_VIEW.UPDATE_REGISTER')
              : t('CATALOGS_VIEW.ADD_NEW_REGISTER')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <Autocomplete
                      options={brands}
                      loading={loadingBrands}
                      id="name"
                      name="name"
                      clearOnBlur={false}
                      fullWidth
                      clearIcon={null}
                      label={t('COMMON.NAME')}
                      getOptionLabel={option => (option ? option.name : '')}
                      onInputChange={(event, newValue) => {
                        setFieldValue('name', newValue);
                      }}
                      inputValue={values.name}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          'name',
                          newValue ? newValue.name : event.target.value
                        );
                        setFieldValue('type', newValue ? newValue.type : '');
                      }}
                      freeSolo
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      helperText={touched.name && t(errors.name)}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </FormControl>

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.type && errors.type)}
                    fullWidth
                    helperText={touched.type && t(errors.type)}
                    label={t('COMMON.TYPE')}
                    margin="normal"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.type}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? t('COMMON.UPDATE') : t('COMMON.CREATE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DisitributorBrandForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default DisitributorBrandForm;
