export const selectBackroundValidations = state => state.backgroundValidations;
export const selectBackgroundValidationsList = state =>
  selectBackroundValidations(state).validations;
export const selectBackgroundValidationsListCount = state =>
  selectBackroundValidations(state).count;
export const selectBackgroundValidationsQueryOptions = state => {
  const { options } = selectBackroundValidations(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
