import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { saveAs } from 'file-saver';
import DialogTransition from 'src/components/DialogTransition';
import { selectDistributorCatalogsFailUpload } from 'src/store/selectors/distributorSelectors';

const FailUploadCatalogEquipmentDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const failUploadCatalogEquipment = useSelector(
    selectDistributorCatalogsFailUpload
  );

  const downloadFile = useCallback(() => {
    const blob = new Blob([failUploadCatalogEquipment], {
      type: 'text/csv;charset=utf-8;'
    });
    saveAs(blob, `${t('DISTRIBUTORS_VIEW.CATALOG_EXCEL_WITH_ERRORS')}.csv`);
  }, [failUploadCatalogEquipment]);

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '30fr',
          maxHeight: '70vh'
        }
      }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle>
        <DialogTitle
          sx={{
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            '& h2': {
              fontWeight: 400
            }
          }}
          id="form-catalog-dialog-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            sx={{
              float: 'right',
              width: '20px',
              height: '20px'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="primary" variant="h2">
            {t('DISTRIBUTORS_VIEW.DOWNLOAD_CATALOG_EXCEL_WITH_ERRORS')}
          </Typography>
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '15px'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginBottom: theme.spacing(2)
            }}
          >
            {t('DISTRIBUTORS_VIEW.UPLOAD_CATALOG_SUCCESS_WITH_ERRORS')}
          </Typography>
          <Typography variant="body2">
            {t(
              'DISTRIBUTORS_VIEW.UPLOAD_CATALOG_SUCCESS_WITH_ERRORS_DESCRIPTION'
            )}
            <Box
              component="a"
              onClick={downloadFile}
              sx={{
                color: theme.palette.primary.main,
                cursor: 'pointer',
                textDecoration: 'underline',
                marginLeft: '5px'
              }}
            >
              {t('LOGIN_VIEW.CLICK_HERE')}
            </Box>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

FailUploadCatalogEquipmentDialog.propTypes = {
  open: PropTypes.string,
  handleClose: PropTypes.func
};
export default FailUploadCatalogEquipmentDialog;
