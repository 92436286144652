import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_CATALOG,
  loadTaxSystemList,
  LOAD_TAX_SYSTEM
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TaxSystemTable from './TaxSystemTable';

const TaxSystemList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const taxSystemList = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_TAX_SYSTEM.action]));
  const success = useSelector(successSelector([LOAD_TAX_SYSTEM.action]));

  useEffect(() => {
    dispatch(loadTaxSystemList());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []);

  if (success && !loading && taxSystemList.length === 0)
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.TAX_STYSTEM_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.TAX_SYSTEM_DESCRIPTION')}
        />
      </Card>
    );

  return <TaxSystemTable taxSystemList={taxSystemList} loading={loading} />;
};

export default TaxSystemList;
