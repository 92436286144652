import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_RENT_BALANCE_SCHEDULE } from 'src/store/actions/contractActions';
import ContractCalendarRow from './RentBalanceScheduleRow';

const Tbody = ({ rentBalanceSchedule }) =>
  rentBalanceSchedule.map(cal => <ContractCalendarRow key={cal.id} {...cal} />);

const RentBalanceScheduleTable = ({
  className,
  rentBalanceSchedule,
  ...rest
}) => {
  const { t } = useTranslation();

  const loading = useSelector(
    isLoadingSelector([LOAD_RENT_BALANCE_SCHEDULE.action])
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" align="center">
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.PERIOD')}
        </TableCell>
        <TableCell variant="head" align="center">
          {t('COMMON.DATE')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.MONTHLY_PRINCIPAL')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('CONTRACT_VIEW.CONTRACT_DETAIL.INTEREST')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.TOTAL')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '10rem' }}>
          {t('COMMON.IVA')}
        </TableCell>
        <TableCell variant="head" align="center" style={{ minWidth: '12rem' }}>
          {t('COMMON.TOTAL_IVA_INCLUDED')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(
    () => () => <Tbody t={t} rentBalanceSchedule={rentBalanceSchedule} />,
    [rentBalanceSchedule, t]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

RentBalanceScheduleTable.propTypes = {
  className: PropTypes.string,
  rentBalanceSchedule: PropTypes.array.isRequired
};

export default RentBalanceScheduleTable;
