import React, { useCallback } from 'react';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import {
  selectCounselorsList,
  selectCounselorsListCount,
  selectCounselorsQueryOptions
} from 'src/store/selectors/counselorSelectors';
import {
  LOAD_COUNSELORS,
  loadCounselors
} from 'src/store/actions/counselorActions';
import CounselorsTable from './CounselorsTable';

const CounselorsList = () => {
  const dispatch = useDispatch();
  const counselors = useSelector(selectCounselorsList);
  const count = useSelector(selectCounselorsListCount);
  const { page } = useSelector(selectCounselorsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_COUNSELORS.action]));
  const success = useSelector(successSelector([LOAD_COUNSELORS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCounselors({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.COUNSELORS_TITLE" />
      </Card>
    );
  }

  return (
    <CounselorsTable
      counselors={counselors}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default CounselorsList;
