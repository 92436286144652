import React from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import { roundNumber } from 'src/utils/common';
import OverflowTipography from 'src/components/OverflowTipography';

const CapitalBalanceRow = ({
  period,
  clientFullName,
  clientId,
  equipment,
  equipmentId,
  equipmentPrice,
  initialBalance,
  initialPayment,
  contribution,
  contractId,
  residual,
  finalBalance,
  payment
}) => {
  return (
    <TableRow hover>
      <TableCell>
        <OverflowTipography maxWidth="150px" title={clientFullName}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/clients/detail/${clientId}`}
          >
            {clientFullName}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <Link to={`/contracts/${contractId}`}>{contractId}</Link>
      </TableCell>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <OverflowTipography title={equipment}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/inventory/${equipmentId}`}
            maxWidth="190px"
          >
            {equipment}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">{period}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(initialBalance))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(equipmentPrice))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(initialPayment))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(payment))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(contribution))}
      </TableCell>
      <TableCell align="center">
        {formatNegativeAmount(roundNumber(residual))}
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(roundNumber(finalBalance))}
      </TableCell>
    </TableRow>
  );
};

CapitalBalanceRow.propTypes = {
  period: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  clientFullName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  equipment: PropTypes.string.isRequired,
  equipmentPrice: PropTypes.number.isRequired,
  equipmentId: PropTypes.string.isRequired,
  initialBalance: PropTypes.number.isRequired,
  initialPayment: PropTypes.number.isRequired,
  contribution: PropTypes.number.isRequired,
  residual: PropTypes.number.isRequired,
  finalBalance: PropTypes.number.isRequired,
  payment: PropTypes.number.isRequired
};

export default CapitalBalanceRow;
