import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  BuroAuthorizationFrequencyOptionList,
  MoffinParameter,
  ParameterType
} from 'src/utils/constants/parameters';
import ParameterRow from '../../common/ParameterRow';

const MoffinParameterTable = ({
  className,
  parameters,
  handleOpenEdit,
  isDisabledEdit,
  ...rest
}) => {
  const { t } = useTranslation();

  const openEdit = useCallback(
    parameter => () => {
      handleOpenEdit(
        parameter,
        BuroAuthorizationFrequencyOptionList.map(item => {
          return { ...item, name: t(item.name) };
        })
      );
    },
    [handleOpenEdit]
  );

  const isDisabledEditParameter = parameter =>
    (parameter && parameter.name === MoffinParameter.PASSED_RANGE_MAX) ||
    (parameter && parameter.type === ParameterType.ARRAY);

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('PARAMETERS_VIEW.NAME')}</TableCell>
      <TableCell align="center">{t('PARAMETERS_VIEW.VALUE')}</TableCell>
      <TableCell style={{ width: '1rem' }}>{}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <ParameterRow
          {...parameter}
          isDisabledEdit={isDisabledEditParameter(parameter)}
          key={parameter.id}
          openEdit={openEdit(parameter)}
        />
      ));
    },
    [parameters]
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

MoffinParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  isDisabledEdit: PropTypes.bool
};

export default MoffinParameterTable;
