export default (values, person) => ({
  ...values,
  name: Object.keys(person).length ? person.name : values.name,
  maternalName: Object.keys(person).length
    ? person.maternalName
    : values.maternalName,
  paternalName: Object.keys(person).length
    ? person.paternalName
    : values.paternalName,
  phone: Object.keys(person).length ? person.phone : values.phone,
  email: Object.keys(person).length ? person.email : values.email,
  industry: values.industry || '',
  denomination: values.denomination || '',
  sex: Object.keys(person).length ? person.sex : values.sex,
  existingRfc: !!Object.keys(person).length,
  curp: Object.keys(person).length ? person.curp : values.curp,
  identificationNumber: Object.keys(person).length
    ? person.identificationNumber
    : values.identificationNumber,
  nationality: Object.keys(person).length
    ? person.nationality
    : values.nationality,
  
});
