import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FadeIn from 'react-fade-in';
import { Box, Card, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useTranslation } from 'react-i18next';
import {
  CLEAR_KAM_DETAIL,
  LOAD_KAM_DETAIL,
  loadKamById
} from 'src/store/actions/userKamActions';
import { selectKamDetail } from 'src/store/selectors/kamSelectors';
import KamDetailTabs from './KamDetailTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const KamDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const kam = useSelector(selectKamDetail);
  const loading = useSelector(isLoadingSelector([LOAD_KAM_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_KAM_DETAIL.action]));

  useEffect(() => {
    dispatch(loadKamById(id));
    return dispatch({ type: CLEAR_KAM_DETAIL });
  }, [dispatch, id]);

  return (
    <Page
      className={classes.root}
      title={`${t('COMMON.KAM')} ${kam ? id : ''}`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loading && kam ? (
              <KamDetailTabs kam={kam} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default KamDetailView;
