import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';

export default function MultipleSelectChip({
  options,
  label,
  setFieldValue,
  errors,
  helperText,
  fieldName,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = React.useState([]);

  const optionList = options.filter(
    option => !selectedValue.includes(option.value)
  );

  const chips = selectedValue.map((option, index) => {
    return (
      <Chip
        sx={{ mt: 1 }}
        key={`${option.value}-${index}` || option}
        label={option.value ? `${option.value}` : option}
        deleteIcon={<RemoveCircleOutline />}
        onDelete={() => {
          setSelectedValue(selectedValue.filter(entry => entry !== option));
          setFieldValue(
            fieldName,
            selectedValue.filter(entry => entry !== option)
          );
        }}
      />
    );
  });

  const handleChange = (e, newValue) => {
    const targetValue = e.target.value;

    const formattedNewValue =
      typeof targetValue === 'string'
        ? [...selectedValue, targetValue]
        : [...selectedValue, newValue[newValue.length - 1].value];

    setSelectedValue(formattedNewValue);
    setFieldValue(fieldName, formattedNewValue);
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }} {...rest} error={errors || undefined}>
        <Autocomplete
          multiple
          freeSolo
          id="multiple-chip-autocomplete"
          filterSelectedOptions
          options={optionList}
          value={selectedValue}
          onChange={(e, newValue) => handleChange(e, newValue)}
          renderInput={params => (
            <TextField {...params} label={label} errors={errors || undefined} />
          )}
          renderTags={() => {}}
        />
        <Box>{chips}</Box>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}

MultipleSelectChip.propTypes = {
  options: PropTypes.array.isRequired,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  helperText: PropTypes.string
};
