export default values => ({
  curp: values ? values.curp : '',
  civilStatus: values ? values.civilStatus : '',
  politicallyExposed: !!values?.politicallyExposed,
  politicallyExposedPerson: {
    name:
      values && values.politicallyExposedPerson
        ? values.politicallyExposedPerson.name
        : '',
    paternalName:
      values && values.politicallyExposedPerson
        ? values.politicallyExposedPerson.paternalName
        : '',
    maternalName:
      values && values.politicallyExposedPerson
        ? values.politicallyExposedPerson.maternalName
        : '',
    kinship:
      values && values.politicallyExposedPerson
        ? values.politicallyExposedPerson.kinship
        : '',
    position:
      values && values.politicallyExposedPerson
        ? values.politicallyExposedPerson.position
        : ''
  }
});
