import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { formatAmountWithDecimals, formatPositiveAmount } from 'src/utils/formatAmout';

const DocumentEquipmentInfo = ({ equipment }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.PDF_INVOICE')}
        value={<DocumentLink link={equipment.invoicePdfUrl} />}
      />

      <DetailRow
        name={t('COMMON.XML_INVOICE')}
        value={<DocumentLink link={equipment.invoiceXmlUrl} />}
      />
      <DetailRow
        name={t('COMMON.PRICE')}
        value={equipment.price ? formatPositiveAmount(equipment.price) : t('COMMON.NO_DATA')}
      />
      <DetailRow name={`${t('COMMON.IVA')} %`} value={equipment.iva ? formatAmountWithDecimals(equipment.iva) : t('COMMON.NO_DATA')} />
      <DetailRow
        name={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
        value={equipment.priceWithIva ? formatPositiveAmount(equipment.priceWithIva) : t('COMMON.NO_DATA')}
      />
    </>
  );
};

DocumentEquipmentInfo.propTypes = {
  equipment: PropTypes.object.isRequired
};

export default DocumentEquipmentInfo;
