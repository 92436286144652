export const selectBillings = state => state.billings;
export const selectBillingsList = state => selectBillings(state).items;
export const selectBillingFailuresList = state =>
         selectBillings(state).billingFailures;
export const selectBillingFailuresCount = state =>
  selectBillings(state).billingFailuresCount;
export const selectBillingsListCount = state => selectBillings(state).count;
export const selectBillingsQueryOptions = state => {
  const { options } = selectBillings(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
