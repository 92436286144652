import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { addPointsPhone } from 'src/utils/common';
import { Gender, PersonType } from 'src/utils/constants/clients';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CLIENTS_VIEW.CREATION_FORM.CLIENT')}
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CLIENTS_VIEW.CREATION_FORM.PERSON_TYPE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {t(`CLIENTS_VIEW.CREATION_FORM.${values.personType}`)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.NAME')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.name}
            </Typography>
          </TableCell>
        </TableRow>

        {values.personType === PersonType.PHYSICAL && (
          <>
            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('COMMON.PATERNAL_NAME')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.paternalName}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('COMMON.MATERNAL_NAME')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.maternalName}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.sex === Gender.MALE
                    ? t('COMMON.MALE')
                    : t('COMMON.FEMALE')}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('COMMON.PHONE')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.phone ? addPointsPhone(values.phone) : '-'}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('COMMON.EMAIL')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.email}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('COMMON.COUNTRY_OF_BIRTH')}
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.nationality
                    ? t(`COUNTRIES.${values.nationality}`)
                    : t('COMMON.NO_DATA')}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.RFC')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.rfc}
            </Typography>
          </TableCell>
        </TableRow>

        {values.personType === PersonType.LEGAL && (
          <>
            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('CLIENTS_VIEW.CREATION_FORM.DENOMINATION')}
              </TableCell>

              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.denomination}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow component="div">
              <TableCell component="div" className={classes.reviewDataLabel}>
                {t('CLIENTS_VIEW.CREATION_FORM.INDUSTRY')}
              </TableCell>

              <TableCell component="div">
                <Typography variant="body2" color="textSecondary">
                  {values.industry}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
