import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const RedirectLink = ({ link }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return link ? (
    <>
      <a
        className={classes.documentLink}
        onClick={() => {
          try {
            window.open(link, '_blank');
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          {t('COMMON.OPEN_LINK')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>
    </>
  ) : (
    <>{t('COMMON.NO_DATA')}</>
  );
};

RedirectLink.propTypes = {
  link: PropTypes.string.isRequired
};

export default RedirectLink;
