import React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import theme from 'src/theme';
import { useTranslation } from 'react-i18next';

function AddButton({ onClick, title, ...props }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(title || '')}>
      <Button
        sx={{
          height: 44,
          letterSpacing: 1.25,
          fontSize: 16,
          width: 44,
          fontWeight: '400',
          minWidth: 'unset',
          background: '#FFFFFF',
          '&:hover': {
            background: '#FFFFFF',
            opacity: 0.8
          },
          '&:focus': {
            background: '#FFFFFF',
            opacity: 0.8
          }
        }}
        onClick={onClick}
        color="primary"
        {...props}
      >
        <Add sx={{ fill: theme.palette.primary.main }} />
      </Button>
    </Tooltip>
  );
}
AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default AddButton;
