import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import { Typography, Button, Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { selectClientsQueryOptions } from 'src/store/selectors/clientSelectors';
import SearchBar from 'src/components/SearchBar';
import loadUserClients, {
  createDistributorClient
} from 'src/store/actions/userClientActions';
import useStyles from './styles';
import UserClientsForm from './UserClientForm';

const UserCLientToolbar = ({ className, clientStatus, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectClientsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [openUserCreationForm, setUserCreationForm] = useState(false);

  const handleOpenCreationForm = useCallback(() => {
    setUserCreationForm(true);
  }, [setUserCreationForm]);

  const handleCloseCreationForm = useCallback(() => {
    setUserCreationForm(false);
  }, [setUserCreationForm]);

  const handleCreateNewClient = useCallback(
    (values, setData) => {
      dispatch(
        createDistributorClient(
          values,
          () => handleCloseCreationForm(),
          setData
        )
      );
    },
    [dispatch, handleCloseCreationForm]
  );

  const debounceSearch = useDebounce(
    (name, queryOpts) =>
      dispatch(loadUserClients({ ...queryOpts, name, page: 0 })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400', textTransform: 'capitalize' }}
            color="primary"
            variant="h1"
          >
            {t('USER_NAVIGATION.MY_CLIENTS')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />

            <Tooltip title={t('CLIENTS_VIEW.ADD_NEW_CLIENT')}>
              <Button
                className={clsx(classes.buttons, classes.outlined)}
                color="secondary"
                onClick={handleOpenCreationForm}
              >
                <Add className={classes.icon} />
              </Button>
            </Tooltip>

            <UserClientsForm
              open={openUserCreationForm}
              handleCreateClient={handleCreateNewClient}
              handleClose={handleCloseCreationForm}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

UserCLientToolbar.propTypes = {
  className: PropTypes.string,
  clientStatus: PropTypes.string
};

export default UserCLientToolbar;
