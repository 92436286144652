import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadEquipmentTypes
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import EquipmentTypeTable from './EquipmentTypeTable';

// eslint-disable-next-line react/prop-types
const EquipmentTypeList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const equipmentTypes = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadEquipmentTypes());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return (
    <EquipmentTypeTable
      equipmentTypes={equipmentTypes}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};

export default EquipmentTypeList;
