import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { sendMoffinAuthorizationMessage } from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import selectFormatDate from 'src/utils/selectDateFormat';
import { useParams } from 'react-router';
import dateFormatType from 'src/utils/dateFormatType';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import { MoffinScoreRange } from 'src/utils/constants/clients';
import {
  PULL_MOFFIN_REPORT,
  pullAvalMoffinReport
} from 'src/store/actions/moffinActions';
import clsx from 'clsx';
import useStyles from './styles';
import LoadingValidationDialog from './LoadingValidationDialog';
import BuroDetailTabs from './BuroDetailTabs';

const BuroValidationDetail = ({ validation }) => {
  const classes = useStyles();
  const loading = useSelector(isLoadingSelector([PULL_MOFFIN_REPORT.action]));

  const dispatch = useDispatch();
  const { avalId } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();

  const handleCloseLoadingDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenLoadingDialog = () => {
    setOpenDialog(true);
  };
  const handleSendAuthorizationMessage = useCallback(() => {
    handleOpenLoadingDialog();
    return dispatch(
      sendMoffinAuthorizationMessage('', avalId, handleCloseLoadingDialog)
    );
  }, [validation]);

  const handlePullMoffinReport = useCallback(() => {
    handleOpenLoadingDialog();

    return dispatch(
      pullAvalMoffinReport(validation.rfc, avalId, handleCloseLoadingDialog)
    );
  }, [validation]);

  const statusTypeAndColor = () => {
    switch (validation.moffinScoreStatus) {
      case MoffinScoreRange.PASS:
        return IconStatusAndColor.OK;

      case MoffinScoreRange.WARNING:
        return IconStatusAndColor.WARNING;

      case MoffinScoreRange.FAIL:
        return IconStatusAndColor.ERROR;
      default:
        return IconStatusAndColor.NONE;
    }
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} lg={12}>
          <Grid container className={classes.validationContainer}>
            <Grid
              item
              lg={4}
              md={5}
              className={classes.validation}
              sx={{ flexDirection: { xs: 'column', lg: 'row', md: 'row' } }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box
                  className={classes.validationIcon}
                  sx={{ background: statusTypeAndColor().backgroundColor }}
                >
                  <StatusIcon type={statusTypeAndColor().status} size="small" />
                </Box>
                <Box>
                  <Typography
                    style={{ marginBottom: 8 }}
                    color="textPrimary"
                    component="p"
                    variant="body2"
                  >
                    {t('COMMON.SCORE')}
                  </Typography>
                  <Typography color="primary" component="h2" variant="h2">
                    {validation.creditProfileScore
                      ? Number(validation.creditProfileScore)
                      : '-'}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.lastUpdateContent}>
                <Typography className={classes.subTitle}>
                  {t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.LAST_UPDATE')}
                </Typography>
                <Typography>
                  {validation.lastUpdate
                    ? selectFormatDate(
                        validation.lastUpdate,
                        dateFormatType.DAY
                      )
                    : '-'}
                </Typography>
              </Box>
            </Grid>

            <Grid lg={4}>
              <Grid item className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.buttons}
                  onClick={handleSendAuthorizationMessage}
                >
                  {!validation.moffinAuthorizationMessageSent
                    ? t('CLIENTS_VIEW.MOFFIN_DETAIL.SEND_TO_APPROVAL')
                    : t('CLIENTS_VIEW.MOFFIN_DETAIL.RESEND_TO_APPROVAL')}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  className={clsx(classes.buttons, classes.outlined)}
                  onClick={handlePullMoffinReport}
                >
                  {t('CLIENTS_VIEW.MOFFIN_DETAIL.PULL_MOFFIN_REPORT')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <BuroDetailTabs validation={validation} />
        </Grid>
      </Grid>
      {loading && (
        <LoadingValidationDialog
          openDialog={openDialog}
          onClose={handleCloseLoadingDialog}
        />
      )}
    </Box>
  );
};

BuroValidationDetail.propTypes = {
  validation: PropTypes.object.isRequired
};

export default BuroValidationDetail;
