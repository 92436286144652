import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_CONTRACT } from 'src/store/actions/contractActions';
import DialogTransition from 'src/components/DialogTransition';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const stepperLabels = [
  'COMMON.ASSIGNATION',
  'COMMON.AVALS',
  'COMMON.AMOUNTS',
  'COMMON.DATES',
  'COMMON.RESUME',
  'COMMON.CONFIRMATION'
];

const ContractToDigitalSignForm = ({
  open,
  handleClose,
  handleCreateContract
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const isLoading = useSelector(isLoadingSelector([CREATE_CONTRACT.action]));

  const resetRef = useRef();

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleCloseModal = useCallback(() => {
    if (resetRef.current) {
      resetRef.current();
    }
    setData(initialState);
    handleClose();
  }, [handleClose, initialState, data]);

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      setData(initialState);
      handleClose();
    } else if (step === 5) {
      if (data.isExistingAddress) {
        setStep(3);
      } else {
        setStep(4);
      }
    } else {
      setData({ ...data });
      setStep(step - 1);
    }
  }, [dispatch, step, setStep, handleClose, data]);

  const handleSubmit = useCallback(
    value => {
      handleCreateContract({
        ...data,
        ...value
      });
      setData(initialState);
    },
    [data]
  );

  if (!open) return null;

  return (
    <Box>
      <Dialog
        aria-labelledby="form-new-gps-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-gps-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseModal}
            disabled={isLoading}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('CONTRACT_VIEW.NEW_CONTRACT')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent
          sx={{
            height: 'auto',
            maxHeight: 'calc(100vh - 200px)'
          }}
        >
          <FormStepper index={step} labels={stepperLabels} />
          <CurrentStep
            open={open}
            activeStep={step}
            data={data}
            resetRef={resetRef}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ContractToDigitalSignForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateContract: PropTypes.func.isRequired
};

export default ContractToDigitalSignForm;
