import { MinCountNotifications } from 'src/utils/constants/notifications';
import {
  LOAD_NEXT_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  MARK_AS_VIEWD,
  SAVE_NOTIFICATION_OPTIONS
} from '../actions/notificationsActions';

const initialState = {
  items: [],
  count: 0,
  unreadNotificationsCount: 0,
  unviewedNotificationsCount: 0,
  options: {
    page: 0,
    limit: MinCountNotifications
  }
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS.success: {
      return {
        ...state,
        items: [...action.items],
        count: action.count,
        unreadNotificationsCount: action.unreadNotificationsCount,
        unviewedNotificationsCount: action.unviewedNotificationsCount
      };
    }
    case LOAD_NEXT_NOTIFICATIONS.success: {
      return {
        ...state,
        items: [...state.items, ...action.items],
        count: action.count
      };
    }
    case MARK_AS_VIEWD.success: {
      return {
        ...state,
        unviewedNotificationsCount: 0
      };
    }
    case SAVE_NOTIFICATION_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
