const navBarOptions = {
  ADMIN: {
    OPERATION: 'OPERATION',
    INCOME: 'INCOME',
    ACCOUNTING: 'ACCOUNTING',
    ADMINISTRATION: 'ADMINISTRATION',
    AUDIT: 'AUDIT'
  }
};

export default navBarOptions;
