import HomeIcon from '@mui/icons-material/Home';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { AgentRoles } from 'src/utils/constants/distributors';
import ClientsIcon from '@mui/icons-material/EmojiPeople';
import DistributorIcon from '@mui/icons-material/Hail';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { UserStatus } from 'src/utils/constants/common';

const distributorDirectorRoutes = [
  {
    title: 'NAVIGATION.GENERAL',
    children: [
      {
        href: '/user/home',
        icon: HomeIcon,
        title: 'USER_NAVIGATION.HOME'
      },
      {
        href: '/user/clients',
        icon: ClientsIcon,
        title: 'USER_NAVIGATION.MY_CLIENTS'
      },
      {
        href: '/user/distributor',
        icon: DistributorIcon,
        title: 'USER_NAVIGATION.MY_COMPANY'
      },
      {
        href: '/user/kams',
        icon: SupervisorAccountIcon,
        title: 'USER_NAVIGATION.KAMS'
      },
      {
        icon: ReceiptIcon,
        title: 'USER_NAVIGATION.QUOTES',
        href: '/user/quotes'
      }
    ]
  }
];

const distributorKamRoutes = [
  {
    title: 'NAVIGATION.GENERAL',
    children: [
      {
        href: '/user/home',
        icon: HomeIcon,
        title: 'USER_NAVIGATION.HOME'
      },
      {
        href: '/user/clients',
        icon: ClientsIcon,
        title: 'USER_NAVIGATION.MY_CLIENTS'
      },
      {
        href: '/user/distributor',
        icon: DistributorIcon,
        title: 'USER_NAVIGATION.MY_COMPANY'
      },
      {
        href: '/user/quotes',
        icon: RequestQuoteIcon,
        title: 'USER_NAVIGATION.MY_QUOTES'
      }
    ]
  }
];

const getUserNavigationRoutes = (role, status) => {
  if (status === UserStatus.PENDING) {
    return [
      {
        title: 'NAVIGATION.GENERAL',
        children: [
          {
            href: '/user/home',
            icon: HomeIcon,
            title: 'USER_NAVIGATION.HOME',
            disabled: true
          }
        ]
      }
    ];
  }
  if (role === AgentRoles.DIRECTOR) {
    return distributorDirectorRoutes;
  }

  if (role === AgentRoles.KAM) {
    return distributorKamRoutes;
  }

  return [];
};

export default getUserNavigationRoutes;
