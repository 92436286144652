import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import BillingRow from './BillingRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ billings, t }) =>
  billings.map(billing => <BillingRow key={billing.id} t={t} {...billing} />);

const BillingTable = ({
  className,
  billings,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="period" align="center">
          {t('BILLING_VIEW.CONTRACT_ID')}
        </TableCell>

        <TableCell variant="head" key="period" align="center">
          {t('BILLING_VIEW.PERIOD')}
        </TableCell>

        <TableCell variant="head" key="client" align="center">
          {t('COMMON.CLIENT')}
        </TableCell>

        <TableCell variant="head" key="equipment" align="center">
          {t('COMMON.EQUIPMENT')}
        </TableCell>

        <TableCell variant="head" key="initialPayment" align="center">
          {t('BILLING_VIEW.INITIAL_PAYMENT')}
        </TableCell>

        <TableCell variant="head" key="rent" align="center">
          {t('BILLING_VIEW.RENT')}
        </TableCell>

        <TableCell variant="head" key="contribution" align="center">
          {t('BILLING_VIEW.CONTRIBUTION')}
        </TableCell>

        <TableCell variant="head" key="montylyPrincipal" align="center">
          {t('BILLING_VIEW.MONTHLY_PRINCIPAL')}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(() => () => <Tbody t={t} billings={billings} />, [
    billings,
    t
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, billings]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

BillingTable.propTypes = {
  className: PropTypes.string,
  billings: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

BillingTable.defaultProps = {
  count: 0
};

export default BillingTable;
