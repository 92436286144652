import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import { useTranslation } from 'react-i18next';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { useSelector } from 'react-redux';
import { selectBusinessQueryOptions } from 'src/store/selectors/businessSelectors';
import { MetricsViewOptions } from 'src/utils/constants/business';

const MetricsRow = ({
  id,
  name,
  quotesInReview,
  quotesAccepted,
  quotesRejected,
  quotesArchived,
  quotesInDraft
}) => {
  const { t } = useTranslation();
  const { metricsViewOption } = useSelector(selectBusinessQueryOptions);

  const nameLinkRedirect =
    metricsViewOption === MetricsViewOptions.DISTRIBUTOR
      ? `/distributors/business/${id}`
      : `/distributor-profile/${id}`;

  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>
        <Link to={nameLinkRedirect}>{name}</Link>
      </TableCell>
      <TableCell align="center">
        {quotesInReview && (
          <TooltipTypography
            title={`${quotesInReview.count} ${t('COMMON.REQUESTS')}`}
            text={formatPositiveAmount(quotesInReview.amount)}
          />
        )}
      </TableCell>

      <TableCell align="center">
        {quotesInDraft && (
          <TooltipTypography
            title={`${quotesInDraft.count} ${t('COMMON.QUOTES')}`}
            text={formatPositiveAmount(quotesInDraft.amount)}
          />
        )}
      </TableCell>

      <TableCell align="center">
        {quotesAccepted && (
          <TooltipTypography
            title={`${quotesAccepted.count} ${t('COMMON.CONTRACTS')}`}
            text={formatPositiveAmount(quotesAccepted.amount)}
          />
        )}
      </TableCell>

      <TableCell align="center">
        {quotesRejected && (
          <TooltipTypography
            title={`${quotesRejected.count} ${t('COMMON.QUOTES')}`}
            text={formatPositiveAmount(quotesRejected.amount)}
          />
        )}
      </TableCell>

      <TableCell align="center">
        {quotesArchived && (
          <TooltipTypography
            title={`${quotesArchived.count} ${t('COMMON.QUOTES')}`}
            text={formatPositiveAmount(quotesArchived.amount)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

MetricsRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  quotesInReview: PropTypes.object.isRequired,
  quotesAccepted: PropTypes.object.isRequired,
  quotesRejected: PropTypes.object.isRequired,
  quotesArchived: PropTypes.object.isRequired,
  quotesInDraft: PropTypes.object.isRequired
};

export default MetricsRow;
