import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(3)}px`,
    fontFamily: 'Roboto'
  },
  root: {
    minWidth: theme.spacing(68)
  },
  box: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`
  },
  buttons: {
    color: theme.palette.primary.main
  },
  closeIcon: {
    float: 'right',
    width: '15px',
    height: '15px'
  }
}));
