import React from 'react';
import PropTypes from 'prop-types';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepSeven from './StepSeven';
import StepEight from './StepEight';
import StepNine from './StepNine';
import StepTen from './StepTen';

const CurrentStep = ({ activeStep, data, onNext, onBack, onSubmit }) => {
  if (activeStep === 1) {
    return <StepOne data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 2) {
    return <StepTwo data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 3) {
    return <StepThree data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 4) {
    return <StepFour data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 5) {
    return <StepFive data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 6) {
    return <StepSix data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 7) {
    return <StepSeven data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 8) {
    return <StepEight data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 9) {
    return <StepNine data={data} handleNext={onNext} handleBack={onBack} />;
  }
  if (activeStep === 10) {
    return <StepTen data={data} onSubmit={onSubmit} handleBack={onBack} />;
  }

  return <StepTen data={data} onSubmit={onSubmit} handleBack={onBack} />;
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CurrentStep;
