import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  Card,
  CardHeader,
  Divider,
  CardContent
} from '@mui/material';
import DetailRow from 'src/components/DetailRow';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import useStyles from './styles';

const ValidationDetail = ({ validation }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.tableContainer}>
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.MOFFIN_DETAIL.MOFFIN_VALIDATION_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent sx={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            {validation.causes &&
              validation.causes.map((cause, index) => (
                <DetailRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  name={`${t('CLIENTS_VIEW.MOFFIN_DETAIL.CAUSE')} ${index + 1}`}
                  value={cause.description || t('COMMON.NO_DATA')}
                />
              ))}
            <DetailRow
              name={t('CLIENTS_VIEW.MOFFIN_DETAIL.BUREAU_REPORT')}
              value={<DocumentLink link={validation.documentReport} />}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

ValidationDetail.propTypes = {
  validation: PropTypes.object.isRequired
};

export default ValidationDetail;
