import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { loadDistributors } from 'src/store/actions/distributorActions';
import CurrentStep from './CurrentStep';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`
    }
  },
  root: {
    minWidth: theme.spacing(80)
  },
  closeIcon: {
    float: 'right',
    marginTop: '-25px',
    width: '20px',
    height: '20px'
  }
}));

const makeInitialState = () => {
  return {
    distributor: '',
    distributorEquipment: '',
    invoicePdfUrl: '',
    invoiceXmlUrl: '',
    serialNumber: '',
    price: 0,
    iva: 0
  };
};

const stepperLabels = [
  'EQUIPMENT_VIEW.CREATION_FORM.EQUIPMENT_DETAIL',
  'EQUIPMENT_VIEW.CREATION_FORM.DUCUMENTATION',
  'COMMON.CONFIRMATION'
];

const EquipmentsForm = ({ open, handleClose, handleCreateDevice }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);

  const resetRef = useRef();

  useEffect(() => {
    if (open) {
      dispatch(loadDistributors({}, false));
    }
    return () => {
      setStep(0);
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleCloseModal = useCallback(() => {
    if (resetRef.current) {
      resetRef.current();
    }
    setData(initialState);
    handleClose();
  }, [handleClose]);

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      if (resetRef.current) {
        resetRef.current();
      }
      handleClose();
      setData(initialState);
    } else {
      setData({ ...data });
      setStep(step - 1);
    }
  }, [dispatch, step, setStep, handleClose, resetRef]);

  const handleSubmit = useCallback(
    value => {
      handleCreateDevice({ ...data, ...value }, () => setData(initialState));
    },
    [data]
  );

  if (!open) return null;

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-gps-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-gps-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseModal}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('EQUIPMENT_VIEW.CREATION_FORM.TITLE')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <FormStepper index={step} labels={stepperLabels} />
          <CurrentStep
            resetRef={resetRef}
            activeStep={step}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            setData={setData}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
EquipmentsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateDevice: PropTypes.func.isRequired
};

export default EquipmentsForm;
