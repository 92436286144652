export const selectCFDIS = state => state.cfdis;
export const selectCFDISList = state => selectCFDIS(state).items;
export const selectCFDISListCount = state => selectCFDIS(state).count;
export const selectCFDISQueryOptions = state => {
  const { options } = selectCFDIS(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

// cfdi income selectors

export const selectCFDIsIncome = state => selectCFDIS(state).income;
export const selectCFDIsIncomeList = state => selectCFDIsIncome(state).items;
export const selectCFDIsIncomeListCount = state =>
  selectCFDIsIncome(state).count;

// Withholdings selectors

export const selectWithholdings = state => selectCFDIS(state).withholdings;
export const selectWithholdingsList = state => selectWithholdings(state).items;
export const selectWithholdingsCount = state => selectWithholdings(state).count;
// cfdi expense selectors

export const selectCFDIsExpense = state => selectCFDIS(state).expense;
export const selectCFDIsExpenseList = state => selectCFDIsExpense(state).items;
export const selectCFDIsExpenseListCount = state =>
  selectCFDIsExpense(state).count;
// cfdi payment selectors

export const selectCFDIsPayment = state => selectCFDIS(state).payment;
export const selectCFDIsPaymentList = state => selectCFDIsPayment(state).items;
export const selectCFDIsPaymentListCount = state =>
  selectCFDIsPayment(state).count;

// cfdi received selectors

export const selectCFDIsReceived = state => selectCFDIS(state).received;
export const selectCFDIsReceivedList = state =>
  selectCFDIsReceived(state).items;
export const selectCFDIsReceivedListCount = state =>
  selectCFDIsReceived(state).count;
export const selectCFDIsReceivedLastSync = state =>
         selectCFDIsReceived(state).lastSync;
