import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import CapitalBalanceRow from './CapitalBalanceRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ capitalBalance, t }) =>
  capitalBalance.map(capital => (
    <CapitalBalanceRow key={capital.id} t={t} {...capital} />
  ));

const CapitalBalanceTable = ({
  className,
  capitalBalance,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow sx={{ '& .MuiTableCell-head': { whiteSpace: 'nowrap' } }}>
        <TableCell
          variant="head"
          key="client"
          style={{ width: '10%' }}
          align="center"
        >
          {t('COMMON.CLIENT')}
        </TableCell>

        <TableCell variant="head" key="client" align="center">
          {t('COMMON.CONTRACT')}
        </TableCell>

        <TableCell variant="head" key="equipment" align="center">
          {t('COMMON.EQUIPMENT')}
        </TableCell>

        <TableCell
          variant="head"
          key="period"
          align="center"
          style={{ width: '5%' }}
        >
          {t('BILLING_VIEW.PERIOD')}
        </TableCell>

        <TableCell
          variant="head"
          key="initialBalance"
          align="center"
          style={{ wrap: 'no-wrap' }}
        >
          {t('CAPITAL_BALANCE.INITIAL_BALANCE')}
        </TableCell>

        <TableCell variant="head" key="equipment" align="center">
          {t('COMMON.CONTRACT_REGISTRATION')}
        </TableCell>

        <TableCell variant="head" key="subscription" align="center">
          {t('CAPITAL_BALANCE.INITIAL_PAYMENT')}
        </TableCell>

        <TableCell variant="head" key="subscription" align="center">
          {t('CAPITAL_BALANCE.CAPITAL_PAYMENT')}
        </TableCell>

        <TableCell variant="head" key="capitalContribution" align="center">
          {t('CAPITAL_BALANCE.CAPITAL_CONTRIBUTION')}
        </TableCell>

        <TableCell variant="head" key="residualPayment" align="center">
          {t('CAPITAL_BALANCE.RESIDUAL_PAYMENT')}
        </TableCell>

        <TableCell variant="head" key="finalBalance" align="center">
          {t('CAPITAL_BALANCE.FINAL_BALANCE')}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(
    () => () => <Tbody t={t} capitalBalance={capitalBalance} />,
    [capitalBalance, t]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, capitalBalance]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CapitalBalanceTable.propTypes = {
  className: PropTypes.string,
  capitalBalance: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

CapitalBalanceTable.defaultProps = {
  count: 0
};

export default CapitalBalanceTable;
