/* eslint-disable import/prefer-default-export */
import qs from 'qs';
import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getKams = serviceBase({
  request: options =>
    axiosClient.get('/distributor-profile/kam/list', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const updateKamById = serviceBase({
  request: ({ id, values }) =>
    axiosClient.patch(`/distributor-profile/kam/${id}`, values)
});

export const createKam = serviceBase({
  request: values => axiosClient.post('/distributor-profile/kam', values)
});

export const getKamById = serviceBase({
  request: id => axiosClient.get(`/distributor-profile/kam/${id}`)
});

export const reassignClient = serviceBase({
  request: values =>
    axiosClient.post('/distributor-profile/client/reassign', values)
});

export const downloadExportedKams = serviceBase({
  request: options =>
    axiosClient.get('/distributor-profile/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Listado de KAMs.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.error(error);
    }
  },
  retry: 0
});

export const acceptRegistrationRequest = serviceBase({
  request: ({ id, distributorId }) =>
    axiosClient.post(`/distributor-profile/${id}/registration-request/accept`, {
      distributorId
    })
});

export const rejectRegistrationRequest = serviceBase({
  request: ({ id, rejectionReason }) =>
    axiosClient.post(`/distributor-profile/${id}/registration-request/reject`, {
      rejectionReason
    })
});
