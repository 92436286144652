import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadIncomeResume } from 'src/store/actions/incomeResumeActions';
import { selectIncomeResumeQueryOptions } from 'src/store/selectors/incomeResumeSelector';
import TimePeriod from 'src/utils/TimePeriod';
import IncomeResumeList from './IncomeResumeList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function selectTimePeriod(value) {
  switch (Number(value)) {
    case 0:
      return TimePeriod.MONTH;
    case 1:
      return TimePeriod.QUARTER;
    case 2:
      return TimePeriod.YEAR;
    default:
      return TimePeriod.MONTH;
  }
}

const IncomeResumeListTabs = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const queryOpts = useSelector(selectIncomeResumeQueryOptions);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadIncomeResume());
  }, []);

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadIncomeResume({
          ...queryOpts,
          period: selectTimePeriod(newValue)
        })
      );
    },
    [setTabIndex, dispatch, queryOpts]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.MONTHLY')} />
          <Tab label={t('COMMON.QUARTERLY')} />
          <Tab label={t('COMMON.YEARLY')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <IncomeResumeList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <IncomeResumeList />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <IncomeResumeList />
      </TabPanel>
    </Box>
  );
};

export default IncomeResumeListTabs;
