import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmout';

const ContractEquipmentDetail = ({ contract }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.CONTRACT')}
        value={
          <Link to={`/contracts/${contract.contractId}`}>
            {contract.contractId}
          </Link>
        }
      />
      <DetailRow
        name={t('COMMON.CLIENT')}
        value={
          <Link to={`/clients/detail/${contract.clientId}`}>
            {contract.client}
          </Link>
        }
      />
      <DetailRow
        name={t('COMMON.RENT')}
        value={formatPositiveAmount(contract.rent)}
      />

      <DetailRow
        name={t('COMMON.STATUS')}
        value={t(`COMMON.${contract.assignationStatus}`)}
      />
    </>
  );
};

ContractEquipmentDetail.propTypes = {
  contract: PropTypes.object.isRequired
};
export default ContractEquipmentDetail;
