const makeInitialState = () => {
  return {
    rfc: '',
    name: '',
    maternalName: '',
    paternalName: '',
    phone: '',
    email: '',
    personType: '',
    agent: '',
  };
};

export default makeInitialState;
