import React from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { useTranslation } from 'react-i18next';
import BillingFailureRowMenu from './BillingFailureRowMenu';

const BillingFailuresRow = ({
  id,
  client,
  clientId,
  amount,
  date,
  category,
  billingLog
}) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell
        style={{
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {client && client.length > 45 ? (
          <Tooltip title={client}>
            <Link to={`/clients/detail/${clientId}`}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.43'
                }}
              >
                {`${client.slice(0, 45)}...`}
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {client}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(amount)}</TableCell>
      <TableCell align="center">
        {selectFormatDate(date, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center">
        {t(`TRANSACTION.CATEGORY.${category}`)}
      </TableCell>
      <TableCell align="center">
        {billingLog && billingLog.length > 45 ? (
          <Tooltip title={billingLog}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {`${billingLog.slice(0, 45)}...`}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            {billingLog}
          </Typography>
        )}
      </TableCell>
      <TableCell align="right">
        <BillingFailureRowMenu id={id} />
      </TableCell>
    </TableRow>
  );
};

BillingFailuresRow.propTypes = {
  id: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  billingLog: PropTypes.string.isRequired
};

export default BillingFailuresRow;
