import React, { useEffect } from 'react';
import FadeIn from 'react-fade-in';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import Footer from 'src/components/Footer';
import { push } from 'connected-react-router';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  USER_EMAIL_VERIFICATION,
  userVerification
} from 'src/store/actions/authActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from 'src/components/Loading';
import { UserTypePath } from 'src/utils/constants/auth';
import useStyles from '../styles';

const UserVerificationView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingVerification = useSelector(
    isLoadingSelector([USER_EMAIL_VERIFICATION.action])
  );
  const successVerification = useSelector(
    successSelector([USER_EMAIL_VERIFICATION.action])
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const { pathname } = location;
    const userType = UserTypePath[pathname.split('/')[1]];
    if (token) {
      dispatch(userVerification(token, userType));
    } else {
      push('/user/login');
    }
  }, []);

  return (
    <MainLayout>
      <Page
        className={classes.userVerifictionRoot}
        title={t(`AUTH_VIEWS.USER_VERIFICATION_TITLE`)}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <div style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </div>
          <Container className={classes.userVerifictionContainter}>
            {successVerification && !loadingVerification ? (
              <FadeIn transitionDuration={200}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10%'
                  }}
                >
                  <VerifiedUserIcon className={classes.verifiedIcon} />
                </Box>
                <Typography className={classes.verifiedText}>
                  {t(`AUTH_VIEWS.USER_VERIFICATION_DESCRIPTION`)}
                </Typography>
              </FadeIn>
            ) : (
              <Loading label={t('AUTH_VIEWS.THE_EMAIL_IS_BEING_VERIFIED')} />
            )}
          </Container>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default UserVerificationView;
