import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { editNuFiParameterAction } from 'src/store/actions/parameterActions';
import NufiParameterRow from './NufiParameterRow';
import EditNufiParameterDialog from './EditNufiParametersDialog/EditNufiParametersDialog';

const NufiParameterTable = ({
  className,
  parameters,
  validateTheJudicialRecords,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const openEditBlock = useCallback(() => {
    setOpen(true);
  });

  const handleCloseEditBlock = useCallback(() => {
    setOpen(false);
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editNuFiParameterAction(values));
      handleCloseEditBlock();
    },
    [handleCloseEditBlock, dispatch]
  );

  const handleOpenEdit = () => {
    openEditBlock();
  };

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell variant="head" sx={{ width: '70%' }}>
        {t('PARAMETERS_VIEW.NAME')}
      </TableCell>
      <TableCell variant="head" sx={{ width: '25%' }}>
        {t('PARAMETERS_VIEW.VALUE')}
      </TableCell>
      <TableCell variant="head" align="rigth" sx={{ width: '5%' }}>
        <MoreMenu handleClickOption={handleOpenEdit} deleteDisabled />
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <NufiParameterRow {...parameter} key={parameter.id} />
      ));
    },
    [parameters]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table tbody={tbody} theads={theads} {...rest} />
      </Grid>
      <EditNufiParameterDialog
        parameterList={parameters}
        open={open}
        handleClose={handleCloseEditBlock}
        handleEdit={handleEdit}
        validateTheJudicialRecords={validateTheJudicialRecords}
      />
    </Grid>
  );
};

NufiParameterTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  validateTheJudicialRecords: PropTypes.bool
};

export default NufiParameterTable;
