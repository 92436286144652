import React from 'react';
import FadeIn from 'react-fade-in';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import AvalDetailTabs from 'src/views/client/AvalDetail/AvalDetailTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const UserAvalDetailView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={`${t('COMMON.AVAL')}`}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            <AvalDetailTabs />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default UserAvalDetailView;
