export const AmortizationBalanceType = {
  POLICY_PRICE: 'POLICY_PRICE',
  ACUMULATED_AMORTIZATION: 'ACUMULATED_AMORTIZATION'
};

export const RentResumeBalanceType = {
  CLIENT: 'CLIENT',
  CONTRACT: 'CONTRACT'
}

export default AmortizationBalanceType;
