import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { PersonType } from 'src/utils/constants/clients';
import AvalDetailRow from './AvalDetailRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ avals, isAdminView, clientPersonType }) =>
  avals.map(aval => (
    <AvalDetailRow
      key={aval.id}
      isAdminView={isAdminView}
      clientPersonType={clientPersonType}
      {...aval}
    />
  ));

const AvalDetailTable = ({
  className,
  avals,
  clientPersonType,
  loading,
  onChangePage,
  page,
  count,
  isAdminView = true,
  ...rest
}) => {
  const { t } = useTranslation();

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={onChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, avals]
  );

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell variant="head" align="left" style={{ width: '20%' }}>
        {t('COMMON.NAME')}
      </TableCell>
      <TableCell variant="head" align="left" style={{ width: '20%' }}>
        {t('COMMON.EMAIL')}
      </TableCell>
      <TableCell variant="head" align="center" style={{ width: '20%' }}>
        {t('COMMON.PHONE')}
      </TableCell>
      {isAdminView && (
        <TableCell
          variant="head"
          key="audit"
          align="center"
          style={{ width: '20%' }}
        >
          {t('COMMON.PENDING')}
        </TableCell>
      )}
      {clientPersonType === PersonType.LEGAL && (
        <>
          <TableCell variant="head" align="center" style={{ width: '15%' }}>
            {t('COMMON.LEGAL_REPRESENTATIVE')}
          </TableCell>
          <TableCell variant="head" align="center" style={{ width: '5%' }}>
            {' '}
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody
        avals={avals}
        isAdminView={isAdminView}
        clientPersonType={clientPersonType}
      />
    ),
    [avals]
  );

  if (!loading && avals.length === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.AVALS_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

AvalDetailTable.propTypes = {
  className: PropTypes.string,
  avals: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  isAdminView: PropTypes.bool,
  clientPersonType: PropTypes.string
};

export default AvalDetailTable;
