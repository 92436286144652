export const getLocalStorage = key => {
  return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = key => {
  localStorage.removeItem(key);
};

export const getLanguage = () => {
  return localStorage.getItem('lang');
};

export const localStorageKey = {
  NAVBAR_OPTIONS: 'NAVBAR_OPTIONS'
};
