import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { loadRentAfterContribution } from 'src/store/actions/contractActions';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepOne = ({ data, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const calculateRentAferContribution = useCallback(
    amount => {
      dispatch(loadRentAfterContribution(id, amount));
    },
    [id, dispatch]
  );

  const onNext = useCallback(
    values => {
      calculateRentAferContribution(values.amount);
      handleNext({ ...values });
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              helperText={touched.amount && t(errors.amount)}
              label={t('COMMON.AMOUNT_WITH_IVA')}
              margin="normal"
              name="amount"
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              show
              variant="outlined"
            />

            <Box display="flex">
              <Button
                className={clsx(classes.buttonFirstStep, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func
};

export default StepOne;
