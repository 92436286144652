import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .required('SCHEMA_ERRORS.NAME_REQUIRED')
    .max(30),
  officePhone: Yup.string()
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
    .length(12, 'SCHEMA_ERRORS.PHONE_LENGTH'),
  phone: Yup.string()
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', value => {
      const phoneNumber = isValidPhoneNumber(`+${value}`);
      return phoneNumber;
    })
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
});
