import { TableCell as MuiTableCell } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

import { withStyles } from '@mui/styles';

const TableCell = withStyles(theme =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      fontSize: theme.spacing(1.8),
      letterSpacing: 0.2
    },
    root: {
      borderBottom: '1px solid rgba(43,45,66,0.1)'
    }
  })
)(MuiTableCell);

export default TableCell;
