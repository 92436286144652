import {
  CLEAR_EQUIPMENTS,
  CLEAR_OPTIONS,
  LOAD_EQUIPMENTS,
  LOAD_EQUIPMENT_DETAIL,
  SAVE_OPTIONS
} from '../actions/equipmentsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  detail: null
};

const EquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EQUIPMENTS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_EQUIPMENT_DETAIL.success: {
      return {
        ...state,
        detail: action.data
      };
    }
    case LOAD_EQUIPMENT_DETAIL: {
      return {
        ...state,
        detail: null
      };
    }
    case SAVE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) }
      };
    }
    case CLEAR_OPTIONS: {
      return {
        ...state,
        options: {
          ...initialState.options
        }
      };
    }
    case CLEAR_EQUIPMENTS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default EquipmentReducer;
