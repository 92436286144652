import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType } from 'src/utils/constants/clients';
import { useParams } from 'react-router';
import selectDocumentType from 'src/utils/selectDocumentType';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';

const DocumentInvestorInfo = ({ investor }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const hideIfe = investor.documents.some(
    document => document.documentType === DocumentType.PASSPORT
  );

  const hidePassport = investor.documents.some(
    document => document.documentType === DocumentType.IFE_FRONT
  );

  const showHidedDocuments = hideIfe && hidePassport;

  return (
    <>
      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.FONT_IFE')}
        sx={{
          display: hideIfe && !showHidedDocuments ? 'none' : 'block'
        }}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.IFE_FRONT,
              investor.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledAdd={hideIfe}
          documentType={DocumentType.IFE_FRONT}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.IFE_FRONT, investor.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.BACK_IFE')}
        sx={{
          display: hideIfe && !showHidedDocuments ? 'none' : 'block'
        }}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.IFE_BACK, investor.documents)}
          />
        }
      >
        <DocumentsMenu
          documents={Boolean(
            selectDocumentType(DocumentType.IFE_BACK, investor.documents)
          )}
          documentType={DocumentType.IFE_BACK}
          investorId={id}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.PASSPORT')}
        sx={{
          display: hidePassport && !showHidedDocuments ? 'none' : 'block'
        }}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.PASSPORT, investor.documents)}
          />
        }
      >
        <DocumentsMenu
          isDisabledAdd={hidePassport}
          documentType={DocumentType.PASSPORT}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.PASSPORT, investor.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('INVESTORS_VIEW.INVESTOR_DETAIL.DOCUMENTS.ADDRESS_PROOF')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.ADDRESS_PROOF,
              investor.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.ADDRESS_PROOF}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.ADDRESS_PROOF, investor.documents)
          )}
        />
      </DetailRow>
      <DetailRow
        name={t('INVESTORS_VIEW.INVESTOR_DETAIL.DOCUMENTS.RFC_PROOF')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.RFC_PROOF,
              investor.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.RFC_PROOF}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.RFC_PROOF, investor.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('INVESTORS_VIEW.INVESTOR_DETAIL.DOCUMENTS.BANK_COVER')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.BANK_COVER,
              investor.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.BANK_COVER}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.BANK_COVER, investor.documents)
          )}
        />
      </DetailRow>
      <DetailRow
        name={t('INVESTORS_VIEW.INVESTOR_DETAIL.DOCUMENTS.RFC_COMPLIANCE')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.RFC_COMPLIANCE,
              investor.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.RFC_COMPLIANCE}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.RFC_COMPLIANCE, investor.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('INVESTORS_VIEW.INVESTOR_DETAIL.DOCUMENTS.KYC')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.KYC, investor.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.KYC}
          investorId={id}
          documents={Boolean(
            selectDocumentType(DocumentType.KYC, investor.documents)
          )}
        />
      </DetailRow>
    </>
  );
};

DocumentInvestorInfo.propTypes = {
  investor: PropTypes.object.isRequired
};

export default DocumentInvestorInfo;
