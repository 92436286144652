import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getTenureFromDate } from 'src/utils/investors';
import { formatAmount } from 'src/utils/formatAmout';

const InvestorRow = ({
  tenure,
  fullName,
  id,
  investment,
  ownership,
  personType
}) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/investors/${id}`}>{`${id}`}</Link>
      </TableCell>
      <TableCell align="center">{fullName}</TableCell>
      <TableCell align="center">
        {t(`ENUMS.PERSON_TYPE.${personType}`)}
      </TableCell>
      <TableCell align="center">
        {tenure ? getTenureFromDate(tenure, t) : t('COMMON.NO_DATA')}
      </TableCell>
      <TableCell align="center">{formatAmount(investment)}</TableCell>
      <TableCell align="center">
        {ownership ? `${(ownership * 100).toFixed(2)}%` : 0}
      </TableCell>
    </TableRow>
  );
};

InvestorRow.propTypes = {
  tenure: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ownership: PropTypes.number.isRequired,
  personType: PropTypes.string.isRequired,
  investment: PropTypes.number.isRequired
};

export default InvestorRow;
