import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  TextField,
  Slide,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';
import validationSchema from './schema';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const LinesOfBusinessDialog = ({ open, handleClose, handleSave, linesOfBusiness, firstOption }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const resetFormRef = useRef();
  
    const initialFormValues = {
      lineOfBusiness: linesOfBusiness.find((item) => item.name === firstOption) || null,
    };
  
    const onSubmit = (values, { resetForm }) => {
      handleSave(values.lineOfBusiness);
      resetForm(initialFormValues);
      handleClose();
    };
  
    useEffect(() => {
      return () => {
        if (resetFormRef.current) {
          resetFormRef.current();
        }
      };
    }, [resetFormRef, open]);
  
    return (
      <Dialog
        classes={{ paper: classes.root }}
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle>
          <IconButton
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="primary" variant="h2">
            {t('COMMON.SELECT_LINE_OF_BUSINESS')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleSubmit, touched, values, setFieldValue, resetForm }) => {
              resetFormRef.current = () => resetForm();
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.lineOfBusiness && errors.lineOfBusiness)}
                  >
                    <Autocomplete
                      options={linesOfBusiness}
                      getOptionLabel={(option) => option.name || ''}
                      id="lineOfBusiness"
                      value={values.lineOfBusiness || null}
                      onChange={(_e, value) => setFieldValue('lineOfBusiness', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('COMMON.LINE_OF_BUSINESS')}
                          variant="outlined"
                          error={Boolean(touched.lineOfBusiness && errors.lineOfBusiness)}
                          helperText={touched.lineOfBusiness && t(errors.lineOfBusiness)}
                        />
                      )}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                  <Button
                    className={classes.saveButton}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };
  

LinesOfBusinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  linesOfBusiness: PropTypes.array.isRequired,
  firstOption: PropTypes.string.isRequired,
};

export default LinesOfBusinessDialog;
