import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CLEAR_CATALOG,
  loadAgentsList,
  loadInsurersList,
  LOAD_CATALOG
} from 'src/store/actions/catalogActions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAgentsList,
  selectInsurersList
} from 'src/store/selectors/catalogSelectors';
import Preloader from 'src/components/Preloader';
import UploadFile from 'src/components/UploadFile';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import {
  LOAD_PARAMETER,
  loadParameter
} from 'src/store/actions/parameterActions';
import {
  calculatePriceWhitIva,
  getDifferenceBetweenStates
} from 'src/utils/common';
import { Parameter } from 'src/utils/constants/parameters';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import useStyles from '../../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InsuranceDetailDialog = ({ open, equipment, handleClose, submit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const percentageIva = useSelector(selectIndividualParameter);
  const initialState = useMemo(
    () => makeInitialState({ ...equipment, iva: percentageIva }),
    [equipment, percentageIva]
  );
  const resetRef = useRef();
  const { t } = useTranslation();

  const agencies = useSelector(selectAgentsList);
  const insurers = useSelector(selectInsurersList);
  const loadingAgents = useSelector(isLoadingSelector([LOAD_CATALOG.action]));
  const loadingInsurers = useSelector(isLoadingSelector([LOAD_CATALOG.action]));
  const loadingIvaParameter = useSelector(
    isLoadingSelector([LOAD_PARAMETER.action])
  );
  const policyExists = equipment.insurance && true;

  const insurerNameList = insurers
    ? insurers?.map(insurer => {
        return {
          key: insurer.id,
          name: insurer.name
        };
      })
    : [];

  const agencyNameList = agencies
    ? agencies.map(agency => {
        return {
          key: agency.id,
          name: agency.name
        };
      })
    : [];

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }

      submit({ ...difference, iva: values.iva });
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  const handleUploadChange = useCallback(
    (setFieldValue, name) => e => {
      if (e.target.files[0]) {
        setFieldValue(name, e.target.files[0]);
      }
    },
    []
  );

  useEffect(() => {
    if (open) {
      dispatch(loadAgentsList());
      dispatch(loadInsurersList());
      dispatch(loadParameter(Parameter.IVA));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
      dispatch({ type: CLEAR_CATALOG });
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('EQUIPMENT_VIEW.EQUIPMENT_DETAIL.INSURANCE_DETAILS')}
        </Typography>
      </DialogTitle>
      {loadingAgents || loadingInsurers || loadingIvaParameter ? (
        <Preloader style={{ height: '50vh' }} />
      ) : (
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.insurerId && errors.insurerId)}
                  >
                    <Autocomplete
                      options={insurerNameList}
                      id="insurerId"
                      getOptionLabel={option => {
                        return option ? option.name : '';
                      }}
                      defaultValue={values.agencyId}
                      value={values.insurerId ?? null}
                      keepMounted
                      fullWidth
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(_e, value) => {
                        setFieldValue('insurerId', value ?? null);
                      }}
                      helperText={touched.insurerId && t(errors.insurerId)}
                      label={t('INSURANCES_VIEW.INSURER')}
                      error={Boolean(touched.insurerId && errors.insurerId)}
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={Boolean(touched.agencyId && errors.agencyId)}
                  >
                    <Autocomplete
                      options={agencyNameList}
                      id="agencyId"
                      value={values.agencyId ?? null}
                      getOptionLabel={option => {
                        return option.name;
                      }}
                      fullWidth
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      onChange={(_e, value) => {
                        setFieldValue('agencyId', value ?? null);
                      }}
                      helperText={touched.agencyId && t(errors.agencyId)}
                      label={t('INSURANCES_VIEW.AGENCY')}
                      error={Boolean(touched.agencyId && errors.agencyId)}
                    />
                  </FormControl>

                  <DatePicker
                    error={Boolean(
                      touched.coverageStart && errors.coverageStart
                    )}
                    label={t('INSURANCES_VIEW.COVERAGE_START_DATE')}
                    value={values.coverageStart}
                    inputFormat="DD/MM/YYYY"
                    margin="normal"
                    onChange={newValue => {
                      setFieldValue('coverageStart', newValue);
                    }}
                    disableFuture
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        sx={{ width: '100%', margin: '16px 0' }}
                        helperText={
                          touched.coverageStart && t(errors.coverageStart)
                        }
                      />
                    )}
                  />

                  <DatePicker
                    error={Boolean(touched.coverageEnd && errors.coverageEnd)}
                    label={t('INSURANCES_VIEW.COVERAGE_END_DATE')}
                    value={values.coverageEnd}
                    minDate={values.coverageStart}
                    disabled={values.coverageStart === ''}
                    inputFormat="DD/MM/YYYY"
                    margin="normal"
                    onChange={newValue => {
                      setFieldValue('coverageEnd', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        sx={{ width: '100%', margin: '16px 0' }}
                        helperText={
                          touched.coverageEnd && t(errors.coverageEnd)
                        }
                      />
                    )}
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.price && errors.price)}
                    fullWidth
                    helperText={touched.price && t(errors.price)}
                    label={t('INSURANCES_VIEW.PRICE')}
                    margin="normal"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.price}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    fullWidth
                    label={`${t('COMMON.IVA')} %`}
                    margin="normal"
                    name="iva"
                    disabled
                    type="text"
                    value={formatAmountWithDecimals(Number(values.iva))}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    disabled
                    fullWidth
                    label={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
                    margin="normal"
                    name="priceWhitIva"
                    type="text"
                    value={formatAmountWithDecimals(
                      calculatePriceWhitIva(values.price, values.iva)
                    )}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.policyId && errors.policyId)}
                    fullWidth
                    helperText={touched.policyId && t(errors.policyId)}
                    label={t('INSURANCES_VIEW.POLICY_NUMBER')}
                    margin="normal"
                    name="policyId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.policyId}
                    variant="outlined"
                    disabled={policyExists}
                  />

                  <UploadFile
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleUploadChange={handleUploadChange}
                    setFieldValue={setFieldValue}
                    accept={{
                      'application/pdf': ['.pdf']
                    }}
                    valueLabel="policyPdfUrl"
                    textLabel={t('COMMON.UPLOAD_PDF_POLICY')}
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      )}
    </Dialog>
  );
};

InsuranceDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  equipment: PropTypes.object
};

export default InsuranceDetailDialog;
