/* eslint-disable react/prop-types */
import React from 'react';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import AgentsListView from 'src/views/catalog/AgentsListView';
import AdminPrivateRoute from 'src/components/PrivateRotes/AdminPrivateRoute';
import BrandsListView from './BrandsListView';
import EquipmentTypesListView from './EquipmentTypesListView';
import CompaniesListView from './CompaniesListView';
import InsurersListView from './InsurersListView';
import CatalogListView from './CatalogListView';
import IncidentsListView from './IncidentsListView';
import ServicesListView from './ServicesListView';
import TaxSystemView from './TaxSystemView';
import BillingConceptView from './BillingConcept';
import DenominationsView from './DenominationView';
import KinshipListView from './KinshipListView';
import IndustriesListView from './IndustriesListView';
import ModelsListView from './ModelsListView';
import AdministrativeRolesListView from './AdministrativeRolesListView';
import EconomicActivitiesView from './EconomicActivitiesListView';
import BanksListView from './BanksListView';
import LineOfBusinessListView from './LineOfBusinessListView';


const CatalogView = ({ match }) => {
  return (
    <>
      <AdminPrivateRoute
        exact
        path={`${match.url}`}
        component={CatalogListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/agents`}
        component={AgentsListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/companies`}
        component={CompaniesListView}
      />
      <PrivateRoute path={`${match.url}/brands`} component={BrandsListView} />
      <AdminPrivateRoute
        path={`${match.url}/equipment-types`}
        component={EquipmentTypesListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/insurers`}
        component={InsurersListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/incidents`}
        component={IncidentsListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/services`}
        component={ServicesListView}
      />
      <AdminPrivateRoute
        path={`${match.url}/tax-system`}
        component={TaxSystemView}
      />
      <AdminPrivateRoute
        path={`${match.url}/billing-concepts`}
        component={BillingConceptView}
      />
      <AdminPrivateRoute
        path={`${match.url}/denominations`}
        component={DenominationsView}
      />
      <PrivateRoute
        path={`${match.url}/kinships`}
        component={KinshipListView}
      />
      <PrivateRoute
        path={`${match.url}/lines-of-business`}
        component={LineOfBusinessListView}
      />
      <PrivateRoute
        path={`${match.url}/industries`}
        component={IndustriesListView}
      />
      <PrivateRoute
        path={`${match.url}/models/:id`}
        component={ModelsListView}
      />
      <PrivateRoute
        path={`${match.url}/administrative-roles`}
        component={AdministrativeRolesListView}
      />
      <PrivateRoute
        path={`${match.url}/economic-activities`}
        component={EconomicActivitiesView}
      />
      <PrivateRoute path={`${match.url}/banks`} component={BanksListView} />
    </>
  );
};

export default CatalogView;
