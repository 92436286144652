import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Edit from '@mui/icons-material/Edit';
import { updateDistributor } from 'src/store/actions/distributorActions';
import DetailCard from 'src/components/DetailBox/DetailCard';
import AddressDialog from 'src/components/AddressDialog/AddressDialog';
import AddressDetailBox from 'src/components/AddressDetailBox/AddressDetailBox';
import useStyles from '../styles';

const DistributorAddress = ({ distributor, isDisabledEdit = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateDistributor(id, { address: values }, () => handleClose()));
    },
    [distributor, dispatch]
  );

  return (
    <DetailCard title={t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_ADDRESS')}>
      <AddressDetailBox address={distributor.address} />
      <AddressDialog
        open={open}
        address={distributor.address}
        handleClose={handleClose}
        submit={handleSubmit}
      />
      <Grid container direction="column" alignItems="stretch">
        <Grid item className={classes.buttons}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            disabled={isDisabledEdit}
            className={clsx(classes.label, classes.buttons)}
            startIcon={<Edit />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Grid>
      </Grid>
    </DetailCard>
  );
};

DistributorAddress.propTypes = {
  distributor: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default DistributorAddress;
