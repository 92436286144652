import { TransactionsCategory } from 'src/utils/constants/transactions';
import * as Yup from 'yup';

export default Yup.object().shape({
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  comment: Yup.string(),
  contract: Yup.object().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  category: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE'),
  continueBilling: Yup.string().when('category', {
    is: TransactionsCategory.UPFRONT_RENT,
    then: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  })
});
