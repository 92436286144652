import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import AdministratorRow from './AdministratorRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ administrators }) =>
  administrators.map(admistrator => (
    <AdministratorRow key={admistrator.id} {...admistrator} />
  ));

const AdministratorsTable = ({
  className,
  administrators,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const minWidth = '9rem';

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="name" align="left">
          {t('COMMON.NAME')}
        </TableCell>
        <TableCell
          variant="head"
          key="email"
          style={{ minWidth }}
          align="center"
        >
          {t('COMMON.EMAIL')}
        </TableCell>

        <TableCell
          variant="head"
          key="phone"
          style={{ minWidth }}
          align="center"
        >
          {t('COMMON.PHONE')}
        </TableCell>
        <TableCell
          variant="head"
          key="email-validated"
          style={{ minWidth }}
          align="center"
        >
          {t('COMMON.EMAIL_VALIDATED')}
        </TableCell>
        <TableCell
          variant="head"
          key="creation-date"
          style={{ minWidth }}
          align="center"
        >
          {t('COMMON.CREATION_DATE')}
        </TableCell>
        <TableCell
          variant="head"
          key="status"
          style={{ minWidth }}
          align="center"
        >
          {t('COMMON.STATUS')}
        </TableCell>
        <TableCell variant="head" key="actions" align="right">
          {}
        </TableCell>
      </TableRow>
    ),
    [classes, t]
  );

  const tbody = useMemo(() => () => <Tbody administrators={administrators} />, [
    administrators
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, administrators]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AdministratorsTable.propTypes = {
  className: PropTypes.string,
  administrators: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

AdministratorsTable.defaultProps = {
  count: 0
};

export default AdministratorsTable;
