import momentLocalTimezone from 'src/utils/momentLocalTimezone';
import * as Yup from 'yup';

export default Yup.object().shape({
  startDate: Yup.date().default(() => momentLocalTimezone().toDate()),
  firstRentDate: Yup.date().default(() =>
    momentLocalTimezone()
      .add(1, 'month')
      .toDate()
  ),
  gracePeriod: Yup.number().default(0),
  endDate: Yup.date().when('term', (term, schema) =>
    schema.default(() =>
      momentLocalTimezone()
        .add(term, 'months')
        .toDate()
    )
  )
});
