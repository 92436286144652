import { LOAD_CLIENT_DISTRIBUTOR_AGENT } from '../actions/distributorActions';
import { CLEAR_KAM_QUOTES, LOAD_KAM_QUOTES } from '../actions/quoteActions';
import {
  LOAD_KAMS,
  SAVE_KAMS_OPTIONS,
  CLEAR_KAMS,
  LOAD_KAM_DETAIL,
  CLEAR_KAM_DETAIL,
  CLEAR_KAM_OPTIONS
} from '../actions/userKamActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    sort: { property: 'id', order: 'asc' }
  },
  items: [],
  count: 0,
  detail: {
    contracts: {
      items: [],
      count: 0
    },
    quotes: {
      items: [],
      count: 0
    },
  },
};

const kamReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_KAMS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_KAM_QUOTES.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          quotes: {
            items: action.items,
            count: action.count
          }
        }
      };
    }
    case CLEAR_KAM_QUOTES: {
      return {
        ...state,
        quotes: {
          items: [],
          count: 0
        }
      };
    }
    case CLEAR_KAM_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case SAVE_KAMS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case LOAD_KAM_DETAIL.success: {
      return {
        ...state,
        detail: { ...action.detail, ...state.detail }
      };
    }
    case LOAD_CLIENT_DISTRIBUTOR_AGENT.success: {
      return {
        ...state,
        detail: { ...state.detail, ...action.detail }
      };
    }
    case CLEAR_KAM_DETAIL: {
      return {
        ...state,
        detail: initialState.detail
      };
    }
    case CLEAR_KAMS: {
      return {
        ...state,
        items: [],
        count: 0
      };
    }

    default: {
      return state;
    }
  }
};

export default kamReducer;
