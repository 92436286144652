import React, { useCallback, useState } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { editParameterAction } from 'src/store/actions/parameterActions';
import { transformArrayToString } from 'src/utils/common';
import ParameterForm from './ParameterForm';
import ParameterTabs from './ParameterTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ParameterListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [dropDownList, setDropDownList] = useState([]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpenEdit = useCallback(
    (values, dropDownListItems) => {
      setOpen(true);
      setData(values);
      setDropDownList(dropDownListItems);
    },
    [setOpen, setData]
  );

  const handleEdit = useCallback(
    values => {
      let newValues = values;

      if (data.type === 'ARRAY') {
        newValues = {
          ...values,
          value: transformArrayToString(values.value)
        };
      }
      dispatch(editParameterAction(newValues));
      handleClose();
    },
    [handleClose, dispatch, data]
  );

  return (
    <Page className={classes.root} title={t('PARAMETERS_VIEW.TITLE')}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <ParameterTabs handleOpenEdit={handleOpenEdit} />
        </Box>
      </Container>
      <ParameterForm
        open={open}
        data={data}
        dropDownList={dropDownList}
        handleClose={handleClose}
        handleEdit={handleEdit}
      />
    </Page>
  );
};

export default ParameterListView;
