import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectEquipmentList,
  selectEquipmentListCount,
  selectEquipmentQueryOptions
} from 'src/store/selectors/equipmentSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  loadEquipment,
  LOAD_EQUIPMENTS
} from 'src/store/actions/equipmentsActions';
import { Card } from '@mui/material';
import EquipmentsTable from './EquipmentsTable';
import useStyles from './styles';

const EquipmentsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const equipments = useSelector(selectEquipmentList);
  const count = useSelector(selectEquipmentListCount);
  const { page } = useSelector(selectEquipmentQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_EQUIPMENTS.action]));
  const success = useSelector(successSelector([LOAD_EQUIPMENTS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadEquipment({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card className={classes.prealoderCard}>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.EQUIPMENT_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.EQUIPMENT_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <EquipmentsTable
      equipments={equipments}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default EquipmentsList;
