import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StatusIcon from 'src/components/StatusIcon';
import { AgentStatus } from 'src/utils/constants/distributors';
import IconStatusAndColor from 'src/utils/constants/common';
import { addPointsPhone } from 'src/utils/common';
import { Button, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import useStyles from '../../styles';

const selectKamStatusIconAndDescription = status => {
  switch (status) {
    case AgentStatus.ACTIVE:
      return {
        icon: IconStatusAndColor.OK.status,
        description: 'ENUMS.STATUS.ACTIVE'
      };
    case AgentStatus.PENDING:
      return {
        icon: IconStatusAndColor.WARNING.status,
        description: 'ENUMS.STATUS.PENDING'
      };
    case AgentStatus.INACTIVE:
      return {
        icon: IconStatusAndColor.ERROR.status,
        description: 'ENUMS.STATUS.INACTIVE'
      };
    default:
      return {
        icon: IconStatusAndColor.NONE.status,
        description: 'COMMON.NO_DATA'
      };
  }
};

const KamGeneralDetailInfo = ({
  kam,
  handleOpenAcceptRegistrationModal,
  handleOpenRejectRegistrationModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <DetailRow
        name={t('COMMON.FULL_NAME')}
        value={kam.name || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.PHONE')}
        value={addPointsPhone(kam.phone ?? '') || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.OFFICE_PHONE')}
        value={addPointsPhone(kam.officePhone ?? '') || t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('COMMON.EMAIL')}
        value={kam.email || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.EMAIL_VALIDATED')}
        value={
          <StatusIcon
            type={
              kam.emailValidated
                ? IconStatusAndColor.OK.status
                : IconStatusAndColor.ERROR.status
            }
            description={
              kam.emailValidated
                ? 'ENUMS.VERIFICATION_STATUS.VERIFIED'
                : 'ENUMS.VERIFICATION_STATUS.UNVERIFIED'
            }
          />
        }
      />

      <DetailRow
        name={t('COMMON.STATUS')}
        value={
          <StatusIcon
            type={selectKamStatusIconAndDescription(kam.status).icon}
            description={
              selectKamStatusIconAndDescription(kam.status).description
            }
          />
        }
      />

      {kam.status === AgentStatus.PENDING && (
        <DetailRow
          name={t('COMMON.DISTRIBUTOR')}
          value={kam.tempDistributorName || t('COMMON.NO_DATA')}
        />
      )}

      {kam.status === AgentStatus.PENDING && (
        <Grid container width="100">
          <Grid item sm={6} justifyContent="center" display="flex">
            <Button
              onClick={handleOpenAcceptRegistrationModal}
              variant="contained"
              color="primary"
              className={clsx(classes.labelAccept, classes.buttons)}
              startIcon={<CheckIcon />}
            >
              {t('COMMON.CONFIRM')}
            </Button>
          </Grid>
          <Grid item sm={6} justifyContent="center" display="flex">
            <Button
              onClick={handleOpenRejectRegistrationModal}
              variant="contained"
              color="primary"
              className={clsx(classes.labelReject, classes.buttons)}
              startIcon={<ClearIcon />}
            >
              {t('COMMON.REJECT')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

KamGeneralDetailInfo.propTypes = {
  kam: PropTypes.object.isRequired,
  handleOpenAcceptRegistrationModal: PropTypes.object.isRequired,
  handleOpenRejectRegistrationModal: PropTypes.object.isRequired,
};
export default KamGeneralDetailInfo;
