import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { BuroDetailTabsIndex } from 'src/utils/constants/clients';
import ValidationDetail from './ValidationDetail';
import BuroValidationHistoryList from './ValidationHistory/BuroValidationHistoryList';

const BillingTabsList = [
  {
    value: BuroDetailTabsIndex.VALIDATION,
    label: 'CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.TITLE'
  },
  {
    value: BuroDetailTabsIndex.HISTORY,
    label: 'COMMON.HISTORY'
  }
];

const BuroDetailTabs = ({ validation }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  const { avalId, id: clientId, subtab } = useParams();

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(
        `/clients/detail/${clientId}/aval/detail/${avalId}/buro/${newValue}`
      );
    },
    [setValue]
  );

  useEffect(() => {
    if (avalId && !subtab) {
      setValue(BuroDetailTabsIndex.VALIDATION);
    }
    if (subtab) {
      if (Object.values(BuroDetailTabsIndex).includes(subtab)) {
        setValue(subtab);
      } else {
        push(`/clients/detail/${clientId}/aval/detail/${avalId}/buro`);
      }
    }
  }, [avalId, subtab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          {BillingTabsList.map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={BuroDetailTabsIndex.VALIDATION}>
        <ValidationDetail validation={validation} />
      </TabPanel>

      <TabPanel value={value} index={BuroDetailTabsIndex.HISTORY}>
        <BuroValidationHistoryList />
      </TabPanel>
    </Box>
  );
};

BuroDetailTabs.propTypes = {
  validation: PropTypes.object.isRequired
};

export default BuroDetailTabs;
