import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { ASSIGN_KAM } from 'src/store/actions/quoteActions';
import Preloader from 'src/components/Preloader';
import { loadAllClientAdministratives } from 'src/store/actions/clientActions';
import { sendInvoiceToClientWhatsappAction } from 'src/store/actions/cfdiActions';
import Dialog from 'src/components/Dialog/Dialog';
import { selectAdministrativeList } from 'src/store/selectors/clientSelectors';
import Button from 'src/components/Button';
import MultipleSelectChip from 'src/components/MultipleSelect/MutipleSelect';
import theme from 'src/theme';
import { SEND_INVOICE_TO_WHATSAPP } from 'src/store/constants/CFDI';
import validationSchema from './schema';
import makeInitialState from './initialState';

const SendWhatsappDialog = ({ open, onClose, id, clientId }) => {
  const dispatch = useDispatch();
  const administrativesList = useSelector(selectAdministrativeList);
  const isLoadingAdministratives = useSelector(
    isLoadingSelector([ASSIGN_KAM.action])
  );
  const isLoadingSendingMWhatsappMessage = useSelector(
    isLoadingSelector([SEND_INVOICE_TO_WHATSAPP.action])
  );
  const initialState = useMemo(() => makeInitialState(), [open]);

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(loadAllClientAdministratives(clientId));
    }
  }, [open]);

  const handleSendWhatsapp = useCallback(  values => {
    dispatch(
      sendInvoiceToClientWhatsappAction({
        id,
        ...values
      }, onClose)
    );
  }, [id]);


  if (!open) return null;

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      TransitionComponent={DialogTransition}
      title={t('ENUMS.CFDI_LIST_ACTIONS.SEND_WHATSAPP')}
      sx={{
        marginBottom: '1.5rem',
        '& .MuiDialog-paper': {
          width: theme.spacing(70)
        }
      }}
    >
      <DialogContent
        sx={{
          m: 2
        }}
      >
        {isLoadingSendingMWhatsappMessage ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSendWhatsapp}
          >
            {({ errors, handleSubmit, touched, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <MultipleSelectChip
                    options={administrativesList.map(admin => {
                      return {
                        value: admin.phone,
                        label: `${admin.id} - ${admin.fullName}`
                      };
                    })}
                    label={t('COMMON.ADMINISTRATIVES')}
                    fullWidth
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    errors={Boolean(touched.phones && errors.phones)}
                    helperText={touched.phones && t(errors.phones)}
                    setFieldValue={setFieldValue}
                    fieldName="phones"
                  />

                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isLoadingAdministratives}
                    >
                      {t('COMMON.SEND')}
                      {isLoadingAdministratives && <Preloader />}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

SendWhatsappDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired
};

export default SendWhatsappDialog;
