import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import BrandRow from './BrandRow';

const BrandTable = ({
  className,
  brands,
  handleOpenEdit,
  handleDelete,
  loading,
  success,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit]
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell variant="head" key="client" align="left">
        {t('COMMON.BRAND')}
      </TableCell>
      <TableCell variant="head" key="client" align="left">
        {t('COMMON.TYPE')}
      </TableCell>
      <TableCell
        variant="head"
        key="client"
        align="center"
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        {t('COMMON.RELATED_MODELS')}
      </TableCell>
      <TableCell />
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return brands.map(brand => (
        <BrandRow
          {...brand}
          key={brand.id}
          openEdit={openEdit(brand)}
          handleDelete={handleDelete}
        />
      ));
    },
    [brands]
  );

  if (success && !loading && !brands.length) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.BRAND_TITLE" />
      </Card>
    );
  }

  return <Table tbody={tbody} theads={theads} loading={loading} {...rest} />;
};

BrandTable.propTypes = {
  className: PropTypes.string,
  brands: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default BrandTable;
