import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`
    }
  },
  root: {
    minWidth: theme.spacing(80)
  },
  closeIcon: {
    float: 'right',
    marginTop: '-25px',
    width: '20px',
    height: '20px'
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(10)
  }
}));
