import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { CommonActions } from 'src/utils/constants/clients';
import { AgentStatus } from 'src/utils/constants/distributors';
import ChangeStatus from '@mui/icons-material/ImportExport';
import ReSendIcon from '@mui/icons-material/Autorenew';
import {
  resendValidationEmail,
  updateAdministrator
} from 'src/store/actions/administratorsActions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import selectFormatDate from 'src/utils/selectDateFormat';
import { addPointsPhone } from 'src/utils/common';
import OverflowTipography from 'src/components/OverflowTipography';
import dateFormatType from 'src/utils/dateFormatType';
import AdministratorDialog from 'src/views/administrator/administrators/AdministratorDialog';
import ChangeAdministratorStatusDialog from './ChangeAdministratortatusDialog/ChangeAdministratortatusDialog';

const AdministratorMenu = (status, emailValidated) => {
  const agentStatus =
    status === AgentStatus.ACTIVE
      ? 'ADMINISTRATORS_VIEW.DISABLE_ADMINISTRATOR'
      : 'ADMINISTRATORS_VIEW.ENABLE_ADMINISTRATOR';

  return [
    {
      name: CommonActions.EDIT,
      label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.EDIT}`
    },
    {
      name: CommonActions.CHANGE_STATUS,
      label: agentStatus,
      icon: <ChangeStatus />
    },
    {
      name: CommonActions.RESEND_VALIDATION,
      label: 'ENUMS.ADMINISTRATIVE_ACTIONS.RESEND_VALIDATION',
      icon: <ReSendIcon />,
      disabled: emailValidated,
      message: emailValidated
        ? 'ADMINISTRATORS_VIEW.EMAIL_ALREADY_VALIDATED'
        : ''
    }
  ];
};

const AdministratorRow = ({
  name,
  isEmailValidated,
  email,
  status,
  id,
  phone,
  createdAt
}) => {
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditStatusDialog, setOpenEditStatusDialog] = useState(false);
  const dispatch = useDispatch();
  const options = AdministratorMenu(status, isEmailValidated);

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatusDialog(false);
  };

  const handleUpdateAdministrator = useCallback(
    values => {
      dispatch(updateAdministrator(id, values, handleCloseEdit));
    },
    [dispatch, id]
  );

  const handleChangeStatus = useCallback(values => {
    dispatch(updateAdministrator(id, values, handleCloseEditStatus));
  });

  const handleResendValidation = useCallback(() => {
    dispatch(resendValidationEmail(id));
  }, [id]);

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.CHANGE_STATUS) {
      setOpenEditStatusDialog(true);
    }
    if (option === CommonActions.RESEND_VALIDATION) {
      handleResendValidation();
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <OverflowTipography title={name}>{name}</OverflowTipography>
      </TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">{addPointsPhone(phone ?? '')}</TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            isEmailValidated
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            isEmailValidated
              ? 'ENUMS.VERIFICATION_STATUS.VERIFIED'
              : 'ENUMS.VERIFICATION_STATUS.UNVERIFIED'
          }
        />
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(createdAt, dateFormatType.DAY) ?? t('COMMON.NO_DATA')}
      </TableCell>
      <TableCell align="center">
        <StatusIcon
          type={
            status === AgentStatus.ACTIVE
              ? IconStatusAndColor.OK.status
              : IconStatusAndColor.ERROR.status
          }
          description={
            status === AgentStatus.ACTIVE
              ? 'ENUMS.STATUS.ACTIVE'
              : 'ENUMS.STATUS.INACTIVE'
          }
        />
      </TableCell>

      <TableCell align="right">
        <MoreMenu
          options={options}
          handleClickOption={handleClickMore}
          deleteDisabled
        />

        <AdministratorDialog
          open={openEditDialog}
          handleClose={handleCloseEdit}
          onSubmit={handleUpdateAdministrator}
          administrator={{
            name,
            email,
            status,
            id,
            phone
          }}
          isEmailValidated={isEmailValidated}
        />

        <ChangeAdministratorStatusDialog
          open={openEditStatusDialog}
          handleClose={handleCloseEditStatus}
          onSubmit={handleChangeStatus}
          status={status}
        />
      </TableCell>
    </TableRow>
  );
};

AdministratorRow.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  isEmailValidated: PropTypes.bool,
  status: PropTypes.bool,
  id: PropTypes.string,
  createdAt: PropTypes.string,
  phone: PropTypes.string
};

export default AdministratorRow;
