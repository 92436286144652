import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl,
  Box,
  TextField,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_ECONOMIC_ACTIVITIES,
  loadEconomicActivities
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const EconomicActivitiesDialog = ({
  open,
  investor,
  handleClose,
  submit,
  disabledCondition
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(investor), [investor]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const economicActivitiesList = useSelector(selectCatalogList);
  const loadingEconomicActivitiesList = useSelector(
    isLoadingSelector([LOAD_ECONOMIC_ACTIVITIES])
  );

  useEffect(() => {
    dispatch(loadEconomicActivities());
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {`${t('INVESTORS_VIEW.INVESTOR_DETAIL.ECONOMIC_ACTIVITIES')}`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={submit}
          enableReinitialize
          validate={values => {
            const errors = {};
            const percentagesSum = values.economicActivities.reduce(
              (accu, curr) => {
                return accu + Number(curr.percentage);
              },
              0
            );

            if (percentagesSum !== 100) {
              errors.percentagesSum =
                'SCHEMA_ERRORS.ECONOMIC_ACTIVITIES_PERCENTAGE_MUST_BE_EQUAL_TO_ONE_HUNDRED';
            }

            return errors;
          }}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
            handleBlur
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.economicActivities && errors.economicActivities
                  )}
                >
                  {values.economicActivities.map((economicActivity, index) => (
                    <Grid
                      container
                      justifyContent="space-between"
                      key={`economicActivity-${
                        economicActivity ? economicActivity.id : Math.random()
                      }`}
                    >
                      <Grid item direction="column" xs={8}>
                        <Box>
                          <Autocomplete
                            key={`economicActivity-${
                              economicActivity
                                ? economicActivity.id
                                : Math.random()
                            }`}
                            options={economicActivitiesList.filter(
                              activity =>
                                !values.economicActivities.some(
                                  selectedActivity => {
                                    if (selectedActivity) {
                                      return (
                                        selectedActivity.id === activity.id
                                      );
                                    }
                                    return false;
                                  }
                                )
                            )}
                            id="economicActivities"
                            label={t(
                              'INVESTORS_VIEW.INVESTOR_DETAIL.ECONOMIC_ACTIVITY'
                            )}
                            onBlur={handleBlur}
                            name="economicActivities"
                            value={economicActivity}
                            loading={loadingEconomicActivitiesList}
                            disabled={
                              loadingEconomicActivitiesList ||
                              disabledCondition(economicActivity)
                            }
                            loadingText={t('COMMON.LOADING')}
                            getOptionLabel={option =>
                              option ? `${option.name}` : ''
                            }
                            fullWidth
                            onChange={(_e, value) => {
                              const updatedEconomicActivities = [
                                ...values.economicActivities
                              ];
                              updatedEconomicActivities[index] = {
                                ...value,
                                percentage:
                                  updatedEconomicActivities[index].percentage
                              };
                              setFieldValue(
                                'economicActivities',
                                updatedEconomicActivities
                              );
                            }}
                            error={Boolean(
                              touched.economicActivities &&
                                errors.economicActivities &&
                                errors.economicActivities[index]?.id
                            )}
                            sx={{ marginBottom: 2, marginTop: 2 }}
                          />
                        </Box>
                      </Grid>
                      <Grid item direction="column" xs={3}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <TextField
                            InputProps={{
                              autoComplete: 'off'
                            }}
                            error={Boolean(
                              touched.percentage &&
                                errors.economicActivities &&
                                touched.percentage &&
                                errors.economicActivities[index]?.percentage
                            )}
                            fullWidth
                            label="(%)"
                            margin="normal"
                            name="percentage"
                            onBlur={handleBlur}
                            onChange={event => {
                              if (
                                Number(event.target.value) <= 100 &&
                                Number(event.target.value) > 0
                              ) {
                                const updatedEconomicActivities = [
                                  ...values.economicActivities
                                ];
                                updatedEconomicActivities[index].percentage =
                                  event.target.value;
                                setFieldValue(
                                  'economicActivities',
                                  updatedEconomicActivities
                                );
                              }
                            }}
                            type="number"
                            value={economicActivity.percentage}
                            variant="outlined"
                          />
                          <IconButton
                            sx={{
                              float: 'right',
                              width: '20px',
                              height: '20px',
                              marginLeft: '10px'
                            }}
                            aria-label="delete"
                            onClick={() => {
                              const updatedEconomicActivities = values.economicActivities.filter(
                                (_, i) => i !== index
                              );
                              setFieldValue(
                                'economicActivities',
                                updatedEconomicActivities
                              );
                            }}
                            disabled={
                              values.economicActivities.length === 1 ||
                              disabledCondition(economicActivity)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                      {(touched.percentage || touched.economicActivities) &&
                        errors.economicActivities &&
                        (t(errors.economicActivities[index]?.percentage) ||
                          t(errors.economicActivities[index]?.id)) && (
                          <Box alignContent="center" sx={{ width: '100%' }}>
                            <FormHelperText
                              color="error"
                              sx={{ textAlign: 'center' }}
                              variant="body2"
                            >
                              {t(
                                errors.economicActivities[index]?.percentage
                              ) || t(errors.economicActivities[index]?.id)}
                            </FormHelperText>
                          </Box>
                        )}
                    </Grid>
                  ))}
                </FormControl>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}
                >
                  <Button
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={values.economicActivities.length === 4}
                    onClick={() => {
                      setFieldValue('economicActivities', [
                        ...values.economicActivities,
                        { id: '', name: '', percentage: '' }
                      ]);
                    }}
                    startIcon={<AddIcon />}
                  >
                    {t('INVESTORS_VIEW.INVESTOR_DETAIL.ADD_ECONOMIC_ACTIVITY')}
                  </Button>
                </Box>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>

                {errors.percentagesSum && (
                  <Typography color="error" align="center" variant="body2">
                    {t(errors.percentagesSum)}
                  </Typography>
                )}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EconomicActivitiesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  investor: PropTypes.object,
  disabledCondition: PropTypes.func
};

export default EconomicActivitiesDialog;
