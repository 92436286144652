import { getNames } from 'src/utils/common';

export default values => ({
  name: values && values.fullName ? getNames(values.fullName) : '',
  paternalName:
    values && values.fullName ? values.fullName.split(' ').slice(-2, -1) : '',
  maternalName:
    values && values.fullName ? values.fullName.split(' ').slice(-1) : '',
  rfc: values?.rfc ?? '',
  phone: values.phone ?? '',
  secondaryPhone: values.secondaryPhone ?? '',
  email: values?.email ?? '',

});
