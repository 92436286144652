import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const KamAlert = ({ kams, loading }) => {
  const [open] = useState(true);
  const { t } = useTranslation();

  if (!loading) {
    if (!kams.length) {
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_KAMS_DIRECTOR_FIRST')}
            <Link to="/user/kams">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_KAMS_DIRECTOR_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

KamAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  kams: PropTypes.array.isRequired
};

export default KamAlert;
