import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Checkbox
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import {
  getAddressByZipCode,
  LOAD_ADDRESS_BY_ZIP_CODE
} from 'src/store/actions/clientActions';
import useIsLargeScreen from 'src/hooks/useIsLargeScreen';
import theme from 'src/theme';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepSix = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLargeScreen = useIsLargeScreen();

  const [addressData, setAddressData] = useState({
    cities: [],
    colonias: [],
    states: []
  });
  const isLoadingAddress = useSelector(
    isLoadingSelector([LOAD_ADDRESS_BY_ZIP_CODE.action])
  );
  const handleZipCodeChange = useCallback((e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue('equipmentZipCode', value);
    if (value.length === 5) {
      dispatch(getAddressByZipCode(value, setAddressData));
    }
    setFieldValue('equipmentColonia', '');
    setFieldValue('equipmentCity', '');
    setFieldValue('equipmentState', '');
  });

  const handleReuseAddress = useCallback(
    setFieldValue => {
      setFieldValue('equipmentStreet', data.street);
      setFieldValue('equipmentExternalHomeNumber', data.externalHomeNumber);
      setFieldValue('equipmentInsideHomeNumber', data.insideHomeNumber);
      setFieldValue('equipmentZipCode', data.zipCode);
      setFieldValue('equipmentColonia', data.colonia);
      setFieldValue('equipmentCity', data.city);
      setFieldValue('equipmentState', data.state);
      setFieldValue('useClientAddress', true);
    },
    [data]
  );

  const handleCancelReuseAddress = setFieldValue => {
    setFieldValue('equipmentStreet', '');
    setFieldValue('equipmentExternalHomeNumber', '');
    setFieldValue('equipmentInsideHomeNumber', '');
    setFieldValue('equipmentZipCode', '');
    setFieldValue('equipmentColonia', '');
    setFieldValue('equipmentCity', '');
    setFieldValue('equipmentState', '');
    setFieldValue('useClientAddress', false);
  };

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext, data]
  );

  return (
    <Box
      sx={{
        height: {
          xs: 'auto',
          md: '50vh'
        },
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          height: '10%'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_SIX.TITLE')}
          <span style={{ textDecoration: 'underline' }}>
            {t('BUREAU_FORM.STEP_SIX.UNDERLINE_TITLE')}
          </span>
        </Typography>
      </Box>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              component="form"
              sx={{
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box className={classes.container}>
                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '40px'
                  }}
                >
                  <Checkbox
                    checked={values.useClientAddress}
                    onClick={
                      values.useClientAddress
                        ? () => handleCancelReuseAddress(setFieldValue)
                        : () => handleReuseAddress(setFieldValue)
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        margin: '0 5px',
                        fontSize: '22px'
                      }}
                    >
                      {t('BUREAU_FORM.STEP_SIX.RE_USE_ADDRESS')}
                    </Typography>
                  </Box>
                </FormControl>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.equipmentStreet && errors.equipmentStreet
                    )}
                    sx={{
                      width: ' 48%'
                    }}
                    helperText={
                      touched.equipmentStreet && t(errors.equipmentStreet)
                    }
                    label={t('COMMON.STREET')}
                    margin="normal"
                    name="equipmentStreet"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.equipmentStreet}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.equipmentExternalHomeNumber &&
                        errors.equipmentExternalHomeNumber
                    )}
                    sx={{
                      width: '24%'
                    }}
                    helperText={
                      touched.equipmentExternalHomeNumber &&
                      t(errors.equipmentExternalHomeNumber)
                    }
                    label={
                      isLargeScreen ? t('COMMON.EXTERNAL_HOME_NUMBER') : 'Ext.'
                    }
                    margin="normal"
                    name="equipmentExternalHomeNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.equipmentExternalHomeNumber}
                    variant="outlined"
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.equipmentInsideHomeNumber &&
                        errors.equipmentInsideHomeNumber
                    )}
                    sx={{
                      width: '24%'
                    }}
                    helperText={
                      touched.equipmentInsideHomeNumber &&
                      t(errors.equipmentInsideHomeNumber)
                    }
                    label={
                      isLargeScreen ? t('COMMON.INSIDE_HOME_NUMBER') : 'Int.'
                    }
                    margin="normal"
                    name="equipmentInsideHomeNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.equipmentInsideHomeNumber}
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.equipmentZipCode && errors.equipmentZipCode
                    )}
                    sx={{
                      width: '48%'
                    }}
                    helperText={
                      touched.equipmentZipCode && t(errors.equipmentZipCode)
                    }
                    label={t('COMMON.ZIP_CODE')}
                    margin="normal"
                    name="equipmentZipCode"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleZipCodeChange(e, setFieldValue);
                    }}
                    type="text"
                    value={values.equipmentZipCode}
                    variant="outlined"
                  />

                  <FormControl
                    sx={{
                      width: '50%'
                    }}
                    error={Boolean(
                      touched.equipmentCity && errors.equipmentCity
                    )}
                  >
                    <Autocomplete
                      freeSolo
                      onBlur={handleBlur}
                      options={addressData.cities.map(city => ({
                        key: city,
                        name: city
                      }))}
                      getOptionLabel={option => {
                        return option.name ? `${option.name}` : '';
                      }}
                      value={
                        addressData.cities
                          .map(city => ({
                            key: city,
                            name: city
                          }))
                          .find(city => city.name === values.equipmentCity) ?? {
                          key: values.equipmentCity,
                          name: values.equipmentCity
                        }
                      }
                      placeholder={values.city}
                      onChange={(e, value) => {
                        setFieldValue(
                          'equipmentCity',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('equipmentCity', value);
                      }}
                      label={t('COMMON.CITY')}
                      error={Boolean(
                        touched.equipmentCity && errors.equipmentCity
                      )}
                      errorText={
                        touched.equipmentCity && t(errors.equipmentCity)
                      }
                      isLoading={isLoadingAddress}
                      disabled={!values.equipmentZipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControl
                    sx={{
                      width: '48%'
                    }}
                    error={Boolean(
                      touched.equipmentColonia && errors.equipmentColonia
                    )}
                  >
                    <Autocomplete
                      options={addressData.colonias.map(col => ({
                        key: col,
                        name: col
                      }))}
                      value={
                        addressData.colonias
                          .map(col => ({
                            key: col,
                            name: col
                          }))
                          .find(
                            col => col.name === values.equipmentColonia
                          ) ?? {
                          key: values.equipmentColonia,
                          name: values.equipmentColonia
                        }
                      }
                      getOptionLabel={option =>
                        option.name ? `${option.name}` : ''
                      }
                      onChange={(e, value) => {
                        setFieldValue(
                          'equipmentColonia',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('equipmentColonia', value);
                      }}
                      freeSolo
                      label={t('COMMON.COLONIA')}
                      error={Boolean(
                        touched.equipmentColonia && errors.equipmentColonia
                      )}
                      errorText={
                        touched.equipmentColonia && t(errors.equipmentColonia)
                      }
                      isLoading={isLoadingAddress}
                      disabled={!values.equipmentZipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      width: '50%'
                    }}
                    error={Boolean(
                      touched.equipmentState && errors.equipmentState
                    )}
                  >
                    <Autocomplete
                      options={addressData.states.map(state => ({
                        key: state,
                        name: state
                      }))}
                      getOptionLabel={option =>
                        option.name ? `${option.name}` : ''
                      }
                      freeSolo
                      value={
                        addressData.states
                          .map(state => ({
                            key: state,
                            name: state
                          }))
                          .find(
                            state => state.name === values.equipmentState
                          ) ?? {
                          key: values.equipmentState,
                          name: values.equipmentState
                        }
                      }
                      onChange={(e, value) => {
                        setFieldValue(
                          'equipmentState',
                          value ? value.name : e.target.value
                        );
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue('equipmentState', value);
                      }}
                      label={t('COMMON.STATE')}
                      error={Boolean(
                        touched.equipmentState && errors.equipmentState
                      )}
                      errorText={
                        touched.equipmentState && t(errors.equipmentState)
                      }
                      isLoading={isLoadingAddress}
                      disabled={!values.equipmentZipCode}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={() => handleBack()}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

StepSix.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepSix;
