import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Gender, PersonType } from 'src/utils/constants/clients';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectIndustriesList } from 'src/store/selectors/catalogSelectors';
import {
  loadDenominationsList,
  loadIndustries
} from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { selectExistingAvalWithRfc } from 'src/store/selectors/clientSelectors';
import {
  CLEAR_AVAL_WITH_RFC,
  SEARCH_AVAL_WITH_RFC,
  searchAvalWithRfc
} from 'src/store/actions/clientActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import { countriesList } from 'src/utils/constants/countries';
import PhoneInput from 'src/components/PhoneInput';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';
import CreateClientFromAvalDialog from './CreateClientFromAval/CreateClientFromAvalDialog';

const ClientGenderType = [
  { name: 'COMMON.MALE', key: Gender.MALE },
  { name: 'COMMON.FEMALE', key: Gender.FEMALE }
];

const StepTwo = ({ data, handleBack, handleNext, activeStep, setStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const industries = useSelector(selectIndustriesList) || [];
  const [denominations, setDenominations] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const existingAvalWithRfc = useSelector(selectExistingAvalWithRfc);
  const initialState = useMemo(
    () => makeInitialState(data, existingAvalWithRfc),
    [data, existingAvalWithRfc]
  );
  const loading = useSelector(isLoadingSelector([SEARCH_AVAL_WITH_RFC.action]));

  const handleOpenCreateClientFromAval = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseCreateClientFromAval = useCallback(() => {
    setOpen(false);
    setStep(0);
    dispatch({ type: CLEAR_AVAL_WITH_RFC });
  }, []);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  const handleSubmitCreateClientFromAval = useCallback(() => {
    setOpen(false);
    onNext(initialState);
  }, [initialState]);

  const denominationOptions = useMemo(() => {
    return denominations.map(denomination => ({
      key: denomination.name,
      name: denomination.name
    }));
  }, [denominations]);

  useEffect(() => {
    if (activeStep === 1) {
      dispatch(loadDenominationsList(setDenominations));
      dispatch(loadIndustries());
      if (!data.name && !data.maternalName && !data.paternalName) {
        dispatch(searchAvalWithRfc(data.rfc));
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(existingAvalWithRfc).length) {
      handleOpenCreateClientFromAval();
    }
  }, [existingAvalWithRfc]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={onNext}
          enableReinitialize
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: open ? 'none' : 'block'
                }}
              >
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                {values.personType === PersonType.PHYSICAL && (
                  <>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.paternalName && errors.paternalName
                      )}
                      fullWidth
                      helperText={
                        touched.paternalName && t(errors.paternalName)
                      }
                      label={t('COMMON.PATERNAL_NAME')}
                      margin="normal"
                      name="paternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.paternalName}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.maternalName && errors.maternalName
                      )}
                      fullWidth
                      helperText={
                        touched.maternalName && t(errors.maternalName)
                      }
                      label={t('COMMON.MATERNAL_NAME')}
                      margin="normal"
                      name="maternalName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.maternalName}
                      variant="outlined"
                    />

                    <DropdownMenu
                      list={ClientGenderType}
                      value={values.sex}
                      setValue={value => setFieldValue('sex', value)}
                      label={t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
                      error={Boolean(touched.sex && errors.sex)}
                      errorText={touched.sex && t(errors.sex)}
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.curp && errors.curp)}
                      fullWidth
                      helperText={touched.curp && t(errors.curp)}
                      label="CURP"
                      margin="normal"
                      name="curp"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.curp}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.identificationNumber &&
                          errors.identificationNumber
                      )}
                      fullWidth
                      helperText={
                        touched.identificationNumber &&
                        t(errors.identificationNumber)
                      }
                      label={t(
                        'CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER'
                      )}
                      margin="normal"
                      name="identificationNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.identificationNumber}
                      variant="outlined"
                    />
                  </>
                )}

                {values.personType === PersonType.LEGAL && (
                  <>
                    <DropdownMenu
                      list={denominationOptions}
                      value={values.denomination}
                      setValue={value => setFieldValue('denomination', value)}
                      label={t('COMMON.DENOMINATION')}
                      error={Boolean(
                        touched.denomination && errors.denomination
                      )}
                      errorText={touched.denomination && t(errors.denomination)}
                    />

                    <FormControl
                      fullWidth
                      error={Boolean(touched.industry && errors.industry)}
                    >
                      <Autocomplete
                        label={t('CLIENTS_VIEW.CREATION_FORM.INDUSTRY')}
                        name="industry"
                        options={industries}
                        id="industry"
                        value={industries.find(
                          option => option.name === values.industry
                        )}
                        getOptionLabel={option =>
                          option ? `${option.id} ${option.name}` : ''
                        }
                        clearOnBlur={false}
                        fullWidth
                        sx={{ marginBottom: 1, marginTop: 2 }}
                        clearIcon={null}
                        onChange={(_e, value) => {
                          setFieldValue('industry', value ? value.name : '');
                        }}
                      />
                    </FormControl>
                  </>
                )}

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    label={t('COMMON.MOBILE_PHONE')}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  label={t('COMMON.EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <FormControl fullWidth>
                  <Autocomplete
                    options={countriesList}
                    id="natiolanity"
                    getOptionLabel={option => {
                      return option ? t(option.name) : '';
                    }}
                    value={
                      countriesList.find(
                        country => country.key === values.nationality
                      ) ?? ''
                    }
                    keepMounted
                    fullWidth
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    onChange={(_e, value) => {
                      setFieldValue('nationality', value?.key ?? null);
                    }}
                    helperText={touched.nationality && t(errors.nationality)}
                    label={t('COMMON.COUNTRY_OF_BIRTH')}
                    error={Boolean(touched.nationality && errors.nationality)}
                  />
                </FormControl>

                <Box
                  className={classes.box}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    className={classes.buttons}
                    onClick={() => handleBack()}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.NEXT')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}

      <CreateClientFromAvalDialog
        open={open}
        handleClose={handleCloseCreateClientFromAval}
        onSubmit={handleSubmitCreateClientFromAval}
        data={existingAvalWithRfc}
      />
    </>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};
export default StepTwo;
