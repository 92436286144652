export const selectAdministrators = state => state.administrators;
export const selectAdministratorsList = state =>
  selectAdministrators(state).items;
export const selectAdministratorsCount = state =>
  selectAdministrators(state).count;
export const selectAdministratorsDetail = state =>
  selectAdministrators(state).detail;
export const selectAdministratorsQueryOptions = state => {
  const { options } = selectAdministrators(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
