import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectDistributorAgents } from 'src/store/selectors/distributorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_DISTRIBUTOR_AGENTS } from 'src/store/actions/distributorActions';
import { showMessage } from 'src/store/actions/snackBarActions';
import { SnackBarVariants } from 'src/utils/constants/common';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import useStyles from '../../styles';
import validationSchema from '../schema';
import makeInitialState from '../initialState';
import KamAutocomplete from './KamAutocomplete';

const StepTwoKam = ({
  data,
  activeStep,
  setData,
  handleNext,
  handleBack,
  resetRef,
  setActiveStep,
  handleClose
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingView, setLoadingView] = useState(true);
  const kamList = useSelector(selectDistributorAgents);
  const loadingDistributorAgents = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );

  const handleInvalidKam = useCallback(() => {
    setActiveStep(0);
    setData({
      ...data,
      name: '',
      maternalName: '',
      paternalName: '',
      phone: '',
      email: '',
      denomination: '',
      industry: '',
      clientId: ''
    });
    dispatch(
      showMessage({
        message: t('ERRORS.THIS_CLIENT_IS_ADD_TO_OTHER_KAM'),
        variant: SnackBarVariants.ERROR
      })
    );
  }, []);

  const onNext = values => {
    handleNext({ ...values });
  };

  if (activeStep !== 1) return null;

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                {loadingDistributorAgents ? (
                  <Loading />
                ) : (
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.distributorProfile && errors.distributorProfile
                    )}
                  >
                    <KamAutocomplete
                      options={kamList}
                      isLoading={loadingView}
                      id="distributorProfile"
                      onSubmit={handleSubmit}
                      setLoadingView={setLoadingView}
                      value={values.distributorProfile ?? ''}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      fullWidth
                      onClose={handleClose}
                      onInvalidKam={handleInvalidKam}
                      onChange={(_e, value) => {
                        setFieldValue('distributorProfile', value ?? '');
                      }}
                      label={`${t('COMMON.KAM')} (${t('COMMON.OPTIONAL')})`}
                      error={
                        touched.distributorProfile && errors.distributorProfile
                      }
                      helperText={
                        touched.distributorProfile &&
                        t(errors.distributorProfile)
                      }
                    />
                  </FormControl>
                )}
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={handleBack}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepTwoKam.propTypes = {
  data: PropTypes.object,
  activeStep: PropTypes.number,
  setData: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object,
  setActiveStep: PropTypes.func,
  handleClose: PropTypes.func
};

export default StepTwoKam;
