import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import { editInvestor } from 'src/store/actions/investorActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useStyles from '../../styles';
import GeneralInvestorInfo from './GeneralInvestorInfo';
import GeneralDetailDialog from './GeneralDetailDialog';

const GeneralClient = ({ investor }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(editInvestor(id, values, () => setOpen(false)));
    },
    [investor, id]
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('INVESTORS_VIEW.INVESTOR_DETAIL.GENERAL_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <GeneralInvestorInfo investor={investor} />
          </TableBody>
        </Table>

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleOpenDialog}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>

      <GeneralDetailDialog
        open={open}
        investor={investor}
        handleClose={handleCloseDialog}
        submit={handleSubmit}
      />
    </Card>
  );
};

GeneralClient.propTypes = {
  investor: PropTypes.object.isRequired
};

export default GeneralClient;
