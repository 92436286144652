import React from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import Toolbar from '../Toolbar';
import BillingConceptList from './BillingConceptList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BillingConceptView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title={t('NAVIGATION.CATALOG_LIST.BILLING_CONCEPT')}
    >
      <Container maxWidth={false}>
        <Toolbar catalogLabel={t('NAVIGATION.CATALOG_LIST.BILLING_CONCEPT')} />
        <Box mt={3}>
          <BillingConceptList />
        </Box>
      </Container>
    </Page>
  );
};

export default BillingConceptView;
