import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  dialogRoot: {
    width: theme.spacing(60)
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    fontWeight: '400'
  }
}));
