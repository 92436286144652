import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { formatAmount } from 'src/utils/formatAmout';
import { useSelector } from 'react-redux';
import { selectRentAfterContribution } from 'src/store/selectors/contractSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_RENT_AFTER_CONTRIBUTION } from 'src/store/actions/contractActions';
import useStyles from '../styles';
import { formatPositiveAmount } from '../../../../../../../utils/formatAmout';

const ReviewData = ({ values, setIsRentLessThanZero }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loadingRentAfterContribution = useSelector(
    isLoadingSelector([LOAD_RENT_AFTER_CONTRIBUTION.action])
  );

  const rentAfterContribution = useSelector(selectRentAfterContribution);

  useEffect(() => {
    if (rentAfterContribution <= 0) {
      setIsRentLessThanZero(true);
    }
  }, [rentAfterContribution]);

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CONTRACT_VIEW.CONTRACT_DATA')}
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {`${t('CONTRACT_VIEW.CURRENT_RENT')}:`}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              $
              {formatAmount(values.currentRent)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {`${t('COMMON.AMOUNT_WITH_IVA')}:`}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              $
              {formatPositiveAmount(values.amount)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {`${t('CONTRACT_VIEW.RENT_AFTER_CONTRIBUTION')}:`}
          </TableCell>
          <TableCell component="div">
            <Typography
              variant="body2"
              color={rentAfterContribution <= 0 ? '#d32f2f' : 'textSecondary'}
            >
              {loadingRentAfterContribution
                ? '...'
                : `$${formatAmount(rentAfterContribution)}`}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired,
  setIsRentLessThanZero: PropTypes.func.isRequired
};

export default ReviewData;
