import React from 'react';
import FadeIn from 'react-fade-in';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import KamQuotesToolbar from './KamQuotesToolbar';
import QuotesListTabs from './QuotesListTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  },
  preloaderCard: {
    height: '82vh'
  }
}));

const KamQuotesView = ({ isDirectorView = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('USER_NAVIGATION.QUOTES')}>
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            <KamQuotesToolbar isDirectorView={isDirectorView} />
            <QuotesListTabs isDirectorView={isDirectorView} />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

KamQuotesView.propTypes = {
  isDirectorView: PropTypes.bool
};

export default KamQuotesView;
