import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { Input, InputAdornment, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const SearchBar = ({ handleSearch, searchValue, placeholder, ...props }) => {
  const { t } = useTranslation();

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <Paper
      elevation={0}
      sx={{
        ml: 2,
        padding: '6px 6px',
        border: '1px solid #EEEEEE',
        display: 'flex',
        alignItems: 'center',
        width: { lg: '30vw', sm: 'auto', xs: 'auto' }
      }}
    >
      <Input
        sx={{ width: '100%' }}
        startAdornment={Addornment}
        placeholder={placeholder || t('COMMON.SEARCH')}
        disableUnderline
        value={searchValue}
        inputProps={{
          'aria-label': 'Search'
        }}
        type="text"
        onChange={handleSearch}
        {...props}
      />
    </Paper>
  );
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.any.isRequired,
  placeholder: PropTypes.string
};

export default SearchBar;
