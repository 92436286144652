import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Info,
  X
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'src/store/actions/snackBarActions';
import {
  selectSnackbarOptions,
  selectSnackbarState
} from 'src/store/selectors/snackBarSelectors';
import { SnackBarVariants } from 'src/utils/constants/common';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
  info: {
    backgroundColor: '#2196F3',
    color: 'black'
  },
  warning: {
    backgroundColor: amber[600],
    color: 'black'
  },
  message: {
    marginLeft: theme.spacing()
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const variantIcon = {
  success: <CheckCircle />,
  warning: <AlertTriangle />,
  error: <AlertCircle />,
  info: <Info />
};

function SnackbarMessage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector(selectSnackbarState);
  const options = useSelector(selectSnackbarOptions);

  options.variant = options.variant || 'info';

  const classes = useStyles();
  const handleOnClose = useCallback(() => {
    dispatch(hideMessage());
  }, [dispatch]);

  const messageComponent = useMemo(() => {
    return (
      <div className={classes.container}>
        {variantIcon[options.variant]}
        <Typography className={classes.message}>
          {options.variant === SnackBarVariants.ERROR &&
            t(`ERRORS.${options.message}`, {
              defaultValue: options.message
            })}
          {options.variant === SnackBarVariants.SUCCESS &&
            t(`SUCCESS.${options.message}`, {
              defaultValue: options.message
            })}
          {options.variant === SnackBarVariants.WARNING &&
            t(`WARNING.${options.message}`, {
              defaultValue: options.message
            })}
          {options.variant === SnackBarVariants.INFO &&
            t(`INFO.${options.message}`, {
              defaultValue: options.message
            })}
        </Typography>
      </div>
    );
  }, [classes, options]);

  return (
    <Snackbar
      {...options}
      anchorOrigin={
        options.anchorOrigin || { vertical: 'top', horizontal: 'center' }
      }
      open={state}
      onClose={handleOnClose}
      classes={{
        root: classes.root
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div'
        }
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={messageComponent}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleOnClose}
          >
            <X />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export default React.memo(SnackbarMessage);
