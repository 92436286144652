import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import BillingListView from './BillingList';
import BillingFailuresListView from './BillingFailuresList';

const BillingView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}/list`} component={BillingListView} />
      <PrivateRoute
        path={`${match.url}/failures`}
        component={BillingFailuresListView}
      />
    </>
  );
};

BillingView.propTypes = {
  match: PropTypes.object.isRequired
};

export default BillingView;
