import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DialogTransition from 'src/components/DialogTransition';
import PhoneInput from 'src/components/PhoneInput';
import { sendQuoteToWhatsappAction } from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { SEND_QUOTE_TO_WHATSAPP } from 'src/store/constants/quotes';
import Loading from 'src/components/Loading';
import useStyles from './styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const SendQuoteToWhatsappDialog = ({ open, onClose, quoteId, phone }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resetRef = useRef();
  const initialState = makeInitialState({ phone });
  const isLoading = useSelector(
    isLoadingSelector([SEND_QUOTE_TO_WHATSAPP.action])
  );

  const handleSubmitReject = useCallback(
    values => {
      dispatch(
        sendQuoteToWhatsappAction({ quoteId, phone: values.phone }, onClose)
      );
    },
    [quoteId]
  );
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle className={classes.header} id="form-distributor-delete">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('ENUMS.QUOTE_ACTIONS.SEND_TO_WHATSAPP')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.confirmation} id="reject-quote-dialog">
        {isLoading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmitReject}
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              resetForm,
              handleBlur,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <form onSubmit={handleSubmit}>
                  <Box className={classes.container}>
                    <FormControl fullWidth margin="normal">
                      <PhoneInput
                        id="phone-label"
                        name="phone"
                        label={t('COMMON.PHONE')}
                        value={values.phone}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        error={Boolean(touched.phone && errors.phone)}
                      />
                      {Boolean(touched.phone && errors.phone) && (
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {t(errors.phone)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: '0.5rem auto'
                    }}
                  >
                    <Button
                      onClick={onClose}
                      color="primary"
                      variant="outlined"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ minWidth: '45%' }}
                    >
                      {t('COMMON.SEND')}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

SendQuoteToWhatsappDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quoteId: PropTypes.number.isRequired,
  phone: PropTypes.string
};

export default SendQuoteToWhatsappDialog;
