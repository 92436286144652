import { TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import BackgroundValidationRow from './BackgroundValidationRow';

const BackgroundValidationTable = ({
  validations,
  count,
  page,
  handleChangePage,
  loading
}) => {
  const { t } = useTranslation();

  const Tbody = ({ validationList = [] }) =>
    validationList.map(validation => (
      <BackgroundValidationRow key={validation.id} {...validation} />
    ));

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell
          variant="head"
          key="fullName"
          align="center"
          sx={{ width: '10%' }}
        >
          {t('COMMON.NAME')}
        </TableCell>
        <TableCell variant="head" key="score" align="center">
          {t('COMMON.SCORE')}
        </TableCell>
        <TableCell variant="head" key="curp" align="center">
          {t('COMMON.CURP')}
        </TableCell>
        <TableCell variant="head" key="rfc" align="center">
          {t('COMMON.RFC')}
        </TableCell>
        <TableCell variant="head" key="siger" align="center">
          {t('ENUMS.CLIENT_VALIDATION.SIGER')}
        </TableCell>
        <TableCell variant="head" key="rug" align="center">
          {t('ENUMS.CLIENT_VALIDATION.RUG')}
        </TableCell>
        <TableCell
          variant="head"
          key="professional-card"
          align="center"
          sx={{
            whiteSpace: 'nowrap'
          }}
        >
          {t('ENUMS.CLIENT_VALIDATION.PROFESSIONALCARD')}
        </TableCell>
        <TableCell
          variant="head"
          key="blackList"
          align="center"
          sx={{
            whiteSpace: 'nowrap'
          }}
        >
          {t('ENUMS.CLIENT_VALIDATION.BLACKLIST')}
        </TableCell>
        <TableCell variant="head" key="sat" align="center">
          {t('ENUMS.CLIENT_VALIDATION.SAT69')}
        </TableCell>
        <TableCell variant="head" key="criminal-records" align="center">
          {t('ENUMS.CLIENT_VALIDATION.CRIMINALRECORDS')}
        </TableCell>
        <TableCell variant="head" key="email" align="center">
          {t('COMMON.EMAIL')}
        </TableCell>
        <TableCell variant="head" key="phone" align="center">
          {t('COMMON.PHONE')}
        </TableCell>
      </TableRow>
    ),
    [t]
  );

  const tbody = useMemo(() => () => <Tbody validationList={validations} />, [
    validations
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, validations]
  );

  return (
    <Table
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

BackgroundValidationTable.propTypes = {
  validations: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

export default BackgroundValidationTable;
