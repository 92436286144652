import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './authReducer';
import equipmentReducer from './equipmentReducer';
import snackBarReducer from './snackBarReducer';
import statusReducer from './statusReducer';
import catalogReducer from './catalogReducer';
import parameterReducer from './parameterReducer';
import contractReducer from './contractReducer';
import insuranceReducer from './insuranceRedurcer';
import transactionReducer from './transactionReducer';
import billingReducer from './billingReducer';
import interestBalanceReducer from './interestBalanceReducer';
import contributionReducer from './contributionsReducer';
import capitalBalanceReducer from './capitalBalanceReducer';
import rentResumeReducer from './rentResumeReducer';
import amortizationBalanceReducer from './amortizationBalanceReducer';
import incomeResumenReducer from './incomeResumeReducer';
import distributorReducer from './distributorReducer';
import UserProfileReducer from './userProfileReducer';
import kamReducer from './kamReducer';
import quoteReducer from './quoteReducer';
import clientReducer from './clientReducer';
import notificationReducer from './notificationReducer';
import businessReducer from './businessReducer';
import administratorsReducer from './administratorsReducer';
import investorReducer from './investorReducer';
import agentsReducer from './agentReducer';
import counselorReducer from './counselorReducer';
import investmentReducer from './investmentReducer';
import cfdisReducer from './cfdiReducer';
import breadcrumbReducer from './bredcrumbsReducer';
import backgroundValidationReducer from './backgroundValidationReducer';
import bureauFormReducer from './bureauFormReducer';
import upfrontRentsReducer from './upfrontRentsReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    status: statusReducer,
    auth: authReducer,
    equipments: equipmentReducer,
    catalog: catalogReducer,
    parameter: parameterReducer,
    clients: clientReducer,
    contracts: contractReducer,
    contributions: contributionReducer,
    insurances: insuranceReducer,
    transactions: transactionReducer,
    billings: billingReducer,
    interestBalance: interestBalanceReducer,
    capitalBalance: capitalBalanceReducer,
    rentResume: rentResumeReducer,
    amortizationBalance: amortizationBalanceReducer,
    incomeResume: incomeResumenReducer,
    distributor: distributorReducer,
    userProfile: UserProfileReducer,
    snackbar: snackBarReducer,
    kams: kamReducer,
    quotes: quoteReducer,
    notifications: notificationReducer,
    business: businessReducer,
    administrators: administratorsReducer,
    investors: investorReducer,
    agents: agentsReducer,
    counselors: counselorReducer,
    investments: investmentReducer,
    cfdis: cfdisReducer,
    breadcrumb: breadcrumbReducer,
    backgroundValidations: backgroundValidationReducer,
    bureauForm: bureauFormReducer,
    upfrontRents: upfrontRentsReducer
  });
