import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { filterClientValidations } from 'src/utils/common';
import { PersonType, validationSection } from 'src/utils/constants/clients';
import TabPanel from 'src/components/TabPanel/TabPanel';
import ValidationDetailTable from './ValidationDetailTable';

const ValidationDetailTableTabs = ({ nufiValidation, includeSiger }) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
    },
    [setTabIndex]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab
            label={t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.VALIDATE_IDENTITY')}
          />
          <Tab
            label={t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.JUDICIAL_RECORD')}
          />
          <Tab
            label={t(
              'CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.CONTACT_INFORMATION'
            )}
          />
          <Tab
            disabled
            label={t(
              'CLIENTS_VIEW.CLIENT_DETAIL.VALIDATION.VALIDATE_IDENTIFICATION'
            )}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <ValidationDetailTable
          validationList={filterClientValidations(
            nufiValidation,
            validationSection.VALIDATE_IDENTITY,
            PersonType.AVAL,
            includeSiger
          )}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ValidationDetailTable
          validationList={filterClientValidations(
            nufiValidation,
            validationSection.JUDICIAL_RECORDS,
            PersonType.AVAL
          )}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ValidationDetailTable
          validationList={filterClientValidations(
            nufiValidation,
            validationSection.CONTACT_INFORMATION,
            PersonType.AVAL
          )}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ValidationDetailTable
          validationList={filterClientValidations(
            nufiValidation,
            validationSection.VALIDATE_IDENTIFICATION,
            PersonType.AVAL
          )}
        />
      </TabPanel>
    </Box>
  );
};

ValidationDetailTableTabs.propTypes = {
  nufiValidation: PropTypes.array,
  includeSiger: PropTypes.bool
};

export default ValidationDetailTableTabs;
