import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { GetApp, Add, FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import useDebounce from 'src/hooks/useDebounce';
import {
  CLEAR_EQUIPMENTS,
  createEquipment,
  downloadExcelEquipment,
  loadEquipment,
  setEquipmentOptions
} from 'src/store/actions/equipmentsActions';
import { selectEquipmentQueryOptions } from 'src/store/selectors/equipmentSelectors';
import {
  AssigantionStatus,
  EquipmentMode
} from 'src/utils/constants/equipment';
import SearchBarByType from 'src/components/SearchBarByType';
import EquipmentsForm from './EquipmentsForm';
import useStyles from './styles';

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: 'checkbox',
    items: [
      {
        label: 'COMMON.ASSIGNED',
        value: 'assigned'
      },
      {
        label: 'COMMON.AVAILABLE',
        value: 'available'
      },
      {
        label: 'COMMON.PENDING_ASSIGNATION',
        value: 'pendingAssignation'
      }
    ]
  },
  {
    title: 'EQUIPMENT_VIEW.BY_MODE',
    controlGroup: 'checkbox',
    items: [
      {
        label: 'EQUIPMENT_VIEW.FINANCIAL_LEASE',
        value: 'financial'
      },
      {
        label: 'EQUIPMENT_VIEW.PURE_LEASE',
        value: 'pure'
      },
      {
        label: 'EQUIPMENT_VIEW.FACTORING',
        value: 'factoring'
      }
    ]
  }
];

const searchTypes = [
  { value: 'clientName', label: 'NAME', type: 'text' },
  { value: 'id', label: 'ID', type: 'text' }
];

function getFilterOptions(options) {
  const assignation = [];
  const mode = [];

  if (options.assigned) {
    assignation.push(AssigantionStatus.ASSIGNED);
  }
  if (options.available) {
    assignation.push(AssigantionStatus.AVAILABLE);
  }
  if (options.pendingAssignation) {
    assignation.push(AssigantionStatus.PENDING);
  }

  if (options.pure) {
    mode.push(EquipmentMode.PURE_LEASE);
  }
  if (options.factoring) {
    mode.push(EquipmentMode.FACTORING);
  }
  if (options.financial) {
    mode.push(EquipmentMode.FINANCIAL_LEASE);
  }

  return { assignation, mode };
}

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectEquipmentQueryOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('clientName');
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateEquipment = useCallback(
    (values, setData) => {
      dispatch(createEquipment(values, handleClose, () => setData()));
    },
    [dispatch, handleClose]
  );

  const debounceSearch = useDebounce(
    (search, queryOpts) => dispatch(loadEquipment({ ...queryOpts, ...search })),
    400
  );

  useEffect(() => {
    dispatch(loadEquipment());
    return () => dispatch({ type: CLEAR_EQUIPMENTS });
  }, []);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions
    ]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelEquipment());
  }, [dispatch]);

  const handleSearch = useCallback(
    (e, criteria) => {
      const { value } = e.target;
      setSearchValue(value);
      setSearchCriteria(criteria);
      debounceSearch({ [criteria]: value }, { ...queryOptions, page: 0 });
    },
    [setSearchValue, debounceSearch, queryOptions, filterValues]
  );

  const handleSearchCriteria = useCallback(() => {
    setSearchValue('');
    dispatch(
      setEquipmentOptions({
        ...queryOptions,
        [searchCriteria]: ''
      })
    );
  }, [dispatch, queryOptions]);

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('EQUIPMENT_VIEW.TITLE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <SearchBarByType
              types={searchTypes}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleSearchCriteria={handleSearchCriteria}
            />
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <GetApp className={classes.icon} />
            </Button>

            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleClickOpen}
              color="secondary"
            >
              <Add className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
      <EquipmentsForm
        open={open}
        handleCreateDevice={handleCreateEquipment}
        handleClose={handleClose}
      />
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
