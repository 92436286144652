import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .matches(
        /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.RFC_REQUIRED'),
    otherwise: Yup.string()
      .matches(
        /^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.RFC_REQUIRED')
  }),
  curp: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .matches(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_REQUIRED')
  }),
  personType: Yup.string().required('SCHEMA_ERRORS.PERSON_TYPE_IS_REQUIRED')
});
