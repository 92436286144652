import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AlertTitle, Typography } from '@mui/material';

const AlertMessage = ({
  open,
  title,
  text,
  handleClose,
  severity,
  hideCloseIcon = false,
  autoTranslation = true,
  textStyle = {}
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            !hideCloseIcon ? (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ) : null
          }
          sx={{ borderRadius: '10px' }}
          severity={severity}
        >
          <AlertTitle>{t(title)}</AlertTitle>

          <Typography
            sx={{
              ...textStyle
            }}
          >
            {autoTranslation ? t(text) : text}
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

AlertMessage.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  handleClose: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  hideCloseIcon: PropTypes.bool,
  autoTranslation: PropTypes.bool,
  textStyle: PropTypes.object
};

export default AlertMessage;
