import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography, FormHelperText, Box } from '@mui/material';
import CloudUpload from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from 'src/store/actions/snackBarActions';
import Dropzone from './Dropzone';

const useStyles = makeStyles(theme => ({
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 3,
    marginBottom: theme.spacing(),
    maxHeight: '3.5rem',
    minHeight: '3.4rem'
  },
  uploadContainerLabel: {
    marginTop: theme.spacing(),
    fontSize: '14px'
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
  },
  filename: {
    flex: 1,
    padding: '10px'
  },
  uploadButton: {
    color: theme.palette.primary.main
  },
  deleteButton: {
    color: '#EE3552'
  }
}));

const UploadFile = ({
  setFieldValue: setFieldValueProp,
  values,
  touched,
  errors,
  textLabel,
  valueLabel,
  accept
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const disabledIcon = true;

  const handleFileReject = useCallback(() => {
    dispatch(
      showMessage({
        message: 'INVALID_FILE_FORMAT',
        variant: 'error'
      })
    );
  }, [dispatch]);

  const handleOpenCurrentCard = useCallback(
    value => {
      if (value && value.inDb) {
        window.open(value.link, '_blank');
      }
    },
    [values]
  );

  const handleOnChangeFileInput = useCallback(
    setFieldValue => files => {
      setFieldValue(valueLabel, files[0]);
    },
    []
  );

  return (
    <>
      <Typography className={classes.uploadContainerLabel} variant="body1">
        {t(textLabel)}
      </Typography>
      <div className={classes.uploadContainer}>
        <div>
          {values[valueLabel] && (
            <Typography
              className={classes.filename}
              component="label"
              variant="body1"
              htmlFor={`icon-button-file-${valueLabel}`}
              onClick={() => handleOpenCurrentCard(values[valueLabel])}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#2B2C43'
              }}
            >
              {t(values[valueLabel].name)}
            </Typography>
          )}

          <label
            className={classes.uploadButton}
            htmlFor={`icon-button-file-${valueLabel}`}
          >
            {!values[valueLabel] && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                  cursor: 'pointer'
                }}
              >
                <CloudUpload sx={{ textAlign: 'center' }} />
                <Dropzone
                  handleFileChange={handleOnChangeFileInput(setFieldValueProp)}
                  acceptedFileFormat={accept}
                  handleFileReject={handleFileReject}
                  disabledIcon={disabledIcon}
                  sx={{
                    border: 'none !important',
                    width: '100% !important'
                  }}
                />
              </Box>
            )}
          </label>
        </div>
        {values[valueLabel] && (
          <IconButton
            onClick={() => setFieldValueProp(valueLabel, null)}
            aria-label="upload pdf"
            component="span"
          >
            <DeleteIcon className={classes.deleteButton} />
          </IconButton>
        )}
      </div>
      {Boolean(touched[valueLabel] && errors[valueLabel]) && (
        <FormHelperText error>{t(errors[valueLabel])}</FormHelperText>
      )}
    </>
  );
};

UploadFile.propTypes = {
  accept: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  valueLabel: PropTypes.string,
  textLabel: PropTypes.string,
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string })),
  touched: PropTypes.objectOf(PropTypes.bool),
  errors: PropTypes.objectOf(PropTypes.string)
};

export default UploadFile;
