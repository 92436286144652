import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';
import UserClientListView from './UserClientListView/UserClientListView';
import UserClientDetailView from './UserClientDetailView/UserClientDetailView';
import HomeView from './HomeView/HomeView';
import KamDetailView from './kam/KamDetailView';
import UserDistributorView from './UserDistributorView';
import KamQuotesView from './quotes';
import DirectorQuotesView from './quotes/DirectorQuotesView';
import QuoteDetailView from '../quotes/QuotesDetail';
import UserAvalDetailView from './UserAvalDetailView';

const UserView = ({ match }) => {
  const role = useSelector(selectAuthRole);
  return (
    <>
      <Route exact path={`${match.url}/home`} component={HomeView} />
      <Route
        exact
        path={`${match.url}/clients`}
        component={UserClientListView}
      />
      <Route
        exact
        path={`${match.url}/clients/:id`}
        component={UserClientDetailView}
      />
      <Route exact path={`${match.url}/kam/:id`} component={KamDetailView} />
      <Route
        exact
        path={`${match.url}/clients/:id/detail/:avalId/:tab?`}
        component={UserAvalDetailView}
      />
      <Route
        exact
        path={`${match.url}/distributor`}
        component={UserDistributorView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/home`} />}
      />
      <Route path={`${match.url}/quotes`}>
        {role === DistributorRoles.DIRECTOR ? (
          <DirectorQuotesView isDirectorView />
        ) : (
          <KamQuotesView />
        )}
      </Route>
      <Route
        path={`${match.url}/quote/detail/:id`}
        component={QuoteDetailView}
      />
    </>
  );
};

UserView.propTypes = {
  match: PropTypes.object.isRequired
};

export default UserView;
