import React from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmout';

const BillingRow = ({
  period,
  client,
  clientId,
  equipment,
  equipmentId,
  initialPayment,
  rent,
  contribution,
  monthlyPrincipal,
  contractId
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/contracts/${contractId}`}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            {`${contractId}`}
          </Typography>
        </Link>
      </TableCell>
      <TableCell align="center">{period}</TableCell>
      <TableCell
        style={{
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {client.length > 45 ? (
          <Tooltip title={client}>
            <Link to={`/clients/detail/${clientId}`}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.43'
                }}
              >
                {`${client.slice(0, 45)}...`}
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {client}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell align="left">
        <Link to={`/inventory/${equipmentId}`}>{equipment}</Link>
      </TableCell>
      <TableCell align="center">
        {formatPositiveAmount(initialPayment)}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(rent)}</TableCell>
      <TableCell align="center">{formatPositiveAmount(contribution)}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(monthlyPrincipal)}
      </TableCell>
    </TableRow>
  );
};

BillingRow.propTypes = {
  period: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  equipment: PropTypes.string.isRequired,
  equipmentId: PropTypes.string.isRequired,
  initialPayment: PropTypes.number.isRequired,
  rent: PropTypes.number.isRequired,
  contribution: PropTypes.number.isRequired,
  monthlyPrincipal: PropTypes.number.isRequired,
  contractId: PropTypes.string.isRequired
};

export default BillingRow;
