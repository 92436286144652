const InvestmentPaymentStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const TitleSignStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export default InvestmentPaymentStatus;
