// eslint-disable-next-line import/prefer-default-export
export const QuoteStatus = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  ARCHIVED: 'ARCHIVED'
};

export const MinInitialPaymentValues = {
  ZERO: 0,
  TEN: 10,
  TWENTY: 20,
  THIRTY: 30
};

export const MinAndMaxTermValues = {
  TWELVE: 12,
  EIGHTEEN: 18,
  TWENTY_FOUR: 24,
  THIRTY_SIX: 36,
  FORTY_EIGTH: 48
};

export const KamAssignationStatus = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED'
};

export const DirectorQuotesTabsIndex = {
  QUOTES: 'QUOTES',
  MY_QUOTES: 'MY_QUOTES'
};

export const QuoteCreationOption = {
  CREATE_QUOTE: 'CREATE_QUOTE',
  QUICK_QUOTE: 'QUICK_QUOTE',
  RENT_CALCULATOR: 'RENT_CALCULATOR'
};

export const PaymentInputOption = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT'
};

export const MaxGracePeriodForTwelveMonthsTerm = 60;
