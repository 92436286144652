import qs from 'qs';
import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getAllAgents = serviceBase({
  request: options =>
    axiosClient.get('/agent', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    }),
  retry: 0
});

export const downloadExportedAgents = serviceBase({
  request: options =>
    axiosClient.get('/agent/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Agentes.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const createAgent = serviceBase({
  request: values => axiosClient.post('/agent', values),
  retry: 0
});

export const getAgentDetail = serviceBase({
  request: id => axiosClient.get(`/agent/${id}`),
  retry: 0
});

export const updateAgent = serviceBase({
  request: ({ id, body }) => axiosClient.patch(`/agent/${id}`, body),
  retry: 0
});
