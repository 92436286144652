import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  validationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  validation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4)
  },

  validationIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%'
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
    marginBottom: theme.spacing(1),
    width: '80%'
  },
  tableContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowY: 'scroll',
    overflowX: 'hidden',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  outlined: {
    borderColor: '#FFFFFF',
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'end',
    marginTop: theme.spacing(1),
    flexDirection: 'column'
  },
  subTitle: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 450,
    textAlign: 'center'
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      boxShadow: 'none',
      width: '40vw',
      height: '40vh'
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  lastUpdateContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'start',
    justifyContent: 'start'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px'
  }
}));
