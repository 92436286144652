import * as Yup from 'yup';

export default Yup.object().shape({
  zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'SCHEMA_ERRORS.ZIP_CODE_INVALID')
    .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.ZIP_CODE_INVALID'),
  street: Yup.string(),
  insideHomeNumber: Yup.string().nullable(),
  externalHomeNumber: Yup.string(),
  colonia: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string()
});
