import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string()
    .matches(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED'),
  identificationNumber: Yup.string()
    .max(30, 'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.IDENTIFICATION_NUMBER_TOO_LONG')
    .required(
      'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.IDENTIFICATION_NUMBER_REQUIRED'
    ),
  curp: Yup.string()
    .matches(
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_REQUIRED'),
  birthDate: Yup.date()
    .typeError('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.BIRTH_DATE_REQUIRED')
    .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.BIRTH_DATE_REQUIRED'),
  birthState: Yup.string()
    .typeError('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.BIRTH_STATE_REQUIRED')
    .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.BIRTH_STATE_REQUIRED'),
  sex: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.GENDER_REQUIRED')
  }),
  yearsOperating: Yup.number(),
  montlyIncome: Yup.number(),
  isDoctorWithTitle: Yup.boolean()
});
