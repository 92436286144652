import React, { useEffect } from 'react';
import { Box, Card } from '@mui/material';
import Preloader from 'src/components/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CONTRACT_AVAL_LIST,
  loadContractAvalList
} from 'src/store/actions/contractActions';
import { selectAvalList } from 'src/store/selectors/contractSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AvalTable from './AvalTable';
import useStyles from './styles';

const AvalListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const avals = useSelector(selectAvalList);
  const loading = useSelector(
    isLoadingSelector([LOAD_CONTRACT_AVAL_LIST.action])
  );
  const success = useSelector(
    successSelector([LOAD_CONTRACT_AVAL_LIST.action])
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(loadContractAvalList(id));
  }, []);

  return (
    <Box mt={3}>
      {success && !loading ? (
        <AvalTable avals={avals} />
      ) : (
        <Card className={classes.preloaderCard}>
          <Preloader />
        </Card>
      )}
    </Box>
  );
};

export default AvalListView;
