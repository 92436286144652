/* eslint-disable no-restricted-syntax */
import { NufiParameter, NufiScoreSection } from './constants/parameters';

const filterNufiParameters = (params, type) => {
  const paramName = NufiParameter[type];
  let sectionValue = 0;
  const filterList = [];

  for (const param of params) {
    if (param.name === paramName[param.name]) {
      if (type === NufiScoreSection.VALIDATE_IDENTIFICATION) {
        if (
          param.name ===
          NufiParameter.VALIDATE_IDENTIFICATION.VALIDATE_INE_SCORE
        ) {
          sectionValue += Number(param.value);
        }
        filterList.push(param);
      } else {
        sectionValue += Number(param.value);
        filterList.push(param);
      }
    }
  }
  return {
    sectionValue,
    filterList
  };
};

export default filterNufiParameters;
