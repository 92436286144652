import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  documentLink: {
    color: theme.palette.primary.main,
    marginLeft: '8px',
    cursor: 'pointer'
  },
  documentIcon: {
    marginRight: '8px',
    size: 20
  },
  documentText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginTop: '-16px'
  }
}));
