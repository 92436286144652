import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import CounselorsListView from './Counselors';

const CounselorsView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/list`} component={CounselorsListView} />

      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/list`} />}
      />
    </>
  );
};

CounselorsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default CounselorsView;
