const makeInitialState = () => {
  return {
    name: '',
    denomination: '',
    commercialName: '',
    equipmentTypeId: '',
    zipCode: '',
    city: '',
    state: '',
    rfc: '',
    quoteCategory: '',
    quoteCategoryId: ''
  };
};

export default makeInitialState;
