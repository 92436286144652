import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Preloader from './Preloader';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500
  }
}));

const Loading = ({ label, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box marginBottom={2} sx={{ alignItems: 'center' }}>
      <Preloader />
      <Typography align="center" className={classes.title}>
        {label || t('COMMON.LOADING')}
      </Typography>
      {children}
    </Box>
  );
};

Loading.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
};

export default Loading;
