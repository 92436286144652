import moment from 'moment-timezone';
import * as Yup from 'yup';

export default Yup.object().shape({
  term: Yup.string().required('SCHEMA_ERRORS.TERM_REQUIRED'),
  initialPayment: Yup.string()
    .required('SCHEMA_ERRORS.INITIAL_PAYMENT_REQUIRED')
    .min(1, 'SCHEMA_ERRORS.INITIAL_PAYMENT_MIN_VALUE'),
  moreInfo: Yup.string(),
  initialPaymentDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(moment(), 'SCHEMA_ERRORS.DATE_CANNOT_BE_BEFORE_CURRENT_DATE'),
  firstRentDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
    .min(
      Yup.ref('initialPaymentDate'),
      'SCHEMA_ERRORS.FIRST_RENT_DATE_CANNOT_BE_BEFORE_INITIAL_PAYMENT_DATE'
    )
});
