/* eslint-disable import/prefer-default-export */
import { getBackgroundValidations } from 'src/services/backgroundValidationService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';
import {
  LOAD_BACKGROUND_VALIDATIONS,
  SAVE_BACKGROUND_VALIDATION_OPTIONS
} from '../constants/CFDI';
import { getBackgroundValidationOptions } from '../options/backgroundValidationOptions';

export const loadBackgroundValidations = (options, sendLimit) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_BACKGROUND_VALIDATIONS.start });

    dispatch({
      type: SAVE_BACKGROUND_VALIDATION_OPTIONS,
      options
    });
    const reqOptions = getBackgroundValidationOptions(getState, sendLimit);

    const response = await getBackgroundValidations({ options: reqOptions });

    return dispatch({
      type: LOAD_BACKGROUND_VALIDATIONS.success,
      payload: response
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_BACKGROUND_VALIDATIONS.failure });
  }
};
