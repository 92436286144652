import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  selectTransactionBalance,
  selectTransactionsList,
  selectTransactionsListCount,
  selectTransactionsQueryOptions
} from 'src/store/selectors/transactionSelectors';
import {
  CLEAR_TRANSACTIONS,
  createManualTransaction,
  loadClientTransaction,
  LOAD_TRANSACTIONS
} from 'src/store/actions/transactionActions';
import { useParams } from 'react-router';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import MenuButton from 'src/components/MenuButton/MenuButton';
import { CreateTransactionOptions } from 'src/utils/constants/transactions';
import Filter from 'src/components/Filter/Filter';
import useDebounce from 'src/hooks/useDebounce';
import { FilterList } from '@mui/icons-material';
import FILTER_TYPES from 'src/utils/constants/filter';
import useStyles from './styles';
import GenerateChargeDialog from './GenerateChargeDialog';
import GeneratePaymentDialog from './GenerateManualPaymentDialog';
import GeneratDiscountDialog from './GenerateManualDiscountDialog';
import AccountStatementTable from './AccountStatementTable';

function getFilterOptions(options) {
  let beforeDate = null;

  if (options.date) {
    beforeDate = options.date;
  }

  return { beforeDate };
}

const DialogOptions = {
  DISCOUNT: 'DISCOUNT',
  PAYMENT: 'PAYMENT',
  CHARGE: 'CHARGE'
};

const createTransactionsOptions = [
  {
    label: 'CLIENTS_VIEW.CLIENT_DETAIL.ACCOUNTING_STATEMENT.MANUAL_CHARGE',
    key: CreateTransactionOptions.CREATE_CHARGE,
    icon: <AddIcon />
  },
  {
    label: 'CLIENTS_VIEW.CLIENT_DETAIL.ACCOUNTING_STATEMENT.MANUAL_PAYMENT',
    key: CreateTransactionOptions.CREATE_PAYMENT,
    icon: <AddIcon />
  },
  {
    label: 'TRANSACTION.CATEGORY.DISCOUNT',
    key: CreateTransactionOptions.CREATE_DISCOUNT,
    icon: <AddIcon />
  }
];

const typeFilters = [
  {
    title: 'TRANSACTION.ACCOUTING_STATE_UNTIL',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date'
      }
    ]
  }
];

const AccountStatementDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transactionCount = useSelector(selectTransactionsListCount);
  const transactions = useSelector(selectTransactionsList);
  const balance = useSelector(selectTransactionBalance);
  const queryOptions = useSelector(selectTransactionsQueryOptions);

  const { id } = useParams();
  const loading = useSelector(isLoadingSelector([LOAD_TRANSACTIONS.action]));

  const [open, setOpen] = useState('');
  const [filterValues, setFilterValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const debounceFilter = useDebounce(
    queryOpts => dispatch(loadClientTransaction(id, { ...queryOpts, page: 0 })),
    400
  );

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [setFilterValues, queryOptions, getFilterOptions]
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(() => {
    dispatch(loadClientTransaction(id, { page: 0 }));
    return () => {
      dispatch({ type: CLEAR_TRANSACTIONS });
    };
  }, [dispatch, id]);

  const handleMovementsChangePage = useCallback(
    (_, value) => {
      dispatch(loadClientTransaction(id, { page: value }));
    },
    [dispatch, id]
  );

  const handleOpenDiscountDialog = useCallback(() => {
    setOpen(DialogOptions.DISCOUNT);
  });

  const handleOpenPaymentDialog = useCallback(() => {
    setOpen(DialogOptions.PAYMENT);
  });

  const handleOpenChargeDialog = useCallback(() => {
    setOpen(DialogOptions.CHARGE);
  });

  const handleCloseDialog = useCallback(() => {
    setOpen('');
  });

  const handleCreateTransactionOptions = value => {
    switch (value) {
      case CreateTransactionOptions.CREATE_CHARGE:
        handleOpenChargeDialog();
        break;
      case CreateTransactionOptions.CREATE_PAYMENT:
        handleOpenPaymentDialog();
        break;
      case CreateTransactionOptions.CREATE_DISCOUNT:
        handleOpenDiscountDialog();
        break;
      default:
        break;
    }
  };

  const handleSubmitTransaction = useCallback((values, handleClose) => {
    dispatch(
      createManualTransaction(
        {
          ...values,
          clientId: Number(id)
        },
        handleClose
      )
    );
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {`${t('NAVIGATION.ACCOUNTING_STATE')} `}
              <Typography
                style={{ fontWeight: '400' }}
                color="primary"
                variant="h3"
                display="inline"
              >
                {t('COMMON.AMOUNT_IVA_INCLUDED')}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={12}>
          <Grid container className={classes.transactionContainer}>
            <Grid item lg={4} className={classes.transaction}>
              <Box className={classes.transactionIcon}>
                <MoneyIcon fill="#28A745" />
              </Box>
              <Grid item>
                <Typography
                  style={{ marginBottom: 8 }}
                  color="textPrimary"
                  component="p"
                  variant="body2"
                >
                  {t('COMMON.BALANCE')}
                </Typography>
                <Typography color="primary" component="h2" variant="h2">
                  {formatAmountWithDecimals(balance, 1)}
                </Typography>
              </Grid>
            </Grid>
            <MenuButton
              label={t('COMMON.CREATE_TRANSACTION')}
              options={createTransactionsOptions}
              handleClickOption={handleCreateTransactionOptions}
              styles={{ width: 220 }}
            />
            <Grid
              item
              lg={8}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'start'
              }}
            >
              <GenerateChargeDialog
                open={open === DialogOptions.CHARGE}
                handleClose={handleCloseDialog}
                submit={handleSubmitTransaction}
                clientId={id}
              />
              <GeneratePaymentDialog
                open={open === DialogOptions.PAYMENT}
                handleClose={handleCloseDialog}
                submit={handleSubmitTransaction}
                clientId={id}
              />
              <GeneratDiscountDialog
                open={open === DialogOptions.DISCOUNT}
                handleClose={handleCloseDialog}
                submit={handleSubmitTransaction}
                clientId={id}
              />
            </Grid>
            <Box />
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem'
            }}
          >
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={typeFilters}
            />
            <Button
              className={classes.filterButton}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
          </Box>
          <AccountStatementTable
            className={classes.table}
            count={transactionCount}
            page={queryOptions.page}
            loading={loading}
            handleChangePage={handleMovementsChangePage}
            transactions={transactions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountStatementDetail;
