import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { InputAdornment, IconButton } from '@mui/material';
import FadeIn from 'react-fade-in';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import Footer from 'src/components/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { selectAuthId } from 'src/store/selectors/authSelectors';
import {
  USER_ASSIGN_PASSWORD,
  assignPassword
} from 'src/store/actions/authActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import theme from 'src/theme';
import validationSchema from './schema';
import makeIntiailValues from './initialState';
import useStyles from '../styles';

const AssignPasswordView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = makeIntiailValues();
  const userId = useSelector(selectAuthId);

  const loadingAssignPassword = useSelector(
    isLoadingSelector([USER_ASSIGN_PASSWORD.action])
  );
  const successAssignPassword = useSelector(
    successSelector([USER_ASSIGN_PASSWORD.action])
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const timeoutRef = useRef(null);

  const handleLogin = useCallback(
    ({ password }, actions) => {
      timeoutRef.current = setTimeout(() => {
        actions.setSubmitting(false);
      }, [200]);
      dispatch(assignPassword(userId, password));
    },
    [dispatch, timeoutRef]
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  const trimWhitespaces = useCallback(
    setFieldValue => e => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
    []
  );

  return (
    <MainLayout>
      <Page
        className={classes.root}
        title={t('LOGIN_VIEW.ASSIGN_PASSWORD')}
        sx={{
          padding: {
            xs: `${theme.spacing(4)} ${theme.spacing(3.5)}`,
            sm: `${theme.spacing(4)} ${theme.spacing(10)}`
          }
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </Box>
          <Container
            className={classes.login}
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '80%'
              }
            }}
          >
            <FadeIn transitionDuration={200}>
              {!successAssignPassword && !loadingAssignPassword ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  {({
                    errors,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box
                        mb={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className={classes.title}
                          sx={{
                            fontSize: {
                              xs: theme.spacing(3.5),
                              sm: theme.spacing(5),
                              md: theme.spacing(6),
                              lg: theme.spacing(7)
                            }
                          }}
                        >
                          {t('LOGIN_VIEW.ASSIGN_PASSWORD')}
                        </Typography>
                      </Box>
                      <Box className={classes.center}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.password && errors.password)}
                          helperText={
                            touched.password ? t(errors.password) : null
                          }
                          label={t('LOGIN_VIEW.PASSWORD')}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={trimWhitespaces(setFieldValue)}
                          InputProps={{
                            autoComplete: 'off',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          variant="outlined"
                        />

                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                          helperText={
                            touched.confirmPassword
                              ? t(errors.confirmPassword)
                              : null
                          }
                          label={t('LOGIN_VIEW.CONFIRM_PASSWORD')}
                          margin="normal"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={trimWhitespaces(setFieldValue)}
                          InputProps={{
                            autoComplete: 'off',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showPassword ? 'text' : 'password'}
                          value={values.confirmPassword}
                          variant="outlined"
                        />

                        <Box
                          my={3}
                          className={classes.center}
                          sx={{
                            width: '100%'
                          }}
                        >
                          <Button
                            className={classes.button}
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {t('COMMON.CONFIRM')}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <Box>
                  <Loading
                    label={t('AUTH_VIEWS.THE_PASSWORD_IS_BEING_ASSIGNED')}
                  />
                </Box>
              )}
            </FadeIn>
          </Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography>
              {t('LOGIN_VIEW.YOU_HAVE_AN_ACCOUNT')}
              &nbsp;
              <Link to="/user/login">{t('LOGIN_VIEW.CLICK_HERE')}</Link>
            </Typography>
          </Box>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default AssignPasswordView;
