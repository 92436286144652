import { selectCFDISQueryOptions } from '../selectors/cfdiSelectors';

export const getCFDIsIncomeOptions = (getState, sendLimit = true) => {
  const queryOpts = selectCFDISQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }

  if (queryOpts.issuer) {
    reqOptions.issuer = queryOpts.issuer;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  return reqOptions;
};

export const getCFDIsExpenseOptions = (getState, sendLimit = true) => {
  const queryOpts = selectCFDISQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
};

export const getCFDIOptions = (getState, sendLimit = true) => {
  const queryOpts = selectCFDISQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.client) {
    reqOptions.name = queryOpts.client;
  }
  if (queryOpts.rfc) {
    reqOptions.rfc = queryOpts.rfc;
  }
  if (queryOpts.folio) {
    reqOptions.folio = queryOpts.folio;
  }
  if (queryOpts.issuer) {
    reqOptions.issuer = queryOpts.issuer;
  }

  if (queryOpts.type) {
    reqOptions.type = queryOpts.type;
  }
  if (queryOpts.series) {
    reqOptions.series = queryOpts.series;
  }

  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }

  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
};

export function getCFDIsPaymentOptions(getState, sendLimit = true) {
  const queryOpts = selectCFDISQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }

  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }

  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const getCFDIReceivedOptions = (getState, sendLimit = true) => {
  const queryOpts = selectCFDISQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.client) {
    reqOptions.name = queryOpts.client;
  }
  if (queryOpts.rfc) {
    reqOptions.rfc = queryOpts.rfc;
  }
  if (queryOpts.folio) {
    reqOptions.folio = queryOpts.folio;
  }

  if (queryOpts.type) {
    reqOptions.type = queryOpts.type;
  }

  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }

  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
};
