import React from 'react';
import PropTypes from 'prop-types';
import { GetApp } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import theme from 'src/theme';
import { useTranslation } from 'react-i18next';

function DownloadButton({ onDownload, title, disabled, ...props }) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        disabled ? (
          <span style={{ whiteSpace: 'pre-line' }}>
            {t('COMMON.NO_DOWNLOAD_DATA')}
          </span>
        ) : (
          <span>{t(title) ?? t('COMMON.DOWNLOAD_EXCEL')}</span>
        )
      }
    >
      <span>
        <Button
          sx={{
            height: 44,
            letterSpacing: 1.25,
            fontSize: 16,
            fontWeight: '400',
            minWidth: 'unset',
            background: '#FFFFFF',
            '&:hover': {
              background: '#FFFFFF',
              opacity: 0.8
            },
            '&:focus': {
              background: '#FFFFFF',
              opacity: 0.8
            },
            color: 'primary'
          }}
          onClick={onDownload}
          disabled={disabled}
          {...props}
        >
          <GetApp sx={{ fill: theme.palette.primary.main }} />
        </Button>
      </span>
    </Tooltip>
  );
}
DownloadButton.propTypes = {
  onDownload: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool
};

export default DownloadButton;
