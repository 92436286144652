import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AdminPrivateRoute from 'src/components/PrivateRotes/AdminPrivateRoute';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import AdministratorsListView from './administrators';

const AdministratorsView = ({ match }) => {
  return (
    <>
      <AdminPrivateRoute
        path={`${match.url}/administrators`}
        component={AdministratorsListView}
      />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/administrators`} />}
      />
    </>
  );
};

AdministratorsView.propTypes = {
  match: PropTypes.object.isRequired
};

export default AdministratorsView;
