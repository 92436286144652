import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import CatalogsRow from './CatalogsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ catalogs, isDisableActions }) =>
  catalogs.map(catalog => (
    <CatalogsRow
      key={catalog.id}
      isDisableActions={isDisableActions}
      {...catalog}
    />
  ));

const CatalogsTable = ({
  className,
  catalogs,
  count,
  page,
  handleChangePage,
  loading,
  isDisableActions,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell variant="head" key="id" style={{ width: '5%' }}>
        {t('COMMON.ID')}
      </TableCell>
      <TableCell variant="head" key="brand" style={{ width: '15%' }} align="center">
        {t('COMMON.BRAND')}
      </TableCell>
      <TableCell variant="head" key="model" style={{ width: '15%' }} align="center">
        {t('COMMON.MODEL')}
      </TableCell>
      <TableCell
        variant="head"
        key="price"
        sx={{ width: '15%' }}
        align="center"
      >
        {t('COMMON.PRICE')}
      </TableCell>
      <TableCell variant="head" key="iva" sx={{ width: '10%' }} align="center">
        {`${t('COMMON.IVA')} %`}
      </TableCell>
      <TableCell
        variant="head"
        key="price_with_iva"
        sx={{ width: '20%' }}
        align="center"
      >
        {t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
      </TableCell>

      <TableCell
        variant="head"
        key="equipment_type"
        sx={{ width: '10%' }}
        align="center"
      >
        {t('COMMON.MOVIL')}
      </TableCell>
      <TableCell variant="head" key="options" align="left" sx={{ width: '5%' }}>
        {}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody catalogs={catalogs} isDisableActions={isDisableActions} />
    ),
    [catalogs]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, catalogs]
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CatalogsTable.propTypes = {
  className: PropTypes.string,
  catalogs: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  isDisableActions: PropTypes.bool
};

CatalogsTable.defaultProps = {
  count: 0
};

export default CatalogsTable;
