import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'src/components/DropdownMenu';
import DialogTransition from 'src/components/DialogTransition';
import { Gender } from 'src/utils/constants/clients';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const ClientGenderType = [
  { name: 'COMMON.MALE', key: Gender.MALE },
  { name: 'COMMON.FEMALE', key: Gender.FEMALE }
];

const GeneralDetailDialogLegalClient = ({
  open,
  aval,
  handleClose,
  submit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(aval), [aval]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      submit(diference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.AVAL_DETAIL.GENERAL_AVAL_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.paternalName && errors.paternalName)}
                  fullWidth
                  helperText={touched.paternalName && t(errors.paternalName)}
                  label={t('COMMON.PATERNAL_NAME')}
                  margin="normal"
                  name="paternalName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.paternalName}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.maternalName && errors.maternalName)}
                  fullWidth
                  helperText={touched.maternalName && t(errors.maternalName)}
                  label={t('COMMON.MATERNAL_NAME')}
                  margin="normal"
                  name="maternalName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.maternalName}
                  variant="outlined"
                />
                <DropdownMenu
                  list={ClientGenderType}
                  value={values.sex}
                  setValue={value => setFieldValue('sex', value)}
                  label={t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
                  error={Boolean(touched.sex && errors.sex)}
                  errorText={touched.sex && t(errors.sex)}
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.rfc && errors.rfc)}
                  fullWidth
                  helperText={touched.rfc && t(errors.rfc)}
                  label={t('COMMON.RFC')}
                  margin="normal"
                  disabled={!!aval}
                  name="rfc"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.rfc}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.curp && errors.curp)}
                  fullWidth
                  helperText={touched.curp && t(errors.curp)}
                  label="CURP"
                  margin="normal"
                  name="curp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.curp}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.identificationNumber && errors.identificationNumber
                  )}
                  fullWidth
                  helperText={
                    touched.identificationNumber &&
                    t(errors.identificationNumber)
                  }
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER')}
                  margin="normal"
                  name="identificationNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.identificationNumber}
                  variant="outlined"
                />

                {/* Only on edit */}
                {aval && (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.shareholdingPercentage &&
                        errors.shareholdingPercentage
                    )}
                    fullWidth
                    helperText={
                      touched.shareholdingPercentage &&
                      t(errors.shareholdingPercentage)
                    }
                    label={t(
                      'CLIENTS_VIEW.CLIENT_DETAIL.SHAREHOLDING_PERCENTAGE'
                    )}
                    margin="normal"
                    name="shareholdingPercentage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.shareholdingPercentage}
                    variant="outlined"
                  />
                )}

                {aval.isLegalRepresentative && (
                  <>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.deedNumberPowerAttorney &&
                          errors.deedNumberPowerAttorney
                      )}
                      fullWidth
                      helperText={
                        touched.deedNumberPowerAttorney &&
                        t(errors.deedNumberPowerAttorney)
                      }
                      label={t(
                        'CLIENTS_VIEW.CLIENT_DETAIL.POWER_OF_ATTORNEY_NUMBER'
                      )}
                      margin="normal"
                      name="deedNumberPowerAttorney"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.deedNumberPowerAttorney}
                      variant="outlined"
                    />

                    <DatePicker
                      label={t(
                        'CLIENTS_VIEW.CLIENT_DETAIL.POWER_OF_ATTORNEY_DATE'
                      )}
                      value={values.deedDatePowerAttorney}
                      onChange={newValue => {
                        setFieldValue(
                          'deedDatePowerAttorney',
                          moment(newValue)
                        );
                      }}
                      error={Boolean(
                        touched.deedDatePowerAttorney &&
                          errors.deedDatePowerAttorney
                      )}
                      renderInput={params => (
                        <TextField
                          InputProps={{
                            autoComplete: 'off'
                          }}
                          {...params}
                          sx={{ width: '100%', marginTop: 2, marginBottom: 1 }}
                          helperText={
                            touched.deedDatePowerAttorney &&
                            t(errors.deedDatePowerAttorney)
                          }
                        />
                      )}
                    />
                  </>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

GeneralDetailDialogLegalClient.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  aval: PropTypes.object
};

export default GeneralDetailDialogLegalClient;
