import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';
import { useParams } from 'react-router';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import DetailCard from 'src/components/DetailBox/DetailCard';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HistoryIcon from '@mui/icons-material/History';
import agentOptions from 'src/utils/constants/agents';
import AgentAssignationDialog from 'src/components/AgentAssignationDialog/AgentAssignationDialog';
import { assingAgent, unassignAgent } from 'src/store/actions/investorActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AgentHistoryDialog from 'src/components/AgentHistoryDialog/AgentHistoryDialog';

const getAssignationOptions = agent => [
  {
    name: agentOptions.AGENT_ASSIGNATION,
    label: 'AGENTS.AGENT_ASSIGNATION',
    icon: <AddIcon />
  },
  {
    name: agentOptions.UNASSIGN_AGENT,
    label: 'AGENTS.UNASSIGN_AGENT',
    icon: <HighlightOffIcon />,
    disabled: !agent?.id
  },
  {
    name: agentOptions.HISTORY,
    label: 'AGENTS.AGENT_HISTORY',
    icon: <HistoryIcon />
  }
];

const AgentAssignedDetail = ({ agent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openAssignation, setOpenAssignation] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const { id } = useParams();

  const toggleAssignationDialog = () => {
    setOpenAssignation(!openAssignation);
  };

  const handleHistory = () => {
    setOpenHistory(!openHistory);
  };

  const handleClickOption = option => {
    switch (option) {
      case agentOptions.AGENT_ASSIGNATION:
        toggleAssignationDialog();
        break;
      case agentOptions.UNASSIGN_AGENT:
        dispatch(unassignAgent(Number(id)));
        break;
      case agentOptions.HISTORY:
        handleHistory();
        break;
      default:
        break;
    }
  };

  const handleAssingAgent = useCallback(
    values => {
      dispatch(assingAgent(id, values.agentId, toggleAssignationDialog));
    },
    [id]
  );

  return (
    <DetailCard title={t('NAVIGATION.AGENT')}>
      <DetailRow
        name={t('COMMON.NAME')}
        value={
          agent?.fullName ? (
            <Link to={`/investors/agents/detail/${agent.id}`}>
              {agent.fullName}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )
        }
      >
        <MoreMenu
          options={getAssignationOptions(agent)}
          deleteDisabled
          editDisabled
          handleClickOption={handleClickOption}
        />
      </DetailRow>
      <AgentAssignationDialog
        open={openAssignation}
        handleClose={toggleAssignationDialog}
        onSubmit={handleAssingAgent}
        agent={agent}
      />
      <AgentHistoryDialog
        open={openHistory}
        handleClose={handleHistory}
        id={id}
      />
    </DetailCard>
  );
};

AgentAssignedDetail.propTypes = {
  agent: PropTypes.object.isRequired
};

export default AgentAssignedDetail;
