import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  transactionContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  transaction: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4)
  },
  transactionIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset'
  }
}));
