import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectClientsList } from 'src/store/selectors/clientSelectors';
import {
  selectDistributorAgents,
  selectDistributorList
} from 'src/store/selectors/distributorSelectors';
import { capitalizeName, getClientFullName } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_DISTRIBUTOR_AGENTS,
  loadDistributorAgents
} from 'src/store/actions/distributorActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { LOAD_CLIENTS } from 'src/store/actions/clientActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from '../../initialState';
import KamAutocomplete from './KamAutocomplete';
import QuoteCreationAlert from './QuoteCreationAlert';

const StepOne = ({ data, handleNext, resetRef }) => {
  const classes = useStyles();
  const initialState = makeInitialState(data);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clients = useSelector(selectClientsList);
  const distributors = useSelector(selectDistributorList);
  const distributorProfiles = useSelector(selectDistributorAgents);
  const isLoadingDistributor = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );
  const isLoadingClients = useSelector(
    isLoadingSelector([LOAD_CLIENTS.action])
  );

  const isLoadingDistributorProfiles = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext]
  );

  const handleDistributorChange = useCallback((distributor, setFieldValue) => {
    setFieldValue('distributor', distributor);
    setFieldValue('distributorProfile', '');
    setFieldValue('distributorEquipment', '');
    setFieldValue('price', '');
    if (distributor.id) {
      dispatch(loadDistributorAgents(distributor.id, null, false));
    }
  }, []);

  const handleClientChange = useCallback((value, setFieldValue) => {
    setFieldValue('client', value ?? '');
    setFieldValue('distributorProfile', '');
  }, []);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm
        }) => {
          resetRef.current = () => resetForm();

          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <QuoteCreationAlert
                  selectedClient={values.client}
                  selectedDistributor={values.distributor}
                  loading={
                    isLoadingDistributorProfiles ||
                    isLoadingDistributor ||
                    isLoadingClients
                  }
                  kams={distributorProfiles}
                  clients={clients}
                  distributors={distributors}
                />
              </Box>
              <Box className={classes.container}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.client && errors.client)}
                >
                  <Autocomplete
                    options={clients}
                    clearIcon={null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ''
                    }
                    id="client"
                    loading={isLoadingClients}
                    getOptionLabel={option =>
                      option
                        ? `${option.id} - ${getClientFullName(option)}`
                        : ''
                    }
                    value={values.client ?? ''}
                    fullWidth
                    onChange={(_e, value) => {
                      handleClientChange(value, setFieldValue);
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    label={t('COMMON.CLIENT')}
                    helperText={touched.client && t(errors.client)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(touched.distributor && errors.distributor)}
                >
                  <Autocomplete
                    options={distributors}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ''
                    }
                    id="distributor"
                    loading={isLoadingDistributor}
                    getOptionLabel={option =>
                      option
                        ? `${option.id} - ${capitalizeName(option.name)}`
                        : ''
                    }
                    value={values.distributor ?? ''}
                    fullWidth
                    clearIcon={null}
                    onChange={(_e, value) => {
                      handleDistributorChange(value, setFieldValue, values);
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    label={t('COMMON.DISTRIBUTOR')}
                    helperText={touched.distributor && t(errors.distributor)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorProfile && errors.distributorProfile
                  )}
                >
                  <KamAutocomplete
                    options={distributorProfiles}
                    id="distributorProfile"
                    value={values.distributorProfile ?? ''}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('distributorProfile', value ?? '');
                    }}
                    label={`${t('COMMON.KAM')} (${t('COMMON.OPTIONAL')})`}
                    error={
                      touched.distributorProfile && errors.distributorProfile
                    }
                    helperText={
                      touched.distributorProfile && t(errors.distributorProfile)
                    }
                  />
                </FormControl>
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepOne;
