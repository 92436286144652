import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  createInverstor,
  createInvestorKycDocument,
  downloadExportedInvestors,
  getAllInvestors,
  getInvesmentsByInvestorId,
  getInvestorDetail,
  getInvestorIdentityValidations,
  getInvestorKYCDetail,
  searchInvestorByRfc,
  sendKYCToSign,
  sendInvestorToIdentityValidate,
  updateInvestor,
  assignAgenToInvestor,
  unassignAgent as unassignAgentService,
  getAgentInvestorHistory
} from 'src/services/investorService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { push } from 'connected-react-router';
import { removePointsPhone } from 'src/utils/common';
import { showMessage } from './snackBarActions';
import {
  selectInvestorInvestmentsQueryOptions,
  selectInvestorQueryOptions
} from '../selectors/investorSelector';

export const SAVE_INVESTOR_OPTIONS = 'SAVE_INVESTOR_OPTIONS';
export const SAVE_INVESTOR_INVESTMENTS_OPTIONS =
  'SAVE_INVESTOR_INVESTMENTS_OPTIONS';
export const CLEAR_INVESTOR_OPTIONS = 'CLEAR_INSVESTOR_OPTIONS';
export const LOAD_INVESTORS = asyncActionCreator('LOAD_INVESTORS');
export const LOAD_INVESTORS_LIST = asyncActionCreator('LOAD_INVESTORS_LIST');
export const LOAD_INVESTOR_DETAIL = asyncActionCreator('LOAD_INVESTOR_DETAIL');
export const UPDATE_INVESTOR = asyncActionCreator('UPDATE_INVESTOR');
export const CLEAR_INVESTORS = 'CLEAR_INVESTORS';
export const LOAD_INVESTOR_INVESMENTS = asyncActionCreator(
  'LOAD_INVESTOR_INVESMENTS'
);
export const CREATE_INVESTOR = asyncActionCreator('CREATE_INVESTOR');
export const SEARCH_INVESTOR = asyncActionCreator('SEARCH_INVESTOR');
export const CLEAR_INVESTOR_DETAIL = 'CLEAR_INVESTOR_DETAIL';
export const LOAD_INVESTOR_KYC_DETAIL = asyncActionCreator(
  'LOAD_INVESTOR_KYC_DETAIL'
);
export const LOAD_INVESTOR_IDENTITY_VALIDATION = asyncActionCreator(
  'LOAD_INVESTOR_IDENTITY_VALIDATION'
);
export const CLEAR_INVESTOR_IDENTITY_VALIDATION =
  'CLEAR_INVESTOR_IDENTITY_VALIDATION';
export const SEND_TO_INVESTOR_IDENTITY_VALIDATION = asyncActionCreator(
  'SEND_TO_INVESTOR_IDENTITY_VALIDATION'
);
export const GENERATE_KYC_DOCUMENT = asyncActionCreator(
  'GENERATE_KYC_DOCUMENT'
);
export const SEND_KYC_TO_SIGN = asyncActionCreator('SEND_KYC_TO_SIGN');
export const LOAD_AGENT_HISTORY = asyncActionCreator('LOAD_AGENT_HISTORY');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectInvestorQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getInvestorInvestmentOptions(getState, sendLimit = true) {
  const queryOpts = selectInvestorInvestmentsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_INVESTOR_OPTIONS });
};

export const loadInvestors = (options, sendLimit = true) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_INVESTORS.start });

    dispatch({
      type: SAVE_INVESTOR_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);

    const data = await getAllInvestors(reqOptions);

    return dispatch({
      type: LOAD_INVESTORS.success,
      ...data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTORS.failure });
  }
};

export const createInvestorAction = (values, handleClose) => async dispatch => {
  try {
    dispatch({ type: CREATE_INVESTOR.start });

    const { id } = await createInverstor({
      ...values,
      phone: removePointsPhone(values.phone)
    });

    handleClose();

    dispatch(
      showMessage({
        message: 'CREATE_INVESTOR',
        variant: 'success'
      })
    );

    dispatch({ type: CREATE_INVESTOR.success });
    return dispatch(push(`/investors/${id}`));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CREATE_INVESTOR.failure });
  }
};

export const searchInvestor = (rfc, handleBack) => async dispatch => {
  try {
    dispatch({ type: SEARCH_INVESTOR.start });

    const investor = await searchInvestorByRfc(rfc);

    if (Object.keys(investor).length) {
      handleBack();
      return dispatch(
        showMessage({
          message: 'INVESTOR_WITH_THAT_RFC_ALREADY_EXISTS',
          variant: SnackBarVariants.ERROR
        })
      );
    }

    return dispatch({ type: SEARCH_INVESTOR.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEARCH_INVESTOR.failure });
  }
};

export const downloadExcelInvestors = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadExportedInvestors(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadInvestorDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INVESTOR_DETAIL.start });

    const data = await getInvestorDetail(id);

    return dispatch({
      type: LOAD_INVESTOR_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTOR_DETAIL.failure });
  }
};

export const loadInvestorKYCDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INVESTOR_KYC_DETAIL.start });

    const data = await getInvestorKYCDetail(id);

    return dispatch({
      type: LOAD_INVESTOR_KYC_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTOR_KYC_DETAIL.failure });
  }
};

export const editInvestor = (
  id,
  values,
  closeCallback,
  loadCallback = loadInvestorDetail
) => async dispatch => {
  try {
    let body = values;
    dispatch({ type: UPDATE_INVESTOR.start });

    let { phone, secondaryPhone } = values;

    if (values.phone) {
      phone = removePointsPhone(values.phone);
      body = {
        ...body,
        phone
      };
    }
    if (values.secondaryPhone) {
      secondaryPhone = removePointsPhone(values.secondaryPhone);
      body = {
        ...body,
        secondaryPhone
      };
    }

    await updateInvestor({
      id,
      body
    });

    closeCallback();

    dispatch(
      showMessage({
        message: 'UPDATE_INVESTOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: UPDATE_INVESTOR.success });

    return dispatch(loadCallback(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_INVESTOR.failure });
  }
};

export const loadInvestmentsByInvestorId = (investorId, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_INVESTOR_INVESMENTS.start });

    dispatch({
      type: SAVE_INVESTOR_INVESTMENTS_OPTIONS,
      options
    });

    const reqOptions = getInvestorInvestmentOptions(getState);

    const data = await getInvesmentsByInvestorId(investorId, reqOptions);

    return dispatch({
      type: LOAD_INVESTOR_INVESMENTS.success,
      ...data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTOR_INVESMENTS.failure });
  }
};

export const loadInvestorIdentityValidation = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INVESTOR_IDENTITY_VALIDATION.start });

    const data = await getInvestorIdentityValidations(id);

    return dispatch({
      type: LOAD_INVESTOR_IDENTITY_VALIDATION.success,
      payload: data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INVESTOR_IDENTITY_VALIDATION.failure });
  }
};

export const generateKYCDocument = id => async dispatch => {
  try {
    dispatch({ type: GENERATE_KYC_DOCUMENT.start });

    await createInvestorKycDocument(id);

    dispatch(
      showMessage({
        message: 'GENERATE_KYC_DOCUMENT',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: GENERATE_KYC_DOCUMENT.success
    });
    return dispatch(loadInvestorKYCDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: GENERATE_KYC_DOCUMENT.failure });
  }
};

export const sendKYCToSignAction = id => async dispatch => {
  try {
    dispatch({ type: SEND_KYC_TO_SIGN.start });

    await sendKYCToSign(id);

    dispatch(
      showMessage({
        message: 'SEND_KYC_TO_SIGN',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: SEND_KYC_TO_SIGN.success
    });
    return dispatch(loadInvestorKYCDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: GENERATE_KYC_DOCUMENT.failure });
  }
};

export const sendToValidateInvestorIdentity = (
  investorId,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_INVESTOR_IDENTITY_VALIDATION.start });

    await sendInvestorToIdentityValidate(investorId);

    dispatch(
      showMessage({
        message: 'VALIDATE_SUCCESS',
        variant: 'success'
      })
    );

    dispatch(loadInvestorIdentityValidation(investorId));

    closeCallback();

    return dispatch({
      type: SEND_TO_INVESTOR_IDENTITY_VALIDATION.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_INVESTOR_IDENTITY_VALIDATION.failure });
  }
};

export const assingAgent = (id, agentId, closeCallback) => async dispatch => {
  try {
    dispatch({ type: UPDATE_INVESTOR.start });
    const body = {
      agentId: Number(agentId),
      investorId: Number(id)
    };

    await assignAgenToInvestor(body);

    closeCallback();

    dispatch(
      showMessage({
        message: 'UPDATE_INVESTOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: UPDATE_INVESTOR.success });

    return dispatch(loadInvestorDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_INVESTOR.failure });
  }
};

export const unassignAgent = id => async dispatch => {
  try {
    dispatch({ type: UPDATE_INVESTOR.start });

    await unassignAgentService(id);

    dispatch(
      showMessage({
        message: 'UPDATE_INVESTOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: UPDATE_INVESTOR.success });

    return dispatch(loadInvestorDetail(id));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: UPDATE_INVESTOR.failure });
  }
};

export const loadAgentHistory = (id, setState) => async dispatch => {
  try {
    dispatch({ type: LOAD_AGENT_HISTORY.start });

    const data = await getAgentInvestorHistory(id);

    setState(data);

    return dispatch({
      type: LOAD_AGENT_HISTORY.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_AGENT_HISTORY.failure });
  }
};

export const loadInvestorsList = setState => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INVESTORS_LIST.start });

    const reqOptions = getOptions(getState, false);

    const data = await getAllInvestors(reqOptions);

    setState(data.items);

    return dispatch({
      type: LOAD_INVESTORS_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: LOAD_INVESTORS_LIST.failure });
  }
};
