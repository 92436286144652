import getUserProfile, {
  updateUserInfo
} from 'src/services/userProfileService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { showMessage } from './snackBarActions';

export const LOAD_USER_PROFILE = asyncActionCreator('LOAD_USER_PROFILE');
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const UPDATE_USER_PROFILE = asyncActionCreator('UPDATE_KAM_PROFILE');

export const loadUserProfile = () => async dispatch => {
  try {
    dispatch({ type: LOAD_USER_PROFILE.start });

    const userData = await getUserProfile();

    return dispatch({
      type: LOAD_USER_PROFILE.success,
      userData
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_USER_PROFILE.failure });
  }
};

export const updateUserProfile = values => async dispatch => {
  try {
    dispatch({ type: UPDATE_USER_PROFILE.start });

    const userData = await updateUserInfo(values);

    dispatch(
      showMessage({ message: 'USER_PROFILE_UPDATED', variant: 'success' })
    );

    dispatch(loadUserProfile());

    return dispatch({
      type: UPDATE_USER_PROFILE.success,
      userData
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_USER_PROFILE.failure });
  }
};
