import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Loading from 'src/components/Loading';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { updateDistributorParams } from 'src/store/actions/distributorActions';
import Button from 'src/components/Button';
import DetailCard from 'src/components/DetailBox/DetailCard';
import ParameterDetailInfo from './ParameterDetailInfo';
import EditParameterDialog from './EditParameterDialog';

const ParameterDetail = ({ distributor, loading }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleOpenEdit = () => {
    setOpenEditDialog(true);
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(updateDistributorParams(id, values, handleCloseEdit));
    },
    [distributor, dispatch]
  );

  return (
    <DetailCard title={t('NAVIGATION.PARAMETERS')}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ParameterDetailInfo distributor={distributor} />
          <EditParameterDialog
            parameter={distributor}
            open={openEditDialog}
            handleClose={handleCloseEdit}
            onSubmit={handleSubmit}
          />
          <Grid container direction="column" alignItems="stretch">
            <Grid
              item
              sx={{
                marginTop: '16px',
                padding: '0 16px'
              }}
            >
              <Button onClick={handleOpenEdit}>{t('COMMON.EDIT')}</Button>
            </Grid>
          </Grid>
        </>
      )}
    </DetailCard>
  );
};

ParameterDetail.propTypes = {
  distributor: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ParameterDetail;
