import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_LINE_OF_BUSINESS, loadLineOfBusiness } from 'src/store/actions/catalogActions';
import { selectLineOfBusinessList } from 'src/store/selectors/catalogSelectors';
import LineOfBusinessTable from './LineOfBusinessTable';

const LineOfBusinessList = () => {
  const dispatch = useDispatch();
  const linesOfBusiness = useSelector(selectLineOfBusinessList);
  const loading = useSelector(isLoadingSelector([LOAD_LINE_OF_BUSINESS.action]));
  const success = useSelector(successSelector([LOAD_LINE_OF_BUSINESS.action]));

  useEffect(() => {
    dispatch(loadLineOfBusiness());
  }, []);

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <LineOfBusinessTable
            linesOfBusiness={linesOfBusiness}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LineOfBusinessList;
