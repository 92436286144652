import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { CommonActions } from 'src/utils/constants/clients';

const EquipmentTypeRow = ({ name, productKey, id, openEdit, handleDelete }) => {
  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      openEdit();
    }
    if (option === CommonActions.DELETE) {
      handleDelete(id);
    }
  };

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell align="center">{productKey}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>
    </TableRow>
  );
};

EquipmentTypeRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  productKey: PropTypes.string,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

export default EquipmentTypeRow;
