import * as Yup from 'yup';

export default Yup.object().shape({
  iva: Yup.number()
    .min(1, 'SCHEMA_ERRORS.NUMBER_MUST_BE_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .typeError('SCHEMA_ERRORS.NUMBER_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  model: Yup.string().required('SCHEMA_ERRORS.SELECT_VALID_VALUE'),
  price: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  brand: Yup.object().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  isMovable: Yup.bool().required('SCHEMA_ERRORS.VALUE_REQUIRED')
});
