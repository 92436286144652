import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import {
  LOAD_COUNSELORS_LIST,
  loadCounselorsList,
  loadCounselorsSignatories
} from 'src/store/actions/counselorActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';
import CounselorsAlert from './CounselorsAlert';

const AssignedSignatoriesDialog = ({ open, handleClose, submit }) => {
  const { t } = useTranslation();
  const resetRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [counselorList, setCounselorList] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const initialState = useMemo(() => makeInitialState(signatories), [
    signatories
  ]);

  const loadingCounselors = useSelector(
    isLoadingSelector([LOAD_COUNSELORS_LIST.action])
  );

  const handleOuterSubmit = useCallback(
    values => {
      submit(values);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadCounselorsSignatories(setSignatories));
      dispatch(loadCounselorsList(setCounselorList));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COUNSELORS.ASSIGN_SIGNATORIES')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {loadingCounselors ? (
          <Loading
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            label={t('COUNSELORS.LOADING_COUNSELORS')}
          />
        ) : (
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
            enableReinitialize
          >
            {({
              errors,
              setFieldValue,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm();
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" alignItems="stretch">
                    <CounselorsAlert
                      counselors={counselorList}
                      loading={loadingCounselors}
                    />
                    <Grid item>
                      <FormControl fullWidth>
                        <Autocomplete
                          options={counselorList.filter(
                            counselor =>
                              counselor.id !== values.counselorsIds[1]
                          )}
                          id="counselorsIds[0]"
                          getOptionLabel={option => {
                            return option ? t(option.fullName) : '';
                          }}
                          value={
                            counselorList.find(
                              counselor =>
                                counselor.id === values.counselorsIds[0]
                            ) ?? ''
                          }
                          keepMounted
                          isLoading={loadingCounselors}
                          fullWidth
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={(_e, value) => {
                            setFieldValue(
                              'counselorsIds[0]',
                              value?.id ?? null
                            );
                          }}
                          helperText={
                            touched.counselorsIds && t(errors.counselorsIds)
                          }
                          label={t('COMMON.COUNSELOR')}
                          error={Boolean(
                            touched.counselorsIds && errors.counselorsIds
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Autocomplete
                          options={counselorList.filter(
                            counselor =>
                              counselor.id !== values.counselorsIds[0]
                          )}
                          id="investorIds[1]"
                          getOptionLabel={option => {
                            return option ? t(option.fullName) : '';
                          }}
                          value={
                            counselorList.find(
                              counselor =>
                                counselor.id === values.counselorsIds[1]
                            ) ?? ''
                          }
                          keepMounted
                          isLoading={loadingCounselors}
                          fullWidth
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          onChange={(_e, value) => {
                            setFieldValue(
                              'counselorsIds[1]',
                              value?.id ?? null
                            );
                          }}
                          helperText={
                            touched.counselorsIds && t(errors.counselorsIds)
                          }
                          label={t('COMMON.COUNSELOR')}
                          error={Boolean(
                            touched.counselorsIds && errors.counselorsIds
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                        fullWidth
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

AssignedSignatoriesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default AssignedSignatoriesDialog;
