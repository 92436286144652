import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import GeneralDistributor from 'src/views/business/DistributorDetail/GeneralDetail';
import DistributorAddress from 'src/views/business/DistributorDetail/AddressDetail';
import DistributorStats from 'src/views/business/DistributorDetail/DistributorStats';
import { useSelector } from 'react-redux';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';

const GeneralView = ({ distributor }) => {
  const disabledEdit = true;
  const role = useSelector(selectAuthRole);
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <GeneralDistributor
            distributor={distributor}
            isDisabledEdit={disabledEdit}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DistributorAddress
            distributor={distributor}
            isDisabledEdit={disabledEdit}
          />
        </Grid>
        {role === DistributorRoles.DIRECTOR && (
          <Grid item xs={12} md={6} lg={4}>
            <DistributorStats distributor={distributor} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  distributor: PropTypes.object
};

export default GeneralView;
