import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectContractsQueryOptions } from 'src/store/selectors/contractSelectors';
import ContractStatus from 'src/utils/constants/contracts';
import {
  CLEAR_CLIENT_CONTRACT_OPTIONS,
  loadClientContracts
} from 'src/store/actions/clientActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ContractsList from './ContractList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function selectContractStatus(value) {
  switch (Number(value)) {
    case 0:
      return ContractStatus.IN_PROGRESS;
    case 1:
      return ContractStatus.PENDING_SIGNATURE;
    case 2:
      return ContractStatus.DRAFT;
    case 3:
      return ContractStatus.ENDED;
    default:
      return ContractStatus.IN_PROGRESS;
  }
}

const ContractListTabs = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectContractsQueryOptions);
  const { t } = useTranslation();
  const { id } = useParams();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadClientContracts({
          ...queryOpts,
          status: selectContractStatus(newValue),
          clientId: id
        })
      );
    },
    [setTabIndex]
  );

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_CLIENT_CONTRACT_OPTIONS });
    };
  }, [dispatch]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.ACTIVES')} />
          <Tab label={t('COMMON.PENDING_SIGNATURE')} />
          <Tab label={t('COMMON.DRAFT')} />
          <Tab label={t('COMMON.ENDED')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <ContractsList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ContractsList />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ContractsList />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ContractsList />
      </TabPanel>
    </Box>
  );
};

export default ContractListTabs;
