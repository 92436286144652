import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { selectUserProfileDistributor } from 'src/store/selectors/userProfileSelectors';
import { loadUserProfile } from 'src/store/actions/userProfileActions';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const stepperLabels = [
  'CLIENTS_VIEW.CREATION_FORM.PERSONAL_INFORMATION',
  'DISTRIBUTORS_VIEW.ASSIGN_DISTRIBUTOR',
  'QUOTES_VIEW.QUICK_QUOTE_FORM.ASSIGN_EQUIPMENT',
  'COMMON.CONFIRMATION'
];

const CreateQuickQuoteForm = ({
  open,
  handleClose,
  handleCreateQuickQuote
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const distributor = useSelector(selectUserProfileDistributor);
  const role = useSelector(selectAuthRole);
  const initialState = makeInitialState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const resetRef = useRef();

  useEffect(() => {
    if (step === 0) {
      setData({ ...data, distributor });
    }
  }, [open, distributor, step]);

  useEffect(() => {
    dispatch(loadUserProfile());
    return () => {
      setData(initialState);
      setStep(0);
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = () => {
    switch (step) {
      case 0:
        setData(initialState);
        handleClose();
        break;
      case 2:
        if (role === DistributorRoles.KAM) {
          setStep(step - 2);
        } else {
          setStep(step - 1);
        }
        break;
      default:
        setStep(step - 1);
        break;
    }
  };

  const handleSubmit = useCallback(
    values => {
      handleCreateQuickQuote(
        {
          ...data,
          ...values
        },
        () => setData(initialState)
      );
    },
    [data]
  );

  const handleCloseForm = () => {
    setData(initialState);
    handleClose();
  };

  if (!open) return null;

  return (
    <Box>
      <Dialog
        aria-labelledby="form-new-gps-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-gps-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseForm}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('COMMON.QUICK_QUOTE')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <FormStepper
          index={step}
          labels={stepperLabels}
          sx={{
            marginBottom: '1.5rem',
            marginTop: '1.5rem'
          }}
        />
        <DialogContent>
          <CurrentStep
            activeStep={step}
            setActiveStep={setStep}
            data={data}
            setData={setData}
            resetRef={resetRef}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            onCloseForm={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

CreateQuickQuoteForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateQuickQuote: PropTypes.func.isRequired
};

export default CreateQuickQuoteForm;
