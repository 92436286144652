import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';

const BanksRow = ({ name, code }) => {
  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="start">{code}</TableCell>
    </TableRow>
  );
};

BanksRow.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string
};

export default BanksRow;
