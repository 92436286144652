import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { BillingTabsIndex } from 'src/utils/constants/clients';
import HistoryInformation from './HistoryInformation';
import BillingDetail from './BillingDetail/BillingDetail';
import BankAccountDetail from './BankAccountDetail/BankAccountDetail';

const BillingTabsList = [
  {
    value: BillingTabsIndex.SAT_INFORMATION,
    label: 'COMMON.SAT_INFORMATION'
  },
  {
    value: BillingTabsIndex.HISTORY,
    label: 'COMMON.HISTORY'
  },
  {
    value: BillingTabsIndex.BANK_ACCOUNT,
    label: 'CLIENTS_VIEW.BILLING_DETAIL.BANK_ACCOUNT'
  }
];

const SatWsDetailTabs = ({ client }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  const { id, subtab } = useParams();

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/clients/detail/${id}/billing/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !subtab) {
      setValue(BillingTabsIndex.SAT_INFORMATION);
    }
    if (subtab) {
      if (Object.values(BillingTabsIndex).includes(subtab)) {
        setValue(subtab);
      } else {
        push(`/clients/detail/${id}/billing`);
      }
    }
  }, [id, subtab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          {BillingTabsList.map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={BillingTabsIndex.SAT_INFORMATION}>
        <BillingDetail client={client} />
      </TabPanel>
      <TabPanel value={value} index={BillingTabsIndex.HISTORY}>
        <HistoryInformation />
      </TabPanel>
      <TabPanel value={value} index={BillingTabsIndex.BANK_ACCOUNT}>
        <BankAccountDetail client={client} />
      </TabPanel>
    </Box>
  );
};

SatWsDetailTabs.propTypes = {
  client: PropTypes.object.isRequired
};

export default SatWsDetailTabs;
