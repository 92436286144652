import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, IconButton } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddButton from 'src/components/AddButton/AddButton';
import { createIndustryAction } from 'src/store/actions/catalogActions';
import useStyles from './styles';
import IndustriesForm from './IndustryForm';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreate = useCallback(
    values => {
      dispatch(createIndustryAction(values));
      handleCloseModal();
    },
    [dispatch, handleCloseModal]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={goBack} color="primary" component="span">
              <ArrowLeft />
            </IconButton>
            <Typography
              style={{ fontWeight: '400' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.CATALOG_LIST.INDUSTRIES')}
            </Typography>
          </Box>
          <Box className={classes.actionsContainer}>
            <AddButton
              title={t('CATALOGS_VIEW.INDUSTRIES.ADD_INDUSTRY')}
              onClick={handleOpenModal}
            />
            <IndustriesForm
              open={open}
              onClose={handleCloseModal}
              onCreate={handleCreate}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  contractStatus: PropTypes.string
};

export default Toolbar;
