import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PersonType } from 'src/utils/constants/clients';
import { addPointsPhone } from 'src/utils/common';
import RedirectLink from 'src/components/RedirectLink/RedirectLink';
import { InstagramUrl } from 'src/utils/constants/common';

const ClientContactInfo = ({ client }) => {
  const { t } = useTranslation();

  return (
    <>
      {client.personType === PersonType.PHYSICAL && (
        <>
          <DetailRow
            name={t('COMMON.MOBILE_PHONE')}
            value={
              client.phone ? addPointsPhone(client.phone) : t('COMMON.NO_DATA')
            }
          />

          <DetailRow
            name={t('COMMON.HOME_PHONE')}
            value={
              client.homePhone
                ? addPointsPhone(client.homePhone)
                : t('COMMON.NO_DATA')
            }
          />

          <DetailRow
            name={t('COMMON.OFFICE_PHONE')}
            value={
              client.officePhone
                ? addPointsPhone(client.officePhone)
                : t('COMMON.NO_DATA')
            }
          />

          <DetailRow name={t('COMMON.EMAIL')} value={client.email} />
        </>
      )}

      <DetailRow
        name={t('COMMON.FACEBOOK')}
        value={<RedirectLink link={client.facebook} />}
      />

      <DetailRow
        name={t('COMMON.INSTAGRAM')}
        value={
          <RedirectLink
            link={client.instagram && `${InstagramUrl}${client.instagram}`}
          />
        }
      />

      {client.personType === PersonType.LEGAL && (
        <DetailRow
          name={t('COMMON.WEB_SITE')}
          value={<RedirectLink link={client.webSite} />}
        />
      )}
    </>
  );
};

ClientContactInfo.propTypes = {
  client: PropTypes.object.isRequired
};
export default ClientContactInfo;
