import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { submitBureauForm } from 'src/store/actions/bureauFormActions';
import { useDispatch, useSelector } from 'react-redux';
import CircularStepper from 'src/components/FormStepper/CircularStepper';
import { selectBureauFormResult } from 'src/store/selectors/bureauFormSelectors';
import makeInitialState from './initialState';
import CurrentStep from './CurrentStep';
import ResultBureauView from '../ResultViews/resultViews';

const stepperLabels = [
  { label: 'BUREAU_FORM.STEPS_ONE' },
  { label: 'BUREAU_FORM.STEPS_TWO' },
  { label: 'BUREAU_FORM.STEPS_THREE' },
  { label: 'BUREAU_FORM.STEPS_FOUR' },
  { label: 'BUREAU_FORM.STEPS_FIVE' },
  { label: 'BUREAU_FORM.STEPS_SIX' },
  { label: 'BUREAU_FORM.STEPS_SEVEN' },
  { label: 'BUREAU_FORM.STEPS_EIGHT' },
  { label: 'BUREAU_FORM.STEPS_NINE' },
  { label: 'BUREAU_FORM.STEPS_TEN' }
];

const BureauForm = () => {
  const initialState = makeInitialState();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);
  const buroResponse = useSelector(selectBureauFormResult);

  useEffect(() => {
    return () => {
      setStep(1);
    };
  }, []);

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleNextStep = useCallback(() => {
    setStep(step + 1);
  });

  const handleBack = useCallback(() => {
    switch (step) {
      case 1:
        setData(initialState);
        break;
      case 2:
        setData({ ...initialState });
        setStep(step - 1);
        break;
      case 3: {
        setStep(step - 1);
        break;
      }
      default:
        setStep(step - 1);
        break;
    }
  }, [step, setStep]);

  const handleSubmit = useCallback(async values => {
    dispatch(submitBureauForm({ ...values }, handleNextStep));
  });

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingY: '20px'
      }}
    >
      {buroResponse ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'center' },
            width: '80%',
            minHeight: { md: '70vh', xs: 'auto' }
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <ResultBureauView
              buroResponse={buroResponse.status}
              name={data.name}
            />
          </Box>
        </Box>
      ) : (
        <>
          <CircularStepper
            activeStep={step}
            steps={labels}
            disableEnumerations
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              width: '80%',
              // minHeight: '70vh'
              minHeight: { md: '70vh', xs: 'auto' }
            }}
          >
            <CurrentStep
              activeStep={step}
              setStep={setStep}
              data={data}
              onNext={handleNext}
              onBack={handleBack}
              onSubmit={handleSubmit}
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default BureauForm;
