import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { showMessage } from 'src/store/actions/snackBarActions';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import Dropzone from 'src/components/Dropzone';
import DialogTransition from 'src/components/DialogTransition';
import {
  UPLOAD_CATALOG_IN_EXCEL,
  uploadCatalogInExcel
} from 'src/store/actions/distributorActions';

const makeInitialValues = () => ({
  fileName: '',
  file: ''
});

const UploadCatalogInExcelFormatDialog = ({
  open,
  handleClose,
  handleOpenFailUpload,
  distributorId
}) => {
  const dispatch = useDispatch();
  const initialValues = makeInitialValues();
  const { t } = useTranslation();
  const loading = useSelector(
    isLoadingSelector([UPLOAD_CATALOG_IN_EXCEL.action])
  );

  const handleOnChangeFileInput = useCallback(
    setFieldValue => files => {
      setFieldValue('file', files[0]);
      setFieldValue('fileName', files[0].name);
    },
    []
  );

  const handleFileReject = useCallback(() => {
    dispatch(
      showMessage({
        message: 'INVALID_FILE_FORMAT',
        variant: 'error'
      })
    );
  }, [dispatch]);

  const handleDropFile = useCallback(
    setFieldValue => () => {
      setFieldValue('file', '');
      setFieldValue('fileName', '');
    },
    []
  );

  const onSubmitFile = useCallback(
    async values => {
      return dispatch(
        uploadCatalogInExcel(
          {
            file: values.file
          },
          handleClose,
          handleOpenFailUpload,
          distributorId
        )
      );
    },
    [dispatch]
  );

  return (
    <Dialog
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '30fr',
          maxHeight: '70vh'
        }
      }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle>
        <DialogTitle
          sx={{
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            '& h2': {
              fontWeight: 400
            }
          }}
          id="form-catalog-dialog-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            sx={{
              float: 'right',
              width: '20px',
              height: '20px'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="primary" variant="h2">
            {t('DISTRIBUTORS_VIEW.UPLOAD_CATALOG_IN_EXCEL')}
          </Typography>
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Loading />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitFile}
            enableReinitialize
          >
            {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  {!values.fileName && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          marginBottom: theme.spacing(2)
                        }}
                      >
                        {t(
                          'DISTRIBUTORS_VIEW.DOWNLOAD_CATALOG_IN_EXCEL_TEMPLATE'
                        )}
                        <Box
                          component="a"
                          sx={{
                            color: theme.palette.primary.main,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            marginLeft: '5px'
                          }}
                          onClick={() => {
                            window.open(
                              process.env.REACT_APP_CATALOG_TEMPLATE_URL
                            );
                          }}
                        >
                          {t('LOGIN_VIEW.CLICK_HERE')}
                        </Box>
                      </Typography>
                      <Dropzone
                        acceptedFileFormat={{
                          'application/csv': ['.csv']
                        }}
                        handleFileChange={handleOnChangeFileInput(
                          setFieldValue
                        )}
                        handleFileReject={handleFileReject}
                      />
                    </>
                  )}
                  {values.fileName && (
                    <Container
                      sx={{
                        border: `1px dashed ${theme.palette.text.secondary}`,
                        padding: '10px 10px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h4">{values.fileName}</Typography>
                      <Button
                        onClick={handleDropFile(setFieldValue)}
                        size="small"
                      >
                        <CloseIcon fontSize="10px" />
                      </Button>
                    </Container>
                  )}
                  <Button
                    disabled={isSubmitting || !values.fileName}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: theme.spacing(2)
                    }}
                  >
                    {t('COMMON.SAVE')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

UploadCatalogInExcelFormatDialog.propTypes = {
  open: PropTypes.string,
  handleClose: PropTypes.func,
  handleOpenFailUpload: PropTypes.func,
  distributorId: PropTypes.string
};
export default UploadCatalogInExcelFormatDialog;
