import React, { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_INVESTMENT_LIST,
  loadInvestmentList
} from 'src/store/actions/investmentActions';
import {
  selectInvestmentsList,
  selectInvestmentsListCount,
  selectInvestmentsQueryOptions
} from 'src/store/selectors/investmentSelector';
import PropTypes from 'prop-types';
import InvestmentTable from './InvestmentTable';
import useStyles from './styles';

const InvestmentList = ({ status }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const investments = useSelector(selectInvestmentsList);
  const count = useSelector(selectInvestmentsListCount);
  const { page } = useSelector(selectInvestmentsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_INVESTMENT_LIST.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInvestmentList({ page: value, status }));
    },
    [dispatch]
  );

  return (
    <Box mt={3}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item lg={12}>
          <InvestmentTable
            className={classes.table}
            loading={loading}
            investments={investments}
            count={count}
            page={page}
            handleChangePage={handleChangePage}
            status={status}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

InvestmentList.propTypes = {
  status: PropTypes.string,
};


export default InvestmentList;
