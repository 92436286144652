import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  IconButton,
  Button,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getDifferenceBetweenStates } from 'src/utils/common';
import DialogTransition from 'src/components/DialogTransition';
import DropdownMenu from 'src/components/DropdownMenu';
import { CivilStatus } from 'src/utils/investors';
import clsx from 'clsx';
import AlertMessage from 'src/components/Alert/Alert';
import { KinshipEnum } from 'src/utils/constants/common';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';

const GeneralKYCDialog = ({ open, investor, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(investor), [investor]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [openAlert, setOpenAlert] = useState(true);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOuterSubmit = useCallback(
    values => {
      if (values.politicallyExposed === 'false') {
        values.politicallyExposedPerson = {};
      }
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      submit(diference);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {`${t('INVESTORS_VIEW.INVESTOR_DETAIL.GENERAL_DATA')} KYC`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.curp && errors.curp)}
                  fullWidth
                  helperText={touched.curp && t(errors.curp)}
                  label={t('COMMON.CURP')}
                  margin="normal"
                  name="curp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.curp}
                  variant="outlined"
                />

                <DropdownMenu
                  list={Object.keys(CivilStatus).map(status => ({
                    name: t(`CIVIL_STATUS.${status}`),
                    key: status
                  }))}
                  value={values.civilStatus}
                  setValue={value => setFieldValue('civilStatus', value)}
                  label={t('INVESTORS_VIEW.INVESTOR_DETAIL.CIVIL_STATUS')}
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.politicallyExposed}
                  setValue={value =>
                    setFieldValue('politicallyExposed', value === 'true')
                  }
                  label={t(
                    'INVESTORS_VIEW.INVESTOR_DETAIL.POLITICALLY_EXPOSED'
                  )}
                />

                {values.politicallyExposed && (
                  <>
                    <AlertMessage
                      open={openAlert}
                      title="COMMON.INFORMATION"
                      text="INVESTORS_VIEW.INVESTOR_DETAIL.POLITICALLY_EXPOSED_PERSON_INFORMATION"
                      severity="info"
                      handleClose={handleCloseAlert}
                      hideCloseIcon
                    />
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.politicallyExposedPerson?.name &&
                          errors.politicallyExposedPerson?.name
                      )}
                      fullWidth
                      helperText={
                        touched.politicallyExposedPerson?.name &&
                        t(errors.politicallyExposedPerson?.name)
                      }
                      label={t('COMMON.NAME')}
                      margin="normal"
                      name="politicallyExposedPerson.name"
                      onBlur={handleBlur}
                      onChange={value => {
                        setFieldValue('politicallyExposedPerson', {
                          ...values.politicallyExposedPerson,
                          name: value.target.value
                        });
                      }}
                      type="text"
                      value={values.politicallyExposedPerson?.name}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.politicallyExposedPerson?.paternalName &&
                          errors.politicallyExposedPerson?.paternalName
                      )}
                      fullWidth
                      helperText={
                        touched.politicallyExposedPerson?.paternalName &&
                        t(errors.politicallyExposedPerson?.paternalName)
                      }
                      label={t('COMMON.PATERNAL_NAME')}
                      margin="normal"
                      name="politicallyExposedPerson.paternalName"
                      onBlur={handleBlur}
                      onChange={value =>
                        setFieldValue('politicallyExposedPerson', {
                          ...values.politicallyExposedPerson,
                          paternalName: value.target.value
                        })
                      }
                      type="text"
                      value={values.politicallyExposedPerson?.paternalName}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.politicallyExposedPerson?.maternalName &&
                          errors.politicallyExposedPerson?.maternalName
                      )}
                      fullWidth
                      helperText={
                        touched.politicallyExposedPerson?.maternalName &&
                        t(errors.politicallyExposedPerson?.maternalName)
                      }
                      label={t('COMMON.MATERNAL_NAME')}
                      margin="normal"
                      name="politicallyExposedPerson.maternalName"
                      onBlur={handleBlur}
                      onChange={value =>
                        setFieldValue('politicallyExposedPerson', {
                          ...values.politicallyExposedPerson,
                          maternalName: value.target.value
                        })
                      }
                      type="text"
                      value={values.politicallyExposedPerson?.maternalName}
                      variant="outlined"
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(
                        touched.politicallyExposedPerson?.position &&
                          errors.politicallyExposedPerson?.position
                      )}
                      fullWidth
                      helperText={
                        touched.politicallyExposedPerson?.position &&
                        t(errors.politicallyExposedPerson?.position)
                      }
                      label={t('COMMON.POSITION')}
                      margin="normal"
                      name="politicallyExposedPerson.position"
                      onBlur={handleBlur}
                      onChange={value =>
                        setFieldValue('politicallyExposedPerson', {
                          ...values.politicallyExposedPerson,
                          position: value.target.value
                        })
                      }
                      type="text"
                      value={values.politicallyExposedPerson?.position}
                      variant="outlined"
                    />

                    <DropdownMenu
                      list={KinshipEnum.map(item => ({
                        name: t(`ENUMS.KINSHIP.${item}`),
                        key: item
                      }))}
                      value={values.politicallyExposedPerson?.kinship}
                      setValue={value =>
                        setFieldValue(
                          'politicallyExposedPerson[kinship]',
                          value
                        )
                      }
                      label={t('COMMON.KINSHIP')}
                      error={Boolean(
                        touched.politicallyExposedPerson?.kinship &&
                          errors.politicallyExposedPerson?.kinship
                      )}
                      errorText={
                        touched.politicallyExposedPerson?.kinship &&
                        t(errors.politicallyExposedPerson?.kinship)
                      }
                    />
                  </>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

GeneralKYCDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  investor: PropTypes.object
};

export default GeneralKYCDialog;
