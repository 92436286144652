import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ContractCalendarTable from './ContractCalendarTable';

const ContractCalendarDetail = ({ contract }) => {
  return (
    <Box mt={3}>
      <ContractCalendarTable contracts={contract} />
    </Box>
  );
};

ContractCalendarDetail.propTypes = {
  contract: PropTypes.object
};

export default ContractCalendarDetail;
