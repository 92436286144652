import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  getDifferenceBetweenStates,
  removePointsPhone
} from 'src/utils/common';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';
import PhoneInput from 'src/components/PhoneInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { getDistributorParameters } from 'src/store/actions/distributorActions';
import { selectAuthDistributorId } from 'src/store/selectors/authSelectors';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../styles';

const EditUserDialog = ({ open, handleClose, onSubmit, user }) => {
  const classes = useStyles();
  const initialState = makeInitialState(user);
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameter = useSelector(selectDistributorDetail);
  const distributorId = useSelector(selectAuthDistributorId);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (user) {
        const { phone, officePhone } = values;

        if (phone) {
          values.phone = removePointsPhone(phone);
        }
        if (officePhone) {
          values.officePhone = removePointsPhone(officePhone);
        }

        const difference = getDifferenceBetweenStates(initialState, values);
        if (Object.entries(difference).length === 0) {
          handleClose();
          return;
        }
        onSubmit({
          ...difference
        });
      } else {
        onSubmit({
          ...values
        });
      }
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );
  useEffect(() => {
    if (open) {
      dispatch(getDistributorParameters(distributorId));
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('USER_PROFILE.EDIT_PROFILE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && t(errors.name)}
                  label={t('COMMON.NAME')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50, autoComplete: 'off' }}
                  type="text"
                  value={values.name}
                  variant="outlined"
                  disabled={!parameter?.enableEditKamInfo}
                />

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="phone"
                    disabled={!parameter?.enableEditKamInfo}
                    label={t('COMMON.PHONE')}
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                  {Boolean(touched.phone && errors.phone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.phone)}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <PhoneInput
                    id="phone-label"
                    name="officePhone"
                    disabled={!parameter?.enableEditKamInfo}
                    label={t('COMMON.OFFICE_PHONE')}
                    value={values.officePhone}
                    error={Boolean(touched.officePhone && errors.officePhone)}
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                  />

                  {Boolean(touched.officePhone && errors.officePhone) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t(errors.officePhone)}
                    </FormHelperText>
                  )}
                </FormControl>
                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Tooltip title={t('COMMON.EDIT_NOT_POSSIBLE')}>
                      <span>
                        <Button
                          className={clsx(classes.label, classes.buttons)}
                          type="submit"
                          color="primary"
                          variant="outlined"
                          disabled={!parameter?.enableEditKamInfo}
                        >
                          {t('COMMON.EDIT')}
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default EditUserDialog;
