import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';

import DownloadButton from 'src/components/DownloadButton';
import { downloadExcelIncomeResume } from 'src/store/actions/incomeResumeActions';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelIncomeResume());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { lg: 'row', sm: 'row', xs: 'column' }
          }}
        >
          <Typography sx={{ fontWeight: '400' }} color="primary" variant="h1">
            {t('NAVIGATION.INCOME_RESUME')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <DownloadButton onDownload={handleExcelExport} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
