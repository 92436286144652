import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import selectDocumentType from 'src/utils/selectDocumentType';
import { DocumentType } from 'src/utils/constants/clients';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';

const DocumentAvalInfo = ({ aval, isDisabledEdit }) => {
  const { t } = useTranslation();
  const [avalId, setAvalId] = useState(aval.id);

  useEffect(() => {
    setAvalId(aval.id);
  }, [aval.id]);

  return (
    <>
      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.FONT_IFE')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.IFE_FRONT, aval.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.IFE_FRONT}
          avalId={avalId}
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.IFE_FRONT, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.BACK_IFE')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.IFE_BACK, aval.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.IFE_BACK}
          avalId={avalId}
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.IFE_BACK, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.PASSPORT')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.PASSPORT, aval.documents)}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.PASSPORT}
          avalId={avalId}
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.PASSPORT, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('ENUMS.DOCUMENTS.ADDRESS_PROOF')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.ADDRESS_PROOF,
              aval.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documentType={DocumentType.ADDRESS_PROOF}
          avalId={avalId}
          documents={Boolean(
            selectDocumentType(DocumentType.ADDRESS_PROOF, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.TAX_STATUS_DOC')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.TAX_STATUS_DOC,
              aval.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.TAX_STATUS_DOC}
          avalId={avalId}
          documents={Boolean(
            selectDocumentType(DocumentType.TAX_STATUS_DOC, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.LAST_TAX_RETURN')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.LAST_TAX_RETURN,
              aval.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.LAST_TAX_RETURN}
          avalId={avalId}
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.LAST_TAX_RETURN, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.RFC_COMPLIANCE')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.RFC_COMPLIANCE,
              aval.documents
            )}
          />
        }
      >
        <DocumentsMenu
          documentType={DocumentType.RFC_COMPLIANCE}
          avalId={avalId}
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.RFC_COMPLIANCE, aval.documents)
          )}
        />
      </DetailRow>

      <DetailRow
        name={t('CLIENTS_VIEW.CLIENT_DETAIL.BANK_STATEMENT')}
        value={
          <DocumentLink
            link={selectDocumentType(
              DocumentType.BANK_STATEMENT,
              aval.documents
            )}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.BANK_STATEMENT, aval.documents)
          )}
          documentType={DocumentType.BANK_STATEMENT}
          avalId={avalId}
        />
      </DetailRow>

      <DetailRow
        name={t('COMMON.OTHERS')}
        value={
          <DocumentLink
            link={selectDocumentType(DocumentType.OTHER, aval.documents)}
          />
        }
      >
        <DocumentsMenu
          isDisabledEdit={isDisabledEdit}
          documents={Boolean(
            selectDocumentType(DocumentType.OTHER, aval.documents)
          )}
          documentType={DocumentType.OTHER}
          avalId={avalId}
        />
      </DetailRow>
    </>
  );
};

DocumentAvalInfo.propTypes = {
  aval: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default DocumentAvalInfo;
