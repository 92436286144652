import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string(),
  paternalName: Yup.string(),
  maternalName: Yup.string(),
  rfc: Yup.string().matches(
    /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
    'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
  ),
  curp: Yup.string().matches(
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
  ),
  sex: Yup.string(),
  identificationNumber: Yup.string(),
  shareholdingPercentage: Yup.number()
    .min(0)
    .max(100),
  deedNumberPowerAttorney: Yup.string(),
  deedDatePowerAttorney: Yup.date()
});
