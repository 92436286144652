import React from 'react';
import { Box, Grid } from '@mui/material';
import ContactsList from 'src/views/business/DistributorDetail/ContactsList/ContactsList';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { useSelector } from 'react-redux';
import { DistributorRoles } from 'src/utils/constants/auth';

const ContactsView = () => {
  const role = useSelector(selectAuthRole);
  const disabledActions = role !== DistributorRoles.DIRECTOR;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContactsList
            isDisabledActions={disabledActions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactsView;
