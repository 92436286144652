import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  downloadBusinessResume,
  downloadExportedMetrics,
  getBusinessResume,
  getMetrics
} from 'src/services/businessService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';
import { selectBusinessQueryOptions } from '../selectors/businessSelectors';

export const LOAD_BUSINESS_METRICS = asyncActionCreator(
  'LOAD_BUSINESS_METRICS'
);
export const DOWNLOAD_BUSINESS_METRICS = asyncActionCreator(
  'DOWNLOAD_BUSINESS_METRICS'
);
export const CLEAR_BUSINESS_METRICS = 'CLEAR_BUSINESS_METRICS';
export const CLEAR_BUSINESS_OPTIONS = 'CLEAR_BUSINESS_OPTIONS';
export const SAVE_BUSINESS_OPTIONS = 'SAVE_BUSINESS_OPTIONS';

// Metrics resume
export const LOAD_BUSINESS_METRICS_RESUME = asyncActionCreator(
  'LOAD_BUSINESS_METRICS_RESUME'
);
export const DOWNLOAD_BUSINESS_METRICS_RESUME = asyncActionCreator(
  'DOWNLOAD_BUSINESS_METRICS_RESUME'
);

export function getOptions(getState, sendLimit = true) {
  const queryOpts = selectBusinessQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }

  if (queryOpts.metricsViewOption) {
    reqOptions.metricsViewOption = queryOpts.metricsViewOption;
  }

  if (queryOpts.balance) {
    reqOptions.balance = queryOpts.balance;
  }

  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadBusinessMetrics = (options, sendLimit) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_BUSINESS_METRICS.start });

    dispatch({
      type: SAVE_BUSINESS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);

    const metrics = await getMetrics(reqOptions);

    return dispatch({
      type: LOAD_BUSINESS_METRICS.success,
      ...metrics
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_BUSINESS_METRICS.failure });
  }
};

export const downloadBusinessMetrics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_BUSINESS_METRICS.start });

    const reqOptions = getOptions(getState, false);

    await downloadExportedMetrics(reqOptions);

    return dispatch({ type: DOWNLOAD_BUSINESS_METRICS.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DOWNLOAD_BUSINESS_METRICS.failure });
  }
};

export const loadBusinessMetricsResume = (options, sendLimit) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_BUSINESS_METRICS_RESUME.start });

    dispatch({
      type: SAVE_BUSINESS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);

    const metrics = await getBusinessResume(reqOptions);

    return dispatch({
      type: LOAD_BUSINESS_METRICS_RESUME.success,
      ...metrics
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_BUSINESS_METRICS_RESUME.failure });
  }
};

export const downloadBusinessMetricsResume = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DOWNLOAD_BUSINESS_METRICS_RESUME.start });

    const reqOptions = getOptions(getState, false);

    await downloadBusinessResume(reqOptions);

    return dispatch({ type: DOWNLOAD_BUSINESS_METRICS_RESUME.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DOWNLOAD_BUSINESS_METRICS_RESUME.failure });
  }
};
