import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Preloader from 'src/components/Preloader';

import {
  LOAD_INVESTOR_DETAIL,
  loadInvestorDetail
} from 'src/store/actions/investorActions';
import { selectInvestorDetail } from 'src/store/selectors/investorSelector';
import GeneralDetail from './GeneralDetail';
import InvestorAddress from './AddressDetail';
import InvestorDocuments from './DocumentsDetail';
import AgentAssignedDetail from './AgentAssigned/AgentAssignedDetail';

const GeneralView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const investor = useSelector(selectInvestorDetail);
  const loading = useSelector(isLoadingSelector([LOAD_INVESTOR_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_INVESTOR_DETAIL.action]));

  useEffect(() => {
    dispatch(loadInvestorDetail(id));
  }, [dispatch, id]);

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.GENERAL')}
            </Typography>
          </Box>
        </Grid>

        {success && !loading ? (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <GeneralDetail investor={investor} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InvestorAddress investor={investor} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InvestorDocuments investor={investor} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AgentAssignedDetail agent={investor?.agent} />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GeneralView;
