import {
  downloadExportedBilling,
  getBillingFailures,
  getBillings,
  sendToBillTransaction
} from 'src/services/billingsService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import { selectBillingsQueryOptions } from '../selectors/billingSelector';
import { showMessage } from './snackBarActions';

export const LOAD_BILLING = asyncActionCreator('LOAD_BILLING');
export const DOWNLOAD_BILLING = asyncActionCreator('DOWNLOAD_BILLING');
export const CLEAR_BILLING = 'CLEAR_BILLING';
export const SAVE_BILLING_OPTIONS = 'SAVE_BILLING_OPTIONS';
export const CLEAR_BILLING_OPTIONS = 'CLEAR_BILLING_OPTIONS';

export const LOAD_BILLING_FAILS_LIST = asyncActionCreator(
  'LOAD_BILLING_FAILS_LIST'
);

export const SEND_TO_REBILL = asyncActionCreator('SEND_TO_REBILL');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectBillingsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.year) {
    reqOptions.year = queryOpts.year;
  }
  if (queryOpts.month) {
    reqOptions.month = queryOpts.month;
  }
  if (queryOpts.clientName) {
    reqOptions.clientName = queryOpts.clientName;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_BILLING_OPTIONS });
};

export const loadBillings = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_BILLING.start });

    dispatch({
      type: SAVE_BILLING_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const transaction = await getBillings(reqOptions);

    return dispatch({
      type: LOAD_BILLING.success,
      ...transaction
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_BILLING.failure });
  }
};

export const downloadExcelBilling = options => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOWNLOAD_BILLING.start
    });

    dispatch({
      type: SAVE_BILLING_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExportedBilling(reqOptions);

    return dispatch({
      type: DOWNLOAD_BILLING.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_BILLING.failure });
  }
};

export const loadBillingFailures = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_BILLING_FAILS_LIST.start });

    dispatch({
      type: SAVE_BILLING_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const transaction = await getBillingFailures(reqOptions);

    return dispatch({
      type: LOAD_BILLING_FAILS_LIST.success,
      ...transaction
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_BILLING_FAILS_LIST.failure });
  }
};

export const billTransaction = transactionId => async dispatch => {
  try {
    dispatch({ type: SEND_TO_REBILL.start });
    await sendToBillTransaction(transactionId);
    dispatch(
      showMessage({ message: 'TRANSACTION_BILLED', variant: 'success' })
    );
    dispatch({ type: CLEAR_BILLING_OPTIONS });
    dispatch(loadBillingFailures());
    return dispatch({ type: SEND_TO_REBILL.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: SEND_TO_REBILL.failure });
  }
};
