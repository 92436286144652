import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import ContactsRow from './ContactsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ contacts, isDisabledActions }) =>
  contacts.map(contact => (
    <ContactsRow
      key={contact.id}
      {...contact}
      isDisabledActions={isDisabledActions}
    />
  ));

const ContactsTable = ({
  contacts,
  isDisabledActions,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell
        sx={{ width: '15%' }}
        variant="head"
        key="name"
      >
        {t('COMMON.NAME')}
      </TableCell>
      <TableCell sx={{ width: '15%' }} variant="head" align="center">
        {t('COMMON.DEPARTMENT')}
      </TableCell>
      <TableCell sx={{ width: '15%' }} variant="head" align="center">
        {t('COMMON.POSITION')}
      </TableCell>
      <TableCell sx={{ width: '15%' }} variant="head" align="center">
        {t('COMMON.MOBILE')}
      </TableCell>
      <TableCell sx={{ width: '15%' }} variant="head" align="center">
        {t('COMMON.EMAIL')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" align="center">
        {t('COMMON.NOTIFICATION')}
      </TableCell>
      <TableCell sx={{ width: '5%' }} variant="head" align="center">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody contacts={contacts} isDisabledActions={isDisabledActions} />
    ),
    [contacts]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, contacts]
  );

  if (count === 0 && !loading) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTOR_CONTACTS_TITLE" />
      </Card>
    );
  }

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.array.isRequired,
  isDisabledActions: PropTypes.bool,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

export default ContactsTable;
