import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getDifferenceBetweenStates } from 'src/utils/common';
import DialogTransition from 'src/components/DialogTransition';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../styles';

const LegalClientContactDetailDialog = ({
  open,
  client,
  handleClose,
  submit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(client), [client]);

  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit(difference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle
        className={classes.header}
        id="form-contact-detail-dialog-title"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_CONTACT_DETAIL')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.facebook && errors.facebook)}
                  fullWidth
                  helperText={touched.facebook && t(errors.facebook)}
                  label={t('COMMON.FACEBOOK_URL')}
                  margin="normal"
                  name="facebook"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.facebook}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    startAdornment: '@'
                  }}
                  error={Boolean(touched.instagram && errors.instagram)}
                  fullWidth
                  helperText={touched.instagram && t(errors.instagram)}
                  label={t('COMMON.INSTAGRAM')}
                  margin="normal"
                  name="instagram"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.instagram}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.webSite && errors.webSite)}
                  fullWidth
                  helperText={touched.webSite && t(errors.webSite)}
                  label={t('COMMON.WEB_SITE')}
                  margin="normal"
                  name="webSite"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.webSite}
                  variant="outlined"
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

LegalClientContactDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  client: PropTypes.object
};

export default LegalClientContactDetailDialog;
