import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    border: `1px dashed ${theme.palette.text.secondary}`,
    padding: '10px 10px',
    textAlign: 'center',
    borderRadius: '5px'
  }
}));
