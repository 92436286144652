import React from 'react';
import { Box, Grid } from '@mui/material';
import SatWsHistoryList from './SatWsHistory/SatWsHistoryList';

const HistoryInformation = () => {
  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <SatWsHistoryList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HistoryInformation;
