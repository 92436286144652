import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { ParameterType } from 'src/utils/constants/parameters';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropdownMenu from 'src/components/DropdownMenu';
import DinamicInputs from 'src/components/DinamicInputs/DinamicInputs';
import DialogTransition from 'src/components/DialogTransition';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import PhoneInput from 'src/components/PhoneInput';
import { transformJsonToArray } from 'src/utils/common';
import validationSchema from './schema';
import makeInitialState from './initialState';
import useStyles from './styles';

const ParameterForm = ({
  open,
  data,
  handleClose,
  handleEdit,
  dropDownList
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const handleOuterSubmit = useMemo(() => handleEdit, [handleEdit]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="parameter-update-form"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="parameter-update-form">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            {t('PARAMETERS_VIEW.UPDATE_PARAMETER_TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              const errorMessages = errors.value || [];
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('PARAMETERS_VIEW.NAME')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={t(`PARAMETER_VIEW.${values.name}`)}
                    variant="outlined"
                    disabled
                  />

                  {data &&
                    (data.type === ParameterType.TEXT ||
                      data.type === ParameterType.NUMBER) && (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        error={Boolean(touched.value && errors.value)}
                        fullWidth
                        helperText={touched.value && t(errors.value)}
                        label={t('PARAMETERS_VIEW.VALUE')}
                        margin="normal"
                        name="value"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={data && data.type.toLowerCase()}
                        value={values.value}
                        variant="outlined"
                      />
                    )}

                  {data && data.type === ParameterType.BOOLEAN && (
                    <DropdownMenu
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') }
                      ]}
                      value={values.value}
                      setValue={value => setFieldValue('value', value)}
                      label={t('PARAMETERS_VIEW.VALUE')}
                      error={Boolean(touched.value && errors.value)}
                      errorText={touched.value && t(errors.value)}
                    />
                  )}

                  {data && data.type === ParameterType.DROPDOWN && (
                    <Autocomplete
                      options={dropDownList}
                      id="dropDownList"
                      value={
                        dropDownList.find(
                          value => value.key === values.value
                        ) ?? null
                      }
                      getOptionLabel={option => `${option.name}`}
                      fullWidth
                      onChange={(_e, value) => {
                        setFieldValue('value', value ? value.key : '');
                      }}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      label={t('PARAMETERS_VIEW.VALUE')}
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && t(errors.value)}
                    />
                  )}

                  {data && data.type === ParameterType.ARRAY && (
                    <DinamicInputs
                      name={data.name}
                      values={transformJsonToArray(values.value)}
                      onChange={value => setFieldValue('value', value)}
                      error={transformJsonToArray(values.value).map((_, i) =>
                        Boolean(touched.value && errorMessages[i])
                      )}
                      helperText={transformJsonToArray(values.value).map(
                        (_, i) => touched.value && t(errorMessages[i])
                      )}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      label={t('PARAMETERS_VIEW.VALUE')}
                    />
                  )}

                  {data && data.type === ParameterType.PHONE_NUMBER && (
                    <FormControl fullWidth margin="normal">
                      <PhoneInput
                        fullWidth
                        id="phone-label"
                        name="value"
                        label={t('PARAMETERS_VIEW.VALUE')}
                        value={values.value}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        error={Boolean(touched.value && errors.value)}
                      />
                      {Boolean(touched.value && errors.value) && (
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {t(errors.value)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}

                  <Box
                    className={classes.box}
                    display="flex"
                    justifyContent="flex-end"
                    component="div"
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t('PARAMETERS_VIEW.UPDATE')}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ParameterForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string
  }),
  handleEdit: PropTypes.func,
  dropDownList: PropTypes.array,
  handleClose: PropTypes.func
};

export default ParameterForm;
