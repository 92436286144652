import { asyncActionCreator } from 'src/utils/loadingUtils';

export const LOAD_CFDIS = asyncActionCreator('LOAD_CFDIS');
export const CLEAR_CFDIS = 'CLEAR_CFDIS';
export const SAVE_CFDIS_OPTIONS = 'SAVE_CFDIS_OPTIONS';
export const CLEAR_CFDIS_OPTIONS = 'CLEAR_CFDIS_OPTIONS';
export const DOWNLOAD_INVOICE = asyncActionCreator('DOWNLOAD_INVOICE');
export const DOWNLOAD_CFDIS_LIST = asyncActionCreator('DOWNLOAD_CFDIS_LIST');
export const CANCEL_CFDI = asyncActionCreator('CANCEL_CFDI');

// cfdi expense
export const LOAD_CFDIS_INCOME = asyncActionCreator('LOAD_CFDIS_INCOME');
export const DOWNLOAD_CFDI_INCOME = asyncActionCreator('DOWNLOAD_CFDI_INCOME');

// cfdi expense
export const LOAD_CFDIS_EXPENSE = asyncActionCreator('LOAD_CFDIS_EXPENSE');
export const DOWNLOAD_CFDI_EXPENSE = asyncActionCreator(
  'DOWNLOAD_CFDI_EXPENSE'
);

export const LOAD_CFDIS_PAYMENT = asyncActionCreator('LOAD_CFDIS_PAYMENT');
export const DOWNLOAD_CFDI_PAYMENT = asyncActionCreator(
  'DOWNLOAD_CFDI_PAYMENT'
);

export const LOAD_WITHHOLDINGS = asyncActionCreator('LOAD_WITHHOLDINGS');
export const DOWNLOAD_WITHHOLDINGS = asyncActionCreator(
  'DOWNLOAD_WITHHOLDINGS'
);

export const LOAD_CFDIS_RECEIVED = asyncActionCreator('LOAD_CFDIS_RECEIVED');
export const DOWNLOAD_CFDI_RECEIVED = asyncActionCreator(
  'DOWNLOAD_CFDI_RECEIVED'
);
export const DOWNLOAD_CFDI_RECEIVED_IN_ZIP = asyncActionCreator(
  'DOWNLOAD_CFDI_RECEIVED_IN_ZIP'
);
export const LOAD_BACKGROUND_VALIDATIONS = asyncActionCreator(
  'LOAD_BACKGROUND_VALIDATIONS'
);

export const CLEAR_BACKGROUND_VALIDATIONS = 'CLEAR_BACKGROUND_VALIDATIONS';
export const CLEAR_BACKGROUND_VALIDATION_OPTIONS =
  'CLEAR_BACKGROUND_VALIDATION_OPTIONS';
export const SAVE_BACKGROUND_VALIDATION_OPTIONS =
  'SAVE_BACKGROUND_VALIDATION_OPTIONS';

export const DOWNLOAD_INVOICES_LIST_IN_ZIP = asyncActionCreator(
  'DOWNLOAD_INVOICES_LIST_IN_ZIP'
);
export const LOAD_PENDING_PAYMENT_INCOME_CFDI = asyncActionCreator(
  'LOAD_PENDING_PAYMENT_INCOME_CFDI'
);
export const SEND_INVOICE_TO_WHATSAPP = asyncActionCreator('SEND_INVOICE_TO_WHATSAPP');
