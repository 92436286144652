import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';

import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  DaysForFirstRentAfterContractStartList,
  MinInitialPaymentList,
  TermList,
  getDefaultDaysForFirstRentList
} from 'src/utils/constants/distributors';
import { getDifferenceBetweenStates } from 'src/utils/common';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const EditParameterDialog = ({ open, parameter, handleClose, onSubmit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(parameter), [parameter]);

  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      onSubmit(diference);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.EDIT_PARAMETER')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            values,
            touched,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_TERM')}
                  value={values.minTerm}
                  setValue={value => setFieldValue('minTerm', value)}
                  name="minTerm"
                  error={Boolean(touched.minTerm && errors.minTerm)}
                  errorText={touched.minTerm && t(errors.minTerm)}
                  list={TermList}
                />
                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_TERM')}
                  value={values.maxTerm}
                  name="maxTerm"
                  setValue={value => setFieldValue('maxTerm', value)}
                  error={Boolean(touched.maxTerm && errors.maxTerm)}
                  errorText={touched.maxTerm && t(errors.maxTerm)}
                  list={TermList}
                />

                <Autocomplete
                  label={t('CONTRACT_VIEW.INITIAL_PAYMENT_PERCENTAGE')}
                  name="minInitialPayment"
                  options={MinInitialPaymentList}
                  id="minInitialPayment"
                  value={values.minInitialPayment}
                  clearOnBlur={false}
                  fullWidth
                  inputType="number"
                  sx={{ marginBottom: 1, marginTop: 2 }}
                  onChange={(_e, value) => {
                    setFieldValue('minInitialPayment', Number(value));
                  }}
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isPriceEditable}
                  setValue={value =>
                    setFieldValue('isPriceEditable', value === 'true')
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_PRICE_EDITABLE')}
                  error={Boolean(
                    touched.isPriceEditable && errors.isPriceEditable
                  )}
                  errorText={
                    touched.isPriceEditable && t(errors.isPriceEditable)
                  }
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isInsuranceIncluded}
                  setValue={value =>
                    setFieldValue('isInsuranceIncluded', value === 'true')
                  }
                  label={t(
                    'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                  )}
                  error={Boolean(
                    touched.isInsuranceIncluded && errors.isInsuranceIncluded
                  )}
                  errorText={
                    touched.isInsuranceIncluded && t(errors.isInsuranceIncluded)
                  }
                />

                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('COMMON.MAX_DAYS_FOR_FIRST_RENT')}
                  value={values.maxGracePeriod}
                  name="maxGracePeriod"
                  setValue={value => setFieldValue('maxGracePeriod', value)}
                  error={Boolean(
                    touched.maxGracePeriod && errors.maxGracePeriod
                  )}
                  errorText={touched.maxGracePeriod && t(errors.maxGracePeriod)}
                  list={DaysForFirstRentAfterContractStartList}
                />

                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('COMMON.DEFAULT_DAYS_FOR_FIRST_RENT')}
                  value={values.defaultGracePeriod}
                  name="defaultGracePeriod"
                  setValue={value => setFieldValue('defaultGracePeriod', value)}
                  error={Boolean(
                    touched.defaultGracePeriod && errors.defaultGracePeriod
                  )}
                  errorText={
                    touched.defaultGracePeriod && t(errors.defaultGracePeriod)
                  }
                  list={getDefaultDaysForFirstRentList(values.maxGracePeriod)}
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isPriceEditable}
                  setValue={value =>
                    setFieldValue('isPriceEditable', value === 'true')
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_PRICE_EDITABLE')}
                  error={Boolean(
                    touched.isPriceEditable && errors.isPriceEditable
                  )}
                  errorText={
                    touched.isPriceEditable && t(errors.isPriceEditable)
                  }
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.enableUseKamContactInfo}
                  setValue={value =>
                    setFieldValue('enableUseKamContactInfo', value === 'true')
                  }
                  label={t(
                    'COMMON.ENABLE_REPEAT_CONTACT_KAM_FOR_QUOTE_CREATION'
                  )}
                  error={Boolean(
                    touched.enableUseKamContactInfo &&
                      errors.enableUseKamContactInfo
                  )}
                  errorText={
                    touched.enableUseKamContactInfo &&
                    t(errors.enableUseKamContactInfo)
                  }
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.enableEditKamInfo}
                  setValue={value =>
                    setFieldValue('enableEditKamInfo', value === 'true')
                  }
                  label={t('COMMON.ENABLE_EDIT_KAM_INFORMATION')}
                  error={Boolean(
                    touched.enableEditKamInfo && errors.enableEditKamInfo
                  )}
                  errorText={
                    touched.enableEditKamInfo && t(errors.enableEditKamInfo)
                  }
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditParameterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parameter: PropTypes.object
};

export default EditParameterDialog;
