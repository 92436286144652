export const selectQuotes = state => state.quotes;
export const selectQuotesList = state => selectQuotes(state).items;
export const selectQuotesListCount = state => selectQuotes(state).count;
export const selectQuotesQueryOptions = state => {
  const { options } = selectQuotes(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectQuotesQueryOptionsStatus = state =>
  selectQuotesQueryOptions(state).status;

export const selectQuoteDetail = state => selectQuotes(state).detail;
export const selectRentCost = state => selectQuotes(state).rentCost;
