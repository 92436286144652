import {
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  REMOVE_TOKEN
} from '../actions/authActions';

const initialState = {
  token: null,
  role: null,
  system: null,
  id: null,
  distributorId: null,
  status: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        token: action.token,
        role: action.role,
        system: action.system,
        id: action.id,
        distributorId: action.distributorId,
        status: action.status
      };
    }
    case LOGIN_ERROR: {
      return initialState;
    }
    case LOGOUT: {
      return initialState;
    }
    case REMOVE_TOKEN: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default auth;
