import { MaxGracePeriodForTwelveMonthsTerm } from 'src/utils/constants/quotes';
import * as Yup from 'yup';

export default Yup.object().shape({
  distributorEquipment: Yup.object().required(
    'SCHEMA_ERRORS.DISTRIBUTOR_EQUIPMENT_REQUIRED'
  ),
  equipmentInsuranceIncluded: Yup.boolean().required(
    'SCHEMA_ERRORS.REQUIRED_FIELD'
  ),
  price: Yup.number().required('SCHEMA_ERRORS.PRICE_REQUIRED'),
  term: Yup.number().positive('SCHEMA_ERRORS.VALUE_GREATER_THAN_ZERO'),
  gracePeriod: Yup.number()
    .when('term', {
      is: 12,
      then: Yup.number().lessThan(
        MaxGracePeriodForTwelveMonthsTerm,
        'SCHEMA_ERRORS.GRACE_PERIOD_MUST_BE_LESS_THAN_SIXTY'
      )
    })
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  initialPayment: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED')
});
