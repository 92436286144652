import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import { updateAval } from 'src/store/actions/clientActions';
import useStyles from '../styles';
import AvalContactInfo from './AvalContactInfo';
import AvalContactDetailDialog from './AvalContactDetailDialog';

const AvalContactDetail = ({ aval, isDisabledEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateAval(aval.id, values, () => handleClose()));
    },
    [aval, dispatch]
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_CONTACT_DETAIL')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <AvalContactInfo aval={aval} />
          </TableBody>
        </Table>

        <AvalContactDetailDialog
          open={open}
          aval={aval}
          handleClose={handleClose}
          submit={handleSubmit}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              disabled={isDisabledEdit}
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<Edit />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AvalContactDetail.propTypes = {
  aval: PropTypes.object.isRequired,
  isDisabledEdit: PropTypes.bool
};

export default AvalContactDetail;
