import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_BRANDS, loadBrands } from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import PropTypes from 'prop-types';
import BrandTable from './BrandTable';

const BrandList = ({ handleOpenEdit, handleDelete, distributorId }) => {
  const dispatch = useDispatch();
  const brands = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_BRANDS.action]));
  const success = useSelector(successSelector([LOAD_BRANDS.action]));

  useEffect(() => {
    dispatch(loadBrands(distributorId));
  }, []);

  return (
    <BrandTable
      brands={brands}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
      success={success}
    />
  );
};

BrandList.propTypes = {
  handleOpenEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  distributorId: PropTypes.number
};

export default BrandList;
