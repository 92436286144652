import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addPointsPhone } from 'src/utils/common';

const AvalContactInfo = ({ aval }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow name={t('COMMON.EMAIL')} value={aval.email} />

      <DetailRow
        name={t('COMMON.MOBILE_PHONE')}
        value={aval.phone ? addPointsPhone(aval.phone) : t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.HOME_PHONE')}
        value={
          aval.homePhone ? addPointsPhone(aval.homePhone) : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.OFFICE_PHONE')}
        value={
          aval.officePhone
            ? addPointsPhone(aval.officePhone)
            : t('COMMON.NO_DATA')
        }
      />
    </>
  );
};

AvalContactInfo.propTypes = {
  aval: PropTypes.object.isRequired
};
export default AvalContactInfo;
