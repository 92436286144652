import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  deleteAdministrative,
  updateAdministrative
} from 'src/store/actions/clientActions';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Tooltip } from '@mui/material';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { addPointsPhone } from 'src/utils/common';
import OverflowTipography from 'src/components/OverflowTipography';
import AdministrativeDialog from './CreateAdministrativesDialog';
import DeleteAdministrativeDialog from './DeleteAdministrativeDialog/DeleteAdministrativeDialog';
import { CommonActions } from '../../../../utils/constants/clients';

const AdministrativesRow = ({
  fullName,
  phone,
  email,
  sendNotifications,
  isLegalRepresentative,
  role,
  disabledSwitch,
  id,
  submitEdit,
  isAval,
  isClientInstance
}) => {
  const [switchChecked, setSwitchChecked] = useState(sendNotifications);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const { id: clientId } = useParams();
  const { t } = useTranslation();

  const handleChange = event => {
    dispatch(
      updateAdministrative(
        id,
        clientId,
        {
          sendNotifications: event.target.checked
        },
        () => {}
      )
    );
    setSwitchChecked(event.target.checked);
  };

  const getDisabledTooltipMessage = () => {
    switch (true) {
      case isClientInstance:
        return t(
          'CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.CANT_EDIT_CLIENT_INSTANCE'
        );
      case isAval:
        return t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.CANT_EDIT_AVAL');
      case isLegalRepresentative:
        return t(
          'CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.CANT_EDIT_LEGAL_REPRESENTATIVE'
        );
      default:
        return '';
    }
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenDeleteDialog(true);
    }
  };

  const getRoleName = () => {
    if (role) {
      return role.name;
    }
    if (isClientInstance) {
      return t('CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.BENEFICIARY');
    }
    return t('COMMON.NO_DATA');
  };

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteAdministrative(id, clientId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  return (
    <TableRow hover>
      <TableCell>{fullName}</TableCell>
      <TableCell align="center">{getRoleName()}</TableCell>
      <TableCell align="center">
        <OverflowTipography
          maxWidth="500px"
          sx={{ textAlign: 'center', width: '100%' }}
        >
          {email}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">{addPointsPhone(phone)}</TableCell>
      <TableCell align="center">
        {isAval ? t('COMMON.YES') : t('COMMON.NO')}
      </TableCell>
      <TableCell align="center">
        {isLegalRepresentative ? t('COMMON.YES') : t('COMMON.NO')}
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            disabledSwitch && (
              <span style={{ whiteSpace: 'pre-line' }}>
                {t(
                  'CLIENTS_VIEW.CLIENT_DETAIL.ADMINISTRATIVES.CLIENT_MUST_HAVE_AT_LEAST_ONE_ADMINISTRATIVE_WITH_NOTIFICATIONS_ACTIVATED'
                )}
              </span>
            )
          }
          sx={{ width: '100%' }}
        >
          <Box sx={{ width: '100%' }}>
            <Switch
              checked={switchChecked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={disabledSwitch}
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <MoreMenu
          disabled={isLegalRepresentative || isAval || isClientInstance}
          handleClickOption={handleClickMore}
          message={getDisabledTooltipMessage()}
        />
      </TableCell>
      <AdministrativeDialog
        open={openEditDialog}
        handleClose={handleCloseEdit}
        submit={submitEdit}
        administrative={{
          id,
          fullName,
          phone,
          email,
          sendNotifications,
          role
        }}
      />
      <DeleteAdministrativeDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
    </TableRow>
  );
};

AdministrativesRow.propTypes = {
  fullName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sendNotifications: PropTypes.bool.isRequired,
  isLegalRepresentative: PropTypes.bool,
  disabledSwitch: PropTypes.bool,
  id: PropTypes.number.isRequired,
  submitEdit: PropTypes.func,
  isAval: PropTypes.bool.isRequired,
  role: PropTypes.string,
  isClientInstance: PropTypes.bool.isRequired
};

export default AdministrativesRow;
