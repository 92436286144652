import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
  TextField,
  IconButton,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_ADDRESS_BY_ZIP_CODE,
  getAddressByZipCode
} from 'src/store/actions/clientActions';
import DropdownMenu from 'src/components/DropdownMenu';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import clsx from 'clsx';
import { countriesList } from 'src/utils/constants/countries';
import makeInitialState from './initialState';
import useStyles from './styles';
import validationSchema from './schema';
import Autocomplete from '../Autocomplete/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddressDialog = ({
  open,
  address,
  handleClose,
  submit,
  useExistingAddress = false,
  existingAddressOptions = [],
  includeCountry = false,
  editableCountry = false
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(address), [address]);
  const [useCopomex, setUseCopomex] = useState(initialState.country === 'MX');
  const resetRef = useRef();
  const dispatch = useDispatch();
  const [addressData, setAddressData] = useState({
    cities: [],
    colonias: [],
    states: []
  });
  const isLoadingAddress = useSelector(
    isLoadingSelector([LOAD_ADDRESS_BY_ZIP_CODE.action])
  );
  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});

  const handleZipCodeChange = useCallback((e, setFieldValue) => {
    const { value } = e.target;
    if (value.length === 5 && useCopomex) {
      dispatch(getAddressByZipCode(value, setAddressData));
    }
    setFieldValue('colonia', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
  });

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  const handleCountryChange = useCallback(
    (value, setFieldValue) => {
      setFieldValue('country', value.key ?? null);
      setFieldValue('zipCode', '');
      setFieldValue('city', '');
      setFieldValue('colonia', '');
      setFieldValue('state', '');
      if (value.key === 'MX') {
        setUseCopomex(true);
      } else {
        setUseCopomex(false);
      }
    },
    [setUseCopomex]
  );

  useEffect(() => {
    if (open && address && address.zipCode && useCopomex) {
      dispatch(getAddressByZipCode(address.zipCode, setAddressData));
    }
    if (!open) {
      setCheckboxState(false);
      setSelectedAddress({});
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open, address]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.CLIENT_ADDRESS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                {useExistingAddress && existingAddressOptions.length && (
                  <FormControlLabel
                    key={checkboxState}
                    control={
                      <Checkbox
                        name="useExistingAddress"
                        color="primary"
                        checked={checkboxState}
                        onChange={() => {
                          setCheckboxState(!checkboxState);
                        }}
                      />
                    }
                    label={t('COMMON.USE_EXISTING_ADDRESS')}
                  />
                )}

                {checkboxState && (
                  <DropdownMenu
                    list={existingAddressOptions.map(item => ({
                      name: `Aval - ${item.fullName}`,
                      key: item
                    }))}
                    value={selectedAddress}
                    setValue={value => {
                      setSelectedAddress(value);
                      setFieldValue('street', value.street);
                      setFieldValue(
                        'externalHomeNumber',
                        value.externalHomeNumber
                      );
                      setFieldValue('insideHomeNumber', value.insideHomeNumber);
                      setFieldValue('zipCode', value.zipCode);
                      dispatch(
                        getAddressByZipCode(value.zipCode, setAddressData)
                      );
                      setFieldValue('city', value.city);
                      setFieldValue('colonia', value.colonia);
                      setFieldValue('state', value.state);
                    }}
                    label={t('COMMON.AVAL')}
                  />
                )}

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.street && errors.street)}
                  fullWidth
                  helperText={touched.street && t(errors.street)}
                  label={t('COMMON.STREET')}
                  margin="normal"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.street}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.externalHomeNumber && errors.externalHomeNumber
                  )}
                  fullWidth
                  helperText={
                    touched.externalHomeNumber && t(errors.externalHomeNumber)
                  }
                  label={t('COMMON.EXTERNAL_HOME_NUMBER')}
                  margin="normal"
                  name="externalHomeNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.externalHomeNumber}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.insideHomeNumber && errors.insideHomeNumber
                  )}
                  fullWidth
                  helperText={
                    touched.insideHomeNumber && t(errors.insideHomeNumber)
                  }
                  label={t('COMMON.INSIDE_HOME_NUMBER')}
                  margin="normal"
                  name="insideHomeNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.insideHomeNumber}
                  variant="outlined"
                />

                <FormControl fullWidth>
                  <Autocomplete
                    disabled={!editableCountry}
                    options={countriesList}
                    id="country"
                    getOptionLabel={option => {
                      return option ? t(option.name) : '';
                    }}
                    value={
                      countriesList.find(
                        country => country.key === values.country
                      ) ?? values.country
                    }
                    keepMounted
                    fullWidth
                    sx={{ marginBottom: 1, marginTop: 2 }}
                    onChange={(_e, value) => {
                      handleCountryChange(value, setFieldValue);
                    }}
                    helperText={touched.country && t(errors.country)}
                    label={t('COMMON.COUNTRY')}
                    error={Boolean(touched.country && errors.country)}
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  fullWidth
                  helperText={touched.zipCode && t(errors.zipCode)}
                  label={t('COMMON.ZIP_CODE')}
                  margin="normal"
                  name="zipCode"
                  onBlur={handleBlur}
                  disabled={includeCountry && !values.country}
                  onChange={e => {
                    if (useCopomex) {
                      handleZipCodeChange(e, setFieldValue);
                    }
                    handleChange(e);
                  }}
                  type="text"
                  value={values.zipCode}
                  variant="outlined"
                />

                {useCopomex ? (
                  <>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.city && errors.city)}
                    >
                      <Autocomplete
                        freeSolo
                        onBlur={handleBlur}
                        options={addressData.cities.map(city => ({
                          key: city,
                          name: city
                        }))}
                        getOptionLabel={option => {
                          return option.name ? `${option.name}` : '';
                        }}
                        value={
                          addressData.cities
                            .map(city => ({
                              key: city,
                              name: city
                            }))
                            .find(city => city.name === values.city) ?? {
                            key: values.city,
                            name: values.city
                          }
                        }
                        placeholder={values.city}
                        onChange={(e, value) => {
                          setFieldValue(
                            'city',
                            value ? value.name : e.target.value
                          );
                        }}
                        onInputChange={(e, value) => {
                          setFieldValue('city', value);
                        }}
                        label={t('COMMON.CITY')}
                        error={Boolean(touched.city && errors.city)}
                        errorText={touched.city && t(errors.city)}
                        isLoading={isLoadingAddress}
                        disabled={!values.zipCode || !values.country}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </FormControl>

                    <FormControl
                      fullWidth
                      error={Boolean(touched.colonia && errors.colonia)}
                    >
                      <Autocomplete
                        options={addressData.colonias.map(col => ({
                          key: col,
                          name: col
                        }))}
                        value={
                          addressData.colonias
                            .map(col => ({
                              key: col,
                              name: col
                            }))
                            .find(col => col.name === values.colonia) ?? {
                            key: values.colonia,
                            name: values.colonia
                          }
                        }
                        getOptionLabel={option =>
                          option.name ? `${option.name}` : ''
                        }
                        onChange={(e, value) => {
                          setFieldValue(
                            'colonia',
                            value ? value.name : e.target.value
                          );
                        }}
                        onInputChange={(e, value) => {
                          setFieldValue('colonia', value);
                        }}
                        freeSolo
                        label={t('COMMON.COLONIA')}
                        error={Boolean(touched.colonia && errors.colonia)}
                        errorText={touched.colonia && t(errors.colonia)}
                        isLoading={isLoadingAddress}
                        disabled={!values.zipCode || !values.country}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </FormControl>

                    <FormControl
                      fullWidth
                      error={Boolean(touched.state && errors.state)}
                    >
                      <Autocomplete
                        options={addressData.states.map(state => ({
                          key: state,
                          name: state
                        }))}
                        getOptionLabel={option =>
                          option.name ? `${option.name}` : ''
                        }
                        freeSolo
                        value={
                          addressData.states
                            .map(state => ({
                              key: state,
                              name: state
                            }))
                            .find(state => state.name === values.state) ?? {
                            key: values.state,
                            name: values.state
                          }
                        }
                        onChange={(e, value) => {
                          setFieldValue(
                            'state',
                            value ? value.name : e.target.value
                          );
                        }}
                        onInputChange={(e, value) => {
                          setFieldValue('state', value);
                        }}
                        label={t('COMMON.STATE')}
                        error={Boolean(touched.state && errors.state)}
                        errorText={touched.state && t(errors.state)}
                        isLoading={isLoadingAddress}
                        disabled={!values.zipCode || !values.country}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && t(errors.city)}
                      label={t('COMMON.CITY')}
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.city}
                      variant="outlined"
                      disabled={!values.zipCode || !values.country}
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.colonia && errors.colonia)}
                      fullWidth
                      helperText={touched.colonia && t(errors.colonia)}
                      label={t('COMMON.COLONIA')}
                      margin="normal"
                      name="colonia"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.colonia}
                      variant="outlined"
                      disabled={!values.zipCode || !values.country}
                    />

                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      error={Boolean(touched.state && errors.state)}
                      fullWidth
                      helperText={touched.state && t(errors.state)}
                      label={t('COMMON.STATE')}
                      margin="normal"
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.state}
                      variant="outlined"
                      disabled={!values.zipCode || !values.country}
                    />
                  </>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  address: PropTypes.object,
  useExistingAddress: PropTypes.bool.isRequired,
  existingAddressOptions: PropTypes.array,
  includeCountry: PropTypes.bool,
  editableCountry: PropTypes.bool
};

export default AddressDialog;
