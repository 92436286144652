import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  selectClientsList,
  selectClientsListCount,
  selectClientsQueryOptions
} from 'src/store/selectors/clientSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadClients, LOAD_CLIENTS } from 'src/store/actions/clientActions';
import ClientTable from './ClientsTable';

const ClientsList = ({ clientStatus }) => {
  const dispatch = useDispatch();
  const clients = useSelector(selectClientsList);
  const count = useSelector(selectClientsListCount);
  const { page } = useSelector(selectClientsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CLIENTS.action]));
  const success = useSelector(successSelector([LOAD_CLIENTS.action]));
  const { t } = useTranslation();

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadClients({ page: value }));
    },
    [dispatch]
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.CLIENTS_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.CLIENTS_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <ClientTable
      clients={clients}
      count={count}
      page={page}
      clientStatus={clientStatus}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

ClientsList.propTypes = {
  clientStatus: PropTypes.string
};

export default ClientsList;
