import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/GetApp';
import {
  downloadExcelPaymentSchedule,
  loadContractPaymentSchedule
} from 'src/store/actions/contractActions';
import { FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import ContractCalendarDetail from '.';
import useStyles from '../styles';

const filters = [
  {
    title: 'CONTRACT_VIEW.CONTRACT_DETAIL.PERIOD',
    controlGroup: 'checkbox',
    value: 'fullScheduleView',
    items: [
      {
        label: 'CONTRACT_VIEW.CONTRACT_DETAIL.FULL_SCHEDULE_PAYMENT',
        value: 'fullScheduleView'
      }
    ]
  }
];

function getFilterOptions(options) {
  let fullScheduleView = false;

  if (options.fullScheduleView) {
    fullScheduleView = true;
  }

  if (!options.fullScheduleView) {
    fullScheduleView = false;
  }

  return { fullScheduleView };
}

const ContractCalendar = ({ contract }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({ fullScheduleView: true });

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelPaymentSchedule(id));
  }, [dispatch]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  useEffect(() => {
    dispatch(loadContractPaymentSchedule({ id, fullScheduleView: true }));
  }, [dispatch, id]);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      dispatch(loadContractPaymentSchedule({ ...getFilterOptions(event), id }));
    },
    [setFilterValues, getFilterOptions]
  );

  return (
    <Box mt={3} display="flex" flexDirection="column">
      <Box
        className={classes.actionsContainer}
        lg={12}
        flex
        justifyContent="flex-end"
      >
        <Button
          className={clsx(
            classes.buttons,
            classes.outlined,
            classes.horizontalPadding
          )}
          color="primary"
          onClick={handleClickFilter}
          style={{ marginRight: '15px' }}
        >
          <FilterList className={classes.icon} />
          {t('COMMON.FILTER')}
        </Button>

        <Button
          className={clsx(classes.buttons, classes.outlined)}
          onClick={handleExcelExport}
          color="primary"
          title={t('COMMON.DOWNLOAD_EXCEL')}
        >
          <FileDownloadIcon className={classes.icon} />
        </Button>

        <Filter
          anchorEl={anchorEl}
          filterValues={filterValues}
          handleClose={handleCloseFilter}
          handleChange={handleFilterChange}
          filters={filters}
        />
      </Box>
      <Box lg={12}>
        <ContractCalendarDetail contract={contract.paymentSchedule} />
      </Box>
    </Box>
  );
};

ContractCalendar.propTypes = {
  contract: PropTypes.object
};

export default ContractCalendar;
