import { AvalRole } from '../../../../utils/constants/clients';

const makeInitialState = () => {
  return {
    rfc: '',
    name: '',
    maternalName: '',
    paternalName: '',
    phone: '',
    email: '',
    personType: '',
    aval: {
      name: '',
      roles: [AvalRole.LEGAL_REPRESENTATIVE],
      paternalName: '',
      maternalName: '',
      phone: '',
      email: '',
      rfc: '',
      sex: ''
    }
  };
};

export default makeInitialState;
